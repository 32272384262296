// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.alogo {
  color: #1abc9c;
  margin-left: 50px;
  text-decoration: none;
  background-color: none;
}

a {
  text-decoration: none; 

}

  a:hover {
    text-decoration: underline; 

  }





`, "",{"version":3,"sources":["webpack://./src/pages/Blog/styles.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;;AAEvB;;EAEE;IACE,0BAA0B;;EAE5B","sourcesContent":["\r\n.alogo {\r\n  color: #1abc9c;\r\n  margin-left: 50px;\r\n  text-decoration: none;\r\n  background-color: none;\r\n}\r\n\r\na {\r\n  text-decoration: none; \r\n\r\n}\r\n\r\n  a:hover {\r\n    text-decoration: underline; \r\n\r\n  }\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
