import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';

import Chip from '@mui/material/Chip';


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';


import { Button } from "@material-ui/core";


function novoColaborador() {
    window.location.href = "/gestao/rh/colaboradores/cadastrar"

}

export default function ListarColaboradores() {
    const [colaboradores, setColaboradores] = useState([]);

    const [loading, setLoading] = useState(true);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };




    const columnsDataGrid = [

        {
            headerName: 'ID',
            field: 'id_colaborador',
            id: 1,
            minWidth: 25,

        },

        {
            headerName: 'Status',
            field: 'status_colaborador',
            id: 2,
            minWidth: 200,
            renderCell: (cellValues) => {
                return (
                    <span style={{ backgroundColor: cellValues.row.status_colaborador === 0 ? 'red' : 'green' }}>
                        {
                            cellValues.row.status_colaborador === 0 ? "INATIVO" : "ATIVO"
                        }
                    </span>
                )
            }

        },

        {
            field: "nome",
            headerName: "Nome e Sobrenome",
            id: 3,
            minWidth: 250,
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.nome} {cellValues.row.sobrenome} </span>
                )
            }


        },





        {
            headerName: 'CPF',
            field: 'cpf',
            minWidth: 150,
            id: 4,

        },

        {
            field: "endereco",
            headerName: "Endereço completo",
            id: 5,
            minWidth: 250,
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.logradouro},{cellValues.row.numero},{cellValues.row.complemento}, bairro {cellValues.row.bairro}, {cellValues.row.cidade} - {cellValues.row.estado}    </span>
                )
            }
        },

        {
            field: "departamentos",
            headerName: "Departamentos",
            id: 6,
            minWidth: 350,

            renderCell: (cellValues) => (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                    {cellValues.row.departamentos.map((departamento) => (
                        <Chip key={departamento.id_departamento} label={departamento.nome} />
                    ))}
                </div>
            ),
        },


        {
            field: "cargos",
            headerName: "Cargos",
            id: 7,
            minWidth: 350,

            renderCell: (cellValues) => (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                    {cellValues.row.cargos.map((cargo) => (
                        <Chip key={cargo.id_cargo} label={cargo.nome} />
                    ))}
                </div>
            ),
        },


        {
            field: "funcoes",
            headerName: "Funções",
            id: 8,
            minWidth: 350,

            renderCell: (cellValues) => (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                    {cellValues.row.funcoes.map((funcao) => (
                        <Chip key={funcao.id_funcao} label={funcao.nome} />
                    ))}
                </div>
            ),
        },

        {
            field: "editar",
            headerName: "Editar",
            sortable: false,
            id: 9,
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <Button color="primary"
                        href={"/alterarcolaborador/" + cellValues.row.id_colaborador}
                        target="_blank"
                    >Editar</Button>
                );
            }
        },



    ];





    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

   

    useEffect(() => {




        async function listarColaboradores() {
            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }



                await api.get("v1/protected/colaboradores/listartodos", {
                    headers: headers
                }).then(function (response) {

                    console.log("COlaboradores: " + JSON.stringify(response.data));
                    setColaboradores(response.data);

                    setLoading(false);


                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar colaboradores: " + _err)

            }

        }

        listarColaboradores();


    }, []);



    return (
        <div>

            <NavegadorGestao
                 RH={true}
                 Colaboradores={true}
                 corFundo={'white'} travado={true} corTexto={'black'}
            />


            <div style={{ padding: 5, width: '100%', height: '100%' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoColaborador}
                    >
                        Novo Colaborador
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        <>
                            <DataGrid
                                localeText={ptBR.localeText}
                                pagination
                                checkboxSelection
                                rows={colaboradores}
                                pageSize={20}
                                rowHeight={40}
                                getRowId={(row) => row.id_colaborador}
                                columns={columnsDataGrid}
                                disableColumnResize={false}
                                onCellClick={handleCellClick}
                                onRowClick={handleRowClick}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />

                        </>
                    </div>
                }


            </div>



        </div>
    );


 
}



