import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import {
    Box,
    Tooltip
} from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@mui/icons-material/Edit';
//Icons Imports
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    MaterialReactTable,

} from 'material-react-table';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ReactApexChart from 'react-apexcharts';

import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import moment from 'moment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { parseISO } from 'date-fns';


import { Card, CardContent, Typography } from '@mui/material';
import { ArrowUpward, ArrowDownward, MoreVert, AttachMoney, ShoppingBasket } from '@mui/icons-material';

import { Button } from "@material-ui/core";


const styles = {
    card: {
        borderRadius: '12px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '200px',
    },
    purpleCard: {
        backgroundColor: '#6a1b9a',
        color: '#fff',
    },
    blueCard: {
        backgroundColor: '#0288d1',
        color: '#fff',
    },
    iconBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconBackground: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '8px',
        padding: '8px',
    },
    graphBox: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '8px',
        height: '40px',
        marginTop: '8px',
    },

};


export default function Lancamento() {

    const [loading, setLoading] = useState(true);

    const [objetos, setObjetos] = useState(null);

    const [value, setValue] = useState(1);

    

    const [totalDespesas, setTotalDespesas] = useState(0.0);
    const [totalReceitas, setTotalReceitas] = useState(0.0);

    const [totalDespesasMesAtual, setTotalDespesasMesAtual] = useState(0.0);
    const [totalDespesasPagoMesAtual, setTotalDespesasPagoMesAtual] = useState(0.0);
    const [totalDespesasAPagarMesAtual, setTotalDespesasAPagarMesAtual] = useState(0.0);


    const [totalDespesasHoje, setTotalDespesasHoje] = useState(0.0);
    const [totalReceitasHoje, setTotalReceitasHoje] = useState(0.0);


    const [totalReceitasMesAtual, setTotalReceitasMesAtual] = useState(0.0);
    const [totalReceitasPagoMesAtual, setTotalReceitasPagoMesAtual] = useState(0.0);
    const [totalReceitasAPagarMesAtual, setTotalReceitasAPagarMesAtual] = useState(0.0);



    const dataAtual = new Date();
    const mesAtual = dataAtual.toLocaleString('default', { month: 'long' });
    const anoAtual = dataAtual.getFullYear();



    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/financeiro/lancamentos/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                const dataAtual = new Date();
                const mesAtualInteiro = dataAtual.getMonth();
                const anoAtual = dataAtual.getFullYear();
                const diaAtual = dataAtual.getDate();


                response.data.forEach(item => {
                    if (item.parcelas && Array.isArray(item.parcelas)) {
                        item.parcelas.forEach(parcela => {
                            const dataVencimento = parseISO(parcela.data_vencimento);
                            const mesVencimento = dataVencimento.getMonth();
                            const anoVencimento = dataVencimento.getFullYear();
                            const diaVencimento = dataVencimento.getDate();


                            console.log("Data venc parcela: " + dataVencimento + " Data hoje: " + dataAtual);

                            if (mesVencimento === mesAtualInteiro && anoVencimento === anoAtual) {
                                console.log("Dia parcela: " + diaVencimento + " Dia parcela: " + diaAtual);
                                if (diaVencimento === diaAtual) {

                                    if (item.tipo === 0) {
                                        if (parcela.status === 0) {
                                            setTotalDespesasHoje(prevTotalDespesasHoje => prevTotalDespesasHoje + parcela.valor);
                                        }
                                    } else if (item.tipo === 1) {
                                        if (parcela.status === 0) {
                                            setTotalReceitasHoje(prevTotalReceitasHoje => prevTotalReceitasHoje + parcela.valor);
                                        }
                                    }

                                }


                                if (item.tipo === 0) {
                                    //despesas
                                    //  setTotalDespesas(prevTotalDespesas => prevTotalDespesas + parcela.valor);

                                    if (parcela.status === 0) {
                                        setTotalDespesasAPagarMesAtual(prevTotalDespesasAPagarMesAtual => prevTotalDespesasAPagarMesAtual + parcela.valor);
                                    } else {
                                        setTotalDespesasPagoMesAtual(prevTotalDespesasPagoMesAtual => prevTotalDespesasPagoMesAtual + parcela.valor);
                                    }

                                } else if (item.tipo === 1) {
                                    //receitas
                                    //setTotalReceitas(prevTotalReceitas => prevTotalReceitas + parcela.valor);


                                    if (parcela.status === 0) {
                                        setTotalReceitasAPagarMesAtual(prevTotalReceitasAPagarMesAtual => prevTotalReceitasAPagarMesAtual + parcela.valor);
                                    } else {
                                        setTotalReceitasPagoMesAtual(prevTotalReceitasPagoMesAtual => prevTotalReceitasPagoMesAtual + parcela.valor);
                                    }
                                }
                            }
                            if (item.tipo === 0) {
                                setTotalDespesas(prevTotalDespesas => prevTotalDespesas + parcela.valor);
                            } else if (item.tipo === 1) {
                                setTotalReceitas(prevTotalReceitas => prevTotalReceitas + parcela.valor);
                            }


                        });
                    }
                });

                console.log(" Objetos: " + response);


                // Obtendo o ano atual

                // Criando um objeto para armazenar os totais de receitas e despesas por mês
                const totaisPorMes = Array.from({ length: 12 }, () => ({ mes: '', receitas: 0, despesas: 0 }));

                // Preenchendo o objeto com os totais de receitas e despesas
                response.data.forEach(lancamento => {
                    lancamento.parcelas.forEach(parcela => {
                        const dataVencimento = new Date(parcela.data_vencimento);
                        const mesVencimento = dataVencimento.getMonth();
                        const anoVencimento = dataVencimento.getFullYear();

                        if (anoVencimento === anoAtual) {
                            if (lancamento.tipo === 0) {

                                totaisPorMes[mesVencimento].despesas += (parcela.valor * -1);
                                if (parcela.status === 1) { // Verificar se a parcela foi paga
                                    totaisPorMes[mesVencimento].despesasRealizadas += (parcela.valor * -1);
                                }
                            } else if (lancamento.tipo === 1) {
                                totaisPorMes[mesVencimento].receitas += parcela.valor;
                                if (parcela.status === 1) { // Verificar se a parcela foi paga
                                    totaisPorMes[mesVencimento].receitasRealizadas += parcela.valor;
                                }
                            }
                            totaisPorMes[mesVencimento].mes = dataVencimento.toLocaleString('default', { month: 'long' });
                        }
                    });
                });

                // Formatando os dados para o gráfico
                const categorias = totaisPorMes.map(item => item.mes);
                const receitas = totaisPorMes.map(item => item.receitas);
                const despesas = totaisPorMes.map(item => item.despesas);
                const receitasRealizadas = totaisPorMes.map(item => item.receitasRealizadas); // Adicionar receitas realizadas
                const despesasRealizadas = totaisPorMes.map(item => item.despesasRealizadas); // Adicionar despesas realizadas


                setDadosGrafico(prevState => ({
                    ...prevState,
                    series: [
                        { name: 'Receitas', data: receitas },
                        { name: 'Despesas', data: despesas },
                    
                    ],
                    options: {
                        ...prevState.options,
                        xaxis: {
                            categories: categorias,
                        }
                    }
                }));

                setLoading(false);




            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }

    const [dadosGrafico, setDadosGrafico] = useState({
        series: [
            { name: 'Receitas', data: [] },
            { name: 'Despesas', data: [] },
           
            
        ],
        options: {
            chart: {
                height: '100%',
                type: 'bar',
                width: '100%',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '100%',
                    endingShape: 'rounded'

                },
            },
            colors: ['#0288d1', '#6a1b9a'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: 'Valores',
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return currencyFormatter.format(val);
                    }
                }
            }
        }
    });

    useEffect(() => {




        listar();


    }, []);







    function novo() {
        window.location.href = "/gestao/financas/lancamento/cadastrar"

    }




    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_lancamento', //normal accessorKey
                header: 'ID Lançamento',
                size: 50,
                maxHeight: 50,
            },
            {
                accessorKey: 'data_lancamento',
                header: 'Data Lançamento',
                size: 130,
                maxHeight: 130,
                Cell: ({ cell }) => moment(cell.getValue()).format('DD/MM/YYYY'),
            },

            {
                accessorKey: 'valor',
                header: 'Valor',
                size: 150,
                maxHeight: 150,
                Cell: ({ cell }) => currencyFormatter.format(cell.getValue()),
            },



            {
                accessorKey: 'tipo',
                header: 'Tipo',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {

                    let text = '';
                    if (renderedCellValue === 0)
                        text = "DESPESA";
                    else if (renderedCellValue === 1)
                        text = "RECEITA";
                    else if (renderedCellValue === 2)
                        text = "EMPRESTIMO";
                    else if (renderedCellValue === 3)
                        text = "TRANSFERENCIA";

                    return (
                        <span style={{ maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block' }}>
                            {text}
                        </span>
                    );
                },


            },

            {
                accessorKey: 'status',
                header: 'Status',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {

                    let text = '';
                    if (renderedCellValue === 0)
                        text = "NÃO PAGO";
                    else if (renderedCellValue === 1)
                        text = "PAGO";


                    return (
                        <span style={{ maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block' }}>
                            {text}
                        </span>
                    );
                },
            },

            {
                accessorKey: 'centro_custo.nome',
                header: 'Centro de Custo',
                size: 150,
                maxHeight: 150,
                Cell: ({ renderedCellValue }) => {

                    return (
                        <span style={{ maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block' }}>
                            {renderedCellValue}
                        </span>
                    );
                },
            },

            {
                header: 'Fornecedor',
                size: 300,
                maxHeight: 300,
                accessorFn: row => {
                    const fornecedor = row.fornecedor;
                    if (fornecedor) {
                        return {
                            identificacao: fornecedor.tipo_cliente === 0 ? fornecedor.cpf : fornecedor.cnpj,
                            nome: fornecedor.tipo_cliente === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : (fornecedor.nome_fantasia),

                        };
                    } else {
                        return {
                            identificacao: "",
                            nome: "",
                        };
                    }
                },
                Cell: ({ renderedCellValue }) => {

                    return (
                        <span style={{ maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block' }}>
                            {renderedCellValue.identificacao + " - " + renderedCellValue.nome}
                        </span>
                    );
                },
            },

            {
                header: 'Conta',
                size: 200,
                maxHeight: 200,
                accessorFn: row => {
                    const conta = row.conta;
                    if (conta) {
                        return {
                            nome: conta.nome,
                            grupo: conta.grupo_contas.nome,

                        };
                    } else {
                        return {
                            nome: "",
                            grupo: "",
                        };
                    }
                },
                Cell: ({ renderedCellValue }) => {

                    return (
                        <span style={{ maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block' }}>
                            {renderedCellValue.grupo + " - " + renderedCellValue.nome}
                        </span>
                    );
                },
            },




        ],
        [],
    );


    function handleClickGerenciar(row) {
        const idLancamento = row.original.id_lancamento;
        const url = `/gestao/financas/lancamento/gerenciar/${idLancamento}`;
        window.location.href = url;
    }

    const handleMenuClick = (newValue) => {
        setValue(newValue);
    };


    return (
        <div>

            <NavegadorGestao
                Financas={true}
                Lancamentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />




            {value === 0 &&
                <div style={{ paddingTop: 5, paddingLeft: 220, width: '100%', backroungcolor: '#f0f4f4' }}>
                    {!loading &&
                        <Grid item xs={12} container spacing={2} style={{ width: '100%', margin: 10 }} direction={'row'} >

                            <Grid item xs={9} container spacing={2} style={{ width: '100%' }} direction={'row'} >

                                <Grid item xs={6}>
                                    <Card style={{ ...styles.card, ...styles.blueCard }}>
                                        <CardContent>
                                            <Box style={styles.iconBox}>
                                                <Box style={styles.iconBackground}>
                                                    <AttachMoney />
                                                </Box>
                                            </Box>



                                            <Typography variant="h5">{currencyFormatter.format(totalReceitasPagoMesAtual)}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <ArrowUpward style={{ fontSize: 14, marginRight: 4 }} />
                                                <Typography variant="body2">{"Recebidos em " + `${mesAtual}/${anoAtual}`}</Typography>
                                            </Box>

                                            <Typography variant="h5">{"  " + currencyFormatter.format(totalReceitasAPagarMesAtual)}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <ArrowUpward style={{ fontSize: 14, marginRight: 4 }} />
                                                <Typography variant="body2">{"  Receitas a receber em " + `${mesAtual}/${anoAtual}`}</Typography>
                                            </Box>
                                            <Box style={styles.graphBox}></Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={6}>
                                    <Card style={{ ...styles.card, ...styles.purpleCard, position: 'relative', overflow: 'hidden' }}>
                                        <CardContent>
                                            <Box style={styles.iconBox}>
                                                <Box style={styles.iconBackground}>
                                                    <ShoppingBasket />
                                                </Box>
                                            </Box>


                                            <Typography variant="h5">{currencyFormatter.format(totalDespesasPagoMesAtual)}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <ArrowDownward style={{ fontSize: 14, marginRight: 4 }} />
                                                <Typography variant="body2">{"Despesas pagas em " + `${mesAtual}/${anoAtual}`}</Typography>
                                            </Box>

                                            <Typography variant="h5">{"  " + currencyFormatter.format(totalDespesasAPagarMesAtual)}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <ArrowDownward style={{ fontSize: 14, marginRight: 4 }} />
                                                <Typography variant="body2">{"  Despesas a pagar em " + `${mesAtual}/${anoAtual}`}</Typography>
                                            </Box>


                                        </CardContent>
                                        <Box style={{
                                            position: 'absolute',
                                            top: -200, // aumentei 10 unidades
                                            right: -200, // aumentei 10 unidades
                                            width: 350, // aumentei 20 unidades
                                            height: 350, // aumentei 20 unidades
                                            backgroundColor: '#9673c3', // tom mais claro
                                            borderRadius: '50%',
                                            opacity: 0.5,
                                            zIndex: 1,
                                        }}></Box>
                                        {/* Semicírculo escuro */}
                                        <Box style={{
                                            position: 'absolute',
                                            top: -150, // aumentei 10 unidades
                                            right: -150, // aumentei 10 unidades
                                            width: 400, // aumentei 20 unidades
                                            height: 250, // aumentei 20 unidades
                                            backgroundColor: '#3a1e56', // tom mais escuro
                                            borderRadius: '50%',
                                            opacity: 0.5,
                                            zIndex: 0,

                                        }}></Box>
                                    </Card>
                                </Grid>



                                <Grid item xs={12} container  >
                                    <ReactApexChart options={dadosGrafico.options} series={dadosGrafico.series}
                                        type="bar"
                                        height={400}
                                        width={800}
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={3} container style={{ width: '100%' }} spacing={1} direction={'row'} justifyContent={'flex-start'} alignItens={'flex-start'} >
                                <Grid item xs={12}>
                                    <Card style={{ ...styles.card, ...styles.blueCard, height: '100px', padding: 5, flexDirection: 'row' }}>
                                        <CardContent>
                                            <Box style={styles.iconBox}>
                                                <Box style={styles.iconBackground}>
                                                    <AttachMoney />
                                                </Box>
                                                <Typography variant="h5" style={{ fontWeight: 'bold' }}>{currencyFormatter.format(totalReceitas / 1000) + " mil"}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <ArrowUpward style={{ fontSize: 14 }} />
                                                <Typography variant="body2">{"Receita Total"}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                    <Card style={{ ...styles.card, ...styles.purpleCard, height: '100px', padding: 5, flexDirection: 'row' }}>
                                        <CardContent>
                                            <Box style={styles.iconBox}>
                                                <Box style={styles.iconBackground}>
                                                    <ShoppingBasket />
                                                </Box>
                                                <Typography variant="h5" style={{ fontWeight: 'bold' }}>{currencyFormatter.format(totalDespesas / 1000) + " mil"}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <ArrowDownward style={{ fontSize: 14 }} />
                                                <Typography variant="body2">{"Despesa Total"}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} >
                                    <Card style={{ ...styles.card, backgroundColor: 'white', height: '300px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)' }}>
                                        <CardContent>

                                            <Typography variant="h6" style={{ color: 'black', fontWeight: 'bold' }}>{"Vence Hoje"}</Typography>
                                            <br></br>
                                            <Typography variant="h4" style={{ color: '#6a1b9a', fontWeight: 'bold' }}>{currencyFormatter.format(totalDespesasHoje)}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <ArrowDownward style={{ fontSize: 14 }} />
                                                <Typography variant="body2" style={{ color: '#6a1b9a', fontWeight: 'bold' }}>{"a pagar"}</Typography>
                                            </Box>
                                            <br></br>
                                            <br></br>
                                            <Typography variant="h4" style={{ color: '#0288d1', fontWeight: 'bold' }}>{currencyFormatter.format(totalReceitasHoje)}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <ArrowUpward style={{ fontSize: 14 }} />
                                                <Typography variant="body2" style={{ color: '#0288d1', fontWeight: 'bold' }}>{"a receber"}</Typography>
                                            </Box>

                                        </CardContent>
                                    </Card>
                                </Grid>


                                <Grid item xs={12} >
                                </Grid>

                            </Grid>
                        </Grid>
                    }

                </div>
            }


            {
                value === 1 &&
                <div style={{ paddingTop: 5, paddingLeft: 220 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item xs={12}
                    >
                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={novo}
                        >
                            Novo Lançamento
                        </Button>
                    </Grid>
                    {loading ?
                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                        </Skeleton>
                        :

                        <div>
                            <div style={{ backgroundColor: 'white' }}>
                                {objetos !== null && (
                                    <>

                                        <MaterialReactTable
                                            columns={columns}
                                            data={objetos}
                                            enableBottomToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_lancamento}
                                            enableGrouping
                                            initialState={{
                                                pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                            muiPaginationProps={{
                                                rowsPerPageOptions: [50, 100, 200],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={true}
                                            renderDetailPanel={({ row }) => (
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        left: '30px',
                                                        maxWidth: '1000px',
                                                        position: 'sticky',
                                                        width: '100%',
                                                    }}
                                                >
                                                </Box>
                                            )}
                                            renderRowActions={({ row }) => (
                                                <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                                                    <Tooltip title="Gerenciar">
                                                        <IconButton color="error" onClick={() => handleClickGerenciar(row)}>
                                                            <SettingsIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </Box>
                                            )}

                                        />


                                    </>
                                )}
                            </div>
                        </div>
                    }


                </div>
            }

            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    top: '100px', // Ajuste conforme a altura do NavegadorGestao
                    height: 'calc(100vh - 100px)',
                    '& .MuiDrawer-paper': {
                        position: 'fixed',
                        top: '100px', // Ajuste conforme a altura do NavegadorGestao
                        width: '220px', // Defina a largura do Drawer conforme necessário
                    },
                }}
            >
                <List>
                    <ListItem button onClick={() => handleMenuClick(0)}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button onClick={() => handleMenuClick(1)}>
                        <ListItemIcon><PostAddIcon /></ListItemIcon>
                        <ListItemText primary="Lançamentos" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><PostAddIcon /></ListItemIcon>
                        <ListItemText primary="Relatoria(em breve)" />
                    </ListItem>
                </List>
            </Drawer>


        </div >
    );
}



