// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-nav {
  background: rgba(0,0,0,0.0);    height: 15px;
    padding-left: '50%';
    font-size: 14px;
    color: black;
}


#nav-dropdown{
    color: black;
    font-size: 14px;
    font-style: normal;
    background: rgba(0,0,0,0.0);
  }



.nav-dropdown:hover{
   color:black;
}


#nav-link{
    font-size: 14px;
    color: black;
}

#nav-link-inicio{
  font-size: 14px;
  color: black;
}

#nav-link-mobile{
  font-size: 14px;
  color: black;
  background: white; 
}

.nav-link:hover {
  text-decoration: underline; 
  background-color: orange;
  }

  .minha-conta:hover {
    text-decoration: underline; 
    background-color: white;
    }
  

  .navbar-nav .dropdown-menu {
    position: absolute;
}

.nav-item .dropdown-menu {
  background: rgba(255,255,255,1);  }
  
  .nav-item .dropdown-item {
    color: black;
    background: rgba(255,255,255,0.1);    font-size: 22px;
  }
  
  .nav-item .dropdown-item:hover {
    background: rgba(255,255,255,0.1);    
  }
`, "",{"version":3,"sources":["webpack://./src/components/NavBarGestao/styles.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B,KAAK,YAAY;IAC1C,mBAAmB;IACnB,eAAe;IACf,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,2BAA2B;EAC7B;;;;AAIF;GACG,WAAW;AACd;;;AAGA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,wBAAwB;EACxB;;EAEA;IACE,0BAA0B;IAC1B,uBAAuB;IACvB;;;EAGF;IACE,kBAAkB;AACtB;;AAEA;EACE,+BAA+B,GAAG;;EAElC;IACE,YAAY;IACZ,iCAAiC,KAAK,eAAe;EACvD;;EAEA;IACE,iCAAiC;EACnC","sourcesContent":[".color-nav {\r\n  background: rgba(0,0,0,0.0);    height: 15px;\r\n    padding-left: '50%';\r\n    font-size: 14px;\r\n    color: black;\r\n}\r\n\r\n\r\n#nav-dropdown{\r\n    color: black;\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    background: rgba(0,0,0,0.0);\r\n  }\r\n\r\n\r\n\r\n.nav-dropdown:hover{\r\n   color:black;\r\n}\r\n\r\n\r\n#nav-link{\r\n    font-size: 14px;\r\n    color: black;\r\n}\r\n\r\n#nav-link-inicio{\r\n  font-size: 14px;\r\n  color: black;\r\n}\r\n\r\n#nav-link-mobile{\r\n  font-size: 14px;\r\n  color: black;\r\n  background: white; \r\n}\r\n\r\n.nav-link:hover {\r\n  text-decoration: underline; \r\n  background-color: orange;\r\n  }\r\n\r\n  .minha-conta:hover {\r\n    text-decoration: underline; \r\n    background-color: white;\r\n    }\r\n  \r\n\r\n  .navbar-nav .dropdown-menu {\r\n    position: absolute;\r\n}\r\n\r\n.nav-item .dropdown-menu {\r\n  background: rgba(255,255,255,1);  }\r\n  \r\n  .nav-item .dropdown-item {\r\n    color: black;\r\n    background: rgba(255,255,255,0.1);    font-size: 22px;\r\n  }\r\n  \r\n  .nav-item .dropdown-item:hover {\r\n    background: rgba(255,255,255,0.1);    \r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
