import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Autocomplete from '@material-ui/lab/Autocomplete';



import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from '@mui/material';
import { Card, CardContent } from '@mui/material';

import { format, isValid, parse } from 'date-fns';
import { useHistory } from "react-router-dom";


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";



export default function CadastrarLancamento() {
    const history = useHistory();

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [loading, setLoading] = useState(true);


    const [tipo, setTipo] = useState(0);
    const today = new Date();

    // Formata a data de hoje para dd/mm/yyyy
    const formattedDate = format(today, 'dd/MM/yyyy');
    const [data, setData] = useState();
    const [dataLancamento, setDataLancamento] = useState(formattedDate);

    const [centroCusto, setCentroCusto] = useState(null);
    const [fornecedor, setFornecedor] = useState(null);
    const [conta, setConta] = useState(null);
    const [contas, setContas] = useState([]);

    const [centrosCusto, setCentrosCusto] = useState([]);

    const [fornecedores, setFornecedores] = useState([]);
    const [observacao, setObservacao] = useState('');
    const [descricao, setDescricao] = useState('');
    const [identificacao, setIdentificacao] = useState('');
    const [clientes, setClientes] = useState([]);

    const [cliente, setCliente] = useState(null);


    const handleChangeTipo = (event) => {
        setTipo(event.target.value);
    };



 async function salvar() {
        try {

            var id_user = Cookies.get('id_usuario');

            const parsedDate = parse(dataLancamento, 'dd/MM/yyyy', new Date());
            if (isValid(parsedDate)) {

            } else {
                alert("A data do Lançamento é invalida!");
                return;
            }

            const dataFormatada = format(parse(dataLancamento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');

            if(!conta){
                alert("selecione a Conta");
                return;
            }

            const cadastro = {

                tipo: tipo,
                usuario_criador: { id_usuario: id_user, },
                data_lancamento: dataFormatada,
                conta: conta,
                descricao: descricao,
                observacao: observacao,
                identificacao: identificacao,


            };

            if(tipo === 0){
                //despesa
                if(!centroCusto){
                    alert("selecione a Centro de Custo pagador");
                    return;
                }else{
                    cadastro.centro_custo = centroCusto
                }

                if(!fornecedor){
                    alert("selecione o Fornecedor recebedor");
                    return;
                }else{
                    cadastro.fornecedor = fornecedor
                }
            }else if(tipo === 1){
                 //receita
                 if(!centroCusto){
                    alert("selecione a Centro de Custo recebedor");
                    return;
                }else{
                    cadastro.centro_custo = centroCusto
                }

                if(!cliente){
                    alert("selecione o Cliente pagador");
                    return;
                }else{
                    cadastro.cliente = cliente
                }
            }

          

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/financeiro/lancamentos/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {


                history.push({
                    pathname: "/gestao/financas/lancamento/gerenciar/" + cadastro_salvo.id_lancamento,
                })
                
                

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
        }

    };



    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/financeiro/contas/listartodos", {
                headers: headers
            }).then(function (response) {
                setContas(response.data)

                api.get("v1/protected/fornecedores/listarTodos", {

                    headers: headers
                }).then(function (response) {


                    setFornecedores(response.data);

                    api.get("v1/protected/clientes/listarTodos", {
                        headers: headers
                    }).then(function (response) {

                        setClientes(response.data);

                        api.get("v1/protected/financeiro/centrocusto/listartodos", {
                            headers: headers
                        }).then(function (response) {

                            setCentrosCusto(response.data);

                            setLoading(false);

                        });
                    });

                });


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }






    function returnDescricaoFornecedor(fornecedor) {

        var identificacao = fornecedor.tipo_fornecedor === 0 ? "CPF " : "CNPJ"

        var valor = fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj;

        var nome = fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) :
            (fornecedor.nome_fantasia);

        var endereco = fornecedor.logradouro + ", nº " + fornecedor.numero + ", " + fornecedor.bairro + ", " + fornecedor.cidade + " " + fornecedor.estado + " CEP: " + fornecedor.cep;


        var texto = "ID: " + fornecedor.id_fornecedor + " " + identificacao + " " + valor + " " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    function returnDescricaoCentroCusto(cc) {
        var texto = cc.nome;
        return texto;
    }

    function returnDescricaoConta(conta) {
        var texto = conta.grupo_contas.nome + "\n" + conta.nome + "\nDescrição: " + conta.descricao;
        return texto;
    }

    function returnDescricaoCliente(cliente) {

        var identificacao = cliente.tipo_cliente === 0 ? "CPF " : "CNPJ"

        var valor = cliente.tipo_cliente === 0 ? cliente.cpf : cliente.cnpj;

        var nome = cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) :
            (cliente.nome_fantasia);

        var endereco = cliente.logradouro + ", nº " + cliente.numero + ", " + cliente.bairro + ", " + cliente.cidade + " " + cliente.estado + " CEP: " + cliente.cep;


        var texto = "ID: " + cliente.id_cliente + " " + identificacao + " " + valor + " " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    useEffect(() => {

        listarInformacoes();


    }, []);





    return (
        <div>
            <NavegadorGestao
                Financas={true}
                TabelasAuxiliaresFinancas={true}
                InstituicaoBancaria={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            {!loading &&
                <Grid

                    container
                    direction="row"
                    item xs={12}
                    alignItems="center"
                    justifyContent="center"
                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                >

<Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar </Button>
                </Grid>

                    <Grid
                        item xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{ padding: 10 }}
                        container  >





                        <Grid item xs={3} style={{ padding: 2 }}>
                            <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                <CardContent>
                                    <Grid
                                        item xs={12}
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        style={{ padding: 2 }}
                                        container  >


                                        <Grid
                                            item xs={12}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ padding: 10 }}
                                            container
                                        >
                                            <Grid item xs={12}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="tipo_notificacao_registro-label">Tipo do Lançamento</InputLabel>
                                                        <Select
                                                            labelId="tipo_notificacao_registro-label"
                                                            id="area-tipo_registro"
                                                            value={tipo}
                                                            onChange={handleChangeTipo}
                                                            label="Tipo do Lançamento"
                                                        >
                                                            <MenuItem value={0}>Despesa</MenuItem>
                                                            <MenuItem value={1}>Receita</MenuItem>


                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>


                                        </Grid>




                                        <Grid
                                            item xs={12}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ padding: 10 }}
                                            container
                                        >

                                            <TextField
                                                variant="standard"
                                                name="dataLancamento"
                                                label="Data Lançamento"
                                                required
                                                id="dataLancamento"
                                                value={dataLancamento}
                                                onChange={(e) => setDataLancamento(e.target.value)}
                                                fullWidth
                                            />

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>


                        {tipo === 0 &&
                            <Grid item xs={5} style={{ padding: 2 }}>
                                <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                    <CardContent>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ padding: 10 }}
                                            >

                                                <Autocomplete
                                                    id="select-centro-custo"
                                                    options={centrosCusto}
                                                    getOptionLabel={(centroCusto) => returnDescricaoCentroCusto(centroCusto).replace(/<br \/>/g, '\n')}
                                                    value={centroCusto}
                                                    onChange={(e, v) => setCentroCusto(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((centroCusto) => {
                                                            const cotacaoText = returnDescricaoCentroCusto(centroCusto).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Pagador: Centro de Custo" multiline minRows={2} fullWidth />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCentroCusto(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ padding: 10 }}
                                            >

                                                <Autocomplete
                                                    id="select-fornecedor_cotacao1"
                                                    options={fornecedores}

                                                    getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor).replace(/<br \/>/g, '\n')}
                                                    value={fornecedor}
                                                    onChange={(e, v) => setFornecedor(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((fornecedor) => {
                                                            const cotacaoText = returnDescricaoFornecedor(fornecedor).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Recebedor: Fornecedor" multiline minRows={2} fullWidth />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoFornecedor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }


                        {tipo === 1 &&

                            <Grid item xs={5} style={{ padding: 2 }}>
                                <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                    <CardContent>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ padding: 10 }}
                                            >

                                                <Autocomplete
                                                    id="select-fornecedor_cotacao1"
                                                    options={clientes}

                                                    getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                    value={cliente}
                                                    onChange={(e, v) => setCliente(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cliente) => {
                                                            const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Pagador: Cliente" multiline minRows={2} fullWidth />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>



                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ padding: 10 }}
                                            >

                                                <Autocomplete
                                                    id="select-centro-custo"
                                                    options={centrosCusto}
                                                    getOptionLabel={(centroCusto) => returnDescricaoCentroCusto(centroCusto).replace(/<br \/>/g, '\n')}
                                                    value={centroCusto}
                                                    onChange={(e, v) => setCentroCusto(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((centroCusto) => {
                                                            const cotacaoText = returnDescricaoCentroCusto(centroCusto).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Recebedor: Centro de Custo" multiline minRows={2} fullWidth />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCentroCusto(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>



                                    </CardContent>
                                </Card>
                            </Grid>
                        }


                        <Grid item xs={4} style={{ padding: 2 }}>
                            <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ padding: 10 }}
                                        >
                                            <Autocomplete
                                                id="select-conta"
                                                options={contas}
                                                getOptionLabel={(conta) => returnDescricaoConta(conta).replace(/<br \/>/g, '\n')}
                                                value={conta}
                                                onChange={(e, v) => setConta(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((conta) => {
                                                        const cotacaoText = returnDescricaoConta(conta).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Conta" multiline minRows={2} fullWidth />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoConta(option) }} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>


                    </Grid>

                    <Grid
                        item xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{ padding: 10 }}
                        container
                    >
                        <Grid
                            item xs={12}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            style={{ padding: 10 }}
                            container
                        >
                            <TextField
                                variant="standard"
                                name="identificacao"
                                label="Identificação"
                                required
                                id="identificacao"
                                value={identificacao}
                                onChange={(e) => setIdentificacao(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{ padding: 10 }}
                        container
                    >
                        <Grid
                            item xs={12}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            style={{ padding: 10 }}
                            container
                        >

                            <TextField
                                variant="standard"
                                name="descricao"
                                label="Descrição"
                                required
                                id="descricao"
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                fullWidth
                                multiline
                                minRows={3}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{ padding: 10 }}
                        container
                    >
                        <Grid
                            item xs={12}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            style={{ padding: 10 }}
                            container
                        >
                            <TextField
                                variant="standard"
                                name="observacao"
                                label="Observações"
                                required
                                id="observacao"
                                value={observacao}
                                onChange={(e) => setObservacao(e.target.value)}
                                fullWidth
                                multiline
                                minRows={3}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            }
        </div >
    );
}


