import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import api from '../../../services/api';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuFornecedor from '../components/menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NavBarAdmin from "../../../components/NavBarAdmin";
import Rodape from '../../../components/Rodape';
import { Button , MenuItem,  Select, InputLabel} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));


function novoCliente() {
    window.location.href = "/cadastrarclientefornecedor"

}

export default function ClienteFornecedor() {



    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [clientes, setClientes] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [parametros, setParametros] = useState(
        {
            identificacao: "%",
            page: 0,
            size: 25,
        }
    );



   

    const handleChangePage = (event, value) => {

        setPage(value);
        let num_pagina = parseInt(value) - 1;
        console.log("numero da pagina: " + num_pagina)
        setParametros(prevState => ({ ...prevState, 'page': num_pagina }))
        

    };


    const handleRowElementsPorPage = (e) => {

        setParametros(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
       

    }



   

    useEffect(() => {



        async function listarClientes() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                const id_usuario = parseInt(Cookies.get('id_usuario'), 10);
                console.log("id na tela Clientes: " + id_usuario)
                await api.get("v1/protected/clientes/listarpaginadosporfornecedor" , {
                    params: {
                        identificacao: parametros.identificacao,
                        page: parametros.page,
                        size: parametros.size,
                        idfornecedor: id_usuario,

                    },
                    headers: headers
                }).then(function (response) {
                    setClientes(response.data.content)

                    console.log(" Clientes: " + response);
                    setLoading(false);
                    setTotalElements(response.data.totalElements);
                    setTotalPages(response.data.totalPages);

                    console.log(" total de elementos: " + response.data.totalElements);


                    console.log(" total de paginas: " + response.data.totalPages);

                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar Clientes: " + _err)

            }

        }
        listarClientes();


    }, [parametros]);


    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} >
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >
                            <TableCell style={{ backgroundColor: 'white', color: 'white', position: "sticky", top: 0, textAlign: "center" }} colSpan={1}></TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Status</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Data Cadastro</TableCell>

                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>CPF/CNPJ</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Nome/Nome Fantasia</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>E-mail</TableCell>
     
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientes.map((cliente) => (
                            <Row key={cliente.id_cliente} row={cliente} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }




    const Row = (props) => {
        const { row } = props;
        const [open, setOpen] = React.useState(false);


        function setOpenInfoExtra(open) {
            setOpen(open);
        }


        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenInfoExtra(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>


                    <TableCell colSpan={1} align="right">{row.id_cliente}</TableCell>
                    <TableCell colSpan={1} align="right">{row.status === 0 ? "DESATIVADO" : "ATIVO"}</TableCell>
                    <TableCell colSpan={1} align="right">{row.data_cadastro}</TableCell>
                    <TableCell colSpan={1} align="right">{row.tipo_cliente === 0 ? row.cpf : row.cnpj}</TableCell>
                    <TableCell colSpan={1} align="right">{row.tipo_cliente === 0 ? row.nome + " " + row.sobrenome : row.nome_fantasia}</TableCell>
                    <TableCell colSpan={1} align="right">{row.email}</TableCell>
                 


                </TableRow>


            </React.Fragment>
        );
    }



    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#DCDCDC' }}>
                <MenuFornecedor titulo={"Clientes"} icone={true} />

                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    <div style={{ padding: 10, width: '100%' }}>
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :

                            <div>
                                <CollapsibleTable></CollapsibleTable>
                                <div style={{ backgroundColor: 'white' }}>
                                    <Grid
                                        direction="row"
                                        item xs={12} sm={12} md={12} lg={12} xl={12}
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ padding: 10 }}
                                        container
                                    >

                                        <Grid
                                            item xs={12} sm={12} md={12} lg={12} xl={12}
                                            direction="column"
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ padding: 20 }}
                                        >

                                            <span style={{ fontWeight: 'bold', fontSize: 14 }}> {totalElements} Clientes encontrados   </span>

                                            <InputLabel id="size">Elementos por página:</InputLabel>
                                            <Select
                                                labelId="size"
                                                id="size"
                                                value={parametros.size}
                                                name="size"
                                                onChange={e => handleRowElementsPorPage(e)}
                                                label="size"
                                                style={{ paddingLeft: 5 }}
                                            >
                                                <MenuItem value={10} >10</MenuItem>
                                                <MenuItem value={25}>20</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                                <MenuItem value={100}>2000</MenuItem>

                                            </Select>
                                        </Grid>
                                        <Grid
                                            item xs={12} sm={12} md={12} lg={12} xl={12}
                                            direction="column"
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Pagination
                                                count={totalPages}
                                                page={page}
                                                onChange={handleChangePage}
                                                variant="outlined"
                                                size="large"
                                                color="primary"
                                            />

                                        </Grid>

                                    </Grid>


                                </div>
                            </div>
                        }
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            item xs={12}
                        >
                            <Button style={{ marginTop: 20 }}
                                variant="contained"
                                color="primary"
                                onClick={novoCliente}
                            >
                                Novo Cliente
                            </Button>
                        </Grid>

                    </div>

                </main>
            </div>
            <div >
                <Rodape zap={false}/>
            </div>
        </div>
    );
}



