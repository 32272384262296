import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuCliente from '../components/menu';
import Cookies from 'js-cookie';
import api from '../../../services/api';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NavBarAdmin from "../../../components/NavBarAdmin";
import Rodape from '../../../components/Rodape';
import consumo from '../../../assets/imgs/grafico-de-linha.png';
import chave from '../../../assets/imgs/chave.png';
import seta_cima from '../../../assets/imgs/seta_cima.png';
import seta_baixo from '../../../assets/imgs/seta_baixo.png';
import './styles.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChargingStation, faUsers, faCar } from "@fortawesome/free-solid-svg-icons";
import relogio from '../../../assets/imgs/relogio.png';
import moment from 'moment';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {

    Legend
} from 'recharts';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));



export default function VisaoGeralFornecedor() {
    const classes = useStyles();
    const [meusDados, setMeusDados] = useState([]);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [registros, setRegistros] = useState(null);
    const [info, setInfo] = useState(null);







    useEffect(() => {

        async function listarMeusDados() {
            try {


                const token = Cookies.get('token');
                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                const id_usuario = Cookies.get('id_usuario');


                await api.get("v1/protected/retornardadosfornecedor/" + id_usuario, {
                    headers: headers
                }).then(function (response) {
                    setMeusDados(response.data)
                    console.log(" Meus Dados: " + response);

                    api.get("v1/protected/retornardadosstatusfornecedor/" + id_usuario, {
                        headers: headers
                    }).then(function (response) {
                        setInfo(response.data)
                        setStatus(response.data.status)
                        setRegistros(response.data.registros)
                        console.log(" Meus Status: " + response);


                        setLoading(false);

                    });

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar seus dados: " + _err)

            }

        }

        listarMeusDados();

    }, []);




    function formatDataOnly(data) {
        var dataCtr = moment(data, "YYYY-MM-DD hh:mm:ss");
        return dataCtr.format("DD/MM/YYYY");
    }





    function formatPorcentagem(consumo_atual, consumo_passado) {
        const porcentagem = ((consumo_atual * 100) / consumo_passado) - 100;
        return Number(porcentagem).toFixed(0);
    };

    const DataFormater = (number) => {
        return number.toLocaleString() + 'Kw/h';
    }

    function formatMinutos(minutos) {
        const horas = Math.floor(minutos / 60);
        const min = minutos % 60;
        const textoHoras = (`00${horas}`).slice(-2);
        const textoMinutos = (`00${min}`).slice(-2);

        return `${textoHoras}:${textoMinutos}`;
    };


    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#1C1C1' }}>

                <MenuCliente titulo={"Visão Geral"} />
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} height={200} style={{ backgroundColor: '#1C1C1C' }}>
                    </Skeleton>
                    :
                    <div style={{ paddingTop: 60, padding: 20, width: '100%', backgroundColor: 'Silver' }} >

                        <Grid container spacing={2}
                            direction="row"
                            alignItems="center"
                            item xs={12}
                            style={{ paddingTop: 60 }}

                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper elevation={5} style={{ color: 'black', height: 200, width: '100%', backgroundColor: 'LightGreen', borderRadius: '10px' }} >
                                    <Grid container
                                        direction="row"
                                        item xs={12}
                                    >
                                        <Grid item xs={12}
                                            style={{ paddingLeft: 20 }}
                                        >
                                            <Typography variant="h5" style={{ textAlign: 'left', paddingTop: 20 }}>
                                                Bem-Vindo
                                            </Typography>
                                            <Grid container
                                                direction="row"
                                                item xs={12}
                                            >
                                                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'left', paddingLeft: 20, paddingTop: 20 }}>
                                                    {meusDados.tipo_fornecedor === 0 ? (meusDados.nome + " " + meusDados.sobrenome) : meusDados.nome_fantasia}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}

                                            container
                                            direction="row"
                                        >
                                            <Grid item xs={10}
                                            >
                                                <Typography variant="h5" style={{ textAlign: 'center' }}>
                                                    <p></p> a revolução da mobilidade urbana!
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ textAlign: 'center' }}>
                                                <img alt="chave" width={80} height={80}
                                                    src={chave}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            </Grid>



                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Paper elevation={5} style={{ color: 'black', height: 200, width: '100%', backgroundColor: 'Gainsboro', borderRadius: '10px' }} >
                                    <Grid container
                                        direction="column"
                                        item xs={12}
                                        alignItens="center"
                                        justifyContent="center"
                                    >

                                        <br></br><br></br><br></br>

                                        <Grid container
                                            direction="row"
                                            item xs={12}
                                            alignItens="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={6}
                                            >
                                                <Typography style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold' }}>
                                                    {status.num_controladoras + (status.num_controladoras > 1 ? " estações de recarga instaladas" : " estação de recarga instalada")}
                                                </Typography>
                                            </Grid>

                                            <FontAwesomeIcon icon={faChargingStation} size="6x" style={{ color: "blue" }} />
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Paper elevation={5} style={{ color: 'black', height: 200, width: '100%', backgroundColor: 'Aquamarine', borderRadius: '10px' }} >
                                    <Grid container
                                        direction="column"
                                        item xs={12}
                                        alignItens="center"
                                        justifyContent="center"
                                    >

                                        <br></br><br></br><br></br>

                                        <Grid container
                                            direction="row"
                                            item xs={12}
                                            alignItens="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={6}
                                            >
                                                <Typography style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold' }}>
                                                    {status.num_clientes + (status.num_clientes > 1 ? " clientes cadastrados" : " cliente cadastrado")}
                                                </Typography>
                                            </Grid>

                                            <FontAwesomeIcon icon={faUsers} size="6x" style={{ color: "purple" }} />
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>



                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Paper elevation={5} style={{ color: 'black', height: 200, width: '100%', backgroundColor: 'NavajoWhite', borderRadius: '10px' }} >
                                    <Grid container
                                        direction="column"
                                        item xs={12}
                                        alignItens="center"
                                        justifyContent="center"
                                    >

                                        <br></br><br></br><br></br>

                                        <Grid container
                                            direction="row"
                                            item xs={12}
                                            alignItens="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={6}
                                            >
                                                <Typography style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold' }}>
                                                    {status.num_veiculos + (status.num_veiculos > 1 ? " veículos cadastrados" : " veículo cadastrado")}
                                                </Typography>
                                            </Grid>

                                            <FontAwesomeIcon icon={faCar} size="6x" style={{ color: "SaddleBrown" }} />
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper elevation={5} style={{ color: 'white', height: 200, width: '100%', backgroundColor: 'Indigo', borderRadius: '20px' }} >
                                    <Grid container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent='center'
                                        item xs={12}

                                    >

                                        <Grid item xs={12}>
                                            <Typography style={{ textAlign: 'center', fontSize: '100%', paddingTop: 20,fontWeight: 'bold'  }}>
                                                {"Sessões Abertas: " + (info.num_sessoes_carga_abertas > 0 ? (info.num_sessoes_carga_abertas > 1 ? (info.num_sessoes_carga_abertas + " sessões") : (info.num_sessoes_carga_abertas + " sessão") ) : "Nenhuma sessão de carga em andamento")}
                                            </Typography>
                                            <br></br>
                                        </Grid>
                                        {info.num_sessoes_carga_abertas > 0 ?
                                            <div>
                                                <Grid container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent='center'
                                                    item xs={12}
                                                >
                                                    <Typography style={{ fontSize: '100%', textAlign: 'center' }}>
                                                        <span style={{ fontWeight: 'bold' }}> Duração: {formatMinutos(info.duracao)}h  </span>
                                                    </Typography>

                                                    <img alt="relogio" width={32} height={32}
                                                        src={relogio}
                                                    />
                                                </Grid>

                                                <Grid container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent='center'
                                                    item xs={12}
                                                >

                                                    <Typography style={{ fontSize: '100%', textAlign: 'center' }}>
                                                        <span style={{ fontSize: '100%', fontWeight: 'bold' }}> Consumo Total: {info.consumo_sessao.toLocaleString()}kWh  </span>
                                                    </Typography>

                                                    <img alt="consumo" width={50} height={50}
                                                        src={consumo}
                                                    />

                                                </Grid>

                                                <Grid container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent='center'
                                                    item xs={12}
                                                >

                                                    <Typography style={{ fontSize: '100%', textAlign: 'center' }}>
                                                        <span style={{ fontSize: '100%', fontWeight: 'bold' }}> Consumo Momentâneo: {info.consumo_momentaneo.toLocaleString()}kWh  </span>
                                                    </Typography>

                                                    <img alt="consumo" width={50} height={50}
                                                        src={consumo}
                                                    />

                                                </Grid>
                                            </div> :
                                            <div></div>}
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={10}  >
                                <Paper elevation={5} style={{ height: 400, borderRadius: '20px' }} >
                                    <ResponsiveContainer width="100%" height="100%">
                                        <AreaChart
                                            width={400}
                                            height={400}
                                            data={registros.map((registro => {

                                                registro['data_formatada'] = formatDataOnly(registro.data_hora)
                                                registro['Consumo Momentâneo'] = registro.pulso
                                                registro['amt'] = registro.pulso / 1000
                                                return registro;
                                            }))}
                                            margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
                                        >
                                            <Area
                                                type='monotone'
                                                dataKey={'Consumo Momentâneo'}
                                                stroke='#8884d8'
                                                activeDot={{ r: 8 }}
                                            />
                                            <CartesianGrid strokeDasharray='3 3' />
                                            <Tooltip />
                                            <YAxis tickFormatter={DataFormater} />
                                            <XAxis dataKey='data_formatada' />
                                            <Legend />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={2} >
                                <Paper elevation={5} style={{ width: '100%', height: 400, borderRadius: '20px', backgroundColor: 'DarkSlateBlue' }} >
                                    <Grid container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent='center'
                                        item xs={12}
                                        style={{ color: 'white' }}

                                    >
                                        <Grid container
                                            direction="row" spacing={3}
                                            alignItems="center"
                                            justifyContent='center'
                                            item xs={12}

                                        >
                                            <Typography style={{ fontSize: 28, fontWeight: 'bold', textAlign: 'right', paddingTop: 20 }}>
                                                Visão Geral
                                            </Typography>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span style={{ fontSize: 28, fontWeight: 'bold' }} >  {status.consumo_total.toLocaleString() + " kWh"}</span>
                                                <img alt="consumo" width={50} height={50}
                                                    src={consumo}
                                                />
                                                <figcaption style={{ fontWeight: 'bold', fontSize: 18 }}> Consumidos no total</figcaption>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span style={{ fontSize: 28, fontWeight: 'bold' }} >  {status.consumo_do_mes.toLocaleString() + " kWh"}</span>
                                                <img alt="consumo" width={50} height={50}
                                                    src={consumo}
                                                />
                                                <figcaption style={{ fontWeight: 'bold', fontSize: 18 }}> Consumidos neste mês ( </figcaption>
                                                <img alt="seta" height={20} width={20}
                                                    src={formatPorcentagem(status.consumo_do_mes, status.consumo_mes_passado) > 0 ? seta_cima : seta_baixo}
                                                />
                                                <span style={{ fontWeight: 'bold', fontSize: 18, color: formatPorcentagem(status.consumo_do_mes, status.consumo_mes_passado) > 0 ? 'green' : 'red' }}> {formatPorcentagem(status.consumo_do_mes, status.consumo_mes_passado) + "%"} </span>
                                                )

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span style={{ fontSize: 28, fontWeight: 'bold' }} >  {status.consumo_mes_passado.toLocaleString() + " kWh"}</span>
                                                <img alt="consumo" width={50} height={50}
                                                    src={consumo}
                                                />
                                                <figcaption style={{ fontWeight: 'bold', fontSize: 18 }}> Consumidos no mês passado</figcaption>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>

                }
            </div >


            <div >
                <Rodape />
            </div>
        </div >
    );
}