import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/styles';
import Chip from '@mui/material/Chip';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { format, isValid, parse } from 'date-fns';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";


import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));



export default function CadastrarPreCotacao() {






    const classes = useStyles();
    const history = useHistory();


    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);

    const { idOrdemCompra } = useParams();

    const [loading, setLoading] = useState(true);
    const today = new Date();

    // Formata a data de hoje para dd/mm/yyyy
    const formattedDate = format(today, 'dd/MM/yyyy');
    const [data, setData] = useState(formattedDate);

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/fornecedores/listarTodos", {
                headers: headers
            }).then(function (response) {
                setFornecedores(response.data);

                setLoading(false);

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }


    useEffect(() => {


        listarInformacoes();


    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };




    function returnDescricaoFornecedor(fornecedor) {

        var identificacao = fornecedor.tipo_fornecedor === 0 ? "CPF " : "CNPJ;"

        var valor = fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj;

        var nome = fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) :
            (fornecedor.nome_fantasia);

        var endereco = fornecedor.logradouro + ", nº " + fornecedor.numero + ", " + fornecedor.bairro + ", " + fornecedor.cidade + " " + fornecedor.estado + " CEP: " + fornecedor.cep;


        var texto = "ID: " + fornecedor.id_fornecedor + "\n" + identificacao + " " + valor + "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }



    async function salvar() {

        if (!fornecedorSelecionado) {
            alert("Selecione o Fornecedor");
            return;
        }

        const parsedDate = parse(data, 'dd/MM/yyyy', new Date());
        if (isValid(parsedDate)) {
        
        } else {
            alert("A data é invalida!");
            return;
        }

        const dataFormatada = format(parse(data, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');

        try {

            console.log("cadastrar chamado");
            const cadastro = {

                fornecedor: { id_fornecedor: fornecedorSelecionado.id_fornecedor },
                usuario: { id_usuario: Cookies.get("id_usuario") },
                data: dataFormatada,

            }



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));




            const response = await api.put('/v1/protected/ordensdecompras/ordemdecompra/cadastrarprecotacao/' + idOrdemCompra + "/" + id_usuario_logado, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Orçamento Cadastrado!");

                history.push({
                    pathname: "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra,
                })


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    }

    return (
        <div>
            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar </Button>
                </Grid>

                {loading
                    ?
                    <div></div>

                    :

                    <React.Fragment >


                        <Grid
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 20 }}
                            >



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ padding: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-fornecedor_cotacao1"
                                            options={fornecedores}
                                            disableCloseOnSelect
                                            getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor).replace(/<br \/>/g, '\n')}
                                            value={fornecedorSelecionado}
                                            getOptionSelected={(option, value) => true}

                                            onChange={(e, v) => setFornecedorSelecionado(v)}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((fornecedor) => {
                                                    const cotacaoText = returnDescricaoFornecedor(fornecedor).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Fornecedor " multiline rows={6} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoFornecedor(option) }} />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >
                                    <TextField
                                        label="Data"
                                        type="text"
                                        value={data}
                                        onChange={(e) => setData(e.target.value)}
                                    />
                                </Grid>





                            </Grid>

                        </Grid>




                    </React.Fragment>
                }

            </Container>



        </div >
    );
}


