
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ImportContacts from '@material-ui/icons/ImportContacts';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListSubheader from '@material-ui/core/ListSubheader';
import Cookies from 'js-cookie';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import AttachMoney from '@material-ui/icons/AttachMoney';
import LocalAtm from '@material-ui/icons/LocalAtm';
import Receipt from '@material-ui/icons/Receipt';
import { faTags, faUsers, faChargingStation, faHome, faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dashboard from '@material-ui/icons/Dashboard';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: '#1C1C1C',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    appBarShift: {


    },
    appBarDesktop: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShiftDesktop: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: '#1C1C1C',
        color: 'white',
        height: '100%',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transitionDuration: 0,
    },

    drawerPaperClose: {

        transitionDuration: 0,
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerPaperDesktop: {
        height: '100%',
        backgroundColor: '#1C1C1C',
        color: "white",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperCloseDesktop: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
}));

export default function MenuFornecedor(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);
    const [width, setWidth] = useState(0);
    const [openCollapse, setOpenCollapse] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function handleOpenSettings() {
        setOpenCollapse(!openCollapse);
    }


    function confirmSair() {
        Cookies.set('token', undefined);
        window.location.href = "/login"

    }



    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (largura < 900)
            setOpen(false);
        else
            setOpen(true);

        setWidth(largura)

    }


    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });



    useEffect(() => {

        checkDimenssoes();

    }, [width]);



    const toggleDrawer = (option) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (option) {
            handleDrawerClose();
        } else {
            handleDrawerOpen();
        }

    };


    const Lista = () => {
        return (
            <div >

                <List>
                    <ListItem button component="a" href="/visaogeralfornecedor" >
                        <ListItemIcon>
                            <Dashboard fontSize={'large'} style={{ color: "green" }} />
                        </ListItemIcon>
                        <ListItemText primary="Visão Geral" />
                    </ListItem>
                </List>

                <List>
                    <ListItem button component="a" href="/minhaconta" >
                        <ListItemIcon>
                            <ImportContacts fontSize={'large'} style={{ color: "MediumBlue" }} />
                        </ListItemIcon>
                        <ListItemText primary="Meus Dados" />
                    </ListItem>
                </List>

                <List>
                    <ListItem button component="a" href="/configuracoesfornecedor" >
                        <ListItemIcon>
                            <SettingsApplicationsIcon fontSize={'large'} style={{ color: "Chartreuse" }} />
                        </ListItemIcon>
                        <ListItemText primary="Configurações" />
                    </ListItem>
                </List>

                <List>
                    <ListItem button component="a" href="/meusclientes" >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faUsers} size="2x" style={{ color: "green" }} />
                        </ListItemIcon>
                        <ListItemText primary="Meus Clientes" />
                    </ListItem>
                </List>

                <ListItem button component="a" href="/controladorasfornecedor" >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faChargingStation} size="2x" style={{ color: "purple" }} />
                    </ListItemIcon>
                    <ListItemText primary="Estações de Recarga" />
                </ListItem>

                <ListItem button component="a" href="/sessoescarga" >
                    <ListItemIcon>

                        <FontAwesomeIcon icon={faBolt} size="2x" style={{ color: "yellow" }} />
                    </ListItemIcon>
                    <ListItemText primary="Sessões de Carga" />
                </ListItem>



                <ListItem button onClick={handleOpenSettings}>
                    <ListItemIcon>
                        <AttachMoney fontSize={'large'} style={{ color: "Gold" }} />
                    </ListItemIcon>
                    <ListItemText primary="Financeiro" />
                    {openCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component="a" href="/minhastarifas" >
                            <ListItemIcon>
                                <LocalAtm fontSize={'large'} style={{ color: "Gold" }} />
                            </ListItemIcon>
                            <ListItemText primary="Tarifas" />
                        </ListItem>
                        <ListItem button component="a" href="/faturamento" >
                            <ListItemIcon>
                                <Receipt fontSize={'large'} style={{ color: "Gold" }} />
                            </ListItemIcon>
                            <ListItemText primary="Faturamento" />
                        </ListItem>

                    </List>
                </Collapse>

                <ListItem button component="a" href="/cartoesfornecedor" >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faTags} size="2x" style={{ color: "blue" }} />
                    </ListItemIcon>
                    <ListItemText primary="Cartões" />
                </ListItem>



                <Divider />
                <List>
                    <ListSubheader inset style={{ color: 'white', fontSize: 18 }}>Opções</ListSubheader>
                    <ListItem button onClick={confirmSair}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize={'large'} style={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItem>
                </List>
            </div>
        )
    }


    const MenuMobile = () => {
        return (
            <>

                <CssBaseline />
                <AppBar position="absolute" style={{ top: 200 }} className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar id="target" className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="column"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="flex-start"

                        >
                            <Grid
                                container
                                direction="row"
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                                justifyContent="flex-start"
                                alignItems="flex-start"

                            >

                                {props.icone ?
                                        <a href="/visaogeralfornecedor" noWrap>
                                            <FontAwesomeIcon icon={faHome} size={"1x"} style={{ color: "LightGreen" }} /> /
                                        </a>
                                    :
                                    null
                                }
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>
                <SwipeableDrawer style={{ position: 'absolute', top: 275 }}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    variant="temporary"
                    disableBackdropTransition
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    anchor={'left'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    <Lista />
                </SwipeableDrawer>
            </>
        )
    }

    const MenuDesktop = () => {
        return (
            <>
                <CssBaseline />
                <AppBar position="absolute" style={{ top: 210 }} className={clsx(classes.appBarDesktop, open && classes.appBarShiftDesktop)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="flex-start"

                        >

                            {props.icone ?
                                <a href="/visaogeralfornecedor" noWrap>
                                    <FontAwesomeIcon icon={faHome} size={"1x"} style={{ color: "LightGreen" }} />  /
                                </a>
                                :
                                null
                            }
                            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                {props.titulo}
                            </Typography>
                            {props.subtitulo !== undefined ?
                                <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                    {"------ " + props.subtitulo}</Typography> : null
                            }
                            <Grid>


                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaperDesktop, !open && classes.drawerPaperCloseDesktop),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <Lista />

                </Drawer>
            </>
        )
    }

    return (
        <>
            {
                width < 900 ?
                    <MenuMobile /> : <MenuDesktop />
            }
        </>

    )

};