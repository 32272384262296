import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';

import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {
  DataGrid, ptBR, GridLinkOperator,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import Rodape from '../../../../../../components/Rodape';

import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';


const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .cabecalho_transparente': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      color: 'rgba(0, 0, 0, 0)',
    },
    '& .cabecalho_azul': {
      backgroundColor: 'rgba(0, 0, 255, 1)',
      color: 'white',
    },
    '& .cabecalho_verde': {
      backgroundColor: 'rgba(0, 100, 0, 1)',
      color: 'white',
    },
    '& .cabecalho_verde_claro': {
      backgroundColor: 'rgba(107,142,35, 1)',
      color: 'white',
    },
    '& .cabecalho_marrom_claro': {
      backgroundColor: 'rgba(184,134,11, 1)',
      color: 'white',
    },
    '& .cabecalho_verde_cyan': {
      backgroundColor: 'rgba(0,139,139, 1)',
      color: 'white',
    },
    '& .cabecalho_verde_dark_sea': {
      backgroundColor: 'rgba(60,179,113, 1)',
      color: 'white',
    },
    '& .cabecalho_marrom_escuro': {
      backgroundColor: 'rgba(139,69,19, 1)',
      color: 'white',
    },
    '& .cabecalho_marrom_chocolate': {
      backgroundColor: 'rgba(210,105,30, 1)',
      color: 'white',
    },
    '& .cabecalho_darkslate': {
      backgroundColor: 'rgba(47,79,79, 1)',
      color: 'white',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.neutro': {
      backgroundColor: '#363636',
      color: 'white',
      fontWeight: '600',
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

const ColorButtonGreen = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'green',
  borderColor: 'black',
  '&:hover': {
    backgroundColor: 'green',
    color: 'blue',
    borderColor: 'blue',
  },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
  borderColor: 'black',
  '&:hover': {
    backgroundColor: 'red',
    color: 'whit',
    borderColor: 'blue',
  },
}));

function novo() {
  window.location.href = "/gestao/operacional/frota/veiculos/cadastrarveiculo"

}

export default function Veiculos() {

  const [loading, setLoading] = useState(true);
  const [objetos, setObjetos] = useState(null);

  

 



  async function listar() {

    setLoading(true);

    try {
      const token = Cookies.get('token');

      const headers = {
        'Authorization': 'Bearer ' + token
      }


      await api.get("v1/protected/veiculos/listartodos", {
        headers: headers
      }).then(function (response) {
        setObjetos(response.data)

        console.log(" Objetos: " + response);
        setLoading(false);


      });


    } catch (_err) {

      console.log("Erro ao listar objetos: " + _err)
      setLoading(false);

    }

  }


  useEffect(() => {




    listar();


  }, []);




  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }



  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };


  const handleClickGerenciar = (event, cellValues) => {


    window.location.href = "/gestao/operacional/frotas/veiculos/gerenciar/" + cellValues.row.id_veiculo;


  };


  const columnsDataGrid = [
    {
      field: 'acoes',
      headerName: 'Ações',
      headerClassName: 'cabecalho_marrom_claro',
      sortable: false,
      width: 150,
      renderCell: (cellValues) => {
        return (
          <ColorButtonGreen
            variant="contained"
            color="primary"
            onClick={(event) => handleClickGerenciar(event, cellValues)}
          >
            Gerenciar
          </ColorButtonGreen>
        );
      },
    },
    {
      headerName: 'ID',
      field: 'id_veiculo',
      id: 1,
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Tipo Veículo',
      field: 'tipo_veiculo',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const tipoVeiculo = params.row.tipo_veiculo;
        switch (tipoVeiculo) {
          case 0:
            return 'Carro';
          case 1:
            return 'Moto';
          case 2:
            return 'Caminhão';
          case 3:
            return 'Van';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Tipo Chassi',
      field: 'tipo_chassi',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const tipoChassi = params.row.tipo_chassi;
        switch (tipoChassi) {
          case 0:
            return 'Passeio';
          case 1:
            return 'Utilitário';
          case 2:
            return 'Sedan';
          case 3:
            return 'Sport';
          case 4:
            return 'Caminhonete';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Tipo Motor',
      field: 'tipo_motor',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const tipoMotor = params.row.tipo_motor;
        switch (tipoMotor) {
          case 0:
            return 'Gasolina';
          case 1:
            return 'Álcool';
          case 2:
            return 'Diesel';
          case 3:
            return 'Flex';
          case 4:
            return 'Elétrico';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Tipo Eixos',
      field: 'tipo_eixos',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const tipoEixos = params.row.tipo_eixos;
        switch (tipoEixos) {
          case 0:
            return 'Tração Dianteira';
          case 1:
            return 'Tração Traseira';
          case 2:
            return '4x4';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Chassi',
      field: 'chassi',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Renavam',
      field: 'renavam',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Status Veículo',
      field: 'status_veiculo',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const statusVeiculo = params.row.status_veiculo;
        switch (statusVeiculo) {
          case 0:
            return 'INABILITADO';
          case 1:
            return 'DISPONÍVEL';
          case 2:
            return 'EM USO';
          case 3:
            return 'EM MANUTENÇÃO';
          case 4:
            return 'IRREGULAR';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Status Documentação',
      field: 'status_documentacao',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const statusDocumentacao = params.row.status_documentacao;
        switch (statusDocumentacao) {
          case 0:
            return 'IRREGULAR';
          case 1:
            return 'REGULAR';
          case 2:
            return 'VENCIDO';
          case 3:
            return 'BAIXADO';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Tipo Transmissão',
      field: 'tipo_transmissao',
      headerClassName: 'cabecalho_azul',
      valueGetter: (params) => {
        const tipoTransmissao = params.row.tipo_transmissao;
        switch (tipoTransmissao) {
          case 0:
            return 'Manual';
          case 1:
            return 'Automática';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'Placa',
      field: 'placa',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Marca',
      field: 'marca',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Modelo',
      field: 'modelo',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Ano Modelo',
      field: 'ano_modelo',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Ano Veículo',
      field: 'ano_veiculo',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Cor',
      field: 'cor',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Número de Ocupantes',
      field: 'num_ocupantes',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Capacidade Porta-Malas',
      field: 'capacidade_porta_malas',
      headerClassName: 'cabecalho_azul',
    },
    {
      headerName: 'Descrição',
      field: 'descricao',
      headerClassName: 'cabecalho_azul',
    },
  ];



  return (
    <div>

      <NavegadorGestao
        Operacional={true}
        Veiculos={true}
        corFundo={'white'} travado={true} corTexto={'black'}
      />
      <div style={{ padding: 5 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          item xs={12}
        >
          <Button style={{ margin: 5 }}
            variant="contained"
            color="primary"
            onClick={novo}
          >
            Novo Veículo
          </Button>
        </Grid>

        {loading ?
          <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
          </Skeleton>
          :

          <div>
            <div style={{ backgroundColor: 'white' }}>
              {objetos !== null && (
                <>
                  <DataGrid
                    pagination
                    checkboxSelection
                    rows={objetos}
                    getRowId={(row) => row.id_veiculo}
                    columns={columnsDataGrid}
                    disableColumnResize={false}
                    onCellClick={handleCellClick}
                    onRowClick={handleRowClick}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />



                </>
              )}
            </div>
          </div>
        }

      </div>


    </div>
  );
}



