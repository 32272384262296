import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


export default function CadastrarNovoItemPreCotacao() {


    const history = useHistory();

    const { idOrdemCompra } = useParams();
    const { idPreCotacao } = useParams();
    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);

    const [cotacaoSelecionada, setCotacaoSelecionada] = useState(null);
    const [cotacoes, setCotacoes] = useState(null);

    const [loading, setLoading] = useState(true);

    const [preCotacao, setPreCotacao] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [quantidade, setQuantidade] = useState('');



    async function listarCotacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordensdecompras/ordemdecompra/precotacao/listarporid/" + idPreCotacao, {
                headers: headers
            }).then(function (response_cot) {
                setPreCotacao(response_cot.data)


                api.get("/v1/protected/almoxarifado/insumos/listar", {
                    headers: headers
                }).then(function (response) {
                    setCotacoes(response.data)


                    setLoading(false);
                });


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Cotações: " + _err)

        }

    }

    function handleSetCotacao(cotacao) {
        setCotacaoSelecionada(cotacao);
    }


    useEffect(() => {
        listarCotacoes();


    }, []);


    function novoInsumo() {
        const url = `/gestao/operacional/almoxarifado/insumos/cadastrarinsumo/0/${preCotacao.fornecedor.id_fornecedor}`;
        window.open(url, '_blank');

    }


    async function salvar() {
        setBotaoSalvarClickado(true);



        if(!cotacaoSelecionada){
            alert("Selecione o Insumo/Cotação!");
            return;
        }


        const cotacoes = cotacaoSelecionada.cotacoes;
        const ultima_cotacao = cotacoes[cotacoes.length - 1];

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao: descricao,
                quantidade: quantidade,
                insumo: cotacaoSelecionada !== null ? { id_insumo: cotacaoSelecionada.id_insumo } : null,
                //cotacao: cotacaoSelecionada !== null ? { id_cotacao: ultima_cotacao.id_cotacao } : null,


            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.put("/v1/protected/ordensdecompras/ordemdecompra/editarprecotacao/" + idPreCotacao + "/" + id_usuario_logado + "/inseriritem", cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Item Adicionado na Cotação");
                history.push({
                    pathname: "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra + "/editarcotacao/" + idPreCotacao,
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }


    function returnDescricaoInsumoCotacao(insumo) {

        const cotacoes = insumo.cotacoes;
        const ultima_cotacao = cotacoes[cotacoes.length - 1];
        
        var texto =
            "Insumo: " +
            insumo.descricao +
            "<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
            "<br />Descrição: " + insumo.descricao;

            if(ultima_cotacao){
           texto += ( "<br />Código Fornecedor: " + (ultima_cotacao?.codigo_fornecedor?  ultima_cotacao.codigo_fornecedor : "") +
            "<br />Unidade: " +
            ultima_cotacao.unidade +
            "<br />Valor Unitário: " +
            ultima_cotacao.valor_unitario +
            "<br />Data Cotação: " +
            ultima_cotacao.data);
        }
        return texto;
    }



    useEffect(() => {

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                console.log('Página recuperou o foco');

               listarCotacoes();

            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };

    }, []);


    return (
        <div>
            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        disabled={botaoSalvarClickado}
                        onClick={salvar}
                    > Adicionar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                style={{ paddingTop: 5 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 5 }}
                                >

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={6}
                                        >
                                            <Autocomplete
                                                id="select-cotacao"
                                                options={cotacoes}
                                                disableCloseOnSelect
                                                getOptionLabel={(cotacao) => returnDescricaoInsumoCotacao(cotacao).replace(/<br \/>/g, '\n')}
                                                value={cotacaoSelecionada}
                                                onChange={(e, v) => handleSetCotacao(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((cotacao) => {
                                                        const cotacaoText = returnDescricaoInsumoCotacao(cotacao).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Insumo/Cotação " multiline minRows={8} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumoCotacao(option) }} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        align="center"
                                        item xs={6}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button style={{ margin: 5 }}
                                                variant="contained"
                                                color="primary"
                                                disabled={botaoSalvarClickado}
                                                onClick={novoInsumo}
                                            > +Insumo  </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>



                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="quantidade"
                                        label="Quantidade"
                                        required
                                        id="quantidade"
                                        value={quantidade}
                                        onChange={(e) => setQuantidade(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        required
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Grid>





                            </Grid>




                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


