import React, { useState, useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import capa_sobre from '../../assets/imgs/capa_sobre.jpg';
import capa_banner_sobre from '../../assets/imgs/capa_banner_sobre.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from '@material-ui/core/Grid';
import visao from '../../assets/imgs/visao.png';
import missao from '../../assets/imgs/missao.png';
import filosofia from '../../assets/imgs/filosofia.png';
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import capa1mobile from '../../assets/imgs/capa1mobile.png';
import area_atuacao from '../../assets/imgs/area_atuacao.png';

import './styles.scss';

const About = () => {

  const [width, setWidth] = useState(0);


  function checkDimenssoes() {


    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;



    setWidth(largura)

  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });



  useEffect(() => {



    checkDimenssoes();



  }, []);





  return (
    <div >
      <div style={{
        backgroundImage: width > 1200 ? `url(${capa_sobre})` : `url(${capa1mobile})`,
        backgroundSize: "contain",
        width: '100%',
        height: '100%',
      }} >

        <Navegador sobre={'underline'} corFundo={'rgba(255,255,255,1)'} travado={width > 900 ? true : false} corTexto={'black'} />
      </div>

      <div>
        <img alt="{banner}"
          src={capa_banner_sobre}
        />
      </div>

      <div style={{ backgroundColor: 'white' }}>
        <Grid
          container
          direction="row"
          item xs={12} sm={12} md={12} lg={12} xl={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={2} >
          </Grid>

          <Grid
            container
            direction="row"
            item xs={12} sm={12} md={12} lg={8} xl={8}
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >

            <Grid
              container
              direction="column"
              item xs={12} sm={12} md={4} lg={4} xl={4}
              justifyContent="center"
              alignItems="center"
              style={{ padding: 20 }}
            >


              <Grid item xs={3} >
                <img alt="filosofia"

                  src={filosofia}
                />
              </Grid>
              <Grid item xs={12} >
                <span style={{ textAlign: "justify", fontWeight: 'bold', fontSize: 26 }}>
                  <br></br>
                  Filosofia

                </span>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "justify" }} >

                <span style={{ textAlign: "justify" }}>
                  <br></br>
                  Contribuir para o desenvolvimento econômico nacional e a vida das pessoas através dos negócios.
                  Maximizar a capacidade das pessoas talentosas e criar um ambiente de respeito.  <br></br> <br></br>
                </span>
              </Grid>

            </Grid>

            <Grid
              container
              direction="column"
              item xs={12} sm={12} md={4} lg={4} xl={4}
              justifyContent="center"
              alignItems="center"
              style={{ padding: 20 }}
            >

              <Grid item xs={3} >
                <img alt="visao"
                  src={visao}
                />
              </Grid>
              <Grid item xs={12} >
                <span style={{ textAlign: "justify", fontWeight: 'bold', fontSize: 26 }}>
                  <br></br>
                  Visão
                </span>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "justify" }}>
                <span style={{ textAlign: "justify" }}>
                  <br></br>
                  Ser reconhecida pelos nossos clientes, parceiros e colaboradores como a melhor empresa de soluções tecnológicas e com uniformidade e excelência na prestação de serviços.
                  <br></br>
                  <br></br>
                </span>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              item xs={12} sm={12} md={4} lg={4} xl={4}
              justifyContent="center"
              alignItems="center"
              style={{ padding: 20 }}
            >


              <Grid item xs={3} >
                <img alt="missao"

                  src={missao}
                />
              </Grid>
              <Grid item xs={12} >
                <span style={{ textAlign: "justify", fontWeight: 'bold', fontSize: 26 }}>
                  <br></br>
                  Missão
                </span>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "justify" }} >

                <span style={{ textAlign: "justify" }}>
                  <br></br>
                  Compreender as necessidades dos nossos clientes proporcionando soluções e utilizando as tecnologias mais atuais e com menor impacto operacional, proporcionando maior eficiência e aumento de produtividade.
                </span>
              </Grid>

            </Grid>



          </Grid>
          <Grid item xs={2} >
          </Grid>
        </Grid>

      </div>


      <div style={{ backgroundColor: 'DarkOliveGreen' }}>

        <Grid
          container
          direction="row"
          item xs={12} sm={12} md={12} lg={12} xl={12}
          justifyContent="center"
          alignItems="center"
          style={{ padding: 20, textAlign: "center" }}
        >
          <Grid item xs={3} >
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >

            <Grid item xs={3} style={{ paddingTop: 20 }} >
              <FontAwesomeIcon icon={faTrophy} size="4x" style={{ color: "Khaki" }} />
            </Grid>

            <Grid item xs={12} >
              <span style={{ fontSize: 32, color: 'Khaki' }}>
                <br></br>
                Valores
                <br></br>
              </span>
            </Grid>

            <Grid item xs={12} >
              <span style={{ color: 'white' }}>
                <br></br>
                Integridade;
                <br></br>
                Profissionalismo;
                <br></br>
                Qualidade;
                <br></br>
                Transparência;
                <br></br>
                Inovação;
              </span>
              <span style={{ color: 'Khaki', fontWeight: 'bold' }}>
                <br></br><br></br>
                Princípios que orientam as ações dos membros do grupo: Integridade, Paixão, Respeito e Criatividade.
                <br></br>
              </span>
            </Grid>

          </Grid>

          <Grid item xs={3} >
          </Grid>
        </Grid>
      </div>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: 10 }}

      >

        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"#Onde Atuamos?"}  </span>
        </div>

      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center', margin: 0, paddingTop: 10, paddingInline: width < 500 ? 10 : 50 }}>
        <img alt={"area_atuacao"} style={{ textAlign: 'center' }} src={area_atuacao}></img>
      </div>


      <div >
        <Rodape />
      </div>
    </div >


  );
}

export default About;