import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';

import { useParams } from "react-router-dom";

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import Skeleton from '@material-ui/lab/Skeleton';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import central_alarme from '../../../../assets/imgs/central_alarme.png';


const drawerWidth = 240;



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'green',
        borderColor: 'green',
    },
}));


const ColorButtonOrange = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'orange',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'orange',
        color: 'blue',
        borderColor: 'yellow',
    },
}));

const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'green',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'black',
        borderColor: 'red',
    },
}));


const ColorButtonPurple = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'purple',
    borderColor: 'green',
    '&:hover': {
        backgroundColor: 'purple',
        color: 'black',
        borderColor: 'red',
    },
}));


const ColorButtonYellow = styled(Button)(({ theme }) => ({
    color: 'black',
    backgroundColor: 'yellow',
    borderColor: 'green',
    '&:hover': {
        backgroundColor: 'yellow',
        color: 'black',
        borderColor: 'red',
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function GerenciarReceptor() {

    const classes = useStyles();
    const { idReceptor } = useParams();
    const [zonas, setZonas] = useState([]);
    const [idZona, setIdZona] = useState(0);
    const [textoExclusaoZona, setTextoExclusaoZona] = useState(" Uma nova ação de excluir zona foi enviada ao receptor, aguarde alguns segundos para ser concluída!");
    const textoDesabilitarZona =(" Uma nova ação de desabilitar zona foi enviada ao receptor, aguarde alguns segundos para ser concluída!");

    const [loading, setLoading] = useState(true);

    const [time, setTime] = useState(30);


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [habilitarBotao, setHabilitarBotao] = useState(true);
    const [acaoEstadoCentral, setAcaoEstadoCentral] = useState(0);



    async function listarReceptoraZonas(idReceptor) {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/receptores/listar/" + idReceptor, {
                headers: headers
            }).then(function (response) {




                api.get("v1/protected/zonas/listarporreceptor/" + idReceptor, {
                    headers: headers
                }).then(function (response) {

                    var zonas = response.data;

                    setZonas(zonas);


                });

                setLoading(false);
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

            console.log("Erro ao listar a receptor: " + _err)

        }

    }




    const [alertOpen, setAlertOpen] = useState(false);


  

    useEffect(() => {


        listarReceptoraZonas(idReceptor);



    }, [idReceptor]);


    const [started, setStarted] = useState(false);




    useEffect(() => {
        let timer;
        if (started && time > 0) {
            timer = setInterval(() => {
                setTime((time) => time - 1);
            }, 1000);
        }

        if (time === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [time, started]);




    useEffect(() => {

        async function verificarPorExclucao(idZona) {

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.delete("v1/protected/zonas/deletarporidzona/" + idZona, {
                    headers: headers
                }).then(function (response) {

                    if (response.data === false) {
                        console.log('Zona não encontrada');
                        setTextoExclusaoZona("A zona foi excluída do receptor, pressione ok para continuar");

                        setHabilitarBotao(false);

                    } else {
                        setTextoExclusaoZona("Houve um erro ao excluír a zona, consulte o administrador do sistema!");

                    }


                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

                console.log("Erro ao verificar por exclusao de zona: " + _err)

            }

        }

        if (time === 0) {
            verificarPorExclucao(idZona)
        }
    }, [time, idZona]);



    const handleOpenAlert = () => {
        setTime(15);
        setStarted(true);
        setAlertOpen(true);
    };


    


    const handleCloseAlert = () => {

        setStarted(false);
        setAlertOpen(false);
        setIdZona(0);
        setLoading(true);
        listarReceptoraZonas(idReceptor);

    }



    




    const [alertOpenDesabilitarZona, setAlertOpenDesabilitarZona] = useState(false);
    const [startedDesabilitarZona, setStartedDesabilitarZona] = useState(false);
    const [timeDesabilitarZona, setTimeDesabilitarZona] = useState(30);
    const [botaoDesabilitarZona, setBotaoDesabilitarZona] = useState(true);


    useEffect(() => {
        let timer;
        if (startedDesabilitarZona && timeDesabilitarZona > 0) {
            timer = setInterval(() => {
                setTimeDesabilitarZona((timeDesabilitarZona) => timeDesabilitarZona - 1);
            }, 1000);
        }

        if (timeDesabilitarZona === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [timeDesabilitarZona, startedDesabilitarZona]);


    useEffect(() => {

       
        if (timeDesabilitarZona === 0) {
            setBotaoDesabilitarZona(false);
            listarReceptoraZonas(idReceptor);
        }
    }, [timeDesabilitarZona,idReceptor]);


    const handleOpenAlertDesabilitarZona = () => {
        setTimeDesabilitarZona(15);
        setStartedDesabilitarZona(true);
        setAlertOpenDesabilitarZona(true);
    };


    


    const handleCloseAlertDesabilitarZona = () => {

        setStartedDesabilitarZona(false);
        setAlertOpenDesabilitarZona(false);
        setIdZona(0);
        setLoading(true);
        listarReceptoraZonas(idReceptor);

    }

    async function handleButtonDesabilitar(id) {

        setIdZona(id);

        console.log("Funcao desabilitar chamada, id: " + id);
        if (window.confirm("Desabilitar a Zona Selecionada? \n\nCuidado, ao desabilitar uma zona a configuração aqui refletira no receptor, o que poderá ocasionar falhas no sistema de alarme, só desabilite se tiver certeza dessa operação! ")) {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }




            var result = await api.get("/v1/protected/acao/desabilitarzona/" + id, {
                headers: headers
            });
            console.log("resultado da exclusao: " + result.status);
            if (result.status === 200) {
                handleOpenAlertDesabilitarZona();


            } else {
                alert("Erro ao Desabilitar! Tente novamente");
            }
        }
    }



    async function handleRele(tipoTransmissor, id, rele) {



        const token = Cookies.get('token');

        const headers = {
            'Authorization': 'Bearer ' + token
        }

        let url = "";

        if(tipoTransmissor === 0){
            url = "v1/protected/acao/acionarreletransmissor/" + id + "/" + rele;
        }else{
            url = "v1/protected/acao/acionarrelectrl/" + id + "/" + rele;

        }

        var result = await api.get(url, {
            headers: headers
        });

        if (result.status === 200) {
            alert("Ação enviada ao banco de dados, aguarde alguns segundos pela resposta do servidor");



        } else {
            alert("Erro ao enviar ação ao banco de dados!");
        }
    }



    async function dispararZona(id) {


        if (window.confirm("Disparar a zona selecionada? \n\nUse essa opção para testes entre o receptor e a central de alarme, se a central estiver Armada, ela será disparada!")) {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }




            var result = await api.get("v1/protected/acao/dispararzona/" + id, {
                headers: headers
            });

            if (result.status === 200) {
                alert("Ação enviada ao banco de dados, aguarde alguns segundos pela resposta do servidor");



            } else {
                alert("Erro ao enviar ação ao banco de dados!");
            }
        }
    }



    async function excluir(id) {

        setIdZona(id);

        console.log("Funcao deletar chamada, id: " + id);
        if (window.confirm("Excluir a Zona Selecionada? \n\nCuidado, ao excluir uma zona a configuração aqui refletira no receptor, o que poderá ocasionar falhas no sistema de alarme, só exclua se tiver certeza dessa operação! ")) {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }




            var result = await api.delete("/v1/protected/acao/deletarzona/" + id, {
                headers: headers
            });
            console.log("resultado da exclusao: " + result.status);
            if (result.status === 200) {
                handleOpenAlert();


            } else {
                alert("Erro ao Excluir! Tente novamente");
            }
        }
    }




    const [alertOpenHabilitarZona, setAlertOpenHabilitarZona] = useState(false);
    const [startedHabilitarZona, setStartedHabilitarZona] = useState(false);
    const [timeHabilitarZona, setTimeHabilitarZona] = useState(30);
    const [botaoHabilitarZona, setBotaoHabilitarZona] = useState(true);
    const textoHabilitarZona = ("Uma nova ação de habilitar zona foi enviada ao receptor, aguarde alguns segundos para ser concluída!");

    useEffect(() => {
        let timer;
        if (startedHabilitarZona && timeHabilitarZona > 0) {
            timer = setInterval(() => {
                setTimeHabilitarZona((timeHabilitarZona) => timeHabilitarZona - 1);
            }, 1000);
        }

        if (timeHabilitarZona === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [timeHabilitarZona, startedHabilitarZona]);


    useEffect(() => {

       
        if (timeHabilitarZona === 0) {
            setBotaoHabilitarZona(false);
            listarReceptoraZonas(idReceptor);
        }
    }, [timeHabilitarZona,idReceptor]);


    const handleOpenAlertHabilitarZona = () => {
        setTimeHabilitarZona(15);
        setStartedHabilitarZona(true);
        setAlertOpenHabilitarZona(true);
    };


    


    const handleCloseAlertHabilitarZona = () => {

        setStartedHabilitarZona(false);
        setAlertOpenHabilitarZona(false);
        setIdZona(0);
        setLoading(true);
        listarReceptoraZonas(idReceptor);

    }
    

    async function handleButtonHabilitar(id) {

        setIdZona(id);

        console.log("Funcao habilitar chamada, id: " + id);
        if (window.confirm("Habilitar a Zona Selecionada? \n\nCuidado, ao habilitar uma zona a configuração aqui refletira no receptor, o que poderá ocasionar falhas no sistema de alarme, só habilite se tiver certeza dessa operação! ")) {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }




            var result = await api.get("/v1/protected/acao/habilitarzona/" + id, {
                headers: headers
            });
            console.log("resultado da habilitar: " + result.status);
            if (result.status === 200) {
                handleOpenAlertHabilitarZona();


            } else {
                alert("Erro ao Habilitar! Tente novamente");
            }
        }
    }



    async function mudarStatusCentral(id) {


        if (window.confirm("Mudar o status da central? \n\nUse essa opção para testes entre o receptor e a central de alarme!")) {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }




            var result = await api.get("v1/protected/acao/mudarstatuscentral/" + id, {
                headers: headers
            });

            if (result.status === 200) {
                alert("Ação enviada ao banco de dados, aguarde alguns segundos pela resposta do servidor");



            } else {
                alert("Erro ao enviar ação ao banco de dados!");
            }
        }
    }



    function returnDescricaoEquipamento(zona) {
        if (zona.tipo_transmissor === 0) {
            return "Transmissor Nº Série: " + zona.transmissor.numero_serie;
        } else if (zona.tipo_transmissor === 1) {
            return "Controlador de Acesso Nº Série: " + zona.controladoracesso.numero_serie;

        }
    }



    useEffect(() => {

            async function pesquisarBackGround() {
                try {



                    var url = "v1/protected/acao/listarestadocentral/porreceptor/" + idReceptor;
                    console.log("Url: " + url);
                    await api.get(url).then(function (response) {

                        setAcaoEstadoCentral(response.data);
                    });


                } catch (_err) {
                    // alert("Erro de Conexão, tente novamente mais tarde");

                }
            }

            pesquisarBackGround();


    }, [idReceptor]);


    useEffect(() => {

        const interval = setInterval(() => {
            async function pesquisarBackGround() {
                try {



                    var url = "v1/protected/acao/listarestadocentral/porreceptor/" + idReceptor;
                    console.log("Url: " + url);
                    await api.get(url).then(function (response) {

                        setAcaoEstadoCentral(response.data);
                    });


                } catch (_err) {
                    // alert("Erro de Conexão, tente novamente mais tarde");

                }
            }

            pesquisarBackGround();


        }, 30000);

        return () => clearInterval(interval);

    }, [idReceptor]);



    function formatData(data) {
        var dataCtr = moment(data, "YYYY-MM-DD hh:mm:ss");
        return dataCtr.format("DD/MM/YYYY ") + " às " + dataCtr.format("HH:mm:ss");
    }


    function handleButtonClick(link) {
        window.open(link, '_blank');
    }


    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Gerenciar Receptor"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >


                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    direction='row'
                                    style={{ margin: 20 }}

                                >
                                    <Grid container
                                        item xs={2} sm={2} md={2} lg={2} xl={2}
                                        alignItems='flex-end'
                                    >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                            style={{ width: '100%', textAlign: 'flex-start' }}
                                        >
                                            <img alt="img1"

                                                src={central_alarme}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid
                                        container
                                        direction='row'
                                        item xs={4} sm={4} md={4} lg={4} xl={4}
                                        alignItems='flex-end'
                                    >

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                            style={{ width: '100%', padding: 20 }}>

                                            <span style={{ fontSize: '18px' }}>Status Atual:</span>
                                            <span style={{ fontSize: '22px', fontWeight: 'bold', color: acaoEstadoCentral.status_central === 1 ? "red" : "green" }}>  {" "} {acaoEstadoCentral.status_central === 1 ? "DESARMADA" : "ARMADA"}</span>
                                            <br></br>
                                            <span style={{ fontSize: '18px' }}>Última atualização:</span>

                                            <span style={{ fontSize: '22px', fontWeight: 'bold' }} >{formatData(acaoEstadoCentral.data_hora_requisicao)}</span>



                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                            style={{ padding: 20 }}>
                                            {

                                                acaoEstadoCentral.status_central === 1 ?
                                                    <ColorButtonGreen variant="contained" color="primary"
                                                        onClick={() => mudarStatusCentral(idReceptor)}

                                                    >
                                                        Armar Central
                                                    </ColorButtonGreen>
                                                    :
                                                    <ColorButtonRed variant="contained" color="primary"
                                                        onClick={() => mudarStatusCentral(idReceptor)}

                                                    >
                                                        Desarmar Central
                                                    </ColorButtonRed>
                                            }


                                        </Grid>

                                    </Grid>


                                </Grid>





                                <Box style={{ backgroundColor: 'Silver', width: '100%' }}>
                                    <AppBar position="static" >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"

                                        >
                                            <Tab label="Zonas do Receptor" style={{ backgroundColor: '#1C1C1C' }} />

                                        </Tabs>
                                    </AppBar>
                                </Box>
                                <SwipeableViews

                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <div value={value} index={0} >
                                        {
                                            loading ?
                                                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                                                </Skeleton>
                                                :

                                                <div>
                                                    <Grid container
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        item xs={12}
                                                    >

                                                        {zonas.map((zona) =>
                                                            <Grid container
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                item xs={3}

                                                                component={Paper} elevation={6} className={"Paper"}
                                                                key={zona.id_zona} style={{ backgroundColor: '#1C1C1C', color: 'white', margin: 20, padding: 20, borderRadius: '10px' }}
                                                            >

                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                                                    style={{ padding: 5 }}
                                                                >


                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                        <div>
                                                                            <span> Número da Zona:  {zona.numero_zona}  </span>
                                                                            <br></br>
                                                                            <span> Tipo da Zona:  {zona.tipo_zona === 0 ? "Com Fio" : "Sem Fio"}  </span>
                                                                            <br></br>
                                                                            <span> Configuração da Zona:  {zona.zona_dupla === 0 ? "Baixa, 2.2k" : "Alta, 3.3k"}  </span>
                                                                            <br></br>
                                                                            <span> {returnDescricaoEquipamento(zona)}      </span>
                                                                            <br></br>
                                                                            <span> Canal: {zona.canal_transmissor}  </span>
                                                                            <br></br>
                                                                        </div>
                                                                    </Grid>

                                                                    <Grid
                                                                        container
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        item xs={12} sm={12} md={12} lg={12} xl={12}
                                                                        style={{ padding: 10 }}
                                                                    >

                                                                        <ColorButtonBlue style={{ margin: 5 }}
                                                                            disabled={zona.status === 0 ? true : false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            href={"/configurarzona/" + zona.id_zona}
                                                                        >
                                                                            Configurar
                                                                        </ColorButtonBlue>

                                                                        <ColorButtonRed style={{ margin: 5 }}
                                                                            disabled={zona.status === 0 ? true : false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => excluir(zona.id_zona)} >
                                                                            Excluir
                                                                        </ColorButtonRed>


                                                                        <ColorButtonOrange style={{ margin: 5 }}
                                                                            disabled={zona.status === 0 ? true : false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => dispararZona(zona.id_zona)}
                                                                        >
                                                                            Disparar Zona
                                                                        </ColorButtonOrange>

                                                                        {zona.status === 1 ?
                                                                            <ColorButtonPurple style={{ margin: 5 }}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => handleButtonDesabilitar(zona.id_zona)}
                                                                            >
                                                                                Desabilitar
                                                                            </ColorButtonPurple>
                                                                            :
                                                                            <ColorButtonPurple style={{ margin: 5 }}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => handleButtonHabilitar(zona.id_zona)}
                                                                            >
                                                                                Habilitar
                                                                            </ColorButtonPurple>
                                                                        }


                                                                        <ColorButtonYellow style={{ margin: 5 }}
                                                                            variant="contained"
                                                                            disabled={zona.status === 0 ? true : false}
                                                                            color="primary"
                                                                            onClick={() => handleButtonClick(zona.link_local)}
                                                                        >
                                                                            Link Interno
                                                                        </ColorButtonYellow>


                                                                        <ColorButtonYellow style={{ margin: 5 }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            disabled={ (zona.status === 0 || zona.tipo_transmissor === 1 ) ? true : false}
                                                                            onClick={() => handleRele(zona.tipo_transmissor , zona.tipo_transmissor === 0 ?  zona.transmissor.id_transmissor : zona.controladoracesso.id_controlador_acesso, 1)}
                                                                        >
                                                                            Acionar Rele 1
                                                                        </ColorButtonYellow>

                                                                        <ColorButtonYellow style={{ margin: 5 }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            disabled={(zona.status  === 0 || zona.tipo_transmissor === 1 ) ? true : false}
                                                                            onClick={() => handleRele(zona.tipo_transmissor , zona.tipo_transmissor === 0 ?  zona.transmissor.id_transmissor : zona.controladoracesso.id_controlador_acesso, 2)}
                                                                        >
                                                                            Acionar Rele 2
                                                                        </ColorButtonYellow>







                                                                    </Grid>


                                                                </Grid>

                                                            </Grid>


                                                        )}
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            item xs={12}
                                                        >
                                                            <ColorButtonGreen style={{ marginTop: 20 }}
                                                                variant="contained"
                                                                color="primary"
                                                                href={"/cadastrarzona/" + idReceptor}
                                                            >
                                                                Nova Zona
                                                            </ColorButtonGreen>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                        }



                                    </div>
                                </SwipeableViews>

                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >

            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle id="confirm-dialog">{ }</DialogTitle>
                <DialogContent>
                    <div>
                        <AppBar sx={{ position: 'relative' }}>

                            <Grid container spacing={1} style={{ padding: 20 }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span>
                                    {textoExclusaoZona}
                                </span>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    disable={habilitarBotao}
                                    onClick={handleCloseAlert}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {time > 0 ? time : "OK"}
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >


            <Dialog
                fullScreen={false}
                open={alertOpenDesabilitarZona}
                onClose={handleCloseAlertDesabilitarZona}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle id="confirm-dialog">{ }</DialogTitle>
                <DialogContent>
                    <div>
                        <AppBar sx={{ position: 'relative' }}>

                            <Grid container spacing={1} style={{ padding: 20 }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span>
                                    {textoDesabilitarZona}
                                </span>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    disable={botaoDesabilitarZona}
                                    onClick={handleCloseAlertDesabilitarZona}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {timeDesabilitarZona > 0 ? timeDesabilitarZona : "OK"}
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >



            <Dialog
                fullScreen={false}
                open={alertOpenHabilitarZona}
                onClose={handleCloseAlertHabilitarZona}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle id="confirm-dialog">{ }</DialogTitle>
                <DialogContent>
                    <div>
                        <AppBar sx={{ position: 'relative' }}>

                            <Grid container spacing={1} style={{ padding: 20 }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span>
                                    {textoHabilitarZona}
                                </span>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    disable={botaoHabilitarZona}
                                    onClick={handleCloseAlertHabilitarZona}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {timeHabilitarZona > 0 ? timeHabilitarZona : "OK"}
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >



        </div >
    );
}


