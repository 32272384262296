import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';

import Skeleton from '@material-ui/lab/Skeleton';


import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';

import {

    Tooltip
} from '@mui/material';
import {
    MaterialReactTable,

} from 'material-react-table';



import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import Rodape from '../../../../../../components/Rodape';
import { useParams } from "react-router-dom";

import { Button } from "@material-ui/core";
import { styled } from '@material-ui/styles';




const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


export default function Cotacoes() {


    const { idInsumo } = useParams();


    const [loading, setLoading] = useState(true);
    const [dadosInsumo, setDadosInsumo] = useState();;

    const [cotacoes, setCotacoes] = useState([]);

    const [colunas, setColunas] = useState(null);


    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/almoxarifado/insumos/listarporid/" + idInsumo, {
                headers: headers
            }).then(function (response) {

                setDadosInsumo(response.data)

                api.get("v1/protected/almoxarifado/cotacoes/listarporidinsumo/" + idInsumo, {
                    headers: headers
                }).then(function (response) {

                    console.log(JSON.stringify(response.data));

                    setCotacoes(response.data);


                    const colunasTable = [

                        {
                            accessorKey: 'id_cotacao', //normal accessorKey
                            header: 'ID',
                            size: 50,
                        },
                        {
                            accessorKey: 'data',
                            header: 'Data',
                            size: 100,

                        },

                        {
                            accessorKey: 'fornecedor',
                            header: 'Fornecedor',
                            size: 300,
                            Cell: ({ renderedCellValue }) => {

                                const text = `ID: ${renderedCellValue.id_fornecedor}\n${renderedCellValue.tipo_fornecedor === 0 ? renderedCellValue.nome + ' ' + renderedCellValue.sobrenome : renderedCellValue.nome_fantasia}\nCPF/CNPJ: ${renderedCellValue.tipo_fornecedor === 0 ? renderedCellValue.cpf : renderedCellValue.cnpj}`;

                                return text;
                            },

                        },



                        {
                            accessorKey: 'valor_unitario',
                            header: 'Valor Unitário',
                            size: 150,
                            Cell: ({ renderedCellValue }) => {
                                return parseFloat(renderedCellValue).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                            },

                        },

                        {
                            accessorKey: 'descricao',
                            header: 'Descrição',
                            size: 500,

                        },


                    ];

                    setColunas(colunasTable);


                    setLoading(false);

                });





            });




        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {




        listar();


    }, []);

    function novo() {
        window.location.href = "/gestao/operacional/almoxarifado/cotacoes/cadastrarcotacao/" + idInsumo;

    }



    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Cotacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>




                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <div>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="flex-start"
                            item xs={12}
                            style={{ padding: 5 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={6}
                                style={{ padding: 5 }}
                            >

                                <a href="/gestao/operacional/almoxarifado/insumos/listar" style={{ fontSize: 14, color: 'blue', marginRight: '5px' }}>{"< Insumos"}</a>
                                <a style={{ fontSize: 12, color: 'black', marginLeft: '5px', padding: 5 }}>{" - Insumo " + idInsumo + " - "}</a>
                                <a style={{ fontSize: 18, color: 'black', marginLeft: '5px', padding: 5 }}>{dadosInsumo.descricao}</a>

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={6}
                            >
                                <Button style={{ margin: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={novo}
                                >
                                    Nova Cotação
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ backgroundColor: 'white' }}>
                            {cotacoes !== null && (



                                <MaterialReactTable

                                    columns={colunas}
                                    data={cotacoes}
                                    enableTopToolbar={false}
                                    enableBottomToolbar={false}
                                    enablePagination={false}
                                    enableColumnFilters={false}
                                    enableColumnActions={false}
                                    sortable={false}
                                    enableRowActions={false}
                                    enableSorting={false}
                                    enableRowSelection={false}
                                    color={'black'}
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_cotacao}
                                    initialState={{
                                        pagination: { pageSize: 20, pageIndex: 0 },
                                        showGlobalFilter: true,
                                        color: 'black'
                                    }}
                                    muiTableContainerProps={{ sx: { maxHeight: "3000px", color: 'black' } }}
                                    muiTableBodyRowProps={{
                                        sx: {
                                            height: 40,
                                            color: 'black' // Define a cor do texto para preto
                                        },
                                    }}
                                    enableColumnFilterModes={false}
                                    enableExpandAll={true}
                                    enableColumnPinning={true}
                                    enableColumnResizing={true}
                                    muiPaginationProps={{
                                        rowsPerPageOptions: [20, 50, 100],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                    muiTablePaperProps={({ table }) => ({
                                        style: {
                                            zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                            color: 'black',
                                        },
                                    })}




                                />


                            )}



                        </div>
                    </div>
                }
            </div>

        </div>
    );
}



