import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';


import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, } from '@mui/material';
import {
    MaterialReactTable,

} from 'material-react-table';
import { Card, CardContent } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';


import DeleteIcon from '@mui/icons-material/Delete';


import { format, isValid, parse } from 'date-fns';

import AppBar from '@mui/material/AppBar';
import {
    Box,
    Tooltip
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import { Stack } from '@mui/material';

import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';

import miniatura_pdf from '../../../../../../assets/imgs/miniatura_pdf.png';
import office from '../../../../../../assets/imgs/office.png';
import comprovante from '../../../../../../assets/imgs/comprovante.png';

import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

const drawerWidth = 240;




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));

const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};




export default function GerenciarLancamento() {


    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    const history = useHistory();

    const [metodosPagamento, setMetodosPagamento] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [instituicoesBancarias, setInstituicoesBancarias] = useState([]);



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const [isUpdate, setIsUpdate] = useState(false);
    const [inUpdate, setInUpdate] = useState(null);

    const [isUpdatePagamento, setIsUpdatePagamento] = useState(false);
    const [inUpdatePagamento, setInUpdatePagamento] = useState(null);



    function handleClickEditar(row) {
        row.data_vencimento = moment(row.data_vencimento).format('DD/MM/YYYY');
        setInUpdate(row);
        console.log("id parcela " + row.id_parcela);
        setIsUpdate(true);

    }


    function handleClickEditarPagamento(row) {

        row.data_pagamento = moment(row.data_pagamento).format('DD/MM/YYYY');
        setInUpdatePagamento(row);

        setIsEditingPagamento(false);
        setIsUpdatePagamento(true);

    }


    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [objetoGerenciado, setObjetoGerenciado] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const [newRow, setNewRow] = useState(createNewRow());


    const [isEditingPagamento, setIsEditingPagamento] = useState(false);
    const [newPagamento, setNewPagamento] = useState(createNewPagamento());


    function createNewRow() {
        const today = moment().format('DD/MM/YYYY');
        return {
            valor: 0.0,
            data_vencimento: today,
        };
    }

    const handleAddRow = () => {
        setNewRow(createNewRow());
        setIsEditing(true);

    };



    function createNewPagamento() {
        const today = moment().format('DD/MM/YYYY');

        let pagador_tipo = 0;
        let recebedor_tipo = 0;

        let dados = {
            valor: 0.0,
            data_pagamento: today,
            status: 0,

        };


        if (objetoGerenciado.tipo === 0) {
            //despesa
            pagador_tipo = 1; //uma instituicao bancaria e o pagador
            recebedor_tipo = 2;//um fornecedor é o recebedor


            //recebedor do lancamento é o recebedor de pagamentos
            dados.fornecedor_recebedor = objetoGerenciado.fornecedor;

        } else if (objetoGerenciado.tipo === 1) {
            //receita
            pagador_tipo = 0;// um cliente é o pagador
            recebedor_tipo = 1;//uma ib é o recebedor

            dados.cliente_pagador = objetoGerenciado.cliente;
        } else if (objetoGerenciado.tipo === 2) {
            //emprestimo
        } else if (objetoGerenciado.tipo === 3) {
            //transferencia
        }

        dados.tipo_pagador = pagador_tipo;
        dados.tipo_recebedor = recebedor_tipo;





        return dados;
    }

    async function handleAddPagamento() {

        if (!isEditingPagamento) {

            try {

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/financeiro/metodospagamento/listartodos", {
                    headers: headers
                }).then(function (response) {

                    setMetodosPagamento(response.data)


                    //listar clientes
                    api.get("v1/protected/clientes/listarTodos", {
                        headers: headers
                    }).then(function (response) {
                        setClientes(response.data)


                        api.get("v1/protected/financeiro/instituicaobancaria/listartodos", {
                            headers: headers
                        }).then(function (response) {
                            setInstituicoesBancarias(response.data)


                            //buscar fornecedores
                            api.get("v1/protected/fornecedores/listarTodos", {
                                headers: headers
                            }).then(function (response) {



                                setFornecedores(response.data);
                                console.log(JSON.stringify(response.data));
                                setNewPagamento(createNewPagamento());
                                setIsEditingPagamento(true);


                            });




                        });


                    });



                });
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar informações: " + _err)

            }
        } else
            return;

    };


    async function handleClickDelete(row) {

        try {

            const confirmacao = window.confirm("Tem certeza que deseja excluir esta parcela?");

            if (confirmacao) {
                var id_user = Cookies.get('id_usuario');

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.delete("v1/protected/financeiro/lancamentos/parcelas/excluir/" + row.id_parcela + "/" + id_user, {
                    headers: headers
                }).then(function (response) {

                    const deletado = response.data;
                    if (deletado) {
                        alert("Parcela Excluída!");
                        listarInformacoes();

                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }



                });
            }
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro na tentativa de exclusão: " + _err)

        }
    };


    async function handleClickDeletePagamento(row) {

        try {

            const confirmacao = window.confirm("Tem certeza que deseja excluir este pagamento?");

            if (confirmacao) {
                var id_user = Cookies.get('id_usuario');

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.delete("v1/protected/financeiro/lancamentos/pagamentos/excluir/" + row.id_pagamento + "/" + id_user, {
                    headers: headers
                }).then(function (response) {

                    const deletado = response.data;
                    if (deletado) {
                        alert("Pagamento Excluído!");
                        listarInformacoes();

                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }



                });
            }
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro na tentativa de exclusão: " + _err)

        }
    };


    const handleCancel = () => {
        setIsEditing(false);
        setNewRow(createNewRow());
    };



    const handleCancelUpdate = () => {
        setIsUpdate(false);
        setInUpdate([]);
    };

    const { idLancamento } = useParams();

    const [tipo, setTipo] = useState(0);
    const [dataLancamento, setDataLancamento] = useState('');
    const [centroCusto, setCentroCusto] = useState(null);
    const [fornecedor, setFornecedor] = useState(null);
    const [conta, setConta] = useState(null);
    const [contas, setContas] = useState([]);
    const [cliente, setCliente] = useState(null);

    const [centrosCusto, setCentrosCusto] = useState([]);

    const [fornecedores, setFornecedores] = useState([]);

    const [height, setHeight] = useState(0);

    const [observacao, setObservacao] = useState('');
    const [descricao, setDescricao] = useState('');
    const [identificacao, setIdentificacao] = useState('');
    const [parcelas, setParcelas] = useState([]);
    const [pagamentos, setPagamentos] = useState([]);



    const onDropArquivo1 = useCallback(async (acceptedFiles) => {
        // Valida o tipo de arquivo
        // Valida o tipo de arquivo

        console.log("Id parcela in OnDrop: " + inUpdate.id_parcela);


        try {
            const formData = new FormData();
            formData.append('arquivos', acceptedFiles[0]);
            formData.append('extensao', acceptedFiles[0].name.split('.').pop());

            const response = await api.post('/v1/protected/financeiro/lancamentos/parcelas/anexararquivo/' + inUpdate.id_parcela + '/' + idLancamento, formData);

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                console.log("arquivo foi enviado");

                listarInformacoes();
                setIsEditing(false);
                setNewRow(createNewRow());

            } else {
                // Lógica a ser executada caso ocorra algum erro durante o salvamento
                console.log("Erro no cadastro_salvo");

            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
        }
    }, [inUpdate, idLancamento, listarInformacoes, setIsEditing, setNewRow]);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        //accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });



    const onDropArquivo2 = useCallback(async (acceptedFiles) => {
        // Valida o tipo de arquivo
        // Valida o tipo de arquivo

        console.log("Id parcela in OnDrop: " + inUpdatePagamento.id_pagamento);



        try {
            const formData = new FormData();
            formData.append('arquivos', acceptedFiles[0]);
            formData.append('extensao', acceptedFiles[0].name.split('.').pop());

            const response = await api.post('/v1/protected/financeiro/lancamentos/pagamentos/anexararquivo/' + inUpdatePagamento.id_pagamento + '/' + idLancamento, formData);

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                console.log("arquivo foi enviado");

                listarInformacoes();
                setIsEditingPagamento(false);
                setIsUpdatePagamento(false);


            } else {
                // Lógica a ser executada caso ocorra algum erro durante o salvamento
                console.log("Erro no cadastro_salvo");

            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
        }
    }, [inUpdatePagamento, idLancamento, listarInformacoes, setIsEditingPagamento, setIsUpdatePagamento]);


    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        //accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });

    async function handleSaveRow() {
        try {

            var id_user = Cookies.get('id_usuario');

            const parsedDate = parse(newRow.data_vencimento, 'dd/MM/yyyy', new Date());
            if (isValid(parsedDate)) {

            } else {
                alert("A data é invalida!");
                return;
            }

            const dataFormatada = format(parse(newRow.data_vencimento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');


            const cadastro = {

                id_lancamento: idLancamento,
                usuario_criador: { id_usuario: id_user, },
                valor: newRow.valor,
                data_vencimento: dataFormatada,


            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/financeiro/lancamentos/parcelas/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                listarInformacoes();
                setIsEditing(false);
                setNewRow(createNewRow());

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
        }

    };


    async function handleSavePagamento() {
        try {

            var id_user = Cookies.get('id_usuario');

            console.log("Tipo pagador: " + newPagamento.tipo_pagador);


            const parsedDate = parse(newPagamento.data_pagamento, 'dd/MM/yyyy', new Date());
            if (isValid(parsedDate)) {

            } else {
                alert("A data é invalida!");
                return;
            }

            const dataFormatada = format(parse(newPagamento.data_pagamento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');



            if (!newPagamento.metodo_pagamento) {
                alert("Selecione o Metodo de Pagamento!");
                console.log("Metodo: " + newPagamento.metodo_pagamento);
                return;
            }


            if (newPagamento.tipo_recebedor === 2) {
                //um fornecedor esta recebendo, precisa especificar a conta
                if (!newPagamento.conta_bancaria) {
                    alert("Selecione a Conta Bancária!");
                    return;
                }
            }




            const cadastro = {

                id_lancamento: idLancamento,
                usuario_criador: { id_usuario: id_user, },
                valor: newPagamento.valor,
                data_pagamento: dataFormatada,
                metodo_pagamento: newPagamento.metodo_pagamento,
                tipo_pagador: newPagamento.tipo_pagador,
                tipo_recebedor: newPagamento.tipo_recebedor,
                status: newPagamento.status,
                conta_bancaria: newPagamento.conta_bancaria,


            };


            if (newPagamento.tipo_pagador === 0 && newPagamento.cliente_pagador !== null) {

                cadastro.cliente_pagador = newPagamento.cliente_pagador;
            } else if (newPagamento.tipo_pagador === 1 && newPagamento.instituicao_bancaria_pagador !== null) {
                console.log("é um ib que ira pagar!");

                cadastro.instituicao_bancaria_pagador = newPagamento.instituicao_bancaria_pagador;
                console.log("A ie é : " + newPagamento.instituicao_bancaria_pagador);

                console.log("A ie é : " + cadastro.instituicao_bancaria_pagador);

            } else {
                alert("Selecione o Pagador!");
                return;
            }



            if (newPagamento.tipo_recebedor === 0 && newPagamento.cliente_recebedor !== null) {
                cadastro.cliente_recebedor = newPagamento.cliente_recebedor;
            } else if (newPagamento.tipo_recebedor === 1 && newPagamento.instituicao_bancaria_recebedor !== null) {
                cadastro.instituicao_bancaria_recebedor = newPagamento.instituicao_bancaria_recebedor;
            } else if (newPagamento.tipo_recebedor === 2 && newPagamento.fornecedor_recebedor !== null) {
                cadastro.fornecedor_recebedor = newPagamento.fornecedor_recebedor;
            } else {
                alert("Selecione o Recebedor!");
                return;
            }



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/financeiro/lancamentos/pagamentos/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                listarInformacoes();
                setIsEditingPagamento(false);
                setNewPagamento(createNewPagamento());

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
        }

    };




    async function handleUpdatePagamento() {
        try {


            console.log("Tipo pagador: " + inUpdatePagamento.tipo_pagador);


            const parsedDate = parse(inUpdatePagamento.data_pagamento, 'dd/MM/yyyy', new Date());
            if (isValid(parsedDate)) {

            } else {
                alert("A data é invalida!");
                return;
            }

            const dataFormatada = format(parse(inUpdatePagamento.data_pagamento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');



            if (!inUpdatePagamento.metodo_pagamento) {
                alert("Selecione o Metodo de Pagamento!");
                console.log("Metodo: " + inUpdatePagamento.metodo_pagamento);
                return;
            }


            if (inUpdatePagamento.tipo_recebedor === 2) {
                //um fornecedor esta recebendo, precisa especificar a conta
                if (!inUpdatePagamento.conta_bancaria) {
                    alert("Selecione a Conta Bancária!");
                    return;
                }
            }





            const cadastro = {

                valor: inUpdatePagamento.valor,
                data_pagamento: dataFormatada,
                metodo_pagamento: inUpdatePagamento.metodo_pagamento,
                tipo_pagador: inUpdatePagamento.tipo_pagador,
                tipo_recebedor: inUpdatePagamento.tipo_recebedor,
                status: inUpdatePagamento.status,
                conta_bancaria: inUpdatePagamento.conta_bancaria,


            };


            if (inUpdatePagamento.tipo_pagador === 0 && inUpdatePagamento.cliente_pagador !== null) {

                cadastro.cliente_pagador = inUpdatePagamento.cliente_pagador;
            } else if (inUpdatePagamento.tipo_pagador === 1 && inUpdatePagamento.instituicao_bancaria_pagador !== null) {
                console.log("é um ib que ira pagar!");

                cadastro.instituicao_bancaria_pagador = inUpdatePagamento.instituicao_bancaria_pagador;
                console.log("A ie é : " + inUpdatePagamento.instituicao_bancaria_pagador);

                console.log("A ie é : " + cadastro.instituicao_bancaria_pagador);

            } else {
                alert("Selecione o Pagador!");
                return;
            }



            if (inUpdatePagamento.tipo_recebedor === 0 && inUpdatePagamento.cliente_recebedor !== null) {
                cadastro.cliente_recebedor = inUpdatePagamento.cliente_recebedor;
            } else if (inUpdatePagamento.tipo_recebedor === 1 && inUpdatePagamento.instituicao_bancaria_recebedor !== null) {
                cadastro.instituicao_bancaria_recebedor = inUpdatePagamento.instituicao_bancaria_recebedor;
            } else if (inUpdatePagamento.tipo_recebedor === 2 && inUpdatePagamento.fornecedor_recebedor !== null) {
                cadastro.fornecedor_recebedor = inUpdatePagamento.fornecedor_recebedor;
            } else {
                alert("Selecione o Recebedor!");
                return;
            }



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/financeiro/lancamentos/pagamentos/atualizar/' + inUpdatePagamento.id_pagamento + '/' + idLancamento, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                listarInformacoes();
                setIsEditingPagamento(false);
                setIsUpdatePagamento(false);

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
        }

    };


    const handleCancelSavePagamento = () => {
        setIsEditingPagamento(false);
        setIsUpdatePagamento(false);
        setNewPagamento(createNewPagamento());

    };





    async function handleUpdateRow() {
        try {

            var id_user = Cookies.get('id_usuario');

            const parsedDate = parse(inUpdate.data_vencimento, 'dd/MM/yyyy', new Date());
            if (isValid(parsedDate)) {

            } else {
                alert("A data é invalida!");
                return;
            }

            const dataFormatada = format(parse(inUpdate.data_vencimento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');


            const cadastro = {


                usuario_criador: { id_usuario: id_user, },
                valor: inUpdate.valor,
                data_vencimento: dataFormatada,
                observacao: inUpdate.observacao,
                descricao: inUpdate.descricao,
                identificador: inUpdate.identificador,


            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/financeiro/lancamentos/parcelas/atualizar/' + inUpdate.id_parcela + '/' + idLancamento, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                listarInformacoes();
                setIsUpdate(false);
                setInUpdate([]);

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
        }






    };

    const [valorTotal, setValorTotal] = useState(0.0);
    const [valorTotalPagamentos, setValorTotalPagamentos] = useState(0.0);

    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });





    const handleChangeTipo = (event) => {
        setTipo(event.target.value);
    };


    const [columnsParcelas, setColumnsParcelas] = useState([]);
    const [columnsPagamentos, setColumnsPagamentos] = useState([]);



    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/financeiro/lancamentos/listarporid/" + idLancamento, {
                headers: headers
            }).then(function (response) {


                const somaParcelas = response.data.parcelas.reduce((total, parcela) => total + parcela.valor, 0);

                setValorTotal(somaParcelas);

                const somaPagamentos = response.data.pagamentos.reduce((total, pagamento) => total + pagamento.valor, 0);

                setValorTotalPagamentos(somaPagamentos);


                setObjetoGerenciado(response.data);

                const parcelasOrdenadas = response.data.parcelas.sort((a, b) => a.id_parcela - b.id_parcela);
                setParcelas(parcelasOrdenadas);

                const pagamentosOrdenados = response.data.pagamentos.sort((a, b) => a.id_pagamento - b.id_pagamento);
                setPagamentos(pagamentosOrdenados);

                setTipo(response.data.tipo);
                setDataLancamento(moment(response.data.data_lancamento).format('DD/MM/YYYY'));
                setCentroCusto(response.data.centro_custo);
                setFornecedor(response.data.fornecedor);
                setConta(response.data.conta);
                setCliente(response.data.cliente);

                setObservacao(response.data.observacao);
                setIdentificacao(response.data.identificacao);
                setDescricao(response.data.descricao);




                const columns = [

                    {
                        size: 50,
                        maxWidth: 50,
                        header: 'Info',
                        id: 1,
                        accessorFn: row => {
                            return {
                                observacao: row.observacao,
                                id_parcela: row.id_parcela,
                                identificador: row.identificador,
                                descricao: row.descricao,
                                observacao: row.observacao,
                            };
                        },
                        Cell: ({ renderedCellValue }) => {
                            return (
                                <Tooltip
                                    title={
                                        <div style={{ backgroundColor: 'white', padding: 5, color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>
                                            <Grid
                                                item xs={12}
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                style={{ padding: 3 }}
                                                container
                                            >

                                                <Grid
                                                    item xs={12}
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    style={{ padding: 3 }}
                                                    container
                                                >

                                                    <TextField
                                                        variant="standard"
                                                        name="identificador"
                                                        label="Identificador"
                                                        id="identificador"
                                                        value={renderedCellValue.identificador}
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                        disabled
                                                    />
                                                </Grid>

                                                <Grid
                                                    item xs={12}
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    style={{ padding: 3 }}
                                                    container
                                                >

                                                    <TextField
                                                        variant="standard"
                                                        name="descricao"
                                                        label="Descrição"
                                                        disabled
                                                        id="descricao"
                                                        value={renderedCellValue.descricao}
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                    />
                                                </Grid>


                                                <Grid
                                                    item xs={12}
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    style={{ padding: 3 }}
                                                    container
                                                >

                                                    <TextField
                                                        variant="standard"
                                                        name="observacao"
                                                        label="Observações"
                                                        disabled
                                                        id="observacao"
                                                        value={renderedCellValue.observacao}
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                    />
                                                </Grid>



                                            </Grid>

                                        </div>
                                    }
                                    placement="right"
                                    arrow
                                    PopperProps={{
                                        style: { backgroundColor: 'white', zIndex: 1000 }, // Defina o fundo branco aqui
                                    }}
                                >
                                    <span>{renderedCellValue.id_parcela}</span>
                                </Tooltip>

                            );
                        },
                    },


                    {
                        id: 2,
                        header: 'Arquivo',
                        size: 50,
                        Cell: ({ row }) => {
                            return (
                                <div >
                                    {
                                        row.original.caminho_arquivo ? (
                                            <div
                                                onClick={() => {
                                                    baixarAnexo(row.original);
                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '30px', height: '30px' }}
                                            >
                                                {/* Verifica a extensão do arquivo e exibe a miniatura ou o ícone correspondente */}
                                                {row.original.caminho_arquivo.toLowerCase().endsWith('.pdf') ? (
                                                    <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf} />
                                                ) : (row.original.caminho_arquivo.toLowerCase().endsWith('.doc') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.docx') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.xls') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.xlsx') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.ppt') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.pptx')) ? (
                                                    <img alt={'office'} style={{ textAlign: 'center' }} src={office} />
                                                ) : (
                                                    <img alt={'comprovante'} style={{ textAlign: 'center' }} src={comprovante} />
                                                )}

                                            </div>
                                        ) : (
                                            <div >

                                            </div>
                                        )
                                    }
                                </div>
                            )

                        },
                    },


                    {
                        id: 3,
                        header: 'Data',
                        size: 100,
                        accessorKey: 'data_criacao',
                        Cell: ({ cell }) => {
                            return (
                                <span style={{ maxWidth: '100px' }}>
                                    {moment(cell.getValue()).format('DD/MM/YYYY')}
                                </span>
                            );
                        }
                    },


                    {


                        id: 4,
                        accessorKey: 'valor',
                        header: 'Valor Total',
                        size: 120,
                        Cell: ({ cell }) => {

                            return (
                                <span style={{ maxWidth: '120px' }}>
                                    {currencyFormatter.format(cell.getValue())}
                                </span>
                            );
                        },
                        Footer: () => (
                            <Stack style={{ color: 'black', backgroundColor: 'white', fontWeight: 'initial', fontSize: 16 }}>
                                <Box>
                                    {
                                        currencyFormatter.format(somaParcelas)
                                    }
                                </Box>
                            </Stack>
                        ),
                    },

                    {
                        id: 5,
                        accessorKey: 'data_vencimento',
                        header: 'Vencimento',

                        size: 100,
                        maxWidth: 100,
                        Cell: ({ cell }) => {
                            const value = cell.getValue();
                            const isFormatted = moment(value, 'DD/MM/YYYY', true).isValid();

                            return (
                                <span style={{ maxWidth: '100px' }}>
                                    {isFormatted ? value : moment(value).format('DD/MM/YYYY')}
                                </span>
                            );
                        }
                    },



                    {
                        id: 6,
                        header: 'Status',
                        size: 100,
                        accessorKey: 'status',
                        Cell: ({ renderedCellValue }) => {

                            return (
                                <span style={{ maxWidth: '100px' }} >{renderedCellValue === 0 ? "Há Pagar" : "Pago"}</span>
                            );
                        }
                    },

                ];

                setColumnsParcelas(columns);




                const columnsDePagamentos = [

                    {
                        size: 60,
                        maxWidth: 60,
                        header: 'Info',
                        accessorFn: row => {
                            return {
                                observacao: row.observacao,
                                id_pagamento: row.id_pagamento,
                                identificador: row.identificador,
                                descricao: row.descricao,
                                observacao: row.observacao,

                            };
                        },
                        Cell: ({ renderedCellValue }) => {
                            return (
                                <Tooltip
                                    title={
                                        <div style={{ backgroundColor: 'white', padding: 5, color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>
                                            <Grid
                                                item xs={12}
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                style={{ padding: 3 }}
                                                container
                                            >

                                                <Grid
                                                    item xs={12}
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    style={{ padding: 3 }}
                                                    container
                                                >

                                                    <TextField
                                                        variant="standard"
                                                        name="identificador"
                                                        label="Identificador"
                                                        id="identificador"
                                                        value={renderedCellValue.identificador}
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                        disabled
                                                    />
                                                </Grid>

                                                <Grid
                                                    item xs={12}
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    style={{ padding: 3 }}
                                                    container
                                                >

                                                    <TextField
                                                        variant="standard"
                                                        name="descricao"
                                                        label="Descrição"
                                                        disabled
                                                        id="descricao"
                                                        value={renderedCellValue.descricao}
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                    />
                                                </Grid>


                                                <Grid
                                                    item xs={12}
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    style={{ padding: 3 }}
                                                    container
                                                >

                                                    <TextField
                                                        variant="standard"
                                                        name="observacao"
                                                        label="Observações"
                                                        disabled
                                                        id="observacao"
                                                        value={renderedCellValue.observacao}
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                    />
                                                </Grid>



                                            </Grid>

                                        </div>
                                    }
                                    placement="right"
                                    arrow
                                    PopperProps={{
                                        style: { backgroundColor: 'white', zIndex: 1000 }, // Defina o fundo branco aqui
                                    }}
                                >
                                    <span>{renderedCellValue.id_pagamento}</span>
                                </Tooltip>

                            );
                        },
                    },

                    {
                        header: 'Data do Pagamento',
                        size: 120,
                        accessorKey: 'data_pagamento',
                        Cell: ({ cell }) => {
                            return (
                                <span style={{ maxWidth: '120px' }}>
                                    {moment(cell.getValue()).format('DD/MM/YYYY')}
                                </span>
                            );
                        }
                    },

                    {
                        header: 'Arquivo',
                        size: 50,
                        Cell: ({ row }) => {
                            return (
                                <div >
                                    {
                                        row.original.caminho_arquivo ? (
                                            <div
                                                onClick={() => {
                                                    baixarAnexo(row.original);
                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '30px', height: '30px' }}
                                            >
                                                {/* Verifica a extensão do arquivo e exibe a miniatura ou o ícone correspondente */}
                                                {row.original.caminho_arquivo.toLowerCase().endsWith('.pdf') ? (
                                                    <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf} />
                                                ) : (row.original.caminho_arquivo.toLowerCase().endsWith('.doc') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.docx') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.xls') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.xlsx') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.ppt') ||
                                                    row.original.caminho_arquivo.toLowerCase().endsWith('.pptx')) ? (
                                                    <img alt={'office'} style={{ textAlign: 'center' }} src={office} />
                                                ) : (
                                                    <img alt={'comprovante'} style={{ textAlign: 'center' }} src={comprovante} />
                                                )}

                                            </div>
                                        ) : (
                                            <div >

                                            </div>
                                        )
                                    }
                                </div>
                            )

                        },
                    },


                    {
                        header: 'Metodo',
                        size: 100,
                        accessorKey: 'metodo_pagamento',
                        Cell: ({ cell }) => {
                            return (
                                <span style={{ maxWidth: '100px' }}>
                                    {cell.getValue().nome}
                                </span>
                            );
                        }
                    },

                    {


                        accessorKey: 'valor',
                        header: 'Valor Total',
                        size: 120,
                        Cell: ({ cell }) => {

                            return (
                                <span style={{ maxWidth: '120px' }}>
                                    {currencyFormatter.format(cell.getValue())}
                                </span>
                            );
                        },
                        Footer: () => (
                            <Stack style={{ color: 'black', backgroundColor: 'white', fontWeight: 'initial', fontSize: 16 }}>
                                <Box>
                                    {
                                        currencyFormatter.format(somaPagamentos)
                                    }
                                </Box>
                            </Stack>
                        ),
                    },

                    {
                        header: 'Transação',
                        size: 120,

                        Cell: ({ row }) => {

                            let texto_pagador = "";
                            const tipo_pagador = row.original.tipo_pagador;
                            if (tipo_pagador === 0) {
                                //cliente
                                texto_pagador = returnDescricaoCliente(row.original.cliente_pagador);
                            } else if (tipo_pagador === 1) {
                                //ib
                                texto_pagador = returnDescricaoIb(row.original.instituicao_bancaria_pagador);
                            } else if (tipo_pagador === 2) {
                                //fornecedor
                                texto_pagador = returnDescricaoFornecedor(row.original.fornecedor_pagador);
                            }


                            let texto_recebedor = "";
                            const tipo_recebedor = row.original.tipo_recebedor;
                            if (tipo_recebedor === 0) {
                                //cliente
                                texto_recebedor = returnDescricaoCliente(row.original.cliente_recebedor);
                            } else if (tipo_recebedor === 1) {
                                //ib
                                texto_recebedor = returnDescricaoIb(row.original.instituicao_bancaria_recebedor);
                            } else if (tipo_recebedor === 2) {
                                //fornecedor
                                texto_recebedor = returnDescricaoFornecedor(row.original.fornecedor_recebedor);
                            }

                            return (
                                <Box>
                                    <Tooltip
                                        title={
                                            <div
                                                style={{ minWidth: '150px', backgroundColor: 'white', padding: 10 }}
                                            >
                                                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', whiteSpace: 'pre-wrap' }} >{"Pagador:"}</span>
                                                <br></br>
                                                <span style={{ fontSize: 14, color: 'black', whiteSpace: 'pre-wrap' }} >{texto_pagador}</span>
                                                <br></br>
                                                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', whiteSpace: 'pre-wrap' }} >{"Recebedor:"}</span>
                                                <br></br>
                                                <span style={{ fontSize: 14, color: 'black', whiteSpace: 'pre-wrap' }} >{texto_recebedor}</span>
                                                <br></br>
                                                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', whiteSpace: 'pre-wrap' }} >{"Status:"}</span>
                                                <br></br>
                                                <span style={{ fontSize: 14, color: 'black', whiteSpace: 'pre-wrap' }} >{row.original.status === 0 ? 'Há Concluir' : "Concluído"}</span>

                                            </div>
                                        }
                                        placement="right"
                                        arrow
                                        sx={{
                                            '& .MuiTooltip-popper': { // Target the popper element of the tooltip
                                                backgroundColor: 'white',
                                            },
                                        }}                                    >
                                        <span style={{ fontSize: 12, color: 'black', whiteSpace: 'nowrap' }}>{texto_pagador}</span>
                                    </Tooltip>
                                </Box>
                            );
                        }
                    },






                ];

                setColumnsPagamentos(columnsDePagamentos);






                api.get("v1/protected/fornecedores/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setFornecedores(response.data);

                    api.get("v1/protected/financeiro/centrocusto/listartodos", {
                        headers: headers
                    }).then(function (response) {
                        setCentrosCusto(response.data);

                        api.get("v1/protected/financeiro/contas/listartodos", {
                            headers: headers
                        }).then(function (response) {
                            setContas(response.data);
                            setLoading(false);

                        });
                    });

                });

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    const autocompleteRef = useRef(null);



    useEffect(() => {
        checkDimenssoes();
        listarInformacoes();


    }, []);




    async function baixarAnexo(row) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/financeiro/lancamentos/baixaranexo", { caminho: encodeURIComponent(row.caminho_arquivo) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            const extensao = row.caminho_arquivo.split('.').pop().toLowerCase(); // Obtém a última parte do caminho após o último ponto e converte para minúsculas

            // Determina o tipo de mídia com base na extensão do arquivo
            let mediaType = 'application/octet-stream'; // Define um tipo padrão
            switch (extensao) {
                case 'pdf':
                    mediaType = 'application/pdf';
                    break;
                case 'doc':
                    mediaType = 'application/msword';
                    break;
                case 'docx':
                    mediaType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    break;
                case 'xls':
                    mediaType = 'application/vnd.ms-excel';
                    break;
                case 'xlsx':
                    mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                // Adicione outros tipos de arquivo conforme necessário
            }

            const blob = new Blob([response.data], { type: mediaType });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Obtém o nome do arquivo a partir do caminho
            const nomeArquivo = row.caminho_arquivo.split('/').pop(); // Obtém a última parte do caminho após a última barra

            if (extensao === 'pdf') {
                // Abre o PDF em uma nova aba
                window.open(blobUrl, '_blank');
            } else {
                // Cria um link <a> temporário para download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = nomeArquivo; // Define o nome do arquivo a ser baixado
                link.click(); // Simula um clique no link para iniciar o download
            }
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    function returnDescricaoCentroCusto(cc) {
        var texto = cc.nome;
        return texto;
    }

    function returnDescricaoConta(conta) {
        var texto = conta.grupo_contas.nome + "\n" + conta.nome + "\nDescrição: " + conta.descricao;
        return texto;
    }

    function returnDescricaoIb(ib) {
        var texto = "Banco: " + ib.banco +
            "\nAgência: " + ib.agencia +
            "\nConta: " + ib.conta +
            "\nDescrição: " + ib.descricao;
        return texto;


    }

    function returnDescricaoBanco(ib) {

        var texto = "Identificação: " + ib.identificacao +
            "\nTitular: " + ib.titular +
            "Banco: " + ib.banco +
            "\nAgência: " + ib.agencia +
            "\nConta: " + ib.conta +
            "\nDescrição: " + ib.descricao;
        return texto;


    }





    function returnDescricaoFornecedor(fornecedor) {

        var identificacao = fornecedor.tipo_fornecedor === 0 ? "CPF " : "CNPJ"

        var valor = fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj;

        var nome = fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) :
            (fornecedor.nome_fantasia);

        var endereco = fornecedor.logradouro + ", nº " + fornecedor.numero + ", " + fornecedor.bairro + ", " + fornecedor.cidade + " " + fornecedor.estado + " CEP: " + fornecedor.cep;


        var texto = "ID: " + fornecedor.id_fornecedor + " " + identificacao + " " + valor + " " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    function returnDescricaoCliente(cliente) {

        var identificacao = cliente.tipo_cliente === 0 ? "CPF " : "CNPJ"

        var valor = cliente.tipo_cliente === 0 ? cliente.cpf : cliente.cnpj;

        var nome = cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) :
            (cliente.nome_fantasia);

        var endereco = cliente.logradouro + ", nº " + cliente.numero + ", " + cliente.bairro + ", " + cliente.cidade + " " + cliente.estado + " CEP: " + cliente.cep;


        var texto = "ID: " + cliente.id_cliente + " " + identificacao + " " + valor + " " + nome + "\n Endereço: " + endereco;
        return texto;
    }



    function returnDescricaoMetodoPagamento(metodo) {
        var texto = "Nome: " + metodo.nome +
            " | Codigo: " + metodo.codigo + " | Sigla: " + metodo.sigla + "\nDescrição: " + metodo.descricao;
        return texto;
    }



    return (
        <div>

            <NavegadorGestao
                Financas={true}
                Lancamentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />




            <div style={{ paddingTop: 5 }}>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <React.Fragment >

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ padding: 5 }}
                            >

                                <a href="/gestao/financas/lancamento/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Lançamentos"}</a>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Especificações" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Finanças" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >
                                    <Grid value={value} index={0}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >
                                        <Grid
                                            item xs={12}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ padding: 10 }}
                                            container  >





                                            <Grid item xs={3} style={{ padding: 2 }}>
                                                <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                                    <CardContent>
                                                        <Grid
                                                            item xs={12}
                                                            alignItems="flex-start"
                                                            justifyContent="flex-start"
                                                            style={{ padding: 2 }}
                                                            container  >


                                                            <Grid
                                                                item xs={12}
                                                                alignItems="flex-start"
                                                                justifyContent="flex-start"
                                                                style={{ padding: 10 }}
                                                                container
                                                            >
                                                                <Grid item xs={12}
                                                                    container
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="tipo_notificacao_registro-label">Tipo do Lançamento</InputLabel>
                                                                            <Select
                                                                                labelId="tipo_notificacao_registro-label"
                                                                                id="area-tipo_registro"
                                                                                value={tipo}
                                                                                onChange={handleChangeTipo}
                                                                                label="Tipo do Lançamento"
                                                                            >
                                                                                <MenuItem value={0}>Despesa</MenuItem>
                                                                                <MenuItem value={1}>Receita</MenuItem>

                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>


                                                            </Grid>

                                                            <Grid
                                                                item xs={12}
                                                                alignItems="flex-start"
                                                                justifyContent="flex-start"
                                                                style={{ padding: 10 }}
                                                                container
                                                            >


                                                                <Typography variant="body1">
                                                                    <strong>Status:</strong> {objetoGerenciado.status === 0 ? 'Não Pago' : 'Pago'}
                                                                </Typography>

                                                            </Grid>


                                                            <Grid
                                                                item xs={12}
                                                                alignItems="flex-start"
                                                                justifyContent="flex-start"
                                                                style={{ padding: 10 }}
                                                                container
                                                            >

                                                                <TextField
                                                                    variant="standard"
                                                                    name="dataLancamento"
                                                                    label="Data Lançamento"
                                                                    required
                                                                    id="dataLancamento"
                                                                    value={dataLancamento}
                                                                    onChange={(e) => setDataLancamento(e.target.value)}
                                                                    fullWidth
                                                                />

                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>


                                            {tipo === 0 &&

                                                <Grid item xs={5} style={{ padding: 2 }}>
                                                    <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                                        <CardContent>


                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                    style={{ padding: 10 }}
                                                                >

                                                                    <Autocomplete
                                                                        id="select-centro-custo"
                                                                        options={[]}
                                                                        getOptionLabel={(centroCusto) => returnDescricaoCentroCusto(centroCusto).replace(/<br \/>/g, '\n')}
                                                                        value={centroCusto}
                                                                        onChange={(e, v) => setCentroCusto(v)}
                                                                        filterOptions={(options, { inputValue }) => {
                                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                            return options.filter((centroCusto) => {
                                                                                const cotacaoText = returnDescricaoCentroCusto(centroCusto).toLowerCase();
                                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                            });
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Pagador: Centro de Custo" multiline minRows={2} fullWidth />
                                                                        )}
                                                                        renderOption={(option) => (
                                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoCentroCusto(option) }} />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>


                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                    style={{ padding: 10 }}
                                                                >

                                                                    <Autocomplete
                                                                        id="select-fornecedor_cotacao1"
                                                                        options={[]}

                                                                        getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor).replace(/<br \/>/g, '\n')}
                                                                        value={fornecedor}
                                                                        onChange={(e, v) => setFornecedor(v)}
                                                                        filterOptions={(options, { inputValue }) => {
                                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                            return options.filter((fornecedor) => {
                                                                                const cotacaoText = returnDescricaoFornecedor(fornecedor).toLowerCase();
                                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                            });
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Recebedor: Fornecedor" multiline minRows={2} fullWidth />
                                                                        )}
                                                                        renderOption={(option) => (
                                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoFornecedor(option) }} />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            }

                                            {tipo === 1 &&
                                                <Grid item xs={5} style={{ padding: 2 }}>
                                                    <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                                        <CardContent>


                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                    style={{ padding: 10 }}
                                                                >

                                                                    <Autocomplete
                                                                        id="select-fornecedor_cotacao1"
                                                                        options={[]}

                                                                        getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                                        value={cliente}
                                                                        onChange={(e, v) => setCliente(v)}
                                                                        filterOptions={(options, { inputValue }) => {
                                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                            return options.filter((cliente) => {
                                                                                const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                            });
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Pagador: Cliente" multiline minRows={2} fullWidth />
                                                                        )}
                                                                        renderOption={(option) => (
                                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>



                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                    style={{ padding: 10 }}
                                                                >

                                                                    <Autocomplete
                                                                        id="select-centro-custo"
                                                                        options={[]}
                                                                        getOptionLabel={(centroCusto) => returnDescricaoCentroCusto(centroCusto).replace(/<br \/>/g, '\n')}
                                                                        value={centroCusto}
                                                                        onChange={(e, v) => setCentroCusto(v)}
                                                                        filterOptions={(options, { inputValue }) => {
                                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                            return options.filter((centroCusto) => {
                                                                                const cotacaoText = returnDescricaoCentroCusto(centroCusto).toLowerCase();
                                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                            });
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Recebedor: Centro de Custo" multiline minRows={2} fullWidth />
                                                                        )}
                                                                        renderOption={(option) => (
                                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoCentroCusto(option) }} />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>



                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            }




                                            <Grid item xs={4} style={{ padding: 2 }}>
                                                <Card style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                                    <CardContent>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                                style={{ padding: 10 }}
                                                            >
                                                                <Autocomplete
                                                                    id="select-conta"
                                                                    options={[]}

                                                                    getOptionLabel={(conta) => returnDescricaoConta(conta).replace(/<br \/>/g, '\n')}
                                                                    value={conta}
                                                                    onChange={(e, v) => setFornecedor(v)}
                                                                    filterOptions={(options, { inputValue }) => {
                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                        return options.filter((conta) => {
                                                                            const cotacaoText = returnDescricaoConta(conta).toLowerCase();
                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label="Conta" multiline minRows={2} fullWidth />
                                                                    )}
                                                                    renderOption={(option) => (
                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoConta(option) }} />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>


                                                        <Grid
                                                            item xs={12}
                                                            alignItems="flex-start"
                                                            justifyContent="flex-start"
                                                            style={{ padding: 15 }}
                                                            container
                                                        >

                                                            <Typography variant="body1" style={{ fontSize: 18 }}>
                                                                Valor:<strong> {currencyFormatter.format(objetoGerenciado.valor)}</strong>
                                                            </Typography>

                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>


                                        </Grid>

                                        <Grid
                                            item xs={12}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ padding: 10 }}
                                            container
                                        >
                                            <Grid
                                                item xs={2}
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                style={{ padding: 10 }}
                                                container
                                            >
                                                <TextField
                                                    variant="standard"
                                                    name="identificacao"
                                                    label="Identificação"
                                                    required
                                                    id="identificacao"
                                                    value={identificacao}
                                                    onChange={(e) => setIdentificacao(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ padding: 10 }}
                                            container
                                        >
                                            <Grid
                                                item xs={5}
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                style={{ padding: 10 }}
                                                container
                                            >

                                                <TextField
                                                    variant="standard"
                                                    name="descricao"
                                                    label="Descrição"
                                                    required
                                                    id="descricao"
                                                    value={descricao}
                                                    onChange={(e) => setDescricao(e.target.value)}
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ padding: 10 }}
                                            container
                                        >
                                            <Grid
                                                item xs={5}
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                style={{ padding: 10 }}
                                                container
                                            >
                                                <TextField
                                                    variant="standard"
                                                    name="observacao"
                                                    label="Observações"
                                                    required
                                                    id="observacao"
                                                    value={observacao}
                                                    onChange={(e) => setObservacao(e.target.value)}
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>



                                    <Grid value={value} index={1}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{ paddingTop: 20, backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            spacing={1}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                item xs={6}
                                                alignItems="center"
                                                justifyContent="center"

                                                style={{
                                                    margin: 0,
                                                    border: '1px solid #000',// Cor azul escuro e borda pontilhada
                                                    borderRadius: 4, // Borda levemente arredondada
                                                    width: '100%',
                                                }}
                                            >
                                                <Grid
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    container
                                                >
                                                    <Grid
                                                        item xs={10}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                    >
                                                        <Typography variant="body1" style={{ fontSize: 18 }}>
                                                            <strong>Parcelas</strong>
                                                        </Typography>

                                                    </Grid>
                                                    <Grid
                                                        item xs={2}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                    >

                                                        <IconButton color="primary" onClick={handleAddRow}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>


                                                </Grid>

                                                {!isUpdate && !isEditing &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        style={{ width: '100%', margin: 0, padding: 0, color: 'black' }}
                                                    >

                                                        <MaterialReactTable
                                                            columns={columnsParcelas}
                                                            data={isEditing ? [...parcelas, newRow] : parcelas}
                                                            enableTopToolbar={false}
                                                            enableBottomToolbar={false}
                                                            enablePagination={false}
                                                            enableColumnFilters={false}
                                                            enableColumnActions={false}
                                                            sortable={false}
                                                            enableRowActions={true}
                                                            enableSorting={false}
                                                            enableRowSelection={false}
                                                            color={'black'}
                                                            paginationDisplayMode={'pages'}
                                                            getRowId={(row) => row.id_item_analise}
                                                            initialState={{
                                                                pagination: { pageSize: 20, pageIndex: 0 },
                                                                showGlobalFilter: true,
                                                                color: 'black'
                                                            }}
                                                            muiTableContainerProps={{ sx: { maxHeight: "3000px", color: 'black' } }}
                                                            muiTableBodyRowProps={{
                                                                sx: {
                                                                    height: 35,
                                                                    color: 'black' // Define a cor do texto para preto
                                                                },
                                                            }}
                                                            enableColumnFilterModes={false}
                                                            enableExpandAll={true}
                                                            enableColumnPinning={true}
                                                            enableColumnResizing={true}
                                                            muiPaginationProps={{
                                                                rowsPerPageOptions: [20, 50, 100],
                                                                variant: 'outlined',
                                                            }}
                                                            enableFullScreenToggle={true}
                                                            muiTablePaperProps={({ table }) => ({
                                                                style: {
                                                                    zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                                                    color: 'black',
                                                                },
                                                            })}
                                                            renderRowActionMenuItems={({ row, table }) => [
                                                                < Tooltip title="Editar">
                                                                    <IconButton size="small" onClick={() => handleClickEditar(row.original)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Tooltip>,
                                                                <Tooltip title="Deletar">
                                                                    <IconButton size="small" color="error" onClick={() => handleClickDelete(row.original)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ]}


                                                      
                                                        />

                                                    </Grid>
                                                }
                                                {isEditing && (
                                                    <Grid
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                        style={{ marginTop: 10 }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            spacing={2}
                                                            style={{ paddingTop: 5 }}
                                                        >
                                                            {Object.keys(newRow).map((key) => (
                                                                <TextField
                                                                    key={key}
                                                                    id={key}
                                                                    variant="standard"
                                                                    value={newRow[key] || ''}
                                                                    onChange={(e) => setNewRow({ ...newRow, [key]: e.target.value })}
                                                                    style={{ marginRight: 10 }}
                                                                    label={key === 'valor' ? "Valor" : "Data Vencimento"}


                                                                />
                                                            ))}
                                                        </Grid>



                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            spacing={2}
                                                            style={{ marginTop: 5 }}
                                                        >
                                                            <IconButton color="success" onClick={handleSaveRow} >
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton color="error" onClick={handleCancel}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )}


                                                {isUpdate && (
                                                    <Grid
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                        style={{ marginTop: 10 }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <TextField
                                                                id="valor"
                                                                variant="standard"
                                                                value={inUpdate.valor}
                                                                onChange={(e) => setInUpdate({ ...inUpdate, ["valor"]: e.target.value })}
                                                                label="Valor"

                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <TextField
                                                                id="data_vencimento"
                                                                variant="standard"
                                                                value={inUpdate.data_vencimento}
                                                                onChange={(e) => setInUpdate({ ...inUpdate, ["data_vencimento"]: e.target.value })}
                                                                label="Data Vencimento"

                                                            />
                                                        </Grid>


                                                        <Grid
                                                            item
                                                            xs={10}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{ marginTop: 5 }}
                                                        >
                                                            <TextField
                                                                id="identificador"
                                                                variant="standard"
                                                                value={inUpdate.identificador}
                                                                onChange={(e) => setInUpdate({ ...inUpdate, ["identificador"]: e.target.value })}
                                                                label="Identificador"
                                                                multiline
                                                                minRows={2}
                                                                fullWidth

                                                            />
                                                        </Grid>


                                                        <Grid
                                                            item
                                                            xs={10}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{ marginTop: 5 }}
                                                        >
                                                            <TextField
                                                                id="descricao"
                                                                variant="standard"
                                                                value={inUpdate.descricao}
                                                                onChange={(e) => setInUpdate({ ...inUpdate, ["descricao"]: e.target.value })}
                                                                label="Descrição"
                                                                multiline
                                                                minRows={2}
                                                                fullWidth

                                                            />
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={10}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{ marginTop: 5 }}
                                                        >
                                                            <TextField
                                                                id="observacao"
                                                                variant="standard"
                                                                value={inUpdate.observacao}
                                                                onChange={(e) => setInUpdate({ ...inUpdate, ["observacao"]: e.target.value })}
                                                                label="Observações"

                                                                multiline
                                                                minRows={2}
                                                                fullWidth

                                                            />
                                                        </Grid>


                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            align="flex-end"
                                                            item xs={8}
                                                            style={{ marginTop: 5 }}
                                                        >


                                                            <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                                <input {...getInputArquivoProps1()} />
                                                                <Typography align="center" variant="h7">
                                                                    Anexo
                                                                </Typography>
                                                            </div>


                                                        </Grid>




                                                        <Grid
                                                            item
                                                            xs={11}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            spacing={2}
                                                            style={{ marginTop: 5 }}
                                                        >
                                                            <IconButton color="success" onClick={handleUpdateRow} >
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton color="error" onClick={handleCancelUpdate} >
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )}

                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={6}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{
                                                    margin: 0,
                                                    border: '1px solid #000',// Cor azul escuro e borda pontilhada
                                                    borderRadius: 4, // Borda levemente arredondada
                                                    width: '100%',
                                                }}

                                            >
                                                <Grid
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    container
                                                >

                                                    <Grid
                                                        item xs={10}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                    >
                                                        <Typography variant="body1" style={{ fontSize: 18 }}>
                                                            <strong>Pagamentos</strong>
                                                        </Typography>

                                                    </Grid>
                                                    <Grid
                                                        item xs={2}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                    >

                                                        <IconButton color="primary" onClick={handleAddPagamento}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>

                                                </Grid>

                                                {!isEditingPagamento && !isUpdatePagamento &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        style={{ width: '100%', margin: 0, padding: 0, color: 'black' }}
                                                    >

                                                        <MaterialReactTable
                                                            columns={columnsPagamentos}
                                                            data={isEditingPagamento ? [...pagamentos, newPagamento] : pagamentos}
                                                            enableTopToolbar={false}
                                                            enableBottomToolbar={false}
                                                            enablePagination={false}
                                                            enableColumnFilters={false}
                                                            enableColumnActions={false}
                                                            sortable={false}
                                                            enableRowActions={true}
                                                            enableSorting={false}
                                                            enableRowSelection={false}
                                                            color={'black'}
                                                            paginationDisplayMode={'pages'}
                                                            getRowId={(row) => row.id_item_analise}
                                                            initialState={{
                                                                pagination: { pageSize: 20, pageIndex: 0 },
                                                                showGlobalFilter: true,
                                                                color: 'black'
                                                            }}
                                                            muiTableContainerProps={{ sx: { maxHeight: "3000px", color: 'black' } }}
                                                            muiTableBodyRowProps={{
                                                                sx: {
                                                                    height: 35,
                                                                    color: 'black' // Define a cor do texto para preto
                                                                },
                                                            }}
                                                            enableColumnFilterModes={false}
                                                            enableExpandAll={true}
                                                            enableColumnPinning={true}
                                                            enableColumnResizing={true}
                                                            muiPaginationProps={{
                                                                rowsPerPageOptions: [20, 50, 100],
                                                                variant: 'outlined',
                                                            }}
                                                            enableFullScreenToggle={true}
                                                            muiTablePaperProps={({ table }) => ({
                                                                style: {
                                                                    zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                                                    color: 'black',
                                                                },
                                                            })}

                                                            renderRowActionMenuItems={({ row, table }) => [
                                                                < Tooltip title="Editar">
                                                                    <IconButton size="small" onClick={() => handleClickEditarPagamento(row.original)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Tooltip>,
                                                                <Tooltip title="Deletar">
                                                                    <IconButton size="small" color="error" onClick={() => handleClickDeletePagamento(row.original)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ]}


                                                        />

                                                    </Grid>
                                                }


                                                {(isEditingPagamento || isUpdatePagamento) && (
                                                    <Grid
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        container
                                                        style={{ marginTop: 10 }}
                                                        spacing={2}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{ padding: 5 }}
                                                        >

                                                            <Grid
                                                                item
                                                                xs={4}
                                                                container
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                style={{ padding: 5 }}
                                                            >
                                                                <TextField
                                                                    id="valor"
                                                                    variant="standard"
                                                                    value={isEditingPagamento ? newPagamento.valor : inUpdatePagamento.valor}

                                                                    onChange={(e) => {
                                                                        if (isEditingPagamento) {
                                                                            setNewPagamento({ ...newPagamento, ["valor"]: e.target.value })
                                                                        } else {
                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["valor"]: e.target.value })
                                                                        }
                                                                    }}
                                                                    label="Valor"
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={4}
                                                                container
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                style={{ padding: 5 }}
                                                            >
                                                                <TextField
                                                                    id="data_pagamento"
                                                                    variant="standard"
                                                                    value={isEditingPagamento ? newPagamento.data_pagamento : inUpdatePagamento.data_pagamento}
                                                                    onChange={(e) => {
                                                                        if (isEditingPagamento) {
                                                                            setNewPagamento({ ...newPagamento, ["data_pagamento"]: e.target.value })
                                                                        } else {
                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["data_pagamento"]: e.target.value })
                                                                        }
                                                                    }}
                                                                    label="Data Pagamento"
                                                                    fullWidth

                                                                />
                                                            </Grid>


                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={4}
                                                                style={{ padding: 5 }}
                                                            >
                                                                <FormControl fullWidth>
                                                                    <InputLabel style={{ fontSize: '14px' }} id="select_stats_pagamento">Status</InputLabel>
                                                                    <Select
                                                                        labelId="select_stats_pagamento"
                                                                        id="select_stats_pagamento"
                                                                        value={isEditingPagamento ? newPagamento.status : inUpdatePagamento.status}
                                                                        onChange={(e) => {
                                                                            if (isEditingPagamento) {
                                                                                setNewPagamento({ ...newPagamento, ["status"]: e.target.value })
                                                                            } else {
                                                                                setInUpdatePagamento({ ...inUpdatePagamento, ["status"]: e.target.value })
                                                                            }
                                                                        }}
                                                                        label="Status"
                                                                        style={{ fontSize: '14px' }}
                                                                    >
                                                                        <MenuItem style={{ fontSize: '14px' }} value={0}>Há Concluir</MenuItem>
                                                                        <MenuItem style={{ fontSize: '14px' }} value={1}>Concluído</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>



                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                                style={{ padding: 5 }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                >

                                                                    <Autocomplete
                                                                        id="select-metodos-pagamento"
                                                                        options={metodosPagamento}
                                                                        fullWidth
                                                                        getOptionLabel={(metodo) => returnDescricaoMetodoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                                                        value={isEditingPagamento ? newPagamento.metodo_pagamento : inUpdatePagamento.metodo_pagamento}

                                                                        onChange={(e, value) => {
                                                                            if (isEditingPagamento) {
                                                                                setNewPagamento({ ...newPagamento, ["metodo_pagamento"]: value })
                                                                            } else {
                                                                                setInUpdatePagamento({ ...inUpdatePagamento, ["metodo_pagamento"]: value })
                                                                            }
                                                                        }}
                                                                        autoSelect
                                                                        filterOptions={(options, { inputValue }) => {
                                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                            return options.filter((solicitante) => {
                                                                                const cotacaoText = returnDescricaoMetodoPagamento(solicitante).toLowerCase();
                                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                            });
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label=" Metodo de Pagamento " multiline minRows={2} />
                                                                        )}
                                                                        renderOption={(option) => (
                                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoMetodoPagamento(option).replace(/\n/g, '<br>') }} />
                                                                        )}

                                                                    />
                                                                </Grid>
                                                            </Grid>





                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                                style={{ marginTop: 20, marginBottom: 20, borderRadius: '4px', border: '0.1px solid #000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                                    spacing={2}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        align="center"
                                                                        item xs={4}
                                                                        style={{ padding: 5 }}
                                                                    >
                                                                        <FormControl fullWidth>
                                                                            <InputLabel style={{ fontSize: '14px' }} id="select_tipo_pagador">Tipo Pagador</InputLabel>
                                                                            <Select
                                                                                labelId="select_tipo_pagador"
                                                                                id="select_tipo_pagador"
                                                                                value={isEditingPagamento ? newPagamento.tipo_pagador : inUpdatePagamento.tipo_pagador}
                                                                                onChange={(e) => {
                                                                                    if (isEditingPagamento) {
                                                                                        setNewPagamento({ ...newPagamento, ["tipo_pagador"]: e.target.value })
                                                                                    } else {
                                                                                        setInUpdatePagamento({ ...inUpdatePagamento, ["tipo_pagador"]: e.target.value })
                                                                                    }
                                                                                }}
                                                                                label="Tipo Pagador"
                                                                                style={{ fontSize: '14px' }}
                                                                            >
                                                                                <MenuItem style={{ fontSize: '14px' }} value={0}>Cliente</MenuItem>
                                                                                <MenuItem style={{ fontSize: '14px' }} value={1}>Instituição Bancária</MenuItem>
                                                                                <MenuItem style={{ fontSize: '14px' }} value={2}>Fornecedor</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>


                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        align="center"
                                                                        item xs={6}
                                                                        style={{ padding: 5 }}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justifyContent="center"
                                                                            align="center"
                                                                            item xs={12}
                                                                        >

                                                                            {
                                                                                ((isEditingPagamento && newPagamento.tipo_pagador === 0) || (isUpdatePagamento && inUpdatePagamento.tipo_pagador === 0)) &&
                                                                                <Autocomplete
                                                                                    id="select-cliente-pagador"
                                                                                    options={clientes}

                                                                                    fullWidth
                                                                                    getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                                                    value={isEditingPagamento ? newPagamento.cliente_pagador : inUpdatePagamento.cliente_pagador}

                                                                                    onChange={(e, value) => {
                                                                                        if (isEditingPagamento) {
                                                                                            setNewPagamento({ ...newPagamento, ["cliente_pagador"]: value })
                                                                                        } else {
                                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["cliente_pagador"]: value })
                                                                                        }
                                                                                    }}

                                                                                    filterOptions={(options, { inputValue }) => {
                                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                                        return options.filter((solicitante) => {
                                                                                            const cotacaoText = returnDescricaoCliente(solicitante).toLowerCase();
                                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                                        });
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField style={{ fontSize: '14px' }} {...params} label=" Cliente Pagador " multiline minRows={2} />
                                                                                    )}
                                                                                    renderOption={(option) => (
                                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option).replace(/\n/g, '<br>') }} />
                                                                                    )}

                                                                                />
                                                                            }

                                                                            {
                                                                                ((isEditingPagamento && newPagamento.tipo_pagador === 1) || (isUpdatePagamento && inUpdatePagamento.tipo_pagador === 1)) &&
                                                                                <Autocomplete
                                                                                    style={{ fontSize: '14px' }}
                                                                                    id="select-cliente-ib"
                                                                                    options={instituicoesBancarias}

                                                                                    fullWidth
                                                                                    getOptionLabel={(ib) => returnDescricaoIb(ib).replace(/<br \/>/g, '\n')}
                                                                                    value={isEditingPagamento ? newPagamento.instituicao_bancaria_pagador : inUpdatePagamento.instituicao_bancaria_pagador}
                                                                                    onChange={(e, value) => {
                                                                                        if (isEditingPagamento) {
                                                                                            setNewPagamento({ ...newPagamento, ["instituicao_bancaria_pagador"]: value })
                                                                                        } else {
                                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["instituicao_bancaria_pagador"]: value })
                                                                                        }
                                                                                    }}


                                                                                    filterOptions={(options, { inputValue }) => {
                                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                                        return options.filter((solicitante) => {
                                                                                            const cotacaoText = returnDescricaoIb(solicitante).toLowerCase();
                                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                                        });
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} style={{ fontSize: '14px' }} label=" Instituição Bancária Pagadora " multiline minRows={2} />
                                                                                    )}
                                                                                    renderOption={(option) => (
                                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoIb(option).replace(/\n/g, '<br>') }} />
                                                                                    )}

                                                                                />
                                                                            }

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>




                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                                style={{ marginTop: 20, marginBottom: 20, borderRadius: '4px', border: '0.1px solid #000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={12}
                                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                                    spacing={2}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        align="center"
                                                                        item xs={4}
                                                                    >
                                                                        <FormControl fullWidth>
                                                                            <InputLabel style={{ fontSize: '14px' }} id="select_tipo_recebedor">Tipo Recebedor</InputLabel>
                                                                            <Select
                                                                                labelId="select_tipo_recebedor"
                                                                                id="select_tipo_recebedor"
                                                                                value={isEditingPagamento ? newPagamento.tipo_recebedor : inUpdatePagamento.tipo_recebedor}
                                                                                onChange={(e) => {
                                                                                    if (isEditingPagamento) {
                                                                                        setNewPagamento({ ...newPagamento, ["tipo_recebedor"]: e.target.value })
                                                                                    } else {
                                                                                        setInUpdatePagamento({ ...inUpdatePagamento, ["tipo_recebedor"]: e.target.value })
                                                                                    }
                                                                                }}

                                                                                label="Tipo Recebedor"
                                                                                style={{ fontSize: '14px' }}
                                                                            >
                                                                                <MenuItem style={{ fontSize: '14px' }} value={0}>Cliente</MenuItem>
                                                                                <MenuItem style={{ fontSize: '14px' }} value={1}>Instituição Bancária</MenuItem>
                                                                                <MenuItem style={{ fontSize: '14px' }} value={2}>Fornecedor</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        align="center"
                                                                        item xs={6}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justifyContent="center"
                                                                            align="center"
                                                                            item xs={12}
                                                                        >


                                                                            {

                                                                                ((isEditingPagamento && newPagamento.tipo_recebedor === 1) || (isUpdatePagamento && inUpdatePagamento.tipo_recebedor === 1)) &&
                                                                                <Autocomplete
                                                                                    style={{ fontSize: '14px' }}
                                                                                    id="select-cliente-ib"
                                                                                    options={instituicoesBancarias}

                                                                                    fullWidth
                                                                                    getOptionLabel={(ib) => returnDescricaoIb(ib).replace(/<br \/>/g, '\n')}
                                                                                    value={isEditingPagamento ? newPagamento.instituicao_bancaria_recebedor : inUpdatePagamento.instituicao_bancaria_recebedor}
                                                                                    onChange={(e, value) => {
                                                                                        if (isEditingPagamento) {
                                                                                            setNewPagamento({ ...newPagamento, ["instituicao_bancaria_recebedor"]: value })
                                                                                        } else {
                                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["instituicao_bancaria_recebedor"]: value })
                                                                                        }
                                                                                    }}


                                                                                    filterOptions={(options, { inputValue }) => {
                                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                                        return options.filter((solicitante) => {
                                                                                            const cotacaoText = returnDescricaoIb(solicitante).toLowerCase();
                                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                                        });
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} style={{ fontSize: '14px' }} label=" Instituição Bancária Recebedora " multiline minRows={2} />
                                                                                    )}
                                                                                    renderOption={(option) => (
                                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoIb(option).replace(/\n/g, '<br>') }} />
                                                                                    )}

                                                                                />
                                                                            }


                                                                            {
                                                                                ((isEditingPagamento && newPagamento.tipo_recebedor === 2) || (isUpdatePagamento && inUpdatePagamento.tipo_recebedor === 2)) &&
                                                                                <Autocomplete
                                                                                    id="select-fornecedor-recebedor"
                                                                                    options={fornecedores}

                                                                                    fullWidth
                                                                                    style={{ fontSize: '14px' }}
                                                                                    getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor).replace(/<br \/>/g, '\n')}
                                                                                    value={isEditingPagamento ? newPagamento.fornecedor_recebedor : inUpdatePagamento.fornecedor_recebedor}
                                                                                    onChange={(e, value) => {
                                                                                        if (isEditingPagamento) {
                                                                                            setNewPagamento({ ...newPagamento, ["fornecedor_recebedor"]: value })
                                                                                        } else {
                                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["fornecedor_recebedor"]: value })
                                                                                        }
                                                                                    }}
                                                                                    filterOptions={(options, { inputValue }) => {
                                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                                        return options.filter((solicitante) => {
                                                                                            const cotacaoText = returnDescricaoFornecedor(solicitante).toLowerCase();
                                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                                        });
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} style={{ fontSize: '14px' }}
                                                                                            InputProps={{
                                                                                                ...params.InputProps,
                                                                                                style: { fontSize: '14px' }, // Definindo o tamanho do texto aqui
                                                                                            }}
                                                                                            InputLabelProps={{
                                                                                                style: { fontSize: '14px' }, // Definindo o tamanho do texto do rótulo aqui
                                                                                            }}
                                                                                            label=" Fornecedor Recebedor " multiline minRows={2}
                                                                                        />
                                                                                    )}



                                                                                />
                                                                            }



                                                                        </Grid>
                                                                    </Grid>


                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        align="center"
                                                                        item xs={6}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justifyContent="center"
                                                                            align="center"
                                                                            item xs={12}
                                                                        >

                                                                            {
                                                                                ((isEditingPagamento && newPagamento.tipo_recebedor === 2) || (isUpdatePagamento && inUpdatePagamento.tipo_recebedor === 2)) &&
                                                                                <Autocomplete
                                                                                    id="select-fornecedor-recebedor"
                                                                                    options={isEditingPagamento ? newPagamento.fornecedor_recebedor.contas_bancarias : inUpdatePagamento.fornecedor_recebedor.contas_bancarias}

                                                                                    fullWidth
                                                                                    style={{ fontSize: '14px' }}
                                                                                    getOptionLabel={(conta) => returnDescricaoBanco(conta).replace(/<br \/>/g, '\n')}
                                                                                    value={isEditingPagamento ? newPagamento.conta_bancaria : inUpdatePagamento.conta_bancaria}
                                                                                    onChange={(e, value) => {
                                                                                        if (isEditingPagamento) {
                                                                                            setNewPagamento({ ...newPagamento, ["conta_bancaria"]: value })
                                                                                        } else {
                                                                                            setInUpdatePagamento({ ...inUpdatePagamento, ["conta_bancaria"]: value })
                                                                                        }
                                                                                    }}
                                                                                    filterOptions={(options, { inputValue }) => {
                                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                                        return options.filter((solicitante) => {
                                                                                            const cotacaoText = returnDescricaoBanco(solicitante).toLowerCase();
                                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                                        });
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} style={{ fontSize: '14px' }}
                                                                                            InputProps={{
                                                                                                ...params.InputProps,
                                                                                                style: { fontSize: '14px' }, // Definindo o tamanho do texto aqui
                                                                                            }}
                                                                                            InputLabelProps={{
                                                                                                style: { fontSize: '14px' }, // Definindo o tamanho do texto do rótulo aqui
                                                                                            }}
                                                                                            label=" Conta Bancária do Recebedor " multiline minRows={2}
                                                                                        />
                                                                                    )}



                                                                                />
                                                                            }



                                                                        </Grid>
                                                                    </Grid>





                                                                </Grid>
                                                            </Grid>

                                                            {isUpdatePagamento &&
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-end"
                                                                    align="flex-end"
                                                                    item xs={8}
                                                                    style={{ marginTop: 5 }}
                                                                >


                                                                    <div {...getRootArquivoProps2()} style={dropzoneStyles}>
                                                                        <input {...getInputArquivoProps2()} />
                                                                        <Typography align="center" variant="h7">
                                                                            Anexo
                                                                        </Typography>
                                                                    </div>


                                                                </Grid>
                                                            }




                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            spacing={2}
                                                            style={{ marginTop: 5 }}
                                                        >
                                                            <IconButton color="success" onClick={isEditingPagamento ? handleSavePagamento : handleUpdatePagamento} >
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton color="error" onClick={handleCancelSavePagamento} >
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )}



                                            </Grid>


                                        </Grid>

                                    </Grid>


                                </SwipeableViews>
                            </Grid>
                        </Grid >




                    </React.Fragment >
                }


            </div >




        </div >
    );
}



