import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from '../../../../../../components/NavBarGestao';

import { Button } from "@material-ui/core";


import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';




function novoTipoOcorrencia() {
    window.location.href = "/gestao/operacional/notificacoes/tiposocorrencias/cadastrar"

}

export default function TipoOcorrencias() {



    const [loading, setLoading] = useState(true);
    const [objetos, setObjetos] = useState([]);






    async function listarObjetos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/notificacoes/tiposocorrencias/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Tipo Ocorrencias: " + _err)

        }

    }


    useEffect(() => {




        listarObjetos();


    }, []);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



 



    const columnsDataGrid = [
       
        {
            headerName: 'ID',
            field: 'id_tipo_ocorrencia',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Nome',
            field: 'nome',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 3,
            width: 500,
            headerClassName: 'cabecalho_azul',
        },

       
    ];




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Notificacoes={true}
                TipoOcorrencias={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoTipoOcorrencia}
                    >
                        Novo Tipo Ocorrência
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        <>
                            <DataGrid
                                localeText={ptBR.localeText}
                                pagination
                                rows={objetos}
                                pageSize={20}
                                rowHeight={40}
                                getRowId={(row) => row.id_tipo_ocorrencia}
                                columns={columnsDataGrid}
                                disableColumnResize={false}
                                onCellClick={handleCellClick}
                                onRowClick={handleRowClick}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />

                        </>
                    </div>
                }


            </div>


        </div>
    );
}



