import React, { useState, useEffect } from 'react';
import Rodape from '../../components/Rodape';

import Navegador from '../../components/NavBar/index';

import Grid from '@material-ui/core/Grid';
import area_atuacao from '../../assets/imgs/area_atuacao.png';


import capa_aniversario from '../../assets/imgs/capa_aniversario.png';

import empresas_do_grupo from '../../assets/imgs/empresas_do_grupo.png';
import servicos_seguranca_eletronica from '../../assets/imgs/servicos_seguranca_eletronica.png';
import servicos_refrigeracao from '../../assets/imgs/servicos_refrigeracao.png';
import servicos_cursos_treinamentos from '../../assets/imgs/servicos_cursos_treinamentos.png';
import servicos_auto_center from '../../assets/imgs/servicos_auto_center.png';



import capa_top from '../../assets/imgs/capa_top.png';


import video_capa1 from '../../assets/videos/video_capa1.mp4';


import Carousel from 'react-bootstrap/Carousel'


import './styles.scss';

const Home = (props) => {

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);


  function checkDimenssoes() {
    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    var altura = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;
    console.log("altura: " + altura);
    console.log("largura: " + largura);

    setHeight(altura * 0.7);
    setWidth(largura * 0.7);

  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });


  useEffect(() => {



    checkDimenssoes();



  }, []);







  return (
    <div >
      <Navegador inicio={'underline'} corFundo={'rgba(255,255,255,1)'} travado={true} corTexto={'black'} />
      <div >

        <Carousel interval={10000} fade

        >


          <Carousel.Item
          >


            <div style={{ display: 'flex', justifyContent: 'center', margin: 0}}>
              <img alt={"capa_aniversario"} style={{ textAlign: 'center' }} src={capa_aniversario}></img>
            </div>




          </Carousel.Item>






        </Carousel>
      </div>

      <Grid
          container
          direction="row"
          item xs={12} sm={12} md={12} lg={12} xl={12}
          justifyContent="center"
          alignItems="center"
          style={{ padding: 20, paddingTop: 50 }}
        >
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}

            container
            alignItens="center"
            justifyContent="center"
            style={{ fontSize: width < 500 ? 14 : 28, textAlign: 'center', whiteSpace: 'pre-wrap' }}
          >

            <span >
              O <span style={{ color: 'DarkRed', fontWeight: 'bold' }}>Grupo</span> <span style={{ color: 'black', fontWeight: 'bold' }}>Rosinetos </span>
              com sua excelência em prestação de serviços, completa 16 anos de atuação na área de{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Tecnologia da Informação</span>,{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Segurança Eletrônica</span>,{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Refrigeração</span>,{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Educação</span>  e{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Automotivo</span>
              , desenvolvendo soluções{" "}
              <span style={{ color: 'black', fontWeight: 'bold' }}>inovadoras</span>{" "}
              que agregam resultado, credibilidade e total satisfação de nossos clientes.
            </span>

          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          </Grid>




        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: 10 }}

      >

        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, paddingInline: 50, fontSize: width < 500 ? 35 : 100 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"#Conheça-nos:"}  </span>
        </div>

      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: width < 500 ? 10 : 100, paddingLeft: 10, paddingRight: 10 }}

      >

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
          justifyContent='center'
          alignItens='center'
          direction='row'
          container

        >
          <video controls poster={capa_top} >
            <source src={video_capa1} type="video/mp4" />
          </video>
        </Grid>



      </Grid>








      <div style={{ display: 'flex', justifyContent: 'center', margin: 0, paddingInline: width < 500 ? 5 : 100 }}>
        <img alt={"empresas_do_grupo"} style={{ textAlign: 'center' }} src={empresas_do_grupo}></img>
      </div>


      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: width < 500 ? 10 : 50 }}

      >

        <div className={ width < 500 ? null : "span2" } style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"# Nossas Soluções"}  </span>
        </div>

      </Grid>


      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 50, paddingBottom: width < 500 ? 10 : 50 }}

      >
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        </Grid>


        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}
          justifyContent='center'
          alignItens='center'
          direction='row'
          container
          spacing={2}
        >

          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }} >
              <img alt={"servicos_seguranca_eletronica"} className={"imagem"} style={{ textAlign: 'center' }} src={servicos_seguranca_eletronica}></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"servicos_refrigeracao"} style={{ textAlign: 'center' }} src={servicos_refrigeracao}></img>
            </div>
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"servicos_cursos_treinamentos"} style={{ textAlign: 'center' }} src={servicos_cursos_treinamentos}></img>
            </div>
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={3} xl={3} >
            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"servicos_auto_center"} style={{ textAlign: 'center' }} src={servicos_auto_center}></img>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        </Grid>

      </Grid>




      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: 10 }}

      >

        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"#Onde Atuamos?"}  </span>
        </div>

      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center', margin: 0, paddingTop: 10, paddingInline: width < 500 ? 10 : 50 }}>
        <img alt={"area_atuacao"} style={{ textAlign: 'center' }} src={area_atuacao}></img>
      </div>




      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: 10 }}

      >

        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"#Onde Estamos?"}  </span>
        </div>

      </Grid>

      <div style={{ backgroundColor: 'white', color: 'black' }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          item xs={12} sm={12} md={12} lg={12} xl={12}
          style={{ paddingBottom: width < 500 ? 10 : 50 }}
        >

          <iframe
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDCL-kmB8NJF2jWAFU8emWx7ldg-B16-nc&q=Tv.%20We%2043%2C%20422%2C%20Cidade%20Nova%2C%20Ananindeua%2C%20PA%2C%2067133-260%2C%20Brasil&maptype=roadmap"
            width={width}
            height={height}
            title="Localização no Mapa"
            style={{ paddingBottom: 30 }}
            allowFullScreen={true}
            onLoad={() => checkDimenssoes()}

          >
          </iframe>

        </Grid>
      </div>


      
      <div >
        <Rodape />
      </div>
      
    </div >
  );
}

export default Home;