// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
    transform: rotate(90deg);
  }


  .piscar {
    animation: piscar 2s ease-in-out infinite;
  }
  
  @keyframes piscar {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }


  .piscardesarmada {
    animation: piscar 1s ease-in-out infinite;
  }
  
  @keyframes piscar {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }


    .piscarcamera {
    animation: piscar 0.5s ease-in-out infinite;
  }
  
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/pages/painelCliente/Monitoramento/monitoramento.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;EAC1B;;;EAGA;IACE,yCAAyC;EAC3C;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;;EAGA;IACE,yCAAyC;EAC3C;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;;IAGE;IACA,2CAA2C;EAC7C","sourcesContent":[".map-container {\r\n    transform: rotate(90deg);\r\n  }\r\n\r\n\r\n  .piscar {\r\n    animation: piscar 2s ease-in-out infinite;\r\n  }\r\n  \r\n  @keyframes piscar {\r\n    0% {\r\n      opacity: 0;\r\n    }\r\n    50% {\r\n      opacity: 1;\r\n    }\r\n    100% {\r\n      opacity: 1;\r\n    }\r\n  }\r\n\r\n\r\n  .piscardesarmada {\r\n    animation: piscar 1s ease-in-out infinite;\r\n  }\r\n  \r\n  @keyframes piscar {\r\n    0% {\r\n      opacity: 0;\r\n    }\r\n    50% {\r\n      opacity: 1;\r\n    }\r\n    100% {\r\n      opacity: 1;\r\n    }\r\n  }\r\n\r\n\r\n    .piscarcamera {\r\n    animation: piscar 0.5s ease-in-out infinite;\r\n  }\r\n  \r\n  \r\n  \r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
