import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';


import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import { useParams } from "react-router-dom";
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


export default function AlterarFornecedor() {
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */



    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    /* variaveis de estado de cadastro de pessoa juridica*/


    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');
    /*variaveis gerais*/

    /*variaveis de endereco*/
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [infoAdicional, setInfoAdicional] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const { idFornecedor } = useParams();
    /*variaveis de endereco*/

    /*variaveis de controle pessoa fisica */

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');


    const [erroDataNascimento, setErroDataNascimento] = useState(false);
    const [textoErroDataNascimento, setTextoErroDataNascimento] = useState('');

    /*variaveis de controle pessoa fisica */


    /*variaveis de controle pessoa juridica */
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [textoErroRazaoSocial, setTextoErroRazaoSocial] = useState('');

    const [erroNomeFantasia, setErroNomeFantasia] = useState(false);
    const [textoErroNomeFantasia, setTextoErroNomeFantasia] = useState('');

    /*variaveis de controle pessoa juridica */



    /* variaveis  de controle gerais */

    /* variaveis  de controle gerais */

    /* variaveis  de controle de endereco */
    const [tipo_endereco, setTipoEndereco] = useState(0);
    const [erroCep, setErroCep] = useState(false);
    const [textoErroCep, setTextoErroCep] = useState('');
    const [erroLogradouro, setErroLogradouro] = useState(false);
    const [textoErroLogradouro, setTextoErroLogradouro] = useState('');
    const textoErroNumero = 'Se sem número deixe o campo em branco';
    const [erroBairro, setErroBairro] = useState(false);
    const [textoErroBairro, setTextoErroBairro] = useState('');
    const [erroComplemento, setErroComplemento] = useState(false);
    const [textoErroComplemento, setTextoErroComplemento] = useState('');
    const [erroCidade, setErroCidade] = useState(false);
    const [textoErroCidade, setTextoErroCidade] = useState('');
    const [erroEstado, setErroEstado] = useState(false);
    const [textoErroEstado, setTextoErroEstado] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    /* variaveis  de controle endereco */

    useEffect(() => {

        setLoading(true);

        async function listarFornecedor() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/retornardadosfornecedor/" + idFornecedor, {
                    headers: headers
                }).then(function (response) {
                    var dados = response.data;

                    if (dados.tipo_fornecedor === 0) {

                        setTipoFisica(true);
                        setTipoJuridica(false);

                        setCpf(dados.cpf);
                        setRg(dados.rg);
                        setDataNascimento(dados.nascimento);
                        setNome(dados.nome);
                        setSobrenome(dados.sobrenome);


                    } else {

                        setTipoFisica(false);
                        setTipoJuridica(true);

                        setCnpj(dados.cnpj)
                        setPorte(dados.porte);
                        setAtividade(dados.atividade);
                        setIe(dados.ie);
                        setRazaoSocial(dados.razao_social);
                        setNomeFantasia(dados.nome_fantasia);

                    }
                    setDescricao(dados.descricao);

                    //dados de endereco
                    setTipoEndereco(dados.tipo_endereco);
                    setCep(dados.cep);
                    setLogradouro(dados.logradouro);
                    setNumero(dados.numero);
                    setBairro(dados.bairro);
                    setCidade(dados.cidade);
                    setEstado(dados.estado);
                    setComplemento(dados.complemento);
                    setInfoAdicional(dados.info_adicional);
                    setLatitude(dados.latitude);
                    setLongitude(dados.longitude);

                    setEmail(dados.email)

            

                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar fornecedor: " + _err)

            }

        }
        listarFornecedor();


    }, [idFornecedor]);


    function validarRazaoSocial() {
        if (razaoSocial?.length > 0) {
            setErroRazaoSocial(false);
            setTextoErroRazaoSocial('');
            return true;
        } else {
            setErroRazaoSocial(true);
            setTextoErroRazaoSocial("Razão Social Inválida");
            return false;
        }
    }

    function validarNomeFantasia() {
        if (nomeFantasia?.length > 0) {
            setErroNomeFantasia(false);
            setTextoErroNomeFantasia('');
            return true;
        } else {
            setErroNomeFantasia(true);
            setTextoErroNomeFantasia("Nome Fantasia Inválido");
            return false;
        }
    }

    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }


    function validarEndereco() {

        var checklogradouro = false;
        var checkbairro = false;
        var checkcep = false;
        var checkcidade = false;
        var checkestado = false;
        var checkcomplemento = false;


        if (logradouro?.length > 0) {
            setErroLogradouro(false);
            setTextoErroLogradouro('');
            checklogradouro = true;
        } else {
            setErroLogradouro(true);
            setTextoErroLogradouro("Logradouro não pode ser em branco");
            checklogradouro = false;

        }


        if (numero?.length <= 0 || numero === "S/N") {
            setNumero("S/N")
        }

        if (bairro?.length > 0) {
            setErroBairro(false);
            setTextoErroBairro('');
            checkbairro = true;
        } else {
            setErroBairro(true);
            setTextoErroBairro("Bairro não pode ser em branco");
            checkbairro = false;

        }

        if (cidade?.length > 0) {
            setErroCidade(false);
            setTextoErroCidade('');
            checkcidade = true;
        } else {
            setErroCidade(true);
            setTextoErroCidade("Cidade não pode ser em branco");
            checkcidade = false;

        }

        if (estado?.length > 0) {
            setErroEstado(false);
            setTextoErroEstado('');
            checkestado = true;
        } else {
            setErroEstado(true);
            setTextoErroEstado("Estado não pode ser em branco");
            checkestado = false;

        }

        if (cep?.length > 0) {
            setErroCep(false);
            setTextoErroCep('');
            checkcep = true;
        } else {
            setErroCep(true);
            setTextoErroCep("CEP não pode ser em branco");
            checkcep = false;

        }

        if (complemento?.length > 0) {
            setErroComplemento(false);
            setTextoErroComplemento('');
            checkcomplemento = true;
        } else {
            setErroComplemento(true);
            setTextoErroComplemento("Complemento não pode ser em branco");
            checkcomplemento = false;

        }

        return checkestado &&
            checklogradouro &&
            checkbairro &&
            checkcep &&
            checkcidade &&
            checkestado &&
            checkcomplemento;
    }




    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }





    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataNascimento(false);
            setTextoErroDataNascimento("")
        } else {
            setErroDataNascimento(true);
            setTextoErroDataNascimento("Data de Nascimento Inválida!")
        }
        return teste;
    }






    const checkCep = () => {

        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => res.json()).then(data => {
            console.log(data);
            // register({ name: 'address', value: data.logradouro });
            setCidade(data.localidade);
            setEstado(data.uf);
            setLogradouro(data.logradouro);
            setBairro(data.bairro);
            setComplemento(data.complemento);

        });
    }

    async function atualizar() {


        if (tipoFisica) {
            //cadastrar pessoa fisica


            validarRg();
            validarData(dataNascimento);
            validarNome();
            validarSobreNome();
            validarEndereco();

            //prosseguir cadastro
            if (validarRg() && validarData(dataNascimento) && validarNome() &&
                validarSobreNome() && validarEndereco()) {
                console.log("cadastro aceito!")


                try {

                    console.log("cadastrar chamado");
                    const cadastro_fornecedor_pf = {
                        tipo_fornecedor: 0,
                        nome: nome,
                        sobrenome: sobreNome,
                        nascimento: dataNascimento,
                        rg: rg,
                        descricao: descricao,
                        logradouro: logradouro,
                        numero: numero,
                        bairro: bairro,
                        cep: cep,
                        complemento: complemento,
                        cidade: cidade,
                        estado: estado,
                        info_adicional: infoAdicional,
                        latitude: latitude,
                        longitude: longitude,
                     
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/fornecedores/atualizar/' + idFornecedor, cadastro_fornecedor_pf,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Atualizado com sucesso!");

                        history.push({
                            pathname: '/fornecedores',
                        })
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro rejeitado!")
            }





        } else {
            //cadastrar pessoa juridica


            validarRazaoSocial();
            validarNomeFantasia();

            validarEndereco();

            if (validarRazaoSocial() && validarNomeFantasia && validarEndereco()) {
                console.log("cadastro pj aceito!")

                try {

                    console.log("cadastrar chamado");
                    const cadastro_fornecedor_pj = {
                        tipo_fornecedor: 1,
                        razao_social: razaoSocial,
                        nome_fantasia: nomeFantasia,
                        porte: porte,
                        ie: ie,
                        atividade: atividade,
                        descricao: descricao,
                        logradouro: logradouro,
                        numero: numero,
                        bairro: bairro,
                        cep: cep,
                        complemento: complemento,
                        cidade: cidade,
                        estado: estado,
                        info_adicional: infoAdicional,
                        latitude: latitude,
                        longitude: longitude,
                      
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/fornecedores/atualizar/' + idFornecedor, cadastro_fornecedor_pj,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Atualizado com sucesso!");

                        history.push({
                            pathname: '/fornecedores',
                        })
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro pj rejeitado!")
            }

        }



    }







    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Fornecedores={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={atualizar}
                    > Atualizar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                item xs={12}
                            >
                                <Typography  >
                                    Tipo Pessoa:
                                </Typography>
                                <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                />} label="Pessoa Física" />
                                <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                />} label="Pessoa Jurídica" />

                            </Grid>


                            <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>

                                    <Grid item xs={4}>
                                        <TextField
                                            variant="standard"
                                            name="cnpj"
                                            label="CNPJ"
                                            required
                                            id="cnpj"
                                            value={cnpj}
                                            inputProps={{ maxLength: 14, minLength: 14 }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            error={erroRazaoSocial}
                                            helperText={textoErroRazaoSocial}
                                            variant="standard"
                                            name="razaoSocial"
                                            label="Razão Social"
                                            required
                                            id="razaoSocial"
                                            autoComplete="razaoSocial"
                                            value={razaoSocial}
                                            onChange={e => setRazaoSocial(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 1 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            error={erroNomeFantasia}
                                            helperText={textoErroNomeFantasia}
                                            variant="standard"
                                            name="nomeFantasia"
                                            label="Nome Fantasia"
                                            required
                                            id="nomeFantasia"
                                            value={nomeFantasia}
                                            onChange={e => setNomeFantasia(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 1 }}
                                            fullWidth

                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}>

                                        <Grid item xs={4} >

                                            <TextField

                                                variant="standard"
                                                name="porte"
                                                label="Porte"
                                                id="porte"
                                                value={porte}
                                                onChange={e => setPorte(e.target.value)}
                                                inputProps={{ maxLength: 100, minLength: 0 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4} >
                                            <TextField
                                                variant="standard"
                                                name="atividade"
                                                label="Atividade"
                                                id="atividade"
                                                autoComplete="atividade"
                                                value={atividade}
                                                onChange={e => setAtividade(e.target.value)}
                                                inputProps={{ maxLength: 100, minLength: 0 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4}  >
                                            <TextField

                                                variant="standard"
                                                name="ie"
                                                label="Inscrição Estadual"
                                                id="ei"
                                                value={ie}
                                                onChange={e => setIe(e.target.value)}
                                                inputProps={{ maxLength: 20, minLength: 0 }}
                                                fullWidth

                                            />
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Collapse>

                            <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>
                                    <Grid item xs={4}  >

                                        <TextField

                                            variant="standard"
                                            name="cpf"
                                            label="CPF"
                                            required
                                            id="cpf"
                                            value={cpf}
                                            inputProps={{ maxLength: 11, minLength: 11 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroRg}
                                            helperText={textoErroRg}
                                            variant="standard"
                                            name="rg"
                                            label="RG"
                                            required
                                            id="rg"
                                            value={rg}
                                            onChange={e => setRg(e.target.value)}
                                            inputProps={{ maxLength: 20, minLength: 3 }}
                                            fullWidth

                                        />
                                    </Grid>


                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroDataNascimento}
                                            helperText={textoErroDataNascimento}
                                            variant="standard"
                                            name="dataNascimento"
                                            label="Data Nascimento"
                                            required
                                            id="dataNascimento"
                                            value={dataNascimento}
                                            onChange={e => setDataNascimento(e.target.value)}
                                            inputProps={{ maxLength: 20, minLength: 3 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroNome}
                                            helperText={textoErroNome}
                                            variant="standard"
                                            name="nome"
                                            label="Nome"
                                            required
                                            id="nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            inputProps={{ maxLength: 100, minLength: 2 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroSobreNome}
                                            helperText={textoErroSobreNome}
                                            variant="standard"
                                            name="sobrenome"
                                            label="Sobrenome"
                                            required
                                            id="sobrenome"
                                            value={sobreNome}
                                            onChange={e => setSobrenome(e.target.value)}
                                            inputProps={{ maxLength: 100, minLength: 2 }}
                                            fullWidth

                                        />
                                    </Grid>

                                </Grid>
                            </Collapse>



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={8} >
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={e => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        rows={4}

                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid style={{ backgroundColor: 'WhiteSmoke' }} component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>

                                    <Grid
                                        container
                                        direction="row"
                                        item xs={9}
                                        spacing={2}>
                                        <Grid item xs={9}>

                                            <Typography style={{ margin: 15 }} >
                                                <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                            </Typography>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={4}
                                                    justifyContent="flex-end"
                                                    alignItens="flex-end"
                                                >
                                                    <InputLabel style={{ fontSize: 18, color: 'black' }} id="tipo_endereco">Tipo Endereço:</InputLabel>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={7}
                                                    justifyContent="center"
                                                    alignItens="center"
                                                >
                                                    <Select
                                                        labelId="tipo_endereco"
                                                        id="tipo_endereco"
                                                        value={tipo_endereco}
                                                        name="tipo_endereco"
                                                        label="tipo_endereco"
                                                        style={{ width: '100%', paddingLeft: 5, fontSize: 22, color: 'black' }}
                                                    >
                                                        <MenuItem value={0}>{"Normal"}</MenuItem>
                                                        <MenuItem value={1}>{"Edifício/Prédio"}</MenuItem>
                                                        <MenuItem value={2}>{"Condomínio"}</MenuItem>

                                                    </Select>
                                                </Grid>
                                            </Grid>


                                            <TextField
                                                error={erroCep}
                                                id="cep"
                                                helperText={textoErroCep}
                                                variant="standard"
                                                name="cep"
                                                fullWidth
                                                label="CEP"
                                                required
                                                value={cep}
                                                onChange={e => (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) ? setCep(e.target.value) : setTextoErroCep('')}
                                                inputProps={{ maxLength: 40, minLength: 6 }}
                                                onBlur={checkCep}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={9}>
                                        <TextField
                                            error={erroLogradouro}
                                            id="logradouro"
                                            helperText={textoErroLogradouro}
                                            variant="standard"
                                            name="logradouro"
                                            fullWidth
                                            label="Logradouro"
                                            required
                                            value={logradouro}
                                            onChange={e => setLogradouro(e.target.value)}
                                            inputProps={{ maxLength: 40, minLength: 6 }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            id="numero"
                                            helperText={textoErroNumero}
                                            variant="standard"
                                            name="numero"
                                            fullWidth
                                            label="Número"
                                            required
                                            value={numero}
                                            onChange={e => setNumero(e.target.value)}
                                            inputProps={{ maxLength: 40, minLength: 6 }}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            error={erroBairro}
                                            id="bairro"
                                            helperText={textoErroBairro}
                                            variant="standard"
                                            name="bairro"
                                            fullWidth
                                            label="Bairro"
                                            required
                                            value={bairro}
                                            onChange={e => setBairro(e.target.value)}
                                            inputProps={{ maxLength: 40, minLength: 6 }}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            error={erroCidade}
                                            id="cidade"
                                            helperText={textoErroCidade}
                                            variant="standard"
                                            name="cidade"
                                            fullWidth
                                            label="Cidade"
                                            required
                                            value={cidade}
                                            onChange={e => setCidade(e.target.value)}
                                            inputProps={{ maxLength: 40, minLength: 6 }}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            error={erroEstado}
                                            id="estado"
                                            helperText={textoErroEstado}
                                            variant="standard"
                                            name="estado"
                                            fullWidth
                                            label="Estado"
                                            required
                                            value={estado}
                                            onChange={e => setEstado(e.target.value)}
                                            inputProps={{ maxLength: 40, minLength: 6 }}
                                        />
                                    </Grid>

                                    <Grid item xs={8} >
                                        <TextField
                                            error={erroComplemento}
                                            helperText={textoErroComplemento}
                                            variant="standard"
                                            name="complemento"
                                            label="Complemento"
                                            id="complemento"
                                            value={complemento}
                                            onChange={e => setComplemento(e.target.value)}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={8} >
                                        <TextField
                                            variant="standard"
                                            name="infoAdicional"
                                            label="Informação Adicional"
                                            id="infoAdicional"
                                            value={infoAdicional}
                                            onChange={e => setInfoAdicional(e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={4}

                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12} >
                                        <Grid item xs={3}  >
                                            <Typography style={{ margin: 15 }} >
                                                <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Localização: </span>
                                            </Typography>

                                            <TextField
                                                id="latitude"
                                                variant="standard"
                                                name="latitude"
                                                fullWidth
                                                label="Latitude"
                                                required
                                                autoComplete="latitude"
                                                value={latitude}
                                                onChange={e => setLatitude(e.target.value)}
                                                inputProps={{ maxLength: 50, minLength: 1 }}

                                            />
                                        </Grid>

                                        <Grid item xs={3}  >
                                            <Typography style={{ margin: 15 }} >
                                                <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Localização: </span>
                                            </Typography>

                                            <TextField
                                                id="longitude"
                                                variant="standard"
                                                name="longitude"
                                                fullWidth
                                                label="Longitude"
                                                required
                                                autoComplete="longitude"
                                                value={longitude}
                                                onChange={e => setLongitude(e.target.value)}
                                                inputProps={{ maxLength: 50, minLength: 1 }}

                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>


                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>



                                    <Grid item xs={5}  >

                                        <Typography style={{ margin: 15 }} >
                                            <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                        </Typography>

                                        <TextField
                                            id="email"
                                            variant="standard"
                                            name="email"
                                            fullWidth
                                            label="E-Mail"
                                            required
                                            autoComplete="email"
                                            value={email}
                                            inputProps={{ maxLength: 50, minLength: 15 }}

                                        />
                                    </Grid>


                                </Grid>
                            </Grid>

                        </Grid>



                    </React.Fragment>
                }

            </Container>
        </div >
    );


}


