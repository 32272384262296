import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import { useHistory } from "react-router-dom";

import moment from 'moment';
import { MenuItem, Select, InputLabel } from "@material-ui/core";

import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Collapse from '@material-ui/core/Collapse';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastrarModuloSonoro() {

    const classes = useStyles();
    const history = useHistory();

    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');
    const [dataFabricacao, setDataFabricacao] = useState('');
    const [status, setStatus] = useState(0);
    const [emailDataplicity, setEmailDataplicity] = useState('');
    const [senhaDataplicity, setSenhaDataplicity] = useState('');
    const [loading, setLoading] = useState(true);

    const [codigo, setCodigo] = useState('');

    const [tipoAssociacao, setTipoAssociacao] = useState(0);

    const [transmissorSelecionado, setTransmissorSelecionado] = useState(null);
    const [transmissores, setTransmissores] = useState(null);


    const [controladorAcessoSelecionado, setControladorAcessoSelecionado] = useState(null);
    const [controladoresAcesso, setControladoresAcesso] = useState(null);



    const [receptorSelecionado, setReceptorSelecionado] = useState(null);
    const [receptores, setReceptores] = useState(null);



    const [erroCodigo, setErroCodigo] = useState(false);
    const [textoErroCodigo, setTextoErroCodigo] = useState('');

    const [erroMarca, setErroMarca] = useState(false);
    const [textoErroMarca, setTextoErroMarca] = useState('');

    const [erroModelo, setErroModelo] = useState(false);
    const [textoErroModelo, setTextoErroModelo] = useState('');

    const [erroDataFabricacao, setErroDataFabricacao] = useState(false);
    const [textoErroDataFabricacao, setTextoErroDataFabricacao] = useState('');

    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [fornecedores, setFornecedores] = useState(null);

    const [urlBd, setUrlBd] = useState("");
    const [urlMonitor, setUrlMonitor] = useState("");
    const [urlVnc, setUrlVnc] = useState("");
    const [urlVncWeb, setUrlVncWeb] = useState("");
    const [urlSshWeb, setUrlSshWeb] = useState("");


    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataFabricacao(false);
            setTextoErroDataFabricacao("")
        } else {
            setErroDataFabricacao(true);
            setTextoErroDataFabricacao("Data de Fabricação Inválida!")
        }
        return teste;
    }

    function validarMarca() {
        if (marca?.length > 0) {
            setErroMarca(false);
            setTextoErroMarca('');
            return true;
        } else {
            setErroMarca(true);
            setTextoErroMarca("Nome da Marca Inválido!");
            return false;
        }
    }

    function validarModelo() {
        if (modelo?.length > 0) {
            setErroModelo(false);
            setTextoErroModelo('');
            return true;
        } else {
            setErroModelo(true);
            setTextoErroModelo("Nome do Modelo Inválido!");
            return false;
        }
    }

    function validarCodigo() {
        if (codigo?.length > 0) {
            setErroCodigo(false);
            setTextoErroCodigo('');
            return true;
        } else {
            setErroCodigo(true);
            setTextoErroCodigo("Código Inválido!");
            return false;
        }
    }






    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Cliente_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia) +
            "\n Endereço: " + (fornecedor.tipo_endereco === 0 ? "Normal " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
        return texto;
    }


    function returnDescricaoReceptor(receptor) {
        var texto = "  Receptor_ID: " + receptor.id_receptor +
            " | Número de Série: " + receptor.numero_serie +
            " | Marca: " + receptor.marca +
            " | Modelo: " + receptor.modelo
            ;
        return texto;
    }

    function returnDescricaoControladorAcesso(ctrl) {
        var texto = "  Controlador Acesso_ID: " + ctrl.id_controlador_acesso +
            " | Número de Série: " + ctrl.numero_serie +
            " | Marca: " + ctrl.marca +
            " | Modelo: " + ctrl.modelo
            ;
        return texto;
    }

    function returnDescricaoTransmissor(tx) {
        var texto = "  Transmissor_ID: " + tx.id_transmissor +
            " | Número de Série: " + tx.numero_serie +
            " | Marca: " + tx.marca +
            " | Modelo: " + tx.modelo
            ;
        return texto;
    }

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };


    async function handleTipoAssociacao(event) {
        var tipo_associacao = event.target.value;



        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            if (fornecedorSelecionado !== null && fornecedorSelecionado.id_fornecedor > 0) {
                var url = "";
                var id_fornecedor = fornecedorSelecionado.id_fornecedor;
                if (tipo_associacao === 1) {
                    //buscar receptores
                    url = "v1/protected/receptores/listarporfornecedor/" + id_fornecedor;

                    await api.get(url, {
                        headers: headers
                    }).then(function (response) {
                        setReceptores(response.data);

                    });


                } else if (tipo_associacao === 2) {
                    //buscar controladores de acesso
                    url = "v1/protected/controladoresacesso/listarporfornecedor/" + id_fornecedor;

                    await api.get(url, {
                        headers: headers
                    }).then(function (response) {
                        setControladoresAcesso(response.data);

                    });

                } else if (tipo_associacao === 3) {
                    //buscar transmissores
                    url = "v1/protected/transmissores/listarporfornecedor/" + id_fornecedor;

                    await api.get(url, {
                        headers: headers
                    }).then(function (response) {
                        setTransmissores(response.data);

                    });
                }
            }


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

            console.log("Erro ao listar a Módulo Sonoro: " + _err)

        }



        setTipoAssociacao(tipo_associacao);

    };




    async function salvar() {

        validarCodigo();
        validarMarca();
        validarModelo();
        validarData(dataFabricacao);



        if (validarCodigo() && validarMarca() && validarModelo() && validarData(dataFabricacao)) {
            console.log("cadastro aceito!");

            try {

                console.log("cadastrar chamado");
                const cadastro_modulo_sonoro = {
                    codigo: codigo,
                    marca: marca,
                    modelo: modelo,
                    data_fabricacao: dataFabricacao,
                    url_bd: urlBd,
                    url_monitor: urlMonitor,
                    url_vnc: urlVnc,
                    url_vnc_web: urlVncWeb,
                    url_ssh_web: urlSshWeb,
                    status: status,
                    email_dataplicity: emailDataplicity,
                    password_dataplicity: senhaDataplicity,
                    fornecedor: fornecedorSelecionado !== null ? { id_fornecedor: fornecedorSelecionado.id_fornecedor } : null,
                    tipo_associacao: tipoAssociacao,
                    receptor: receptorSelecionado !== null && tipoAssociacao === 1 ? { id_receptor: receptorSelecionado.id_receptor } : null,
                    ctrl: controladorAcessoSelecionado !== null && tipoAssociacao === 2 ? { id_controlador_acesso: controladorAcessoSelecionado.id_controlador_acesso } : null,
                    tx: transmissorSelecionado !== null && tipoAssociacao === 3 ? { id_transmissor: transmissorSelecionado.id_transmissor } : null,



                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.post('/v1/protected/modulosonoro/cadastrar', cadastro_modulo_sonoro,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Módulo Sonoro atualizado com sucesso!")
                    history.push({
                        pathname: '/tecnica/equipamentos/modulossonoros',
                    })
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }

        } else {
            console.log("cadastro rejeitado!");
        }


    }




    useEffect(() => {

        async function listarFornecedores() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.get("/v1/protected/fornecedores/listarTodos", {
                    headers: headers
                }).then(function (response) {

                    setFornecedores(response.data);
                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

                console.log("Erro ao listar Fornecedores " + _err)

            }

        }
        listarFornecedores();


    }, []);

    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Cadastrar Módulo Sonoro"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Grid container spacing={2}   >

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5} >
                                            <Autocomplete
                                                id="select-fornecedor"
                                                options={fornecedores}
                                                disableCloseOnSelect
                                                getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor)}
                                                value={fornecedorSelecionado}
                                                onChange={(e, v) => setFornecedorSelecionado(v)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Fornecedor "

                                                        multiline
                                                        rows={6} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid style={{ backgroundColor: 'white' }} component={Paper} elevation={12}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            spacing={2}>

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">STATUS:</InputLabel>
                                                <Select
                                                    labelId="status"
                                                    id="status"
                                                    value={status}
                                                    name="status"
                                                    label="status"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={handleStatus}
                                                >
                                                    <MenuItem value={0}>LIVRE</MenuItem>
                                                    <MenuItem value={1}>EM MANUTENÇÃO</MenuItem>
                                                    <MenuItem value={2}>INSTALADO</MenuItem>
                                                    <MenuItem value={3}>INUTILIZADO</MenuItem>

                                                </Select>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} >
                                                <Grid item xs={3}  >
                                                    <Typography style={{ margin: 15 }} >
                                                        <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados da Controladora: </span>
                                                    </Typography>

                                                    <TextField
                                                        error={erroCodigo}
                                                        id="codigo"
                                                        helperText={textoErroCodigo}
                                                        variant="standard"
                                                        name="codigo"
                                                        fullWidth
                                                        label="Código"
                                                        required
                                                        autoComplete="codigo"
                                                        value={codigo}
                                                        onChange={e => setCodigo(e.target.value)}
                                                        inputProps={{ maxLength: 50, minLength: 1 }}

                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={3}  >
                                                <TextField
                                                    error={erroMarca}
                                                    id="marca"
                                                    helperText={textoErroMarca}
                                                    variant="standard"
                                                    name="marca"
                                                    fullWidth
                                                    label="Marca"
                                                    required
                                                    autoComplete="marca"
                                                    value={marca}
                                                    onChange={e => setMarca(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                            <Grid item xs={3}  >
                                                <TextField
                                                    error={erroModelo}
                                                    id="modelo"
                                                    helperText={textoErroModelo}
                                                    variant="standard"
                                                    name="modelo"
                                                    fullWidth
                                                    label="Modelo"
                                                    required
                                                    autoComplete="modelo"
                                                    value={modelo}
                                                    onChange={e => setModelo(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                            <Grid item xs={3}  >
                                                <TextField
                                                    error={erroDataFabricacao}
                                                    id="dataFabricacao"
                                                    helperText={textoErroDataFabricacao}
                                                    variant="standard"
                                                    name="dataFabricacao"
                                                    fullWidth
                                                    label="Data de Fabricação"
                                                    required
                                                    autoComplete="dataFabricacao"
                                                    value={dataFabricacao}
                                                    onChange={e => setDataFabricacao(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                        </Grid>

                                    </Grid>







                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid style={{ backgroundColor: 'white' }} component={Paper} elevation={12}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            spacing={2}>

                                            <Grid
                                                item xs={12}
                                                direction="row"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Módulo Sonoro: </span>
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item xs={12}
                                                direction="row"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="tipoAssociacao">Tipo da Associação:</InputLabel>
                                                <Select
                                                    labelId="tipoAssociacao"
                                                    id="tipoAssociacao"
                                                    value={tipoAssociacao}
                                                    name="tipoAssociacao"
                                                    label="tipoAssociacao"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={handleTipoAssociacao}
                                                >
                                                    <MenuItem value={0}>Não Associado</MenuItem>
                                                    <MenuItem value={1}>Associado a um Receptor</MenuItem>
                                                    <MenuItem value={2}>Associado a um Controlador de Acesso</MenuItem>
                                                    <MenuItem value={3}>Associado a um Transmissor</MenuItem>

                                                </Select>
                                            </Grid>



                                            <Grid
                                                item
                                                xs={12}
                                                direction="row"
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                style={{ padding: 20 }}
                                            >

                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <Collapse in={tipoAssociacao === 1} timeout="auto" unmountOnExit>
                                                        <Autocomplete
                                                            id="select-receptor"
                                                            options={receptores}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(receptor) => returnDescricaoReceptor(receptor)}
                                                            value={receptorSelecionado}
                                                            onChange={(e, v) => setReceptorSelecionado(v)}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Receptor "

                                                                    multiline
                                                                    rows={6} />
                                                            )}
                                                        />
                                                    </Collapse>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <Collapse in={tipoAssociacao === 2} timeout="auto" unmountOnExit>

                                                        <Autocomplete
                                                            id="select-ctrl"
                                                            options={controladoresAcesso}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(controlador_acesso) => returnDescricaoControladorAcesso(controlador_acesso)}
                                                            value={controladorAcessoSelecionado}
                                                            onChange={(e, v) => setControladorAcessoSelecionado(v)}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Controlador de Acesso "

                                                                    multiline
                                                                    rows={2} />
                                                            )}
                                                        />

                                                    </Collapse>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <Collapse in={tipoAssociacao === 3} timeout="auto" unmountOnExit>
                                                    <Autocomplete
                                                            id="select-transmissor"
                                                            options={transmissores}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(tx) => returnDescricaoTransmissor(tx)}
                                                            value={transmissorSelecionado}
                                                            onChange={(e, v) => setTransmissorSelecionado(v)}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Transmissor "

                                                                    multiline
                                                                    rows={2} />
                                                            )}
                                                        />
                                                    </Collapse>
                                                </Grid>




                                            </Grid>


                                        </Grid>
                                    </Grid>




                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            spacing={2}>



                                        </Grid>
                                    </Grid>

                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={8}  >

                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Url Serviços: </span>
                                                </Typography>

                                                <TextField
                                                    id="urlBd"
                                                    variant="standard"
                                                    name="urlBd"
                                                    fullWidth
                                                    label="URL da Base de Dados"
                                                    type="urlBd"
                                                    value={urlBd}
                                                    onChange={e => setUrlBd(e.target.value)}

                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="urlMonitor"
                                                        variant="standard"
                                                        name="urlMonitor"
                                                        fullWidth
                                                        label="Url de Acesso ao Monitor"
                                                        type="urlMonitor"
                                                        value={urlMonitor}
                                                        onChange={e => (setUrlMonitor(e.target.value))}
                                                    />
                                                </Grid>
                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="urlVnc"
                                                        variant="standard"
                                                        name="urlVnc"
                                                        fullWidth
                                                        label="Url de Acesso ao Vnc"
                                                        type="urlVnc"
                                                        value={urlVnc}
                                                        onChange={e => (setUrlVnc(e.target.value))}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="urlVncWeb"
                                                        variant="standard"
                                                        name="urlVncWeb"
                                                        fullWidth
                                                        label="Url de Acesso ao Vnc Web"
                                                        type="urlVncWeb"
                                                        value={urlVncWeb}
                                                        onChange={e => (setUrlVncWeb(e.target.value))}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="urlSshWeb"
                                                        variant="standard"
                                                        name="urlSshWeb"
                                                        fullWidth
                                                        label="Url de Acesso ao SSH Web"
                                                        type="urlSshWeb"
                                                        value={urlSshWeb}
                                                        onChange={e => (setUrlSshWeb(e.target.value))}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>


                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >



                                        <Grid style={{ backgroundColor: 'Wheat' }} component={Paper} elevation={12}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={5}  >

                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login Dataplicity: </span>
                                                </Typography>

                                                <TextField
                                                    id="emailDataplicity"
                                                    variant="standard"
                                                    name="emailDataplicity"
                                                    fullWidth
                                                    label="Email Dataplicity"
                                                    required
                                                    autoComplete="emailDataplicity"
                                                    value={emailDataplicity}
                                                    onChange={e => setEmailDataplicity(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        id="senhaDataplicity"
                                                        variant="standard"
                                                        name="senhaDataplicity"
                                                        fullWidth
                                                        label="Senha Dataplicity"
                                                        required
                                                        type="senhaDataplicity"
                                                        value={senhaDataplicity}
                                                        onChange={e => setSenhaDataplicity(e.target.value)}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        item xs={10}
                                    >
                                        <Button style={{ marginTop: 50 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={salvar}
                                        > Salvar  </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >
        </div >
    );
}


