import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory, useParams } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../services/api';
import NavegadorGestao from "../../../../components/NavBarGestao";



import { Select, MenuItem, InputLabel } from '@material-ui/core';

export default function AlterarUsuario() {

    const history = useHistory();


    /* variaveis pessoa fisica */
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [cargo, setCargo] = useState('');
    const { idUsuario } = useParams();
    const [loading, setLoading] = useState(true);

    /* variaveis pessoa fisica */








    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');

    const [urlImg, setUrlImg] = useState('');


    /*variaveis de controle pessoa fisica */


    /*variaveis de endereco*/
    const [tipo_endereco, setTipoEndereco] = useState(0);
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    /*variaveis de endereco*/



    const [sobre, setSobre] = useState('');


    const checkCep = () => {

        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => res.json()).then(data => {
            console.log(data);
            // register({ name: 'address', value: data.logradouro });
            setCidade(data.localidade);
            setEstado(data.uf);
            setLogradouro(data.logradouro);
            setBairro(data.bairro);
            setComplemento(data.complemento);

        });
    }


    const verificaEspaco = (string) => /\s/g.test(string);

    useEffect(() => {

        async function listarUsuario() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/user/retornardadosusuario/" + idUsuario, {
                    headers: headers
                }).then(function (response) {

                    var usuario = response.data;

                    setRg(usuario.rg);
                    setNome(usuario.nome);
                    setSobrenome(usuario.sobrenome);
                    setCargo(usuario.cargo);
                    setUrlImg(usuario.url_img_perfil);
                    setTipoEndereco(usuario.tipo_endereco);
                    setCep(usuario.cep);
                    setLogradouro(usuario.logradouro);
                    setNumero(usuario.numero);
                    setBairro(usuario.bairro);
                    setCidade(usuario.cidade);
                    setEstado(usuario.estado);
                    setComplemento(usuario.complemento);
                    setSobre(usuario.sobre);

                    setLoading(false);


                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

                console.log("Erro ao listar o usuário: " + _err)

            }

        }
        listarUsuario();


    }, [idUsuario]);





    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }





    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }










    async function atualizar() {


        validarRg();
        validarNome();
        validarSobreNome();


        //prosseguir cadastro
        if (validarRg() && validarNome() &&
            validarSobreNome()) {
            console.log("cadastro aceito!")


            try {

                const cadastro = {
                    nome: nome,
                    sobrenome: sobreNome,
                    rg: rg,
                    cargo: cargo,
                    tipo_endereco: parseInt(tipo_endereco),
                    logradouro: logradouro,
                    numero: numero,
                    bairro: bairro,
                    cep: cep,
                    complemento: complemento,
                    cidade: cidade,
                    estado: estado,
                    url_img_perfil: urlImg,
                    sobre: sobre,

                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.put('/v1/protected/users/atualizar/' + idUsuario, cadastro,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Usuário Atualizado com sucesso!")

                    history.push({
                        pathname: '/administracao/usuarios',
                    })
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }
            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }



        } else {
            console.log("cadastro rejeitado!")
        }




    }




    return (
        <div>
            <NavegadorGestao
                Administracao={true}
                Usuarios={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={atualizar}
                    > Atualizar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >





                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={8}  >
                                    <TextField
                                        error={erroRg}
                                        helperText={textoErroRg}
                                        variant="standard"
                                        name="rg"
                                        label="RG"
                                        required
                                        id="rg"
                                        value={rg}
                                        onChange={e => setRg(e.target.value)}
                                        inputProps={{ maxLength: 20, minLength: 3 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={2}  >
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroNome}
                                        helperText={textoErroNome}
                                        variant="standard"
                                        name="nome"
                                        label="Nome"
                                        required
                                        id="nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        inputProps={{ maxLength: 100, minLength: 2 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroSobreNome}
                                        helperText={textoErroSobreNome}
                                        variant="standard"
                                        name="sobrenome"
                                        label="Sobrenome"
                                        required
                                        id="sobrenome"
                                        value={sobreNome}
                                        onChange={e => setSobrenome(e.target.value)}
                                        fullWidth
                                        multiline
                                        rows={3}

                                    />
                                </Grid>

                            </Grid>





                            <Grid
                                container
                                direction="row"
                                item xs={9}
                                spacing={2}>
                                <Grid item xs={9}>

                                    <Typography style={{ margin: 15 }} >
                                        <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                    </Typography>

                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            item xs={4}
                                            justifyContent="flex-end"
                                            alignItens="flex-end"
                                        >
                                            <InputLabel style={{ fontSize: 18, color: 'black' }} id="tipo_endereco">Tipo Endereço:</InputLabel>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            item xs={7}
                                            justifyContent="center"
                                            alignItens="center"
                                        >
                                            <Select
                                                labelId="tipo_endereco"
                                                id="tipo_endereco"
                                                value={tipo_endereco}
                                                name="tipo_endereco"
                                                onChange={e => setTipoEndereco(e.target.value)}
                                                label="tipo_endereco"
                                                style={{ width: '100%', paddingLeft: 5, fontSize: 22, color: 'black' }}
                                            >
                                                <MenuItem value={0}>{"Normal"}</MenuItem>
                                                <MenuItem value={1}>{"Edifício/Prédio"}</MenuItem>
                                                <MenuItem value={2}>{"Condomínio"}</MenuItem>

                                            </Select>
                                        </Grid>
                                    </Grid>


                                    <TextField
                                        id="cep"
                                        variant="standard"
                                        name="cep"
                                        fullWidth
                                        label="CEP"
                                        required
                                        value={cep}
                                        onChange={e => (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) ? setCep(e.target.value) : null}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                        onBlur={checkCep}
                                    />
                                </Grid>
                            </Grid>


                            <Grid item xs={9}>
                                <TextField
                                    id="logradouro"
                                    variant="standard"
                                    name="logradouro"
                                    fullWidth
                                    label="Logradouro"
                                    required
                                    value={logradouro}
                                    onChange={e => setLogradouro(e.target.value)}
                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    id="numero"
                                    variant="standard"
                                    name="numero"
                                    fullWidth
                                    label="Número"
                                    required
                                    value={numero}
                                    onChange={e => setNumero(e.target.value)}
                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id="bairro"
                                    variant="standard"
                                    name="bairro"
                                    fullWidth
                                    label="Bairro"
                                    required
                                    value={bairro}
                                    onChange={e => setBairro(e.target.value)}
                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id="cidade"
                                    variant="standard"
                                    name="cidade"
                                    fullWidth
                                    label="Cidade"
                                    required
                                    value={cidade}
                                    onChange={e => setCidade(e.target.value)}
                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id="estado"
                                    variant="standard"
                                    name="estado"
                                    fullWidth
                                    label="Estado"
                                    required
                                    value={estado}
                                    onChange={e => setEstado(e.target.value)}
                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                />
                            </Grid>

                            <Grid item xs={8} >
                                <TextField
                                    variant="standard"
                                    name="complemento"
                                    label="Complemento"
                                    id="complemento"
                                    value={complemento}
                                    onChange={e => setComplemento(e.target.value)}
                                    fullWidth

                                />
                            </Grid>





                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                            >


                                <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>



                                    <Grid item xs={5}  >

                                        <Typography style={{ margin: 15 }} >
                                            <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                        </Typography>

                                        <TextField
                                            id="cargo"
                                            variant="standard"
                                            name="cargo"
                                            fullWidth
                                            label="Cargo"
                                            required
                                            autoComplete="cargo"
                                            value={cargo}
                                            onChange={e => setCargo(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 15 }}

                                        />



                                        <TextField
                                            id="urlImg"
                                            variant="standard"
                                            name="urlImg"
                                            fullWidth
                                            label="Url Imagem do Perfil"
                                            required
                                            autoComplete="urlImg"
                                            value={urlImg}
                                            onChange={e => verificaEspaco(e.target.value) ? null : setUrlImg(e.target.value)}

                                        />

                                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                                            <img width={200} height={200} alt={"imgPerfil"} style={{ textAlign: 'center' }} src={urlImg}></img>
                                        </div>



                                        <TextField
                                            id="sobre"
                                            variant="standard"
                                            name="sobre"
                                            fullWidth
                                            label="Sobre"
                                            required
                                            autoComplete="sobre"
                                            value={sobre}
                                            onChange={(e) => setSobre(e.target.value)}

                                        />



                                    </Grid>


                                </Grid>
                            </Grid>




                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


