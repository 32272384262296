import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import {  useParams } from "react-router-dom";


import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { FormControl, FormHelperText } from '@material-ui/core';



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'blue',
        borderColor: 'blue',
    },
}));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function Tokens() {

    const classes = useStyles();
    const { idControladorAcesso } = useParams();


    const [loading, setLoading] = useState(true);
    const [tokens, setTokens] = useState([]);

    const [alertOpen, setAlertOpen] = useState(false);

    const [tokenVizualizar, setTokenVizualizar] = useState(0);

    const [motivoToken, setMotivoToken] = useState('');
    const [erroMotivoToken, setErroMotivoToken] = useState(false);
    const [textoErroMotivoToken, setTextoErroMotivoToken] = useState('');

    const [utilizadores, setUtilizadores] = useState('');
    const [erroUtilizadores, setErroUtilizadores] = useState(false);
    const [textoErroUtilizadores, setTextoErroUtilizadores] = useState('');


    const [senha, setSenha] = useState('');
    const [erroSenha, setErroSenha] = useState(false);
    const [textoErroSenha, setTextoErroSenha] = useState('');

    const [checkTermos, setCheckTermos] = React.useState(false);
    const [erroTermos, setErroTermos] = useState(false);
    const [textoErroTermos, setTextoErroTermos] = useState('');


    const handleOpenAlert = (idToken) => {
        setTokenVizualizar(idToken);

        setMotivoToken('');
        setErroMotivoToken(false);
        setTextoErroMotivoToken('');


        setUtilizadores('');
        setErroUtilizadores(false);
        setTextoErroUtilizadores('');

        setSenha('');
        setErroSenha(false);
        setTextoErroSenha('');


        setCheckTermos(false);
        setErroTermos(false);
        setTextoErroTermos('');

        setAlertOpen(true);
    };


    function validateSenha() {

        if (senha.length > 2) {

            setErroSenha(false);
            setTextoErroSenha('');
            return true;
        } else {

            setErroSenha(true);
            setTextoErroSenha("Revise esse campo");

            return false;
        }
    }


    function validateTermos() {

        if (checkTermos) {

            setErroTermos(false);
            setTextoErroTermos('');
            return true;
        } else {

            setErroTermos(true);
            setTextoErroTermos("Concorde com os termos");

            return false;
        }
    }


    function validadeUtilizadores() {

        if (utilizadores.length > 10) {

            setErroUtilizadores(false);
            setTextoErroUtilizadores('');
            return true;
        } else {

            setErroUtilizadores(true);
            setTextoErroUtilizadores("Campo de Utilizadores muito curto, informe ao menos um utilizador e identificação(CPF, RG)");

            return false;
        }
    }


    function validateMotivo() {

        if (motivoToken.length > 10) {

            setErroMotivoToken(false);
            setTextoErroMotivoToken('');
            return true;
        } else {

            setErroMotivoToken(true);
            setTextoErroMotivoToken("Campo de motivo muito curto, informe claramente o motivo da vizualização do Token");

            return false;
        }
    }



    async function handleCloseAlert() {

        try {

            validateMotivo();
            validadeUtilizadores();
            validateTermos();
            validateSenha();


            if (validateMotivo() && validadeUtilizadores() &&  validateTermos() &&  validateSenha()  ) {

                
            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = Cookies.get('id_usuario');


            const response = await api.put('/v1/protected/token/vizualizarToken/' + tokenVizualizar + "/" + motivoToken + "/" + utilizadores + "/"  + senha + "/" + id_usuario_logado,
                { headers: headers });

            


            console.log("resposta: " + response.data);

            if (response.data === "senha incorreta") {
                setErroSenha(true);
                setTextoErroSenha("Senha Incorreta");
            }else if(response.data === "token null"){
                alert("Token nullo, atualize a página para receber a lista de tokens mais recentes para este Controaldor de Acesso");
            }else if(response.data === "token vizualizado"){
                alert("Você já pode vizualizar o token!");
                handleCancel();
            } 
        }

        } catch (_err) {

        }




    }

    function handleCancel() {

        setTokenVizualizar(0);

        setMotivoToken('');
        setErroMotivoToken(false);
        setTextoErroMotivoToken('');


        setUtilizadores('');
        setErroUtilizadores(false);
        setTextoErroUtilizadores('');

        setSenha('');
        setErroSenha(false);
        setTextoErroSenha('');


        setCheckTermos(false);
        setErroTermos(false);
        setTextoErroTermos('');

        setAlertOpen(false);
        listarTokens();

        
    }

    
    async function listarTokens() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const id_usuario_logado = Cookies.get('id_usuario');


            await api.get("v1/protected/controladoresacesso/listartokens/" + idControladorAcesso + "/" + id_usuario_logado, {
                headers: headers
            }).then(function (response) {

                var ctrl = response.data;

                setTokens(ctrl);

                setLoading(false);

            });




        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

            console.log("Erro ao listar tokens de acesso: " + _err)

        }

    }


    useEffect(() => {

        async function listarTokens() {

            setLoading(true);
    
            try {
                const token = Cookies.get('token');
    
                const headers = {
                    'Authorization': 'Bearer ' + token
                }
    
                const id_usuario_logado = Cookies.get('id_usuario');
    
    
                await api.get("v1/protected/controladoresacesso/listartokens/" + idControladorAcesso + "/" + id_usuario_logado, {
                    headers: headers
                }).then(function (response) {
    
                    var ctrl = response.data;
    
                    setTokens(ctrl);
    
                    setLoading(false);
    
                });
    
    
    
    
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
    
                console.log("Erro ao listar tokens de acesso: " + _err)
    
            }
    
        }
        listarTokens();


    }, [idControladorAcesso]);

    function maskText(text) {
        const stringText = text.toString();
        return stringText.replace(/./g, '*');
    }

    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Tokens de Acesso"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item xs={12}
                                >


                                    {
                                        tokens.map((token) =>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                item xs={2}
                                                style={{ padding: 5, margin: 5 }}
                                                component={Paper} elevation={6}
                                                key={token.id_token}
                                            >

                                                <span style={{ paddingLeft: 10, fontSize: '35px', fontWeight: 'bold', textAlign: 'center' }} >{token.vizualizada === 0 ? maskText(token.codigo) : token.codigo}</span>
                                                <IconButton aria-label="visualizar"
                                                disabled={token.vizualizada === 0 ? false : true}
                                                    onClick={(e) => handleOpenAlert(token.id_token)}
                                                >
                                                    <VisibilityIcon style={{ color: 'red', fontSize: 28 }} />
                                                </IconButton>

                                            </Grid>
                                        )

                                    }






                                </Grid>

                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >

            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle
                    style={{ backgroundColor: 'black', color: 'white' }}
                    id="confirm-dialog"> Vizualizar Token</DialogTitle>
                <DialogContent
                    style={{ backgroundColor: 'black' }}
                >
                    <div>
                        <AppBar
                            style={{ backgroundColor: 'black', color: 'white' }}

                            sx={{ position: 'relative' }}>

                            <Grid container spacing={1}

                                style={{ backgroundColor: 'black', color: 'white' }}

                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    id="motivoToken"
                                    variant="standard"
                                    error={erroMotivoToken}
                                    helperText={textoErroMotivoToken}
                                    name="motivoToken"
                                    fullWidth
                                    label="Motivo da Vizualização deste Token"
                                    required
                                    autoComplete="motivoToken"
                                    value={motivoToken}
                                    onChange={e => setMotivoToken(e.target.value)}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}
                                    placeholder="Informe o motivo da vizualização do Token"


                                />


                                <TextField
                                    id="utilizadores"
                                    error={erroUtilizadores}
                                    helperText={textoErroUtilizadores}
                                    variant="standard"
                                    name="utilizadores"
                                    fullWidth
                                    label="Utilizadores"
                                    required
                                    autoComplete="utilizadores"
                                    value={utilizadores}
                                    multiline
                                    rows={5}
                                    onChange={e => setUtilizadores(e.target.value)}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}
                                    placeholder="Informe o nome e identificação de quem irá utilizar o Token"


                                />

                                <FormControl error={erroTermos}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"

                                                checked={checkTermos}
                                                onChange={(event) => setCheckTermos(event.target.checked)}
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: 'white' }} />}

                                            />
                                        }
                                        label={"Concordo que é de minha total responsabilidade quaisquer incidentes que venham a ocorrer após a liberação do token, e consequentemente liberação da entrada de pessoal as dependecias do local de instalação desse controlador de acesso"}
                                        error={erroTermos}
                                        helperText={textoErroTermos}
                                    />
                                    <FormHelperText>{textoErroTermos}</FormHelperText>
                                </FormControl>



                                <TextField
                                    error={erroSenha}
                                    id="password"
                                    helperText={textoErroSenha}
                                    variant="standard"
                                    name="password"
                                    fullWidth
                                    label="Senha"
                                    required
                                    type="password"
                                    autoComplete="current-password"
                                    value={senha}
                                    onChange={e => setSenha(e.target.value)}
                                    inputProps={{ maxLength: 15, minLength: 8, style: { color: 'white' } }}
                                    InputLabelProps={{ className: classes.entrada, style: { color: 'white' } }}
                                    style={{ paddingTop: 5, paddingBottom: 10 }}

                                />




                                <Grid

                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                >

                                    <ColorButtonRed style={{ margin: 20 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCancel}

                                    >
                                        Cancelar
                                    </ColorButtonRed>

                                    <ColorButtonGreen style={{ margin: 20 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCloseAlert}

                                    >
                                        ok
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >

        </div >
    );
}


