import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory, useParams } from "react-router-dom";

import { MenuItem, Select, InputLabel } from "@material-ui/core";

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import { styled } from '@material-ui/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastroZona() {

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { idReceptor } = useParams();

    const [numeroZona, setNumeroZona] = useState(1);
    const [tipoZona, setTipoZona] = useState(0);
    const [zonaDupla, setZonaDupla] = useState(0);
    const [canalTransmissor, setCanalTransmissor] = useState(1);
    const [tipoTransmissor, setTipoTransmissor] = useState(0);

    const [habilitarEscolhaTransmissor, setHabilitarEscolhaTransmissor] = useState(true);
    const [habilitarEscolhaCtrl, setHabilitarEscolhaCtrl] = useState(false);


    const [transmissorSelecionado, setTransmissorSelecionado] = useState(null);
    const [transmissores, setTransmissores] = useState(null);



    const [controladorAcessoSelecionado, setControladoresAcessoSelecionado] = useState(null);
    const [controladoresAcesso, setControladoresAcesso] = useState(null);


    function returnDescricaoTransmissor(transmissor) {
        var texto =
            "  Transmissor_ID: " + transmissor.id_transmissor
            + "\n Número de Série:" + transmissor.numero_serie
            + "\n Marca:" + transmissor.marca
            + "\n Modelo: " + transmissor.modelo;
        return texto;
    }


    function returnDescricaoControladorAcesso(ctrl) {
        var texto =
            "  CONTROLADOR_ACESSO_ID: " + ctrl.id_controlador_acesso
            + "\n Número de Série:" + ctrl.numero_serie
            + "\n Marca:" + ctrl.marca
            + "\n Modelo: " + ctrl.modelo;
        return texto;
    }







    const handleTipoZona = (event) => {
        setTipoZona(event.target.value);
    };

    const handleZonaDupla = (event) => {
        setZonaDupla(event.target.value);
    };


    const handleNumeroZona = (event) => {
        setNumeroZona(event.target.value);
    };

    const handleCanalTransmissor = (event) => {
        setCanalTransmissor(event.target.value);
    };










    useEffect(() => {

        async function listarTransmissoresPorReceptor() {
            try {

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.get("/v1/protected/transmissores/listarporreceptor/" + idReceptor, {
                    headers: headers
                }).then(function (response) {
                    setTransmissores(response.data)
                });
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar Transmissores: " + _err)

            }


        }




        async function listarControladoresAcessoPorReceptor() {
            try {

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.get("/v1/protected/controladoresacesso/listarporreceptor/" + idReceptor, {
                    headers: headers
                }).then(function (response) {
                    setControladoresAcesso(response.data)
                    setLoading(false);
                });
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar ctrls de acesso: " + _err)

            }


        }

        listarTransmissoresPorReceptor();

        listarControladoresAcessoPorReceptor();


    }, [idReceptor]);


    const [time, setTime] = useState(15);
    const [habilitarBotao, setHabilitarBotao] = useState(true);
    const [alertOpen, setAlertOpen] = useState(false);
    const [started, setStarted] = useState(false);
    const [textoAdicaoZona, setTextoAdicaoZona] = useState("");

    const [idAcao, setIdAcao] = useState(0);

    useEffect(() => {
        let timer;
        if (started && time > 0) {
            timer = setInterval(() => {
                setTime((time) => time - 1);
            }, 1000);
        }

        if (time === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [time, started]);


    useEffect(() => {
        if (time === 0) {
            verificarPorAcaoRespondida(idAcao)
        }
    }, [time, idAcao]);


    const handleOpenAlert = () => {
        setTime(15);
        setStarted(true);
        setAlertOpen(true);
    };

    const handleCloseAlert = () => {

        setStarted(false);
        setAlertOpen(false);

        history.push({
            pathname: '/gerenciarreceptor/' + idReceptor,
        })

    }



    const handleTipoTransmissor = (tipo) => {


        if (tipo === 0) {
            setHabilitarEscolhaTransmissor(true);
            setHabilitarEscolhaCtrl(false);
        } else if (tipo === 1) {
            setHabilitarEscolhaTransmissor(false);
            setHabilitarEscolhaCtrl(true);
        }

        setTipoTransmissor(tipo);

    };


    async function verificarPorAcaoRespondida(idAcao) {
        try {


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.get('/v1/protected/acao/verificarrespondida/' + idAcao,
                { headers: headers });

            console.log("resposta: " + response.data);

            if (response.data) {
                console.log('Ação Respondida');
                setTextoAdicaoZona("A zona foi adicionada no receptor, pressione ok para continuar");

                setHabilitarBotao(false);
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }
    }


    async function salvar() {

        if (( tipoTransmissor === 0 &&  transmissorSelecionado != null) || (tipoTransmissor === 1 && controladorAcessoSelecionado != null)) {
            //buscar por zonaexistente
            const cadastro_zona = {
                numero_zona: numeroZona,
                tipo_zona: tipoZona,
                zona_dupla: zonaDupla,
                tipo_transmissor: tipoTransmissor,
                transmissor: tipoTransmissor === 0 ?  { id_transmissor: transmissorSelecionado.id_transmissor } : null,
                controladoracesso: tipoTransmissor === 1 ? {id_controlador_acesso : controladorAcessoSelecionado.id_controlador_acesso } : null, 
                receptor: { id_receptor: idReceptor },
                canal_transmissor: tipoTransmissor === 0 ? canalTransmissor : 1,
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }


            let url = tipoTransmissor === 0 ? '/v1/protected/zonas/buscarportransmissorzonaexistente/' + transmissorSelecionado.numero_serie :
            '/v1/protected/zonas/buscarporctrlzonaexistente/' + controladorAcessoSelecionado.numero_serie;
            
            let response = await api.post(url, cadastro_zona,
                { headers: headers });
            const zona_existente = response.data;

            if (!zona_existente) {


                if (window.confirm("Adicionar nova Zona? \n\nCuidado, ao adicionar uma nova zona a configuração aqui refletira no receptor, o que poderá ocasionar falhas no sistema de alarme, só adicione se tiver certeza dessa operação! ")) {

                    try {



                        const response2 = await api.post('/v1/protected/acao/cadastrarzona', cadastro_zona,
                            { headers: headers });

                        const cadastro_salvo = response2.data;
                        if (cadastro_salvo > 0) {
                            setIdAcao(cadastro_salvo);
                            setTextoAdicaoZona("A Ação de Configuração de Nova Zona foi enviada ao banco de dados, aguarde alguns segundos pela resposta do Receptor\nId da Requisição: " + cadastro_salvo);

                            handleOpenAlert();

                        } else {
                            alert("A ação não foi enviada ao banco de dados!");
                        }



                    } catch (_err) {
                        console.log("erro ao cadastrar: " + _err);
                        alert("Erro de Conexão, tente novamente mais tarde");

                    }


                }
            } else {
                alert("A configuração escolhida já existe no receptor!");

            }
        } else {
            if(tipoTransmissor === 0)
            alert("Selecione o transmissor!");
            else if(tipoTransmissor === 1)
            alert(("Selecione o Controlador de Acesso"))
        }
    }



    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Cadastrar Nova Zona"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Grid container spacing={2}   >





                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >


                                        <Grid
                                            item xs={3}
                                            direction="column"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >

                                            <InputLabel id="numero_zona">Número da Zona:</InputLabel>
                                            <Select
                                                labelId="numeroZona"
                                                id="numeroZona"
                                                value={numeroZona}
                                                name="numeroZona"
                                                label="Número da Zona"
                                                style={{ paddingLeft: 5 }}
                                                onChange={handleNumeroZona}
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={11}>11</MenuItem>
                                                <MenuItem value={12}>12</MenuItem>
                                                <MenuItem value={13}>13</MenuItem>
                                                <MenuItem value={14}>14</MenuItem>
                                                <MenuItem value={15}>15</MenuItem>
                                                <MenuItem value={16}>16</MenuItem>
                                                <MenuItem value={17}>17</MenuItem>
                                                <MenuItem value={18}>18</MenuItem>
                                                <MenuItem value={19}>19</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>

                                            </Select>
                                        </Grid>


                                        <Grid
                                            item xs={3}
                                            direction="column"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >

                                            <InputLabel id="status">Tipo da Zona:</InputLabel>
                                            <Select
                                                labelId="tipoZona"
                                                id="tipoZona"
                                                value={tipoZona}
                                                name="tipoZona"
                                                label="Tipo Zona"
                                                style={{ paddingLeft: 5 }}
                                                onChange={handleTipoZona}
                                            >
                                                <MenuItem value={0}>COM FIO</MenuItem>
                                                <MenuItem value={1}>SEM FIO</MenuItem>

                                            </Select>
                                        </Grid>

                                        <Grid
                                            item xs={3}
                                            direction="column"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >

                                            <InputLabel id="status">Configuração da Zona:</InputLabel>
                                            <Select
                                                labelId="zonaDupla"
                                                id="zonaDupla"
                                                value={zonaDupla}
                                                name="zonaDupla"
                                                label="Configuração da Zona"
                                                style={{ paddingLeft: 5 }}
                                                onChange={handleZonaDupla}
                                            >
                                                <MenuItem value={0}>BAIXA, 2.2k</MenuItem>
                                                <MenuItem value={1}>ALTA, 3.3k</MenuItem>

                                            </Select>
                                        </Grid>


                                    </Grid>



                                    <Grid container spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                        style={{ margin: 20 }}
                                    >
                                        <Grid item xs={4}>
                                            <Typography style={{ textAlign: 'right' }}>
                                                Tipo do Equipamento:
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8} >
                                            <FormControl>
                                                <RadioGroup
                                                    name="controlled-radio-buttons-group"
                                                    value={tipoTransmissor}
                                                    onChange={(e) => handleTipoTransmissor(Number(e.target.value))}

                                                >
                                                    <FormControlLabel value={0} control={<Radio />} label="Transmissor" />
                                                    <FormControlLabel value={1} control={<Radio />} label="Controlador de Acesso" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>


                                    {habilitarEscolhaTransmissor ?

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5} >
                                                <Autocomplete
                                                    id="select-fornecedor"
                                                    options={transmissores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(transmissor) => returnDescricaoTransmissor(transmissor)}
                                                    value={transmissorSelecionado}
                                                    onChange={(e, v) => setTransmissorSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Transmissores que respondem a esse receptor "

                                                            multiline
                                                            rows={6} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        : null
                                    }


                                    {habilitarEscolhaCtrl ?

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5} >
                                                <Autocomplete
                                                    id="select-fornecedor"
                                                    options={controladoresAcesso}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(ctrl) => returnDescricaoControladorAcesso(ctrl)}
                                                    value={controladorAcessoSelecionado}
                                                    onChange={(e, v) => setControladoresAcessoSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Controladores de Acesso que respondem a esse receptor "

                                                            multiline
                                                            rows={6} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        : null
                                    }







                                    {habilitarEscolhaTransmissor ?
                                        <Grid
                                            item xs={12}
                                            direction="row"
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ padding: 20 }}
                                        >

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="numero_zona">Canal do Equipamento:</InputLabel>
                                                <Select
                                                    labelId="canalTransmissor"
                                                    id="canalTransmissor"
                                                    value={canalTransmissor}
                                                    name="canalTransmissor"
                                                    label="Canal do Transmissor"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={handleCanalTransmissor}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>

                                                </Select>
                                            </Grid>
                                        </Grid>
                                        : null}


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        item xs={10}
                                    >
                                        <Button style={{ marginTop: 50 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={salvar}

                                        > Salvar  </Button>
                                    </Grid>

                                </Grid>


                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >
            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle id="confirm-dialog">{ }</DialogTitle>
                <DialogContent>
                    <div>
                        <AppBar sx={{ position: 'relative' }}>

                            <Grid container spacing={1} style={{ padding: 20 }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span>
                                    {textoAdicaoZona}
                                </span>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    disable={habilitarBotao}
                                    onClick={handleCloseAlert}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {time > 0 ? time : "OK"}
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >
        </div >
    );
}


