import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from "react-router-dom";

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";



export default function AlterarCliente() {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { idCliente } = useParams();

    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */



    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    /* variaveis de estado de cadastro de pessoa juridica*/


    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');
    /*variaveis gerais*/

    const [parametrosEndereco, setParametrosEndereco] = useState(
        {
            rua: "",
            bloco: "",
            numero: "",
            apartamento: "",
            complemento: "",
        }
    );

    const [infoAdicional, setInfoAdicional] = useState('');


    /*variaveis de controle pessoa fisica */

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');


    const [erroDataNascimento, setErroDataNascimento] = useState(false);
    const [textoErroDataNascimento, setTextoErroDataNascimento] = useState('');

    /*variaveis de controle pessoa fisica */


    /*variaveis de controle pessoa juridica */
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [textoErroRazaoSocial, setTextoErroRazaoSocial] = useState('');

    const [erroNomeFantasia, setErroNomeFantasia] = useState(false);
    const [textoErroNomeFantasia, setTextoErroNomeFantasia] = useState('');

    /*variaveis de controle pessoa juridica */



    /* variaveis  de controle gerais */

    /* variaveis  de controle gerais */



    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [fornecedores, setFornecedores] = useState(null);

    async function listarFornecedores() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/fornecedores/listarTodos", {
                headers: headers
            }).then(function (response) {
                setFornecedores(response.data)
                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Fornecedores: " + _err)

        }

    }


    useEffect(() => {
        listarFornecedores();

        setLoading(true);

        async function listarCliente() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/retornardadoscliente/" + idCliente, {
                    headers: headers
                }).then(function (response) {
                    var dados = response.data;

                    if (dados.tipo_cliente === 0) {

                        setTipoFisica(true);
                        setTipoJuridica(false);

                        setCpf(dados.cpf);
                        setRg(dados.rg);
                        setDataNascimento(dados.nascimento);
                        setNome(dados.nome);
                        setSobrenome(dados.sobrenome);


                    } else {

                        setTipoFisica(false);
                        setTipoJuridica(true);

                        setCnpj(dados.cnpj)
                        setPorte(dados.porte);
                        setAtividade(dados.atividade);
                        setIe(dados.ie);
                        setRazaoSocial(dados.razao_social);
                        setNomeFantasia(dados.nome_fantasia);

                    }

                    setFornecedorSelecionado(dados.fornecedor)

                    setDescricao(dados.descricao);


                    setParametrosEndereco(prevState => ({ ...prevState, 'rua': dados.rua }))
                    setParametrosEndereco(prevState => ({ ...prevState, 'bloco': dados.bloco }))
                    setParametrosEndereco(prevState => ({ ...prevState, 'numero': dados.numero }))
                    setParametrosEndereco(prevState => ({ ...prevState, 'apartamento': dados.apartamento }))
                    setParametrosEndereco(prevState => ({ ...prevState, 'complemento': dados.complemento }))


                    setEmail(dados.email)

                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar fornecedor: " + _err)

            }

        }
        listarCliente();


    }, [idCliente]);


    function validarRazaoSocial() {
        if (razaoSocial?.length > 0) {
            setErroRazaoSocial(false);
            setTextoErroRazaoSocial('');
            return true;
        } else {
            setErroRazaoSocial(true);
            setTextoErroRazaoSocial("Razão Social Inválida");
            return false;
        }
    }

    function validarNomeFantasia() {
        if (nomeFantasia?.length > 0) {
            setErroNomeFantasia(false);
            setTextoErroNomeFantasia('');
            return true;
        } else {
            setErroNomeFantasia(true);
            setTextoErroNomeFantasia("Nome Fantasia Inválido");
            return false;
        }
    }

    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }





    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }






    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataNascimento(false);
            setTextoErroDataNascimento("")
        } else {
            setErroDataNascimento(true);
            setTextoErroDataNascimento("Data de Nascimento Inválida!")
        }
        return teste;
    }









    async function atualizar() {

        if (tipoFisica) {
            //cadastrar pessoa fisica

            validarRg();
            validarData(dataNascimento);
            validarNome();
            validarSobreNome();

            console.log(validarData(dataNascimento));
            //prosseguir cadastro
            if (validarRg() && validarData(dataNascimento) && validarNome() &&
                validarSobreNome()) {
                console.log("cadastro aceito!")

                try {
                    const cadastro_cliente_pf = {
                        tipo_cliente: 0,
                        nome: nome,
                        sobrenome: sobreNome,
                        nascimento: dataNascimento,
                        rg: rg,
                        descricao: descricao,
                        info_adicional: infoAdicional,
                        rua: parametrosEndereco.rua,
                        bloco: parametrosEndereco.bloco,
                        numero: parametrosEndereco.numero,
                        apartamento: parametrosEndereco.apartamento,
                        complemento: parametrosEndereco.complemento,
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/clientes/atualizar/' + idCliente, cadastro_cliente_pf,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Cliente atualizado com sucesso!")

                        history.push({
                            pathname: '/clientes',
                        })
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro rejeitado!")
            }



        } else {
            //cadastrar pessoa juridica

            validarRazaoSocial();
            validarNomeFantasia();


            //prosseguir cadastro
            if (validarRazaoSocial() && validarNomeFantasia) {
                console.log("cadastro pj aceito!")

                try {

                    console.log("cadastrar chamado");
                    const cadastro_cliente_pj = {
                        tipo_cliente: 1,
                        razao_social: razaoSocial,
                        nome_fantasia: nomeFantasia,
                        porte: porte,
                        atividade: atividade,
                        descricao: descricao,
                        info_adicional: infoAdicional,
                        rua: parametrosEndereco.rua,
                        bloco: parametrosEndereco.bloco,
                        numero: parametrosEndereco.numero,
                        apartamento: parametrosEndereco.apartamento,
                        complemento: parametrosEndereco.complemento,
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/clientes/atualizar/' + idCliente, cadastro_cliente_pj,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Cliente atualizado com sucesso!")
                        history.push({
                            pathname: '/clientes',
                        })
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro pj rejeitado!")
            }

        }
    }


    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia) +
            "\n Endereço: " + (fornecedor.tipo_endereco === 0 ? "Normal " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
        return texto;
    }



    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={atualizar}
                    > Atualizar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 20 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={5} >
                                    <Autocomplete
                                        id="select-fornecedor"
                                        options={fornecedores}
                                        disableCloseOnSelect
                                        getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor)}
                                        value={fornecedorSelecionado}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Fornecedor "

                                                multiline
                                                rows={6} />
                                        )}
                                    />
                                </Grid>
                            </Grid>




                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                item xs={12}
                            >
                                <Typography  >
                                    Tipo Pessoa:
                                </Typography>
                                <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                />} label="Pessoa Física" />
                                <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                />} label="Pessoa Jurídica" />

                            </Grid>



                            <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>

                                    <Grid item xs={4}>
                                        <TextField

                                            variant="standard"
                                            name="cnpj"
                                            label="CNPJ"
                                            required
                                            id="cnpj"
                                            value={cnpj}
                                            inputProps={{ maxLength: 14, minLength: 14 }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            error={erroRazaoSocial}
                                            helperText={textoErroRazaoSocial}
                                            variant="standard"
                                            name="razaoSocial"
                                            label="Razão Social"
                                            required
                                            id="razaoSocial"
                                            autoComplete="razaoSocial"
                                            value={razaoSocial}
                                            onChange={e => setRazaoSocial(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 1 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            error={erroNomeFantasia}
                                            helperText={textoErroNomeFantasia}
                                            variant="standard"
                                            name="nomeFantasia"
                                            label="Nome Fantasia"
                                            required
                                            id="nomeFantasia"
                                            value={nomeFantasia}
                                            onChange={e => setNomeFantasia(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 1 }}
                                            fullWidth

                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}>

                                        <Grid item xs={4} >

                                            <TextField

                                                variant="standard"
                                                name="porte"
                                                label="Porte"
                                                id="porte"
                                                value={porte}
                                                onChange={e => setPorte(e.target.value)}
                                                inputProps={{ maxLength: 100, minLength: 0 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4} >
                                            <TextField
                                                variant="standard"
                                                name="atividade"
                                                label="Atividade"
                                                id="atividade"
                                                autoComplete="atividade"
                                                value={atividade}
                                                onChange={e => setAtividade(e.target.value)}
                                                inputProps={{ maxLength: 100, minLength: 0 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4}  >
                                            <TextField

                                                variant="standard"
                                                name="ie"
                                                label="Inscrição Estadual"
                                                id="ei"
                                                value={ie}
                                                onChange={e => setIe(e.target.value)}
                                                inputProps={{ maxLength: 20, minLength: 0 }}
                                                fullWidth

                                            />
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Collapse>

                            <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>
                                    <Grid item xs={4}  >

                                        <TextField

                                            variant="standard"
                                            name="cpf"
                                            label="CPF"
                                            required
                                            id="cpf"
                                            value={cpf}
                                            inputProps={{ maxLength: 11, minLength: 11 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroRg}
                                            helperText={textoErroRg}
                                            variant="standard"
                                            name="rg"
                                            label="RG"
                                            required
                                            id="rg"
                                            value={rg}
                                            onChange={e => setRg(e.target.value)}
                                            inputProps={{ maxLength: 20, minLength: 3 }}
                                            fullWidth

                                        />
                                    </Grid>


                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroDataNascimento}
                                            helperText={textoErroDataNascimento}
                                            variant="standard"
                                            name="dataNascimento"
                                            label="Data Nascimento"
                                            required
                                            id="dataNascimento"
                                            value={dataNascimento}
                                            onChange={e => setDataNascimento(e.target.value)}
                                            inputProps={{ maxLength: 20, minLength: 3 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroNome}
                                            helperText={textoErroNome}
                                            variant="standard"
                                            name="nome"
                                            label="Nome"
                                            required
                                            id="nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            inputProps={{ maxLength: 100, minLength: 2 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField
                                            error={erroSobreNome}
                                            helperText={textoErroSobreNome}
                                            variant="standard"
                                            name="sobrenome"
                                            label="Sobrenome"
                                            required
                                            id="sobrenome"
                                            value={sobreNome}
                                            onChange={e => setSobrenome(e.target.value)}
                                            inputProps={{ maxLength: 100, minLength: 2 }}
                                            fullWidth

                                        />
                                    </Grid>

                                </Grid>
                            </Collapse>


                            <Collapse style={{ width: '100%' }} in={fornecedorSelecionado ? fornecedorSelecionado.tipo_endereco > 0 ? true : false : false} timeout="auto" unmountOnExit>

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>

                                    <Grid item xs={8} >
                                        <TextField
                                            variant="standard"
                                            name="descricao"
                                            label="Descrição"
                                            id="descricao"
                                            value={descricao}
                                            onChange={e => setDescricao(e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={4}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={8} >
                                        <TextField
                                            variant="standard"
                                            name="infoAdicional"
                                            label="Informação Adicional"
                                            id="infoAdicional"
                                            value={infoAdicional}
                                            onChange={e => setInfoAdicional(e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={4}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid style={{ backgroundColor: 'WhiteSmoke' }} component={Paper} elevation={12}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"

                                        spacing={2}>
                                        <Grid
                                            container
                                            direction="row"
                                            item xs={9}
                                            spacing={2}>
                                            <Grid item xs={9}>

                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                                </Typography>


                                                <Grid item xs={9}>
                                                    <TextField

                                                        id="rua"
                                                        variant="standard"
                                                        name="rua"
                                                        fullWidth
                                                        label="Rua"
                                                        value={parametrosEndereco.rua}
                                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'rua': e.target.value }))}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>

                                                <Grid item xs={9}>
                                                    <TextField

                                                        id="bloco"
                                                        variant="standard"
                                                        name="bloco"
                                                        fullWidth
                                                        label="Bloco"
                                                        value={parametrosEndereco.bloco}
                                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bloco': e.target.value }))}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>

                                                <Grid item xs={9}>
                                                    <TextField

                                                        id="numero"
                                                        variant="standard"
                                                        name="numero"
                                                        fullWidth
                                                        label="Número"
                                                        value={parametrosEndereco.numero}
                                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>

                                                <Grid item xs={9}>
                                                    <TextField

                                                        id="apartamento"
                                                        variant="standard"
                                                        name="apartamento"
                                                        fullWidth
                                                        label="apartamento"
                                                        value={parametrosEndereco.apartamento}
                                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'apartamento': e.target.value }))}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>

                                                <Grid item xs={9}>
                                                    <TextField

                                                        id="complemento"
                                                        variant="standard"
                                                        name="complemento"
                                                        fullWidth
                                                        label="complemento"
                                                        value={parametrosEndereco.complemento}
                                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Collapse>

                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>



                                    <Grid item xs={5}  >

                                        <Typography style={{ margin: 15 }} >
                                            <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                        </Typography>

                                        <TextField
                                            id="email"
                                            variant="standard"
                                            name="email"
                                            fullWidth
                                            label="E-Mail"
                                            required
                                            autoComplete="email"
                                            value={email}
                                            inputProps={{ maxLength: 50, minLength: 15 }}

                                        />
                                    </Grid>


                                </Grid>
                            </Grid>





                        </Grid>


                    </React.Fragment>
                }


            </Container>

        </div >
    );
}


