import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastrarOrdemDeCompra() {


    const classes = useStyles();
    const history = useHistory();

    const [descricao, setDescricao] = useState('');
    const [solicitantes, setSolicitantes] = useState('');
    const [unidades, setUnidades] = useState([]);
    const [unidadeSelecionada, setUnidadeSelecionada] = useState();

    const [loading, setLoading] = useState(true);

    const [ordemServicoSelecionada, setOrdemServicoSelecionada] = useState(null);

    const [ordensServicos, setOrdensServicos] = useState(null);


    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [fornecedores, setFornecedores] = useState(null);




    async function salvar() {


        try {


            if (!unidadeSelecionada) {
                alert("Informe a Unidade Administrativa!");
                return;
            }

            let solicitantes_cad = [];

            if (!solicitantesSelecionados || solicitantesSelecionados.length > 0) {
                solicitantes_cad = solicitantesSelecionados.map(solicitante => {


                    return {
                        solicitante: {
                            id_usuario: solicitante.id_usuario
                        }
                    };
                });
            }


            console.log("cadastrar chamado");
            const cadastro = {

                usuario: { id_usuario: Cookies.get("id_usuario") },
                descricao: descricao,
                unidade: { id_unidade: unidadeSelecionada.id_unidade },
                solicitantes: solicitantes_cad,
                ordem_servico: ordemServicoSelecionada,

            }

            if (ordemServicoSelecionada) {
                cadastro.ordem_servico = ordemServicoSelecionada;
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }




            const response = await api.post('/v1/protected/ordensdecompras/ordemdecompra/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Ordem de Compra Cadastrada!");
                history.push({
                    pathname: "/gestao/administracao/ordensdecompras/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }





    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("v1/protected/user/listartodos", {
                headers: headers
            }).then(function (response) {
                setSolicitantes(response.data)


                api.get("v1/protected/unidades/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setUnidades(response.data)


                    api.get("v1/protected/ordemservico/listartodos", {
                        headers: headers
                    }).then(function (response) {
                        setOrdensServicos(response.data);


                        api.get("v1/protected/fornecedores/listarTodos", {
                            headers: headers
                        }).then(function (response) {
                            setFornecedores(response.data);

                            setLoading(false);

                        });
                    });
                });


            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Solicitantes: " + _err)

        }

    }

    useEffect(() => {




        listarInformacoes();


    }, []);



    const [solicitantesSelecionados, setSolicitantesSelecionados] = useState([]);

    const handleAdicionarSolicitante = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosSolicitantes = valoresSelecionados.filter(
            (opcao) => !solicitantesSelecionados.some((selecionado) => selecionado.id_usuario === opcao.id_usuario)
        );

        setSolicitantesSelecionados([...solicitantesSelecionados, ...novosSolicitantes]);
    };


    const handleRemoverSolicitante = (event, solicitanteRemovido) => {
        const novosSolicitantes = solicitantesSelecionados.filter(
            (solicitante) => solicitante.id_usuario !== solicitanteRemovido.id_usuario
        );
        setSolicitantesSelecionados(novosSolicitantes);
    };



    function returnDescricaoSolicitante(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel;
        return texto;
    }


    const handleOrdemServico = (event, ordem) => {

        setOrdemServicoSelecionada(ordem);
    };



    function returnDescricaoOrdem(ordem) {
        var texto = "ID: " + ordem.id_ordem_servico + "\n Solicitação: " + ordem.solicitacao.solicitacao;
        return texto;
    }

    const handleUnidade = (event, unidade) => {

        setUnidadeSelecionada(unidade);
    };



    const handleFornecedor = (event, fornecedor) => {

        setFornecedorSelecionado(fornecedor);
    };



    function returnDescricaoFornecedor(fornecedor) {

        var identificacao = fornecedor.tipo_fornecedor === 0 ? "CPF " : "CNPJ;"

        var valor = fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj;

        var nome = fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) :
            (fornecedor.nome_fantasia);

        var endereco = fornecedor.logradouro + ", nº " + fornecedor.numero + ", " + fornecedor.bairro + ", " + fornecedor.cidade + " " + fornecedor.estado + " CEP: " + fornecedor.cep;


        var texto = "ID: " + fornecedor.id_fornecedor + "\n" + identificacao + " " + valor + "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }



    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }





    return (
        <div>
            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container maxWidth="lg" className={classes.container} >

                    <React.Fragment >
                        <Grid container spacing={2}   >





                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >





                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        required
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Grid>



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={unidades}
                                            disableCloseOnSelect
                                            getOptionLabel={(unidade) => returnDescricaoUnidade(unidade).replace(/<br \/>/g, '\n')}
                                            value={unidadeSelecionada}
                                            onChange={handleUnidade}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((ordem) => {
                                                    const cotacaoText = returnDescricaoUnidade(ordem).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Unidade Administrativa " multiline rows={7} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoUnidade(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>







                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={ordensServicos}
                                            disableCloseOnSelect
                                            getOptionLabel={(ordem) => returnDescricaoOrdem(ordem).replace(/<br \/>/g, '\n')}
                                            value={ordemServicoSelecionada}
                                            onChange={handleOrdemServico}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((ordem) => {
                                                    const cotacaoText = returnDescricaoOrdem(ordem).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Ordem de Serviço " multiline rows={3} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoOrdem(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>








                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-solicitantes"
                                            options={solicitantes}
                                            disableCloseOnSelect
                                            multiple
                                            getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                            value={solicitantesSelecionados}
                                            onChange={handleAdicionarSolicitante}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((solicitante) => {
                                                    const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Solicitantes " multiline rows={7} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((solicitante, index) => (
                                                    <Chip
                                                        label={returnDescricaoSolicitante(solicitante)}
                                                        {...getTagProps({ index })}
                                                        onDelete={(event) => handleRemoverSolicitante(event, solicitante)}
                                                    />
                                                ))
                                            }
                                        />
                                    </Grid>
                                </Grid>





                            </Grid>



                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={10}
                            >
                                <Button style={{ marginTop: 50 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                > Salvar  </Button>
                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


