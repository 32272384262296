import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { styled } from '@material-ui/styles';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@mui/material/AppBar';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';




import {

    Tooltip
} from '@mui/material';
import {
    MaterialReactTable,

} from 'material-react-table';

import Paper from '@material-ui/core/Paper';
import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../../assets/imgs/miniatura_pdf.png';

import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';






const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'red',
    },
}));



const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};



export default function EditarProposta() {

    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }

    const [numItens, setNumItens] = useState(0);
    const [quantItens, setQuantItens] = useState(0);




    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelectionChange = (selectionModel) => {
        console.log("funcao chamada!");

        // Obtém os IDs das linhas selecionadas
        const selectedIDs = new Set(selectionModel);
        // Filtra os itens com base nos IDs selecionados
        const selectedRows = itensRequerimento.filter((row) => selectedIDs.has(row.id_item_requerimento));
        setSelectedItems(selectedRows);
        console.log("Itens selecionados: " + selectedRows.length);

    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer
                container
                direction="row"
                alignItems="center"
                justifyContent={"center"}
            >
                <Grid
                    direction="row"
                    container
                    alignItems="flex-start"
                    justifyContent={"flex-start"}
                >
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box display="flex" alignItems="center">
                        <span style={{ fontSize: 18, marginRight: 5 }}> {"Número de Itens: "} </span>
                        <span style={{ fontSize: 22, marginRight: 15, fontWeight: 'bold' }}> {numItens} </span>
                        <span style={{ fontSize: 18, marginRight: 5 }}> {" Quantidade de Itens: "} </span>
                        <span style={{ fontSize: 22, marginRight: 5, fontWeight: 'bold' }}> {quantItens} </span>


                    </Box>
                </Grid>
                {selectedItems.length > 0 && <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <ColorButtonRed variant="outlined" color="secondary"
                        onClick={handleClickRemoverSelecionados}
                    >
                        Remover Selecionados
                    </ColorButtonRed>

                    <ColorButtonRed variant="outlined" color="primary"
                        onClick={handleClickSolicitarOrdemCompra}
                    >
                        Solicitar Ordem de Compra
                    </ColorButtonRed>
                </Grid>
                }

            </GridToolbarContainer >
        );
    }

    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const history = useHistory();
    const [descricaoProposta, setDescricaoProposta] = useState('');
    const [diasValidade, setDiasValidade] = useState('');
    const [diasInicioInstalacao, setDiasInicioInstalacao] = useState('');
    const [diasInstalacao, setDiasInstalacao] = useState('');
    const [nivelSla, setNivelSla] = useState('');
    const [itens, setItens] = useState([]);

    const [proposta, setProposta] = useState(null);

    const { idProjeto } = useParams();

    const { idProposta } = useParams();


    const { telaRetorno } = useParams();

    const { idInstalacao } = useParams();

    const { idCliente } = useParams();


    const [arquivos, setArquivos] = useState(null);

    const [instalacao, setInstalacao] = useState(null);


    const [projeto, setProjeto] = useState(null);

    const [descricaoItem, setDescricaoItem] = useState([]);
    const [unidadeItem, setUnidadeItem] = useState([]);
    const [quantidadeItem, setQuantidadeItem] = useState([]);
    const [quantidadeTotal, setQuantidadeTotal] = useState([]);
    const [periodicidade, setPeriodicidade] = useState([]);
    const [valorUnitario, setValorUnitario] = useState([]);
    const [valorTotal, setValorTotal] = useState([]);






    const onDropArquivo1 = useCallback(async (acceptedFiles) => {
        // Valida o tipo de arquivo
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            alert('Apenas arquivos Excel são permitidos.');
            return;
        }

        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            const formData = new FormData();
            formData.append('arquivos', acceptedFiles[0]);
            formData.append('extensao', acceptedFiles[0].name.split('.').pop());


            await api.post("/v1/protected/projetos/proposta/upload/" + idProposta, formData, {
                headers: headers
            }).then(function (response) {

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {



                    const sortedItensPreRequerimento = response.data.proposta.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                    // Atualiza o estado com a ordem de serviço e os itens ordenados
                    setItensRequerimento(sortedItensPreRequerimento);

                    setNumItens(sortedItensPreRequerimento.length);
                    const totalQuantItens = sortedItensPreRequerimento.reduce((total, item) => total + item.quantidade, 0);
                    setQuantItens(totalQuantItens);


                    api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                        headers: headers
                    }).then(function (response) {
                        setInsumos(response.data)
                    });

                    alert("Arquivo Lido!");

                } else {
                    // Lógica a ser executada caso ocorra algum erro durante o salvamento
                    alert("Erro ao processar, insira os itens manualmente!");
                }


            });





        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
        }
    }, []);


    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        //accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });




    async function handleClickRemocao(cellValues) {



        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.delete("/v1/protected/projetos/proposta/" + idProposta + "/removeritem/" + cellValues.id_item_proposta,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Removido!");
                listarInformacoes();

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


    };




    function handleClickSolicitarOrdemCompra() {

        const categoria = 0;

        history.push({
            pathname: '/gestao/administracao/ordensdeservicos/cadastrarordemservico',
            state: { categoriaCad: categoria, projetoCad: projeto, propostaCad: proposta, itensCad: selectedItems }
        });

    }



    async function handleClickRemoverSelecionados() {



        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const itens = {
                itens_pre_requerimento: selectedItems,
            }


            const response = await api.delete(
                "/v1/protected/projetos/proposta/" + idProposta + "/removerinsumos",
                {
                    headers: headers,
                    data: itens // Aqui estamos incluindo o corpo da requisição no campo 'data'
                }
            );

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Itens selecionados removidos!");



                const sortedItensPreRequerimento = response.data.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                setItensRequerimento(sortedItensPreRequerimento);
                setNumItens(sortedItensPreRequerimento.length);
                const totalQuantItens = sortedItensPreRequerimento.reduce((total, item) => total + item.quantidade, 0);
                setQuantItens(totalQuantItens);

                setArquivos(response.data);

                const somatoria = response.data.itens.reduce((sum, item) => sum + item.valor_total, 0);
                setSomatoria(somatoria);

                //listarInformacoes();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    };






    async function novoItem() {
        // Obtém o próximo ID

        // Cria um novo item com os valores das constantes



        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao: descricaoItem,
                unidade_medida: unidadeItem,
                quantidade: quantidadeItem,
                quantidade_total: quantidadeTotal,
                periodicidade: periodicidade,
                valor_unitario: valorUnitario,
                valor_total: valorTotal,
            };


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.put("/v1/protected/projetos/proposta/" + idProposta + "/inseriritem", cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Adicionado!");
                const sortedItensPreRequerimento = response.data.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                setItensRequerimento(sortedItensPreRequerimento);
                setNumItens(sortedItensPreRequerimento.length);
                const totalQuantItens = sortedItensPreRequerimento.reduce((total, item) => total + item.quantidade, 0);
                setQuantItens(totalQuantItens);

                setArquivos(response.data);

                const somatoria = response.data.itens.reduce((sum, item) => sum + item.valor_total, 0);
                setSomatoria(somatoria);
                // listarInformacoes();
                // Limpa os valores das constantes
                setDescricaoItem('');
                setUnidadeItem('');
                setQuantidadeItem('');
                setQuantidadeTotal('');
                setPeriodicidade('');
                setValorUnitario('');
                setValorTotal('');

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const handleClickExcluirItemProposta = (event, cellValues) => {

        const id = cellValues.row.id_item_proposta;


        // Crie um novo array que exclua o item com o ID correspondente
        const novoArrayItens = itens.filter(item => item.id_item_proposta !== id);

        // Atualize o array de itens com o novo array
        setItens(novoArrayItens);

    };


    const [colunas, setColunas] = useState(null);
    const [somatoria, setSomatoria] = useState(0.0);

    const [itensRequerimento, setItensRequerimento] = useState([]);

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("/v1/protected/projetos/listarporid/" + idProjeto, {
                headers: headers
            }).then(function (response) {
                setProjeto(response.data.projeto);

                api.get("/v1/protected/projetos/propostas/listarporidproposta/" + idProposta, {
                    headers: headers
                }).then(function (response) {

                    setProposta(response.data.proposta);
                    setDescricaoProposta(response.data.proposta.descricao_proposta);
                    setDiasInicioInstalacao(response.data.proposta.dias_inicio_instalacao);
                    setDiasInstalacao(response.data.proposta.dias_instalacao);
                    setDiasValidade(response.data.proposta.dias_validade);
                    setItens(response.data.proposta.itens);

                    setNivelSla(response.data.proposta.nivel_sla);
                    console.log("NIvel: " + nivelSla);


                    const sortedItensPreRequerimento = response.data.proposta.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                    setItensRequerimento(sortedItensPreRequerimento);
                    setNumItens(sortedItensPreRequerimento.length);
                    const totalQuantItens = sortedItensPreRequerimento.reduce((total, item) => total + item.quantidade, 0);
                    setQuantItens(totalQuantItens);

                    setArquivos(response.data);

                    const somatoria = response.data.proposta.itens.reduce((sum, item) => sum + item.valor_total, 0);

                    setSomatoria(somatoria);


                    const colunasTable = [

                        {
                            accessorKey: 'id_item_proposta', //normal accessorKey
                            header: 'ID',
                            size: 50,
                        },
                        {
                            accessorKey: 'descricao',
                            header: 'Descricao',
                            size: 300,

                        },

                        {
                            accessorKey: 'unidade_medida',
                            header: 'Unidade',
                            size: 100,

                        },

                        {
                            accessorKey: 'quantidade',
                            header: 'Quantidade',
                            size: 100,

                        },

                        {
                            accessorKey: 'quantidade_total',
                            header: 'Total',
                            size: 100,

                        },


                        {
                            accessorKey: 'periodicidade',
                            header: 'Periodicidade',
                            size: 100,

                        },

                        {
                            accessorKey: 'valor_unitario',
                            header: 'Valor Unitário',
                            size: 100,
                            Cell: ({ renderedCellValue }) => {
                                return parseFloat(renderedCellValue).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                            },

                        },

                        {
                            accessorKey: 'valor_total',
                            header: 'Valor Total',
                            size: 200,
                            Cell: ({ renderedCellValue }) => {
                                return parseFloat(renderedCellValue).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                            },
                            Footer: () => (
                                <Stack style={{ fontSize: 20, fontWeight: 'bold' }}>
                                    {currencyFormatter.format(somatoria)}
                                </Stack>
                            ),

                        },

                    ];

                    setColunas(colunasTable);


                    api.get("/v1/protected/cliente/instalacoes/listarporid/" + idInstalacao, {
                        headers: headers
                    }).then(function (response) {
                        setInstalacao(response.data);


                        api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                            headers: headers
                        }).then(function (response) {
                            setInsumos(response.data)
                            setLoading(false);

                        });



                    });


                });
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);



    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/projetos/proposta/baixarPdf", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }





    function returnDescricaoRetorno(instalacao) {
        var texto = instalacao.identificador_integrador + " | " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            " | " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);
    const [quantidade, setQuantidade] = useState(0);
    const [observacoesItem, setObservacoesItem] = useState('');
    const [descricaoNovoInsumo, setDescricaoNovoInsumo] = useState('');
    const [descricao, setDescricao] = useState('');


    function handleSetInsumo(insumo) {
        setInsumoSelecionado(insumo);
    }










    async function adicionarItem() {

        if (!insumoSelecionado) {
            if (descricaoNovoInsumo.length <= 0) {
                alert("Selecione o Insumo ou Insira a descrição para um novo Insumo!");
                return;
            } else {

            }

        }

        if (!quantidade || parseInt(quantidade) <= 0) {
            alert("Informe a quantidade!");
            return;
        }

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                observacoes: observacoesItem,
                quantidade: parseInt(quantidade),
                insumo: insumoSelecionado !== null ? { id_insumo: insumoSelecionado.insumo.id_insumo } : null,
                descricao: descricaoNovoInsumo,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.put("/v1/protected/projetos/proposta/" + idProposta + "/inseririnsumo", cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Adicionado!");
                listarInformacoes();

                setInsumoSelecionado(null);
                setQuantidade(0);
                setObservacoesItem('');
                setDescricao('');

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    }



    async function handleClickRemoverInsumo(event, cellValues) {



        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.delete("/v1/protected/projetos/proposta/" + idProposta + "/removerinsumo/" + cellValues.row.id_item_requerimento,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Removido!");



                const sortedItensPreRequerimento = response.data.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                setItensRequerimento(sortedItensPreRequerimento);
                setNumItens(sortedItensPreRequerimento.length);
                const totalQuantItens = sortedItensPreRequerimento.reduce((total, item) => total + item.quantidade, 0);
                setQuantItens(totalQuantItens);

                setArquivos(response.data);

                const somatoria = response.data.itens.reduce((sum, item) => sum + item.valor_total, 0);
                setSomatoria(somatoria);

                //listarInformacoes();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


    };



    function returnDescricaoInsumo(insumo_) {

        let insumo = insumo_.insumo;
        let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        var texto =

            //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
            // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
            //"<br />Tipo: " + insumo.tipo +
            //"<br />Categoria: " + insumo.categoria +
            textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
        return texto;
    }



    function returnDescricaoInsumoSimplificada(insumo) {

        let textoComQuebraDeLinha = insumo.descricao + "\n";

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        var texto = textoComQuebraDeLinha;
        return texto;
    }




    const colunasInsumos = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {

                return (
                    <ColorButtonRed variant="outlined" color="secondary"
                        onClick={(event) => handleClickRemoverInsumo(event, cellValues)}

                    >
                        Remover
                    </ColorButtonRed>
                )
            },
        },
        {
            headerName: 'Item',
            field: 'id_item_na_lista',
            id: 2,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Insumo',
            field: 'insumo',
            id: 3,
            width: 700,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const insumo = params.row.insumo;
                let descricao = returnDescricaoInsumoSimplificada(insumo);
                return <div style={{ whiteSpace: 'pre-line' }}>{descricao}</div>;
            },
        },


        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 4,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Observações',
            field: 'observacoes',
            id: 5,
            width: 600,
            headerClassName: 'cabecalho_azul',
        },

    ];





    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Propostas={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :

                <div >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                    >


                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="center"
                            alignItems="center"
                            item xs={12}
                            style={{ margin: 10, fontSize: 14 }}
                        >
                            <span style={{ fontSize: 18, fontWeight: 'bold' }}>{"Proposta Nº " + idProposta + " "}</span>
                            <a

                                href={"/gestao/administracao/projetos/editaroprojeto/" + idProjeto + "/" + telaRetorno + "/" + idInstalacao + "/" + idCliente}
                                style={{ marginRight: 2, align: 'center', alignItems: 'center', fontSize: 12, color: 'black', marginRight: '5px' }}>
                                {" < Projeto " + idProjeto + returnDescricaoRetorno(instalacao)}
                            </a>

                        </Grid>



                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >


                            <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                <AppBar position="static" >

                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"


                                    >
                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                        <Tab label="Serviços" style={{ backgroundColor: 'green' }} />
                                        <Tab label="Insumos" style={{ backgroundColor: 'green' }} />



                                    </Tabs>
                                </AppBar>

                            </Box>
                        </Grid>


                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >

                            <Grid
                                value={value} index={0}
                                style={{ padding: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                spacing={2}
                            >



                                <Grid item xs={6}
                                    container
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                >
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="area-selector-label">Nível SLA</InputLabel>
                                            <Select
                                                labelId="area-selector-label"
                                                id="area-selector"
                                                value={nivelSla}

                                                label="Nível SLA"
                                            >
                                                <MenuItem value={0}>
                                                    Nível 0 - Básico     <br />
                                                    CAPITAL E REGIÃO METROPOLITANA EM ATÉ 48 HORAS <br />
                                                    INTERIOR DO ESTADO EM ATÉ 72 HORAS <br />
                                                    ÁREAS REMOTAS EM ATÉ 96 HORAS <br />
                                                    ----------------------------------------------

                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    Nível 1 - Crítico     <br />
                                                    CAPITAL E REGIÃO METROPOLITANA	EM ATÉ 24 HORAS <br />
                                                    INTERIOR DO ESTADO	EM ATÉ 48 HORAS <br />
                                                    ÁREAS REMOTAS	EM ATÉ 72 HORAS <br />
                                                    ----------------------------------------------

                                                </MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                <Grid item xs={6}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >

                                    <span style={{ fontSize: 22, fontWeight: 'bold' }}>{
                                        "Valor da Proposta: " + currencyFormatter.format(proposta.valor)}</span>

                                </Grid>




                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    item xs={4}
                                >
                                    <TextField
                                        variant="standard"
                                        name="diasValidade"
                                        label="Validade da Proposta (em dias)"
                                        required
                                        id="diasValidade"
                                        value={diasValidade}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    item xs={4}
                                >
                                    <TextField
                                        variant="standard"
                                        name="diasInicioInstalacao"
                                        label="Dias até Início da Instalação"
                                        required
                                        id="diasInicioInstalacao"
                                        value={diasInicioInstalacao}
                                        fullWidth
                                    />
                                </Grid>



                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    item xs={4}
                                >
                                    <TextField
                                        variant="standard"
                                        name="diasInstalacao"
                                        label="Duração da Instalação (em dias)"
                                        required
                                        id="diasInstalacao"
                                        value={diasInstalacao}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricaoProposta"
                                        label="Descrição Proposta"
                                        required
                                        id="descricaoProposta"
                                        value={descricaoProposta}
                                        fullWidth
                                        multiline
                                        minRows={1}
                                    />
                                </Grid>


                            </Grid>

                            <Grid
                                value={value} index={1}
                                style={{ padding: 10 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >




                                {itens !== null && (



                                    <MaterialReactTable

                                        columns={colunas}
                                        data={itens}
                                        enableTopToolbar={false}
                                        enableBottomToolbar={false}
                                        enablePagination={false}
                                        enableColumnFilters={false}
                                        enableColumnActions={false}
                                        sortable={false}
                                        enableRowActions={true}
                                        enableSorting={false}
                                        enableRowSelection={false}
                                        color={'black'}
                                        paginationDisplayMode={'pages'}
                                        getRowId={(row) => row.id_item_analise}
                                        initialState={{
                                            pagination: { pageSize: 20, pageIndex: 0 },
                                            showGlobalFilter: true,
                                            color: 'black'
                                        }}
                                        muiTableContainerProps={{ sx: { maxHeight: "3000px", color: 'black' } }}
                                        muiTableBodyRowProps={{
                                            sx: {
                                                height: 35,
                                                color: 'black' // Define a cor do texto para preto
                                            },
                                        }}
                                        enableColumnFilterModes={false}
                                        enableExpandAll={true}
                                        enableColumnPinning={true}
                                        enableColumnResizing={true}
                                        muiPaginationProps={{
                                            rowsPerPageOptions: [20, 50, 100],
                                            variant: 'outlined',
                                        }}
                                        enableFullScreenToggle={true}
                                        muiTablePaperProps={({ table }) => ({
                                            style: {
                                                zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                                color: 'black',
                                            },
                                        })}

                                        renderRowActionMenuItems={({ row, table }) => [

                                            <Tooltip title="Deletar">
                                                <IconButton size="small" color="error" onClick={() => handleClickRemocao(row.original)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ]}


                                    />


                                )}

                                <Paper
                                    elevation={5} // Ajuste o valor da elevação conforme necessário
                                    style={{
                                        backgroundColor: '#edf2f9', // Ajuste a cor de fundo conforme necessário
                                        padding: 20,
                                        borderRadius: 10, // Adiciona bordas levemente arredondadas
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', // Ajusta os valores da sombra conforme necessário
                                        margin: '20px', // Ajusta a margem para afastamento das bordas da tela
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        item xs={12}
                                        spacing={2}
                                        style={{ paddingTop: 20 }}
                                    >

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="descricaoItem"
                                                label="Descrição"
                                                required
                                                id="descricaoItem"
                                                value={descricaoItem}
                                                onChange={(e) => setDescricaoItem(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={1}
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="unidadeItem"
                                                label="Unidade"
                                                required
                                                id="unidadeItem"
                                                value={unidadeItem}
                                                onChange={(e) => setUnidadeItem(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="quantidadeItem"
                                                label="Quantidade"
                                                required
                                                id="quantidadeItem"
                                                value={quantidadeItem}
                                                onChange={(e) => setQuantidadeItem(e.target.value.replace(/\D/g, ''))}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="quantidadeTotal"
                                                label="Quantidade Total"
                                                required
                                                id="quantidadeTotal"
                                                value={quantidadeTotal}
                                                onChange={(e) => setQuantidadeTotal(e.target.value.replace(/\D/g, ''))}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="periodicidade"
                                                label="Periodicidade"
                                                required
                                                id="periodicidade"
                                                value={periodicidade}
                                                onChange={(e) => setPeriodicidade(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="valorUnitario"
                                                label="Valor Unitário"
                                                required
                                                id="valorUnitario"
                                                value={valorUnitario}
                                                onChange={(e) => setValorUnitario(e.target.value.replace(/^(\D*)(\d*(?:\.\d{0,2})?).*$/, '$2'))}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="valorTotal"
                                                label="Valor Total"
                                                required
                                                id="valorTotal"
                                                value={valorTotal}
                                                onChange={(e) => setValorTotal(e.target.value.replace(/^(\D*)(\d*(?:\.\d{0,2})?).*$/, '$2'))}
                                                fullWidth
                                            />
                                        </Grid>



                                    </Grid>
                                </Paper>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <Button style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={novoItem}
                                    >
                                        Adicionar Serviço
                                    </Button>
                                </Grid>


                            </Grid >


                            <Grid value={value} index={2}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                            >



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    item xs={12}
                                >
                                    <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                        <input {...getInputArquivoProps1()} />
                                        <Typography align="center" variant="h7">
                                            Anexo
                                        </Typography>
                                    </div>


                                </Grid>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ padding: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={8}
                                    >

                                        <Autocomplete
                                            id="select-insumo"
                                            options={insumos}
                                            getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                            value={insumoSelecionado}
                                            getOptionSelected={(option, value) => true}
                                            onChange={(e, v) => setInsumoSelecionado(v)}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((insumo) => {
                                                    const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                    return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Insumo " multiline minRows={2} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                            )}
                                        />
                                    </Grid>


                                    <Grid
                                        container
                                        item xs={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ marginRight: 2 }}
                                    >
                                        <TextField
                                            variant="standard"
                                            name="quantidade"
                                            label="Quantidade"
                                            id="quantidade"
                                            value={quantidade}
                                            onChange={(e) => setQuantidade(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>


                                    <Grid
                                        container
                                        item xs={12}
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ paddingTop: 10 }}
                                    >
                                        <TextField
                                            variant="standard"
                                            name="observacoesItem"
                                            label="Observações"
                                            id="observacoesItem"
                                            value={observacoesItem}
                                            onChange={(e) => setObservacoesItem(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={1}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        item xs={12}
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ paddingTop: 10 }}
                                    >
                                        <TextField
                                            variant="standard"
                                            name="descricaoNovoInsumo"
                                            label="Descrição para Novo Insumo"
                                            id="descricaoNovoInsumo"
                                            value={descricaoNovoInsumo}
                                            onChange={(e) => setDescricaoNovoInsumo(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={1}
                                        />
                                    </Grid>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        item xs={2}
                                    >
                                        <Button style={{ marginTop: 5 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={(ev) => adicionarItem()}
                                        > Adicionar Item  </Button>
                                    </Grid>





                                </Grid>







                                <Grid
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {
                                        itensRequerimento &&
                                        <>
                                            <DataGrid
                                                localeText={ptBR.localeText}
                                                pagination
                                                checkboxSelection
                                                rows={itensRequerimento}
                                                getRowId={(row) => row.id_item_requerimento}
                                                columns={colunasInsumos}
                                                disableColumnResize={false}

                                                onRowSelectionModelChange={handleSelectionChange}
                                                autoHeight

                                                onCellClick={handleCellClick}
                                                onRowClick={handleRowClick}
                                                components={{
                                                    Toolbar: CustomToolbar,
                                                }}
                                            />

                                        </>
                                    }



                                </Grid>


                            </Grid>


                        </SwipeableViews >







                    </Grid >


                </div >
            }


        </div >
    );
}


