import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";



export default function CadastroCliente() {
    const history = useHistory();

    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */



    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    /* variaveis de estado de cadastro de pessoa juridica*/


    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');



    const [infoAdicional, setInfoAdicional] = useState('');




    /* variaveis  de controle gerais */

    const [erroEmail, setErroEmail] = useState(false);
    const [textoErroEmail, setTextoErroEmail] = useState('');

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);

    const [parametrosEndereco, setParametrosEndereco] = useState(
        {
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            cidade: "",
            estado: "",
            cep: "",
            latitude: "",
            longitude: "",
        }
    );



    const verificaEspaco = (string) => /\s/g.test(string);

    useEffect(() => {


    }, []);









    async function salvar() {

        setBotaoSalvarClickado(true);
        if (tipoFisica) {
            //cadastrar pessoa fisica


            try {
                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }


                try {
                    console.log("procurar email chamado: " + email);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }



                    try {

                        const cadastro_cliente_pf = {
                            tipo_cliente: 0,
                            nome: nome,
                            sobrenome: sobreNome,
                            cpf: cpf,
                            nascimento: dataNascimento,
                            rg: rg,
                            email: email,
                            descricao: descricao,
                            logradouro: parametrosEndereco.logradouro,
                            numero: parametrosEndereco.numero,
                            bairro: parametrosEndereco.bairro,
                            cidade: parametrosEndereco.cidade,
                            estado: parametrosEndereco.estado,
                            cep: parametrosEndereco.cep,
                            complemento: parametrosEndereco.complemento,
                            latitude: parametrosEndereco.latitude,
                            longitude: parametrosEndereco.longitude,

                        }

                        const headers = {
                            'Authorization': 'Bearer ' + Cookies.get("token")
                        }

                        const response = await api.post('/v1/protected/clientes/cadastrar', cadastro_cliente_pf,
                            { headers: headers });

                        const cadastro_salvo = response.data;
                        if (cadastro_salvo) {
                            //alert("Cliente cadastrado com sucesso!")
                            history.push({
                                pathname: '/gestao/administracao/clientes/gerenciarcliente/' + cadastro_salvo.id_cliente,
                            })

                            /*
                                                    history.push({
                                                        pathname: '/gestao/administracao/clientes/listar',
                                                    })
                                                        */
                        } else {
                            alert("Erro de Conexão, tente novamente mais tarde");
                            setBotaoSalvarClickado(false);

                        }
                    } catch (_err) {
                        console.log("erro ao cadastrar: " + _err);
                        alert("Erro de Conexão, tente novamente mais tarde");
                        setBotaoSalvarClickado(false);

                    }





                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");
                    setBotaoSalvarClickado(false);

                    return false;
                }



            } catch (_err) {

                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }





        } else {
            //cadastrar pessoa juridica

            //verificar por cpf ja cadastrado
            try {
                console.log("procurar cnpj chamado: " + cnpj);
                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }


                //verificar por email ja cadastrado
                try {
                    console.log("procurar email chamado: " + email);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }


                    try {

                        console.log("cadastrar chamado");
                        const cadastro_cliente_pj = {
                            tipo_cliente: 1,
                            cnpj: cnpj,
                            razao_social: razaoSocial,
                            nome_fantasia: nomeFantasia,
                            porte: porte,
                            atividade: atividade,
                            email: email,
                            descricao: descricao,
                            logradouro: parametrosEndereco.logradouro,
                            numero: parametrosEndereco.numero,
                            bairro: parametrosEndereco.bairro,
                            complemento: parametrosEndereco.complemento,
                            cidade: parametrosEndereco.cidade,
                            estado: parametrosEndereco.estado,
                            cep: parametrosEndereco.cep,
                            latitude: parametrosEndereco.latitude,
                            longitude: parametrosEndereco.longitude,

                        }

                        const headers = {
                            'Authorization': 'Bearer ' + Cookies.get("token")
                        }

                        const response = await api.post('/v1/protected/clientes/cadastrar', cadastro_cliente_pj,
                            { headers: headers });

                        const cadastro_salvo = response.data;
                        if (cadastro_salvo) {
                            //alert("Cliente cadastrado com sucesso!")
                            history.push({
                                pathname: '/gestao/administracao/clientes/gerenciarcliente/' + cadastro_salvo.id_cliente,
                            })

                            /*
                                                                        history.push({
                                                                            pathname: '/gestao/administracao/clientes/listar',
                                                                        })
                                                                            */
                        } else {
                            alert("Erro de Conexão, tente novamente mais tarde");
                            setBotaoSalvarClickado(false);

                        }
                    } catch (_err) {
                        console.log("erro ao cadastrar: " + _err);
                        alert("Erro de Conexão, tente novamente mais tarde");
                        setBotaoSalvarClickado(false);


                    }





                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");
                    setBotaoSalvarClickado(false);

                    return false;
                }



            } catch (_err) {

                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }



        }


    }

    async function handleCnpj(cnpj) {

        // Remove caracteres não numéricos do CNPJ
        let cnpjSemFormato = cnpj.replace(/\D/g, '');

        cnpjSemFormato = cnpjSemFormato.slice(0, 14);


        setCnpj(cnpjSemFormato);
        try {
            if (cnpjSemFormato.length === 14) {
                console.log("cnpj" + cnpjSemFormato);
                const response = await api.get('https://brasilapi.com.br/api/cnpj/v1/' + cnpjSemFormato);
                setRazaoSocial(response.data.razao_social);
                setNomeFantasia(response.data.razao_social);
                setPorte(response.data.porte);
                setAtividade(response.data.cnae_fiscal_descricao);

                //endereco
                setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': response.data.logradouro }))
                setParametrosEndereco(prevState => ({ ...prevState, 'bairro': response.data.bairro }))

                setParametrosEndereco(prevState => ({ ...prevState, 'estado': response.data.uf }))
                setParametrosEndereco(prevState => ({ ...prevState, 'numero': response.data.numero }))
                setParametrosEndereco(prevState => ({ ...prevState, 'cep': response.data.cep }))
                setParametrosEndereco(prevState => ({ ...prevState, 'cidade': response.data.municipio }))
                setParametrosEndereco(prevState => ({ ...prevState, 'complemento': response.data.complemento }))

            }
        } catch (_err) {

        }

        // Atualiza o estado com o CNPJ formatado
    }

    const handleChange1 = (event) => {
        setTipoFisica(true);
        setTipoJuridica(false);
    };

    const handleChange2 = (event) => {
        setTipoFisica(false);
        setTipoJuridica(true);
    };

    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                        disabled={botaoSalvarClickado}
                    > Salvar  </Button>
                </Grid>

                <React.Fragment >
                    <Grid container spacing={2}   >




                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item xs={12}
                        >
                            <Typography  >
                                Tipo Pessoa:
                            </Typography>
                            <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                onChange={handleChange1} />} label="Pessoa Física" />
                            <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                onChange={handleChange2} />} label="Pessoa Jurídica" />

                        </Grid>



                        <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={4}>
                                    <TextField

                                        variant="standard"
                                        name="cnpj"
                                        label="CNPJ"
                                        required
                                        id="cnpj"
                                        value={cnpj}
                                        onChange={e => handleCnpj(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={4} >
                                    <TextField

                                        variant="standard"
                                        name="razaoSocial"
                                        label="Razão Social"
                                        required
                                        id="razaoSocial"
                                        autoComplete="razaoSocial"
                                        value={razaoSocial}
                                        onChange={e => setRazaoSocial(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4} >
                                    <TextField

                                        variant="standard"
                                        name="nomeFantasia"
                                        label="Nome Fantasia"
                                        required
                                        id="nomeFantasia"
                                        value={nomeFantasia}
                                        onChange={e => setNomeFantasia(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>

                                    <Grid item xs={4} >

                                        <TextField

                                            variant="standard"
                                            name="porte"
                                            label="Porte"
                                            id="porte"
                                            value={porte}
                                            onChange={e => setPorte(e.target.value)}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            variant="standard"
                                            name="atividade"
                                            label="Atividade"
                                            id="atividade"
                                            autoComplete="atividade"
                                            value={atividade}
                                            onChange={e => setAtividade(e.target.value)}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField

                                            variant="standard"
                                            name="ie"
                                            label="Inscrição Estadual"
                                            id="ei"
                                            value={ie}
                                            onChange={e => setIe(e.target.value)}
                                            fullWidth

                                        />
                                    </Grid>


                                </Grid>
                            </Grid>
                        </Collapse>

                        <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>
                                <Grid item xs={4}  >

                                    <TextField

                                        variant="standard"
                                        name="cpf"
                                        label="CPF"
                                        required
                                        id="cpf"
                                        value={cpf}
                                        onChange={e => setCpf(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField

                                        variant="standard"
                                        name="rg"
                                        label="RG"
                                        required
                                        id="rg"
                                        value={rg}
                                        onChange={e => setRg(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>


                                <Grid item xs={4}  >
                                    <TextField

                                        variant="standard"
                                        name="dataNascimento"
                                        label="Data Nascimento"
                                        required
                                        id="dataNascimento"
                                        value={dataNascimento}
                                        onChange={e => setDataNascimento(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField

                                        variant="standard"
                                        name="nome"
                                        label="Nome"
                                        required
                                        id="nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField

                                        variant="standard"
                                        name="sobrenome"
                                        label="Sobrenome"
                                        required
                                        id="sobrenome"
                                        value={sobreNome}
                                        onChange={e => setSobrenome(e.target.value)}
                                        fullWidth

                                    />
                                </Grid>

                            </Grid>
                        </Collapse>


                        <Grid
                            container
                            direction="row"
                            item xs={12}
                        >
                            <Grid
                                container
                                direction="row" spacing={2}
                                item xs={12}>

                                <Typography style={{ margin: 15 }} >
                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                </Typography>


                                <Grid item xs={12}>
                                    <TextField

                                        id="logradouro"
                                        variant="standard"
                                        name="logradouro"
                                        fullWidth
                                        label="Logradouro"
                                        value={parametrosEndereco.logradouro}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': e.target.value }))}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField

                                        id="numero"
                                        variant="standard"
                                        name="numero"
                                        fullWidth
                                        label="Número"
                                        value={parametrosEndereco.numero}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                                    />
                                </Grid>

                                <Grid item xs={5}>
                                    <TextField

                                        id="bairro"
                                        variant="standard"
                                        name="bairro"
                                        fullWidth
                                        label="Bairro"
                                        value={parametrosEndereco.bairro}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bairro': e.target.value }))}
                                    />
                                </Grid>



                                <Grid item xs={5}>
                                    <TextField

                                        id="complemento"
                                        variant="standard"
                                        name="complemento"
                                        fullWidth
                                        label="Complemento"
                                        value={parametrosEndereco.complemento}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="cidade"
                                        variant="standard"
                                        name="cidade"
                                        fullWidth
                                        label="Cidade"
                                        value={parametrosEndereco.cidade}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cidade': e.target.value }))}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="estado"
                                        variant="standard"
                                        name="estado"
                                        fullWidth
                                        label="Estado"
                                        value={parametrosEndereco.estado}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'estado': e.target.value }))}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="cep"
                                        variant="standard"
                                        name="cep"
                                        fullWidth
                                        label="CEP"
                                        value={parametrosEndereco.cep}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cep': e.target.value }))}
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField

                                        id="latitude"
                                        variant="standard"
                                        name="latitude"
                                        fullWidth
                                        label="Latitude"
                                        value={parametrosEndereco.latitude}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'latitude': e.target.value }))}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="longitude"
                                        variant="standard"
                                        name="longitude"
                                        fullWidth
                                        label="Longitude"
                                        value={parametrosEndereco.longitude}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'longitude': e.target.value }))}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>




                        <Grid
                            style={{ paddingTop: 30 }}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"

                                spacing={2}>



                                <Grid item xs={5}  >

                                    <Typography style={{ margin: 15 }} >
                                        <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                    </Typography>

                                    <TextField
                                        id="email"
                                        variant="standard"
                                        name="email"
                                        fullWidth
                                        label="E-Mail"
                                        required
                                        autoComplete="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}

                                    />
                                </Grid>


                            </Grid>
                        </Grid>





                    </Grid>


                </React.Fragment>


            </Container>

        </div >
    );
}


