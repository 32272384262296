import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuAdmin from '../components/menu';
import Cookies from 'js-cookie';
import api from '../../../services/api';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NavegadorGestao from '../../../components/NavBarGestao';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 0, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 0,
  },
  table: {
    minWidth: 0,
  },
}));



export default function ContaAdmin() {
  const classes = useStyles();
  const [meusDados, setMeusDados] = useState([]);

  const [loading, setLoading] = useState(true);

  const [senha, setSenha] = useState('');
  const [confirmesenha, setConfirmeSenha] = useState('');

  const [erroSenha, setErroSenha] = useState(false);
  const [textoErroSenha, setTextoErroSenha] = useState('');

  const [open, setOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [emAutorizacao, setEmAutorizacao] = useState(false);
  const [codigoValidacao, setCodigoValidacao] = useState("");


  const title = "Deseja mudar sua senha?";
  const history = useHistory();


  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);

  }


  const handleChangeIndex = (index) => {
    setValue(index);
  };


  async function listarMeusDados() {
    try {

      const token = Cookies.get('token');

      const headers = {
        'Authorization': 'Bearer ' + token
      }


      const id_usuario = Cookies.get('id_usuario');
      console.log("id na tela de enderecos: " + id_usuario)

      await api.get("v1/protected/user/retornardadosusuario/" + id_usuario, {
        headers: headers
      }).then(function (response) {
        setMeusDados(response.data)

        console.log(" Meus Dados: " + response);
        setLoading(false);


      });



    } catch (_err) {
      // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
      console.log("Erro ao listar seus dados: " + _err)

    }

  }




  useEffect(() => {


    listarMeusDados();



  }, []);


  async function validar() {
    try {

      const id_usuario = Cookies.get('id_usuario');

      console.log("id usuario: " + id_usuario);

      if (!codigoValidacao) {
        alert("Digite um Código Valido!");
        return;
      }

      const validador = {
        username: meusDados.login,
        code: parseInt(codigoValidacao),
      }




      const headers = {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }

      const response = await api.post('/v1/protected/validate/key', validador,
        { headers: headers });

      const valido = response.data.valid;
      if (valido) {



        const response_autorizado = await api.put('/v1/protected/users/autorizar/' + meusDados.id_usuario,
          { headers: headers });

        if (response_autorizado) {
          alert("Código Validado, você está autorizado!");
          window.location.reload();
        } else {

        }


      } else {
        alert("Código Inválido!");
      }
    } catch (_err) {
      console.log("erro ao cadastrar: " + _err);
      alert("Erro de Conexão, tente novamente mais tarde");

    }
  }

  async function onConfirm() {
    try {

      const id_usuario = Cookies.get('id_usuario');


      console.log("alterar senha chamado");
      console.log("id usuario: " + id_usuario);
      const cadastro_usuario = {
        senha: senha,
      }

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }

      const response = await api.put('/v1/protected/users/mudarsenha/' + id_usuario, cadastro_usuario,
        { headers: headers });

      const cadastro_salvo = response.data;
      if (cadastro_salvo) {
        alert("Senha alterada com sucesso!");
        Cookies.set('token', undefined);

        history.push({
          pathname: '/login',
        })
      } else {
        alert("Erro de Conexão, tente novamente mais tarde");
      }
    } catch (_err) {
      console.log("erro ao cadastrar: " + _err);
      alert("Erro de Conexão, tente novamente mais tarde");

    }
  }

  function verificar() {
    var senha_valida = validateSenha();

    if (senha_valida) {
      setOpen(true);
    }


  }



  async function autorizar() {


    setEmAutorizacao(true);

    try {

      const id_usuario = Cookies.get('id_usuario');


      console.log("alterar senha chamado");
      console.log("id usuario: " + id_usuario);

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }

      const response = await api.get('/v1/protected/autorizacao/generate/' + id_usuario,
        { headers: headers });

      // console.log(response.data);


      const imageSrc = `data:image/png;base64,${response.data}`;
      //src={`data:image/jpeg;base64,${arquivos.imagem3}`} 
      setQrCodeUrl(imageSrc);



    } catch (_err) {
      console.log("erro ao cadastrar: " + _err);
      alert("Erro de Conexão, tente novamente mais tarde");

    }
  }


  function validateSenha() {
    if (senha.length >= 4) {
      if (senha === confirmesenha) {

        setErroSenha(false);
        setTextoErroSenha('');
        return true;
      } else {

        setErroSenha(true);
        setTextoErroSenha("Senhas não conferem");
        console.log("senhas nao conferem");
        return false;
      }
    } else {
      setErroSenha(true);
      setTextoErroSenha("Senha muito curta");
      console.log("Senha muito curta");
      return false;
    }
  }





  return (
    <div>
      <NavegadorGestao inicio={'underline'} corFundo={'white'} travado={true} corTexto={'black'} />
    

          <Container maxWidth="lg" style={{paddingTop: 5}}>
            {loading ?
              <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
              </Skeleton>
              :
              <div>



                <Grid
                  item xs={12}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent={"center"}
                >


                  <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                    <AppBar position="static" >

                      <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"


                      >
                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                        <Tab label="Acesso" style={{ backgroundColor: '#1C1C1C' }} />
                        <Tab label="Autorização" style={{ backgroundColor: '#1C1C1C' }} />


                      </Tabs>
                    </AppBar>

                  </Box>
                </Grid>

                <SwipeableViews
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                >

                  <Grid
                    value={value} index={0}
                    style={{ paddingTop: 30 }}
                    container
                    direction="row"
                    item xs={12}
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >

                    <Grid
                      style={{ paddingTop: 30 }}
                      container
                      direction="row"
                      item xs={12}
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >

                      <Grid item xs={1}>
                      </Grid>

                      <Grid item xs={11}>
                        <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                          Dados Pessoais
                        </Typography>
                      </Grid>


                      <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                          <img width={200} height={200} alt={"imgPerfil"} style={{ textAlign: 'center' }} src={meusDados.url_img_perfil}></img>
                        </div>
                      </Grid>


                      <Grid item xs={2}>
                        <Typography style={{ textAlign: 'right' }}>
                          Nome Completo:
                        </Typography>
                      </Grid>

                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 22 }}>
                          {meusDados.nome_amigavel}
                        </Typography>
                      </Grid>

                    </Grid>


                    <Grid
                      style={{ paddingTop: 30 }}
                      container
                      direction="row"
                      item xs={12}
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >

                      <Grid item xs={1}>
                      </Grid>

                      <Grid item xs={11}>
                        <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                          Dados da Conta
                        </Typography>
                      </Grid>

                      <Grid container spacing={1}
                        direction="row"
                        alignItems="center"
                        item xs={12}
                      >
                        <Grid item xs={2}>
                          <Typography style={{ textAlign: 'right' }}>
                            E-mail:
                          </Typography>
                        </Grid>

                        <Grid item xs={8}>
                          <Typography style={{ fontSize: 22 }}>
                            {meusDados.email}
                          </Typography>
                        </Grid>

                      </Grid>


                    </Grid>





                  </Grid>

                  <Grid
                    value={value} index={1}
                    style={{ paddingTop: 30 }}
                    container
                    direction="row"
                    item xs={12}
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >

                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={11}>
                      <Typography >
                        Mudar Senha:
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={11} style={{ padding: 10, paddingTop: 2 }}>
                      <TextField
                        error={erroSenha}
                        id="password"
                        helperText={textoErroSenha}
                        variant="standard"
                        name="password"
                        fullWidth
                        label="Senha"
                        required
                        type="password"
                        autoComplete="current-password"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        inputProps={{ maxLength: 8, minLength: 4 }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={11} style={{ padding: 10, paddingTop: 2 }}>
                      <TextField
                        error={erroSenha}
                        id="password2"
                        helperText={textoErroSenha}
                        variant="standard"
                        name="password2"
                        fullWidth
                        label="Conferir Senha"
                        required
                        type="password"
                        autoComplete="current-password"
                        value={confirmesenha}
                        onChange={(e) => setConfirmeSenha(e.target.value)}
                        inputProps={{ maxLength: 8, minLength: 4 }}
                      />
                    </Grid>

                    <Grid item xs={1} style={{ padding: 30 }}>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => verificar()}
                      > Salvar  </Button>

                    </Grid>

                  </Grid>


                  <Grid
                    value={value} index={2}
                    style={{ paddingTop: 30 }}
                    container
                    direction="row"
                    item xs={12}
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    {meusDados.autorizado === 0 ?
                      <Grid item xs={12} style={{ padding: 30 }}>

                        <Grid item xs={12} style={{ padding: 30 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={emAutorizacao}
                            onClick={(e) => autorizar()}
                          > Iniciar Autorização  </Button>
                        </Grid>

                        <Grid item xs={12} style={{ padding: 30 }}>
                          <Grid item xs={12} style={{ padding: 30 }}>

                            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                              {qrCodeUrl && <img alt={'icone_qr_code'} style={{ textAlign: 'center' }} src={qrCodeUrl}></img>}

                            </div>
                          </Grid>

                          <Grid item xs={12} style={{ padding: 30 }}>
                            <Typography >
                              Com o Aplicativo Google Authenticator, escaneia o QRCODE acima e insira um Token para Validar sua Autorização!
                            </Typography>
                          </Grid>

                          <Grid item xs={12} style={{ padding: 5 }}>
                            <TextField
                              id="codigoValidacao"
                              variant="standard"
                              name="codigoValidacao"
                              fullWidth
                              label="Código de Validação"
                              required
                              value={codigoValidacao}
                              onChange={(e) => setCodigoValidacao(e.target.value)}
                            />

                          </Grid>
                          <Grid item xs={12} style={{ padding: 30 }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e) => validar()}
                            > Validar  </Button>
                          </Grid>

                        </Grid>

                      </Grid>
                      :
                      <div>
                        <Grid item xs={11}>
                          <Typography >
                            Autorização Completa
                          </Typography>
                        </Grid>


                      </div>

                    }

                  </Grid>




                </SwipeableViews>




              </div>

            }
          </Container>

      
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{"Após modificar a senha, você será deslogado e redirecionado para a página de login"}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="default"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}