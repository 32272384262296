import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import globalConfig from '../../../../configs/globalConfigs';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {

    Menu,
    MenuItem,
} from '@material-ui/core';

import icone_visao_geral_rh from '../../../../assets/imgs/icone_visao_geral_rh.png';
import icone_unidades from '../../../../assets/imgs/icone_unidades.png';
import icone_recursos_humanos from '../../../../assets/imgs/icone_recursos_humanos.png';
import icone_area_gestao from '../../../../assets/imgs/icone_area_gestao.png';

import icone_recursos_operacionais from '../../../../assets/imgs/icone_recursos_operacionais.png';
import icone_recursos_financeiros from '../../../../assets/imgs/icone_recursos_financeiros.png';



import Cookies from 'js-cookie';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListSubheader from '@material-ui/core/ListSubheader';

import './styles.css'; // Importe o arquivo de estilo CSS


const drawerWidth = 300;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: 'white',
        backgroundColor: 'black',
        zIndex: 0,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: 'black',
        ...theme.mixins.toolbar,
    },
    icone: {
        color: 'white',
        backgroundColor: 'black',

    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    appBarShift: {


    },
    appBarDesktop: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShiftDesktop: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),


    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: '#1C1C1C',
        color: 'white',
        height: '100%',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transitionDuration: 0,
    },

    drawerPaperClose: {

        transitionDuration: 0,
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerPaperDesktop: {
        height: '100%',
        backgroundColor: 'black',
        color: "white",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperCloseDesktop: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
}));


export default function MenuAreaGestao(props) {




    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [width, setWidth] = useState(0);

    const regra = Cookies.get('regra');


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };




    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (largura < 900)
            setOpen(false);
        else
            setOpen(true);

        setWidth(largura)

    }


    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });

    const toggleDrawer = (option) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (option) {
            handleDrawerClose();
        } else {
            handleDrawerOpen();
        }

    };


    useEffect(() => {

        checkDimenssoes();

    }, [width]);



    function confirmSair() {
        Cookies.set('token', undefined);
        window.location.href = "/login"


    }



    const Lista = () => {


        const [isAreaGestaoOpen, setIsAreaGestaoOpen] = useState(null);

        const toggleAreaGestao = (event) => {
            setIsAreaGestaoOpen(event.currentTarget);
        };

        const closeAreaGestao = () => {
            setIsAreaGestaoOpen(null);
        };


        return (
            <div>



                {(regra.includes('SUPER_ADMIN') || regra.includes('ADMIN_TEC') || regra.includes('ADMIN_FINAN')  )  ? (
                    <ListItem button component="a" href="/gestao" className="menu-item" >

                        <ListItemIcon>
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                <img alt={'icone_visao_geral_rh'} style={{ textAlign: 'center' }} src={icone_visao_geral_rh}></img>
                            </div>

                        </ListItemIcon>
                        <ListItemText primary="Visão Geral" />
                    </ListItem>




                ) : null}


                <ListItem button component="a" href="/gestao/operacional" className="menu-item" >

                    <ListItemIcon>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                            <img alt={'icone_recursos_operacionais'} style={{ textAlign: 'center' }} src={icone_recursos_operacionais}></img>
                        </div>

                    </ListItemIcon>
                    <ListItemText primary="Recursos Operacionais" />
                </ListItem>

                <ListItem button component="a" href="/gestao/administracao" className="menu-item" >

                    <ListItemIcon>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                            <img alt={'icone_unidades'} style={{ textAlign: 'center' }} src={icone_unidades}></img>
                        </div>

                    </ListItemIcon>
                    <ListItemText primary="Recursos Administrativos" />
                </ListItem>

                <ListItem button component="a" href="/gestao/rh" className="menu-item" >
                    <ListItemIcon>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                            <img alt={'icone_recursos_humanos'} style={{ textAlign: 'center' }} src={icone_recursos_humanos}></img>
                        </div>
                    </ListItemIcon>
                    <ListItemText primary="Recursos Humanos" />
                </ListItem>

                <ListItem button component="a" href="/gestao/financas" className="menu-item" >
                    <ListItemIcon>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                            <img alt={'icone_recursos_financeiros'} style={{ textAlign: 'center' }} src={icone_recursos_financeiros}></img>
                        </div>
                    </ListItemIcon>
                    <ListItemText primary="Recursos Financeiros" />
                </ListItem>







                <Divider />

                <ListSubheader style={{ color: 'white' }} inset>Opções</ListSubheader>
                <ListItem button onClick={confirmSair}>
                    <ListItemIcon>
                        <ExitToAppIcon style={{ color: 'red' }} />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                </ListItem>
            </div>
        );

    }


    const MenuMobile = () => {
        return (
            <>

                <CssBaseline />
                <AppBar position="absolute" style={{ top: 60 }} className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar id="target" className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"


                        >
                            <Grid
                                container
                                direction="row"
                                item xs={6} sm={6} md={6} lg={6} xl={6}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer style={{ position: 'absolute', top: 275 }}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    variant="temporary"
                    disableBackdropTransition
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    anchor={'left'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton className={classes.icone} onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    <Lista />
                </SwipeableDrawer>
            </>
        )
    }

    const MenuDesktop = () => {
        return (
            <>
                <CssBaseline />
                <AppBar position="absolute" style={{ top: globalConfig.appBarTop }} className={clsx(classes.appBarDesktop, open && classes.appBarShiftDesktop)}>
                    <Toolbar id="target" className={classes.toolbar} >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"


                        >
                            <Grid
                                container
                                direction="row"
                                item xs={6} sm={6} md={6} lg={6} xl={6}
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {props.link1 !== undefined ?
                                        <Grid  >
                                            <Link to={props.link1[1]}>
                                                <Typography component="h1" style={{fontSize:14}}  variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link1[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }
                                    {props.link1 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }

                                    {props.link2 !== undefined ?
                                        <Grid>
                                            <Link to={props.link2[1]}>
                                                <Typography component="h1" style={{fontSize:14}}  variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link2[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }
                                    {props.link2 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link3 !== undefined ?
                                        <Grid>
                                            <Link to={props.link3[1]}>
                                                <Typography component="h1" style={{fontSize:14}}  variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link3[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link3 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link4 !== undefined ?
                                        <Grid>
                                            <Link to={props.link4[1]}>
                                                <Typography component="h1" style={{fontSize:14}}  variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link4[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link4 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link5 !== undefined ?
                                        <Grid>
                                            <Link to={props.link5[1]}>
                                                <Typography component="h1" style={{fontSize:14}}  variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link5[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link5 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link6 !== undefined ?
                                        <Grid>
                                            <Link to={props.link6[1]}>
                                                <Typography style={{ fontSize: 14 }} component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link6[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link6 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    <Typography component="h1" style={{ fontSize: 16 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                        {props.titulo}
                                    </Typography>

                                </Grid>

                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>

                <Drawer

                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaperDesktop, !open && classes.drawerPaperCloseDesktop),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton className={classes.icone} onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <Lista />

                </Drawer>
            </>
        )
    }

    return (


        <div>
            {
                width < 900 ?
                    <MenuMobile /> : <MenuDesktop />
            }


        </div>


    )

};