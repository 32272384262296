import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import api from '../../../services/api';
import MenuCliente from '../components/menu';
import NavBarAdmin from "../../../components/NavBarAdmin";
import Skeleton from '@material-ui/lab/Skeleton';
import './monitoramento.css';
import { styled } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
//import SockJS from "sockjs-client";

import {
    GoogleMap,

    LoadScript,


    OverlayView, Polygon, Circle
} from "@react-google-maps/api";
import moment from 'moment';
import { useParams } from "react-router-dom";
import icone_receptor from '../../../assets/imgs/icone_receptor.png';
import icone_controle_acesso from '../../../assets/imgs/icone_controle_acesso.png';
import icone_transmissor from '../../../assets/imgs/icone_transmissor.png';
import { MenuItem, Select, InputLabel } from "@material-ui/core";
import Button from '@material-ui/core/Button';


import icone_camera from '../../../assets/imgs/icone_camera.png';


const drawerWidth = 240;

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'green',
        borderColor: 'green',
    },
}));


const ColorButtonYellow = styled(Button)(({ theme }) => ({
    color: 'black',
    backgroundColor: 'yellow',
    borderColor: 'green',
    '&:hover': {
        backgroundColor: 'yellow',
        color: 'black',
        borderColor: 'red',
    },
}));




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


const alert_beep = '/alert_beep.mp3';



export default function MonitoramentoCliente() {

  
/*
    useEffect(() => {
        //const ws = new WebSocket("ws://localhost:8080/monitoramentowebsocket");
        const ws = new WebSocket("wss://monitoramentowebsocket");

        ws.onopen = function() {
          console.log("WebSocket connection open");
          ws.send(JSON.stringify({ destination: "/app/mensagem" }));
        };
      
        ws.onmessage = function(e) {
        //  console.log("WebSocket message received: ", e.data);

          let response = JSON.parse(e.data);
          console.log("Resposta: " + response );




          setReceptores(response.receptores);




          setTransmissores(response.transmissores);

          let arrayZonasTransmissores = [];
          let i = 0;
          console.log("N de trans: " + response.transmissores.length);
          while (i < response.transmissores.length) {


              let zonas_deste_transmissor = response.transmissores[i].zonas;
              if (zonas_deste_transmissor !== null) {

                  console.log("N de zonas: " + zonas_deste_transmissor.length);

                  let j = 0;
                  while (j < zonas_deste_transmissor.length) {
                      arrayZonasTransmissores.push(zonas_deste_transmissor[j]);
                      j++;
                  }
              }


              i++;





          }

          setZonasTransmissores(arrayZonasTransmissores);

          setControladoresAcesso(response.ctrls);
          let arrayZonasControladores = [];
          let t = 0;
          console.log("N de ctrls: " + response.ctrls.length);
          while (t < response.ctrls.length) {


              let zonas_deste_ctrl = response.ctrls[t].zonas;
              if (zonas_deste_ctrl !== null) {

                  console.log("N de zonas: " + zonas_deste_ctrl.length);

                  let u = 0;
                  while (u < zonas_deste_ctrl.length) {
                      arrayZonasControladores.push(zonas_deste_ctrl[u]);
                      u++;
                  }
              }

              t++;

          }

          setZonasControladores(arrayZonasControladores);



        };
      
        ws.onclose = function() {
          console.log("WebSocket connection closed");
        };
      
        return () => {
          ws.close();
        };
      }, []);
  
      

    const { idFornecedor } = useParams();
    const classes = useStyles();
    const [transmissores, setTransmissores] = useState([]);
    const [receptores, setReceptores] = useState([]);
    const [controladoresAcesso, setControladoresAcesso] = useState([]);
    const [fornecedor, setFornecedor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [legendas, setLegendas] = useState(1);


    const [zonasTransmissores, setZonasTransmissores] = useState([])
    const [zonasControladores, setZonasControladores] = useState([])

    const [center, setCenter] = useState();

    const [largura, setLargura] = useState(0);
    const [altura, setAltura] = useState(0);


    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        // console.log("altura: " + altura);
        // console.log("largura: " + largura);

        setLargura(largura);
        setAltura(altura);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {



        checkDimenssoes();



    }, []);


    const containerStyle = {
        width: largura * 0.9,
        height: altura * 0.8,
    };

    const [audio] = useState(new Audio(alert_beep));
    const [isPlaying, setIsPlaying] = useState(false);



    useEffect(() => {

        const hasOnlineTransmissor = transmissores.some(transmissor => transmissor.status_online === 0);

        const hasOnlineCtrl = controladoresAcesso.some(ctrl => ctrl.status_online === 0);


        setIsPlaying(hasOnlineTransmissor || hasOnlineCtrl);

        return () => {
            setIsPlaying(false);
        };


    }, [transmissores, controladoresAcesso]);



    useEffect(() => {
        if (isPlaying) {
            const intervalId = setInterval(() => {
                audio.play();
            }, 2000);

            return () => {
                clearInterval(intervalId);
                audio.pause();
                audio.currentTime = 0;
            };
        } else {
            audio.pause();
            audio.currentTime = 0;
        }
    }, [audio, isPlaying]);




    useEffect(() => {

        async function listarDados() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                const url = "v1/protected/fornecedores/listadordadosmonitoramento/" + idFornecedor;
                console.log("Url: " + url)

                await api.get(url, {
                    headers: headers
                }).then(function (response) {

                    setFornecedor(response.data.fornecedor);

                    console.log("Id do fornecedor: " + response.data.fornecedor.id_fornecedor);
                    setReceptores(response.data.receptores);




                    setTransmissores(response.data.transmissores);

                    let arrayZonasTransmissores = [];
                    let i = 0;
                    console.log("N de trans: " + response.data.transmissores.length);
                    while (i < response.data.transmissores.length) {


                        let zonas_deste_transmissor = response.data.transmissores[i].zonas;
                        if (zonas_deste_transmissor !== null) {

                            console.log("N de zonas: " + zonas_deste_transmissor.length);

                            let j = 0;
                            while (j < zonas_deste_transmissor.length) {
                                arrayZonasTransmissores.push(zonas_deste_transmissor[j]);
                                j++;
                            }
                        }


                        i++;





                    }


                    setZonasTransmissores(arrayZonasTransmissores);

                    setControladoresAcesso(response.data.ctrls);
                    let arrayZonasControladores = [];
                    let t = 0;
                    console.log("N de ctrls: " + response.data.ctrls.length);
                    while (t < response.data.ctrls.length) {


                        let zonas_deste_ctrl = response.data.ctrls[t].zonas;
                        if (zonas_deste_ctrl !== null) {

                            console.log("N de zonas: " + zonas_deste_ctrl.length);

                            let u = 0;
                            while (u < zonas_deste_ctrl.length) {
                                arrayZonasControladores.push(zonas_deste_ctrl[u]);
                                u++;
                            }
                        }


                        t++;





                    }

                    setZonasControladores(arrayZonasControladores);



                    let centerFornecedor = {
                        lat: parseFloat(response.data.fornecedor.latitude),
                        lng: parseFloat(response.data.fornecedor.longitude),
                    };


                    setCenter(centerFornecedor);
                    setLoading(false)





                });



            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar dados de monitoramento: " + _err)

            }

        }

       listarDados();





    }, [idFornecedor]);


    /*
        useEffect(() => {
    
    
    
            const interval = setInterval(() => {
                async function pesquisarBackGround() {
                    try {
    
    
    
                        const url = "v1/protected/fornecedores/listadordadosmonitoramento/" + idFornecedor;
                        console.log("Url: " + url);
                        await api.get(url).then(function (response) {
    
                            if (response.data !== null) {
    
                                setReceptores(response.data.receptores);
    
    
    
                                setTransmissores(response.data.transmissores);
                                let arrayZonasTransmissores = [];
                                let i = 0;
                                console.log("N de trans: " + response.data.transmissores.length);
                                while (i < response.data.transmissores.length) {
    
    
                                    let zonas_deste_transmissor = response.data.transmissores[i].zonas;
                                    if (zonas_deste_transmissor !== null) {
    
                                        console.log("N de zonas: " + zonas_deste_transmissor.length);
    
                                        let j = 0;
                                        while (j < zonas_deste_transmissor.length) {
                                            arrayZonasTransmissores.push(zonas_deste_transmissor[j]);
                                            j++;
                                        }
                                    }
    
    
                                    i++;
    
    
    
    
    
                                }
    
    
    
                                setZonasTransmissores(arrayZonasTransmissores);
    
                                setControladoresAcesso(response.data.ctrls);
    
                            }
                        });
    
    
    
                    } catch (_err) {
                        //alert("Erro de Conexão, tente novamente mais tarde");
    
                    }
                }
    
                pesquisarBackGround();
    
    
            }, 15000);
    
            return () => clearInterval(interval);
    
    
    
    
        }, [idFornecedor]);
    

    function formatDataAndHour(data) {
        var dataCtr = moment(data, "YYYY-MM-DD hh:mm:ss");
        return dataCtr.format("DD/MM/YYYY HH:mm:ss");
    }



    async function handleRele(idTransmissor, rele) {



        const token = Cookies.get('token');

        const headers = {
            'Authorization': 'Bearer ' + token
        }


        var result = await api.get("v1/protected/acao/acionarrele/" + idTransmissor + "/" + rele, {
            headers: headers
        });

        if (result.status === 200) {
            //  alert("Ação enviada ao banco de dados, aguarde alguns segundos pela resposta do servidor");



        } else {
            alert("Erro ao enviar ação ao banco de dados!");
        }
    }




    const AnyReactComponent = ({ id_transmissor, text, data, status, lat, lng }) => {
        const [isOpen, setIsOpen] = useState(false);



        const handleMarkerClick = () => {

            console.log("clickado");
            setIsOpen(true);
        };

        const handleInfoWindowClose = () => {
            setIsOpen(false);
        };

        return (
            <>
                <OverlayView

                    position={{ lat, lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({
                        x: -(width / 2),
                        y: -(height / 2),
                    })}


                >
                    <div style={{
                        position: "relative",
                    }}
                    >

                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: "translate(-50%, -50%)",
                                height: "100px",
                                width: "100px",
                                backgroundColor: 'rgb(0,0,0,0.2)',
                                borderRadius: '100px',
                                zIndex: 9999,


                            }}
                            className={status === 0 ? "piscar" : ""}
                        >

                            <IconButton
                                style={{ zIndex: 9999 }}
                                onClick={handleMarkerClick}>
                                <img src={icone_transmissor} alt="icone_transmissor" />
                            </IconButton>

                            <div
                            >

                                {legendas === 2 ?
                                    <span style={{ color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {"Monitor nº série: " + text}</span>
                                    : legendas === 1 ?
                                        <span style={{ color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {text}</span>

                                        : null
                                }

                                {legendas === 2 ?
                                    <span style={{ color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Última Resposta: " + formatDataAndHour(data)}</span>
                                    : null
                                }

                            </div>
                            {legendas === 2 || legendas === 1 ?
                                <span style={{ color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Status: " + (status === 1 ? "🟢" : "🔴")}</span>
                                : null
                            }
                        </div>

                        {isOpen && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    transform: "translate(-50%, -100%)",
                                    backgroundColor: "white",
                                    padding: "10px",
                                    border: "1px solid black",
                                    zIndex: 9999

                                }}
                            >

                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                    style={{ padding: 10 }}

                                >
                                    <button
                                        style={{ position: "absolute", top: 0, right: 0 }}
                                        onClick={(e) => handleInfoWindowClose()}
                                    >
                                        X
                                    </button>



                                    <ColorButtonYellow style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleRele(id_transmissor, 1)}
                                    >
                                        Acionar Rele 1
                                    </ColorButtonYellow>

                                    <ColorButtonYellow style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleRele(id_transmissor, 2)}
                                    >
                                        Acionar Rele 2
                                    </ColorButtonYellow>







                                </Grid>

                            </div>

                        )}
                    </div>

                </OverlayView>
            </>
        );
    };



    const ZonaReactComponent = ({ lat, lng, tipo_equipamento, descricao, raio, rotacao, zona_violada, link }) => {

        const [isOpen, setIsOpen] = useState(false);

        const handleMarkerClick = () => {
            console.log("clickado");
            setIsOpen(true);
        };

        const handleInfoWindowClose = () => {
            setIsOpen(false);
        };


        const [audio] = useState(new Audio(alert_beep));
        const [isPlaying, setIsPlaying] = useState(false);

        function handleOpenLink(link) {
            window.open(link, '_blank');
        }



        useEffect(() => {


            setIsPlaying(zona_violada === 1);

            return () => {
                setIsPlaying(false);
            };


        }, [zona_violada]);



        useEffect(() => {
            if (isPlaying) {
                const intervalId = setInterval(() => {
                    audio.play();
                }, 10);

                return () => {
                    clearInterval(intervalId);
                    audio.pause();
                    audio.currentTime = 0;
                };
            } else {
                audio.pause();
                audio.currentTime = 0;
            }
        }, [audio, isPlaying]);


        function returnIcone(tipo_equipamento) {

            if (tipo_equipamento === 1) {
                return icone_camera;
            }
        }


        const [show, setShow] = useState(true);

        useEffect(() => {
            if (zona_violada === 1) {
                const intervalId = setInterval(() => {
                    setShow((show) => !show);
                }, 500);
                return () => clearInterval(intervalId);
            }
        }, [zona_violada]);


        let rotationAngle = rotacao; // em graus


        let initialVertex = {
            lat: lat,
            lng: lng,
        };

        const metersToLatLng = (meters) => meters / 111111;


        const latLngDistance = metersToLatLng(raio);


        // Calcular as coordenadas dos outros dois vértices
        const vertex2 = {
            lat: initialVertex.lat - latLngDistance,
            lng: initialVertex.lng - latLngDistance,
        };
        const vertex3 = {
            lat: initialVertex.lat - latLngDistance,
            lng: initialVertex.lng + latLngDistance,
        };
        // Calcular as coordenadas dos vértices girados
        const angleInRadians = (rotationAngle * Math.PI) / 180;
        const cosTheta = Math.cos(angleInRadians);
        const sinTheta = Math.sin(angleInRadians);

        const rotatedVertex1 = initialVertex;
        const rotatedVertex2 = {
            lat:
                cosTheta * (vertex2.lat - initialVertex.lat) -
                sinTheta * (vertex2.lng - initialVertex.lng) +
                initialVertex.lat,
            lng:
                sinTheta * (vertex2.lat - initialVertex.lat) +
                cosTheta * (vertex2.lng - initialVertex.lng) +
                initialVertex.lng,
        };
        const rotatedVertex3 = {
            lat:
                cosTheta * (vertex3.lat - initialVertex.lat) -
                sinTheta * (vertex3.lng - initialVertex.lng) +
                initialVertex.lat,
            lng:
                sinTheta * (vertex3.lat - initialVertex.lat) +
                cosTheta * (vertex3.lng - initialVertex.lng) +
                initialVertex.lng,
        };

        // Criar um array de objetos que representam os vértices do triângulo, na ordem em que devem ser conectados por segmentos.
        const triangleVertices = [rotatedVertex1, rotatedVertex2, rotatedVertex3];



        const triangleOptions = {
            strokeColor: zona_violada === 0 ? "green" : "red",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: zona_violada === 0 ? "green" : "red",
            fillOpacity: 0.35,
            animation: "piscar 1s ease-in-out infinite",

        };


        return (
            <>
                <OverlayView

                    position={{ lat, lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({
                        x: -(width / 2),
                        y: -(height / 2),
                    })}
                >
                    <div style={{ position: "relative" }}

                    >
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: "translate(-50%, -50%)",
                                height: "40px",
                                width: "40px",
                            }}

                        >


                            <div
                                style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>

                                <IconButton
                                    style={{ zIndex: 9999 }}
                                    onClick={(e) => handleMarkerClick()}>
                                    <img src={returnIcone(tipo_equipamento)} alt="icone_equipamento" />
                                </IconButton>


                            </div>


                            {legendas === 2 ?
                                <span
                                    style={{ backgroundColor: "white", fontSize: '14px', fontWeight: 'bold' }} > {descricao}</span>
                                : null
                            }

                        </div>

                        {show &&
                            <div style={{ zIndex: -100 }}>
                                <Polygon paths={triangleVertices} options={triangleOptions} />
                            </div>

                        }


                        {isOpen && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    transform: "translate(-50%, -100%)",
                                    backgroundColor: "white",
                                    padding: "10px",
                                    border: "1px solid black",
                                    zIndex: 9999

                                }}
                            >

                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                    style={{ padding: 10 }}

                                >
                                    <button
                                        style={{ position: "absolute", top: 0, right: 0, zIndex: 9999 }}
                                        onClick={(e) => handleInfoWindowClose()}
                                    >
                                        X
                                    </button>

                                    <ColorButtonYellow style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => handleOpenLink(link)}
                                    >
                                        Link Interno
                                    </ColorButtonYellow>



                                </Grid>

                            </div>

                        )}



                    </div>

                </OverlayView>
            </>
        );
    };




    const ZonaCircleReactComponent = ({ lat, lng, tipo_equipamento, descricao, raio, rotacao, zona_violada, link }) => {



        const [audio] = useState(new Audio(alert_beep));
        const [isPlaying, setIsPlaying] = useState(false);

         

        useEffect(() => {

            setIsPlaying(zona_violada === 16 || zona_violada === 17);

            return () => {
                setIsPlaying(false);
            };


        }, [zona_violada]);


        useEffect(() => {
            if (isPlaying) {
                const intervalId = setInterval(() => {
                    audio.play();
                }, 1000);

                return () => {
                    clearInterval(intervalId);
                    audio.pause();
                    audio.currentTime = 0;
                };
            } else {
                audio.pause();
                audio.currentTime = 0;
            }
        }, [audio, isPlaying]);


        const [show, setShow] = useState(true);


        useEffect(() => {
            if (zona_violada === 16) {
                const intervalId = setInterval(() => {
                    setShow((show) => !show);
                }, 1000);
                return () => clearInterval(intervalId);
            }else if(zona_violada === 17){
                const intervalId = setInterval(() => {
                    setShow((show) => !show);
                }, 300);
                return () => clearInterval(intervalId);
            }
        }, [zona_violada]);


        const circleOptions = {
            strokeColor: zona_violada === 16 ? 'yellow' : zona_violada === 17 ? 'red' : 'rgb(0,0,0,0.3)',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: zona_violada === 16 ? 'yellow' : zona_violada === 17 ? 'red' : 'rgb(0,0,0,0.3)',
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 20,
            center: {
                lat: lat,
                lng: lng,
            },
        };

        

        return (
            <>
                <OverlayView

                    position={{ lat, lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({
                        x: -(width / 2),
                        y: -(height / 2),
                    })}
                >
                    <div style={{ position: "relative" }}

                    >
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: "translate(-50%, -50%)",
                                height: "40px",
                                width: "40px",
                            }}

                        >

                        </div>

                        {show &&
                            <div style={{ zIndex: -100 }}>
                                <Circle
                                    options={circleOptions}
                                />
                            </div>

                        }



                    </div>

                </OverlayView>
            </>
        );
    };

    async function mudarStatusCentral(id) {


        if (window.confirm("Mudar o status da central?")) {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }




            var result = await api.get("v1/protected/acao/mudarstatuscentral/" + id, {
                headers: headers
            });

            if (result.status === 200) {
                // alert("Ação enviada ao banco de dados, aguarde alguns segundos pela resposta do servidor");



            } else {
                alert("Erro ao enviar ação ao banco de dados!");
            }
        }
    }



    const ReceptorReactComponent = ({ idReceptor, text, data, status_online, lat, lng, status_central, data_central }) => {
        const [isOpen, setIsOpen] = useState(false);

        const handleMarkerClick = () => {
            setIsOpen(true);
        };

        const handleInfoWindowClose = () => {
            setIsOpen(false);
        };

        return (
            <>
                <OverlayView
                    position={{ lat, lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({
                        x: -(width / 2),
                        y: -(height / 2),
                    })}
                >
                    <div style={{ position: "relative" }}>
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: "translate(-50%, -50%)",
                                height: "100px",
                                width: "400px",
                            }}
                            className={status_online === 0 ? "piscar" : ""}
                        >


                            <div
                                onClick={(e) => handleMarkerClick()}
                                style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                                <img alt={"icone_receptor"} style={{ textAlign: 'center' }} src={icone_receptor}></img>
                            </div>
                            <div>
                                {legendas === 2 ?
                                    <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {"Receptor nº série: " + text}</span>
                                    : legendas === 1 ?
                                        <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {text}</span>

                                        : null
                                }

                                {legendas === 2 ?
                                    <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Última Resposta: " + formatDataAndHour(data)}</span>
                                    : null
                                }

                            </div>
                            {legendas === 2 || legendas === 1 ?
                                <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Status: " + (status_online === 1 ? "Online 🟢" : "Offline 🔴")}</span>
                                : null
                            }

                            {legendas === 2 || legendas === 1 ?
                                <span
                                    className={status_central === 1 ? "piscardesarmada" : ""}
                                    style={{ backgroundColor: "white", fontSize: '14px', fontWeight: 'bold', color: status_central === 1 ? "red" : "green" }} > <br></br> {"Status da Central: " + (status_central === 1 ? "DESARMADA" : "ARMADA")}</span>
                                : null
                            }

                        </div>


                        {isOpen && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    transform: "translate(-50%, -100%)",
                                    backgroundColor: "white",
                                    padding: "10px",
                                    border: "1px solid black",

                                }}
                            >

                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                    style={{ padding: 10 }}
                                >
                                    <button
                                        style={{ position: "absolute", top: 0, right: 0 }}
                                        onClick={(e) => handleInfoWindowClose()}
                                    >
                                        X
                                    </button>


                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                        style={{ padding: 20 }}>
                                        {

                                            status_central === 1 ?
                                                <ColorButtonGreen variant="contained" color="primary"
                                                    onClick={() => mudarStatusCentral(idReceptor)}

                                                >
                                                    Armar Central
                                                </ColorButtonGreen>
                                                :
                                                <ColorButtonRed variant="contained" color="primary"
                                                    onClick={() => mudarStatusCentral(idReceptor)}

                                                >
                                                    Desarmar Central
                                                </ColorButtonRed>
                                        }


                                    </Grid>


                                </Grid>

                            </div>

                        )}

                    </div>
                </OverlayView>
            </>
        );
    };




    const ControleAcessoReactComponent = ({ text, data, status, lat, lng, acao_atual }) => {






        return (
            <>
                <OverlayView
                    position={{ lat, lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({
                        x: -(width / 2),
                        y: -(height / 2),
                    })}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            transform: "translate(-50%, -50%)",
                            height: "100px",
                            width: "400px",
                        }}
                        className={status === 0 ? "piscar" : ""}
                    >

                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                            <img alt={"icone_controle_acesso"} style={{ textAlign: 'center' }} src={icone_controle_acesso}></img>
                        </div>
                        <div>
                            {legendas === 2 ?
                                <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {"Controle de Acesso nº série: " + text}</span>
                                : legendas === 1 ?
                                    <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {text}</span>

                                    : null
                            }

                            {legendas === 2 ?
                                <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Última Resposta: " + formatDataAndHour(data)}</span>
                                : null
                            }

                        </div>
                        {legendas === 2 || legendas === 1 ?
                            <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Status: " + (status === 1 ? "Online 🟢" : "Offline 🔴")}</span>
                            : null
                        }
                    </div>
                </OverlayView>

            </>
        );
    };



    const options = {
        mapTypeId: 'satellite',
        mapTypeControl: true,
        streetViewControl: true,
        gestureHandling: 'greedy',
        clickableIcons: true,
        mapPanes: {
            overlayLayer: {
                zIndex: 150,
                mouseEvents: false,
                shouldOverlay: true,
            },
        },

    };

    function returnEnderecoFornecedor(fornecedor) {
        var texto = (fornecedor.tipo_endereco === 0 ? "" : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
        return texto;
    }

    function returnNomeFornecedor(fornecedor) {
        var texto = (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);
        return texto;
    }




    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
            <MenuCliente titulo={"Monitoramento do Sistema de Alarme em Tempo Real"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?

                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <div>

                                <Grid
                                    item xs={12}
                                    direction="column"
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ padding: 20 }}

                                >

                                    <span style={{ color: 'black', fontWeight: 'bold', fontSize: '36px' }}>{returnNomeFornecedor(fornecedor)}</span>
                                    <span style={{ color: 'black', fontSize: '18px' }}>{returnEnderecoFornecedor(fornecedor)}</span>

                                </Grid>
                                <Grid
                                    item xs={12}
                                    direction="row"
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ padding: 20 }}

                                >

                                    <InputLabel id="legendas" style={{ color: 'black' }}>Legendas?:</InputLabel>

                                    <Select
                                        labelId="legendas"
                                        id="legendas"
                                        value={legendas}
                                        name="legendas"
                                        label="Legendas"

                                        style={{ backgroundColor: 'white' }}
                                        onChange={(e) => setLegendas(Number(e.target.value))}
                                    >
                                        <MenuItem value={0}>SEM LEGENDAS</MenuItem>
                                        <MenuItem value={1}>SIMPLIFICADO</MenuItem>
                                        <MenuItem value={2}>COMPLETO</MenuItem>


                                    </Select>
                                </Grid>
                                <Grid container

                                    item xs={12}
                                    direction="row"
                                    justifyContent="center"
                                    align="center"

                                >
                                    <LoadScript googleMapsApiKey={"AIzaSyDYUFTfxXyi3TBHgkne5iKbsgooVXchxUU"}>

                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            options={options}
                                            center={center}
                                            zoom={18}

                                        >



                                            {transmissores.map((transmissor) => {
                                                return (
                                                    <AnyReactComponent
                                                        key={transmissor.id_transmissor}
                                                        id_transmissor={transmissor.id_transmissor}
                                                        lat={parseFloat(transmissor.latitude)}
                                                        lng={parseFloat(transmissor.longitude)}
                                                        text={transmissor.numero_serie}
                                                        data={transmissor.data_hora_ultima_resposta}
                                                        status={transmissor.status_online}
                                                    />
                                                )
                                            })}


                                            {zonasTransmissores.map((zona) => {
                                                return (
                                                    <ZonaReactComponent
                                                        key={zona.id_zona}
                                                        lat={parseFloat(zona.latitude)}
                                                        lng={parseFloat(zona.longitude)}
                                                        tipo_equipamento={zona.tipo_equipamento}
                                                        descricao={zona.descricao_equipamento}
                                                        raio={zona.raio_perimetro}
                                                        rotacao={zona.rotacao}
                                                        zona_violada={zona.zona_violada}
                                                        link={zona.link_local}
                                                    />
                                                )
                                            })}






                                            {receptores.map((receptor) => {
                                                return (
                                                    <ReceptorReactComponent
                                                        key={receptor.id_receptor}
                                                        idReceptor={receptor.id_receptor}

                                                        lat={parseFloat(receptor.latitude)}
                                                        lng={parseFloat(receptor.longitude)}
                                                        text={receptor.numero_serie}
                                                        data={receptor.data_hora_ultima_resposta}
                                                        status_online={receptor.status_online}
                                                        status_central={receptor.status_central}
                                                        data_central={receptor.data_hora_ultima_resposta_central}
                                                    />
                                                )
                                            })}

                                            {controladoresAcesso.map((ctrl) => {
                                                return (
                                                    <ControleAcessoReactComponent
                                                        key={ctrl.id_controlador_acesso}

                                                        lat={parseFloat(ctrl.latitude)}
                                                        lng={parseFloat(ctrl.longitude)}
                                                        text={ctrl.numero_serie}
                                                        data={ctrl.data_hora_ultima_resposta}
                                                        status={ctrl.status_online}
                                                        acao_atual={ctrl.zona_violada}
                                                    />
                                                )
                                            })}


                                            {zonasControladores.map((zona) => {
                                                return (
                                                    <ZonaCircleReactComponent
                                                        key={zona.id_zona}
                                                        lat={parseFloat(zona.latitude)}
                                                        lng={parseFloat(zona.longitude)}
                                                        tipo_equipamento={zona.tipo_equipamento}
                                                        descricao={zona.descricao_equipamento}
                                                        raio={zona.raio_perimetro}
                                                        rotacao={zona.rotacao}
                                                        zona_violada={zona.zona_violada}
                                                        link={zona.link_local}
                                                    />
                                                )
                                            })}



                                        </GoogleMap>

                                    </LoadScript>

                                </Grid>
                            </div>
                        }

                    </Container>
                </main >
            </div >
        </div >
    );
    */
}


