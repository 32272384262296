// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alogo {
  color: #1abc9c;
  margin-left: 50px;
  text-decoration: none;
  background-color: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/painelFornecedor/Cartoes/styles.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,iBAAA;EACA,qBAAA;EACA,sBAAA;AAAF","sourcesContent":["\r\n.alogo {\r\n  color: #1abc9c;\r\n  margin-left: 50px;\r\n  text-decoration: none;\r\n  background-color: none;\r\n}\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
