import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useHistory } from "react-router-dom";

import moment from 'moment';
import { MenuItem, Select, InputLabel } from "@material-ui/core";


import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastroTransmissor() {

    const classes = useStyles();
    const history = useHistory();

    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');
    const [dataFabricacao, setDataFabricacao] = useState('');
    
    const [descricao, setDescricao] = useState('');

    const status = 0;

    const [codigo, setCodigo] = useState('');


    const [erroCodigo, setErroCodigo] = useState(false);
    const [textoErroCodigo, setTextoErroCodigo] = useState('');

    const [erroMarca, setErroMarca] = useState(false);
    const [textoErroMarca, setTextoErroMarca] = useState('');

    const [erroModelo, setErroModelo] = useState(false);
    const [textoErroModelo, setTextoErroModelo] = useState('');

    const [erroDataFabricacao, setErroDataFabricacao] = useState(false);
    const [textoErroDataFabricacao, setTextoErroDataFabricacao] = useState('');

    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [fornecedores, setFornecedores] = useState(null);

    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [erroPassword, setErroPassword] = useState(false);
    const [textoErroPassword, setTextoErroPassword] = useState('');


    const [receptorSelecionado, setReceptorSelecionado] = useState(null);
    const [receptores, setReceptores] = useState(null);

    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');


    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia) +
            "\n Endereço: " + (fornecedor.tipo_endereco === 0 ? "Normal " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
        return texto;
    }




    const verificaEspaco = (string) => /\s/g.test(string);

    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataFabricacao(false);
            setTextoErroDataFabricacao("")
        } else {
            setErroDataFabricacao(true);
            setTextoErroDataFabricacao("Data de Fabricação Inválida!")
        }
        return teste;
    }

    function validarMarca() {
        if (marca?.length > 0) {
            setErroMarca(false);
            setTextoErroMarca('');
            return true;
        } else {
            setErroMarca(true);
            setTextoErroMarca("Nome da Marca Inválido!");
            return false;
        }
    }

    function validarModelo() {
        if (modelo?.length > 0) {
            setErroModelo(false);
            setTextoErroModelo('');
            return true;
        } else {
            setErroModelo(true);
            setTextoErroModelo("Nome do Modelo Inválido!");
            return false;
        }
    }

    function validarCodigo() {
        if (codigo?.length > 0) {
            setErroCodigo(false);
            setTextoErroCodigo('');
            return true;
        } else {
            setErroCodigo(true);
            setTextoErroCodigo("Código Inválido!");
            return false;
        }
    }

    function validateSenha() {
        if (password.length >= 8) {
            if (password === password2) {

                setErroPassword(false);
                setTextoErroPassword('');
                return true;
            } else {

                setErroPassword(true);
                setTextoErroPassword("Senhas não conferem");
                console.log("senhas nao conferem");
                return false;
            }
        } else {
            setErroPassword(true);
            setTextoErroPassword("Senha muito curta");
            console.log("Senha muito curta");
            return false;
        }
    }





    async function salvar() {

        validarCodigo();
        validarMarca();
        validarModelo();
        validarData(dataFabricacao);

        validateSenha();

        if (validarCodigo() && validarMarca() && validarModelo() && validarData(dataFabricacao) && validateSenha()) {
            console.log("cadastro aceito!");

            try {

                console.log("cadastrar chamado");
                const cadastro_transmissor = {
                    numero_serie: codigo,
                    marca: marca,
                    modelo: modelo,
                    data_fabricacao: dataFabricacao,
                    status: status,
                    fornecedor: fornecedorSelecionado !== null ? { id_fornecedor: fornecedorSelecionado.id_fornecedor } : null,
                    receptor: receptorSelecionado !== null ? { id_receptor: receptorSelecionado.id_receptor } : null,

                    senhaweb: password,
                    descricao: descricao,
                    latitude: latitude,
                    longitude: longitude,
                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.post('/v1/protected/transmissores/cadastrar', cadastro_transmissor,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    history.push({
                        pathname: '/transmissores',
                    })
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }

        } else {
            console.log("cadastro rejeitado!");
        }


    }

    async function listarFornecedores() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/fornecedores/listarTodos", {
                headers: headers
            }).then(function (response) {
                setFornecedores(response.data)
                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Fornecedores: " + _err)

        }

    }


    useEffect(() => {
        listarFornecedores();


    }, []);


    async function listarReceptoresPorFornecedor(idFornecedor) {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/receptores/listarporfornecedor/" + idFornecedor, {
                headers: headers
            }).then(function (response) {
                setReceptores(response.data)
            });

            setLoading(false);

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Receptores: " + _err)

        }

    }

    function returnDescricaoReceptor(receptor) {
        var texto = "  Receptor_ID: " + receptor.id_receptor +
            "\n Número de Série: " + receptor.numero_serie +
            "\n Marca: " + receptor.marca +
            "\n Modelo: " + receptor.modelo
            ;
        return texto;
    }

    function handleSetFornecedor(fornecedor) {
        listarReceptoresPorFornecedor(fornecedor.id_fornecedor)
        setReceptorSelecionado(null);
        setFornecedorSelecionado(fornecedor);
    }

    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Cadastrar Novo Transmissor"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Grid container spacing={2}   >



                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5} >
                                            <Autocomplete
                                                id="select-fornecedor"
                                                options={fornecedores}
                                                disableCloseOnSelect
                                                getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor)}
                                                value={fornecedorSelecionado}
                                                onChange={(e, v) => handleSetFornecedor(v)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Fornecedor "

                                                        multiline
                                                        rows={6} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5} >
                                            <Autocomplete
                                                id="select-fornecedor"
                                                options={receptores}
                                                disableCloseOnSelect
                                                getOptionLabel={(receptor) => returnDescricaoReceptor(receptor)}
                                                value={receptorSelecionado}
                                                onChange={(e, v) => setReceptorSelecionado(v)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Receptores do Cliente "

                                                        multiline
                                                        rows={6} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid style={{ backgroundColor: 'white' }} component={Paper} elevation={12}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            spacing={2}>

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">STATUS:</InputLabel>
                                                <Select
                                                    labelId="status"
                                                    id="status"
                                                    value={status}
                                                    name="status"
                                                    label="status"
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    <MenuItem value={0}>LIVRE</MenuItem>
                                                    <MenuItem value={1}>EM MANUTENÇÃO</MenuItem>
                                                    <MenuItem value={2}>INSTALADO</MenuItem>
                                                    <MenuItem value={3}>INUTILIZADO</MenuItem>

                                                </Select>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} >
                                                <Grid item xs={3}  >
                                                    <Typography style={{ margin: 15 }} >
                                                        <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados do Transmissor: </span>
                                                    </Typography>

                                                    <TextField
                                                        error={erroCodigo}
                                                        id="codigo"
                                                        helperText={textoErroCodigo}
                                                        variant="standard"
                                                        name="codigo"
                                                        fullWidth
                                                        label="Número de Série"
                                                        required
                                                        autoComplete="codigo"
                                                        value={codigo}
                                                        onChange={e => setCodigo(e.target.value)}
                                                        inputProps={{ maxLength: 50, minLength: 1 }}

                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={3}  >
                                                <TextField
                                                    error={erroMarca}
                                                    id="marca"
                                                    helperText={textoErroMarca}
                                                    variant="standard"
                                                    name="marca"
                                                    fullWidth
                                                    label="Marca"
                                                    required
                                                    autoComplete="marca"
                                                    value={marca}
                                                    onChange={e => setMarca(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                            <Grid item xs={3}  >
                                                <TextField
                                                    error={erroModelo}
                                                    id="modelo"
                                                    helperText={textoErroModelo}
                                                    variant="standard"
                                                    name="modelo"
                                                    fullWidth
                                                    label="Modelo"
                                                    required
                                                    autoComplete="modelo"
                                                    value={modelo}
                                                    onChange={e => setModelo(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                            <Grid item xs={3}  >
                                                <TextField
                                                    error={erroDataFabricacao}
                                                    id="dataFabricacao"
                                                    helperText={textoErroDataFabricacao}
                                                    variant="standard"
                                                    name="dataFabricacao"
                                                    fullWidth
                                                    label="Data de Fabricação"
                                                    required
                                                    autoComplete="dataFabricacao"
                                                    value={dataFabricacao}
                                                    onChange={e => setDataFabricacao(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 1 }}

                                                />
                                            </Grid>

                                            <Grid item xs={12}  >
                                                <TextField
                                                    id="descricao"
                                                    variant="standard"
                                                    name="descricao"
                                                    fullWidth
                                                    label="Descrição"
                                                    required
                                                    autoComplete="descricao"
                                                    value={descricao}
                                                    onChange={e => setDescricao(e.target.value)}

                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} >
                                                <Grid item xs={3}  >
                                                    <Typography style={{ margin: 15 }} >
                                                        <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Localização: </span>
                                                    </Typography>

                                                    <TextField
                                                        id="latitude"
                                                        variant="standard"
                                                        name="latitude"
                                                        fullWidth
                                                        label="Latitude"
                                                        required
                                                        autoComplete="latitude"
                                                        value={latitude}
                                                        onChange={e => setLatitude(e.target.value)}
                                                        inputProps={{ maxLength: 50, minLength: 1 }}

                                                    />
                                                </Grid>

                                                <Grid item xs={3}  >
                                                    <Typography style={{ margin: 15 }} >
                                                        <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Localização: </span>
                                                    </Typography>

                                                    <TextField
                                                        id="longitude"
                                                        variant="standard"
                                                        name="longitude"
                                                        fullWidth
                                                        label="Longitude"
                                                        required
                                                        autoComplete="longitude"
                                                        value={longitude}
                                                        onChange={e => setLongitude(e.target.value)}
                                                        inputProps={{ maxLength: 50, minLength: 1 }}

                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        error={erroPassword}
                                                        id="password"
                                                        helperText={textoErroPassword}
                                                        variant="standard"
                                                        name="password"
                                                        fullWidth
                                                        label="Senha"
                                                        required
                                                        type="password"
                                                        autoComplete="current-password"
                                                        value={password}
                                                        onChange={e => (verificaEspaco(e.target.value) || /[`~!%^*()_|+\-=?;:¨'",.<>{}[\]/\b]+$/.test(e.target.value)) ? setTextoErroPassword('') : setPassword(e.target.value)}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        error={erroPassword}
                                                        id="password2"
                                                        helperText={textoErroPassword}
                                                        variant="standard"
                                                        name="password2"
                                                        fullWidth
                                                        label="Conferir Senha"
                                                        required
                                                        type="password"
                                                        autoComplete="current-password"
                                                        value={password2}
                                                        onChange={e => (verificaEspaco(e.target.value) || /[`~!%^*()_|+\-=?;:¨'",.<>{}[\]/\b]+$/.test(e.target.value)) ? setTextoErroPassword('') : setPassword2(e.target.value)}
                                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                                    />
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                    </Grid>





                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        item xs={10}
                                    >
                                        <Button style={{ marginTop: 50 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={salvar}
                                        > Salvar  </Button>
                                    </Grid>

                                </Grid>


                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >
        </div >
    );
}


