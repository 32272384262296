import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import { useHistory } from "react-router-dom";

import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastrarCartao() {

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [uid, setUid] = useState('');
    const [erroUid, setErroUid] = useState(false);
    const [textoErroUid, setTextoErroUid] = useState('');

    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [fornecedores, setFornecedores] = useState(null);
    const [erroFornecedor, setErroFornecedor] = useState(false);
    const [textoErroFornecedor, setTextoErroFornecedor] = useState('');

    async function listarFornecedores() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/fornecedores/listarTodos", {
                headers: headers
            }).then(function (response) {
                setFornecedores(response.data)
                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Fornecedores: " + _err)

        }

    }


    function validarUid() {
        if (uid?.length > 0) {
            setErroUid(false);
            setTextoErroUid('');
            return true;
        } else {
            setErroUid(true);
            setTextoErroUid("UID Inválido!");
            return false;
        }
    }

    function validarFornecedor() {
        if (fornecedorSelecionado !== null && fornecedorSelecionado.id_fornecedor > 0) {
            setErroFornecedor(false);
            setTextoErroFornecedor('');
            console.log("id fornecedor: " + fornecedorSelecionado.id_fornecedor)
            return true;
        } else {
            setErroFornecedor(true);
            setTextoErroFornecedor("Selecione o Fornecedor");
            return false;
        }

    }


    useEffect(() => {
        listarFornecedores();


    }, []);

    async function salvar() {

        var validacao1 = validarUid();
        var validacao2 = validarFornecedor();

        if (validacao1 && validacao2) {
            try {


                console.log("cadastrar chamado");
                const cadastro_cartao = {
                    uid: uid,
                    id_fornecedor: fornecedorSelecionado.id_fornecedor,

                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }


                var response = await api.get('/v1/protected/cartoes/verificar/' + uid,
                    { headers: headers });

                    console.log("existe: " + response.data);
                if (!response.data) {

                    response = await api.post('/v1/protected/cartoes/cadastrar', cadastro_cartao,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Cartão Cadastrado!");
                        history.push({
                            pathname: '/cartoes',
                        })
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } else {
                    alert("UID já Cadastrada!");

                }

            } catch (_err) {
                console.log("erro ao cadastrar cartao: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }
        }
    }

    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia) +
            "\n Endereço: " + (fornecedor.tipo_endereco === 0 ? "Normal " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
        return texto;
    }




    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Cadastrar Novo Veículo"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Grid container spacing={2}   >

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5} >
                                            <Autocomplete
                                                id="select-fornecedor"
                                                options={fornecedores}
                                                disableCloseOnSelect
                                                getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor)}
                                                value={fornecedorSelecionado}
                                                onChange={(e, v) => setFornecedorSelecionado(v)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Fornecedor "
                                                        error={erroFornecedor}
                                                        helperText={textoErroFornecedor}
                                                        multiline
                                                        rows={6} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}
                                        justifyContent="center"
                                        align="center"
                                    >

                                        <Grid item xs={3}  >
                                            <TextField
                                                error={erroUid}
                                                helperText={textoErroUid}
                                                variant="standard"
                                                name="uid"
                                                label="UID"
                                                required
                                                id="uid"
                                                value={uid}
                                                onChange={e => setUid(e.target.value)}
                                                inputProps={{ maxLength: 20, minLength: 1 }}
                                                fullWidth

                                            />
                                        </Grid>

                                    </Grid>




                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        item xs={10}
                                    >
                                        <Button style={{ marginTop: 50 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={salvar}
                                        > Salvar  </Button>
                                    </Grid>

                                </Grid>


                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >
        </div >
    );
}


