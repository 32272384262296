import React, { useState, useEffect } from 'react';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import logo from '../../assets/imgs/logo_fundo_preto.png';
import logo_fundo_preto from '../../assets/imgs/logo_fundo_preto.png';

import {

    Link

} from "react-router-dom";




const NavBarAdmin = () => {




    const [width, setWidth] = useState(0);
    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;


        setWidth(largura)

    }


    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });



    useEffect(() => {

        checkDimenssoes();

    }, [width]);


    



   

    return (
        <div>
            <Grid
                container
                direction="row"
                item xs={12} sm={12} md={12} lg={12} xl={12}
                style={{ backgroundColor: 'black' }}
                
            >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >

                    <div style={{ paddingTop: 10 }} >
                        <Link className="a"

                            to={{
                                pathname: "/",

                            }}
                        >


                            <img alt="logomarca" style={{ width: width < 900 ? '50%' : '50%', height: 40  , paddingLeft: 40, marginTop: 10, marginBottom: 20}}
                                src={logo_fundo_preto}
                            />

                        </Link>
                    </div>
                </Grid>




            </Grid>


           
        </div>
    )
}
export default NavBarAdmin;
