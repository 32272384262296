import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";
import { useParams } from "react-router-dom";


import Skeleton from '@material-ui/lab/Skeleton';
import GoogleMapReact from 'google-map-react';

import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


const alert_beep = '/alert_beep.mp3';


export default function GerenciarTransmissor() {

    const classes = useStyles();
    const { idTransmissor } = useParams();

    const defaultProps = {

        zoom: 21
    };
    const options = {
        mapTypeId: 'satellite'
    };

    const [latitude, setLatitude] = useState(false);
    const [longitude, setLongitude] = useState(false);
    const [status, setStatus] = useState(0);
    const [dataHoraUltimaResposta, setDataHoraUltimaRestaoa] = useState('');
    const [loading, setLoading] = useState(true);
    const [statusOnline, setStatusOnline] = useState(0);
    const [codigo, setCodigo] = useState('');





    const AnyReactComponent = ({ text, data, status }) =>


        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)', height: '100px', width: '400px', display: showText && (status === 0 || status === 1) ? 'block' : 'none'
            }} >
            <FontAwesomeIcon icon={faWifi} size="4x" style={{ color: status === 1 ? "blue" : "red" }} />

            <div>
                <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {"Monitor nº série: " + text}</span>
                <br></br>
                <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Última Resposta: " + data}</span>
            </div>
            <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }} > {"Status: " + (status === 1 ? "Online 🟢" : "Offline 🔴")}</span>
        </div>;





    const [showText, setShowText] = useState(true);
    const [audio] = useState(new Audio(alert_beep));

    useEffect(() => {
        if (showText && statusOnline === 0) {
            audio.play();
        } else {
            audio.currentTime = 0;
            audio.pause();
        }
    }, [showText, statusOnline, audio]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setShowText((showText) => !showText);
        }, 1000);

        return () => {
            clearInterval(intervalId);
            audio.pause();
            audio.currentTime = 0;
        };
    }, [audio]);

    useEffect(() => {
        setStatusOnline(status);
    }, [status]);





    useEffect(() => {




        async function listarTransmissor() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/transmissores/listar/" + idTransmissor, {
                    headers: headers
                }).then(function (response) {

                    var transmissor = response.data;

                    setCodigo(transmissor.numero_serie);
                    setStatus(transmissor.status);
                    setLatitude(transmissor.latitude)
                    setLongitude(transmissor.longitude)
                    setDataHoraUltimaRestaoa(transmissor.data_hora_ultima_resposta);
                    setStatusOnline(transmissor.status_online);
                    setLoading(false);


                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

                console.log("Erro ao listar a transmissor: " + _err)

            }

        }
        listarTransmissor();


        const interval = setInterval(() => {
            async function pesquisarBackGround() {
                try {



                    var url = "v1/protected/transmissores/listar/" + idTransmissor;
                    console.log("Url: " + url);
                    await api.get(url).then(function (response) {

                        if (response.data !== null) {

                            var transmissor = response.data;

                            setCodigo(transmissor.numero_serie);
                            setStatus(transmissor.status);
                            setLatitude(transmissor.latitude)
                            setLongitude(transmissor.longitude)
                            setStatusOnline(transmissor.status_online);
                            setDataHoraUltimaRestaoa(transmissor.data_hora_ultima_resposta);

                        }
                    });



                } catch (_err) {
                    //alert("Erro de Conexão, tente novamente mais tarde");

                }
            }

            pesquisarBackGround();


        }, 15000);

        return () => clearInterval(interval);




    }, [idTransmissor]);

    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Gerenciar Transmissor"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Grid container

                                    item xs={12}
                                    direction="column"
                                    justifyContent="center"
                                    align="center"


                                >





                                    <Grid container

                                        item xs={12}
                                        direction="column"
                                        justifyContent="center"
                                        align="center"


                                    >

                                        {
                                            loading ?
                                                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                                                </Skeleton>
                                                :
                                                <div style={{ height: '100vh', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: "AIzaSyDYUFTfxXyi3TBHgkne5iKbsgooVXchxUU" }}

                                                        defaultCenter={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}

                                                        defaultZoom={defaultProps.zoom}
                                                        options={options}

                                                    >
                                                        <AnyReactComponent
                                                            lat={parseFloat(latitude)}
                                                            lng={parseFloat(longitude)}
                                                            text={codigo}
                                                            data={dataHoraUltimaResposta}
                                                            status={statusOnline}
                                                        />
                                                    </GoogleMapReact>
                                                </div>
                                        }

                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >
        </div >
    );
}


