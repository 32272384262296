import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@mui/material/AppBar';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';


import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function EditarProjeto() {

    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const history = useHistory();

    
    const { telaRetorno } = useParams();
    const { idInstalacao } = useParams();
    const {idCliente} = useParams();
    const { idProjeto } = useParams();
    

    const [nomeProjeto, setNomeProjeto] = useState('');

    const [area, setArea] = useState(0);
    const [clienteSelecionado, setClienteSelecionado] = useState({});
    const [descricaoProjeto, setDescricaoProjeto] = useState('');
    const [clientes, setClientes] = useState([]);
    const [propostas, setPropostas] = useState([]);
    const [instalacao, setInstalacao] = useState([]);

    const [arquivos, setArquivos] = useState(null);
    const [instalacoes, setInstalacoes] = useState([]);

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/projetos/listarporid/" + idProjeto, {
                headers: headers
            }).then(function (response) {

                setArea(response.data.projeto.area);
                setDescricaoProjeto(response.data.projeto.descricao_projeto);
                setClienteSelecionado(response.data.projeto.cliente);
                //setInstalacoes(response.data.projeto.cliente.instalacoes);
                setNomeProjeto(response.data.projeto.nome_projeto);
                setPropostas(response.data.projeto.propostas);
                setInstalacao(response.data.projeto.instalacao);

                setArquivos(response.data);


                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    function returnDescricaoCliente(cliente) {
        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("CPF: " + cliente.cpf) : ("CNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia);

        return texto;
    }

    useEffect(() => {
        listarInformacoes();


    }, []);



    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/projetos/baixarPdf", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }






    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    const handleClickGerenciarProposta = (cellValues) => {

        //"tas/editarproposta/:idProposta/:idProjeto/:telaRetorno/:idInstalacao/:idCliente""
        window.location.href = "/gestao/administracao/projetos/editarprojeto/" + idProjeto + 
        "/propostas/editarproposta/" + cellValues.row.id_proposta + "/1/" + idInstalacao + "/" + idCliente  ;

    };


    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 50,
            renderCell: (cellValues) => {
                return (

                    <IconButton color="error" onClick={() => handleClickGerenciarProposta(cellValues)}>
                        <SettingsIcon />
                    </IconButton>


                );
            },
        },
        {
            headerName: 'ID',
            field: 'id_proposta',
            id: 1,
            width: 30,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Status Atual',
            field: 'status_ordem',
            width: 150,
            id: 2,

            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const status_ordem = params.row.status_proposta;
                let backgroundColor = '';
                let color = '#ffffff'; // Cor do texto (branca) para todos os status

                switch (status_ordem) {
                    case 0:
                        backgroundColor = '#FF0000'; // Cor de fundo para ABERTA
                        break;
                    case 1:
                        backgroundColor = '#ff9900'; // Cor de fundo para DESIGNADA
                        break;
                    case 2:
                        backgroundColor = '#ffcc00'; // Cor de fundo para EM ANALISE
                        break;
                    case 3:
                        backgroundColor = '#3366cc'; // Cor de fundo para TRATAMENTO REMOTO
                        break;
                    case 4:
                        backgroundColor = '#ff0000'; // Cor de fundo para REQUISITOS
                        break;
                    case 9:
                        backgroundColor = '#ff0000'; // Cor de fundo para REQUISITOS
                        break;

                    default:
                        backgroundColor = ''; // Para outros status, não altera o fundo
                }

                let text = "";
                switch (status_ordem) {
                    case 0:
                        text = 'ABERTA';
                        break;
                    case 1:
                        text = 'EM ÁNALISE';
                        break;
                    case 2:
                        text = 'EM TRATAMENTO REMOTO';
                        break;
                    case 3:
                        text = 'TRATAMENTO EM CAMPO';
                        break;
                    case 4:
                        text = 'REQUISITOS';
                        break;
                    case 9:
                        text = 'CONCLUÍDO';
                        break;
                    default:
                        text = '';
                }

                return (
                    <Typography
                        variant="h8"


                        style={{
                            backgroundColor: backgroundColor,
                            color: color,
                        }}
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Descrição',
            field: 'descricao_proposta',
            id: 3,
            width: 400,
            headerClassName: 'cabecalho_azul',
        },
        {
            field: 'usuario_criador',
            id: 4,
            headerName: 'Usuário Criador',
            minWidth: 300,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            valueFormatter: (params) => {
                var usuario = params.value;
                var texto = usuario.nome_amigavel;

                return texto;
            },
        },

        {
            headerName: 'Data Criação',
            field: 'data_hora_criacao',
            width: 250,
            id: 5,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {
                const rawDate = params.row.data_hora_criacao;

                if (rawDate) {
                    const formattedDate = moment(rawDate).format('HH:mm:ss DD/MM/YYYY');
                    return formattedDate;
                }

                return '';
            },
        },




    ];


    function novaProposta() {
        window.location.href = "/gestao/administracao/projetos/editarprojeto/" + idProjeto + "/proposta/cadastrarproposta";

    }


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    

    function returnDescricaoRetorno(instalacao) {
        var texto = instalacao.identificador_integrador +  " | " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            " | " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}

            />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :

                <div>
                    <Grid container 
                    
                        alignItems="center"
                        justifyContent="center"
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="flex-start"
                            item xs={12}
                            style={{ width: '100%', margin: 10, fontSize: 14, }}
                        >

                            <a href={"/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao +"/" + idCliente} style={{ fontSize: 12, color: 'black', marginRight: '5px' }}>{"< " + returnDescricaoRetorno(instalacao)}</a>

                        </Grid>

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                            style={{margin: 0}}
                        >


                            <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center' }}>
                                <AppBar position="static" >

                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"


                                    >
                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                        <Tab label="Propostas" style={{ backgroundColor: 'green' }} />
                                        <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                    </Tabs>
                                </AppBar>

                            </Box>
                        </Grid>


                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >

                            <Grid
                                value={value} index={0}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                spacing={2}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    spacing={2}
                                    style={{ padding: 50 }}
                                >

                                    <Grid
                                        item xs={12}
                                        container
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="area-selector-label">Área</InputLabel>
                                                <Select
                                                    labelId="area-selector-label"
                                                    id="area-selector"
                                                    value={area}
                                                    label="Área"
                                                >
                                                    <MenuItem value={0}>Segurança</MenuItem>
                                                    <MenuItem value={1}>Refrigeração</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>




                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        align="flex-start"
                                        item xs={12}
                                        style={{ marginTop: 5 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                        >

                                            <Autocomplete
                                                id="select-ordem-servico"
                                                options={clientes}
                                                disableCloseOnSelect
                                                getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                value={clienteSelecionado}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((cliente) => {
                                                        const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Cliente " multiline minRows={3} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                )}

                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        align="flex-start"
                                        item xs={12}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                        >

                                            <Autocomplete
                                                id="select-instalacao"
                                                options={instalacoes}
                                                disableCloseOnSelect
                                                getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                value={instalacao}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((instalacao) => {
                                                        const cotacaoText = returnDescricaoInstalacao(instalacao).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                )}

                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid
                                        justifyContent="flex-start"
                                        align="flex-start"
                                        container item xs={4}>
                                        <TextField
                                            variant="standard"
                                            name="nomeProjeto"
                                            label="Nome Projeto"
                                            required
                                            id="nomeProjeto"
                                            value={nomeProjeto}
                                            fullWidth
                                        />
                                    </Grid>



                                    <Grid container
                                        justifyContent="flex-start"
                                        align="flex-start"
                                        item xs={12} >
                                        <TextField
                                            variant="standard"
                                            name="descricaoProjeto"
                                            label="Descrição Projeto"
                                            required
                                            id="descricaoProjeto"
                                            value={descricaoProjeto}
                                            fullWidth
                                            multiline
                                            minRows={2}
                                        />
                                    </Grid>




                                </Grid>
                            </Grid>

                            <Grid
                                value={value} index={1}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    style={{ padding: 10 }}
                                >
                                    <Button style={{ padding: 10 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={novaProposta}
                                    >
                                        Nova Proposta
                                    </Button>
                                </Grid>


                                





                            </Grid>


                        </SwipeableViews>







                    </Grid>


                </div>
            }

        </div >
    );
}


