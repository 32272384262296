import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { faHome, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import icone_area_tecnica from '../../../assets/imgs/icone_area_tecnica.png';
import icone_area_gestao from '../../../assets/imgs/icone_area_gestao.png';
import icone_recursos_humanos from '../../../assets/imgs/icone_recursos_humanos.png';

import icone_unidades from '../../../assets/imgs/icone_unidades.png';

import icone_administracao from '../../../assets/imgs/icone_administracao.png';

import icone_recursos_financeiros from '../../../assets/imgs/icone_recursos_financeiros.png';


import mini_icone_informacao from '../../../assets/imgs/mini_icone_informacao.png';


import icone_operacao from '../../../assets/imgs/icone_recursos_operacionais.png';

import Cookies from 'js-cookie';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListSubheader from '@material-ui/core/ListSubheader';
import api from '../../../services/api';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { faBell, faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import './styles.css'; // Importe o arquivo de estilo 


import globalConfig from '../../../configs/globalConfigs';


import {

    Menu,
    MenuItem,
} from '@material-ui/core';
const drawerWidth = 300;

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: 'white',
        backgroundColor: 'black',
        zIndex: 0,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: 'black',
        ...theme.mixins.toolbar,
    },
    icone: {
        color: 'white',
        backgroundColor: 'black',

    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    appBarShift: {


    },
    appBarDesktop: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShiftDesktop: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),


    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: '#1C1C1C',
        color: 'white',
        height: '100%',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transitionDuration: 0,
    },

    drawerPaperClose: {

        transitionDuration: 0,
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerPaperDesktop: {
        height: '100%',
        backgroundColor: 'black',
        color: "white",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperCloseDesktop: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
}));


export default function MenuAdmin(props) {


    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [tarefas, setTarefas] = useState([]);
    const [open, setOpen] = React.useState(true);
    const [width, setWidth] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [tarefaEmVista, setTarefaEmvista] = useState();

    const regra = Cookies.get('regra');



    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleOpenAlert = (tarefa) => {
        console.log("handle open alert chamado!");
        setTarefaEmvista(tarefa);
        setAlertOpen(true);
        vizualizarTarefa(tarefa);
    };

    function formatDataAndHourComplete(data) {
        var dataCtr = moment(data, "YYYY-MM-DD hh:mm:ss");
        return dataCtr.format("DD/MM/YYYY HH:mm:ss");
    }

    async function vizualizarTarefa(tarefa) {
        try {


            console.log("Vizualizar tarefa chamado");
            const id_usuario = Cookies.get('id_usuario');


            var url = "v1/protected/tarefasgestao/vizualizar/" + parseInt(tarefa.id_tarefa);
            //console.log("Url: " + url);
            await api.put(url).then(function (response) {

                if (response) {
                    setTarefas(tarefas.filter(tarefa => tarefa.id_tarefa !== tarefa.id_tarefa));

                }

            });


        } catch (_err) {
            console.log("Erro ao vizualizar acao, err: " + _err);

        }
    }






    const handleCloseAlert = () => {

        setAlertOpen(false);
        setTarefaEmvista(null);

    }

    function processarLink() {


        if (tarefaEmVista.especificidade === 0) {
            //para ordes de compra
            return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.ordem_compra.id_ordem_compra;
        }else if(tarefaEmVista.especificidade === 1){
            return "/gestao/administracao/ordensdeservicos/editar/" + tarefaEmVista.ordem_servico.id_ordem_servico;

        }



    }

    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (largura < 900)
            setOpen(false);
        else
            setOpen(true);

        setWidth(largura)

    }


    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    async function pesquisarBackGround() {


        try {


            const id_usuario_logado = Cookies.get('id_usuario');

            let url = "v1/protected/tarefasgestao/listarPorUsuario/" + id_usuario_logado;

            //console.log("Url: " + url);
            await api.get(url).then(function (response) {

                setTarefas(response.data);
                //console.log(JSON.stringify(response.data));
                setLoading(false);

            });


        } catch (_err) {
            console.log("erro: " + _err);

        }


    }


    useEffect(() => {

        checkDimenssoes();

        pesquisarBackGround();


    }, []);


    useEffect(() => {



        const interval = setInterval(() => {


            pesquisarBackGround();


        }, 10000);

        return () => clearInterval(interval);

    }, [tarefas]);

    const toggleDrawer = (option) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (option) {
            handleDrawerClose();
        } else {
            handleDrawerOpen();
        }

    };


    function confirmSair() {
        Cookies.set('token', undefined);
        window.location.href = "/login"


    }


    const Lista = () => {

        const [isAreaTecnicaOpen, setIsAreaTecnicaOpen] = useState(null);

        const toggleAreaTecnica = (event) => {
            setIsAreaTecnicaOpen(event.currentTarget);
        };

        const closeAreaTecnica = () => {
            setIsAreaTecnicaOpen(null);
        };


        const [isAreaGestaoOpen, setIsAreaGestaoOpen] = useState(null);

        const toggleAreaGestao = (event) => {
            setIsAreaGestaoOpen(event.currentTarget);
        };

        const closeAreaGestao = () => {
            setIsAreaGestaoOpen(null);
        };


        return (
            <div>
                <ListItem button component="a" href="/minhaconta">
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faHome} size="2x" style={{ color: 'SpringGreen' }} />
                    </ListItemIcon>
                    <ListItemText primary="Meus Dados" />
                </ListItem>

                <ListItem button onClick={toggleAreaTecnica} >
                    <ListItemIcon>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                            <img alt={'icone_area_tecnica'} style={{ textAlign: 'center' }} src={icone_area_tecnica}></img>
                        </div>
                    </ListItemIcon>
                    <ListItemText primary="Área Técnica" />
                </ListItem>


                <ListItem button onClick={toggleAreaGestao} >
                    <ListItemIcon>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                            <img alt={'icone_area_gestao'} style={{ textAlign: 'center' }} src={icone_area_gestao}></img>
                        </div>
                    </ListItemIcon>
                    <ListItemText primary="Área De Gestão" />
                </ListItem>

                <Menu
                    anchorEl={isAreaTecnicaOpen}
                    open={Boolean(isAreaTecnicaOpen)}
                    onClose={closeAreaTecnica}
                    PaperProps={{
                        style: {
                            background: 'white', // Cor de fundo cinza
                        },
                    }}

                >

                    <ListItem button component="a" href="/tecnica" className="menu-item">
                        <ListItemIcon  >
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                <img alt={'icone_area_tecnica'} style={{ textAlign: 'center' }} src={icone_area_tecnica}></img>
                            </div>
                        </ListItemIcon>
                        <ListItemText primary="Área Técnica" />
                    </ListItem>

                    <>

                        {(regra === 'SUPER_ADMIN' || regra === 'TECNICO') ? (
                            <ListItem button component="a" href="/clientes" className="menu-item" >
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faInfoCircle} size="2x" style={{ color: 'orange' }} />
                                </ListItemIcon>
                                <ListItemText primary="Instalações" />
                            </ListItem>
                        ) : null}


                    </>


                </Menu>


                <Menu
                    anchorEl={isAreaGestaoOpen}
                    open={Boolean(isAreaGestaoOpen)}
                    onClose={closeAreaGestao}
                    PaperProps={{
                        style: {
                            background: 'white', // Cor de fundo cinza
                        },
                    }}

                >

                    <MenuItem >
                        <ListItem button component="a" href="/gestao" className="menu-item" >
                            <ListItemIcon>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                    <img alt={'icone_area_gestao'} style={{ textAlign: 'center' }} src={icone_area_gestao}></img>
                                </div>
                            </ListItemIcon>
                            <ListItemText primary="Área De Gestão" />
                        </ListItem>
                    </MenuItem>

                    <>
                        {( regra.includes("SUPER_ADMIN") ||
                                regra.includes("ADMIN_FINAN") || 
                                regra.includes("ADMIN_TEC") 
                        ) ? (
                            <ListItem button component="a" href="/gestao/administracao" className="menu-item" >
                                <ListItemIcon>
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                        <img alt={'icone_unidades'} style={{ textAlign: 'center' }} src={icone_unidades}></img>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary="Recursos Administrativos" />
                            </ListItem>
                        ) : null}

                    </>


                    <>
                        {( regra.includes("SUPER_ADMIN") ||
                                regra.includes("ADMIN_FINAN") || 
                                regra.includes("ADMIN_TEC") 
                        ) ? (
                            <ListItem button component="a" href="/gestao/operacional" className="menu-item" >
                                <ListItemIcon>
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                        <img alt={'icone_operacao'} style={{ textAlign: 'center' }} src={icone_operacao}></img>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary="Recursos Operacionais" />
                            </ListItem>
                        ) : null}

                    </>


                    <>
                        {(regra === 'SUPER_ADMIN' || regra === 'TECNICO') ? (
                            <ListItem button component="a" href="/gestao/rh" className="menu-item" >
                                <ListItemIcon>
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                        <img alt={'icone_recursos_humanos'} style={{ textAlign: 'center' }} src={icone_recursos_humanos}></img>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary="Recursos Humanos" />
                            </ListItem>
                        ) : null}

                    </>


                    <>
                        {( regra.includes("SUPER_ADMIN") ||
                                regra.includes("ADMIN_FINAN") 
                        ) ? (
                            <ListItem button component="a" href="/gestao/financas" className="menu-item" >
                                <ListItemIcon>
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                        <img alt={'icone_recursos_financeiros'} style={{ textAlign: 'center' }} src={icone_recursos_financeiros}></img>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary="Recursos Financeiros" />
                            </ListItem>
                        ) : null}

                    </>


                </Menu>


                <>
                    {(regra === 'SUPER_ADMIN') ? (
                        <ListItem button component="a" href="/administracao" className="menu-item" >
                            <ListItemIcon>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                    <img alt={'icone_administracao'} style={{ textAlign: 'center' }} src={icone_administracao}></img>
                                </div>
                            </ListItemIcon>
                            <ListItemText primary="Administração do Sistema" />
                        </ListItem>
                    ) : null}

                </>


                <Divider />

                <ListSubheader style={{ color: 'white' }} inset>Opções</ListSubheader>
                <ListItem button onClick={confirmSair}>
                    <ListItemIcon>
                        <ExitToAppIcon style={{ color: 'red' }} />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                </ListItem>
            </div>
        );

    }


    const MenuMobile = () => {
        return (
            <>

                <CssBaseline />
                <AppBar position="absolute" style={{ top: 60 }} className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar id="target" className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"

                        >
                            <Grid
                                container
                                direction="row"
                                item xs={9} sm={9} md={9} lg={9} xl={9}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>


                            {(regra === "SUPER_ADMIN" || regra === "TECNICO" || regra === "ROLE_CLIENTE") ?
                                <Grid
                                    container
                                    direction="column"
                                    item xs={3} sm={3} md={3} lg={3} xl={3}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'


                                >

                                    <NavDropdown id="nav-dropdown"
                                        drop='right'
                                        alignright="true"

                                        title={
                                            <div className="notification-icon-container">
                                                <span className="notification-count">{tarefas.length}</span>
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    size="2x"
                                                    className={tarefas.length > 0 ? "shake-bell" : ""}
                                                    style={{ color: "SpringGreen" }}
                                                />
                                            </div>
                                        }
                                    >

                                        {
                                            loading && tarefas ?
                                                <div>
                                                </div>
                                                :
                                                tarefas.map((tarefa) => {
                                                    return (
                                                        <NavDropdown.Item
                                                            onClick={(ev) => handleOpenAlert(tarefa)}
                                                            style={{ backgroundColor: 'white', color: 'black', }}
                                                            key={tarefa.id_tarefa} eventKey={tarefa.id_tarefa} >

                                                            <Grid container spacing={2}
                                                                style={{ padding: 20 }}
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                item xs={12} >
                                                                <Grid

                                                                    item xs={6}

                                                                >
                                                                    <Typography
                                                                        variant="h5"
                                                                    >
                                                                        {tarefa.nome}
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid

                                                                    item xs={6}

                                                                >
                                                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                                                        <img alt={'mini_icone_informacao'} style={{ textAlign: 'center' }} src={mini_icone_informacao}></img>
                                                                    </div>
                                                                </Grid>

                                                            </Grid>




                                                        </NavDropdown.Item>
                                                    )
                                                })
                                        }




                                    </NavDropdown>


                                </Grid>
                                : null
                            }

                        </Grid>

                    </Toolbar>
                </AppBar>
                <SwipeableDrawer style={{ position: 'absolute', top: 275 }}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    variant="temporary"
                    disableBackdropTransition
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    anchor={'left'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton className={classes.icone} onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    <Lista />
                </SwipeableDrawer>
            </>
        )
    }

    const MenuDesktop = () => {
        return (
            <>
                <CssBaseline />
                <AppBar position="absolute" style={{ top: globalConfig.appBarTop }} className={clsx(classes.appBarDesktop, open && classes.appBarShiftDesktop)}>
                    <Toolbar id="target" className={classes.toolbar} >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"


                        >
                            <Grid
                                container
                                direction="row"
                                item xs={6} sm={6} md={6} lg={6} xl={6}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>

                            {(regra === "SUPER_ADMIN" || regra === "TECNICO" || regra === "ROLE_CLIENTE") ?
                                <Grid
                                    container
                                    direction="column"
                                    item xs={6} sm={6} md={6} lg={6} xl={6}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'


                                >

                                    <NavDropdown id="nav-dropdown"
                                        drop='right'
                                        alignright="true"

                                        title={
                                            <div className="notification-icon-container">
                                                <span className="notification-count">{tarefas.length}</span>
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    size="2x"
                                                    className={tarefas.length > 0 ? "shake-bell" : ""}
                                                    style={{ color: "SpringGreen" }}
                                                />
                                            </div>
                                        }
                                    >

                                        {
                                            loading && tarefas ?
                                                <div>
                                                </div>
                                                :
                                                tarefas.map((tarefa) => {
                                                    return (
                                                        <NavDropdown.Item
                                                            onClick={(ev) => handleOpenAlert(tarefa)}
                                                            style={{ backgroundColor: 'white', color: 'black', }}
                                                            key={tarefa.id_tarefa} eventKey={tarefa.id_tarefa} >

                                                            <Grid container spacing={2}
                                                                style={{ padding: 20 }}
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                item xs={12} >
                                                                <Grid

                                                                    item xs={2}
                                                                >
                                                                    <img
                                                                        style={{ width: '40px', height: '40px' }}
                                                                        alt={'mini_icone_informacao'}
                                                                        src={mini_icone_informacao}></img>
                                                                </Grid>
                                                                <Grid

                                                                    item xs={4}

                                                                >
                                                                    <Typography
                                                                        variant="h5"
                                                                    >
                                                                        {tarefa.nome}
                                                                    </Typography>
                                                                </Grid>



                                                            </Grid>



                                                        </NavDropdown.Item>
                                                    )
                                                })
                                        }




                                    </NavDropdown>


                                </Grid>
                                : null
                            }

                        </Grid>

                    </Toolbar>
                </AppBar>

                <Drawer

                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaperDesktop, !open && classes.drawerPaperCloseDesktop),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton className={classes.icone} onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <Lista />

                </Drawer>
            </>
        )
    }

    return (


        <div>
            {
                width < 900 ?
                    <MenuMobile /> : <MenuDesktop />
            }
            {tarefaEmVista &&
                <Dialog
                    open={alertOpen}
                    onClose={handleCloseAlert}
                    aria-labelledby="confirm-dialog"
                >
                    <DialogTitle id="confirm-dialog">{ }</DialogTitle>
                    <DialogContent >
                        <div >

                            <Grid container spacing={2}
                                style={{ padding: 20 }}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                item xs={12} >
                                <Grid

                                    item xs={6}

                                >
                                    <Typography
                                        variant="h5"
                                    >
                                        {tarefaEmVista.nome}
                                    </Typography>
                                </Grid>

                                <Grid

                                    item xs={6}

                                >
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                                        <img alt={'mini_icone_informacao'} style={{ textAlign: 'center' }} src={mini_icone_informacao}></img>
                                    </div>
                                </Grid>



                                <Grid

                                    item xs={12}

                                >
                                    <Typography
                                        variant="h6"
                                        component="a"
                                        href={processarLink()}
                                        target="_blank" rel="noopener noreferrer"
                                        style={{ color: 'black', textDecoration: 'underline', '&:hover': { color: 'blue' } }}

                                    >
                                        {tarefaEmVista.descricao}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCloseAlert}

                                    >
                                        OK
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>

                </Dialog >
            }

        </div>


    )

};