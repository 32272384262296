import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import { Button } from "@material-ui/core";

import VisibilityIcon from '@mui/icons-material/Visibility';

import IconButton from '@material-ui/core/IconButton';


function novo() {
    window.location.href = "/gestao/operacional/frota/rotas/cadastrarrota"

}

export default function Rotas() {

    const [loading, setLoading] = useState(true);
    const [height, setHeight] = useState(0);

    const [objetos, setObjetos] = useState(null);





    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/rotas/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {




        listar();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    const handleClickGerenciar = (event, cellValues) => {


        window.location.href = "/gestao/operacional/frota/rotas/vizualizarrota/" + cellValues.row.id_rota;


    };


    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {
                return (


                    <IconButton
                        color="primary"
                        onClick={(event) => handleClickGerenciar(event, cellValues)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                );
            },
        },
        {
            headerName: 'ID',
            field: 'id_rota',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 2,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Origem',
            field: 'origem',
            id: 3,
            minWidth: '350',
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                var componentes = params.row.componentes_rota;
                var texto = "";

                // Encontre o componente com tipo igual a 0
                const componenteTipo0 = Array.from(componentes).find(componente => componente.tipo === 0);

                // Se o componente com tipo 0 for encontrado, atribua o valor do atributo endereco à variável texto
                if (componenteTipo0) {
                    texto = componenteTipo0.endereco;
                }

                return (
                    <Typography variant="h8">
                        {texto}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Destino',
            field: 'destino',
            id: 4,
            minWidth: '350',
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                var componentes = params.row.componentes_rota;
                var texto = "";

                // Encontre o componente com tipo igual a 0
                const componenteTipo0 = Array.from(componentes).find(componente => componente.tipo === 1);

                // Se o componente com tipo 0 for encontrado, atribua o valor do atributo endereco à variável texto
                if (componenteTipo0) {
                    texto = componenteTipo0.endereco;
                }

                return (
                    <Typography variant="h8">
                        {texto}
                    </Typography>
                );
            },
        },
    ];



    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Rotas={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novo}
                    >
                        Nova Rota
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white' }}>
                            {objetos !== null && (
                                <>
                                    <DataGrid
                                        pagination
                                        checkboxSelection
                                        rows={objetos}
                                        getRowId={(row) => row.id_rota}
                                        columns={columnsDataGrid}
                                        disableColumnResize={false}
                                        onCellClick={handleCellClick}
                                        onRowClick={handleRowClick}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />



                                </>
                            )}
                        </div>
                    </div>
                }


            </div>



        </div>
    );
}



