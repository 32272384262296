// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alogo {
  color: green;
  font-size: 30px;
  margin-left: 50px;
  text-decoration: none;
}

.resp-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contato/styles.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;AAAF","sourcesContent":["\r\n.alogo {\r\n  color: green;\r\n  font-size: 30px;\r\n  margin-left: 50px;\r\n  text-decoration: none;\r\n}\r\n\r\n.resp-container {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  border: 0;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
