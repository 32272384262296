import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';


import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


import { Button } from "@material-ui/core";
import { styled } from '@material-ui/styles';




export default function CondicoesPagamento() {

    const [loading, setLoading] = useState(true);

    const [objetos, setObjetos] = useState(null);

    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/financeiro/condicoespagamento/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {



        listar();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };




    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,

        },
        {
            headerName: 'ID',
            field: 'id_metodo_pagamento',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Nome',
            field: 'nome',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 3,
            width: 300,
            headerClassName: 'cabecalho_azul',
        },

    ];



    function novo() {
        window.location.href = "/gestao/financas/condicoespagamento/cadastrar"

    }



    return (
        <div>
            <NavegadorGestao
                Financas={true}
                TabelasAuxiliaresFinancas={true}
                CondicoesPagamento={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novo}
                    >
                        Nova Condição de Pagamento
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white' }}>
                            {objetos !== null && (
                                <>
                                    <DataGrid
                                        pagination
                                        checkboxSelection
                                        rows={objetos}
                                        getRowId={(row) => row.id_condicao_pagamento}
                                        columns={columnsDataGrid}
                                        disableColumnResize={false}
                                        onCellClick={handleCellClick}
                                        onRowClick={handleRowClick}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />



                                </>
                            )}
                        </div>
                    </div>
                }


            </div>


        </div>
    );
}



