import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import MenuRecursosOperacionais from '../components/menu';

import { Viewer, Worker } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function CadastrarProcedimento() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const classes = useStyles();
    const history = useHistory();

    const [nome, setNome] = useState('');
    const [descricaoGeral, setDescricaoGeral] = useState('');
    const [instruncoes, setInstruncoes] = useState('');
    const [falha, setFalha] = useState("");
    const [sucesso, setSucesso] = useState("");

    const [etapa, setEtapa] = useState(0);
    const [exigirComprovacao, setExigirComprovacao ] = useState(0);


    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');

    const [area, setArea] = useState(0);

    const handleChange = (event) => {
        setArea(event.target.value);
    };

    const [tipo, setTipo] = useState(0);

    const handleChangeTipo = (event) => {
        setTipo(event.target.value);
    };

    const [obrigatorio, setObrigatorio] = useState(0);

    const handleChangeObrigatorio = (event) => {
        setObrigatorio(event.target.value);
    };

    const handleChangeExigirComprovacao = (event) => {
        setExigirComprovacao(event.target.value);
    };

    const handleChangeEtapa = (event) => {
        setEtapa(event.target.value);
    };

    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);


    const onDrop1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada1(acceptedFiles[0]);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada2(acceptedFiles[0]);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada3(acceptedFiles[0]);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada4(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });





    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            // Adicionar os dados do procedimento
            formData.append('descricao_geral', descricaoGeral);
            formData.append('nome', nome);
            formData.append('area', area);
            formData.append('tipo', tipo);
            formData.append('obrigatorio', obrigatorio);

            formData.append('etapa', etapa);
            formData.append('exigir_comprovacao', exigirComprovacao);



            formData.append('instruncoes', instruncoes);
            formData.append('usuario_criador.id_usuario', Cookies.get('id_usuario'));

            formData.append('falha', falha);

            formData.append('sucesso', sucesso);

            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);


            formData.append('descricao_video1', descricaoVideo1);
            formData.append('descricao_video2', descricaoVideo2);
            formData.append('descricao_video3', descricaoVideo3);

            formData.append('descricao_arquivo1', descricaoArquivo1);
            formData.append('descricao_arquivo2', descricaoArquivo2);
            formData.append('descricao_arquivo3', descricaoArquivo3);



            // Adicionar as imagens ao FormData
            formData.append('imagens', imagemSelecionada1);
            formData.append('imagens', imagemSelecionada2);
            formData.append('imagens', imagemSelecionada3);
            formData.append('imagens', imagemSelecionada4);

            formData.append('videos', videoSelecionado1);
            formData.append('videos', videoSelecionado2);
            formData.append('videos', videoSelecionado3);

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('arquivos', arquivoSelecionado2);
            formData.append('arquivos', arquivoSelecionado3);

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/procedimentos/cadastrar', formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Procedimento Cadastrado");
                history.push({
                    pathname: "/gestao/operacional/procedimentos/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Procedimentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }}>

                <React.Fragment >
                    <Grid container spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >


                            <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                <AppBar position="static" >

                                    <Tabs
                                        value={value}
                                        onChange={handleChangeTab}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"


                                    >
                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                        <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                        <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                        <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                    </Tabs>
                                </AppBar>

                            </Box>
                        </Grid>
                        <Grid
                            style={{ paddingTop: 5 }}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={12}
                            >
                                <Button style={{ margin: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Salvar  </Button>
                            </Grid>

                            <SwipeableViews
                                index={value}
                                onChangeIndex={handleChangeIndex}
                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                            >

                                <Grid
                                    value={value} index={0}
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    <Grid item xs={3}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="area-selector-label">Área</InputLabel>
                                                <Select
                                                    labelId="area-selector-label"
                                                    id="area-selector"
                                                    value={area}
                                                    onChange={handleChange}
                                                    label="Área"
                                                >
                                                    <MenuItem value={0}>Ordem de Serviço</MenuItem>
                                                    <MenuItem value={1}>Ordem de Compra</MenuItem>
                                                    <MenuItem value={2}>Recursos Humanos</MenuItem>
                                                    <MenuItem value={3}>Recursos Financeiras</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Tipo-selector-label">Tipo</InputLabel>
                                                <Select
                                                    labelId="Tipo-selector-label"
                                                    id="Tipo-selector"
                                                    value={tipo}
                                                    onChange={handleChangeTipo}
                                                    label="Tipo"
                                                >
                                                    <MenuItem value={0}>Ánalise</MenuItem>
                                                    <MenuItem value={1}>Implementação</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="etapa-selector-label">Etapa</InputLabel>
                                            <Select
                                                labelId="etapa-selector-label"
                                                id="etapa-selector"
                                                value={etapa}
                                                onChange={handleChangeEtapa}
                                                label="Etapa"
                                                
                                            >
                                                <MenuItem value={0}>Ánalise Prévia</MenuItem>
                                                <MenuItem value={1}>Tratamento Remoto</MenuItem>
                                                <MenuItem value={2}>Tratamento em Campo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="obrigatorio-selector-label">Obrigatório</InputLabel>
                                            <Select
                                                labelId="obrigatorio-selector-label"
                                                id="obrigatorio-selector"
                                                value={obrigatorio}
                                                onChange={handleChangeObrigatorio}
                                                label="Obrigatório"
                                            >
                                                <MenuItem value={0}>NÃO</MenuItem>
                                                <MenuItem value={1}>SIM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="exigir-selector-label">Exigir Comprovação</InputLabel>
                                            <Select
                                                labelId="exigir-selector-label"
                                                id="exigir-selector"
                                                value={exigirComprovacao}
                                                onChange={handleChangeExigirComprovacao}
                                                label="Exigir Comprovação"
                                            >
                                                <MenuItem value={0}>NÃO</MenuItem>
                                                <MenuItem value={1}>SIM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={4}>
                                        <TextField
                                            variant="standard"
                                            name="nome"
                                            label="Nome"
                                            id="nome"
                                            value={nome}
                                            onChange={(e) => setNome(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ margin: 5 }}>
                                        <TextField
                                            variant="standard"
                                            name="descricaoGeral"
                                            label="Descrição Geral"
                                            required
                                            id="descricaoGeral"
                                            value={descricaoGeral}
                                            onChange={(e) => setDescricaoGeral(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ margin: 5 }}>
                                        <TextField
                                            variant="standard"
                                            name="instruncoes"
                                            label="Instrunções"
                                            id="instruncoes"
                                            value={instruncoes}
                                            onChange={(e) => setInstruncoes(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>


                                    <Grid item xs={12} style={{ margin: 5 }}>
                                        <TextField
                                            variant="standard"
                                            name="falha"
                                            label="Resultado de Falha"
                                            id="falha"
                                            value={falha}
                                            onChange={(e) => setFalha(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ margin: 5 }}>
                                        <TextField
                                            variant="standard"
                                            name="sucesso"
                                            label="Resultado de Sucesso"
                                            id="sucesso"
                                            value={sucesso}
                                            onChange={(e) => setSucesso(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>


                                </Grid>

                                <Grid
                                    value={value} index={1}
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                >




                                    <Grid item xs={12} style={{ padding: 40 }}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada1 ? (
                                                        <img
                                                            src={URL.createObjectURL(imagemSelecionada1)}
                                                            alt="Imagem Selecionada 1"
                                                            style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootProps1()} style={dropzoneStyles}>
                                                            <input {...getInputProps1()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem1"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem1"
                                                            value={descricaoImagem1}
                                                            onChange={(e) => setDescricaoImagem1(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada2 ? (
                                                        <img
                                                            src={URL.createObjectURL(imagemSelecionada2)}
                                                            alt="Imagem Selecionada 2"
                                                            style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootProps2()} style={dropzoneStyles}>
                                                            <input {...getInputProps2()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem2"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem2"
                                                            value={descricaoImagem2}
                                                            onChange={(e) => setDescricaoImagem2(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada3 ? (
                                                        <img
                                                            src={URL.createObjectURL(imagemSelecionada3)}
                                                            alt="Imagem Selecionada 3"
                                                            style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootProps3()} style={dropzoneStyles}>
                                                            <input {...getInputProps3()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem3"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem3"
                                                            value={descricaoImagem3}
                                                            onChange={(e) => setDescricaoImagem3(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>


                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada4 ? (
                                                        <img
                                                            src={URL.createObjectURL(imagemSelecionada4)}
                                                            alt="Imagem Selecionada 4"
                                                            style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootProps4()} style={dropzoneStyles}>
                                                            <input {...getInputProps4()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem4"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem4"
                                                            value={descricaoImagem4}
                                                            onChange={(e) => setDescricaoImagem4(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid
                                    value={value} index={2}
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                >



                                    <Grid item xs={12} style={{ padding: 40 }}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >

                                        <Grid item xs={8}>
                                            <Card>
                                                <CardContent>
                                                    {videoSelecionado1 ? (
                                                        <video
                                                            controls
                                                            src={URL.createObjectURL(videoSelecionado1)}
                                                            alt="Vídeo Selecionado"
                                                            style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootVideoProps1()} style={dropzoneStyles}>
                                                            <input {...getInputVideoProps1()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um vídeo aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoVideo1"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoVideo1"
                                                            value={descricaoVideo1}
                                                            onChange={(e) => setDescricaoVideo1(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Card>
                                                <CardContent>
                                                    {videoSelecionado2 ? (
                                                        <video
                                                            controls
                                                            src={URL.createObjectURL(videoSelecionado2)}
                                                            alt="Vídeo Selecionado"
                                                            style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootVideoProps2()} style={dropzoneStyles}>
                                                            <input {...getInputVideoProps2()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um vídeo aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoVideo2"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoVideo2"
                                                            value={descricaoVideo2}
                                                            onChange={(e) => setDescricaoVideo2(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Card>
                                                <CardContent>
                                                    {videoSelecionado3 ? (
                                                        <video
                                                            controls
                                                            src={URL.createObjectURL(videoSelecionado3)}
                                                            alt="Vídeo Selecionado"
                                                            style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                        />
                                                    ) : (
                                                        <div {...getRootVideoProps3()} style={dropzoneStyles}>
                                                            <input {...getInputVideoProps3()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um vídeo aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoVideo3"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoVideo3"
                                                            value={descricaoVideo3}
                                                            onChange={(e) => setDescricaoVideo3(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    value={value} index={3}
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                >




                                    <Grid item xs={12} style={{ padding: 5 }}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={8}>
                                            <Card>
                                                <CardContent>
                                                    {arquivoSelecionado1 ? (

                                                        <div
                                                            href={URL.createObjectURL(arquivoSelecionado1)}
                                                            onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                            onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado1))}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>


                                                    ) : (
                                                        <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                            <input {...getInputArquivoProps1()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um arquivo aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoArquivo1"
                                                            label="Descrição"
                                                            id="descricaoArquivo1"
                                                            value={descricaoArquivo1}
                                                            onChange={(e) => setDescricaoArquivo1(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Card>
                                                <CardContent>
                                                    {arquivoSelecionado2 ? (
                                                        <div
                                                            href={URL.createObjectURL(arquivoSelecionado2)}
                                                            onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                            onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado2))}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>
                                                    ) : (
                                                        <div {...getRootArquivoProps2()} style={dropzoneStyles}>
                                                            <input {...getInputArquivoProps2()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um arquivo aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoArquivo2"
                                                            label="Descrição"
                                                            id="descricaoArquivo2"
                                                            value={descricaoArquivo2}
                                                            onChange={(e) => setDescricaoArquivo2(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Card>
                                                <CardContent>
                                                    {arquivoSelecionado3 ? (
                                                        <div
                                                            href={URL.createObjectURL(arquivoSelecionado3)}
                                                            onClick={() => window.open(URL.createObjectURL(arquivoSelecionado3), '_blank')}
                                                            onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado3))}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>
                                                    ) : (
                                                        <div {...getRootArquivoProps3()} style={dropzoneStyles}>
                                                            <input {...getInputArquivoProps3()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um arquivo aqui ou clique para adicionar
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoArquivo3"
                                                            label="Descrição"
                                                            id="descricaoArquivo3"
                                                            value={descricaoArquivo3}
                                                            onChange={(e) => setDescricaoArquivo3(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </SwipeableViews>







                        </Grid>





                    </Grid>


                </React.Fragment>


            </Container>

        </div >
    );
}


