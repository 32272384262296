import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";



import Typography from '@material-ui/core/Typography';




import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";




export default function CadastroUnidade() {

  const history = useHistory();





  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [descricao, setDescricao] = useState(''); // Add this line
  const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);



  const [infoAdicional, setInfoAdicional] = useState('');

  const [porte, setPorte] = useState('');
  const [atividade, setAtividade] = useState('');
  const [ie, setIe] = useState('');

  const [email, setEmail] = useState('');



  const [parametrosEndereco, setParametrosEndereco] = useState(
    {
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cep: "",
      latitude: "",
      longitude: "",
    }
  );





  async function salvar() {
    setEstadoBotaoSalvar(true);
    console.log("Salvar chamado");
    try {

      const cadastro_unidade = {
        razao_social: razaoSocial,
        nome_fantasia: nomeFantasia,
        cnpj: cnpj,
        descricao: descricao,
        logradouro: parametrosEndereco.logradouro,
        numero: parametrosEndereco.numero,
        bairro: parametrosEndereco.bairro,
        cidade: parametrosEndereco.cidade,
        estado: parametrosEndereco.estado,
        cep: parametrosEndereco.cep,
        complemento: parametrosEndereco.complemento,
        latitude: parametrosEndereco.latitude,
        longitude: parametrosEndereco.longitude,


        info_adicional: infoAdicional,
        porte: porte,
        atividade: atividade,
        ie: ie,
        email: email,
      };

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }

      const response = await api.post('/v1/protected/unidades/cadastrar', cadastro_unidade,
        { headers: headers });

      const cadastro_salvo = response.data;
      if (cadastro_salvo) {
        alert("Unidade cadastrada com sucesso!")

        history.push({
          pathname: '/gestao/administracao/unidades/listar',
        })
      } else {
        alert("Erro de Conexão, tente novamente mais tarde");
      }
    } catch (_err) {
      console.log("erro ao cadastrar: " + _err);
      alert("Erro de Conexão, tente novamente!");
      setEstadoBotaoSalvar(false);
    }




  }




  async function handleCnpj(cnpj) {

    // Remove caracteres não numéricos do CNPJ
    let cnpjSemFormato = cnpj.replace(/\D/g, '');

    cnpjSemFormato = cnpjSemFormato.slice(0, 14);


    setCnpj(cnpjSemFormato);

    if (cnpjSemFormato.length === 14) {
      console.log("cnpj" + cnpjSemFormato);
      const response = await api.get('https://brasilapi.com.br/api/cnpj/v1/' + cnpjSemFormato);
      setRazaoSocial(response.data.razao_social);
      setNomeFantasia(response.data.razao_social);
      setPorte(response.data.porte);
      setAtividade(response.data.cnae_fiscal_descricao);

      //endereco
      setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': response.data.logradouro }))
      setParametrosEndereco(prevState => ({ ...prevState, 'bairro': response.data.bairro }))

      setParametrosEndereco(prevState => ({ ...prevState, 'estado': response.data.uf }))
      setParametrosEndereco(prevState => ({ ...prevState, 'numero': response.data.numero }))
      setParametrosEndereco(prevState => ({ ...prevState, 'cep': response.data.cep }))
      setParametrosEndereco(prevState => ({ ...prevState, 'cidade': response.data.municipio }))
      setParametrosEndereco(prevState => ({ ...prevState, 'complemento': response.data.complemento }))

    }

    // Atualiza o estado com o CNPJ formatado
  }


  return (
    <div>
      <NavegadorGestao
        BaseDados={true}
        Unidades={true}
        corFundo={'white'} travado={true} corTexto={'black'}
      />
      <Container style={{ paddingTop: 5 }} >

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          item xs={10}
        >
          <Button style={{ margin: 5 }}
            variant="contained"
            color="primary"
            onClick={salvar}
            disabled={estadoBotaoSalvar}
          > Salvar  </Button>
        </Grid>

        <React.Fragment >
          <Grid container spacing={2}   >



            <Grid
              container
              direction="row"
              item xs={12}
              spacing={2}>

              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  name="cnpj"
                  label="CNPJ"
                  required
                  id="cnpj"
                  value={cnpj}
                  onChange={e => handleCnpj(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4} >
                <TextField

                  variant="standard"
                  name="razaoSocial"
                  label="Razão Social"
                  required
                  id="razaoSocial"
                  autoComplete="razaoSocial"
                  value={razaoSocial}
                  onChange={e => setRazaoSocial(e.target.value)}
                  inputProps={{ maxLength: 50, minLength: 1 }}
                  fullWidth

                />
              </Grid>

              <Grid item xs={4} >
                <TextField
                  variant="standard"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  required
                  id="nomeFantasia"
                  value={nomeFantasia}
                  onChange={e => setNomeFantasia(e.target.value)}
                  inputProps={{ maxLength: 50, minLength: 1 }}
                  fullWidth

                />
              </Grid>
              <Grid
                container
                direction="row"
                item xs={12}
                spacing={2}>

                <Grid item xs={4} >

                  <TextField

                    variant="standard"
                    name="porte"
                    label="Porte"
                    id="porte"
                    value={porte}
                    onChange={e => setPorte(e.target.value)}
                    inputProps={{ maxLength: 100, minLength: 0 }}
                    fullWidth

                  />
                </Grid>

                <Grid item xs={4} >
                  <TextField
                    variant="standard"
                    name="atividade"
                    label="Atividade"
                    id="atividade"
                    autoComplete="atividade"
                    value={atividade}
                    onChange={e => setAtividade(e.target.value)}
                    inputProps={{ maxLength: 100, minLength: 0 }}
                    fullWidth

                  />
                </Grid>

                <Grid item xs={4}  >
                  <TextField

                    variant="standard"
                    name="ie"
                    label="Inscrição Estadual"
                    id="ei"
                    value={ie}
                    onChange={e => setIe(e.target.value)}
                    inputProps={{ maxLength: 20, minLength: 0 }}
                    fullWidth

                  />
                </Grid>


              </Grid>
            </Grid>


            <Grid item xs={12}  >
              <TextField

                variant="standard"
                name="descricao"
                label="Descrição"
                id="descricao"
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
                inputProps={{ maxLength: 20, minLength: 0 }}
                fullWidth

              />
            </Grid>

            <Grid container spacing={2}>
              {/* Email field */}
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  name="email"
                  label="Email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </Grid>


              <Grid
                container
                direction="row"
                item xs={12}
              >
                <Grid
                  container
                  direction="row" spacing={2}
                  item xs={12}>

                  <Typography style={{ margin: 15 }} >
                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Endereço: </span>
                  </Typography>


                  <Grid item xs={12}>
                    <TextField

                      id="logradouro"
                      variant="standard"
                      name="logradouro"
                      fullWidth
                      label="Logradouro"
                      value={parametrosEndereco.logradouro}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField

                      id="numero"
                      variant="standard"
                      name="numero"
                      fullWidth
                      label="Número"
                      value={parametrosEndereco.numero}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField

                      id="bairro"
                      variant="standard"
                      name="bairro"
                      fullWidth
                      label="Bairro"
                      value={parametrosEndereco.bairro}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bairro': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>



                  <Grid item xs={5}>
                    <TextField

                      id="complemento"
                      variant="standard"
                      name="complemento"
                      fullWidth
                      label="Complemento"
                      value={parametrosEndereco.complemento}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField

                      id="cidade"
                      variant="standard"
                      name="cidade"
                      fullWidth
                      label="Cidade"
                      value={parametrosEndereco.cidade}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cidade': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField

                      id="estado"
                      variant="standard"
                      name="estado"
                      fullWidth
                      label="Estado"
                      value={parametrosEndereco.estado}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'estado': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField

                      id="cep"
                      variant="standard"
                      name="cep"
                      fullWidth
                      label="CEP"
                      value={parametrosEndereco.cep}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cep': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>


                  <Grid item xs={4}>
                    <TextField

                      id="latitude"
                      variant="standard"
                      name="latitude"
                      fullWidth
                      label="Latitude"
                      value={parametrosEndereco.latitude}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'latitude': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField

                      id="longitude"
                      variant="standard"
                      name="longitude"
                      fullWidth
                      label="Longitude"
                      value={parametrosEndereco.longitude}
                      onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'longitude': e.target.value }))}
                      inputProps={{ maxLength: 40, minLength: 6 }}
                    />
                  </Grid>


                </Grid>
              </Grid>



              {/* Additional Info field */}
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  label="Informação Adicional"
                  value={infoAdicional}
                  onChange={(e) => setInfoAdicional(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>



          </Grid>


        </React.Fragment>



      </Container>

    </div >
  );
}


