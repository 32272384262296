import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import { Paper, makeStyles } from '@material-ui/core';


export default function ModuloPGM(props) {
    const equipamento = props.equipamento;
            
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const [centralIP, setCentralIP] = useState('');
    const [centralPorta, setCentralPorta] = useState('');
    const [centralUsername, setCentralUsername] = useState('');
    const [centralPassword, setCentralPassword] = useState('');
    const [conexaoPrioridade, setConexaoPrioridade] = useState('0'); // '0' para Ethernet/Wifi, '1' para Wifi/Ethernet


    const [showMenuConfigPgm, setShowMenuConfigPgm] = useState(false);
    const [subMenuPgm, setSubMenuPgm] = useState(1);


    const [cornetaIP, setCornetaIp] = useState('');



    const [wifiSSID, setWifiSSID] = useState('');
    const [wifiPasswd, setWifiPasswd] = useState('');
    const [wifiIp, setWifiIp] = useState('');
    const [wifiSubMascara, setWifiSuBmascara] = useState('');
    const [wifiGateway, setWifiGateway] = useState('');


    const [ethIp, setEthiIp] = useState('');
    const [ethSubMascara, setEthSuBmascara] = useState('');
    const [ethGateway, setEthGateway] = useState('');


    
    const handleChangeIndex = (index) => {
        setValue(index);
        if (index === 1) {
            setShowMenuConfigPgm(true);
        } else {
            setShowMenuConfigPgm(false);

        }
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            setShowMenuConfigPgm(true);
        } else {
            setShowMenuConfigPgm(false);

        }
    }


    

    useEffect(() => {

        
    if (equipamento !== null) {
        if (equipamento !== undefined) {
            if (equipamento.tipo_equipamento_area_tecnica === 0) {
                if (equipamento.modulo_pgm !== null) {
                    if (equipamento.modulo_pgm !== undefined) {
                        setCentralIP(equipamento.modulo_pgm.ip_central);
                        setCentralPorta(equipamento.modulo_pgm.porta_central);
                        setCentralUsername(equipamento.modulo_pgm.usuario_central);
                        setCentralPassword(equipamento.modulo_pgm.senha_central);

                        setCornetaIp(equipamento.modulo_pgm.ip_corneta);

                        //wifi
                        setWifiSSID(equipamento.modulo_pgm.ssid);
                        setWifiPasswd(equipamento.modulo_pgm.passwd);
                        setWifiIp(equipamento.modulo_pgm.ip_wifi);
                        setWifiGateway(equipamento.modulo_pgm.gateway_wifi);
                        setWifiSuBmascara(equipamento.modulo_pgm.submascara_wifi);
                        //eth
                        setEthiIp(equipamento.modulo_pgm.ip_eth);
                        setEthGateway(equipamento.modulo_pgm.gateway_eth);
                        setEthSuBmascara(equipamento.modulo_pgm.submascara_eth);

                    }
                }
            }
        }
    }
    }, [equipamento]);

    const salvarConfiguracoesCentral = async () => {
        if (centralIP && centralPorta && centralUsername && centralPassword) {
            try {

                const cadastro = {
                    dado1: centralIP,
                    dado2: centralPorta,
                    dado3: centralUsername,
                    dado4: centralPassword,


                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/92";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Salvo e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
            }
        } else {
            alert("Revise as informações.");
        }
    };


    const salvarConfiguracoesCorneta = async () => {
        if (cornetaIP) {
            try {

                const cadastro = {
                    dado1: cornetaIP,


                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/117";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Salvo e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
            }
        } else {
            alert("Revise as informações.");
        }
    };



    const salvarConfiguracoesWifi = async () => {
        if (wifiSSID && wifiPasswd) {
            try {

                const cadastro = {
                    dado1: wifiSSID,
                    dado2: wifiPasswd
                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/115";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    if (wifiIp && wifiIp.length > 10 && wifiGateway.length > 10 && wifiSubMascara.length > 10) {
                        const cadastrar = {
                            dado1: wifiIp,
                            dado2: wifiGateway,
                            dado3: wifiSubMascara,
                        };



                        const url2 = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/118";
                        const response2 = api.post(url2, cadastrar, { headers });

                        if (response2.data) {
                            setSnackbarMessage('Salvo com Dados de Endereçamento e Comando Enviado a PGM. Aguarde pela Resposta!');
                            setSnackbarSeverity('success');
                            setSnackbarOpen(true);
                        }
                    } else {
                        setSnackbarMessage('Salvo sem Dados de Endereçamento e Comando Enviado a PGM. Aguarde pela Resposta!');
                        setSnackbarSeverity('success');
                        setSnackbarOpen(true);
                    }

                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
                console.log(error);
            }
        } else {
            alert("Revise as informações.");
        }
    };




    const salvarConfiguracoesEth = async () => {
        if (cornetaIP) {
            try {

                const cadastro = {
                    dado1: ethIp,
                    dado2: ethGateway,
                    dado3: ethSubMascara,


                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/116";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Salvo e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
            }
        } else {
            alert("Revise as informações.");
        }
    };


    const reiniciarDispositivo = async () => {

    };



   


    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={2}
                >
                    <List style={{ width: '100%' }}>
                        <ListItem style={{}}>
                            <Button
                                onClick={(e) => setSubMenuPgm(1)}
                                variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }}>
                                Configurações do Cliente
                            </Button>
                        </ListItem>
                        <ListItem style={{}}>
                            <Button
                                onClick={(e) => setSubMenuPgm(4)}
                                variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }} >
                                Configurações da Central
                            </Button>
                        </ListItem>
                        <ListItem style={{}}>
                            <Button
                                onClick={(e) => setSubMenuPgm(5)}
                                variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }} >
                                Configurações da Corneta
                            </Button>
                        </ListItem>
                        <ListItem style={{}}>
                            <Button
                                onClick={(e) => setSubMenuPgm(6)}
                                variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }} >
                                Opções
                            </Button>
                        </ListItem>
                    </List>
                </Grid>



                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={10}
                >


                    {subMenuPgm === 1 && (

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={6}
                            spacing={2}
                            style={{ with: 200, height: 200 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={3}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: 'green' }}
                                    onClick={(e) => setSubMenuPgm(2)}
                                >
                                    Wifi
                                </Button>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={3}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: 'blue' }}
                                    onClick={(e) => setSubMenuPgm(3)}
                                >
                                    Ethernet
                                </Button>
                            </Grid>
                        </Grid>
                    )}


                    {subMenuPgm === 2 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={10}
                        >
                            <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                TITANIWM MÓDULO PGM WIFI/ETHERNET
                            </Typography>
                            <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                Configure Wifi
                            </Typography>
                            <TextField
                                fullWidth
                                label="SSID"
                                variant="outlined"
                                margin="normal"
                                value={wifiSSID}
                                onChange={(e) => setWifiSSID(e.target.value)}
                                placeholder="Digite o SSID"
                            />


                            <TextField
                                fullWidth
                                label="Senha"
                                type="password"
                                variant="outlined"
                                margin="normal"
                                value={wifiPasswd}
                                onChange={(e) => setWifiPasswd(e.target.value)}
                                placeholder="Digite a senha do SSID"
                            />

                            <TextField
                                fullWidth
                                label="Ip"
                                variant="outlined"
                                margin="normal"
                                value={wifiIp}
                                onChange={(e) => setWifiIp(e.target.value)}
                                placeholder="Digite o IP "
                            />

                            <TextField
                                fullWidth
                                label="Sub Mascara de Rede"
                                variant="outlined"
                                margin="normal"
                                value={wifiSubMascara}
                                onChange={(e) => setWifiSuBmascara(e.target.value)}
                                placeholder="Digite a Mascara de Rede "
                            />

                            <TextField
                                fullWidth
                                label="Gateway da Rede"
                                variant="outlined"
                                margin="normal"
                                value={wifiGateway}
                                onChange={(e) => setWifiGateway(e.target.value)}
                                placeholder="Digite o Gateway "
                            />


                            <Box mt={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: '#00E1AA' }}
                                    onClick={(e) => salvarConfiguracoesWifi()}
                                >
                                    Salvar e Enviar a PGM
                                </Button>
                            </Box>
                        </Grid>
                    )}



                    {subMenuPgm === 3 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={10}
                        >
                            <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                TITANIWM MÓDULO PGM WIFI/ETHERNET
                            </Typography>
                            <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                Configure Ethernet
                            </Typography>


                            <TextField
                                fullWidth
                                label="Ip"
                                variant="outlined"
                                margin="normal"
                                value={ethIp}
                                onChange={(e) => setEthIp(e.target.value)}
                                placeholder="Digite o IP "
                            />

                            <TextField
                                fullWidth
                                label="Sub Mascara de Rede"
                                variant="outlined"
                                margin="normal"
                                value={ethSubMascara}
                                onChange={(e) => setEthSubMascara(e.target.value)}
                                placeholder="Digite a Mascara de Rede "
                            />

                            <TextField
                                fullWidth
                                label="Gateway da Rede"
                                variant="outlined"
                                margin="normal"
                                value={ethGateway}
                                onChange={(e) => setEthGateway(e.target.value)}
                                placeholder="Digite o Gateway "
                            />


                            <Box mt={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: '#00E1AA' }}
                                    onClick={(e) => salvarConfiguracoesEth()}
                                >
                                    Salvar e Enviar a PGM
                                </Button>
                            </Box>
                        </Grid>
                    )}


                    {subMenuPgm === 4 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={10}
                        >
                            <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                TITANIWM MÓDULO PGM WIFI/ETHERNET
                            </Typography>
                            <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                Configure Acesso a Central
                            </Typography>
                            <TextField
                                fullWidth
                                label="Endereço IP da Central"
                                variant="outlined"
                                margin="normal"
                                value={centralIP}
                                onChange={(e) => setCentralIP(e.target.value)}
                                placeholder="Digite o endereço da central, ex: 10.0.0.1"
                            />
                            <TextField
                                fullWidth
                                label="Porta da Central"
                                variant="outlined"
                                margin="normal"
                                value={centralPorta}
                                onChange={(e) => setCentralPorta(e.target.value)}
                                placeholder="Digite a porta da central, ex: 80"
                            />
                            <TextField
                                fullWidth
                                label="Usuário"
                                variant="outlined"
                                margin="normal"
                                value={centralUsername}
                                onChange={(e) => setCentralUsername(e.target.value)}
                                placeholder="Digite o usuário de acesso"
                            />
                            <TextField
                                fullWidth
                                label="Senha"
                                type="password"
                                variant="outlined"
                                margin="normal"
                                value={centralPassword}
                                onChange={(e) => setCentralPassword(e.target.value)}
                                placeholder="Digite a senha de acesso"
                            />
                            <Box mt={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: '#00E1AA' }}
                                    onClick={(e) => salvarConfiguracoesCentral()}
                                >
                                    Salvar e Enviar a PGM
                                </Button>
                            </Box>
                        </Grid>
                    )}


                    {subMenuPgm === 5 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={10}
                        >
                            <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                TITANIWM MÓDULO PGM WIFI/ETHERNET
                            </Typography>
                            <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                Configure Acesso a Corneta
                            </Typography>
                            <TextField
                                fullWidth
                                label="Endereço IP da Corneta"
                                variant="outlined"
                                margin="normal"
                                value={cornetaIP}
                                onChange={(e) => setCornetaIp(e.target.value)}
                                placeholder="Digite o endereço da corneta, ex: 10.0.0.1"
                            />


                            <Box mt={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: '#00E1AA' }}
                                    onClick={(e) => salvarConfiguracoesCorneta()}
                                >
                                    Salvar e Enviar a PGM
                                </Button>
                            </Box>
                        </Grid>
                    )}

                    {subMenuPgm === 6 && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            align="center"
                            item xs={10}
                        >
                            <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                TITANIWM MÓDULO PGM WIFI/ETHERNET
                            </Typography>
                            <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                Opções Gerais
                            </Typography>



                            <Box mt={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    style={{ backgroundColor: 'red', color: 'white' }}
                                    onClick={(e) => reiniciarDispositivo()}
                                >
                                    Reiniciar
                                </Button>
                            </Box>
                        </Grid>
                    )}

                </Grid>

            </Grid>
        </div>
    )
}


