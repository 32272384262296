import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";




export default function CadastrarEquipe() {

    const [loading, setLoading] = useState(true);


    const history = useHistory();

    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');

    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradoresSelecionados, setColaboradoresSelecionados] = useState([]);


    async function listarColaboradores() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/colaboradores/listartodos", {
                headers: headers
            }).then(function (response) {
                setColaboradores(response.data)

                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Colaboradores: " + _err)

        }

    }



    useEffect(() => {
        listarColaboradores();


    }, []);







    async function salvar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {


                descricao: descricao,
                nome: nome,
                colaboradores: colaboradoresSelecionados,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/equipes/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Equipe Cadastrada");
                history.push({
                    pathname: "/gestao/operacional/equipes/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }


    const handleSetColaborador = (novoColaborador) => {
        if (novoColaborador) {
            // Verifica se o colaborador já está selecionado
            if (!colaboradoresSelecionados.includes(novoColaborador)) {
                // Adiciona o novo colaborador à lista de colaboradores selecionados
                handleSetColaboradoresSelecionados([...colaboradoresSelecionados, novoColaborador]);
            }
        }
    };

    const handleSetColaboradoresSelecionados = (event, values) => {
        if (Array.isArray(values)) {
            const novosColaboradoresSelecionados = values.filter(
                (colaborador) => !colaboradoresSelecionados.includes(colaborador)
            );
            setColaboradoresSelecionados(novosColaboradoresSelecionados);
        } else if (values !== null) {
            // Tratar caso values seja um objeto (único valor selecionado)
            if (!colaboradoresSelecionados.includes(values)) {
                setColaboradoresSelecionados([...colaboradoresSelecionados, values]);
            }
        } else {
            setColaboradoresSelecionados([]);
        }
    };

    const handleRemoveColaborador = (colaboradorParaRemover) => {
        const novosColaboradoresSelecionados = colaboradoresSelecionados.filter(
            (colaborador) => colaborador !== colaboradorParaRemover
        );
        setColaboradoresSelecionados(novosColaboradoresSelecionados);
    };

    function returnDescricaoEndereco(objeto) {
        var texto = objeto.logradouro + ", " + objeto.numero + ", " + " bairro " +
            objeto.bairro + ", " + objeto.cidade + " UF: " + objeto.estado + " CEP: " + objeto.cep;
        return texto;
    }

    function returnDescricaoColaborador(colaborador) {

        var endereco = returnDescricaoEndereco(colaborador);

        var texto = "  Colaborador_ID: " + colaborador.id_colaborador +
            " CPF: " + colaborador.cpf +
            " Nome: " + colaborador.nome + " " + colaborador.sobrenome + "\n " + endereco;
        return texto;
    }


    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Equipes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <Container style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ marginTop: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >

                                    <div>
                                        <strong>Colaboradores Selecionados:</strong>
                                        <ul>
                                            {colaboradoresSelecionados.map((colaborador, index) => (
                                                <li key={index}>
                                                    {returnDescricaoColaborador(colaborador)}{' '}
                                                    <button onClick={() => handleRemoveColaborador(colaborador)}>Remover</button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 5 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                    >
                                        <Autocomplete
                                            id="select-colaboradores"
                                            options={colaboradores}
                                            disableCloseOnSelect
                                            getOptionLabel={(colaborador) => returnDescricaoColaborador(colaborador).replace(/<br \/>/g, '\n')}
                                            onChange={handleSetColaboradoresSelecionados}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((colaborador) => {
                                                    const colaboradorText = returnDescricaoColaborador(colaborador).toLowerCase();
                                                    return inputKeywords.every((keyword) => colaboradorText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Colaboradores " multiline rows={3} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="nome"
                                        label="Nome"
                                        id="nome"
                                        value={nome}
                                        onChange={(e) => setNome(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>











                            </Grid>





                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


