import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import {
    Box,
     Tooltip
} from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';
import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";

import api from '../../../../services/api';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NavegadorGestao from "../../../../components/NavBarGestao";



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


function novoUsuario() {
    window.location.href = "/administracao/cadastrarusuario"

}

export default function Usuarios() {


    const history = useHistory();


    const [loading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [click, setClick] = useState(1);
    const [parametros, setParametros] = useState(
        {
            identificacao: "",
            page: 0,
            size: 1000,
        }
    );

    const [idUsuarioAAlterar, setIdUsuarioAlterar] = useState(0);





    const [alertOpen, setAlertOpen] = useState(false);
    const [mensagem, setMensagem] = useState('');


    const handleOpenAlert = (id) => {
        setIdUsuarioAlterar(id);
        setMensagem('');

        setAlertOpen(true);
    };

    async function handleCloseAlert() {

        try {



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = Cookies.get('id_usuario');

            const response = await api.put('/v1/protected/users/mudarStatus/' + id_usuario_logado + "/" + idUsuarioAAlterar + "/" + mensagem,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Usuário Atualizado com sucesso!")

                history.push({
                    pathname: '/usuarios',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


        setIdUsuarioAlterar(0);
        setMensagem('');
        setAlertOpen(false);

        history.push({
            pathname: '/usuarios',
        })

    }




    async function listarUsuarios() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            var parametros1 = parametros
            await api.get("v1/protected/users/listar", {
                params: {
                    identificacao: parametros1.identificacao,
                    page: parametros1.page,
                    size: parametros1.size,


                },
                headers: headers
            }).then(function (response) {
                setUsuarios(response.data.content)

                console.log(" Usuarios: " + response);
                setLoading(false);


                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar usuarios: " + _err)

        }

    }


    useEffect(() => {



        async function listarUsuarios() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setUsuarios(response.data)

                    setLoading(false);




                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar usuarios: " + _err)

            }

        }
        listarUsuarios();


    }, []);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }






    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {

                const status = cellValues.row.status;


                return (


                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                        <Tooltip title="Gerenciar">
                            <IconButton color="error" href={"/administracao/gerenciarusuario/" + cellValues.row.id_usuario}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>

                );

            },
        },
        {
            headerName: 'ID',
            field: 'id_usuario',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 80,
            id: 2,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const status = params.row.status;



                let text = "";
                switch (status) {
                    case 0:
                        text = 'DESATIVADO';
                        break;
                    case 1:
                        text = 'ATIVADO';
                        break;

                    default:
                        text = '';
                }

                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            field: "tipo_associacao",
            headerName: "Associado a",
            id: 3,
            headerClassName: 'cabecalho_azul',
            minWidth: 200,
            renderCell: (params) => {
                var value = params.row.tipo_associacao;
                return (
                    <Typography
                        variant="h8"

                    >
                        {value === 0 ? "Colaborador" : value === 1 ? "Cliente" : ""}
                    </Typography>
                );
            },
        },


        {
            field: "nome",
            headerName: "Nome",
            id: 4,
            headerClassName: 'cabecalho_azul',
            minWidth: 200,
            renderCell: (params) => {
                const text = params.row.nome_amigavel;
                return (
                    <Typography
                        variant="h8"

                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            field: "indentificador",
            headerName: "Identificador",
            id: 5,
            headerClassName: 'cabecalho_azul',
            minWidth: 200,
            renderCell: (params) => {
                const tipo_associacao = params.row.tipo_associacao;
                let text = "";

                if (tipo_associacao === 0) {
                    text = params.row.colaborador.cpf;
                } else if (tipo_associacao === 1) {
                    const tipo_cliente = params.row.cliente.tipo_cliente;
                    if (tipo_cliente === 0) {
                        text = params.row.cliente.cpf;
                    } else if (tipo_cliente === 1) {
                        text = params.row.cliente.cnpj;
                    }
                }


                return (
                    <Typography
                        variant="h8"

                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            field: "login",
            headerName: "Login",
            id: 6,
            headerClassName: 'cabecalho_azul',
            minWidth: 200,

        },

        {
            field: "email",
            headerName: "E-mail",
            id: 7,
            headerClassName: 'cabecalho_azul',
            minWidth: 200,

        },
        {
            field: "cargo",
            headerName: "Nível",
            id: 8,
            headerClassName: 'cabecalho_azul',
            minWidth: 150,

        },



    ];




    return (
        <div>

            <NavegadorGestao
                Administracao={true}
                Usuarios={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoUsuario}
                    >
                        Novo Usuário
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ height: '100%', width: '100%' }}>
                            <>
                                <DataGrid
                                    localeText={ptBR.localeText}
                                    pagination
                                    checkboxSelection
                                    rows={usuarios}
                                    pageSize={20}
                                    rowHeight={40}
                                    getRowId={(row) => row.id_usuario}
                                    columns={columnsDataGrid}
                                    disableColumnResize={false}
                                    onCellClick={handleCellClick}
                                    onRowClick={handleRowClick}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                />

                            </>
                        </div>
                    </div>
                }


            </div>


            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle
                    style={{ backgroundColor: 'black', color: 'white' }}
                    id="confirm-dialog"> Mudar Status de Usuário</DialogTitle>
                <DialogContent
                    style={{ backgroundColor: 'black' }}
                >
                    <div>
                        <AppBar
                            style={{ backgroundColor: 'black', color: 'white' }}

                            sx={{ position: 'relative' }}>

                            <Grid container spacing={1}

                                style={{ backgroundColor: 'black', color: 'white' }}

                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    id="mensagem"
                                    variant="standard"
                                    name="mensagem"
                                    fullWidth
                                    label="Mensagem"
                                    required
                                    autoComplete="mensagem"
                                    value={mensagem}
                                    onChange={e => setMensagem(e.target.value)}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}

                                />

                                <Grid

                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    onClick={handleCloseAlert}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        ok
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >

        </div >
    );
}



