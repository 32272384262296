// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Lista.css */
.menu-item {
    background-color: transparent; /* Cor de fundo padrão */
    cursor: pointer; /* O cursor muda para uma mãozinha ao passar o mouse */
  }
  
  .menu-item:hover {
    background-color: black !important;/* Cor de fundo cinza escuro ao passar o mouse */
  }`, "",{"version":3,"sources":["webpack://./src/pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/Rotas/styles.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,6BAA6B,EAAE,wBAAwB;IACvD,eAAe,EAAE,sDAAsD;EACzE;;EAEA;IACE,kCAAkC,CAAC,gDAAgD;EACrF","sourcesContent":["/* Lista.css */\r\n.menu-item {\r\n    background-color: transparent; /* Cor de fundo padrão */\r\n    cursor: pointer; /* O cursor muda para uma mãozinha ao passar o mouse */\r\n  }\r\n  \r\n  .menu-item:hover {\r\n    background-color: black !important;/* Cor de fundo cinza escuro ao passar o mouse */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
