import React from 'react';



import NavBarAdmin from "../../../../../components/NavBarAdmin";
import Rodape from '../../../../../components/Rodape';

import MenuRecursosHumanos from '../components/menu';


export default function RecursosHumanos() {
   

    


    return (
        <div>

            <NavBarAdmin />
            <MenuRecursosHumanos 
    link1={["Home", "/"]} 
link2={["Minha Conta", "/minhaconta"]} 
                  link3={["Área de Gestão", "/gestao"]} 
           titulo={"Visão Geral"}
/>
         
         
        </div>
    );
}



