import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';

import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';



import api from '../../../../services/api';
import NavegadorGestao from "../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import miniatura_pdf from '../../../../assets/imgs/miniatura_pdf.png';


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


export default function GerenciarUsuario() {

    const [regioesSelecionadas, setRegioesSelecionadas] = useState([]);

    const [value, setValue] = React.useState(0);
    const [usuario, setUsuario] = useState();


    const regioes = [
        { nome: 'Brasil', sigla: 'BR' },
        { nome: 'Acre', sigla: 'AC' },
        { nome: 'Alagoas', sigla: 'AL' },
        { nome: 'Amapá', sigla: 'AP' },
        { nome: 'Amazonas', sigla: 'AM' },
        { nome: 'Bahia', sigla: 'BA' },
        { nome: 'Ceará', sigla: 'CE' },
        { nome: 'Distrito Federal', sigla: 'DF' },
        { nome: 'Espírito Santo', sigla: 'ES' },
        { nome: 'Goiás', sigla: 'GO' },
        { nome: 'Maranhão', sigla: 'MA' },
        { nome: 'Mato Grosso', sigla: 'MT' },
        { nome: 'Mato Grosso do Sul', sigla: 'MS' },
        { nome: 'Minas Gerais', sigla: 'MG' },
        { nome: 'Pará', sigla: 'PA' },
        { nome: 'Paraíba', sigla: 'PB' },
        { nome: 'Paraná', sigla: 'PR' },
        { nome: 'Pernambuco', sigla: 'PE' },
        { nome: 'Piauí', sigla: 'PI' },
        { nome: 'Rio de Janeiro', sigla: 'RJ' },
        { nome: 'Rio Grande do Norte', sigla: 'RN' },
        { nome: 'Rio Grande do Sul', sigla: 'RS' },
        { nome: 'Rondônia', sigla: 'RO' },
        { nome: 'Roraima', sigla: 'RR' },
        { nome: 'Santa Catarina', sigla: 'SC' },
        { nome: 'São Paulo', sigla: 'SP' },
        { nome: 'Sergipe', sigla: 'SE' },
        { nome: 'Tocantins', sigla: 'TO' }
    ];


    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);

    const [contatos, setContatos] = useState([]);

    const [botaoClicadoPermissoes, setBotaoClicadoPermissoes] = useState(false);

    const [botaoClicadoConfiguracoes, setBotaoClicadoConfiguracoes] = useState(false);

    const atualizarPermissoes = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicadoPermissoes) {
            // Executa a lógica do clique aqui
            console.log('Botão permissoes clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicadoPermissoes(true);
            funcaoAtualizarPermissoes();
        }
    };

    const atualizarConfiguracoes = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicadoPermissoes) {
            // Executa a lógica do clique aqui
            console.log('Botão configuracoes clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicadoConfiguracoes(true);
            funcaoAtualizarConfiguracoes();
        }
    };



    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };

    const { idUsuario } = useParams();


    const history = useHistory();



    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");
            let roles = ""; // Inicializa a variável roles como uma string vazia

            // Verifica o estado dos checkboxes e atribui os valores correspondentes à variável roles
            if (superAdminChecked) {
                roles = "SUPER_ADMIN";
            } else {
                if (adminFinancasChecked) {
                    roles += "ADMIN_FINAN,";
                }
                if (adminTecnicoChecked) {
                    roles += "ADMIN_TEC,";
                }
                if (suporteTecnicoChecked) {
                    roles += "TEC,";
                }
                if (marketingChecked) {
                    roles += "MARKETING,";
                }

                // Remove a vírgula extra no final da string, se houver
                roles = roles.replace(/,\s*$/, "");
            }

            // Verifica o valor de roles
            console.log("Roles: " + roles);

            setBotaoClicado(false);


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }





    async function funcaoAtualizarPermissoes() {


        try {

            console.log("cadastrar chamado");
            let roles = ""; // Inicializa a variável roles como uma string vazia

            // Verifica o estado dos checkboxes e atribui os valores correspondentes à variável roles
            if (superAdminChecked) {
                roles = "SUPER_ADMIN";
            } else {
                if (adminFinancasChecked) {
                    roles += "ADMIN_FINAN,";
                }
                if (adminTecnicoChecked) {
                    roles += "ADMIN_TEC,";
                }
                if (suporteTecnicoChecked) {
                    roles += "TEC,";
                }
                if (marketingChecked) {
                    roles += "MARKETING,";
                }

                // Remove a vírgula extra no final da string, se houver
                roles = roles.replace(/,\s*$/, "");
            }

            // Verifica o valor de roles
            console.log("Roles: " + roles);


            usuario.roles = roles;

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/user/atualizarpermissoes/' + Cookies.get('id_usuario'), usuario,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Permissões Atualizadas!")
                // Recarrega a página atual
                window.location.reload();
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


            setBotaoClicadoPermissoes(false);


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicadoPermissoes(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }





    async function funcaoAtualizarConfiguracoes() {


        try {

            console.log("atualizar configuracoes chamado");
            let roles = ""; // Inicializa a variável roles como uma string vazia

            // Verifica o estado dos checkboxes e atribui os valores correspondentes à variável roles


            const regioesCobertura = regioesSelecionadas.map(regiao => regiao.sigla).join(',');

            usuario.regiao_cobertura = regioesCobertura;

            usuario.analista_previo = preAnaliseChecked ? 1 : 0;
            usuario.analista_remoto = analiseRemotoChecked ? 1 : 0;
            usuario.analista_em_campo = analiseEmCampoChecked ? 1 : 0;


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/user/atualizarconfiguracoes/' + Cookies.get('id_usuario'), usuario,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Configurações Atualizadas!")

                // Recarrega a página atual
                window.location.reload();
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


            setBotaoClicadoConfiguracoes(false);


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicadoConfiguracoes(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const [loading, setLoading] = useState(true);

    const [tipoColaborador, setTipoColaborador] = useState(true);
    const [tipoCliente, setTipoCliente] = useState(false);


    const [regioesCobertura, setRegioesCobertura] = useState();


    const [clienteSelecionado, setClienteSelecionado] = useState();
    const [colaboradorSelecionado, setColaboradorSelecionado] = useState();

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/user/retornardadosusuario/" + idUsuario, {
                headers: headers
            }).then(function (response) {

                setUsuario(response.data);
                if (response.data.tipo_associacao === 0) {
                    setTipoColaborador(true);
                    setColaboradorSelecionado(response.data.colaborador)
                } else if (response.data.tipo_associacao === 1) {
                    setTipoCliente(true);
                    setClienteSelecionado(response.data.cliente)
                }


                setPreAnaliseChecked(response.data.analista_previo === 1 ? true : false)
                setAnaliseRemotoChecked(response.data.analista_remoto === 1 ? true : false)
                setAnaliseEmCampoChecked(response.data.analista_em_campo === 1 ? true : false)
                setContatos(response.data.contatos);

                var regioes_cobertura = response.data.regiao_cobertura;



                try {
                    // Dividir a string em um array usando ',' como delimitador
                    const siglas = regioes_cobertura.split(',');

                    console.log("Siglas: " + siglas);

                    // Mapear as siglas de estado para criar um array de objetos de região correspondentes
                    const regioesSelecionadas = siglas.map(sigla => {
                        // Encontrar o objeto de região correspondente à sigla de estado
                        const regiao = regioes.find(regiao => regiao.sigla === sigla);
                        // Retornar o objeto de região encontrado
                        return regiao;
                    });
                } catch (_err) {
                    console.log("Sem regras" + _err)

                }

                setRegioesSelecionadas(regioesSelecionadas);


                let adminFinancasChecked = false;
                let adminTecnicoChecked = false;
                let suporteTecnicoChecked = false;
                let marketingChecked = false;
                let superAdminChecked = false;

                try {
                    const roles = response.data.roles.split(',');
                    roles.forEach(role => {
                        switch (role.trim()) {
                            case 'SUPER_ADMIN':
                                superAdminChecked = true;
                                break;
                            case 'ADMIN_FINAN':
                                adminFinancasChecked = true;
                                break;
                            case 'ADMIN_TEC':
                                adminTecnicoChecked = true;
                                break;
                            case 'TEC':
                                suporteTecnicoChecked = true;
                                break;
                            case 'MARKETING':
                                marketingChecked = true;
                                break;
                            default:
                                break;
                        }
                    });
                } catch (_err) {
                    console.log("Sem regras" + _err)

                }

                setSuperAdminChecked(superAdminChecked);
                setAdminFinancasChecked(adminFinancasChecked);
                setAdminTecnicoChecked(adminTecnicoChecked);
                setSuporteTecnicoChecked(suporteTecnicoChecked);
                setMarketingChecked(marketingChecked);


                setLoading(false);

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);


    function returnDescricaoEndereco(objeto) {
        var texto = objeto.logradouro + ", " + objeto.numero + ", " + " bairro " +
            objeto.bairro + ", " + objeto.cidade + " UF: " + objeto.estado + " CEP: " + objeto.cep;
        return texto;
    }

    function returnDescricaoColaborador(colaborador) {

        var endereco = returnDescricaoEndereco(colaborador);

        var texto = "Colaborador_ID: " + colaborador.id_colaborador +
            "\nCPF: " + colaborador.cpf +
            "\nNome: " + colaborador.nome + " " + colaborador.sobrenome + "\n " + endereco;
        return texto;
    }




    function returnDescricaoCliente(cliente) {
        var endereco = returnDescricaoEndereco(cliente);


        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("\nCPF: " + cliente.cpf) : ("\nCNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia) + " " +
            "\nEndrereço: " + endereco;

        return texto;
    }


    const [preAnaliseChecked, setPreAnaliseChecked] = useState(false);
    const [analiseRemotoChecked, setAnaliseRemotoChecked] = useState(false);
    const [analiseEmCampoChecked, setAnaliseEmCampoChecked] = useState(false);


    const handlePreAnalise = (event) => {
        setPreAnaliseChecked(event.target.checked);
    };

    const handleAnaliseRemota = (event) => {
        setAnaliseRemotoChecked(event.target.checked);
    };

    const handleAnaliseEmCampo = (event) => {
        setAnaliseEmCampoChecked(event.target.checked);
    };


    const [superAdminChecked, setSuperAdminChecked] = useState(false);
    const [adminFinancasChecked, setAdminFinancasChecked] = useState(false);
    const [adminTecnicoChecked, setAdminTecnicoChecked] = useState(false);
    const [suporteTecnicoChecked, setSuporteTecnicoChecked] = useState(false);
    const [marketingChecked, setMarketingChecked] = useState(false);

    const handleSuperAdminChange = (event) => {
        setSuperAdminChecked(event.target.checked);
    };





    const handleAdicionarRegiao = (event, values) => {
        // Adiciona cada estado selecionado que ainda não está na lista de regiões selecionadas
        values.forEach(value => {
            if (!regioesSelecionadas.some(regiao => regiao.nome === value.nome)) {
                setRegioesSelecionadas(prevRegioes => [...prevRegioes, value]);
            }
        });
    };



    const handleRemoverRegiao = (event, regiaoParaRemover) => {
        console.log("Remover chamado")
        const novasRegioesSelecionadas = regioesSelecionadas.filter(
            (regiao) => regiao.nome.toLocaleLowerCase() !== regiaoParaRemover.nome.toLocaleLowerCase()
        );
        setRegioesSelecionadas(novasRegioesSelecionadas);
    };





    return (
        <div>
            <NavegadorGestao
                Administracao={true}
                Usuarios={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Configurações" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Permissões" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Contatos" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>




                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            spacing={2}>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Typography  >
                                                    Tipo Associação:
                                                </Typography>
                                                <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoColaborador}
                                                />} label="Associado a Colaborador" />
                                                <FormControlLabel control={<Checkbox checked={tipoCliente}
                                                />} label="Associado a Cliente" />

                                            </Grid>


                                            <Collapse style={{ width: '100%' }} in={tipoColaborador} timeout="auto" unmountOnExit>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                    style={{ marginTop: 20 }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={5} >
                                                        <Autocomplete
                                                            id="select-colaborador"
                                                            options={[]}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(colaborador) => returnDescricaoColaborador(colaborador)}
                                                            value={colaboradorSelecionado}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Colaborador "

                                                                    multiline
                                                                    rows={6} />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Collapse>

                                            <Collapse style={{ width: '100%' }} in={tipoCliente} timeout="auto" unmountOnExit>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                    style={{ marginTop: 20 }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={5} >
                                                        <Autocomplete
                                                            id="select-cliente"
                                                            options={[]}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(cliente) => returnDescricaoCliente(cliente)}
                                                            value={clienteSelecionado}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Cliente "

                                                                    multiline
                                                                    rows={6} />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Collapse>

                                        </Grid>


                                        <Grid item xs={12} container spacing={3} >

                                            <Typography style={{ margin: 15 }} >
                                                <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                            </Typography>

                                            <TextField
                                                id="login"
                                                variant="standard"
                                                name="login"
                                                fullWidth
                                                label="Login"
                                                required
                                                autoComplete="login"
                                                value={usuario.login}
                                                inputProps={{ maxLength: 50, minLength: 15 }}
                                                style={{ margin: 15 }}
                                            />

                                            <TextField
                                                id="email"
                                                variant="standard"
                                                name="email"
                                                fullWidth
                                                label="E-Mail"
                                                required
                                                autoComplete="email"
                                                value={usuario.email}
                                                inputProps={{ maxLength: 50, minLength: 15 }}
                                                style={{ margin: 15 }}
                                            />
                                        </Grid>


                                    </Grid>


                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            style={{ paddingTop: 30 }}
                                            container
                                            direction="row"
                                            item xs={8}
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                spacing={2}>

                                                <Grid item xs={12}  >
                                                    <Autocomplete
                                                        multiple
                                                        id="regioes"
                                                        options={regioes}
                                                        getOptionLabel={(regiao) => regiao.sigla}
                                                        onChange={handleAdicionarRegiao}
                                                        value={regioesSelecionadas}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Selecione Regiões de Atuação"
                                                            />
                                                        )}

                                                        renderTags={(value, getTagProps) =>
                                                            value.map((regiao, index) => (
                                                                <Chip
                                                                    label={regiao.nome}
                                                                    {...getTagProps({ index })}
                                                                    onDelete={(event) => handleRemoverRegiao(event, regiao)}
                                                                />
                                                            ))
                                                        }

                                                    />


                                                </Grid>
                                            </Grid>


                                            <Grid container
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                style={{ paddingTop: 20 }}
                                            >
                                                <Grid item xs={8}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    spacing={2}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={preAnaliseChecked} onChange={handlePreAnalise} />}
                                                        label="Realiza Pré-Ánalise"
                                                    />
                                                </Grid>

                                                <Grid item xs={8}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    spacing={2}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={analiseRemotoChecked} onChange={handleAnaliseRemota} />}
                                                        label="Realiza Ánalise Remoto"
                                                    />
                                                </Grid>


                                                <Grid item xs={8}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    spacing={2}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={analiseEmCampoChecked} onChange={handleAnaliseEmCampo} />}
                                                        label="Realiza Ánalise em Campo"
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={10}
                                        >
                                            <Button style={{ marginTop: 50 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={atualizarConfiguracoes}
                                                disabled={botaoClicadoConfiguracoes}
                                            > Atualizar Configurações </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        value={value} index={2}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid container
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >
                                            <Grid item xs={8}
                                                container
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                spacing={2}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={superAdminChecked} onChange={handleSuperAdminChange} />}
                                                    label="Administrador do Sistema"
                                                />
                                            </Grid>
                                            <Grid item xs={8}
                                                container
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                spacing={2}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={adminFinancasChecked} onChange={(event) => setAdminFinancasChecked(event.target.checked)} disabled={superAdminChecked} />}
                                                    label="Administrador de Finanças"
                                                />
                                            </Grid>
                                            <Grid item xs={8}
                                                container
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                spacing={2}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={adminTecnicoChecked} onChange={(event) => setAdminTecnicoChecked(event.target.checked)} disabled={superAdminChecked} />}
                                                    label="Administrador Técnico"
                                                />
                                            </Grid>
                                            <Grid item xs={8}
                                                container
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                spacing={2}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={suporteTecnicoChecked} onChange={(event) => setSuporteTecnicoChecked(event.target.checked)} disabled={superAdminChecked} />}
                                                    label="Suporte Técnico"
                                                />
                                            </Grid>
                                            <Grid item xs={8}
                                                container
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                spacing={2}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={marketingChecked} onChange={(event) => setMarketingChecked(event.target.checked)} disabled={superAdminChecked} />}
                                                    label="Marketing"
                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                item xs={10}
                                            >
                                                <Button style={{ marginTop: 50 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={atualizarPermissoes}
                                                    disabled={botaoClicadoPermissoes}
                                                > Atualizar Permissões </Button>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </SwipeableViews>



                            </Grid>

                        </Grid>

                    </React.Fragment>

                }
            </Container>

        </div >
    );
}


