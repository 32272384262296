// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alogo {
  color: #1abc9c;
  margin-left: 50px;
  text-decoration: none;
  background-color: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 33px;
  width: 33px;
  color: black;
  filter: invert(100%);
}

.carousel-indicators .active {
  background-color: #0000ff;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/styles.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,iBAAA;EACA,qBAAA;EACA,sBAAA;AAAF;;AAIA;;EAEE,YAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;AADF;;AAMA;EAEE,yBAAA;AAJF","sourcesContent":["\r\n.alogo {\r\n  color: #1abc9c;\r\n  margin-left: 50px;\r\n  text-decoration: none;\r\n  background-color: none;\r\n}\r\n\r\n\r\n.carousel-control-prev-icon,\r\n.carousel-control-next-icon {\r\n  height: 33px;\r\n  width: 33px;\r\n  color: black;\r\n  filter: invert(100%);\r\n \r\n}\r\n\r\n\r\n.carousel-indicators .active{\r\n  \r\n  background-color: #0000ff;\r\n\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
