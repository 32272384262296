import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import Skeleton from '@material-ui/lab/Skeleton';


import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import { Button } from "@material-ui/core";



function novo() {
    window.location.href = "/gestao/administracao/ped/cadastrarprojeto"


}


export default function PeD() {

    const [loading, setLoading] = useState(true);

    const [objetos, setObjetos] = useState(null);

 


    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/ped/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {




        listar();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    const handleClickGerenciar = (cellValues) => {

        window.location.href = "/gestao/administracao/ped/editarprojeto/" + cellValues.row.id_projeto;



    };





    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 70,
            renderCell: (cellValues) => {
                return (


                    <IconButton color="error" onClick={() => handleClickGerenciar(cellValues)}>
                        <SettingsIcon />
                    </IconButton>
                );
            },
        },
        {
            headerName: 'ID',
            field: 'id_projeto',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },


        {
            headerName: 'Status',
            field: 'status_projeto',
            id: 2,
            headerClassName: 'cabecalho_azul',
            valueFormatter: (params) => {
                var status = params.value;
                var texto;
                if (status === 0) {
                    texto = "Aberto"
                } else if (status === 1) {
                    texto = "Em Ánalise";
                } else if (status === 2) {
                    texto = "Em Correção";
                } else if (status === 3) {
                    texto = "Aprovado";
                } else if (status === 4) {
                    texto = "Recusado";
                }

                return texto;
            },
        },


        {
            headerName: 'Descriçao',
            field: 'descricao_projeto',
            id: 3,
            width: 600,
            headerClassName: 'cabecalho_azul',
        },



        {
            field: 'usuario_criador',
            id: 4,
            headerName: 'Criador',
            width: 400,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                var usuario = params.value;
                var rawDate = params.row.data_hora_criacao;
                const formattedDate = moment(rawDate).format('DD/MM/YYYY HH:mm');
                var texto = "Criado por " + (usuario.nome_amigavel) + " em " + formattedDate;

                return (
                    <Typography
                        variant="h8"
                    >
                        {texto}
                    </Typography>
                );
            },

        },

    ];



    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novo}
                    >
                        Novo Projeto
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white' }}>
                            {objetos !== null && (
                                <>
                                    <DataGrid
                                        pagination
                                        checkboxSelection
                                        rows={objetos}
                                        getRowId={(row) => row.id_projeto}
                                        columns={columnsDataGrid}
                                        disableColumnResize={false}
                                        onCellClick={handleCellClick}
                                        onRowClick={handleRowClick}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />



                                </>
                            )}
                        </div>
                    </div>
                }


            </div>



        </div>
    );
}



