import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';




import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";


import { Button } from "@material-ui/core";

//Material UI Imports
import {
    Box,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
//Icons Imports
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
    MaterialReactTable,

} from 'material-react-table';



function novo() {
    window.location.href = "/gestao/operacional/almoxarifado/insumos/cadastrarinsumo/0/0"

}

const Insumos = () => {


    const [loading, setLoading] = useState(true);

    const [objetos, setObjetos] = useState(null);









    useEffect(() => {



        async function listar() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }




                await api.get("v1/protected/almoxarifado/insumos/listar", {
                    headers: headers
                }).then(function (response) {
                    const updatedData = response.data.map(item => {
                        return Object.keys(item).reduce((acc, key) => {
                            acc[key] = item[key] === null ? '' : item[key];
                            return acc;
                        }, {});
                    });


                    setObjetos(updatedData);
                    console.log(" Objetos: " + response);


                    setLoading(false);


                });



            } catch (_err) {

                console.log("Erro ao listar objetos: " + _err)
                setLoading(false);

            }

        }

        listar();


    }, []);

    function handleClickEditar(row) {
        const idInsumo = row.original.id_insumo;
        const url = `/gestao/operacional/almoxarifado/insumos/editarinsumo/${idInsumo}`;
        window.open(url, '_blank');
    }

    function handleClickGerenciar(row) {
        const idInsumo = row.original.id_insumo;
        const url = `/gestao/operacional/almoxarifado/cotacoes/listar/${idInsumo}`;
        window.open(url, '_blank');
    }

    function handleClickReplicar(row) {
        const idInsumo = row.original.id_insumo;
        const url = `/gestao/operacional/almoxarifado/insumos/cadastrarinsumo/${idInsumo}/0`;
        window.open(url, '_blank');
    }

    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_insumo', //normal accessorKey
                header: 'ID Insumo',
                size: 30,
            },


            {
                header: 'Descrição',
                size: 300,
                accessorKey: 'descricao',
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },

            {
                header: 'Tipo',
                accessorKey: 'tipo',
                size: 300,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },
            {
                accessorKey: 'marca',
                header: 'Marca',
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },




            {
                header: 'Modelo',
                accessorKey: 'modelo',
                size: 200,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },
            {
                accessorKey: 'serie',
                header: 'Série',
                size: 200,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),

            },
            {
                accessorKey: 'codigo_interno',
                header: 'Código Interno',
                size: 200,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },


            {
                accessorKey: 'categoria',
                header: 'Categoria',
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),

            },
            {
                accessorKey: 'versao',
                header: 'Versão',
                size: 200,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),

            },
            {
                header: 'Data',
                accessorKey: 'data',
                size: 200,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },

        ],
        [],
    );


    return (
        <div>

            <NavegadorGestao
                Almoxarifado={true}
                Insumos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novo}
                    >
                        Novo Insumo
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white', margin: 5 }}>
                            {objetos && (
                                <MaterialReactTable
                                    columns={columns}
                                    data={objetos}
                                    enableBottomToolbar={true}
                                    enableGlobalFilterModes={true}
                                    enablePagination={true}
                                    enableRowActions
                                    enableGlobalFilter={true}
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_insumo}

                                    initialState={{
                                        pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                        showGlobalFilter: true,
                                    }}
                                    muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                    muiPaginationProps={{
                                        rowsPerPageOptions: [50, 100, 200],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                left: '30px',
                                                maxWidth: '1000px',
                                                position: 'sticky',
                                                width: '100%',
                                            }}
                                        >
                                        </Box>
                                    )}
                                    renderRowActions={({ row }) => (
                                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => handleClickEditar(row)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Gerenciar">
                                                <IconButton color="error" onClick={() => handleClickGerenciar(row)}>
                                                    <SettingsIcon />
                                                </IconButton>
                                            </Tooltip>

                                        </Box>
                                    )}
                                />
                            )}
                        </div>
                    </div>
                }


            </div>



        </div >
    );

};

export default Insumos;