import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useParams } from "react-router-dom";
import Chip from '@mui/material/Chip';

import { useHistory } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";


export default function CadastroInsumo() {

    const [loading, setLoading] = useState(true);


    const history = useHistory();

    const [serie, setSerie] = useState('');
    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');

    const [codigo, setCodigo] = useState('');

    const [tipo, setTipo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [categoria, setCategoria] = useState('');
    const [versao, setVersao] = useState('');
    const [data, setData] = useState('');

    const [procedimentos, setProcedimentos] = useState([]);

    const [procedimentosSelecionados, setProcedimentosSelecionados] = useState([]);

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);





    const [observacoes, setObservacoes] = useState('');


    const [teste, setTeste] = useState(0);

    const handleChangeTeste = (event) => {
        setTeste(event.target.value);
    };


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/procedimentos/listartodos", {
                headers: headers
            }).then(function (response) {
                setProcedimentos(response.data)
                console.log("procedimentos listados!");

                setLoading(false);

            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Procedimentos: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();

    }, []);




    const handleAdicionarProcedimento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosProcedimentos = valoresSelecionados.filter(
            (opcao) => !procedimentosSelecionados.some((selecionado) => selecionado.id_procedimento === opcao.id_procedimento)
        );

        setProcedimentosSelecionados([...procedimentosSelecionados, ...novosProcedimentos]);
    };



    async function salvar() {


        setBotaoSalvarClickado(true);
        var id_user = Cookies.get('id_usuario');


        try {

            console.log("cadastrar chamado");
            const cadastro_insumo = {

                marca: marca,
                modelo: modelo,
                serie: serie,
                codigo_interno: codigo,
                tipo: tipo,
                descricao: descricao,
                categoria: categoria,
                versao: versao,
                data: data,
                procedimentos: procedimentosSelecionados,
                teste: teste,
                observacoes: observacoes,

            }





            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/almoxarifado/insumos/cadastrar', cadastro_insumo,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Insumo Cadastrado!");
                history.push({
                    pathname: "/gestao/operacional/almoxarifado/insumos/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }

    function returnDescricaoProcedimento(procedimento) {
        var texto = "Nome: " + procedimento.nome + "\n Descrição: " + procedimento.descricao_geral + (procedimento.obrigatorio === 1 ? " Obrigatório" : "Opcional");
        return texto;
    }



    return (
        <div>
            <NavegadorGestao
                Almoxarifado={true}
                Insumos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }}>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >




                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="marca"
                                        label="Marca"
                                        required
                                        id="marca"
                                        value={marca}
                                        onChange={(e) => setMarca(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="modelo"
                                        label="Modelo"
                                        required
                                        id="modelo"
                                        value={modelo}
                                        onChange={(e) => setModelo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>



                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="tipo"
                                        label="Tipo"
                                        required
                                        id="tipo"
                                        value={tipo}
                                        onChange={(e) => setTipo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="categoria"
                                        label="Categoria"
                                        required
                                        id="categoria"
                                        value={categoria}
                                        onChange={(e) => setCategoria(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="versao"
                                        label="Versão"
                                        required
                                        id="versao"
                                        value={versao}
                                        onChange={(e) => setVersao(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="serie"
                                        label="Série"
                                        required
                                        id="serie"
                                        value={serie}
                                        onChange={(e) => setSerie(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="codigo"
                                        label="Código Interno"
                                        required
                                        id="codigo"
                                        value={codigo}
                                        onChange={(e) => setCodigo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="data"
                                        label="Ano"
                                        required
                                        id="data"
                                        value={data}
                                        onChange={(e) => setData(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>




                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={3}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="observacoes"
                                        label="observacoes"
                                        id="Observações"
                                        value={observacoes}
                                        onChange={(e) => setObservacoes(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={3}
                                    />
                                </Grid>

                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="obrigatorio-selector-label">Teste no CheckList</InputLabel>
                                            <Select
                                                labelId="teste-selector-label"
                                                id="teste-selector"
                                                value={teste}
                                                onChange={handleChangeTeste}
                                                label="Teste no CheckList"
                                            >
                                                <MenuItem value={0}>NÃO</MenuItem>
                                                <MenuItem value={1}>SIM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>




                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-procedimentos"
                                            options={procedimentos}
                                            disableCloseOnSelect
                                            multiple
                                            getOptionLabel={(procedimento) => returnDescricaoProcedimento(procedimento).replace(/<br \/>/g, '\n')}
                                            value={procedimentosSelecionados}
                                            onChange={handleAdicionarProcedimento}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((procedimento) => {
                                                    const cotacaoText = returnDescricaoProcedimento(procedimento).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Procedimentos " multiline minRows={3} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoProcedimento(option) }} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>


                            </Grid>



                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={10}
                            >
                                <Button style={{ marginTop: 50 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoSalvarClickado}
                                > Salvar  </Button>
                            </Grid>

                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


