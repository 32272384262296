import React, { useState, useMemo, useEffect, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import globalConfig from '../../../../../../configs/globalConfigs';
import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Close from '@material-ui/icons/Close'

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Box from "@material-ui/core/Box";
import Skeleton from '@material-ui/lab/Skeleton';
import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";
import "./MapPage.css";




import icone_origem from '../../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../../assets/imgs/icone_parada.png';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'white',
    },
    toolbar: {

    },
    toolbarIcon: {

    },
    appBar: {

    },
    appBarShift: {

    },
    menuButton: {

    },
    menuButtonHidden: {

    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {

    },
    drawerPaperClose: {

    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        display: 'flex',


    },
    container: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'green',


    },
    paper: {

    },
    fixedHeight: {

    },
}));


const libraries = ["places"];


export default function VizualizarRota() {


    const { idRota } = useParams();


    const classes = useStyles();
    const history = useHistory();


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const [tipoVeiculo, setTipoVeiculo] = useState(0);
    const [evitarPedagio, setEvitarPedagio] = useState(true);
    const [evitarBalsas, setEvitarBalsas] = useState(true);
    const [distancia, setDistancia] = useState('');
    const [tempo, setTempo] = useState('');

    const handleTipoVeiculoChange = (event) => {
        const value = event.target.value;
        console.log("valor: " + value);
        setTipoVeiculo(parseInt(value));
    };

    const handleEvitarPedagioChange = (event) => {
        setEvitarPedagio(event.target.checked);
    };

    const handleEvitarBalsasChange = (event) => {
        setEvitarBalsas(event.target.checked);
    };

    const handleDistanciaChange = (event) => {
        setDistancia(event.target.value);
    };

    const handleTempoChange = (event) => {
        setTempo(event.target.value);
    };


    const handleClickRotaPrincipal = () => {
        setIsRotaPrincipal(true);
        setIsRotaAlternativa1(false);
        setIsRotaAlternativa2(false);

        console.log("Rota principal chamada!");


        var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
        if (!directionsData) {
            alert('Falha ao obter a rota');
            return;
        }
        else {
            console.log(" Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").");
            setDistancia(directionsData.distance.text);
            setTempo(directionsData.duration.text);
        }

    };


    const handleClickRotaAlternativa1 = () => {
        setIsRotaPrincipal(false);
        setIsRotaAlternativa1(true);
        setIsRotaAlternativa2(false);

        console.log("Rota Alternativa 1 chamada!");


        var directionsData = routeAlternative1.routes[0].legs[0]; // Get data about the mapped route
        if (!directionsData) {
            alert('Falha ao obter a rota');
            return;
        }
        else {
            console.log(" Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").");
            setDistancia(directionsData.distance.text);
            setTempo(directionsData.duration.text);
        }
    };

    const handleClickRotaAlternativa2 = () => {

        console.log("Rota Alternativa 2 chamada!");

        setIsRotaPrincipal(false);
        setIsRotaAlternativa1(false);
        setIsRotaAlternativa2(true);

        var directionsData = routeAlternative2.routes[0].legs[0]; // Get data about the mapped route
        if (!directionsData) {
            alert('Falha ao obter a rota');
            return;
        }
        else {
            console.log(" Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").");
            setDistancia(directionsData.distance.text);
            setTempo(directionsData.duration.text);
        }
    };


    const [contador, setContador] = useState(0);
    const [complete, setComplete] = useState(0);


    const [map, setMap] = useState();
    const [searchBoxA, setSearchBoxA] = useState();
    const [searchBoxB, setSearchBoxB] = useState();
    const [searchBoxParada, setSearchBoxParada] = useState();

    const [paradas, setParadas] = useState([]);
    const [paradasEndereco, setParadasEndereco] = useState([]);

    const [rotaComponentes, setRotaComponentes] = useState();

    const [pointA, setPointA] = useState();
    const [pointB, setPointB] = useState();

    const [origin, setOrigin] = useState(null);
    const [originEndereco, setOriginEndereco] = useState(null);
    const [destination, setDestination] = useState(null);
    const [destinationEndereco, setDestinationEndereco] = useState(null);

    const [response, setResponse] = useState(null);


    const [lat, setLat] = useState();
    const [lng, setLng] = useState();



    const onMapLoad = (map) => {
        setMap(map);
        setGoogleLoaded(true);



    };

    const onLoadA = (ref) => {
        setSearchBoxA(ref);
    };

    const onLoadB = (ref) => {
        setSearchBoxB(ref);
    };

    const onLoadParada = (ref) => {
        setSearchBoxParada(ref);
    };

    const onPlacesChangedA = () => {
        console.log("On places A chamado")
        const places = searchBoxA.getPlaces();
        console.log(places);
        const place = places[0];
        const location = {
            lat: place?.geometry?.location?.lat() || 0,
            lng: place?.geometry?.location?.lng() || 0,
        };
        setPointA(location);
        setOriginEndereco(place?.formatted_address || null);

        setOrigin(null);
        setDestination(null);
        setResponse(null);
        map?.panTo(location);
    };

    const onPlacesChangedB = () => {
        console.log("On places B chamado")

        const places = searchBoxB.getPlaces();
        console.log(places);
        const place = places[0];
        const location = {
            lat: place?.geometry?.location?.lat() || 0,
            lng: place?.geometry?.location?.lng() || 0,
        };
        setPointB(location);
        setOrigin(null);
        setDestinationEndereco(place?.formatted_address || null);

        setDestination(null);
        setResponse(null);
        map?.panTo(location);
    };



    const onPlacesAddWaypoint = () => {
        console.log("Add parada chamado")

        const places = searchBoxParada.getPlaces();
        console.log(places);
        const place = places[0];

        console.log("dados:", JSON.stringify(place, null, 2));



        const endereco = {
            completo: place?.formatted_address || 0,
        }
        setParadasEndereco(prevParadasEndereco => [...prevParadasEndereco, { endereco }]);

        const location = {
            lat: place?.geometry?.location?.lat() || 0,
            lng: place?.geometry?.location?.lng() || 0,
        };
        setParadas(prevParadas => [...prevParadas, { location }]);

        map?.panTo(location);

        setRouteAlternative1(null);
        setRouteAlternative2(null);

        //setSearchBoxParada(null);
    };

    const traceRoute = () => {
        console.log("Tracar rota chamado");

        if (pointA && pointB) {


            console.log("a: " + JSON.stringify(pointA));
            console.log("b: " + JSON.stringify(pointB));

            setOrigin(null);
            setDestination(null);

            setPointA(null)
            setPointB(null)

            setPointA(pointA);
            setPointB(pointB)


            setOrigin(pointA);
            setDestination(pointB);


            setContador(contador + 1);



        } else {
            console.log("Pontos Invalidos");


            console.log("a: " + JSON.stringify(pointA));
            console.log("b: " + JSON.stringify(pointB));
        }

        /*
            
                      const a = ({
                          lat: -1.3557332,
                          lng: -48.41009689999999,
                      });
               
                      const b = ({
                          lat: -1.4141825,
                          lng: -48.4389725,
                      });
               
                      setPointA(a);
                      setPointB(b);
               
                      setOrigin(a);
                      setDestination(b);
               
               */
    };

    const directionsServiceOptions = useMemo(() => {
        return {
            origin,
            destination,
            travelMode: tipoVeiculo === 0 ? "DRIVING" : "TWO_WHEELER",
            provideRouteAlternatives: true,
            avoidFerries: evitarPedagio,
            avoidTolls: evitarBalsas,
            waypoints: paradas.map(parada => ({
                location: parada.location,
                stopover: true,
            })),
            optimizeWaypoints: true,
            drivingOptions: {
                departureTime: new Date(), // Define o tempo de partida como o momento atual
                trafficModel: "pessimistic", // Define o modelo de tráfego
            },
        };
    }, [contador]);






    const [routeAlternative1, setRouteAlternative1] = useState([]);
    const [routeAlternative2, setRouteAlternative2] = useState([]);


    const [isRotaPrincipal, setIsRotaPrincipal] = useState(true);
    const [isRotaAlternativa1, setIsRotaAlternativa1] = useState(false);
    const [isRotaAlternativa2, setIsRotaAlternativa2] = useState(false);

    const [distanceResponse, setDistanceResponse] = useState();




    const directionsCallback = useCallback((res) => {

        console.log("Callback recebido!");

        if (res !== null && res.status === "OK") {
            setResponse(res);

            //   console.log("dados da rota: " + JSON.stringify(res.routes[0]));


            const routes = res.routes;

            const segundaRota = routes[1];
            const terceiraRota = routes[2];


            if (segundaRota !== null) {
                const response = { ...res, routes: [segundaRota] };
                setRouteAlternative1(response);
            } else {
                setRouteAlternative1(null);
            }


            if (terceiraRota !== null) {
                console.log("Rota Alternativa 2 é valida")
                const response = { ...res, routes: [terceiraRota] };
                setRouteAlternative2(response);
            } else {
                console.log("Rota Alternativa 2 não é valida")
                setRouteAlternative2(null);
            }



         
            


        } else {
            console.log("erro na resposta do callback");
            console.log(res);
        }
    }, []);







    const [botaoClicado, setBotaoClicado] = useState(false);
    const [roteamento, setRoteamento] = useState(0);
    const [descricao, setDescricao] = useState('');



    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };


    function criarComponentesRota() {
        const componentesRota = [];

        //origin
        componentesRota.push({
            tipo: 0,
            endereco: originEndereco,
            latitude: origin.lat,
            longitude: origin.lng,
        });

        //origin
        componentesRota.push({
            tipo: 1,
            endereco: destinationEndereco,
            latitude: destination.lat,
            longitude: destination.lng,
        });

        // Percorrer o array de paradas
        paradas.forEach((parada, index) => {
            // Obter os dados da parada atual
            const { location } = parada;
            const { lat, lng } = location;
            const endereco = paradasEndereco[index]?.endereco?.completo || '';

            // Criar um objeto com os atributos especificados
            const componente = {
                tipo: 2,
                endereco,
                latitude: lat,
                longitude: lng
            };

            // Adicionar o objeto ao array componentesRota
            componentesRota.push(componente);
        });


        console.log("componentes: " + JSON.stringify(componentesRota));

        return componentesRota;

    }

    async function funcaoSalvar() {

        if (origin && destination) {

            try {

                console.log("cadastrar chamado");
                const cadastro = {
                    descricao: descricao,
                    usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                    tipo_veiculo: parseInt(tipoVeiculo),
                    evitar_pedagio: evitarPedagio ? 1 : 0,
                    evitar_balsa: evitarBalsas ? 1 : 0,
                    roteamento: isRotaPrincipal ? 0 : isRotaAlternativa1 ? 1 : 2,
                    componentes_rota: criarComponentesRota(),


                }


                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.post('/v1/protected/rotas/cadastrar', cadastro,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Rota Cadastrado!");
                    history.push({
                        pathname: "/gestao/operacional/frota/rotas/listar",
                    })
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }

            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoClicado(false);
            }

        } else {
            alert("Defina Origem e Destino!");
            setBotaoClicado(false);
        }

    }




    const options = {
        mapTypeId: 'roadmap',
        streetViewControl: true,
        fullscreenControl: true,
    };


    const [endereco, setEndereco] = useState();


    const markers = [
        {
            position: { lat: lat, lng: lng },
            title: "Unidade Base",
        },
    ];


    const containerStyle = {
        width: '100%',
        height: '100%'
    };


    const [configuracoes, setConfiguracoes] = useState();
    const [loading, setLoading] = useState();

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            console.log("id da rota: " + idRota);

            await api.get("/v1/protected/rotas/listarporid/" + idRota, {
                headers: headers
            }).then(function (response) {

                console.log("resposta: " + JSON.stringify(response.data));






                setTipoVeiculo(response.data.tipo_veiculo);
                setDescricao(response.data.descricao);
                setEvitarBalsas(response.data.evitar_balsa === 1 ? true : false);
                setEvitarPedagio(response.data.evitar_pedagio === 1 ? true : false);
                setRoteamento(response.data.roteamento);

                setRotaComponentes(response.data.componentes_rota);

                api.get("/v1/protected/configuracoes", {
                    headers: headers
                }).then(function (response) {
                    setConfiguracoes(response.data);

                    const lati = parseFloat(response.data.unidade_base.latitude);
                    const lngi = parseFloat(response.data.unidade_base.longitude);

                    setLat(lati);
                    setLng(lngi);

                    console.log("Lat: " + lati);
                    console.log("Lng: " + lngi);


                    const logradouro = response.data.unidade_base.logradouro;
                    const numero = response.data.unidade_base.numero;
                    const bairro = response.data.unidade_base.bairro;
                    const complemento = response.data.unidade_base.complemento;
                    const cidade = response.data.unidade_base.cidade;
                    const estado = response.data.unidade_base.estado;
                    const cep = response.data.unidade_base.cep;

                    const end = `${logradouro}, ${numero}, ${bairro}, ${cidade}, ${estado} - ${cep}`;
                    setEndereco(end);
                    console.log("Endereço: " + end);

                    setLoading(false);
                });


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    const [googleLoaded, setGoogleLoaded] = useState(false);




    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;


        console.log("Largura: " + largura);
        setWidth(largura)

    }

    const [width, setWidth] = useState(0);



    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });

    // Efeito para carregar a API do Google Maps
    useEffect(() => {


        listarInformacoes();
        checkDimenssoes();




    }, []);

    useEffect(() => {


        if (paradas) {
            setRouteAlternative1(null);
            setRouteAlternative2(null);
        }


    }, [paradas]);



    useEffect(() => {
        // Verifique se googleLoaded e loading são verdadeiros
        if (!loading && googleLoaded) {
            console.log("Mapa carregado");


            // Encontre o componente com tipo igual a 0
            const componenteTipo0 = Array.from(rotaComponentes).find(componente => componente.tipo === 0);
            const componenteTipo1 = Array.from(rotaComponentes).find(componente => componente.tipo === 1);

            // Se o componente com tipo 0 for encontrado, atribua o valor do atributo endereco à variável texto
            if (componenteTipo0 && componenteTipo1) {
                console.log("Origem e Destino encontrada");



                const location = ({
                    lat: parseFloat(componenteTipo0.latitude),
                    lng: parseFloat(componenteTipo0.longitude),
                });

                const location1 = ({
                    lat: parseFloat(componenteTipo1.latitude),
                    lng: parseFloat(componenteTipo1.longitude),
                });

                setOriginEndereco(componenteTipo0.endereco);
                setDestinationEndereco(componenteTipo1.endereco);

                setPointA(location);
                setPointB(location1);



                //            

            }

        }
    }, [loading]);


    useEffect(() => {

        if (pointA && pointB) {
            console.log("Pont A: " + pointA?.lat);
            console.log("Pont B: " + pointB?.lng);
            traceRoute();

        }


    }, [pointA, pointB]);







    useEffect(() => {

        if (!loading && googleLoaded) {
            if (roteamento === 0) {
                handleClickRotaPrincipal();
            } else if (roteamento === 1) {
                handleClickRotaAlternativa1();
            } 
        }

    }, [routeAlternative1]);


    useEffect(() => {

        if (!loading && googleLoaded) {
             if (roteamento === 2) {
                handleClickRotaAlternativa2();
            }
        }

    }, [routeAlternative2]);

 

    const AnyReactComponent = ({ text, data, status, lat, lng }) => (
        <OverlayView
            position={{ lat, lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height / 2),
            })}
        >
            <div
                style={{
                    position: "absolute",
                    top: 50,
                    left: 0,
                    transform: "translate(-50%, -50%)",
                    height: "100px",
                    width: "400px",
                }}
            >
                <div>
                    <span style={{ backgroundColor: "white", color: 'blue', fontSize: '14px', fontWeight: 'bold' }}> {text}</span>
                </div>

            </div>
        </OverlayView>
    );

    const removerParada = (index) => {
        // Cria um novo array de paradas excluindo o elemento com o índice especificado
        const novasParadas = paradas.filter((_, i) => i !== index);
        const novoEnderecos = paradasEndereco.filter((_, i) => i !== index);

        // Atualiza o estado paradas com o novo array
        setParadasEndereco(novoEnderecos);
        setParadas(novasParadas);
    };



    return (
        <div  >
              <NavegadorGestao
                Operacional={true}
                Rotas={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


                {loading ?
                    <Skeleton animation={"wave"} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <div className={classes.content}>
                        <React.Fragment >
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                item xs={12}
                            >


                                {googleLoaded && (
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        item xs={4}
                                        style={{ backgroundColor: 'white',  paddingTop: 5, width:  '100%', zIndex: 1 }}

                                    >

                                        <Grid
                                            item xs={12}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent={"center"}
                                        >
                                            <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                                <AppBar position="static" >

                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChangeTab}
                                                        indicatorColor="secondary"
                                                        textColor="inherit"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"


                                                    >
                                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                        <Tab label="Paradas" style={{ backgroundColor: 'green' }} />

                                                    </Tabs>
                                                </AppBar>

                                            </Box>
                                        </Grid>


                                        <SwipeableViews
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >

                                            <Grid
                                                value={value} index={0}
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                item xs={12}
                                                spacing={2}
                                                alignItems="center"

                                            >


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    item xs={12}
                                                    alignItems="center"

                                                >

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        item xs={12}
                                                        style={{ paddingTop: 20 }}
                                                    >
                                                        <StandaloneSearchBox
                                                            onLoad={onLoadA}
                                                        >

                                                            <input
                                                                className="addressField"
                                                                value={originEndereco}

                                                                />

                                                        </StandaloneSearchBox>
                                                    </Grid>


                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        item xs={12}
                                                    >
                                                        <StandaloneSearchBox
                                                            onLoad={onLoadB}
                                                        >
                                                            <input
                                                                className="addressField"
                                                                value={destinationEndereco}
                                                            />
                                                        </StandaloneSearchBox>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        item xs={12}
                                                        style={{ paddingTop: 2 }}
                                                    >
                                                        <button>Traçar rota</button>

                                                    </Grid>

                                                </Grid>




                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    item xs={12}
                                                    spacing={2}
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        item xs={12}
                                                        spacing={2}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item xs={6}
                                                        >
                                                            <FormControl component="fieldset" className={classes.formControl}>
                                                                <FormLabel component="legend">Tipo de Veículo</FormLabel>
                                                                <RadioGroup
                                                                    aria-label="Tipo de Veículo"
                                                                    name="tipoVeiculo"
                                                                    value={tipoVeiculo}
                                                                >
                                                                    <Grid
                                                                        item xs={12}
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid
                                                                            item xs={8}
                                                                        >
                                                                            <FormControlLabel
                                                                                value={0}
                                                                                control={<Radio />}
                                                                                label="Carro"
                                                                            />
                                                                        </Grid>

                                                                    </Grid>


                                                                    <Grid
                                                                        item xs={12}
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid
                                                                            item xs={8}
                                                                        >
                                                                            <FormControlLabel
                                                                                value={1}
                                                                                control={<Radio />}
                                                                                label="Moto"
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item xs={6}

                                                        >
                                                            <FormControl component="fieldset" className={classes.formControl}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={evitarPedagio}
                                                                            name="evitarPedagio"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Evitar Pedágio"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={evitarBalsas}
                                                                            name="evitarBalsas"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Evitar Balsas"
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item xs={12}
                                                        >
                                                            <Grid
                                                                item xs={12}
                                                            >
                                                                <Typography variant="h8">
                                                                    Distância: {distancia}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item xs={12}
                                                            >
                                                                <Typography variant="h8">
                                                                    Tempo: {tempo}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>


                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item xs={12}
                                                            spacing={2}

                                                        >
                                                            {roteamento === 0 &&
                                                                <Grid item xs={4}>
                                                                    <Button onClick={handleClickRotaPrincipal}>
                                                                        <Box display="flex" alignItems="center">
                                                                            <div style={{ width: '20px', height: '20px', backgroundColor: 'green', marginRight: '10px' }} /> {/* Quadrado verde */}
                                                                            <Typography variant="h8">
                                                                                Rota Principal
                                                                            </Typography>
                                                                        </Box>
                                                                    </Button>
                                                                </Grid>
                                                            }

                                                            {roteamento === 1 &&
                                                                <Grid item xs={4}>
                                                                    <Button onClick={handleClickRotaAlternativa1}>
                                                                        <Box display="flex" alignItems="center">
                                                                            <div style={{ width: '20px', height: '20px', backgroundColor: 'red', marginRight: '1px' }} /> {/* Quadrado verde */}
                                                                            <Typography style={{ fontSize: 14 }}>
                                                                                1º Rota Alternativa
                                                                            </Typography>
                                                                        </Box>
                                                                    </Button>
                                                                </Grid>
                                                            }

                                                            {roteamento === 2 &&
                                                                <Grid item xs={4}>
                                                                    <Button onClick={handleClickRotaAlternativa2}>
                                                                        <Box display="flex" alignItems="center">
                                                                            <div style={{ width: '20px', height: '20px', backgroundColor: 'blue', marginRight: '1px' }} /> {/* Quadrado verde */}
                                                                            <Typography style={{ fontSize: 14 }}>
                                                                                2º Rota Alternativa
                                                                            </Typography>
                                                                        </Box>
                                                                    </Button>
                                                                </Grid>
                                                            }

                                                            <Grid item xs={12} style={{ padding: 5 }}>
                                                                <TextField
                                                                    variant="standard"
                                                                    name="descricao"
                                                                    label="Descrição"
                                                                    required
                                                                    id="descricao"
                                                                    value={descricao}
                                                                    onChange={(e) => setDescricao(e.target.value)}
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={3}
                                                                />
                                                            </Grid>


                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-end"
                                                                alignItems="center"
                                                                item xs={12}
                                                            >
                                                                <Button style={{ margin: 5 }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={salvar}
                                                                    disabled={botaoClicado}
                                                                > Salvar  </Button>
                                                            </Grid>


                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                value={value} index={1}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                            >


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    item xs={12}
                                                    style={{ paddingTop: 10 }}
                                                >
                                                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                                                        Paradas
                                                    </Typography>
                                                </Grid>

                                           

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    item
                                                    xs={12}
                                                    spacing={1}
                                                >
                                                    {paradas && paradas.map((parada, index) => (
                                                        <Grid
                                                            key={index}
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item
                                                            xs={12}
                                                            style={{ paddingTop: 2 }}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={10}
                                                            >
                                                                <Typography color="inherit" >
                                                                    {paradasEndereco[index]?.endereco?.completo || ''}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={1}
                                                                direction="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <IconButton
                                                                    aria-label="remover parada"
                                                                    onClick={() => removerParada(index)}
                                                                    style={{
                                                                        backgroundColor: 'red',
                                                                        borderRadius: '8px', // Arredonda os cantos em 8px
                                                                        border: '1px solid black', // Reduz a espessura da borda para 1px
                                                                    }}
                                                                >
                                                                    <Close style={{ color: 'white' }} />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>


                                        </SwipeableViews>

                                    </Grid>
                                )}

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ position: 'absolute',  paddingTop: 5, width: '100%', height: '100%', zIndex: 0 }}
                                >
                                    <LoadScript
                                        googleMapsApiKey={globalConfig.mapKey}
                                        libraries={libraries}

                                    >
                                        <GoogleMap
                                            onLoad={onMapLoad}
                                            center={{
                                                lat: lat,
                                                lng: lng,
                                            }}
                                            mapContainerStyle={containerStyle}
                                            options={options}
                                            zoom={18}

                                        >

                                            {markers.map((marker) => (
                                                <Marker key={marker.title} position={marker.position} title={marker.title} />
                                            ))}


                                            {paradas && paradas.map((parada, index) => (
                                                <AnyReactComponent
                                                    key={index} // Certifique-se de usar uma chave única para cada marcador
                                                    lat={parada.location.lat}
                                                    lng={parada.location.lng}
                                                    text={`${index + 1}º Parada`}
                                                />
                                            ))}

                                            {paradas && paradas.map((parada, index) => (
                                                <Marker
                                                    position={parada.location}
                                                    icon={icone_parada}
                                                />

                                            ))}

                                            {pointA &&
                                                <Marker position={pointA}
                                                    icon={icone_origem}
                                                />}
                                            {pointB &&
                                                <Marker position={pointB}
                                                    icon={icone_destino}
                                                />}

                                            {origin && destination && (
                                                <DirectionsService
                                                    options={directionsServiceOptions}
                                                    callback={directionsCallback}
                                                />
                                            )}




                                            {response && routeAlternative1 && roteamento === 1 && (
                                                <DirectionsRenderer
                                                    suppressMarkers={true}

                                                    options={
                                                        {
                                                            suppressMarkers: true,
                                                            directions: routeAlternative1,
                                                            polylineOptions: {
                                                                strokeColor: 'red', // Cor da rota alternativa
                                                                strokeOpacity: isRotaAlternativa1 ? 1 : 0.5, // Opacidade da rota alternativa
                                                                strokeWeight: isRotaAlternativa1 ? 13 : 8, // Espessura da linha da rota alternativa
                                                            },


                                                        }
                                                    }

                                                />
                                            )}


                                            {response && routeAlternative2 && roteamento === 2 &&  (
                                                <DirectionsRenderer
                                                    suppressMarkers={true}

                                                    options={
                                                        {
                                                            suppressMarkers: true,
                                                            directions: routeAlternative2,
                                                            polylineOptions: {
                                                                strokeColor: 'blue', // Cor da rota alternativa
                                                                strokeOpacity: isRotaAlternativa2 ? 1 : 0.5, // Opacidade da rota alternativa
                                                                strokeWeight: isRotaAlternativa2 ? 13 : 8, // Espessura da linha da rota alternativa
                                                            },


                                                        }

                                                    }

                                                />

                                            )}


                                            {response && roteamento === 0 && (
                                                <DirectionsRenderer
                                                    suppressMarkers={true}

                                                    options={
                                                        {
                                                            suppressMarkers: true,
                                                            directions: response,
                                                            polylineOptions: {
                                                                strokeColor: 'green',
                                                                strokeOpacity: isRotaPrincipal ? 1 : 0.5, // Opacidade da rota alternativa
                                                                strokeWeight: isRotaPrincipal ? 13 : 8, // Espessura da linha da rota alternativa
                                                            },

                                                        }
                                                    }

                                                />
                                            )}




                                        </GoogleMap>
                                    </LoadScript>
                                </Grid>

                            </Grid>


                        </React.Fragment>
                    </div>

                }
            </div >
    );
}





