import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import AppBar from '@mui/material/AppBar';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { Box, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@material-ui/icons';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/styles';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import ChatIcon from '@mui/icons-material/Chat';
import VariationsIcon from '@mui/icons-material/ViewCarousel';
import moment from 'moment';
import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import office from '../../../../../assets/imgs/office.png';
import Notifications from '@mui/icons-material/Notifications';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ReplyIcon from '@mui/icons-material/Reply';


import api from '../../../../../services/api';
import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import {
    MaterialReactTable
} from 'material-react-table';

import NavegadorGestao from '../../../../../components/NavBarGestao';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

import { Email, EmailOutlined, Visibility, VisibilityOff, Message, MessageOutlined } from '@mui/icons-material';



const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));



export default function EditarListaRequerimentos() {

    
    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });





    const [loading, setLoading] = useState(true);


    const [ordemCompra, setOrdemCompra] = useState(null);
    const { idOrdemCompra } = useParams();



    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);
    const [quantidade, setQuantidade] = useState(0);
    const [observacoesItem, setObservacoesItem] = useState('');
    const [descricaoNovoInsumo, setDescricaoNovoInsumo] = useState('');
    const [descricao, setDescricao] = useState('');


    function handleSetInsumo(insumo) {
        setInsumoSelecionado(insumo);
    }

    async function listarInformacoes() {
        try {


            const token = Cookies.get('token');

            let status_ordem = -1;
            let isSolicitanteLocal = false;

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordensdecompras/ordemdecompra/listarporid/" + idOrdemCompra, {
                headers: headers
            }).then(function (response) {

                console.log("ordem de compra listada!");

                const ordem_compra = response.data.ordem_compra;
                setOrdemCompra(ordem_compra);


                api.get("v1/protected/almoxarifado/insumos/listar", {
                    headers: headers
                }).then(function (response) {
                    setInsumos(response.data);
                    setLoading(false);
                });
            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }





    useEffect(() => {


        listarInformacoes();


    }, []);



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {

        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    function returnDescricaoInsumo(insumo_) {

        let insumo = insumo_.insumo;
        let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        var texto =

            //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
            // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
            //"<br />Tipo: " + insumo.tipo +
            //"<br />Categoria: " + insumo.categoria +
            textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
        return texto;
    }





    function returnDescricaoInsumoSimplificada(insumo) {


        var texto =


            "Descrição: " + insumo.descricao;
        return texto;
    }


    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {

                return (
                    <ColorButtonRed variant="contained" color="primary"
                        onClick={(event) => handleClickRemocao(event, cellValues)}

                    >
                        Remover
                    </ColorButtonRed>
                )
            },
        },
        {
            headerName: 'Insumo',
            field: 'insumo',
            id: 2,
            width: 700,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const insumo = params.row.insumo;
                let descricao = returnDescricaoInsumoSimplificada(insumo);
                descricao = descricao.replace(/<br\s*\/?>/gi, '\n');
                return <div style={{ whiteSpace: 'pre-line' }}>{descricao}</div>;
            },
        },


        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 3,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Observações',
            field: 'observacoes',
            id: 4,
            width: 600,
            headerClassName: 'cabecalho_azul',
        },

    ];



    async function adicionarItem() {

        if (!insumoSelecionado) {
            if (descricaoNovoInsumo.length <= 0) {
                alert("Selecione o Insumo ou Insira a descrição para um novo Insumo!");
                return;
            } else {

            }

        }

        if (!quantidade || parseInt(quantidade) <= 0) {
            alert("Informe a quantidade!");
            return;
        }

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                observacoes: observacoesItem,
                quantidade: parseInt(quantidade),
                insumo: insumoSelecionado !== null ? { id_insumo: insumoSelecionado.id_insumo } : null,
                descricao: descricaoNovoInsumo,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.put("/v1/protected/ordensdecompras/editarrequerimento/" + idOrdemCompra + "/inseriritem", cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Adicionado!");
                listarInformacoes();

                setInsumoSelecionado(null);
                setQuantidade(0);
                setObservacoesItem('');
                setDescricao('');

            } else {
                alert("Erro de Conexão 2 tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão 3, tente novamente mais tarde");

        }




    }




    async function handleClickRemocao(event, cellValues) {



        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.delete("/v1/protected/ordensdecompras/editarrequerimento/" + idOrdemCompra + "/removeritem/" + cellValues.row.id_item_requerimento,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Removido!");
                listarInformacoes();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


    };






    return (
        <div style={{ width: '100%', margin: 0, padding: 0 }}>

            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
                style={{ width: '100%', margin: 0, padding: 0 }}

            />

            <div style={{ paddingTop: 5, width: '100%', margin: 0, backgroundColor: 'white' }} >
                {loading
                    ?
                    <div></div>

                    :

                    <div style={{ width: '100%', margin: 0, padding: 0 }}>

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                            style={{ width: '100%', margin: 0, padding: 0 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ padding: 5 }}
                            >
                                <a href={"/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra} style={{ fontSize: 14, color: 'black', marginLeft: '5px' }}>{"< Ordem de Compra nº " + idOrdemCompra}</a>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Requerimento de Insumos" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>


                            <SwipeableViews
                                index={value}
                                onChangeIndex={handleChangeIndex}
                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                            >



                                <Grid value={value} index={0}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >



                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ padding: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={8}
                                        >

                                            <Autocomplete
                                                id="select-insumo"
                                                options={insumos}
                                                disableCloseOnSelect
                                                getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                value={insumoSelecionado}
                                                getOptionSelected={(option, value) => true}
                                                onChange={(e, v) => setInsumoSelecionado(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((insumo) => {
                                                        const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                        return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Insumo " multiline rows={6} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                )}
                                            />
                                        </Grid>


                                        <Grid
                                            container
                                            item xs={2}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <TextField
                                                variant="standard"
                                                name="quantidade"
                                                label="Quantidade"
                                                id="quantidade"
                                                value={quantidade}
                                                onChange={(e) => setQuantidade(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid
                                            container
                                            item xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ paddingTop: 10 }}
                                        >
                                            <TextField
                                                variant="standard"
                                                name="observacoesItem"
                                                label="Observações"
                                                id="observacoesItem"
                                                value={observacoesItem}
                                                onChange={(e) => setObservacoesItem(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={2}
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            item xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ paddingTop: 10 }}
                                        >
                                            <TextField
                                                variant="standard"
                                                name="descricaoNovoInsumo"
                                                label="Descrição para Novo Insumo"
                                                id="descricaoNovoInsumo"
                                                value={descricaoNovoInsumo}
                                                onChange={(e) => setDescricaoNovoInsumo(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={2}
                                            />
                                        </Grid>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            item xs={2}
                                        >
                                            <Button style={{ marginTop: 5 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={(ev) => adicionarItem()}
                                            > Adicionar Item  </Button>
                                        </Grid>





                                    </Grid>







                                    <Grid
                                        style={{ paddingTop: 5 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {
                                            ordemCompra.itens_pre_requerimento &&
                                            <>
                                                <DataGrid
                                                    pagination
                                                    checkboxSelection
                                                    rows={ordemCompra.itens_pre_requerimento}
                                                    getRowId={(row) => row.id_item_requerimento}
                                                    columns={columnsDataGrid}
                                                    disableColumnResize={false}
                                                    rowHeight={40}
                                                    onCellClick={handleCellClick}
                                                    onRowClick={handleRowClick}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                />

                                            </>
                                        }



                                    </Grid>


                                </Grid>



                            </SwipeableViews>



                        </Grid>

                    </div>
                }
            </div>

        </div >
    );
}


