import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import { useHistory, useParams } from "react-router-dom";


import Skeleton from '@material-ui/lab/Skeleton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import { styled } from '@material-ui/styles';



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function ConfigurarControladorAcesso() {

    const classes = useStyles();
    const history = useHistory();
    const { idControladorAcesso } = useParams();


    const [loading, setLoading] = useState(true);
  
    const [disparar_acesso_negado, setDispararAcessoNegado] = useState(true);
    const [acionar_rele1, setAcionarRele1] = useState(true);
    const [acionar_rele2, setAcionarRele2] = useState(true);
    const [tempo_acionamento_rele1, setTempoAcionamentoRele1] = useState(true);
    const [multiplicador_rele1, setMultiplicadorRele1] = useState(true);
    const [tempo_acionamento_rele2, setTempoAcionamentoRele2] = useState(true);
    const [multiplicador_rele2, setMultiplicadorRele2] = useState(true);

   

    const [time, setTime] = useState(15);
    const [habilitarBotao, setHabilitarBotao] = useState(true);
    const [alertOpen, setAlertOpen] = useState(false);
    const [started, setStarted] = useState(false);
    const [textoConfiguracao, setTextoConfiguracao] = useState("");

    const [idAcao, setIdAcao] = useState(0);
    


    useEffect(() => {
        let timer;
        if (started && time > 0) {
            timer = setInterval(() => {
                setTime((time) => time - 1);
            }, 1000);
        }

        if (time === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [time, started]);


    useEffect(() => {
        if (time === 0) {
            verificarPorAcaoRespondida(idAcao)
        }
    }, [time,idAcao]);


    const handleOpenAlert = () => {
        setTime(15);
        setStarted(true);
        setAlertOpen(true);
    };

    const handleCloseAlert = () => {

        setStarted(false);
        setAlertOpen(false);

        history.push({
            pathname: '/controladoresacesso',
        })

    }


    
    async function verificarPorAcaoRespondida(idAcao) {
        try {


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.get('/v1/protected/acao/verificarrespondida/' + idAcao,
                { headers: headers });

            console.log("resposta: " + response.data);

            if (response.data) {
                console.log('Ação Respondida');
                setTextoConfiguracao("As novas configurações foram salvar no Controlador de Acesso");

                setHabilitarBotao(false);
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }
    }


    
    async function atualizarConfiguracoes() {

        if (window.confirm("Deseja atualizar as configurações? \n\nCuidado, configurações incorretas podem surtir efeitos negativos, só realize as configurações perto do controlador de acesso!")) {

            try {

                console.log("cadastrar chamado");

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }
                const parametrosConfiguracao = {
                    disparar_acesso_negado: disparar_acesso_negado,
                    acionar_rele1: acionar_rele1,
                    acionar_rele2: acionar_rele2,
                    tempo_acionamento_rele1: tempo_acionamento_rele1,
                    multiplicador_rele1: multiplicador_rele1,
                    tempo_acionamento_rele2: tempo_acionamento_rele2,
                    multiplicador_rele2: multiplicador_rele2,

                }

                const response = await api.put('/v1/protected/controladoresacesso/configurar/' + idControladorAcesso, parametrosConfiguracao,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {

                    setIdAcao(cadastro_salvo);
                    setTextoConfiguracao("A Ação de Configuração foi enviada ao banco de dados, aguarde alguns segundos pela resposta do Controlador de Acesso\nId da Requisição: " + cadastro_salvo);
                    handleOpenAlert();


                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }
        }



    }




    useEffect(() => {

        async function listarControladorAcesso() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/controladoresacesso/listar/" + idControladorAcesso, {
                    headers: headers
                }).then(function (response) {

                    var ctrl = response.data;

                    setDispararAcessoNegado(ctrl.disparar_acesso_negado);
                    setAcionarRele1(ctrl.acionar_rele1);
                    setAcionarRele2(ctrl.acionar_rele2);
                    setTempoAcionamentoRele1(ctrl.tempo_acionamento_rele1);
                    setTempoAcionamentoRele2(ctrl.tempo_acionamento_rele2);
                    setMultiplicadorRele1(ctrl.multiplicador_rele1);
                    setMultiplicadorRele2(ctrl.multiplicador_rele2);

                    setLoading(false);

                });




            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

                console.log("Erro ao listar a controlador de acesso: " + _err)

            }

        }
        listarControladorAcesso();


    }, [idControladorAcesso]);

    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Editar Configurações"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Paper elevation={5} style={{ paddingBottom: 20 }} >
                                    <Typography variant="h6" component="h2" style={{ margin: 20, fontWeight: 'bold', paddingTop: 20 }}>
                                        Parâmetros do Configuração do Controlador de Acesso
                                    </Typography>

                                    <Grid container spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                        style={{ margin: 20 }}
                                    >
                                        <Grid item xs={4}>
                                            <Typography style={{ textAlign: 'right' }}>
                                                Disparar Zona ao Acesso negado?:
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8} >
                                            <FormControl>
                                                <RadioGroup
                                                    name="controlled-radio-buttons-group"
                                                    value={disparar_acesso_negado}
                                                    onChange={(e) => setDispararAcessoNegado(Number( e.target.value))}
                                                >
                                                    <FormControlLabel value={0} control={<Radio />} label="Não" />
                                                    <FormControlLabel value={1} control={<Radio />} label="Sim" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                        style={{ margin: 20 }}
                                    >
                                        <Grid item xs={4}>
                                            <Typography style={{ textAlign: 'right' }}>
                                                Habilitar Relé 1?:
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8} >
                                            <FormControl>
                                                <RadioGroup
                                                    name="controlled-radio-buttons-group"
                                                    value={acionar_rele1}
                                                    onChange={(e) => setAcionarRele1( Number(e.target.value))}

                                               >
                                                    <FormControlLabel value={0} control={<Radio />} label="Não" />
                                                    <FormControlLabel value={1} control={<Radio />} label="Sim" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid
                                            item xs={12} sm={12} md={4} lg={4} xl={4}
                                            alignItems="center"
                                            style={{ margin: 20 }}
                                        >
                                            <TextField
                                                variant="standard"
                                                name="tempo_acionamento_rele1"
                                                label="Tempo de Acionamento do Relé 1(em milissegundos)"
                                                id="tempo_acionamento_rele1"
                                                value={tempo_acionamento_rele1}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 3 && Number(value) <= 255) {
                                                        setTempoAcionamentoRele1(value);
                                                    }
                                                  }}
                                                                                                
                                                inputProps={{
                                                    maxLength: 3, 
                                                    min: 1, 
                                                    max: 255, 
                                                    type: "number",
                                                  }}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid
                                            item xs={12} sm={12} md={4} lg={4} xl={4}
                                            style={{ margin: 20 }}
                                        >
                                            <TextField
                                                variant="standard"
                                                name="multiplicador_rele1"
                                                label="Multiplicador Tempo Relé 1"
                                                id="multiplicador_rele1"
                                                value={multiplicador_rele1}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 3 && Number(value) <= 255) {
                                                        setMultiplicadorRele1(value);
                                                    }
                                                  }}
                                                inputProps={{
                                                    maxLength: 3, 
                                                    min: 1, 
                                                    max: 255, 
                                                    type: "number",
                                                  }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                        style={{ margin: 20 }}
                                    >
                                        <Grid item xs={4}>
                                            <Typography style={{ textAlign: 'right' }}>
                                                Habilitar Relé 2?:
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8} >
                                            <FormControl>
                                                <RadioGroup
                                                    name="controlled-radio-buttons-group"
                                                    value={acionar_rele2}
                                                    onChange={(e) => setAcionarRele2(Number( e.target.value))}
                                                    >
                                                    <FormControlLabel value={0} control={<Radio />} label="Não" />
                                                    <FormControlLabel value={1} control={<Radio />} label="Sim" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>


                                    <Grid container
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                        style={{ margin: 20 }}
                                    >
                                        <Grid
                                            item xs={12} sm={12} md={4} lg={4} xl={4}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="tempo_acionamento_rele2"
                                                label="Tempo de Acionamento do Relé 2(em milissegundos)"
                                                id="tempo_acionamento_rele2"
                                                value={tempo_acionamento_rele2}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 3 && Number(value) <= 255) {
                                                      setTempoAcionamentoRele2(value);
                                                    }
                                                  }}
                                                                                                  fullWidth
                                                inputProps={{
                                                    maxLength: 3, 
                                                    min: 1, 
                                                    max: 255, 
                                                    type: "number",
                                                  }}
                                            />
                                        </Grid>


                                        <Grid
                                            item xs={12} sm={12} md={4} lg={4} xl={4}
                                            style={{ margin: 20 }}
                                        >
                                            <TextField
                                                variant="standard"
                                                name="multiplicador_rele2"
                                                label="Multiplicador Tempo Relé 2"
                                                id="multiplicador_rele2"
                                                value={multiplicador_rele2}
                                                fullWidth
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 3 && Number(value) <= 255) {
                                                        setMultiplicadorRele2(value);
                                                    }
                                                  }}
                                                inputProps={{
                                                    maxLength: 3, 
                                                    min: 1, 
                                                    max: 255, 
                                                    type: "number",
                                                  }}
                                            />
                                        </Grid>
                                    </Grid>



                                    <Grid item xs={12}
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        style={{ padding: 30 }}>


                                        <Grid item xs={1} >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={atualizarConfiguracoes}
                                            > Atualizar  </Button>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >

            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle id="confirm-dialog">{ }</DialogTitle>
                <DialogContent>
                    <div>
                        <AppBar sx={{ position: 'relative' }}>

                            <Grid container spacing={1} style={{ padding: 20 }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span>
                                    {textoConfiguracao}
                                </span>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    disable={habilitarBotao}
                                    onClick={handleCloseAlert}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {time > 0 ? time : "OK"}
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >

        </div >
    );
}


