import React, { useEffect, useState } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import Grid from '@material-ui/core/Grid';


import banner1_solucoes from '../../assets/imgs/banner1_solucoes.png';
import banner2_solucoes from '../../assets/imgs/banner2_solucoes.png';
import banner3_solucoes from '../../assets/imgs/banner3_solucoes.png';
import manutencao_alarmes from '../../assets/imgs/manutencao_alarmes.png';
import cftv from '../../assets/imgs/cftv.png';
import controle_acesso from '../../assets/imgs/controle_acesso.png';
import cerca_eletrica from '../../assets/imgs/cerca_eletrica.png';
import seguranca_com_monitoramento from '../../assets/imgs/seguranca_com_monitoramento.png';

import capa_top from '../../assets/imgs/capa_top.png';


import video_capa1 from '../../assets/videos/video_capa1.mp4';
import Carousel from 'react-bootstrap/Carousel'


import logo_seguranca_eletronica from '../../assets/imgs/logo_seguranca_eletronica.png';

import logo_auto_center from '../../assets/imgs/logo_auto_center.png';
import logo_cursos from '../../assets/imgs/logo_cursos.png';
import logo_refrigeracao from '../../assets/imgs/logo_refrigeracao.png';
import logo_educacao from '../../assets/imgs/logo_educacao.png';


import './styles.scss';
import { Typography } from '@material-ui/core';

const Solucoes = (props) => {




  const [width, setWidth] = useState(0);


  function checkDimenssoes() {


    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;



    setWidth(largura)

  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });



  useEffect(() => {



    checkDimenssoes();



  }, []);











  return (
    <div >


      <Navegador solucoes={'underline'} corFundo={'white'} travado={true} corTexto={'black'} />




      <Carousel interval={10000} fade

      >
        <Carousel.Item
        >
          <div style={{
            backgroundImage: `url(${banner1_solucoes})`,
            backgroundSize: width < 500 ? "auto" : "cover" ,
            width: '100%',
            height: '100%',

          }}  >

            <div style={{ backgroundColor: 'rgba(3,0,15,0.8)', color: 'white' }}>
              <Grid
                container
                direction="row"
                item xs={12} sm={12} md={12} lg={12} xl={12}
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ paddingLeft:  width < 500 ? 20 : 300 , paddingTop:  width < 500 ? 20 : 150 , paddingBottom:  width < 500 ? 20 : 100 , textAlign: width < 500 ? 'center' : null }}
              >
                <Typography>
                  <span style={{ fontWeight: 'bold', fontSize: '55px' }}> Segurança, Tecnologia e </span>
                  <p></p>
                  <span style={{ fontWeight: 'bold', fontSize: '55px', color: 'orange' }}> Inovação</span>

                </Typography>


              </Grid>

            </div>
          </div>
        </Carousel.Item>



      </Carousel>




      <div>
      <Grid
          container
          direction="row"
          item xs={12} sm={12} md={12} lg={12} xl={12}
          justifyContent="center"
          alignItems="center"
          style={{ padding: 20, paddingTop: 50 }}
        >
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}

            container
            alignItens="center"
            justifyContent="center"
            style={{ fontSize: width < 500 ? 14 : 28, textAlign: 'center', whiteSpace: 'pre-wrap' }}
          >

            <span >
              O <span style={{ color: 'DarkRed', fontWeight: 'bold' }}>Grupo</span> <span style={{ color: 'black', fontWeight: 'bold' }}>Rosinetos </span>
              com sua excelência em prestação de serviços, completa 16 anos de atuação na área de{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Tecnologia da Informação</span>,{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Segurança Eletrônica</span>,{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Refrigeração</span>,{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Educação</span>  e{" "}
              <span style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>Automotivo</span>
              , desenvolvendo soluções{" "}
              <span style={{ color: 'black', fontWeight: 'bold' }}>inovadoras</span>{" "}
              que agregam resultado, credibilidade e total satisfação de nossos clientes.
            </span>

          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          </Grid>




        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
          justifyContent='center'
          alignItens='center'
          direction='row'
          container
          style={{ paddingTop: width < 500 ? 10 : 50, paddingBottom: width < 500 ? 14 : 50, paddingInline: 10 }}

        >

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
            justifyContent='center'
            alignItens='center'
            direction='row'
            container

          >
            <video controls poster={capa_top} >
              <source src={video_capa1} type="video/mp4" />
            </video>
          </Grid>

        </Grid>
      </div>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container

          style={{paddingTop: width < 500 ? 10 : 50}}
      >



        <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"# Áreas de atuação"}  </span>
        </div>

      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container


        style={{ paddingTop: width < 500 ? 10 : 50, paddingBottom: width < 500 ? 10 : 50 }}

      >
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}
          justifyContent='center'
          alignItens='center'
          direction='row'
          container
          spacing={2}
        >

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className={"imagemlogos"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }} >
              <img alt={"alarmes"} height={'100px'} className={"imagem"} style={{ textAlign: 'center' }} src={logo_seguranca_eletronica}></img>
            </div>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className={"imagemlogos"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"cftv"} style={{ textAlign: 'center' }} src={logo_refrigeracao}></img>
            </div>
          </Grid>


          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className={"imagemlogos"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"cerca_eletrica"} style={{ textAlign: 'center' }} src={logo_auto_center}></img>
            </div>
          </Grid>


          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
            <div className={"imagemlogos"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"controle_acesso"} style={{ textAlign: 'center' }} src={logo_cursos}></img>
            </div>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
            <div className={"imagemlogos"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"logo_educacao"} style={{ textAlign: 'center' }} src={logo_educacao}></img>
            </div>
          </Grid>

        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        </Grid>


      </Grid>






      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
          style={{paddingTop: width < 500 ? 10 : 100, paddingBottom: width < 500 ? 10 : 100}}

      >



        <div className={width < 500 ? null : "span2"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
          <span className='' style={{
            backgroundColor: 'white',
            color: 'white',
            WebkitTextStroke: '1px #084669',
            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
            whiteSpace: 'pre-wrap'
          }} >{"# Nossas Soluções"}  </span>
        </div>

      </Grid>


      <Grid 
       id={"seguranca_eletronica"}
      item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 50, paddingBottom: width < 500 ? 10 : 50 }}

      >
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div style={{ display: 'flex', justifyContent: 'center', paddingInline: width < 500 ? 20 : 0 }}>
            <img alt={"banner2_soculoes"} style={{ textAlign: 'center' }} src={banner2_solucoes}></img>
          </div>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        </Grid>
      </Grid>

      




      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container


        style={{ paddingTop: width < 500 ? 10 : 50, paddingBottom: width < 500 ? 10 : 50 }}

      >
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}
          justifyContent='center'
          alignItens='center'
          direction='row'
          container
          spacing={2}
        >

          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <div className={"imagem"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }} >
              <img alt={"alarmes"} height={'100px'} className={"imagem"} style={{ textAlign: 'center' }} src={manutencao_alarmes}></img>
            </div>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <div className={"imagem"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"cftv"} style={{ textAlign: 'center' }} src={cftv}></img>
            </div>
          </Grid>


          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <div className={"imagem"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"cerca_eletrica"} style={{ textAlign: 'center' }} src={cerca_eletrica}></img>
            </div>
          </Grid>


          <Grid item xs={6} sm={6} md={6} lg={3} xl={3} >
            <div className={"imagem"} style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
              <img alt={"controle_acesso"} style={{ textAlign: 'center' }} src={controle_acesso}></img>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        </Grid>

      </Grid>

      <div  style={{ display: 'flex', justifyContent: 'center', margin: 0, paddingInline: width < 500 ? 10 : 50 }}>
        <img alt={"seguranca_com_monitoramento"} style={{ textAlign: 'center' }} src={seguranca_com_monitoramento}></img>
      </div>



      <Grid 
       id={"refrigeracao"}
      item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent='center'
        alignItens='center'
        direction='row'
        container
        style={{ paddingTop: width < 500 ? 10 : 50, paddingBottom: width < 500 ? 10 : 50 }}

      >
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div style={{ display: 'flex', justifyContent: 'center', paddingInline: width < 500 ? 20 : 0 }}>
            <img alt={"banner3_soculoes"} style={{ textAlign: 'center' }} src={banner3_solucoes}></img>
          </div>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        </Grid>
      </Grid>

    

      <div >
        <Rodape />
      </div>
    </div >
  );
}

export default Solucoes;