import React, { useState, useEffect ,useMemo} from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';
import {
    MaterialReactTable,

} from 'material-react-table';
import {
    Box,
    Tooltip
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';



import api from '../../../../../services/api';



import NavegadorGestao from "../../../../../components/NavBarGestao";


import { Button } from "@material-ui/core";

import Typography from '@material-ui/core/Typography';


export default function Fornecedores() {


    const { tipoMenuRender } = useParams();


    const [loading, setLoading] = useState(true);
    const [fornecedores, setFornecedores] = useState([]);



    async function listarFornecedores() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/fornecedores/listarTodos", {

                headers: headers
            }).then(function (response) {

                const updatedData = response.data.map(item => {
                    // Modificar o atributo 'status'
                    item.status = item.status === 1 ? 'ATIVO' : 'DESATIVADO';

                    item.tipo_identificacao = item.tipo_fornecedor === 0 ? item.cpf : item.cnpj;

                    item.nome_pesquisa = item.tipo_fornecedor === 0 ?
                    ( (item.nome ? item.nome : "")  + " " + (item.sobrenome ?  item.sobrenome : "")  ) :
                    ( (item.nome_fantasia ? (item.razao_social ? item.razao_social : "") : ""  ));

                    item.endereco_completo =  item.logradouro + ", nº " + item.numero + ", " + item.bairro + ", " + item.cidade + " " + item.estado + " CEP: " +item.cep

                    return item;
                });

                setFornecedores(updatedData);


                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar fornecedores: " + _err)

        }

    }


    useEffect(() => {



        listarFornecedores();


    }, []);



    function novoFornecedor() {

        if (parseInt(tipoMenuRender, 10) === 1)
            window.location.href = "/gestao/operacional/almoxarifado/cadastrarfornecedor/" + tipoMenuRender;

    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    
    const handleClickGerenciar = (row) => {


        window.location.href = "/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/" + row.id_fornecedor;


    };





    

    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_fornecedor', //normal accessorKey
                header: 'ID',
                size: 60,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 80,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },

            {
                header: 'CPF/CNPJ',
                size: 150,
                accessorKey: 'tipo_identificacao',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span >
                            {renderedCellValue}
                        </span>
                    )
                },
            },

            {
                header: 'Nome',
                accessorKey: 'nome_pesquisa',
                size: 350,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span>
                            {renderedCellValue}
                        </span>
                    )

                },
            },

            {
                header: 'Endereço',
                accessorKey: 'endereco_completo',
                size: 450,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span style={{ fontSize: 12 }} >
                            {
                                renderedCellValue
                            }
                        </span>
                    )

                },
            },



        ],
        [],
    );

    

    



    return (
        <div>


            <div >
                {parseInt(tipoMenuRender, 10) === 1 ?
                    <NavegadorGestao
                        Almoxarifado={true}
                        Fornecedores={true}
                        corFundo={'white'} travado={true} corTexto={'black'}
                    /> : null

                }


                <div style={{ padding: 5 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item xs={12}
                    >
                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={novoFornecedor}
                        >
                            Novo Fornecedor
                        </Button>
                    </Grid>
                    {loading ?
                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                        </Skeleton>
                        :


                        <div>
                            {fornecedores && (
                            <MaterialReactTable
                                columns={columns}
                                data={fornecedores}
                                enableGlobalFilterModes
                                enablePagination={true}
                                enableRowActions
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_fornecedor}
                                enableTopToolbar={true}
                                enableBottomToolbar={false}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={true}
                                enableSorting={true}
                                enableRowSelection={false}


                                enableColumnFilterModes={true}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}




                                initialState={{
                                    pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                    showGlobalFilter: true,
                                    density: 'compact',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                muiPaginationProps={{
                                    rowsPerPageOptions: [50, 100, 200],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            left: '30px',
                                            maxWidth: '1000px',
                                            position: 'sticky',
                                            width: '100%',
                                        }}
                                    >
                                    </Box>
                                )}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                       
                                        <Tooltip title="Gerenciar">
                                            <IconButton color="error" onClick={() => handleClickGerenciar(row.original)}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                )}
                            />
                        )}
                        </div>
                    }


                </div>

            </div>


        </div>
    );
}


