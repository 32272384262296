import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { format, addDays, isValid, parse } from 'date-fns';

import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/styles';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import api from '../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import Tooltip from '@mui/material/Tooltip';

import { AddToQueueOutlined } from '@material-ui/icons';


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));






export default function GerenciarTratamentoEmCampo() {


    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [ordemServico, setOrdemServico] = useState();
    const { idOrdemServico } = useParams();

    const [solicitante, setSolicitante] = useState(null);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(null);
    const [solicitacoes, setSolicitacoes] = useState(null);
    const [instalacoes, setInstalacoes] = useState(null);

    const [procedimentosTratamentoEmCampo, setProcedimentosTratamentoEmCampo] = useState(null);

    const [insumos, setInsumos] = useState(null);

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordemservico/listarporid/" + idOrdemServico, {
                headers: headers
            }).then(function (response) {
                setOrdemServico(response.data)
                setDescricao(response.data.descricao)
                setSolicitante(response.data.solicitante)
                setSolicitacaoSelecionada(response.data.solicitacao);
                setInstalacaoSelecionada(response.data.instalacao);

                setInsumos(response.data.insumos);

                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);

    const finalizarAnalise = () => {

        const result = verificarObrigatorioStatus(ordemServico);
        if (result) {
            alert("Existem Procedimentos Obrigatórios Pendentes, atualize-os!")
        } else {
            handleOpen();

        }
    }



    function novoProcedimento(etapaProcedimento) {
        window.location.href = "/gestao/administracao/ordensdeservicos/cadastrarprocedimentoavulso/" + idOrdemServico + "/" + etapaProcedimento + "/1";

    }


    const handleClickAtualizarProcedimento = (procExec) => {

        const id = procExec.id_procedimento_execucao;

        if (procExec.procedimento.id_procedimento === 1) {
            //definir equipe
            window.location.href = "/gestao/operacional/agendamentos/cadastrar/1/" + idOrdemServico + "/" + id;
        } else if (procExec.procedimento.id_procedimento === 2) {
            //definir veiculo
            window.location.href = "/gestao/operacional/agendamentos/cadastrar/0/" + idOrdemServico + "/" + id;
        }


        else if (procExec.procedimento.id_procedimento === 5) {
            //definir agendamento
            //verificar se procedimento de definir equipe esta concluído

            var equipe_definida = false;

            ordemServico.proc_requisitos_tratamento_em_campo.map((item, index) => {
                // Verifica se o ID do procedimento é igual a 1 e o status é igual a 1
                if (item.procedimento.id_procedimento === 1 && item.procedimento.status === 1) {
                    equipe_definida = true;
                }
            });

            if (equipe_definida) {
                window.location.href = "/gestao/administracao/ordensdeservicos/atualizarprocedimentoexecucao/" + id + "/ordemservico/" + ordemServico.id_ordem_servico + "/1";

            } else {
                alert("Defina a equipe antes de definir o agendamento!");
                return null;
            }

        }
        else {
            window.location.href = "/gestao/administracao/ordensdeservicos/atualizarprocedimentoexecucao/" + id + "/ordemservico/" + ordemServico.id_ordem_servico + "/1";

        }


    };


    const handleClickVizualizarProcedimento = (id) => {
        window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentoexecucaotratamentoemcampo/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };


    const handleClickVizualizarProcedimentoTratamentoRemoto = (id) => {
        window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentotratamentoremoto/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    function returnDescricaoSolicitacao(solicitacao) {
        var texto = "Solicitação: " + solicitacao.solicitacao + "\n Descrição: " + solicitacao.descricao;
        return texto;
    }

    function handleSetInstalacao(instalacao) {
        setInstalacaoSelecionada(instalacao);
    }

    function handleSetSolicitacao(solicitacao) {
        setSolicitacaoSelecionada(solicitacao);
    }


    function handleSolucaoTratamentoRemoto(event) {
        setSolucaoTratamentoRemoto(event.target.value);
    }




    const [open, setOpen] = useState(false);
    const [openTratamentoEmCampo, setOpenTratamentoEmCampo] = useState(false);

    const [descricaoAnalise, setDescricaoAnalise] = useState("");

    const [openTratamentoRemoto, setOpenTratamentoRemoto] = useState(false);
    const [descricaoTratamentoRemoto, setDescricaoTratamentoRemoto] = useState("");
    const [solucaoTratamentoRemoto, setSolucaoTratamentoRemoto] = useState(0);

    const [descricaoTratamentoEmCampo, setDescricaoTratamentoEmCampo] = useState("");

    const handleOpen = (event, cellValues) => {

        setOpen(true);

    }


    const handleClose = () => {
        setOpen(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };


    const handleOpenTratamentoRemoto = (event, cellValues) => {

        setOpenTratamentoRemoto(true);

    }


    const handleCloseTratamentoRemoto = () => {
        setOpenTratamentoRemoto(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };

    const handleOpenTratamentoEmCampo = (event, cellValues) => {

        setOpenTratamentoEmCampo(true);

    }


    const handleCloseTratamentoEmCampo = () => {
        setOpenTratamentoEmCampo(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };

    const verificarObrigatorioStatus = (ordemServico) => {
        return ordemServico.procedimentos_tratamento_em_campo.some((procedimento) => {
            return procedimento.obrigatorio === 1 && procedimento.status === 0;
        });
    };





    async function handleFinalizar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao_analise: descricaoAnalise,
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/finalizarAnalise/' + idOrdemServico, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }


    const resultadoTratamentoEmCampo = () => {
        const status_ordem = ordemServico.status_ordem;
        const solucionado = ordemServico.solucionado_tratamento_remoto;

        let texto_status = "";

        if (status_ordem === 2) {
            texto_status = "Status: Tratamento em Campo";
        } else if (status_ordem > 2) {
            //tratamento remoto avançado
            if (solucionado === 1) {
                texto_status = "Status: Tratamento Remoto Finalizado Solucionando a Solicitação";

            } else {
                texto_status = "Status: Tratamento Remoto Finalizado | Necessário Tratamento Em Campo";

            }
        }



        return (

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item xs={12}
                style={{ padding: 30 }}
            >

                <Typography variant="h5">
                    {texto_status} <br />
                    {`às ${moment(ordemServico.data_hora_finalizacao_tratamento_em_campo).format('HH:mm:ss DD/MM/YYYY')}`}
                </Typography>

            </Grid>
        )
    }


    async function handleFinalizarTratamentoEmCampo() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao_tratamento_remoto: descricaoTratamentoRemoto,
                solucionado_tratamento_remoto: solucaoTratamentoRemoto,
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/finalizartratamentoremoto/' + idOrdemServico, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };



    function returnDescricaoCotacao(cotacao) {

        var texto =
            "Insumo: " +
            cotacao.insumo.nome +
            "\nMarca/Modelo: " + cotacao.insumo.marca + "/" + cotacao.insumo.modelo +
            "\nCódigo Fornecedor: " + cotacao.insumo.codigo_fornecedor +
            "\nDescrição: " + cotacao.insumo.descricao +
            "\nUnidade: " +
            cotacao.unidade +
            "\nData Cotação: " +
            cotacao.data;
        return texto;
    }

    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }

    function returnDescricaoFornecedor(fornecedor) {
        var texto = "Fornecedor_ID: " + fornecedor.id_fornecedor + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\nNome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }

    function returnDescricaoOrdemCompra(ordem) {
        var texto = "Ordem_ID: " + ordem.id_ordem_compra + "\nFornecedor: " + returnDescricaoFornecedor(ordem.fornecedor) + "\nDescrição da Ordem: " + ordem.descricao + "\nData prevista de entrega: " + ordem.data_prevista_entrega;
        return texto;
    }


    function returnDescricaoEntradaEstoque(entrada_estoque) {
        var texto = "Entrada_Estoque_ID: " + entrada_estoque.id_entrada_estoque;
        return texto;
    }


    const handleClickEdicao = (event, cellValues) => {


        window.location.href = "/gestao/operacional/almoxarifado/estoque/gerenciaritemestoque/" + cellValues.row.id_item_estoque;


    };



    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <ColorButtonGreen variant="contained" color="primary"
                        onClick={(event) => handleClickEdicao(event, cellValues)}
                    >
                        Gerenciar
                    </ColorButtonGreen>
                )
            },
        },


        {
            headerName: 'ID',
            field: 'id_item_estoque',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },


        {
            headerName: 'Insumo',
            field: 'cotacao',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {

                var cotacao = params.row.item.cotacao;
                return returnDescricaoCotacao(cotacao);

            },
        },

        {
            headerName: 'Status',
            field: 'status',
            id: 3,
            width: 150,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {

                var status_item = params.row.status_item;
                return returnDescricaoStatus(status_item);

            },
        },


        {
            headerName: 'Saída Estoque',
            field: 'saida_estoque',
            id: 4,
            width: 150,
            headerClassName: 'cabecalho_azul',


        },

    ];



    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <Container style={{ paddingTop: 5 }}>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <React.Fragment >

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ padding: 5 }}
                            >

                                <a href={"/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Ordens de Serviço nº " + idOrdemServico}</a>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Requisitos" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Itens" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Procedimentos Técnicos em Campo" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Financeiro" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >
                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid container spacing={2}
                                            item xs={12}
                                            style={{ padding: 30 }}
                                            rowSpacing={2}>
                                            {ordemServico.proc_requisitos_tratamento_em_campo.map((procedimento_tratamento_em_campo, index) => (
                                                <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                                                    {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                    <Card style={{ borderRadius: '10px', border: '1px solid #e0e0e0', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                        <Tooltip
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        border: '1px solid black', // Adiciona uma borda preta
                                                                        bgcolor: 'common.white',
                                                                        '& .MuiTooltip-arrow': {
                                                                            color: 'common.black',
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            title={
                                                                <div style={{ fontSize: 14, color: 'black' }}>
                                                                    {
                                                                        procedimento_tratamento_em_campo.procedimento.id_procedimento === 2 && procedimento_tratamento_em_campo.status === 1 ?
                                                                            <div style={{ fontSize: 14, color: 'black' }}>
                                                                                <strong>{"Veículo: " + ordemServico.agendamento_veiculo.veiculo.marca + "/" + ordemServico.agendamento_veiculo.veiculo.modelo + " " + ordemServico.agendamento_veiculo.veiculo.placa}</strong>
                                                                                <br></br>
                                                                                <strong>{"Escopo: De " + format(new Date(ordemServico.agendamento_veiculo.data_hora_inicial), 'HH:mm dd/MM/yyyy') + " até " + format(new Date(ordemServico.agendamento_veiculo.data_hora_final), 'HH:mm dd/MM/yyyy')}</strong>
                                                                            </div>

                                                                            :
                                                                            procedimento_tratamento_em_campo.procedimento.id_procedimento === 1 && procedimento_tratamento_em_campo.status === 1 ?
                                                                                <div style={{ fontSize: 14, color: 'black' }}>
                                                                                    <strong>{"Equipe: " + ordemServico.agendamento_equipe.equipe.nome}</strong>
                                                                                    <br></br>
                                                                                    <strong>{"Escopo: De " + format(new Date(ordemServico.agendamento_equipe.data_hora_inicial), 'HH:mm dd/MM/yyyy') + " até " + format(new Date(ordemServico.agendamento_equipe.data_hora_final), 'HH:mm dd/MM/yyyy')}</strong>
                                                                                    <br></br>
                                                                                    <br></br>
                                                                                    <strong>{"Integrantes:"}</strong>
                                                                                    <br></br>
                                                                                    {
                                                                                        ordemServico.agendamento_equipe.equipe.colaboradores.map((colaborador, index) => (
                                                                                            <span key={index}>
                                                                                                {colaborador.nome} {colaborador.sobrenome}
                                                                                                {index !== ordemServico.agendamento_equipe.equipe.colaboradores.length - 1 && ', '}
                                                                                            </span>
                                                                                        ))
                                                                                    }

                                                                                </div> :

                                                                                procedimento_tratamento_em_campo.procedimento.id_procedimento === 3 && procedimento_tratamento_em_campo.status === 1 ?
                                                                                    <div style={{ fontSize: 14, color: 'black' }}>
                                                                                        <strong>{"Rota: " + ordemServico.rota.descricao}</strong>
                                                                                        <br></br>
                                                                                        <strong>{"Escopo: "}</strong>
                                                                                        <br></br>
                                                                                        <br></br>
                                                                                        <strong>{"Origem " + ordemServico.rota.componentes_rota[0].endereco}</strong>
                                                                                        <br></br>
                                                                                        <br></br>
                                                                                        <strong>{"Destino " + ordemServico.rota.componentes_rota[1].endereco}</strong>
                                                                                        <br></br>
                                                                                        <br></br>
                                                                                        <a href={"/gestao/operacional/frota/rotas/vizualizarrota/" + ordemServico.rota.id_rota} target="_blank" rel="noopener noreferrer">
                                                                                            <button>Ver Rota</button>
                                                                                        </a>

                                                                                    </div> :
                                                                                    null
                                                                    }


                                                                </div>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            style={{ backgroundColor: 'white', color: 'black' }}
                                                        >
                                                            <CardContent>
                                                                <Link
                                                                    target="_blank"
                                                                    to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_tratamento_em_campo.procedimento.id_procedimento}`}
                                                                >
                                                                    <Typography variant="h6">{procedimento_tratamento_em_campo.procedimento.nome}</Typography>
                                                                </Link>
                                                                <br />
                                                                <Typography variant="h8">{"ID: " + (procedimento_tratamento_em_campo.id_procedimento_execucao)}</Typography>
                                                                <br />
                                                                <br />
                                                                <Typography variant="h8">{"Status: " + (procedimento_tratamento_em_campo.status === 0 ? "Não Executado" : "Concluído")}</Typography>
                                                                <br />
                                                                {
                                                                    procedimento_tratamento_em_campo.status === 1 ?
                                                                        <Typography variant="h8">{(procedimento_tratamento_em_campo.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                        :
                                                                        <Typography variant="h8"></Typography>

                                                                }
                                                                <br />
                                                                {
                                                                    procedimento_tratamento_em_campo.status === 1 ?
                                                                        <Typography variant="h8">
                                                                            {`Executado por ${procedimento_tratamento_em_campo.usuario_executor.nome_amigavel}`} <br />
                                                                            {`às ${moment(procedimento_tratamento_em_campo.data_hora_execucao).format('HH:mm:ss DD/MM/YYYY')}`}
                                                                        </Typography> :
                                                                        <Typography variant="h8"></Typography>

                                                                }
                                                                <br />
                                                                <Typography variant="h8">{(procedimento_tratamento_em_campo.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                                <br />  <br />
                                                                {
                                                                    procedimento_tratamento_em_campo.status === 0 ?
                                                                        <ColorButtonBlue variant="contained" color="primary"
                                                                            onClick={(event) => handleClickAtualizarProcedimento(procedimento_tratamento_em_campo)}
                                                                        >
                                                                            Definir
                                                                        </ColorButtonBlue>
                                                                        :
                                                                        <ColorButtonGreen variant="contained" color="primary"
                                                                            onClick={(event) => handleClickVizualizarProcedimento(procedimento_tratamento_em_campo.id_procedimento_execucao)}
                                                                        >
                                                                            Vizualizar
                                                                        </ColorButtonGreen>
                                                                }

                                                            </CardContent>
                                                        </Tooltip>
                                                    </Card>

                                                </Grid>
                                            ))}
                                        </Grid>


                                    </Grid>


                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <>
                                            <DataGrid
                                                pagination
                                                checkboxSelection
                                                rows={insumos}
                                                getRowId={(row) => row.id_item_estoque}
                                                columns={columnsDataGrid}
                                                disableColumnResize={false}
                                                onCellClick={handleCellClick}
                                                onRowClick={handleRowClick}
                                                components={{
                                                    Toolbar: CustomToolbar,
                                                }}
                                            />



                                        </>

                                    </Grid>

                                    <Grid value={value} index={2}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                            style={{ padding: 6 }}
                                        >

                                            <Typography variant="h7">{"Status: " + (ordemServico.status_ordem === 3 ? "Realizando Tratamento em Campo" : ordemServico.status_ordem >= 4 ? ("Tratamento em Campo Finalizado ás " + moment(ordemServico.data_hora_finalizacao_tratamento_em_campo).format('HH:mm:ss DD/MM/YYYY')) : null)}</Typography>
                                            <br></br>

                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                            style={{ padding: 6 }}
                                        >
                                            {ordemServico.status_ordem >= 4 &&
                                                <Typography variant="h7">{"Comentário de " + ordemServico.usuario_analista_finalizou_tratamento_em_campo.nome_amigavel + ": " + ordemServico.descricao_tratamento_em_campo}</Typography>
                                            }

                                        </Grid>
                                        {ordemServico.status_ordem === 3 ?

                                            <div></div>
                                            :
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Button style={{ marginTop: 5 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={(e) => handleFinalizarTratamentoEmCampo()}
                                                > Finalizar Tratamento em Campo  </Button>
                                            </Grid>}




                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                            style={{ padding: 10 }}
                                        >

                                            <Typography variant="h5">Procedimentos de Tratamento em Campo</Typography>


                                        </Grid>
                                        {ordemServico.status_ordem === 3 &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Button style={{ marginTop: 2 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={(e) => novoProcedimento(2)}
                                                > Novo Procedimento  </Button>
                                            </Grid>
                                        }

                                        <Grid container spacing={2}
                                            item xs={12}
                                            style={{ padding: 30 }}
                                            rowSpacing={2}>
                                            {ordemServico.procedimentos_tratamento_em_campo?.map((procedimento_analise, index) => (
                                                <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                                                    {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}

                                                    <Card style={{ borderRadius: '10px', border: '1px solid #e0e0e0', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>

                                                        <CardContent>
                                                            <Link
                                                                target="_blank"
                                                                to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                            >
                                                                <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                                            </Link>
                                                            <br /> <br /> <br />
                                                            <Typography variant="h8">{"Status: " + (procedimento_analise.status === 0 ? "Não Executado" : "Concluído")}</Typography>
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                    :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">
                                                                        {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                        {`às ${moment(procedimento_analise.data_hora_execucao).format('HH:mm:ss DD/MM/YYYY')}`}
                                                                    </Typography> :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br /> <br />
                                                            <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                            <br /> <br />
                                                            {
                                                                procedimento_analise.status === 0 ?
                                                                    <ColorButtonBlue variant="contained" color="primary"
                                                                        onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                    >
                                                                        Atualizar
                                                                    </ColorButtonBlue>
                                                                    :
                                                                    <ColorButtonGreen variant="contained" color="primary"
                                                                        onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                    >
                                                                        Vizualizar
                                                                    </ColorButtonGreen>
                                                            }

                                                        </CardContent>

                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>




                                    </Grid>

                                    <Grid
                                        value={value} index={3}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                    </Grid>









                                </SwipeableViews>
                            </Grid>
                        </Grid>





                    </React.Fragment >
                }


            </Container >




        </div >
    );
}


