import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import { useHistory } from "react-router-dom";





import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


export default function CadastrarConta() {

    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState(''); // Add this line

    const [grupoContas, setGrupoContas] = useState();
    const [grupoContasSelecionado, setGrupoContasSelecionado] = useState();



    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/financeiro/grupocontas/listartodos", {
                headers: headers
            }).then(function (response) {
                setGrupoContas(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {




        listar();


    }, []);


    async function salvar() {
        setEstadoBotaoSalvar(true);
        console.log("Salvar chamado");

        if (!grupoContasSelecionado) {
            alert("Selecione o Grupo de Contas!");
            setEstadoBotaoSalvar(false);
            return;
        }

        try {

            const cadastro = {

                nome: nome,
                descricao: descricao,
                grupo_contas: { id_grupo_contas: grupoContasSelecionado.id_grupo_contas },


            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/financeiro/contas/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Conta cadastrada com sucesso!")

                history.push({
                    pathname: '/gestao/financas/contas/listar',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
            setEstadoBotaoSalvar(false);
        }




    }

    function returnDescricaoGrupoContas(grupo) {
        const text = "ID: " + grupo.id_grupo_contas + "\nNome: " + grupo.nome + "\nDescrição: " + grupo.descricao;
        return text;
    }


    const handleGrupoContas = (event, grupo) => {

        setGrupoContasSelecionado(grupo);
    };




    return (
        <div>
            <NavegadorGestao
                Financas={true}
                TabelasAuxiliaresFinancas={true}
                Contas={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                        disabled={estadoBotaoSalvar}
                    > Salvar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={grupoContas}
                                            disableCloseOnSelect
                                            getOptionLabel={(grupo) => returnDescricaoGrupoContas(grupo).replace(/<br \/>/g, '\n')}
                                            value={grupoContasSelecionado}
                                            onChange={handleGrupoContas}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((grupo) => {
                                                    const cotacaoText = returnDescricaoGrupoContas(grupo).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Grupo de Contas " multiline rows={5} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoGrupoContas(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>



                                <Grid item xs={4} >
                                    <TextField
                                        variant="standard"
                                        name="nome"
                                        label="Nome"
                                        required
                                        id="nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        inputProps={{ maxLength: 50, minLength: 1 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={12}  >
                                    <TextField

                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={e => setDescricao(e.target.value)}
                                        inputProps={{ maxLength: 600, minLength: 0 }}
                                        fullWidth

                                    />
                                </Grid>





                            </Grid>




                        </Grid>


                    </React.Fragment>
                }


            </Container>

        </div >
    );
}


