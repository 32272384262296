import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';




import { useParams } from "react-router-dom";


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../../assets/imgs/miniatura_pdf.png';


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function CadastrarProposta() {


    const { idProjeto } = useParams();


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();

    const [descricaoProposta, setDescricaoProposta] = useState('');
    const [diasValidade, setDiasValidade] = useState('');
    const [diasInicioInstalacao, setDiasInicioInstalacao] = useState('');
    const [diasInstalacao, setDiasInstalacao] = useState('');
    const [nivelSla, setNivelSla] = useState('');


    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');

    const [area, setArea] = useState(0);

    const handleChange = (event) => {
        setArea(event.target.value);
    };


    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);


    const onDrop1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada1(acceptedFiles[0]);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada2(acceptedFiles[0]);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada3(acceptedFiles[0]);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada4(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });





    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            // Adicionar os dados do procedimento

            formData.append('descricao_proposta', descricaoProposta);
            formData.append('dias_validade', diasValidade);
            formData.append('dias_inicio_instalacao', diasInicioInstalacao);
            formData.append('dias_instalacao', diasInstalacao);
            formData.append('nivel_sla', nivelSla);



            formData.append('usuario_criador.id_usuario', Cookies.get('id_usuario'));



            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);


            formData.append('descricao_video1', descricaoVideo1);
            formData.append('descricao_video2', descricaoVideo2);
            formData.append('descricao_video3', descricaoVideo3);

            formData.append('descricao_arquivo1', descricaoArquivo1);
            formData.append('descricao_arquivo2', descricaoArquivo2);
            formData.append('descricao_arquivo3', descricaoArquivo3);



            // Adicionar as imagens ao FormData
            formData.append('imagens', imagemSelecionada1);
            formData.append('imagens', imagemSelecionada2);
            formData.append('imagens', imagemSelecionada3);
            formData.append('imagens', imagemSelecionada4);

            formData.append('videos', videoSelecionado1);
            formData.append('videos', videoSelecionado2);
            formData.append('videos', videoSelecionado3);

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('arquivos', arquivoSelecionado2);
            formData.append('arquivos', arquivoSelecionado3);


            const itensRawJSON = JSON.stringify(itens.map(item => ({
                id_item_proposta: item.id_item_proposta,
                descricao: item.descricao,
                unidade_medida: item.unidade_medida,
                quantidade: item.quantidade,
                quantidade_total: item.quantidade_total,
                periodicidade: item.periodicidade,
                valor_unitario: item.valor_unitario,
                valor_total: item.valor_total
            })));

            formData.append('itensRaw', itensRawJSON);



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/projetos/propostas/cadastrar/' + idProjeto, formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Proposta Cadastrada");
                history.push({
                    pathname: "/gestao/administracao/projetos/editarprojeto/" + idProjeto,
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const [clienteSelecionado, setClienteSelecionado] = useState();

    const [clientes, setClientes] = useState();

    const [loading, setLoading] = useState(true);

    const handleCliente = (event, cliente) => {

        setClienteSelecionado(cliente);
    };


    



    function returnDescricaoCliente(cliente) {
        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("CPF: " + cliente.cpf) : ("CNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia);

        return texto;
    }


    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("v1/protected/clientes/listarTodos", {
                headers: headers
            }).then(function (response) {
                setClientes(response.data)
                setLoading(false);

            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Clientes: " + _err)
        }
    }

    useEffect(() => {

        listarInformacoes();


    }, []);


    const handleChangeNivel = (event) => {
        setNivelSla(event.target.value);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    const [itens, setItens] = useState([]);


    const [descricaoItem, setDescricaoItem] = useState([]);
    const [unidadeItem, setUnidadeItem] = useState([]);
    const [quantidadeItem, setQuantidadeItem] = useState([]);
    const [quantidadeTotal, setQuantidadeTotal] = useState([]);
    const [periodicidade, setPeriodicidade] = useState([]);
    const [valorUnitario, setValorUnitario] = useState([]);
    const [valorTotal, setValorTotal] = useState([]);

    function novoItem() {
        // Obtém o próximo ID
        const novoId = itens.length + 1;

        // Cria um novo item com os valores das constantes
        const novoItem = {
            id_item_proposta: novoId,
            descricao: descricaoItem,
            unidade_medida: unidadeItem,
            quantidade: quantidadeItem,
            quantidade_total: quantidadeTotal,
            periodicidade: periodicidade,
            valor_unitario: valorUnitario,
            valor_total: valorTotal,
        };

        // Adiciona o novo item ao array de itens
        setItens([...itens, novoItem]);

        // Limpa os valores das constantes
        setDescricaoItem('');
        setUnidadeItem('');
        setQuantidadeItem('');
        setQuantidadeTotal('');
        setPeriodicidade('');
        setValorUnitario('');
        setValorTotal('');
    }


    const handleClickExcluirItemProposta = (event, cellValues) => {

        const id = cellValues.row.id_item_proposta;


        // Crie um novo array que exclua o item com o ID correspondente
        const novoArrayItens = itens.filter(item => item.id_item_proposta !== id);

        // Atualize o array de itens com o novo array
        setItens(novoArrayItens);

    };



    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <IconButton
                        color="primary"
                        onClick={(event) => handleClickExcluirItemProposta(event, cellValues)}
                    >
                        <DeleteIcon />
                    </IconButton>
                );
            },

        },
        {
            headerName: 'ID',
            field: 'id_item_proposta',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 2,
            width: 400,

            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Unidade',
            field: 'unidade',
            id: 3,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 4,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Total',
            field: 'quantidade_total',
            id: 5,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Periodicidade',
            field: 'periodicidade',
            id: 6,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Valor Unitário',
            field: 'valor_unitario',
            id: 7,
            width: 200,
            headerClassName: 'cabecalho_azul',
            valueFormatter: (params) => {
                // Converte o valor para o formato de moeda brasileira
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            },
        },

        {
            headerName: 'Valor Total',
            field: 'valor_total',
            id: 8,
            width: 200,
            headerClassName: 'cabecalho_azul',
            valueFormatter: (params) => {
                // Converte o valor para o formato de moeda brasileira
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            },
        },

    ];

    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Propostas={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ paddingTop: 5 }} />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container maxWidth="lg" >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Serviços" style={{ backgroundColor: 'green' }} />
                                         

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>




                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >



                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={10}
                                        >
                                            <Button style={{ margin: 5 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={salvar}
                                                disabled={botaoClicado}
                                            > Salvar  </Button>
                                        </Grid>


                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="area-selector-label">Nível SLA</InputLabel>
                                                    <Select
                                                        labelId="area-selector-label"
                                                        id="area-selector"
                                                        value={nivelSla}
                                                        onChange={handleChangeNivel}

                                                        label="Nível SLA"
                                                    >
                                                        <MenuItem value={0}>
                                                            Nível 0 - Básico     <br />
                                                            CAPITAL E REGIÃO METROPOLITANA EM ATÉ 48 HORAS <br />
                                                            INTERIOR DO ESTADO EM ATÉ 72 HORAS <br />
                                                            ÁREAS REMOTAS EM ATÉ 96 HORAS <br />
                                                            ----------------------------------------------

                                                        </MenuItem>
                                                        <MenuItem value={1}>
                                                            Nível 1 - Crítico     <br />
                                                            CAPITAL E REGIÃO METROPOLITANA	EM ATÉ 24 HORAS <br />
                                                            INTERIOR DO ESTADO	EM ATÉ 48 HORAS <br />
                                                            ÁREAS REMOTAS	EM ATÉ 72 HORAS <br />
                                                            ----------------------------------------------

                                                        </MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>




                                        <Grid item xs={4}>
                                            <TextField
                                                variant="standard"
                                                name="diasValidade"
                                                label="Validade da Proposta (em dias)"
                                                required
                                                id="diasValidade"
                                                value={diasValidade}
                                                onChange={(e) => setDiasValidade(e.target.value.replace(/\D/g, ''))}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={4}>
                                            <TextField
                                                variant="standard"
                                                name="diasInicioInstalacao"
                                                label="Dias até Início da Instalação"
                                                required
                                                id="diasInicioInstalacao"
                                                value={diasInicioInstalacao}
                                                onChange={(e) => setDiasInicioInstalacao(e.target.value.replace(/\D/g, ''))}
                                                fullWidth
                                            />
                                        </Grid>



                                        <Grid item xs={4}>
                                            <TextField
                                                variant="standard"
                                                name="diasInstalacao"
                                                label="Duração da Instalação (em dias)"
                                                required
                                                id="diasInstalacao"
                                                value={diasInstalacao}
                                                onChange={(e) => setDiasInstalacao(e.target.value.replace(/\D/g, ''))}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={12} style={{ padding: 40 }}>
                                            <TextField
                                                variant="standard"
                                                name="descricaoProposta"
                                                label="Descrição Proposta"
                                                required
                                                id="descricaoProposta"
                                                value={descricaoProposta}
                                                onChange={(e) => setDescricaoProposta(e.target.value)}
                                                fullWidth
                                                multiline
                                                rows={10}
                                            />
                                        </Grid>


                                    </Grid>

                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 5 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        {itens !== null && (
                                            <>
                                                <DataGrid
                                                    pagination
                                                    checkboxSelection
                                                    rows={itens}
                                                    getRowId={(row) => row.id_item_proposta}
                                                    columns={columnsDataGrid}
                                                    disableColumnResize={false}
                                                    onCellClick={handleCellClick}
                                                    onRowClick={handleRowClick}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                />



                                            </>
                                        )}

                                        <Paper
                                            elevation={5} // Ajuste o valor da elevação conforme necessário
                                            style={{
                                                backgroundColor: '#edf2f9', // Ajuste a cor de fundo conforme necessário
                                                padding: 20,
                                                borderRadius: 10, // Adiciona bordas levemente arredondadas
                                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', // Ajusta os valores da sombra conforme necessário
                                                margin: '20px', // Ajusta a margem para afastamento das bordas da tela
                                            }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                item xs={12}
                                                spacing={2}
                                                style={{ paddingTop: 20 }}
                                            >

                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoItem"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoItem"
                                                        value={descricaoItem}
                                                        onChange={(e) => setDescricaoItem(e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="standard"
                                                        name="unidadeItem"
                                                        label="Unidade"
                                                        required
                                                        id="unidadeItem"
                                                        value={unidadeItem}
                                                        onChange={(e) => setUnidadeItem(e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="standard"
                                                        name="quantidadeItem"
                                                        label="Quantidade"
                                                        required
                                                        id="quantidadeItem"
                                                        value={quantidadeItem}
                                                        onChange={(e) => setQuantidadeItem(e.target.value.replace(/\D/g, ''))}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="standard"
                                                        name="quantidadeTotal"
                                                        label="Quantidade Total"
                                                        required
                                                        id="quantidadeTotal"
                                                        value={quantidadeTotal}
                                                        onChange={(e) => setQuantidadeTotal(e.target.value.replace(/\D/g, ''))}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="standard"
                                                        name="periodicidade"
                                                        label="Periodicidade"
                                                        required
                                                        id="periodicidade"
                                                        value={periodicidade}
                                                        onChange={(e) => setPeriodicidade(e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>


                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="standard"
                                                        name="valorUnitario"
                                                        label="Valor Unitário"
                                                        required
                                                        id="valorUnitario"
                                                        value={valorUnitario}
                                                        onChange={(e) => setValorUnitario(e.target.value.replace(/^(\D*)(\d*(?:\.\d{0,2})?).*$/, '$2'))}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="standard"
                                                        name="valorTotal"
                                                        label="Valor Total"
                                                        required
                                                        id="valorTotal"
                                                        value={valorTotal}
                                                        onChange={(e) => setValorTotal(e.target.value.replace(/^(\D*)(\d*(?:\.\d{0,2})?).*$/, '$2'))}
                                                        fullWidth
                                                    />
                                                </Grid>



                                            </Grid>
                                        </Paper>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button style={{ marginTop: 20 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={novoItem}
                                            >
                                                Novo Item
                                            </Button>
                                        </Grid>


                                    </Grid>

                                </SwipeableViews>







                            </Grid>





                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


