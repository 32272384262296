import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Chip from '@mui/material/Chip';
import Button from '@material-ui/core/Button';

import { useHistory } from "react-router-dom";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function EditarInsumo() {

    const [loading, setLoading] = useState(true);


    const classes = useStyles();
    const history = useHistory();

    const [serie, setSerie] = useState('');
    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');

    const [codigo, setCodigo] = useState('');
    const { idInsumo } = useParams();


    const [observacoes, setObservacoes] = useState('');


    const [tipo, setTipo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [categoria, setCategoria] = useState('');
    const [versao, setVersao] = useState('');
    const [data, setData] = useState('');

    const [procedimentos, setProcedimentos] = useState([]);

    const [procedimentosSelecionados, setProcedimentosSelecionados] = useState([]);


    const [tipoAreaTecnica, setTipoAreaTecnica] = useState(0);
    const [versaoAreaTecnica, setVersaoAreaTecnica] = useState(0);

    const [teste, setTeste] = useState(0);

    const handleChangeTeste = (event) => {
        setTeste(event.target.value);
    };


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("/v1/protected/almoxarifado/insumos/listarporid/" + idInsumo, {
                headers: headers
            }).then(function (response_insumo) {

                if (response_insumo.data.procedimentos)
                    setProcedimentosSelecionados(response_insumo.data.procedimentos);
                else
                    setProcedimentosSelecionados([]);


                setMarca(response_insumo.data.marca);
                setModelo(response_insumo.data.modelo);
                setTipo(response_insumo.data.tipo);
                setCategoria(response_insumo.data.categoria);
                setVersao(response_insumo.data.versao);
                setSerie(response_insumo.data.serie);
                setCodigo(response_insumo.data.codigo_interno)
                setData(response_insumo.data.data);
                setDescricao(response_insumo.data.descricao);
                setTeste(response_insumo.data.teste)
                setObservacoes(response_insumo.data.observacoes);
                setTipoAreaTecnica(response_insumo.data.tipo_equipamento_area_tecnica);
                setVersaoAreaTecnica(response_insumo.data.versao_area_tecnica);


                api.get("v1/protected/procedimentos/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setProcedimentos(response.data)


                    setLoading(false);

                });

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Informacoes: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);


    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }

    const handleAdicionarProcedimento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosProcedimentos = valoresSelecionados.filter(
            (opcao) => !procedimentosSelecionados.some((selecionado) => selecionado.id_procedimento === opcao.id_procedimento)
        );

        setProcedimentosSelecionados([...procedimentosSelecionados, ...novosProcedimentos]);
    };



    async function salvar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {


                marca: marca,
                modelo: modelo,
                serie: serie,
                codigo_interno: codigo,
                tipo: tipo,
                descricao: descricao,
                categoria: categoria,
                versao: versao,
                data: data,
                procedimentos: procedimentosSelecionados,
                teste: teste,
                observacoes: observacoes,
                tipo_equipamento_area_tecnica: tipoAreaTecnica,
                versao_area_tecnica: versaoAreaTecnica,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/almoxarifado/insumos/atualizarinsumo/' + idInsumo, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Insumo Atualizado!")
                history.push({
                    pathname: "/gestao/operacional/almoxarifado/insumos/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }

    function returnDescricaoProcedimento(procedimento) {
        var texto = "Nome: " + procedimento.nome + "\n Descrição: " + procedimento.descricao_geral + (procedimento.obrigatorio === 1 ? " Obrigatório" : "Opcional");
        return texto;
    }




    const handleRemoverProcedimento = (event, procedimentoRemovido) => {
        const novosProcedimentos = procedimentosSelecionados.filter(
            (procedimento) => procedimento.id_procedimento !== procedimentoRemovido.id_procedimento
        );
        setProcedimentosSelecionados(novosProcedimentos);
    };


    return (
        <div>
            <NavegadorGestao
                Almoxarifado={true}
                Insumos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={12}
                            >
                                <Button style={{ margin: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                > Atualizar  </Button>
                            </Grid>

                            <Grid
                                style={{ paddingTop: 5 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >





                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="marca"
                                        label="Marca"
                                        required
                                        id="marca"
                                        value={marca}
                                        onChange={(e) => setMarca(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="modelo"
                                        label="Modelo"
                                        required
                                        id="modelo"
                                        value={modelo}
                                        onChange={(e) => setModelo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>



                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="tipo"
                                        label="Tipo"
                                        required
                                        id="tipo"
                                        value={tipo}
                                        onChange={(e) => setTipo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="categoria"
                                        label="Categoria"
                                        required
                                        id="categoria"
                                        value={categoria}
                                        onChange={(e) => setCategoria(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="versao"
                                        label="Versão"
                                        required
                                        id="versao"
                                        value={versao}
                                        onChange={(e) => setVersao(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="serie"
                                        label="Série"
                                        required
                                        id="serie"
                                        value={serie}
                                        onChange={(e) => setSerie(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="codigo"
                                        label="Código Interno"
                                        required
                                        id="codigo"
                                        value={codigo}
                                        onChange={(e) => setCodigo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>



                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="data"
                                        label="Ano"
                                        required
                                        id="data"
                                        value={data}
                                        onChange={(e) => setData(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>




                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={3}
                                    />
                                </Grid>



                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="observacoes"
                                        label="Observações"
                                        required
                                        id="observacoes"
                                        value={observacoes}
                                        onChange={(e) => setObservacoes(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={3}
                                    />
                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="obrigatorio-selector-label">Teste no CheckList</InputLabel>
                                            <Select
                                                labelId="teste-selector-label"
                                                id="teste-selector"
                                                value={teste}
                                                onChange={handleChangeTeste}
                                                label="Teste no CheckList"
                                            >
                                                <MenuItem value={0}>NÃO</MenuItem>
                                                <MenuItem value={1}>SIM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-procedimentos"
                                            options={procedimentos}
                                            disableCloseOnSelect
                                            multiple
                                            getOptionLabel={(procedimento) => returnDescricaoProcedimento(procedimento).replace(/<br \/>/g, '\n')}
                                            value={procedimentosSelecionados}
                                            onChange={handleAdicionarProcedimento}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((procedimento) => {
                                                    const cotacaoText = returnDescricaoProcedimento(procedimento).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Procedimentos " multiline rows={3} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoProcedimento(option) }} />
                                            )}

                                            renderTags={(value, getTagProps) =>
                                                value.map((procedimento, index) => (
                                                    <Chip
                                                        label={returnDescricaoProcedimento(procedimento)}
                                                        {...getTagProps({ index })}
                                                        onDelete={(event) => handleRemoverProcedimento(event, procedimento)}
                                                    />
                                                ))
                                            }

                                        />
                                    </Grid>
                                </Grid>



                                <Grid item xs={6}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="obrigatorio-selector-label">Tipo do Equipamento</InputLabel>
                                            <Select
                                                labelId="teste-selector-label"
                                                id="teste-selector"
                                                value={tipoAreaTecnica}
                                                onChange={(e) => setTipoAreaTecnica(e.target.value)}
                                                label="Tipo do Equipamento"
                                            >
                                                <MenuItem value={0}>PGM</MenuItem>
                                                <MenuItem value={10}>MEDIDOR</MenuItem>
                                                <MenuItem value={20}>CONTROLADOR DE ACESSO</MenuItem>
                                                <MenuItem value={30}>MÓDULO SONORO</MenuItem>
                                                <MenuItem value={40}>FONTE VDC</MenuItem>
                                                <MenuItem value={70}>CENTRAL</MenuItem>
                                                <MenuItem value={80}>CÂMERA</MenuItem>
                                                <MenuItem value={90}>SENSOR</MenuItem>
                                                <MenuItem value={100}>DVR</MenuItem>
                                                <MenuItem value={110}>NVR</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="obrigatorio-selector-label">Versão do Equipamento</InputLabel>
                                            <Select
                                                labelId="teste-selector-label"
                                                id="teste-selector"
                                                value={versaoAreaTecnica}
                                                onChange={(e) => setVersaoAreaTecnica(e.target.value)}
                                                label="Versão do Equipamento"
                                            >
                                                <MenuItem value={0}>1.0</MenuItem>
                                                <MenuItem value={1}>2.0</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                            </Grid>





                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


