import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import Rodape from '../../../../components/Rodape';
import moment from 'moment';

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));




export default function Logs() {



    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [parametros] = useState(
        {

            page: 0,
            size: 100,
        }
    );

    const [height, setHeight] = useState(0);


    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });




    useEffect(() => {


        checkDimenssoes();

        async function listarLogs() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }



                await api.get("v1/protected/logs/listarTodos", {
                    params: {
                        page: parametros.page,
                        size: parametros.size,
                    },
                    headers: headers
                }).then(function (response) {
                    setLogs(response.data.content)

                    console.log(" logs: " + response);
                    setLoading(false);


                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar logs: " + _err)

            }

        }
        listarLogs();


    }, [parametros]);

    function formatDataEHour(data) {
        var dataCtr = moment(data, "YYYY-MM-DD hh:mm:ss");
        return dataCtr.format("DD/MM/YYYY HH:mm");
    }



    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} style={{ height: height }}>
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Data e Hora</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Controladora</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Fornecedor</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Endereço</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Tipo</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Mensagem</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((log) => (
                            <Row key={log.id_log} row={log} />
                        ))}
                    </TableBody>





                </Table>
            </TableContainer>
        );
    }


    function returnEnderecoFornecedor(fornecedor) {
        const descricao = (fornecedor.tipo_endereco === 0 ? " " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio " ) + " " + 
            " Logradouro: " + fornecedor.logradouro +
            ", nº " + fornecedor.numero +
            ", Bairro: " + fornecedor.bairro +
            ", Cidade: " + fornecedor.cidade +
            ", UF: " + fornecedor.estado +
            ", CEP: " + fornecedor.cep

            return descricao;
    }


    function returnDescricaoFornecedor(fornecedor) {
        const descricao = (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) + " " +
            (fornecedor.tipo_fornecedor === 0 ? fornecedor.nome + " " + fornecedor.sobrenome : fornecedor.nome_fantasia);

        return descricao;
    }


    function Row(props) {
        const { row } = props;




        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>


                    <TableCell colSpan={1} align="right">{row.id_log}</TableCell>
                    <TableCell colSpan={1} align="right">{formatDataEHour(row.data_hora)}</TableCell>
                    <TableCell colSpan={1} align="right">{row.controladora.codigo}</TableCell>
                    <TableCell colSpan={1} align="right">{row.controladora.fornecedor ? returnDescricaoFornecedor(row.controladora.fornecedor) : "NÃO INSTALADA"}</TableCell>
                    <TableCell colSpan={1} align="right">{row.controladora.fornecedor ? returnEnderecoFornecedor(row.controladora.fornecedor)  : "NÃO INSTALADA"}</TableCell>
                    <TableCell colSpan={1} align="right">{row.tipo === 0 ? "AVISO" : row.tipo === 1 ? "INFORMATIVO" : "ERRO"}</TableCell>
                    <TableCell colSpan={1} align="right">{row.mensagem}</TableCell>


                </TableRow>


            </React.Fragment>
        );
    }



    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#DCDCDC' }}>
                <MenuAdmin titulo={"Logs"} />

                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    <div style={{ padding: 10, width: '100%', height: '70%' }}>
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :

                            <div>
                                <CollapsibleTable></CollapsibleTable>

                            </div>
                        }

                    </div>

                </main>
            </div>
            <div >
                <Rodape />
            </div>
        </div>
    );
}



