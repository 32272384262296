import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import {
    Box,
    Tooltip
} from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment'; // Importe a biblioteca Moment.js
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import Rodape from '../../../../../components/Rodape';
import {
    MaterialReactTable,

} from 'material-react-table';
import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';


const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



export default function OrdensDeCompras() {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [height, setHeight] = useState(0);




    const [value, setValue] = useState(1);
    const [objetos, setObjetos] = useState(null);

    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });




    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/ordensdecompras/ordemdecompra/listar", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {


        checkDimenssoes();


        listar();


    }, []);

    function novo() {
        window.location.href = "/gestao/administracao/ordensdecompras/cadastrarordemcompra"

    }


   


    const handleClickEdicao = (row) => {


        window.location.href = "/gestao/administracao/ordensdecompras/editar/" + row.id_ordem_compra;


    };



    function formatDate(date) {
        // Formate a data para 'HH:mm DD/MMM/YYYY'
        return moment(date).format('HH:mm DD/MM/YYYY');
    }







    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_ordem_compra', //normal accessorKey
                header: 'ID',
                size: 50,
                maxHeight: 50,
            },
            {
                accessorKey: 'nivel_prioridade',
                header: 'Prioridade',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {

                    let text = '';
                    if (renderedCellValue === 0)
                        text = "Comum";
                    else if (renderedCellValue === 1)
                        text = "Urgente";
                    else if (renderedCellValue === 2)
                        text = "Priorizar";


                    return (
                        <span>
                            {text}
                        </span>
                    );
                },


            },

            {
                accessorKey: 'status',
                header: 'Status',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {

                    let text = '';
                    if (renderedCellValue === 0)
                        text = "Editando";
                    else if (renderedCellValue === 1)
                        text = "Elaborada, Aguardando Escolha de Cotação";
                    else if (renderedCellValue === 2)
                        text = "Ánalise Orçamentária Finalizada, Aguardando Autorização";
                    else if (renderedCellValue === 3)
                        text = "Autorizado, em Faturamento";
                    else if (renderedCellValue === 4)
                        text = "Recebimento Concluído";


                    return (
                        <span>
                            {text}
                        </span>
                    );
                },


            },
            {
                accessorKey: 'usuario',
                header: 'Usuário Criador',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {

                    var text = (renderedCellValue.nome_amigavel);

                    return (
                        <span>
                            {text}
                        </span>
                    );
                },


            },
            {
                accessorKey: 'descricao',
                header: 'Descrição',
                size: 130,
                maxHeight: 130,

            },

            {
                accessorKey: 'data_criacao',
                header: 'Data Criação',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {


                    return (
                        <span>{formatDate(renderedCellValue)}</span>
                    );
                },


            },

            {
                accessorKey: 'data_hora_maxima_conclusao',
                header: 'Data Máxima Conclusão',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {


                    return (
                        <span>{formatDate(renderedCellValue)}</span>
                    );
                },


            },

            {
                accessorKey: 'solicitantes',
                header: 'Solicitantes',
                size: 130,
                maxHeight: 130,
                Cell: ({ renderedCellValue }) => {

                    var solicitantesString = renderedCellValue.map(ordem_compra_solicitante => {
                        return ordem_compra_solicitante.solicitante.nome_amigavel;
                    });
    
                    // Juntar as strings com vírgula
                    var solicitantesConcatenados = solicitantesString.join(', ');

                    return (
                        <span>{solicitantesConcatenados}</span>
                    );
                },


            },



        ],
        [],
    );


    const handleMenuClick = (newValue) => {
        setValue(newValue);
    };





    return (
        <div>

            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {value === 0 &&
                <div style={{ paddingTop: 5, paddingLeft: 220, width: '100%', backroungcolor: '#f0f4f4' }}>
                </div>
            }

            {
                value === 1 &&
                <div style={{ paddingTop: 5, paddingLeft: 220 }}>
                    {loading ?
                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                        </Skeleton>
                        :

                        <div>
                            <div style={{ backgroundColor: 'white' }}>
                                {objetos !== null && (
                                    <>

                                        <MaterialReactTable
                                            columns={columns}
                                            data={objetos}
                                            enableBottomToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_ordem_compra}
                                            enableGrouping
                                            initialState={{
                                                pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                            muiPaginationProps={{
                                                rowsPerPageOptions: [50, 100, 200],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={true}
                                            renderDetailPanel={({ row }) => (
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        left: '30px',
                                                        maxWidth: '1000px',
                                                        position: 'sticky',
                                                        width: '100%',
                                                    }}
                                                >
                                                </Box>
                                            )}
                                            renderRowActions={({ row }) => (
                                                <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                                                    <Tooltip title="Gerenciar">
                                                        <IconButton color="error" onClick={() => handleClickEdicao(row.original)}>
                                                            <SettingsIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </Box>
                                            )}

                                        />


                                    </>
                                )}
                            </div>
                        </div>
                    }
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item xs={12}
                    >
                        <Button style={{ marginTop: 20 }}
                            variant="contained"
                            color="primary"
                            onClick={novo}
                        >
                            Nova Ordem de Compra
                        </Button>
                    </Grid>

                </div>
            }



            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    top: '100px', // Ajuste conforme a altura do NavegadorGestao
                    height: 'calc(100vh - 100px)',
                    '& .MuiDrawer-paper': {
                        position: 'fixed',
                        top: '100px', // Ajuste conforme a altura do NavegadorGestao
                        width: '220px', // Defina a largura do Drawer conforme necessário
                    },
                }}
            >
                <List>
                    <ListItem button onClick={() => handleMenuClick(0)}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button onClick={() => handleMenuClick(1)}>
                        <ListItemIcon><PostAddIcon /></ListItemIcon>
                        <ListItemText primary="Ordens de Compras" />
                    </ListItem>
                </List>
            </Drawer>

        </div>
    );
}



