import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { format, addDays, isValid, parse } from 'date-fns';
import Typography from '@material-ui/core/Typography';

import { useParams } from "react-router-dom";



export default function CadastrarAgendamento() {

    const [loading, setLoading] = useState(true);


    const history = useHistory();

    const [descricao, setDescricao] = useState('');




    const [tipoAgendamento, setTipoAgendamento] = useState(0);

    const handleChangeTipo = (event) => {
        setTipoAgendamento(event.target.value);
    };

    const { idOrdemServico } = useParams();
    const { idProcedimentoExecucao } = useParams();
    const { tipoCadastro } = useParams();


    const [equipes, setEquipes] = useState([]);
    const [equipeSelecionada, setEquipeSelecionada] = useState(null);
    function handleSetEquipe(equipe) {
        setEquipeSelecionada(equipe);
    }

    const [veiculos, setVeiculos] = useState([]);
    const [veiculoSelecionado, setVeiculoSelecionado] = useState(null);

    function handleSetVeiculo(veiculo) {
        setVeiculoSelecionado(veiculo);
    }


    const [controleSelecao, setControleSelecao] = useState(false);


    const [motivo, setMotivo] = useState('');

    const [dataInicial, setDataInicial] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [horaInicial, setHoraInicial] = useState("08:00");
    const [dataFinal, setDataFinal] = useState(format(addDays(new Date(), 7), 'dd/MM/yyyy'));
    const [horaFinal, setHoraFinal] = useState("12:00");

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);


    async function listarVeiculos() {

        try {

            const parsedHoraInicial = parse(horaInicial, 'HH:mm', new Date());
            if (!isValid(parsedHoraInicial)) {
                alert("Hora Inicial Inválida!");
                return null;
            }

            const parsedDataInicial = parse(dataInicial, 'dd/MM/yyyy', new Date());
            if (!isValid(parsedDataInicial)) {
                alert("Data Inicial Inválida!");
                return null;
            }

            const parsedHoraFinal = parse(horaFinal, 'HH:mm', new Date());
            if (!isValid(parsedHoraFinal)) {
                alert("Hora Final Inválida!");
                return null;
            }

            const parsedDataFinal = parse(dataFinal, 'dd/MM/yyyy', new Date());
            if (!isValid(parsedDataFinal)) {
                alert("Data Final Inválida!");
                return null;
            }

            // Formatar datas e horas para 'yyyy/MM/dd HH:mm'
            var dataHoraInicialFormatada = format(parsedDataInicial, 'yyyy-MM-dd') + ' ' + format(parsedHoraInicial, 'HH:mm');
            var dataHoraFinalFormatada = format(parsedDataFinal, 'yyyy-MM-dd') + ' ' + format(parsedHoraFinal, 'HH:mm');


            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }
            await api.get("v1/protected/agendamentos/veiculos/" + dataHoraInicialFormatada + "/" + dataHoraFinalFormatada, {
                headers: headers
            }).then(function (response) {
                console.log("veiculos: " + JSON.stringify(response.data));
                setVeiculos(response.data)
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar veículos: " + _err)

        }

    }


    async function listarEquipes() {

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }
            await api.get("v1/protected/veiculos/listartodos", {
                headers: headers
            }).then(function (response) {
                setEquipes(response.data)
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar equipes: " + _err)

        }

    }

    async function listarInformacoes() {

        console.log("listando infos");
        setLoading(true);

        try {

            try {

                const parsedHoraInicial = parse(horaInicial, 'HH:mm', new Date());
                if (!isValid(parsedHoraInicial)) {
                    alert("Hora Inicial Inválida!");
                    return null;
                }

                const parsedDataInicial = parse(dataInicial, 'dd/MM/yyyy', new Date());
                if (!isValid(parsedDataInicial)) {
                    alert("Data Inicial Inválida!");
                    return null;
                }

                const parsedHoraFinal = parse(horaFinal, 'HH:mm', new Date());
                if (!isValid(parsedHoraFinal)) {
                    alert("Hora Final Inválida!");
                    return null;
                }

                const parsedDataFinal = parse(dataFinal, 'dd/MM/yyyy', new Date());
                if (!isValid(parsedDataFinal)) {
                    alert("Data Final Inválida!");
                    return null;
                }

                // Formatar datas e horas para 'yyyy/MM/dd HH:mm'
                var dataHoraInicialFormatada = format(parsedDataInicial, 'yyyy-MM-dd') + ' ' + format(parsedHoraInicial, 'HH:mm');
                var dataHoraFinalFormatada = format(parsedDataFinal, 'yyyy-MM-dd') + ' ' + format(parsedHoraFinal, 'HH:mm');


                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                console.log("listando infos 2");

                await api.get("v1/protected/agendamentos/veiculos/" + dataHoraInicialFormatada + "/" + dataHoraFinalFormatada, {
                    headers: headers
                }).then(function (response) {

                    console.log("retorno");

                    console.log("veiculos: " + JSON.stringify(response.data));
                    setVeiculos(response.data)

                    api.get("v1/protected/agendamentos/equipes/" + dataHoraInicialFormatada + "/" + dataHoraFinalFormatada, {
                        headers: headers
                    }).then(function (response) {
                        setEquipes(response.data);

                        console.log("Tipo cadastro: " + tipoCadastro);

                        if (parseInt(tipoCadastro) === 0) {
                            //obrigatorio cadastro de agendamento de veiculo
                            setTipoAgendamento(0);
                            setControleSelecao(true);
                            setMotivo("Veículo para deslocar Equipe para cumprimento da Ordem de Servico nº " + idOrdemServico);
                            setLoading(false);

                        } else if (parseInt(tipoCadastro) === 1) {
                            //obrigatorio  cadastro de agendamento de equipe
                            setTipoAgendamento(1);
                            setControleSelecao(true);
                            setMotivo("Equipe para cumprir Ordem de Servico nº " + idOrdemServico);
                            setLoading(false);

                        } else {
                            setLoading(false);
                        }


                    });



                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar veículos: " + _err)

            }

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar equipes: " + _err)

        }

    }

    useEffect(() => {


        listarInformacoes();

    }, []);







    async function salvar() {
        setBotaoSalvarClickado(true);


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                motivo: motivo,
                descricao: descricao,
                tipo_agendamento: tipoAgendamento,
                usuario_criador: { id_usuario: Cookies.get("id_usuario") },
            }


            const parsedHoraInicial = parse(horaInicial, 'HH:mm', new Date());
            if (!isValid(parsedHoraInicial)) {
                alert("Hora Inicial Inválida!");
                setBotaoSalvarClickado(false);
                return null;
            }

            const parsedDataInicial = parse(dataInicial, 'dd/MM/yyyy', new Date());
            if (!isValid(parsedDataInicial)) {
                alert("Data Inicial Inválida!");
                setBotaoSalvarClickado(false);
                return null;
            }

            const parsedHoraFinal = parse(horaFinal, 'HH:mm', new Date());
            if (!isValid(parsedHoraFinal)) {
                alert("Hora Final Inválida!");
                setBotaoSalvarClickado(false);
                return null;
            }

            const parsedDataFinal = parse(dataFinal, 'dd/MM/yyyy', new Date());
            if (!isValid(parsedDataFinal)) {
                alert("Data Final Inválida!");
                setBotaoSalvarClickado(false);
                return null;
            }

            // Formatar datas e horas para 'yyyy/MM/dd HH:mm'
            var dataHoraInicialFormatada = format(parsedDataInicial, 'yyyy-MM-dd') + ' ' + format(parsedHoraInicial, 'HH:mm');
            var dataHoraFinalFormatada = format(parsedDataFinal, 'yyyy-MM-dd') + ' ' + format(parsedHoraFinal, 'HH:mm');

            cadastro.data_hora_inicial = dataHoraInicialFormatada;
            cadastro.data_hora_final = dataHoraFinalFormatada;


            if (tipoAgendamento === 0) {
                //veiculo
                if (!veiculoSelecionado) {
                    alert("Selecione o Veículo!");
                    setBotaoSalvarClickado(false);
                    return null;
                } else {
                    cadastro.veiculo = veiculoSelecionado;
                }
            } else if (tipoAgendamento === 1) {
                //equipe
                //veiculo
                if (!equipeSelecionada) {
                    alert("Selecione a Equipe!");
                    setBotaoSalvarClickado(false);
                    return null;
                } else {
                    cadastro.equipe = equipeSelecionada;
                }
            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            let url = '/v1/protected/agendamentos/cadastrar/';

            if (parseInt(tipoCadastro) >= 0) {
                //agendar veiculo para ordem de servico
                url += ("1/" + idOrdemServico + "/" + idProcedimentoExecucao);
            } else {
                url += ("0/0/0");

            }


            const response = await api.post(url, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {


                alert("Agendamento Cadastrado");
                if (parseInt(tipoCadastro) >= 0) {
                    history.push({
                        pathname: "/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/" + idOrdemServico,
                    })
                } else {

                    history.push({
                        pathname: "/gestao/operacional/agendamentos/listar",
                    })
                }




            } else {
                alert("O escopo do agendamento conflita com agendamentos já cadastrados!");
                setBotaoSalvarClickado(false);
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }



    function returnDescricaoStatusEquipe(status) {
        switch (status) {
            case -1:
                return "Desativado";
            case 0:
                return "Livre";
            case 1:
                return "Agendado - Não Iniciado";
            case 2:
                return "Agendado - Em Deslocamento para Tratamento de O.S em campo";
            case 3:
                return "Agendado - Tratando Equipe em Campo";
            case 4:
                return "Agendado - Retornando de Tratamento de O.S em campo";
            case 5:
                // Adicione mais casos conforme necessário
                return "Outro status";
            default:
                return "Status Desconhecido";
        }
    }

    function returnDescricaoAgendamento(agendamento) {
        var texto = "ID:  " + agendamento.id_agendamento +
            "\nDe " + format(new Date(agendamento.data_hora_inicial), 'HH:mm dd/MM/yyyy') + " até " + format(new Date(agendamento.data_hora_final), 'HH:mm dd/MM/yyyy') +
            "\nMotivo: " + agendamento.motivo +
            "\nDescrição: " + agendamento.descricao;



        return texto;

    }


    function returnDescricaoEquipe(equipe) {
        var texto = "ID Equipe: " + equipe.id_equipe +
            "\nStatus: " + (returnDescricaoStatusEquipe(equipe.status_equipe)) +
            "\nNome: " + equipe.nome +
            "\nDescrição: " + equipe.descricao +
            "\nRegião de Atuação: " + equipe.regiao_atuacao;



        // Verifica se o veículo possui agendamentos
        if (equipe.agendamentos && equipe.agendamentos.length > 0) {
            texto += "\n\nAgendamentos:";
            equipe.agendamentos.forEach((agendamento, index) => {
                texto += `\n\n  Agendamento ${index + 1}: ${returnDescricaoAgendamento(agendamento)}`;
            });
        } else {
            texto += "\nAgendamentos: Sem Agendamentos nos últimos 60 dias";
        }

        return texto;
    }

    function returnDescricaoVeiculo(veiculo) {


        var texto = "ID Veículo: " + veiculo.id_veiculo +
            "\nStatus: " + (returnDescricaoStatusEquipe(veiculo.status_servico_veiculo)) +
            "\nPlaca: " + veiculo.placa +
            "\nMarca|Modelo Ano/Ano: " + veiculo.marca + "|" + veiculo.modelo + " " + veiculo.ano_modelo + "/" + veiculo.ano_veiculo +
            "\nRegião de Atuação: " + veiculo.regiao_atuacao;


        // Verifica se o veículo possui agendamentos
        if (veiculo.agendamentos && veiculo.agendamentos.length > 0) {
            texto += "\n\nAgendamentos:";
            veiculo.agendamentos.forEach((agendamento, index) => {
                texto += `\n\n  Agendamento ${index + 1}: ${returnDescricaoAgendamento(agendamento)}`;
            });
        } else {
            texto += "\nAgendamentos: Sem Agendamentos nos últimos 60 dias";
        }

        return texto;
    }



    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Agendamentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <Container style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ marginTop: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                        disabled={botaoSalvarClickado}
                    > Salvar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >



                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                >

                                    <Grid
                                        container
                                        direction="row"
                                        item xs={3}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        <FormControl disabled={controleSelecao}
                                            fullWidth>
                                            <InputLabel id="tipo-selector-label">Tipo do Agendamento</InputLabel>
                                            <Select
                                                labelId="tipo-selector-label"
                                                id="tipo-selector"
                                                value={tipoAgendamento}
                                                onChange={handleChangeTipo}
                                                label="Tipo do Agendamento"
                                                disabled={controleSelecao}
                                                inputProps={{ disabled: controleSelecao }}
                                            >
                                                <MenuItem value={0}>Veículo</MenuItem>
                                                <MenuItem value={1}>Equipe</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                {tipoAgendamento === 0 &&
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                        >
                                            <Autocomplete
                                                id="select-veiculo"
                                                options={veiculos}
                                                disableCloseOnSelect
                                                getOptionLabel={(veiculo) => returnDescricaoVeiculo(veiculo).replace(/<br \/>/g, '\n')}
                                                value={veiculoSelecionado}
                                                onChange={(e, v) => handleSetVeiculo(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((cotacao) => {
                                                        const cotacaoText = returnDescricaoVeiculo(cotacao).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Veículo " multiline rows={5} />
                                                )}
                                                renderOption={(option) => (
                                                    <div>
                                                        {returnDescricaoVeiculo(option).split('\n').map((line, index) => (
                                                            <Typography key={index}>{line}</Typography>
                                                        ))}
                                                    </div>
                                                )}
                                            />

                                        </Grid>
                                    </Grid>
                                }


                                {tipoAgendamento === 1 &&
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                        >
                                            <Autocomplete
                                                id="select-equipe"
                                                options={equipes}
                                                disableCloseOnSelect
                                                getOptionLabel={(equipe) => returnDescricaoEquipe(equipe).replace(/<br \/>/g, '\n')}
                                                value={equipeSelecionada}
                                                onChange={(e, v) => handleSetEquipe(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((cotacao) => {
                                                        const cotacaoText = returnDescricaoEquipe(cotacao).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Equipe " multiline minRows={5} />
                                                )}
                                                renderOption={(option) => (
                                                    <div>
                                                        {returnDescricaoEquipe(option).split('\n').map((line, index) => (
                                                            <Typography key={index}>{line}</Typography>
                                                        ))}
                                                    </div>
                                                )}
                                            />

                                        </Grid>
                                    </Grid>
                                }




                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >

                                    <Grid
                                        container
                                        direction="row"
                                        item xs={6}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={3}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TextField
                                                variant="standard"
                                                name="horaInicial"
                                                label="Hora Inicial"
                                                id="horaInicial"
                                                value={horaInicial}
                                                onChange={(e) => setHoraInicial(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={3}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TextField
                                                variant="standard"
                                                name="dataInicial"
                                                label="Data Inicial"
                                                id="dataInicial"
                                                value={dataInicial}
                                                onChange={(e) => setDataInicial(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>



                                        <Grid
                                            container
                                            direction="row"
                                            item xs={3}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TextField
                                                variant="standard"
                                                name="horaFinal"
                                                label="Hora Final"
                                                id="horaFinal"
                                                value={horaFinal}
                                                onChange={(e) => setHoraFinal(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={3}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TextField
                                                variant="standard"
                                                name="dataFinal"
                                                label="Data Final"
                                                id="dataFinal"
                                                value={dataFinal}
                                                onChange={(e) => setDataFinal(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="motivo"
                                        label="Motivo"
                                        id="motivo"
                                        value={motivo}
                                        onChange={(e) => setMotivo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={3}
                                    />
                                </Grid>















                            </Grid>





                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


