import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";
import { isCellEditCommitKeys } from '@mui/x-data-grid/utils/keyboardUtils';





export default function CadastroFornecedorContato() {
    const history = useHistory();


    const { idFornecedor } = useParams();


    const [nome, setNome] = useState('');
    const [cargo, setCargo] = useState('');
    const [celular, setCelular] = useState('');
    const [fixo, setFixo] = useState('');
    const [email, setEmail] = useState('');
    const [descricao, setDescricao] = useState('');


    const [principalChecked, setPrincipalChecked] = useState(false);
    const [copiaChecked, setCopiaChecked] = useState(false);
    const [naoChecked, setNaoChecked] = useState(true);

    const [whatsappChecked, setWhatsappChecked] = useState(true);
    const [recebeOrdensChecked, setRecebeOrdensChecked] = useState(true);

    
    const handleWhatsappChecked = (event) => {
        setWhatsappChecked(!whatsappChecked);
    };

    const handleRecebeOrdensChecked = (event) => {
        setRecebeOrdensChecked(!recebeOrdensChecked);
    };



    const handleNaoChecked = (event) => {
        setPrincipalChecked(false);
        setCopiaChecked(false);
        setNaoChecked(true);
    };

    const handlePrincipalChecked = (event) => {
        setPrincipalChecked(event.target.checked);
        setCopiaChecked(!event.target.checked);
        setNaoChecked(false);

    };


    const handleCopiaChecked = (event) => {
        setCopiaChecked(event.target.checked);
        setPrincipalChecked(!event.target.checked);
        setNaoChecked(false);
    };



    async function salvar() {



        try {
            const cadastro_contato = {

                nome_contato: nome,
                cargo_contato: cargo,
                celular_contato: celular,
                fixo_contato: fixo,
                e_mail_contato: email,
                descricao_contato: descricao,
                email_principal: naoChecked ? 0 : principalChecked ? 1 : 0,
                email_copia: naoChecked ? 0 : copiaChecked ? 1 : 0,
                whatsapp:  whatsappChecked ? 1 : 0,
                recebe_ordens: recebeOrdensChecked ? 1 : 0,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/fornecedores/atualizar/contatos/cadastrarcontato/' + idFornecedor, cadastro_contato,

                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Contato Cadastrado!")

                history.push({
                    pathname: "/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/" + idFornecedor,
                })

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    }


    return (
        <div>
            <NavegadorGestao
                Almoxarifado={true}
                Fornecedores={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <Container style={{ paddingTop: 5 }} >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar  </Button>
                </Grid>

                <React.Fragment >
                    <Grid container spacing={2}   >
                        <Grid
                            container
                            direction="row" spacing={2}
                            item xs={12}>


                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems={"center"}
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    direction="row" spacing={2}
                                    item xs={6}>
                                    <TextField
                                        id="nome"
                                        variant="standard"
                                        name="nome"
                                        fullWidth
                                        label="Nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                </Grid>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems={"center"}
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    direction="row" spacing={2}
                                    item xs={6}>
                                    <TextField
                                        id="cargo"
                                        variant="standard"
                                        name="cargo"
                                        fullWidth
                                        label="Cargo"
                                        value={cargo}
                                        onChange={e => setCargo(e.target.value)}
                                    />
                                </Grid>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems={"center"}
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    direction="row" spacing={2}
                                    item xs={4}>
                                    <TextField
                                        id="celular"
                                        variant="standard"
                                        name="celular"
                                        fullWidth
                                        label="Celular"
                                        value={celular}
                                        onChange={e => setCelular(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={2}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    >
                                    <FormControlLabel
                                        control={<Checkbox checked={whatsappChecked} onChange={handleWhatsappChecked} />}
                                        label="Whatsapp"
                                    />
                                </Grid>

                                <Grid item xs={2}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                 >
                                    <FormControlLabel
                                        control={<Checkbox checked={recebeOrdensChecked} onChange={handleRecebeOrdensChecked} />}
                                        label="Recebe Ordens"
                                    />
                                </Grid>

                            </Grid>



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems={"center"}
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    direction="row" spacing={2}
                                    item xs={6}>
                                    <TextField
                                        id="fixo"
                                        variant="standard"
                                        name="fixo"
                                        fullWidth
                                        label="Fixo"
                                        value={fixo}
                                        onChange={e => setFixo(e.target.value)}
                                    />
                                </Grid>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems={"center"}
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    direction="row" spacing={2}
                                    item xs={6}>
                                    <TextField
                                        id="email"
                                        variant="standard"
                                        name="email"
                                        fullWidth
                                        label="E-mail"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Grid>
                            </Grid>



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems={"center"}
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    direction="row" spacing={2}
                                    item xs={6}>
                                    <TextField
                                        id="descricao"
                                        variant="standard"
                                        name="descricao"
                                        fullWidth
                                        label="Descrição"
                                        value={descricao}
                                        multiline
                                        minRows={3}
                                        onChange={e => setDescricao(e.target.value)}
                                    />
                                </Grid>
                            </Grid>


                        </Grid>

                        <Grid container
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                            style={{ paddingTop: 20 }}
                        >
                            <Grid item xs={8}
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={principalChecked} onChange={handlePrincipalChecked} />}
                                    label="É destinatário de E-mail"
                                />
                            </Grid>

                            <Grid item xs={8}
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={copiaChecked} onChange={handleCopiaChecked} />}
                                    label="É destinatário em Cópia"
                                />
                            </Grid>


                            <Grid item xs={8}
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={naoChecked} onChange={handleNaoChecked} />}
                                    label="Não é Destinátario"
                                />
                            </Grid>
                        </Grid>





                    </Grid>


                </React.Fragment>


            </Container>

        </div >
    );
}


