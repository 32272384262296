import React, { useState } from 'react';
import './styles.scss';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import logo_fundo_preto from '../../assets/imgs/logo_fundo_preto.png';

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import Typography from '@material-ui/core/Typography';

const Rodape = (props) => {

    const date = new Date();
    const [width, setWidth] = useState(0);

    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setWidth(largura);


        console.log("altura:" + width);


    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });



    /*

    return (
        <div style={{ backgroundColor: 'white' }}>


            <a href="https://api.whatsapp.com/send?phone=519130037575&text=olá"
                style={{ height: 45, width: 150, position: 'fixed', right: '15px', bottom: width < 768 ? '10px' : '100px', textAlign: 'center', backgroundColor: 'green', borderRadius: '20px', zIndex: 1 }}

            >
                <p style={{ fontSize: 14, lineHeight: '40px', color: 'white', paddingLeft: 10, textAlign: 'center' }}>  Fale Conosco
                    <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="botão whatsapp" width={35}
                        style={{ paddingLeft: 5 }} />

                </p>

            </a>

            <div style={{ color: 'black', backgroundColor: 'white' }}>

                {props.zap ?
                    <a href="https://api.whatsapp.com/send?phone=5138999416698&text=olá"
                        style={{ height: 40, width: 135, position: 'fixed', right: '15px', bottom: width < 768 ? '10px' : '100px', backgroundColor: 'green', borderRadius: '20px' }}

                    >
                        <p style={{ fontSize: 14, lineHeight: '40px', color: 'black', paddingLeft: 10 }}>  Fale Conosco
                            <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="botão whatsapp" width={35}
                                style={{ paddingLeft: 5 }} />

                        </p>

                    </a> : <div></div>
                }



            </div>

            <div style={{ paddingTop: 30 }}>
                <Grid container
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                    justifyContent='center'
                    alignItems='center'
                    direction='row'
                    style={{ textAlign: 'center' }}
                >

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 10 }}>
                        <div >
                            <h2 className="footer-wid-title" style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 22, color: 'black' }}>Navegação</h2>
                            <ul style={{ fontSize: 22 }}>
                                <li><a id={"arodape"} href="/#">Ínicio</a></li>
                                <li><a id={"arodape"} href="/login">Minha Conta</a></li>
                                <li><a id={"arodape"} href="/noticias">Blog</a></li>
                                <li><a id={"arodape"} href="/contato">Fale Conosco</a></li>
                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 10 }}>
                        <div>
                            <h2 className="footer-wid-title" style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 22, color: 'black' }} >Soluções</h2>
                            <ul style={{ fontSize: 22 }}>
                                <li><a id={"arodape"} href="/solucoes/#seguranca_eletronica">InfoClick Segurança Eletrônica</a></li>
                                <li><a id={"arodape"} href="/solucoes/#refrigeracao">InfoClick Refrigeração</a></li>
                                <li><a id={"arodape"} href="/minhaconta">InfoClick AutoCenter</a></li>
                                <li><a id={"arodape"} href="/minhaconta">InfoClick Cursos e Treinamentos</a></li>

                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 10 }}>
                        <div >
                            <h2 className="footer-wid-title" style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 22, color: 'black' }}>Acesso Rápido</h2>
                            <ul style={{ fontSize: 22 }}>
                                <li  ><a id={"arodape"} href="/patio">Central de Ajuda</a></li>
                                <li><a id={"arodape"} href="/patio">Dúvidas Frequentes</a></li>
                                <li><a id={"arodape"} href="/patio">Acesso</a></li>
                                <li><a id={"arodape"} href="/patio">Mapa do Site</a></li>

                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginInline: 40, marginTop: 20, marginBottom: 20, backgroundColor: 'white', height: 1 }}>  </div>

            <Grid
                item xs={12} sm={12} md={12} lg={12} xl={12}
                container
                direction='row'
                justifyContent='center'
                alignItens='center'
                style={{ textAlign: 'center', paddingTop: width < 1200 ? 20 : 0, paddingBottom: width < 1200 ? 20 : 0 }}
            >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}
                    container
                    justifyContent='center'
                    alignItens='center'
                    direction='column'
                    style={{ paddingInline: 40 }}
                >
                    <Typography style={{ textAlign: 'center', fontSize: 24, color: 'black' }}>
                        Tv. We 43, 422 - Cidade Nova, Ananindeua-PA 67133-260, Brasil

                    </Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}
                    justifyContent='center'
                    alignItens='center'
                    direction='column'
                    style={{ textAlign: 'center', paddingTop: width < 1200 ? 20 : 0, paddingBottom: width < 1200 ? 20 : 0 }}
                >
                    <span style={{ fontSize: 22, color: 'black' }}>

                        <img alt="{telefone}"
                            style={{ weight: 20, height: 20 }}
                            src={'https://www.certsan.com.br/wp-content/uploads/2020/05/icone-telefone-1.png'}
                        />
                        (91) 9 8897-5705
                        <br></br>
                        <img alt="{telefone}"
                            style={{ weight: 20, height: 20 }}
                            src={'https://cdn-icons-png.flaticon.com/512/3670/3670051.png'}
                        />
                        <a
                            style={{ fontSize: 22, fontWeight: 'bold', textDecoration: 'underline', color: 'black' }}
                            href="https://api.whatsapp.com/send?1=pt_BR&phone=551125897073">

                            (91) 3033-7575</a>

                        <br></br>
                        <img alt="{telefone}"
                            style={{ weight: 20, height: 20 }}
                            src={'https://cdn-icons-png.flaticon.com/512/2504/2504727.png'}
                        />
                        <a href={"mailto:contato@gruporosinetos.com"} style={{ fontSize: 22, color: 'black' }}> contato@gruporosinetos</a>
                    </span>
                </Grid>
            </Grid>

            <div style={{ marginInline: 40, marginTop: 20, marginBottom: 20, backgroundColor: 'white', height: 1 }}>  </div>


            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}
                justifyContent='center'
                alignItens='center'
                direction='row'
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
                    container
                    justifyContent='center'
                    alignItens='center'
                    direction='row'

                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <img alt="img1"

                            src={logo}
                        />
                    </Grid>

                </Grid>


                <Grid
                    item xs={12} sm={12} md={4} lg={4} xl={4}
                    container
                    direction='column'
                    justifyContent='center'
                    alignItens='center'
                    style={{ textAlign: 'center', paddingTop: width < 1200 ? 10 : 0, paddingBottom: width < 1200 ? 10 : 0 }}
                >
                    <span style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 28, color: 'black' }}>
                        Segurança, Tecnologia e Inovação
                    </span>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
                    justifyContent='center'
                    alignItens='center'
                    direction='row'
                    container
                    style={{ paddingTop: width < 1200 ? 20 : 0, paddingBottom: width < 1200 ? 20 : 0 }}
                >

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}
                        container
                        direction='column'
                        justifyContent='center'
                        alignItens='center'
                        style={{ textAlign: 'center' }}
                    >
                        <a href="https://www.facebook.com/GrupoRosinetos" title="Facebook Grupo Rosinetos">  <FacebookIcon style={{ color: "black" }} fontSize='large' /> </a>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}
                        container
                        direction='column'
                        justifyContent='center'
                        alignItens='center'
                        style={{ textAlign: 'center' }}
                    >
                        <a href="https://www.instagram.com/rosinetos/" title="Instagram Grupo Rosinetos"> <InstagramIcon style={{ color: "black" }} fontSize='large' /> </a>

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}
                        container
                        direction='column'
                        justifyContent='center'
                        alignItens='center'
                        style={{ textAlign: 'center' }}
                    >
                        <a href="https://www.linkedin.com/company/grupo-rosinetos---com%C3%A9rcio-e-servi%C3%A7os-ltda/about/" title="Linkedin Kapplan Energy"><LinkedInIcon style={{ color: "black" }} fontSize='large' /></a>


                    </Grid>

                </Grid>

            </Grid>



            <Grid 
            
            item xs={12} container style={{backgroundColor:'DimGray', paddingTop: 10, paddingBottom: 10}}
            alignItens='center'
            justifyContent='center'
            >

                <div style={{ textAlign: 'center' }}>
                    <p style={{ paddingTop: 10, fontSize: 16}} >  &copy; Grupo Rosinetos {moment(date).format("yyyy")} - Todos os direitos reservados
                        programado orgulhosamente por <a href="https://titaniwm.com" target="noopener noreferrer"> titaniwm</a>
                    </p>
                </div>
            </Grid>


        </div >

    )


     */



    return (
        <div style={{ backgroundColor: '#1A1A1A' }}>

            <div style={{ marginInline: 40, paddingTop: 20, marginBottom: 20, backgroundColor: 'black', height: 1 }}>  </div>

            <Grid container
                item xs={12} sm={12} md={12} lg={12} xl={12}
                justifyContent='center'
                alignItems='center'
                direction='row'
                style={{ textAlign: 'center', paddingBottom: 20 }}
            >



                <Grid
                    container
                    item xs={12} sm={12} md={3} lg={3} xl={3}
                    justifyContent='center'
                    alignItens='center'
                    direction='row'
                >


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                        container
                    >
                        <img alt="img1"

                            src={logo_fundo_preto}
                        />
                    </Grid>

                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                        container
                        justifyContent='center'
                        alignItens='center'
                        style={{ textAlign: 'center', paddingTop: 10 }}
                    >
                        <span style={{ textAlign: 'center', fontSize: 16, color: 'white' }}>
                            Segurança, Tecnologia e Inovação
                        </span>
                    </Grid>




                </Grid>

                <Grid
                    container
                    item xs={8} sm={8} md={8} lg={8} xl={8}

                >
                </Grid>

            </Grid>

            <Grid container
                item xs={12} sm={12} md={12} lg={12} xl={12}
                justifyContent='center'
                alignItems='center'
                direction='row'
                style={{ textAlign: 'center', paddingBottom: 100 }}
            >

              

                <Grid
                    item xs={12} sm={12} md={3} lg={3} xl={3}
                    container
                    direction='row'
                    justifyContent='center'
                    alignItens='center'
                    style={{ textAlign: 'center' }}
                >

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                        container
                        justifyContent='center'
                        alignItens='center'
                        direction='row'
                    >
                        <Typography style={{ paddingTop: 30, textAlign: 'center', fontSize: 14, color: 'white' }}>
                            Tv. We 43, 422 - Cidade Nova, Ananindeua-PA 67133-260, Brasil CNPJ: 09.426.072/0001-70

                        </Typography>
                    </Grid>

                    <span style={{ fontSize: 14, color: 'white', paddingTop: 30 }}>

                        <img alt="{telefone}"
                            style={{ weight: 20, height: 20 }}
                            src={'https://www.certsan.com.br/wp-content/uploads/2020/05/icone-telefone-1.png'}
                        />
                        (91) 9 8897-5705
                        <br></br>
                        <img alt="{telefone}"
                            style={{ weight: 20, height: 20 }}
                            src={'https://cdn-icons-png.flaticon.com/512/3670/3670051.png'}
                        />
                        <a
                            style={{ fontSize: 14, textDecoration: 'underline', color: 'white' }}
                            href="https://api.whatsapp.com/send?1=pt_BR&phone=551125897073">

                            (91) 3033-7575</a>

                        <br></br>
                        <img alt="{telefone}"
                            style={{ weight: 20, height: 20 }}
                            src={'https://cdn-icons-png.flaticon.com/512/2504/2504727.png'}
                        />
                        <a href={"mailto:contato@gruporosinetos.com"} style={{ fontSize: 14, color: 'white' }}> contato@gruporosinetos</a>
                    </span>
                </Grid>


                <Grid container
                    item xs={12} sm={12} md={8} lg={8} xl={8}
                    justifyContent='center'
                    alignItems='center'
                    direction='row'
                    style={{ textAlign: 'center' }}
                >
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
                        style={{ paddingBottom: 10 }}>
                        <div >
                        
                            <ul style={{ fontSize: 22,  color: 'white', textAlign: 'justify' }}>
                                <li><a>Navegação</a></li>
                                <div style={{backgroundColor:'white', width: '80%', height:'1px'}} ></div>
                            </ul>
                            
                            <ul style={{ fontSize: 18, textAlign: 'justify' }}>
                                <li><a id={"arodape"} href="/#">Ínicio</a></li>
                                <li><a id={"arodape"} href="/login">Minha Conta</a></li>
                                <li><a id={"arodape"} href="/noticias">Blog</a></li>
                                <li><a id={"arodape"} href="/contato">Fale Conosco</a></li>
                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 10 }}>
                        <div>

                        <ul style={{ fontSize: 22,  color: 'white', textAlign: 'justify' }}>
                                <li><a>InfoClick</a></li>
                                <div style={{backgroundColor:'white', width: '80%', height:'1px'}} ></div>
                            </ul>

                            <ul style={{ fontSize: 18, textAlign: 'justify' }}>
                                <li><a id={"arodape"} href="/solucoes/#seguranca_eletronica"> Segurança Eletrônica</a></li>
                                <li><a id={"arodape"} href="/solucoes/#refrigeracao"> Refrigeração</a></li>
                                <li><a id={"arodape"} href="/minhaconta"> AutoCenter</a></li>
                                <li><a id={"arodape"} href="/minhaconta"> Cursos e Treinamentos</a></li>

                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 10 }}>
                        <div >
                           
                        <ul style={{ fontSize: 22,  color: 'white', textAlign: 'justify' }}>
                                <li><a>Acesso Rápido</a></li>
                                <div style={{backgroundColor:'white', width: '80%', height:'1px'}} ></div>
                            </ul>
                           
                            <ul style={{ fontSize: 18, textAlign: 'justify' }}>
                                <li  ><a id={"arodape"} href="/patio">Central de Ajuda</a></li>
                                <li><a id={"arodape"} href="/patio">Dúvidas Frequentes</a></li>
                                <li><a id={"arodape"} href="/patio">Acesso</a></li>
                                <li><a id={"arodape"} href="/patio">Mapa do Site</a></li>

                            </ul>
                        </div>
                    </Grid>
                </Grid>




            </Grid>





            <Grid

                item xs={12} container
                 style={{ backgroundColor: 'black', color: 'white', paddingTop: 20, paddingBottom: 10 }}
                alignItens='center'
                justifyContent='center'
            >

                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}
                    justifyContent='flex-start'
                    alignItens='center'
                    direction='row'
                    container
                >

                    <div style={{ textAlign: 'center' }}>
                        <p style={{ paddingTop: 10, paddingLeft: 10, fontSize: 12 }} >  &copy; Grupo Rosinetos {moment(date).format("yyyy")} - Todos os direitos reservados
                            - Desenvolvido orgulhosamente por <a href="https://titaniwm.com" target="noopener noreferrer"> TITANIWM</a>
                        </p>
                    </div>

                </Grid>


                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}
                    justifyContent='center'
                    alignItens='center'
                    direction='row'
                    container
                >


                    <a href="https://www.facebook.com/GrupoRosinetos" title="Facebook Grupo Rosinetos">  <FacebookIcon style={{ color: "white" }} fontSize='large' /> </a>

                    <a href="https://www.instagram.com/rosinetos/" title="Instagram Grupo Rosinetos"> <InstagramIcon style={{ color: "white" }} fontSize='large' /> </a>


                    <a href="https://www.linkedin.com/company/grupo-rosinetos---com%C3%A9rcio-e-servi%C3%A7os-ltda/about/" title="Linkedin Grupo Rosinetos"><LinkedInIcon style={{ color: "white" }} fontSize='large' /></a>


                </Grid>


            </Grid>




        </div >

    )
}
export default Rodape;
