
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListSubheader from '@material-ui/core/ListSubheader';
import Cookies from 'js-cookie';
import { faHome, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import api from '../../../services/api';
import moment from 'moment';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { faBell, faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import globalConfig from '../../../configs/globalConfigs';

const drawerWidth = 300;

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: 'white',
        backgroundColor: 'black',
        zIndex: 0,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: 'black',
        ...theme.mixins.toolbar,
    },
    icone: {
        color: 'white',
        backgroundColor: 'black',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    appBarShift: {


    },
    appBarDesktop: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShiftDesktop: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),


    },
    menuButton: {
        marginRight: 36,

    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: '#1C1C1C',
        color: 'white',
        height: '100%',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transitionDuration: 0,
    },

    drawerPaperClose: {

        transitionDuration: 0,
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerPaperDesktop: {
        height: '100%',
        backgroundColor: 'black',
        color: "white",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperCloseDesktop: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
}));

export default function MenuCliente(props) {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [notificacoes, setNotificacoes] = useState([]);
    const [open, setOpen] = React.useState(true);
    const [width, setWidth] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [texto, setTexto] = useState();

    const regra = Cookies.get('regra');


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const idFornecedor = Cookies.get('id_meu_fornecedor');


    function confirmSair() {
        Cookies.set('token', undefined);
        window.location.href = "/login"

    }


    const handleOpenAlert = (acao) => {
        setTexto(descricaoAcao(acao));
        setAlertOpen(true);
        vizualizarAcao(acao);


    };
    function formatDataAndHourComplete(data) {
        var dataCtr = moment(data, "YYYY-MM-DD hh:mm:ss");
        return dataCtr.format("DD/MM/YYYY HH:mm:ss");
    }

    async function vizualizarAcao(acao) {
        try {


            const id_usuario = Cookies.get('id_usuario');
            console.log("id na navbaradmin: " + id_usuario)

            var url = "v1/protected/acao/vizualizaracao/" + acao.id_acao + "/" + id_usuario;
            console.log("Url: " + url);
            await api.get(url).then(function (response) {

                if (response) {
                    setNotificacoes(notificacoes.filter(notificacao => notificacao.id_acao !== acao.id_acao));

                }

            });


        } catch (_err) {
            console.log("Erro ao vizualizar acao, err: " + _err);

        }
    }


    function descricaoAcao(acao) {

        let texto = "ACAO_ID: " + acao.id_acao
            + "\nData e Hora: " + formatDataAndHourComplete(acao.data_hora_requisicao)
            + "\nAção Realizada: " + acao.resposta


            ;
        return texto;
    }

    function returnDescricaoAlerta(tipo_alerta) {
        if (tipo_alerta === 3) {
            return "DISPARO DE ZONA";
        } else if (tipo_alerta === 4) {
            return "TX OFFLINE";
        } else if (tipo_alerta === 17) {
            return "ACESSO NEGADO";
        } else if (tipo_alerta === 16) {
            return "ACESSO LIBERADO";
        } else if (tipo_alerta === 60) {
            return "ZONA DESABILITADA";
        } else if (tipo_alerta === 62) {
            return "ZONA HABILITADA";
        }
    }



    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (largura < 900)
            setOpen(false);
        else
            setOpen(true);

        setWidth(largura)

    }

    const handleCloseAlert = () => {

        setAlertOpen(false);


    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });



    useEffect(() => {

        checkDimenssoes();

    }, [width]);

    useEffect(() => {

        const idFornecedor = Cookies.get('id_meu_fornecedor');
        const regra = Cookies.get('regra');

        async function pesquisarBackGround() {

            if (regra === "SUPER_ADMIN" || regra === "TECNICO" || regra === "ROLE_CLIENTE") {

                try {


                    const id_usuario_logado = Cookies.get('id_usuario');

                    let url = '';
                    if (regra === "SUPER_ADMIN" || regra === "TECNICO") {
                        url = "v1/protected/acao/listaracoesrespondidasnaovizualizadas/" + id_usuario_logado;

                    } else if (regra === "ROLE_CLIENTE") {
                        url = "v1/protected/acao/listaracoesrespondidasnaovizualizadasporfornecedor/" + id_usuario_logado + "/" + idFornecedor;

                    }

                    console.log("Url: " + url);
                    await api.get(url).then(function (response) {


                        if (response.data.status === 0) {

                            Cookies.set('token', undefined);
                            window.location.href = "/login"
                        }




                        setNotificacoes(response.data.acoes);
                    });
                    setLoading(false);



                } catch (_err) {
                    // alert("Erro de Conexão, tente novamente mais tarde");

                }
            }

        }

        pesquisarBackGround();


    }, []);


    useEffect(() => {

        const idFornecedor = Cookies.get('id_meu_fornecedor');


        const interval = setInterval(() => {
            async function pesquisarBackGround() {

                if (regra === "SUPER_ADMIN" || regra === "TECNICO" || regra === "ROLE_CLIENTE") {

                    try {


                        const id_usuario_logado = Cookies.get('id_usuario');

                        let url = '';
                        if (regra === "SUPER_ADMIN" || regra === "TECNICO") {
                            url = "v1/protected/acao/listaracoesrespondidasnaovizualizadas/" + id_usuario_logado;

                        } else if (regra === "ROLE_CLIENTE") {
                            url = "v1/protected/acao/listaracoesrespondidasnaovizualizadasporfornecedor/" + id_usuario_logado + "/" + idFornecedor;

                        }

                        console.log("Url: " + url);
                        await api.get(url).then(function (response) {


                            if (response.data.status === 0) {

                                Cookies.set('token', undefined);
                                window.location.href = "/login"
                            }




                            setNotificacoes(response.data.acoes);
                        });
                        setLoading(false);



                    } catch (_err) {
                        // alert("Erro de Conexão, tente novamente mais tarde");

                    }
                }
            }

            pesquisarBackGround();


        }, 10000);

        return () => clearInterval(interval);

    }, [notificacoes, regra]);

    const toggleDrawer = (option) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (option) {
            handleDrawerClose();
        } else {
            handleDrawerOpen();
        }

    };


    const Lista = () => {
        return (
            <div >





                <ListItem button component="a" href="/minhaconta" >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faHome} size="2x" style={{ color: "SpringGreen" }} />
                    </ListItemIcon>
                    <ListItemText primary="Meus Dados" />
                </ListItem>


                <ListItem button component="a" href={"/monitoramentocliente/" + idFornecedor} >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faEye} size="2x" style={{ color: "MediumBlue" }} />
                    </ListItemIcon>
                    <ListItemText primary="Monitoramento" />
                </ListItem>


                <Divider />
                <List>
                    <ListSubheader inset style={{ color: 'white', fontSize: 18 }}>Opções</ListSubheader>
                    <ListItem button onClick={confirmSair}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize={'large'} style={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItem>
                </List>
            </div>
        )
    }

    const MenuMobile = () => {
        return (
            <>

                <CssBaseline />
                <AppBar position="absolute" style={{ top: 60 }} className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar id="target" className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="column"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"

                        >
                            <Grid
                                container
                                direction="row"
                                item xs={9} sm={9} md={9} lg={9} xl={9}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>

                            {(regra === "SUPER_ADMIN" || regra === "TECNICO" || regra === "ROLE_CLIENTE") ?
                                <Grid
                                    container
                                    direction="column"
                                    item xs={3} sm={3} md={3} lg={3} xl={3}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'


                                >

                                    <NavDropdown id="nav-dropdown"
                                        drop='right'
                                        alignright="true"

                                        title={
                                            <div className="notification-icon-container">
                                                <span className="notification-count">{notificacoes.length}</span>
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    size="2x"
                                                    className={notificacoes.length > 0 ? "shake-bell" : ""}
                                                    style={{ color: "SpringGreen" }}
                                                />
                                            </div>
                                        }
                                    >

                                        {
                                            loading && notificacoes ?
                                                <div>
                                                </div>
                                                :
                                                notificacoes.map((notificacao) => {
                                                    return (
                                                        <NavDropdown.Item
                                                            onClick={() => handleOpenAlert(notificacao)}
                                                            style={{ backgroundColor: 'white', color: 'black', }}
                                                            key={notificacao.id_acao} eventKey={notificacao.id_acao} >

                                                            {notificacao.tipo_requisicao === 16 ?
                                                                <span style={{ paddingRight: 10 }}>
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" style={{ color: "yellow" }} />
                                                                </span>
                                                                :
                                                                <span style={{ paddingRight: 10 }}>
                                                                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" style={{ color: "red" }} />
                                                                </span>
                                                            }
                                                            <span >
                                                                {returnDescricaoAlerta(notificacao.tipo_requisicao)}

                                                            </span>


                                                        </NavDropdown.Item>
                                                    )
                                                })
                                        }




                                    </NavDropdown>


                                </Grid>
                                : null
                            }

                        </Grid>

                    </Toolbar>
                </AppBar>
                <SwipeableDrawer style={{ position: 'absolute', top: 275 }}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    variant="temporary"
                    disableBackdropTransition
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    anchor={'left'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton className={classes.icone} onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    <Lista />
                </SwipeableDrawer>
            </>
        )
    }

    const MenuDesktop = () => {
        return (
            <>
                <CssBaseline />
                <AppBar position="absolute" style={{ top: globalConfig.appBarTop }} className={clsx(classes.appBarDesktop, open && classes.appBarShiftDesktop)}>
                    <Toolbar id="target" className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"

                        >
                            <Grid
                                container
                                direction="row"
                                item xs={6} sm={6} md={6} lg={6} xl={6}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>

                            {(regra === "SUPER_ADMIN" || regra === "TECNICO" || regra === "ROLE_CLIENTE") ?
                                <Grid
                                    container
                                    direction="column"
                                    item xs={6} sm={6} md={6} lg={6} xl={6}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'


                                >

                                    <NavDropdown id="nav-dropdown"
                                        drop='right'
                                        alignright="true"

                                        title={
                                            <div className="notification-icon-container">
                                                <span className="notification-count">{notificacoes.length}</span>
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    size="2x"
                                                    className={notificacoes.length > 0 ? "shake-bell" : ""}
                                                    style={{ color: "SpringGreen" }}
                                                />
                                            </div>
                                        }
                                    >

                                        {
                                            loading && notificacoes ?
                                                <div>
                                                </div>
                                                :
                                                notificacoes.map((notificacao) => {
                                                    return (
                                                        <NavDropdown.Item
                                                            onClick={() => handleOpenAlert(notificacao)}
                                                            style={{ backgroundColor: 'white', color: 'black', }}
                                                            key={notificacao.id_acao} eventKey={notificacao.id_acao} >

                                                            {notificacao.tipo_requisicao === 16 ?
                                                                <span style={{ paddingRight: 10 }}>
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" style={{ color: "yellow" }} />
                                                                </span>
                                                                :
                                                                <span style={{ paddingRight: 10 }}>
                                                                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" style={{ color: "red" }} />
                                                                </span>
                                                            }
                                                            <span >
                                                                {returnDescricaoAlerta(notificacao.tipo_requisicao)}

                                                            </span>


                                                        </NavDropdown.Item>
                                                    )
                                                })
                                        }




                                    </NavDropdown>


                                </Grid>
                                : null
                            }


                        </Grid>

                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaperDesktop, !open && classes.drawerPaperCloseDesktop),
                    }}
                    open={open}
                >

                    <div className={classes.toolbarIcon}>
                        <IconButton className={classes.icone} onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <Lista />

                </Drawer>
            </>
        )
    }

    return (


        <div>
            {
                width < 900 ?
                    <MenuMobile /> : <MenuDesktop />
            }
            <Dialog
                fullScreen={true}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"


            >
                <DialogTitle style={{ backgroundColor: 'black' }} id="confirm-dialog">{ }</DialogTitle>
                <DialogContent style={{ backgroundColor: 'black' }}>
                    <div style={{ backgroundColor: 'black' }}>
                        <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>

                            <Grid container spacing={1}
                                style={{ backgroundColor: 'black', padding: 20 }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    item xs={12} sm={12} md={12} lg={12} xl={12}

                                >
                                    <span style={{ whiteSpace: "pre-line", fontSize: '22px' }} >{texto}</span>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCloseAlert}

                                    >
                                        OK
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >

        </div>


    )
};