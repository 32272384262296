import React, { useState, useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import background from '../../assets/imgs/fale-conosco.jpg';
import img_email from '../../assets/imgs/email.png';
import img_telefone from '../../assets/imgs/telefone.png';

import whatsapp from '../../assets/imgs/whatsapp.png';
import { TextField, Container } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import api from '../../services/api';
import './styles.scss';

const Contato = () => {

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);




    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        console.log("altura: " + altura);
        console.log("largura: " + largura);

        setHeight(altura * 0.7);
        setWidth(largura * 0.7);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    const [nome, setNome] = useState('');
    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [email, setEmail] = useState('');
    const [erroEmail, setErroEmail] = useState(false);
    const [textoErroEmail, setTextoErroEmail] = useState('');

    const [telefone, setTelefone] = useState('');
    const [erroTelefone, setErroTelefone] = useState(false);
    const [textoErroTelefone, setTextoErroTelefone] = useState('');

    const [assunto, setAssunto] = useState('');
    const [erroAssunto, setErroAssunto] = useState(false);
    const [textoErroAssunto, setTextoErroAssunto] = useState('');

    const [mensagem, setMensagem] = useState('');
    const [erroMensagem, setErroMensagem] = useState(false);
    const [textoErroMensagem, setTextoErroMensagem] = useState('');


    function validateNome() {
        if (nome?.length > 2) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Inclua seu sobrenome");
            return false;
        }
    }

    function validateAssunto() {
        if (assunto?.length > 2) {
            setErroAssunto(false);
            setTextoErroAssunto('');
            return true;
        } else {
            setErroAssunto(true);
            setTextoErroAssunto("Qual assunto quer tratar?");
            return false;
        }
    }




    function validateMensagem() {
        if (mensagem?.length > 2) {
            setErroMensagem(false);
            setTextoErroMensagem('');
            return true;
        } else {
            setErroMensagem(true);
            setTextoErroMensagem("Conte um pouco mais...");
            return false;
        }
    }

    function validateTelefone() {
        if (telefone?.length > 2) {
            setErroTelefone(false);
            setTextoErroTelefone('');
            return true;
        } else {
            setErroTelefone(true);
            setTextoErroTelefone("Revise esse campo, por favor!");
            return false;
        }
    }

    function validateEmail() {
        //var re1 = /\S+@\S+\.\S+/;
        var re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        var re2 = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/;
        if (re.test(email) || re2.test(email)) {

            setErroEmail(false);
            setTextoErroEmail('');
            return true;
        } else {

            setErroEmail(true);
            setTextoErroEmail("Informe um e-mail!");

            return false;
        }
    }





    const changeAssunto = (event) => {
        setAssunto(event.target.value);
    };



    async function enviarMensagem() {
        try {

            validateNome();
            validateEmail();
            validateAssunto();
            validateTelefone();
            validateMensagem();
            if (validateNome() && validateMensagem() && validateTelefone() && validateEmail() && validateAssunto()) {
                console.log("cadastro aceito");


                const enviar = {
                    nome: nome,
                    email: email,
                    telefone: telefone,
                    assunto: assunto,
                    mensagem: mensagem,
                }


                await api.post('/v1/protected/email/enviar', enviar);
                alert("Recebemos sua mensagem " + nome + ", em breve entraremos em contato com você!");
                limpar();
                /*
                                const cadastro_salvo = response.data;
                                if (cadastro_salvo) {
                                    alert("Recebemos sua mensagem " + nome + ", em breve entraremos em contato com você!");
                
                                } else {
                                    alert("Erro de Conexão, tente novamente!");
                
                                }
                                */
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    }

    useEffect(() => {

        checkDimenssoes();

    }, []);

    function limpar() {
        setNome("");
        setEmail("");
        setTelefone("");
        setMensagem("");
    }

    return (
        <div >
            <Navegador contato={'underline'} corFundo={'rgba(255,255,255,1)'} travado={true} corTexto={'white'} />

            <div style={{
                backgroundImage: `url(${background})`,

            }} >
                <div style={{ height: 1, backgroundColor: 'Wheat' }}>
                </div>

                <div style={{ backgroundColor: 'rgba(0,0,0,0.7)', color: 'white' }}>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{ paddingTop: 150, paddingBottom: 100 }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }} >
                            <Typography component="h1" variant="h5" >
                                <span style={{ fontSize: 44 }}> Como você prefere falar com a gente?</span><br></br> <br></br>
                            </Typography>
                        </Grid>

                    </Grid>
                </div>


                <div style={{ backgroundColor: 'white', color: 'black' }}>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{ paddingTop: 40, paddingBottom: 10, textAlign: 'justify', paddingInline: 20 }}
                    >

                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2} ></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} >
                            <span style={{ fontSize: 18 }}> Com um atendimento ágil e de qualidade, queremos garantir total satisfação de todos os nossos clientes!<br></br>
                                Se você ainda não é cliente, saiba que focamos num atendimento transparente, sem burocracia, comprove: todos nossos meios de contato estão listados abaixo,
                                escolha seu caminho preferido e fale diretamente com nosso time de suporte.</span><br></br> <br></br>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2} ></Grid>

                    </Grid>
                </div>

                <div style={{ backgroundColor: 'white', color: 'black' }}>


                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >

                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}></Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item xs={12} sm={12} md={12} lg={8} xl={8}
                            style={{ paddingTop: 10, paddingBottom: 40, paddingInline: 40 }}
                        >

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
                                style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}
                                container
                                direction="row"
                                justifyContent="center"

                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    item xs={1} sm={1} md={1} lg={1} xl={1}
                                >
                                    <div style={{ backgroundColor: 'MidnightBlue', width: 5, height: '75%', marginInline: 10 }}> </div>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    item xs={11} sm={11} md={11} lg={11} xl={11}
                                >

                                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11} style={{ textAlign: "left" }} >
                                        <img alt="email"
                                            style={{ weight: 30, height: 30 }}
                                            src={img_email}
                                        />
                                        <Typography component="h1" variant="h5" >
                                            <span style={{ fontSize: 24, color: '#20165b', fontWeight: 'bold' }}> E-mail</span>
                                        </Typography>
                                        <br></br>
                                        <Typography component="h1" variant="h5" >
                                            <span style={{ fontSize: 18, color: 'black' }}> Tem alguma dúvida?</span>
                                        </Typography>
                                        <br></br>
                                        <Typography component="h1" variant="h5" >

                                            <a href={"mailto:contato@gruporosinetos.com"} style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}> contato@gruporosinetos.com</a>
                                        </Typography>

                                        <br></br>

                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
                                style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}
                                container
                                direction="row"
                                justifyContent="center"

                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    item xs={1} sm={1} md={1} lg={1} xl={1}
                                >
                                    <div style={{ backgroundColor: 'DarkOrange', width: 5, height: '50%', marginInline: 10 }}> </div>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    item xs={11} sm={11} md={11} lg={11} xl={11}
                                >

                                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11} style={{ textAlign: "left" }} >
                                        <img alt="{img_telefone}"
                                            style={{ weight: 30, height: 30 }}
                                            src={img_telefone}
                                        />
                                        <Typography component="h1" variant="h5" >
                                            <span style={{ fontSize: 24, color: '#20165b', fontWeight: 'bold' }}>Telefones</span>
                                        </Typography>
                                        <br></br>
                                        <Typography component="h1" variant="h5" >
                                            <span style={{ fontSize: 18, color: 'black' }}> Ligue para nós</span>
                                        </Typography>
                                        <br></br>
                                        <Typography component="h1" variant="h5" >

                                            <span style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}> (91) 9 8897-5705</span>
                                            <br></br>

                                            <span style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}> (91) 3033-7575</span>

                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
                                style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}
                                container
                                direction="row"
                                justifyContent="center"

                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    item xs={1} sm={1} md={1} lg={1} xl={1}
                                >
                                    <div style={{ backgroundColor: 'Chartreuse', width: 5, height: '25%', marginInline: 10 }}> </div>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    item xs={11} sm={11} md={11} lg={11} xl={11}
                                >

                                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11} style={{ textAlign: "left" }} >
                                        <img alt="{whatsapp}"
                                            style={{ weight: 30, height: 30 }}
                                            src={whatsapp}
                                        />
                                        <Typography component="h1" variant="h5" >
                                            <span style={{ fontSize: 24, color: '#20165b', fontWeight: 'bold' }}>Whatsapp</span>
                                        </Typography>
                                        <br></br>
                                        <Typography component="h1" variant="h5" >
                                            <span style={{ fontSize: 18, color: 'black' }}> Converse com a gente</span>
                                        </Typography>
                                        <br></br>
                                        <Typography component="h1" variant="h5" >

                                            <a
                                                style={{ fontSize: 18, color: 'green', fontWeight: 'bold', textDecoration: 'underline' }}
                                                href="https://api.whatsapp.com/send?1=pt_BR&phone=5591988975705">

                                                _________ Web _________</a>
                                        </Typography>
                                        <br></br>
                                        <br></br>


                                    </Grid>

                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2} > </Grid>
                    </Grid>
                </div>

                <div style={{ height: 5, backgroundColor: 'green' }}>
                </div>

                <div style={{ backgroundColor: 'white', color: 'black' }}>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{ paddingTop: 40, paddingBottom: 40 }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={1} xl={1} style={{ textAlign: "left" }} >

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} style={{ textAlign: "center", padding: 20 }} >
                            {width < 900 ?
                                <Typography component="h1" variant="h5" >
                                    <span style={{ fontSize: 22, color: 'black' }}> Se preferir, preencha o formulário abaixo, logo entraremos em contato com você!</span>
                                </Typography>
                                :
                                <Typography component="h1" variant="h5" >
                                    <span style={{ fontSize: 22, color: 'black' }}> Se preferir, preencha o formulário ao lado, logo entraremos em contato com você!</span>
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "left" }} >
                            <Grid
                                container
                                direction="row"
                                item xs={12} sm={12} md={12} lg={12} xl={12}

                            >
                                <Container maxWidth="sm" component="article" className="form">
                                    <form onSubmit={(event) => {
                                        event.preventDefault();
                                    }}>
                                        <TextField
                                            id="nome"
                                            label="Nome"
                                            variant="standard"
                                            margin="dense"
                                            fullWidth
                                            value={nome}
                                            error={erroNome}
                                            helperText={textoErroNome}
                                            onChange={(event) => { setNome(event.target.value) }}
                                        />
                                        <TextField
                                            id="email"
                                            label="E-mail"
                                            variant="standard"
                                            margin="dense"
                                            fullWidth
                                            error={erroEmail}
                                            helperText={textoErroEmail}
                                            value={email}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                        />
                                        <TextField
                                            id="telefone"
                                            label="Telefone"
                                            variant="standard"
                                            margin="dense"
                                            fullWidth
                                            value={telefone}
                                            error={erroTelefone}
                                            helperText={textoErroTelefone}
                                            onChange={(event) => { setTelefone(event.target.value) }}
                                        />

                                        <Box sx={{ minWidth: 120 }} style={{ marginTop: 10 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="label-assunto">Assunto</InputLabel>
                                                <Select
                                                    labelId="label-assunto"
                                                    id="select-assunto"
                                                    value={assunto}
                                                    label="Assunto"
                                                    onChange={changeAssunto}
                                                    error={erroAssunto}
                                                    helperText={textoErroAssunto}
                                                >
                                                    <MenuItem value={"Financeiro"}>Financeiro</MenuItem>
                                                    <MenuItem value={"Comercial"}>Comercial</MenuItem>
                                                    <MenuItem value={"Administrativo"}>Suporte</MenuItem>
                                                    <MenuItem value={"Administrativo"}>Críticas</MenuItem>
                                                    <MenuItem value={"Administrativo"}>Elogios</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <TextField style={{ marginTop: 10 }}
                                            variant="standard"
                                            name="mensagem"
                                            label="Sua mensagem"
                                            id="mensagem"
                                            value={mensagem}
                                            error={erroMensagem}
                                            helperText={textoErroMensagem}
                                            fullWidth
                                            onChange={(event) => { setMensagem(event.target.value) }}
                                            multiline
                                            rows={6}
                                        />

                                    </form>
                                </Container>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >

                                    <a
                                        style={{ fontSize: 18, color: 'black', fontWeight: 'bold', marginTop: 20 }}
                                        href="/lgpd">
                                        Lei Geral de Proteção de Dados</a>

                                    <Button style={{ marginTop: 30 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => enviarMensagem()}
                                    > Enviar  </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={1} xl={1} style={{ textAlign: "left" }} >

                            </Grid>

                        </Grid>
                    </Grid>

                </div>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                    justifyContent='center'
                    alignItens='center'
                    direction='row'
                    container
                    style={{ paddingTop: width < 500 ? 10 : 100, paddingBottom: 10 , backgroundColor:'white'}}

                >

                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
                        <span className='' style={{
                            backgroundColor: 'white',
                            color: 'white',
                            WebkitTextStroke: '1px #084669',
                            fontSize: width < 500 ? 35 : 100, fontWeight: 'bold', textAlign: 'center',
                            whiteSpace: 'pre-wrap'
                        }} >{"#Onde Estamos?"}  </span>
                    </div>

                </Grid>

                <div style={{ backgroundColor: 'white', color: 'black' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{ paddingBottom: 100 }}
                    >

                        <iframe
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDCL-kmB8NJF2jWAFU8emWx7ldg-B16-nc&q=Tv.%20We%2043%2C%20422%2C%20Cidade%20Nova%2C%20Ananindeua%2C%20PA%2C%2067133-260%2C%20Brasil&maptype=roadmap"
                            width={width}
                            height={height}
                            title="Localização no Mapa"
                            style={{ paddingBottom: 30 }}
                            allowFullScreen={true}
                            onLoad={() => checkDimenssoes()}

                        >
                        </iframe>

                    </Grid>
                </div>


                <div >
                    <Rodape />
                </div>
            </div >
        </div>
    );
}


export default Contato;
