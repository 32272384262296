import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";




import api from '../../../../../../services/api';
import NavegadorGestao from '../../../../../../components/NavBarGestao';

export default function CadastrarTipoOcorrencia() {
    const history = useHistory();


    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState(''); // Add this line






    async function salvar() {
        setEstadoBotaoSalvar(true);
        console.log("Salvar chamado");
        try {

            const cadastro = {

                nome: nome,

                descricao: descricao,

            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/notificacoes/tiposocorrencias/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Tipo de Ocorrência Cadastrada!")

                history.push({
                    pathname: '/gestao/operacional/notificacoes/tiposocorrencias/listar',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
            setEstadoBotaoSalvar(false);
        }




    }






    return (
        <div>
            <NavegadorGestao
                RH={true}
                TabelasAuxiliares={true}
                Cargos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                        disabled={estadoBotaoSalvar}
                    > Salvar  </Button>
                </Grid>
                <React.Fragment >
                    <Grid container spacing={2}   >



                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            spacing={2}>




                            <Grid item xs={4} >
                                <TextField
                                    variant="standard"
                                    name="nome"
                                    label="Nome"
                                    required
                                    id="nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                    inputProps={{ maxLength: 50, minLength: 1 }}
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs={12}  >
                                <TextField

                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    id="descricao"
                                    value={descricao}
                                    onChange={e => setDescricao(e.target.value)}
                                    inputProps={{ maxLength: 600, minLength: 0 }}
                                    fullWidth

                                />
                            </Grid>


                        </Grid>





                    </Grid>


                </React.Fragment>



            </Container>

        </div >
    );
}


