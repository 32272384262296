import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Typography from '@material-ui/core/Typography';

import { useHistory } from "react-router-dom";





import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";




export default function CadastrarCondicaoPagamento() {
    const history = useHistory();


    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState(''); // Add this line

    const [codigo, setCodigo] = useState('');
    const [sigla, setSigla] = useState('');


    const [modalidadeCondicao, setModalidadeCondicao] = useState(0);
    const [numeroParcelas, setNumeroParcelas] = useState(0);
    const [tipoIntervalo, setTipoIntervalo] = useState(0);
    const [intervaloPrimeiraParcela, setIntervaloPrimeiraParcela] = useState(0);


    const [intervaloParcelas, setIntervaloParcelas] = useState(0);
    const [intervaloVariavel, setIntervaloVariavel] = useState('');

    const [tipoDivisao, setTipoDivisao] = useState(0);
    const [divisaoVariavel, setDivisaoVariavel] = useState('');


    async function salvar() {
        setEstadoBotaoSalvar(true);
        console.log("Salvar chamado");
        try {

            const cadastro = {

                nome: nome,
                descricao: descricao,
                sigla: sigla,
                codigo: codigo,
                modalidade_condicao: modalidadeCondicao,
                numero_parcelas: numeroParcelas,
                tipo_intervalo: tipoIntervalo,
                intervalo_parcelas: intervaloParcelas,
                intervalo_variavel: intervaloVariavel,
                intervalo_primeira_parcela: intervaloPrimeiraParcela,
                tipo_divisao: tipoDivisao,
                divisao_variavel: divisaoVariavel, 

            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/financeiro/condicoespagamento/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Condição de Pagamento cadastrada com sucesso!")

                history.push({
                    pathname: '/gestao/financas/condicoespagamento/listar',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
            setEstadoBotaoSalvar(false);
        }




    }






    return (
        <div>
            <NavegadorGestao
                Financas={true}
                TabelasAuxiliaresFinancas={true}
                CondicoesPagamento={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                        disabled={estadoBotaoSalvar}
                    > Salvar  </Button>
                </Grid>
                <React.Fragment >
                    <Grid container spacing={2}   >



                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            spacing={2}>




                            <Grid item xs={4} >
                                <TextField
                                    variant="standard"
                                    name="nome"
                                    label="Nome"
                                    required
                                    id="nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                    inputProps={{ maxLength: 50, minLength: 1 }}
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs={12}  >
                                <TextField

                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    id="descricao"
                                    value={descricao}
                                    onChange={e => setDescricao(e.target.value)}
                                    inputProps={{ maxLength: 600, minLength: 0 }}
                                    fullWidth

                                />
                            </Grid>





                        </Grid>


                        <Grid item xs={12}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="obrigatorio-selector-label">Modalidade</InputLabel>
                                    <Select
                                        labelId="teste-selector-label"
                                        id="teste-selector"
                                        value={modalidadeCondicao}
                                        onChange={(e) => setModalidadeCondicao(e.target.value)}
                                        label="Modalidade"
                                    >
                                        <MenuItem value={0}>Antecipado - A vista</MenuItem>
                                        <MenuItem value={1}>Após receber Nota Fiscal - Faturamento</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h6" style={{ marginTop: 5 }}>
                                Parcelamento
                            </Typography>
                        </Grid>

                        <Grid item xs={3}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="obrigatorio-selector-label">Tipo do Intervalo de Parcelamento</InputLabel>
                                    <Select
                                        labelId="teste-selector-label"
                                        id="teste-selector"
                                        value={tipoIntervalo}
                                        onChange={(e) => setTipoIntervalo(e.target.value)}
                                        label="Modalidade"
                                    >
                                        <MenuItem value={0}>Fixo</MenuItem>
                                        <MenuItem value={1}>Variavél</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={2}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TextField

                                variant="standard"
                                name="numeroParcelas"
                                label="Número de Parcelas"
                                id="numeroParcelas"
                                value={numeroParcelas}
                                onChange={(e) => {
                                    // Remove todos os caracteres não numéricos
                                    let input = e.target.value.replace(/\D/g, '');
                                    // Atualiza o estado com o valor formatado
                                    setNumeroParcelas(input);
                                }}
                                inputProps={{ maxLength: 600, minLength: 0 }}
                                fullWidth

                            />
                        </Grid>

                        {tipoIntervalo === 0 &&
                            <Grid item xs={3}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <TextField

                                    variant="standard"
                                    name="intervaloParcelas"
                                    label="Intervalo de Parcelas(dias)"
                                    id="intervaloParcelas"
                                    value={intervaloParcelas}
                                    onChange={(e) => {
                                        // Remove todos os caracteres não numéricos
                                        let input = e.target.value.replace(/\D/g, '');
                                        // Atualiza o estado com o valor formatado
                                        setIntervaloParcelas(input);
                                    }}
                                    inputProps={{ maxLength: 600, minLength: 0 }}
                                    fullWidth

                                />
                            </Grid>
                        }


                        {tipoIntervalo === 1 &&
                            <Grid item xs={3}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <TextField

                                    variant="standard"
                                    name="intervaloVariavel"
                                    label="Intervalo Variavél de Parcelas(dias)"
                                    id="intervaloVariavel"
                                    value={intervaloVariavel}
                                    onChange={(e) => {
                                        // Remove todos os caracteres não numéricos
                                        let input = e.target.value.replace(/[^\d,]/g, '');
                                        // Atualiza o estado com o valor formatado
                                        setIntervaloVariavel(input);
                                    }}
                                    inputProps={{ maxLength: 600, minLength: 0 }}
                                    fullWidth

                                />
                            </Grid>
                        }


                        <Grid item xs={3}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TextField

                                variant="standard"
                                name="intervaloPrimeiraParcela"
                                label="Intervalo da Primeira Parcela"
                                id="intervaloPrimeiraParcela"
                                value={intervaloPrimeiraParcela}
                                onChange={(e) => {
                                    // Remove todos os caracteres não numéricos
                                    let input = e.target.value.replace(/\D/g, '');
                                    // Atualiza o estado com o valor formatado
                                    setIntervaloPrimeiraParcela(input);
                                }}
                                inputProps={{ maxLength: 600, minLength: 0 }}
                                fullWidth

                            />
                        </Grid>


                        <Grid item xs={3}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="obrigatorio-selector-label">Tipo da Divisão de Valores</InputLabel>
                                    <Select
                                        labelId="teste-selector-label"
                                        id="teste-selector"
                                        value={tipoDivisao}
                                        onChange={(e) => setTipoDivisao(e.target.value)}
                                        label="Tipo da Divisão de Valores"
                                    >
                                        <MenuItem value={0}>Igualitária</MenuItem>
                                        <MenuItem value={1}>Variavél</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>


                        {tipoDivisao === 1 &&
                            <Grid item xs={3}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <TextField

                                    variant="standard"
                                    name="divisaoVariavel"
                                    label="Divisão de Valores(porcentagem)"
                                    id="divisaoVariavel"
                                    value={divisaoVariavel}
                                    onChange={(e) => {
                                        // Remove todos os caracteres não numéricos
                                        let input = e.target.value.replace(/[^\d,]/g, '');
                                        // Atualiza o estado com o valor formatado
                                        setDivisaoVariavel(input);
                                    }}
                                    inputProps={{ maxLength: 600, minLength: 0 }}
                                    fullWidth

                                />
                            </Grid>
                        }


                    </Grid>



                </React.Fragment>



            </Container>

        </div >
    );
}


