import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../../components/NavBarGestao";



export default function CadastrarArmazenamento() {

    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const [sala, setSala] = useState('');
    const [descricao, setDescricao] = useState('');
    const [corredor, setCorredor] = useState('');
    const [bloco, setBloco] = useState('');
    const [gondola, setGondola] = useState('');
    const [prateleira, setPrateleira] = useState('');




    const [unidadeSelecionada, setUnidadeSelecionada] = useState(null);
    const [unidades, setUnidades] = useState(null);




    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("v1/protected/unidades/listarTodos", {
                headers: headers
            }).then(function (response) {
                setUnidades(response.data);

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }

    useEffect(() => {




        listarInformacoes();


    }, []);


    const handleUnidade = (event, unidade) => {

        setUnidadeSelecionada(unidade);
    };


    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    async function salvar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                unidade: { id_unidade: unidadeSelecionada.id_unidade },
                descricao: descricao,
                sala: sala,
                corredor: corredor,
                bloco: bloco,
                prateleira: prateleira,
                gondola: gondola,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/almoxarifado/armazenamento/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Local de Armazenamento Cadastrado!")
                history.push({
                    pathname: "/gestao/operacional/almoxarifado/armazenamento/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    return (
        <div>
            <NavegadorGestao
                Almoxarifado={true}
                Armazenamento={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ marginTop: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >






                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={unidades}
                                            disableCloseOnSelect
                                            getOptionLabel={(unidade) => returnDescricaoUnidade(unidade).replace(/<br \/>/g, '\n')}
                                            value={unidadeSelecionada}
                                            onChange={handleUnidade}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((ordem) => {
                                                    const cotacaoText = returnDescricaoUnidade(ordem).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Unidade Administrativa " multiline rows={7} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoUnidade(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        required
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="sala"
                                        label="Sala"
                                        required
                                        id="sala"
                                        value={sala}
                                        onChange={(e) => setSala(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="corredor"
                                        label="Corredor"
                                        required
                                        id="corredor"
                                        value={corredor}
                                        onChange={(e) => setCorredor(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="bloco"
                                        label="Bloco"
                                        required
                                        id="bloco"
                                        value={bloco}
                                        onChange={(e) => setBloco(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>



                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="gondola"
                                        label="Gôndola"
                                        required
                                        id="gondola"
                                        value={gondola}
                                        onChange={(e) => setGondola(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField
                                        variant="standard"
                                        name="prateleira"
                                        label="Prateleira"
                                        required
                                        id="prateleira"
                                        value={prateleira}
                                        onChange={(e) => setPrateleira(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>







                            </Grid>





                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


