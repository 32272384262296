import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useLocation } from 'react-router-dom';

import { useHistory } from "react-router-dom";

import Autocomplete from '@material-ui/lab/Autocomplete';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastrarOrdemDeServico() {


    const classes = useStyles();
    const history = useHistory();

    const [descricao, setDescricao] = useState('');

    const [loading, setLoading] = useState(true);




    const [projetos, setProjetos] = useState([]);


    const [projetosPeD, setProjetosPeD] = useState([]);
    const [projetoPeDSelecionado, setProjetoPeDSelecionado] = useState(null);

    const [motivo, setMotivo] = useState('');

    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);


    const [solicitacoes, setSolicitacoes] = useState([]);
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(null);

    const [solicitantes, setSolicitantes] = useState([]);

    const [solicitanteSelecionado, setSolicitanteSelecionado] = useState(null);

    const [nivelPrioridade, setNivelPrioridade] = useState(0);

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);

    const handleChangeNivelPrioridade = (event) => {
        setNivelPrioridade(event.target.value);
    };

    const [autoCad, setAutoCad] = useState(false);

    const location = useLocation();

    const { categoriaCad, projetoCad, propostaCad, itensCad } = location.state || {};
    const [categoria, setCategoria] = useState(0);
    const [projetoSelecionado, setProjetoSelecionado] = useState(null);
    const [propostaSelecionada, setPropostaSelecionada] = useState(null);



    async function listarInformacoesIniciais() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("/v1/protected/solicitacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setSolicitacoes(response.data);


                const selectedSolicitacao = response.data.find(solicitacao => solicitacao.id_solicitacao === 7);


                api.get("/v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setSolicitantes(response.data)

                    if (projetoCad) {
                        setAutoCad(true);
                        setCategoria(categoriaCad);
                        setProjetos([]);
                        setProjetoSelecionado(projetoCad);
                        setPropostaSelecionada(propostaCad);

                        setSolicitacaoSelecionada(selectedSolicitacao || null); // Define null se não encontrar o solicitante
                        setDescricao("Elaborar Ordem de Compra de Insumos da Proposta Nº " + propostaCad.id_proposta + " no Projeto Nº " + projetoCad.id_projeto + " da instalação " + returnDescricaoInstalacao(projetoCad.instalacao));

                        setLoading(false);
                    }
                    else {

                        api.get("v1/protected/projetos/listartodos", {
                            headers: headers
                        }).then(function (response) {
                            setProjetos(response.data)

                            api.get("v1/protected/ped/listartodos", {
                                headers: headers
                            }).then(function (response) {
                                setProjetosPeD(response.data);

                                setLoading(false);
                            });
                        });




                    }

                });


            });



        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Solicitações: " + _err)

        }

    }

 

    function handleSetSolicitacao(solicitacao) {
        setSolicitacaoSelecionada(solicitacao);
    }


    function handleSolicitante(solicitante) {
        setSolicitanteSelecionado(solicitante);
    }


    function handleSetProjeto(projeto) {
        setProjetoSelecionado(projeto);
    }


    function handleSetProposta(proposta) {
        setPropostaSelecionada(proposta);
    }


    function handleSetProjetoPeD(projeto) {
        setProjetoPeDSelecionado(projeto);
    }




    useEffect(() => {
        listarInformacoesIniciais();


    }, []);


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n" + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    function returnDescricaoSolicitacao(solicitacao) {
        var texto = "Solicitação: " + solicitacao.solicitacao + "\n Descrição: " + solicitacao.descricao;
        return texto;
    }


    const [solicitantesSelecionados, setSolicitantesSelecionados] = useState([]);

    const handleAdicionarSolicitante = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosSolicitantes = valoresSelecionados.filter(
            (opcao) => !solicitantesSelecionados.some((selecionado) => selecionado.id_usuario === opcao.id_usuario)
        );

        setSolicitantesSelecionados([...solicitantesSelecionados, ...novosSolicitantes]);
    };


    const handleRemoverSolicitante = (event, solicitanteRemovido) => {
        const novosSolicitantes = solicitantesSelecionados.filter(
            (solicitante) => solicitante.id_usuario !== solicitanteRemovido.id_usuario
        );
        setSolicitantesSelecionados(novosSolicitantes);
    };



    async function salvar() {

        setBotaoSalvarClickado(true);

        try {



            const cadastro = {

                usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                descricao_solicitacao: descricao,
                nivel_prioridade: nivelPrioridade,
                usuario_designador: { id_usuario: Cookies.get("id_usuario") },
            }


            if (autoCad) {
                cadastro.auto_cad = 1;
            }


            if (categoria === 0) {
                if (!projetoSelecionado) {
                    alert("Selecione o Projeto!");
                    return;
                } else {
                    cadastro.projeto_id_cad = projetoSelecionado.id_projeto;
                    if (!propostaSelecionada) {
                        alert("Selecione a Proposta!")
                        return;
                    } else {
                        cadastro.proposta_id_cad =  propostaSelecionada.id_proposta 
                    }
                }
            } else if (categoria === 1) {
                if (!projetoPeDSelecionado) {
                    alert("Selecione o Projeto de Pesquisa e Desenvolvimento!");
                    return;
                } else {
                    cadastro.ped_id_cad = projetoPeDSelecionado.id_projeto;
                }
            }

            if (!solicitacaoSelecionada) {
                alert("Informe a Solicitação!");
                setBotaoSalvarClickado(false);
                return;
            } else {
                cadastro.solicitacao = { id_solicitacao: solicitacaoSelecionada.id_solicitacao };
            }


            if (solicitacaoSelecionada.id_solicitacao !== 7) {

                if (!solicitanteSelecionado.id_usuario) {
                    alert("Informe o Solicitante!");
                    setBotaoSalvarClickado(false);
                    return;
                } else {
                    console.log("Id do usuiario solicitante: " + solicitanteSelecionado.id_usuario + "\nome amigale: " +
                        solicitanteSelecionado.nome_amigavel);
                    cadastro.usuario_solicitante = { id_usuario: solicitanteSelecionado.id_usuario };
                }
            } else {
                if (!solicitantesSelecionados || solicitantesSelecionados.length < 1) {
                   
                    
                } else {
                    cadastro.solicitantes = solicitantesSelecionados;
                }
            }


                cadastro.auto_cad = 1;
                if (!usuarioSelecionado) {
                    alert("Informe o Analista a executar a O.S");
                    setBotaoSalvarClickado(false);
                    return;
                }else{
                    cadastro.analista = { id_usuario: usuarioSelecionado.id_usuario };
                    cadastro.motivo = motivo;
                }
            

            if(autoCad){
                cadastro.itens_pre_requerimento = itensCad;
                cadastro.finalizar_requerimento_itens = 1;
            }


            console.log("cadastrar chamado");




            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/ordemservico/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Ordem de Serviço Cadastrada");
                history.push({
                    pathname: "/gestao/administracao/ordensdeservicos/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }


    function returnDescricaoSolicitante(usuario) {
        var texto = usuario.nome_amigavel;
        return texto;
    }

    function returnDescricaoProjeto(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto + "\n";//+ returnDescricaoInstalacao(projeto.instalacao);
        return texto;
    }


    function returnDescricaoProposta(proposta) {
        var texto = proposta.id_proposta + " - " + proposta.descricao_proposta;
        return texto;
    }


    function returnDescricaoProjetoPeD(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto;
        return texto;
    }


    function returnDescricaoUsuario(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\nNome: " + usuario.nome_amigavel + "\nRegiões de Atuação: " + usuario.regiao_cobertura;

        var dados_colaborador = "";

        if (usuario.tipo_associacao === 0) {
            //colaborador
            dados_colaborador += ("\nDepartamentos: " + usuario.colaborador.departamentos.map(departamento => departamento.nome).join(", "));
            dados_colaborador += ("\nCargos: " + usuario.colaborador.cargos.map(cargo => cargo.nome).join(", "));
            dados_colaborador += ("\nFunções: " + usuario.colaborador.funcoes.map(funcao => funcao.nome).join(", "));


        }

        texto += ("\n" + dados_colaborador);


        return texto;
    }



    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <Container >
                {!loading &&

                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="Tipo-selector-label">Categoria</InputLabel>
                                            <Select
                                                labelId="Tipo-selector-label"
                                                id="Tipo-selector"
                                                value={categoria}
                                                disabled={autoCad}
                                                onChange={(e) => setCategoria(e.target.value)}
                                                label="Categoria"
                                            >
                                                <MenuItem value={0}>Projeto</MenuItem>
                                                <MenuItem value={1}>Pesquisa & Desenvolvimento</MenuItem>
                                                <MenuItem value={2}>Serviços e Manutenção</MenuItem>
                                                <MenuItem value={3}>Renovar Estoque</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>



                                {categoria === 0 &&
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 5 }}
                                        spacing={2}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            align="flex-end"
                                            item xs={6}
                                            style={{ marginTop: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-projeto"
                                                    options={projetos}
                                                    disabled={autoCad}
                                                    getOptionLabel={(projeto) => returnDescricaoProjeto(projeto).replace(/<br \/>/g, '\n')}
                                                    value={projetoSelecionado}
                                                    onChange={(e, v) => handleSetProjeto(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoProjeto(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Projeto " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoProjeto(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>




                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            align="flex-start"
                                            item xs={6}
                                            style={{ marginTop: 5 }}
                                        >
                                            {
                                                projetoSelecionado &&
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={8}
                                                >

                                                    <Autocomplete
                                                        disabled={autoCad}
                                                        id="select-proposta"
                                                        options={autoCad ? [] : projetoSelecionado.propostas}
                                                        getOptionLabel={(proposta) => returnDescricaoProposta(proposta).replace(/<br \/>/g, '\n')}
                                                        value={propostaSelecionada}
                                                        onChange={(e, v) => handleSetProposta(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((cotacao) => {
                                                                const cotacaoText = returnDescricaoProposta(cotacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Proposta " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoProposta(option) }} />
                                                        )}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>



                                    </Grid>
                                }

                                {categoria === 1 &&

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 5 }}
                                        spacing={2}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={6}
                                            style={{ marginTop: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-projeto-ped"
                                                    options={projetosPeD}
                                                    disabled={autoCad}
                                                    getOptionLabel={(projeto) => returnDescricaoProjetoPeD(projeto).replace(/<br \/>/g, '\n')}
                                                    value={projetoPeDSelecionado}
                                                    onChange={(e, v) => handleSetProjetoPeD(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoProjetoPeD(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Projeto de P&D " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoProjetoPeD(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-solicitacao"
                                            options={solicitacoes}
                                            disabled={autoCad}
                                            getOptionLabel={(solicitacao) => returnDescricaoSolicitacao(solicitacao).replace(/<br \/>/g, '\n')}
                                            value={solicitacaoSelecionada}
                                            onChange={(e, v) => handleSetSolicitacao(v)}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((cotacao) => {
                                                    const cotacaoText = returnDescricaoSolicitacao(cotacao).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Solicitação " multiline minRows={3} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitacao(option) }} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>



                                {(solicitacaoSelecionada && solicitacaoSelecionada.id_solicitacao === 7) ?
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={8}
                                        >

                                            <Autocomplete
                                                id="select-solicitantes"
                                                options={solicitantes}

                                                multiple
                                                getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                                value={solicitantesSelecionados}
                                                onChange={handleAdicionarSolicitante}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((solicitante) => {
                                                        const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Solicitantes " multiline minRows={2} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((solicitante, index) => (
                                                        <Chip
                                                            label={returnDescricaoSolicitante(solicitante)}
                                                            {...getTagProps({ index })}
                                                            onDelete={(event) => handleRemoverSolicitante(event, solicitante)}
                                                        />
                                                    ))
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    :

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                        >

                                            <Autocomplete
                                                id="select-ordem-solicitante"
                                                options={solicitantes}

                                                getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                                value={solicitanteSelecionado}
                                                onChange={(e, v) => handleSolicitante(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((solicitante) => {
                                                        const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Usuário Solicitador " multiline minRows={3} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                )}

                                            />
                                        </Grid>
                                    </Grid>
                                }


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ paddingTop: 4 }}
                                >
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="area-selector-label">Nível Prioridade</InputLabel>
                                            <Select
                                                labelId="area-selector-label"
                                                id="area-selector"
                                                value={nivelPrioridade}
                                                onChange={handleChangeNivelPrioridade}

                                                label="Nível SLA"
                                            >
                                                <MenuItem value={0}>
                                                    Nível 0 - Comum
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    Nível 1 - Urgente
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Nível 2 - Priorizar
                                                </MenuItem>


                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                <Grid item xs={12}
                                    style={{ paddingTop: 4 }}
                                >
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        required
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={2}
                                    />
                                </Grid>




                            </Grid>


                                <Grid
                                    direction={"row"}
                                    item xs={12}
                                    container
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    spacing={2}
                                >

                                    <Grid
                                        item xs={5}
                                    >
                                        <Autocomplete
                                            id="select-analista"
                                            options={solicitantes}
                                            getOptionLabel={(usuario) => returnDescricaoUsuario(usuario).replace(/<br \/>/g, '\n')}
                                            value={usuarioSelecionado}
                                            onChange={(e, v) => setUsuarioSelecionado(v)}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((cotacao) => {
                                                    const cotacaoText = returnDescricaoUsuario(cotacao).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Analista Responsável " multiline rows={3} />
                                            )}

                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoUsuario(option).replace(/\n/g, '<br>') }} />
                                            )}
                                        />

                                    </Grid>

                                    <Grid
                                        item xs={12}
                                        style={{ padding: 5 }}
                                    >
                                        <TextField
                                            label="Motivo"
                                            variant="outlined"
                                            fullWidth
                                            value={motivo}
                                            multiline
                                            minRows={1}
                                            onChange={(e) => setMotivo(e.target.value)}
                                        />
                                    </Grid>

                                </Grid>
                            



                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={10}
                            >
                                <Button style={{ marginTop: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoSalvarClickado}
                                > Salvar  </Button>
                            </Grid>

                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


