import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useHistory } from "react-router-dom";


import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from '@mui/material';



import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";



export default function CadastrarInstituicaoBancaria() {
    const history = useHistory();


    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [banco, setBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [conta, setConta] = useState('');
    const [descricao, setDescricao] = useState('');

    const [bancos, setBancos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [unidades, setUnidades] = useState([]);

    const [unidadeSelecionada, setUnidadeSelecionada] = useState(null);

    async function salvar() {

        if (!unidadeSelecionada) {
            alert("Informe a Unidade Administrativa!");
            return;
        }


        setEstadoBotaoSalvar(true);
        console.log("Salvar chamado");
        try {

            const cadastro = {

                banco: returnDescricaoBanco(banco),
                unidade: { id_unidade: unidadeSelecionada.id_unidade },
                agencia: agencia,
                conta: conta,
                descricao: descricao,


            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/financeiro/instituicaobancaria/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Instituição Bancária cadastrada!")

                history.push({
                    pathname: '/gestao/financas/instituicaobancaria/listar',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
            setEstadoBotaoSalvar(false);
        }




    }



    async function listarInformacoes() {



        try {
            const response = await api.get('https://brasilapi.com.br/api/banks/v1');
            setBancos(response.data);

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            api.get("v1/protected/unidades/listarTodos", {
                headers: headers
            }).then(function (response) {
                setUnidades(response.data)

                setLoading(false);

            });
        } catch (error) {
            console.log("Erro ao buscar bancos.");
        }
    }


    const handleChangeBanco = (event, _banco) => {

        setBanco(_banco);
    };


    const handleUnidade = (event, unidade) => {

        setUnidadeSelecionada(unidade);
    };


    useEffect(() => {

        listarInformacoes();


    }, []);


    function returnDescricaoBanco(banco) {
        if (banco) {
            var texto = banco.fullName + " | Código: " + banco.code;
            return texto;
        }
        else
            return '';
    }




    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    return (
        <div>
            <NavegadorGestao
                Financas={true}
                TabelasAuxiliaresFinancas={true}
                InstituicaoBancaria={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            {!loading &&
                <Container style={{ paddingTop: 5 }} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item xs={10}
                    >
                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={salvar}
                            disabled={estadoBotaoSalvar}
                        > Salvar  </Button>
                    </Grid>
                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={3}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={bancos}
                                            disableCloseOnSelect
                                            getOptionLabel={(banco) => returnDescricaoBanco(banco).replace(/<br \/>/g, '\n')}
                                            value={banco}
                                            onChange={handleChangeBanco}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((grupo) => {
                                                    const cotacaoText = returnDescricaoBanco(grupo).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Banco " multiline minRows={1} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoBanco(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="agencia"
                                        label="Agência"
                                        required
                                        id="agencia"
                                        value={agencia}
                                        onChange={(e) => setAgencia(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="conta"
                                        label="Conta"
                                        required
                                        id="conta"
                                        value={conta}
                                        onChange={(e) => setConta(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={unidades}
                                            disableCloseOnSelect
                                            getOptionLabel={(unidade) => returnDescricaoUnidade(unidade).replace(/<br \/>/g, '\n')}
                                            value={unidadeSelecionada}
                                            onChange={handleUnidade}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((ordem) => {
                                                    const cotacaoText = returnDescricaoUnidade(ordem).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Unidade Administrativa " multiline rows={7} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoUnidade(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    required
                                    id="descricao"
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    fullWidth
                                    multiline
                                    minRows={2}
                                />
                            </Grid>




                        </Grid>


                    </React.Fragment>



                </Container>
            }
        </div >
    );
}


