import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastroEntradaEstoque() {

    const [loading, setLoading] = useState(true);


    const classes = useStyles();
    const history = useHistory();
    const [descricao, setDescricao] = useState('');




    const [ordemCompraSelecionada, setOrdemCompraSelecionada] = useState(null);
    const [ordens, setOrdens] = useState(null);

    const [compraSelecionada, setCompraSelecionada] = useState(null);


    const [executores, setExecutores] = useState(null);

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordensdecompras/ordemdecompra/listarordensautorizadasnaorecebidas", {
                headers: headers
            }).then(function (response) {
                setOrdens(response.data)

                api.get("/v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setExecutores(response.data)

                    setLoading(false);
                });

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);


    function returnDescricaoFornecedor(fornecedor) {
        var texto = "Fornecedor_ID: " + fornecedor.id_fornecedor + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\nNome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }


    function returnDescricaoOrdem(ordem) {
        var texto = "Ordem_ID: " + ordem.id_ordem_compra + "\nDescrição da Ordem: " + ordem.descricao;
        return texto;
    }


    function returnDescricaoExecutor(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel;
        return texto;
    }

    function returnDescricaoCompra(compra) {
        var texto = "Compra_ID: " + compra.id_pre_cotacao + "\nFornecedor: " + compra.nome_fornecedor_amigavel + "\nValor: " + (compra.valor_total + compra.valor_frete);
        return texto;
    }

    async function salvar() {





        if (!ordemCompraSelecionada.id_ordem_compra) {
            alert("Informe a Ordem de Compra!");
            return;
        } else {


        }


        if (!compraSelecionada.id_pre_cotacao) {
            alert("Informe a Compra!");
            return;
        } else {


        }


        try {

            console.log("cadastrar chamado");
            const cadastro = {


                descricao: descricao,
                usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                usuario_executor: { id_usuario: Cookies.get("id_usuario") },
                id_ordem_compra: ordemCompraSelecionada.id_ordem_compra ,
                pre_cotacao: { id_pre_cotacao: compraSelecionada.id_pre_cotacao },



            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/almoxarifado/entradaestoque/cadastrarentradaestoque', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Nova Operação Entrada de Estoque Cadastrada")
                history.push({
                    pathname: "/gestao/operacional/almoxarifado/entradaestoque/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }


    function handleSetOrdemCompra(ordem) {
        setOrdemCompraSelecionada(ordem);
    }




    function handleSetCompra(compra) {
        setCompraSelecionada(compra);
    }




    return (
        <div>

            <NavegadorGestao
                Almoxarifado={true}
                OpEntrada={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >



                            <Grid
                                style={{ paddingTop: 5 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={6}
                                    style={{ marginTop: 5 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                    >

                                        <Autocomplete
                                            id="select-ordem"
                                            options={ordens}
                                            disableCloseOnSelect
                                            getOptionLabel={(ordem) => returnDescricaoOrdem(ordem).replace(/<br \/>/g, '\n')}
                                            value={ordemCompraSelecionada}
                                            onChange={(e, v) => handleSetOrdemCompra(v)}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((cotacao) => {
                                                    const cotacaoText = returnDescricaoOrdem(cotacao).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Ordem de Compra " multiline minRows={3} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoOrdem(option) }} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    ordemCompraSelecionada &&
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={6}
                                        style={{ marginTop: 5 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                        >

                                            <Autocomplete
                                                id="select-compra"
                                                options={
                                                    ordemCompraSelecionada.pre_cotacoes.filter((cotacao) =>
                                                        cotacao.itens_pre_cotacao.some((item) => item.selecionado === 1)
                                                    )
                                                }
                                                disableCloseOnSelect
                                                getOptionLabel={(compra) => returnDescricaoCompra(compra).replace(/<br \/>/g, '\n')}
                                                value={compraSelecionada}
                                                onChange={(e, v) => handleSetCompra(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((cotacao) => {
                                                        const cotacaoText = returnDescricaoCompra(cotacao).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Compra " multiline minRows={3} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoCompra(option) }} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                }


                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    id="descricao"
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </Grid>




                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={10}
                            >
                                <Button style={{ margin: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                > Salvar  </Button>
                            </Grid>

                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


