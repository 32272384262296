import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { parse, format } from 'date-fns';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";



import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function CadastrarNotificacao() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();




    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');
    const [descricaoImagem5, setDescricaoImagem5] = useState('');
    const [descricaoImagem6, setDescricaoImagem6] = useState('');


    const [dataOcorrencia, setDataOcorrencia] = useState('');
    const [horaOcorrencia, setHoraOcorrencia] = useState('');


    const [tipoNotificacao, setTipoNotificacao] = useState(0);

    const [tipoRegistro, setTipoRegistro] = useState(0);

    const handleChangeRegistro = (event) => {
        setTipoRegistro(event.target.value);
        Cookies.set("tipoRegistro", event.target.value);

    };

    const handleChangeTipoNotificacao = (event) => {
        setTipoNotificacao(event.target.value);
        Cookies.set("tipoNotificacao", event.target.value);
    };


    const [formaConhecimento, setFormaConhecimento] = useState(0);

    const handleChangeFormaConhecimento = (event) => {
        setFormaConhecimento(event.target.value);
        Cookies.set("formaConhecimento", event.target.value);
    };

    const [classificacao, setClassificacao] = useState(0);

    const handleChangeClassificacao = (event) => {
        setClassificacao(event.target.value);
        Cookies.set("classificacao", event.target.value);
    };

    const [descricao, setDescricao] = useState('');
    const [acoes, setAcoes] = useState('');

    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);

    const [imagemSelecionada5, setImagemSelecionada5] = useState(null);
    const [imagemSelecionada6, setImagemSelecionada6] = useState(null);



    const [loading, setLoading] = useState(true);

    const [tipoOcorrenciaSelecionada, setTipoOcorrenciaSelecionada] = useState(null);


    const [instalacoes, setInstalacoes] = useState(null);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);


    const [tiposOcorrencias, setTiposOcorrencias] = useState([]);

    async function listarInformacoesIniciais() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/clientes/instalacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setInstalacoes(response.data)

                api.get("v1/protected/notificacoes/tiposocorrencias/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setTiposOcorrencias(response.data)

                    setLoading(false);

                });



            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Instalações: " + _err)

        }

    }

    function handleSetInstalacao(instalacao) {
        setInstalacaoSelecionada(instalacao);
        Cookies.set("instalacaoSelecionada",instalacao);
    }


    function handleSetTipoOcorrencia(tipoOcorrencia) {
        setTipoOcorrenciaSelecionada(tipoOcorrencia);
        Cookies.set("tipoOcorrenciaSelecionada",tipoOcorrencia);
    }



    useEffect(() => {
        listarInformacoesIniciais();


        if(Cookies.get("tipoRegistro") !== undefined){
            setTipoRegistro(Cookies.get("tipoRegistro") );
        }


    }, []);


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }

    function returnDescricaoTipoOcorrencia(ocorrencia) {
        var texto = "ID: " + ocorrencia.id_tipo_ocorrencia +
            "\nNome: " + ocorrencia.nome +
            "\n" + ocorrencia.descricao;

        return texto;
    }


    const onDrop1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.startsWith('image/')) {
            alert('Apenas arquivos de imagem são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada1(acceptedFiles[0]);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.startsWith('image/')) {
            alert('Apenas arquivos de imagem são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada2(acceptedFiles[0]);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.startsWith('image/')) {
            alert('Apenas arquivos de imagem são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada3(acceptedFiles[0]);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.startsWith('image/')) {
            alert('Apenas arquivos de imagem são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada4(acceptedFiles[0]);
    }, []);


    const onDrop5 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.startsWith('image/')) {
            alert('Apenas arquivos de imagem são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada5(acceptedFiles[0]);
    }, []);



    const onDrop6 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.startsWith('image/')) {
            alert('Apenas arquivos de imagem são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada6(acceptedFiles[0]);
    }, []);




    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });


    const { getRootProps: getRootProps5, getInputProps: getInputProps5 } = useDropzone({
        onDrop: onDrop5,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });


    const { getRootProps: getRootProps6, getInputProps: getInputProps6 } = useDropzone({
        onDrop: onDrop6,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });




    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });



    async function vizualizar() {


        if (!instalacaoSelecionada) {
            alert("Selecione a Instalação");
            return;
        }

        if (!tipoOcorrenciaSelecionada) {
            alert("Selecione o Sub Tipo da Notificação");
            return;
        }

        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            // Adicionar os dados do procedimento
            formData.append('tipo_registro', tipoRegistro);
            formData.append('forma_conhecimento', formaConhecimento);
            formData.append('classificacao', classificacao);
            formData.append('descricao', descricao);
            formData.append('acoes', acoes);

            formData.append('usuario_criador.id_usuario', Cookies.get('id_usuario'));
            formData.append('tipo_ocorrencia.id_tipo_ocorrencia', tipoOcorrenciaSelecionada.id_tipo_ocorrencia);

            formData.append('tipo_notificacao', tipoNotificacao);


            if (dataOcorrencia && parse(dataOcorrencia, 'dd/MM/yyyy', new Date()) instanceof Date) {

                formData.append('data_ocorrencia', format(parse(dataOcorrencia, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'));
            } else {
                alert("Informe uma data válida!");
                return;
            }

            if (horaOcorrencia && parse(horaOcorrencia, 'HH:mm', new Date()) instanceof Date) {

                formData.append('hora_ocorrencia', format(parse(horaOcorrencia, 'HH:mm', new Date()), 'HH:mm'));
            } else {
                alert("Informe uma hora válida!");
                return;
            }



            formData.append('instalacao.id_instalacao', instalacaoSelecionada.id_instalacao);


            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);
            formData.append('descricao_imagem5', descricaoImagem5);
            formData.append('descricao_imagem6', descricaoImagem6);



            if (imagemSelecionada1) formData.append('imagens', imagemSelecionada1, imagemSelecionada1.name);
            if (imagemSelecionada2) formData.append('imagens', imagemSelecionada2, imagemSelecionada2.name);
            if (imagemSelecionada3) formData.append('imagens', imagemSelecionada3, imagemSelecionada3.name);
            if (imagemSelecionada4) formData.append('imagens', imagemSelecionada4, imagemSelecionada4.name);
            if (imagemSelecionada5) formData.append('imagens', imagemSelecionada5, imagemSelecionada5.name);
            if (imagemSelecionada6) formData.append('imagens', imagemSelecionada6, imagemSelecionada6.name);


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }


            const response = await api.post("/v1/protected/notificacoes/vizualizar", formData, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {


                // Cria um blob com os dados recebidos
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Cria uma URL do blob
                const blobUrl = URL.createObjectURL(blob);

                // Abre uma nova guia com o arquivo PDF
                window.open(blobUrl, '_blank');

            } else {
                alert("Erro de Conexão, tente novamente");
            }





        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente");

        }

    }

    async function funcaoSalvar() {


        if (!instalacaoSelecionada) {
            alert("Selecione a Instalação");
            return;
        }

        if (!tipoOcorrenciaSelecionada) {
            alert("Selecione o Sub Tipo da Notificação");
            return;
        }

        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            // Adicionar os dados do procedimento
            formData.append('tipo_registro', tipoRegistro);
            formData.append('forma_conhecimento', formaConhecimento);
            formData.append('classificacao', classificacao);
            formData.append('descricao', descricao);
            formData.append('acoes', acoes);

            formData.append('usuario_criador.id_usuario', Cookies.get('id_usuario'));
            formData.append('tipo_ocorrencia.id_tipo_ocorrencia', tipoOcorrenciaSelecionada.id_tipo_ocorrencia);

            formData.append('tipo_notificacao', tipoNotificacao);



            if (dataOcorrencia && parse(dataOcorrencia, 'dd/MM/yyyy', new Date()) instanceof Date) {

                formData.append('data_ocorrencia', format(parse(dataOcorrencia, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'));
            } else {
                alert("Informe uma data válida!");
                return;
            }

            if (horaOcorrencia && parse(horaOcorrencia, 'HH:mm', new Date()) instanceof Date) {

                formData.append('hora_ocorrencia', format(parse(horaOcorrencia, 'HH:mm', new Date()), 'HH:mm'));
            } else {
                alert("Informe uma hora válida!");
                return;
            }



            formData.append('instalacao.id_instalacao', instalacaoSelecionada.id_instalacao);



            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);
            formData.append('descricao_imagem5', descricaoImagem5);
            formData.append('descricao_imagem6', descricaoImagem6);



            if (imagemSelecionada1) formData.append('imagens', imagemSelecionada1, imagemSelecionada1.name);
            if (imagemSelecionada2) formData.append('imagens', imagemSelecionada2, imagemSelecionada2.name);
            if (imagemSelecionada3) formData.append('imagens', imagemSelecionada3, imagemSelecionada3.name);
            if (imagemSelecionada4) formData.append('imagens', imagemSelecionada4, imagemSelecionada4.name);
            if (imagemSelecionada5) formData.append('imagens', imagemSelecionada5, imagemSelecionada5.name);
            if (imagemSelecionada6) formData.append('imagens', imagemSelecionada6, imagemSelecionada6.name);





            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/notificacoes/cadastrar', formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Notificação Cadastrada!");
                history.push({
                    pathname: "/gestao/operacional/notificacoes/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Notificacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }}>
                {!loading &&

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Imagens" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>
                            <Grid
                                style={{ paddingTop: 5 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item xs={6}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={vizualizar}
                                    > Vizualizar  </Button>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={6}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoClicado}
                                    > Salvar  </Button>
                                </Grid>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 5 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >



                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                            style={{ margin: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                            >

                                                <Autocomplete
                                                    id="select-instalacao"
                                                    options={instalacoes}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                    value={instalacaoSelecionada}
                                                    onChange={(e, v) => handleSetInstalacao(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoInstalacao(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={3}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="tipo_notificacao_registro-label">Tipo da Notificação</InputLabel>
                                                    <Select
                                                        labelId="tipo_notificacao_registro-label"
                                                        id="area-tipo_registro"
                                                        value={tipoNotificacao}
                                                        onChange={handleChangeTipoNotificacao}
                                                        label="Tipo da Notificação"
                                                    >
                                                        <MenuItem value={0}>Ocorrência</MenuItem>
                                                        <MenuItem value={1}>Manutenção</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                            style={{ margin: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                            >

                                                <Autocomplete
                                                    id="select-subtipo_ocorrencia"
                                                    options={tiposOcorrencias}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(tipoocorrencia) => returnDescricaoTipoOcorrencia(tipoocorrencia).replace(/<br \/>/g, '\n')}
                                                    value={tipoOcorrenciaSelecionada}
                                                    onChange={(e, v) => handleSetTipoOcorrencia(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoTipoOcorrencia(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Subtipo da Notificação " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoTipoOcorrencia(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        {instalacaoSelecionada &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ margin: 5 }}
                                            >
                                                <span>Destinatários:</span>
                                                <Typography variant="h7">
                                                    {instalacaoSelecionada.contatos
                                                        .filter(contato => contato.recebe_ocorrencias === 1)
                                                        .map((contato, index, array) => (
                                                            <React.Fragment key={index}>
                                                                {contato.e_mail_contato}
                                                                {index < array.length - 1 && ', '}
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </Typography>
                                            </Grid>
                                        }

                                        <Grid item xs={3}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="area-tipo_registro-label">Tipo Registro</InputLabel>
                                                    <Select
                                                        labelId="area-tipo_registro-label"
                                                        id="area-tipo_registro"
                                                        value={tipoRegistro}
                                                        onChange={handleChangeRegistro}
                                                        label="Tipo do Registro"
                                                    >
                                                        <MenuItem value={0}>Prevenção</MenuItem>
                                                        <MenuItem value={1}>Regularização</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={3}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="Tipo-forma-label">Forma de Conhecimento</InputLabel>
                                                    <Select
                                                        labelId="Tipo-forma-label"
                                                        id="Tipo-forma"
                                                        value={formaConhecimento}
                                                        onChange={handleChangeFormaConhecimento}
                                                        label="Forma de Conhecimento"
                                                    >
                                                        <MenuItem value={0}>Registro</MenuItem>
                                                        <MenuItem value={1}>Ronda Presencial</MenuItem>
                                                        <MenuItem value={2}>Visita Técnica</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="classificacao-selector-label">Classificação</InputLabel>
                                                <Select
                                                    labelId="classificacao-selector-label"
                                                    id="classificacao-selector"
                                                    value={classificacao}
                                                    onChange={handleChangeClassificacao}
                                                    label="Classificação"
                                                >
                                                    <MenuItem value={0}>Positiva - Automatico</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"

                                        >

                                            <Grid item xs={2}
                                                style={{ padding: 5 }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    name="dataOcorrencia"
                                                    label="Data da Identificação"
                                                    id="dataOcorrencia"
                                                    value={dataOcorrencia}
                                                    onChange={(e) => setDataOcorrencia(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={2}
                                                style={{ padding: 5 }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    name="horaOcorrencia"
                                                    label="Hora da Identificação"
                                                    id="horaOcorrencia"
                                                    value={horaOcorrencia}
                                                    onChange={(e) => setHoraOcorrencia(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>


                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="descricao"
                                                label="Descrição"
                                                id="descricao"
                                                value={descricao}
                                                onChange={(e) => setDescricao(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{ margin: 5 }}>
                                            <TextField
                                                variant="standard"
                                                name="acoes"
                                                label="Ações"
                                                required
                                                id="acoes"
                                                value={acoes}
                                                onChange={(e) => setAcoes(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>







                                    </Grid>

                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 5 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >



                                        <Grid item xs={12} style={{ padding: 40 }}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada1 ? (
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada1)}
                                                                alt="Imagem Selecionada 1"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                        ) : (
                                                            <div {...getRootProps1()} style={dropzoneStyles}>
                                                                <input {...getInputProps1()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem1"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem1"
                                                                value={descricaoImagem1}
                                                                onChange={(e) => setDescricaoImagem1(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada2 ? (
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada2)}
                                                                alt="Imagem Selecionada 2"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                        ) : (
                                                            <div {...getRootProps2()} style={dropzoneStyles}>
                                                                <input {...getInputProps2()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem2"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem2"
                                                                value={descricaoImagem2}
                                                                onChange={(e) => setDescricaoImagem2(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada3 ? (
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada3)}
                                                                alt="Imagem Selecionada 3"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                        ) : (
                                                            <div {...getRootProps3()} style={dropzoneStyles}>
                                                                <input {...getInputProps3()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem3"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem3"
                                                                value={descricaoImagem3}
                                                                onChange={(e) => setDescricaoImagem3(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>


                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada4 ? (
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada4)}
                                                                alt="Imagem Selecionada 4"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                        ) : (
                                                            <div {...getRootProps4()} style={dropzoneStyles}>
                                                                <input {...getInputProps4()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem4"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem4"
                                                                value={descricaoImagem4}
                                                                onChange={(e) => setDescricaoImagem4(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>



                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada5 ? (
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada5)}
                                                                alt="Imagem Selecionada 5"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                        ) : (
                                                            <div {...getRootProps5()} style={dropzoneStyles}>
                                                                <input {...getInputProps5()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem5"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem5"
                                                                value={descricaoImagem5}
                                                                onChange={(e) => setDescricaoImagem5(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>




                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada6 ? (
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada6)}
                                                                alt="Imagem Selecionada 6"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                        ) : (
                                                            <div {...getRootProps6()} style={dropzoneStyles}>
                                                                <input {...getInputProps6()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem6"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem6"
                                                                value={descricaoImagem6}
                                                                onChange={(e) => setDescricaoImagem6(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        </Grid>

                                    </Grid>



                                </SwipeableViews>







                            </Grid>





                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


