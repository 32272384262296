import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import NavBarAdmin from "../../../../../components/NavBarAdmin";
import Rodape from '../../../../../components/Rodape';
import icone_unidades from '../../../../../assets/imgs/icone_unidades.png';
import MenuRecursosOperacionais from '../components/menu';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));


export default function RecursosOperacionais() {


    const classes = useStyles();


    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#DCDCDC' }}>
                <MenuRecursosOperacionais
                    link1={["Home", "/"]}
                    link2={["Minha Conta", "/minhaconta"]}
                    link3={["Área de Operação", "/gestao/operacional"]}
                    titulo={"Visão Geral"}
                />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Grid
                        container
                        item xs={12}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                    >

                        <Grid item xs={5}
                            container
                            direction='row'
                            alignItems={"center"}
                            justifyContent={"center"}
                            component={Paper} elevation={6} className={"Paper"}
                            style={{ backgroundColor: '#1C1C1C', color: 'white', margin: 5, padding: 50, marginTop: 20, paddingBottom: 20, borderRadius: '10px' }}
                        >
                            <Grid xs={3}
                                container
                                direction='row'
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <img style={{ width: 100, height: 'auto' }} src={icone_unidades} alt="icone_unidades" />
                            </Grid>
                            <Grid xs={9}
                                container
                                direction='row'
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Typography variant="h3" >{"3 Unidades Administrativas"}</Typography>
                            </Grid>

                        </Grid>

                    </Grid>

                </main>
            </div>

            <div>
                <Rodape />
            </div>
        </div>
    );


}



