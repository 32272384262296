import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';


import { useHistory, useParams } from "react-router-dom";

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import Skeleton from '@material-ui/lab/Skeleton';
import { MenuItem, Select, InputLabel } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function ConfigurarZona() {

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { idZona } = useParams();

    const [descricao, setDescricao] = useState(1);
    const [linkLocal, setLinkLocal] = useState('');
    const [linkExterno, setLinkExterno] = useState('');
    const [idReceptor, setIdReceptor] = useState(0);
    const [rotacao, setRotacao] = useState(0);

    //dados de equipamento
    const [tipoEquipamento, setTipoEquipamento] = useState(0);
    const [descricaoEquipamento, setDescricaoEquipamento] = useState('');
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState('');
    const [raioPerimetro, setRaioPerimetro] = useState(0);


    useEffect(() => {

        async function listarZona() {
            try {

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.get("/v1/protected/zonas/retornardadoszona/" + idZona, {
                    headers: headers
                }).then(function (response) {
                    setDescricao(response.data.descricao);
                    setLinkLocal(response.data.link_local);
                    setLinkExterno(response.data.link_externo);
                    setIdReceptor(response.data.receptor.id_receptor);

                    setTipoEquipamento(response.data.tipo_equipamento);
                    setDescricaoEquipamento(response.data.descricao_equipamento);
                    setLatitude(response.data.latitude);
                    setLongitude(response.data.longitude)
                    setRaioPerimetro(response.data.raio_perimetro)
                    setRotacao(response.data.rotacao);

                    setLoading(false);
                });
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar dados da zona: " + _err)

            }


        }

        listarZona();





    }, [idZona]);


    async function atualizar() {

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao: descricao,
                link_local: linkLocal,
                link_externo: linkExterno,
                tipo_equipamento: tipoEquipamento,
                descricao_equipamento: descricaoEquipamento,
                latitude: latitude,
                longitude: longitude,
                raio_perimetro: raioPerimetro,
                rotacao: rotacao,
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/zonas/atualizar/' + idZona, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Configurações atualizadas com sucesso!")

                history.push({
                    pathname: '/gerenciarreceptor/' + idReceptor,
                })

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    }

    const handleTipoEquipamento = (event) => {
        setTipoEquipamento(Number(event.target.value));
    };


    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Configurar Zona"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >
                                <Grid container spacing={2}   >


                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}>
                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="descricao"
                                                label="Descrição"
                                                required
                                                id="descricao"
                                                value={descricao}
                                                onChange={e => setDescricao(e.target.value)}
                                                fullWidth
                                                multiline
                                                rows={3}

                                            />
                                        </Grid>


                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="linkLocal"
                                                label="Link Local"
                                                required
                                                id="linkLocal"
                                                value={linkLocal}
                                                onChange={e => setLinkLocal(e.target.value)}
                                                fullWidth
                                                multiline
                                                rows={3}

                                            />
                                        </Grid>


                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="linkExterno"
                                                label="Link Externo"
                                                required
                                                id="linkExterno"
                                                value={linkExterno}
                                                onChange={e => setLinkExterno(e.target.value)}
                                                fullWidth

                                            />
                                        </Grid>


                                        <Grid
                                            item xs={12}
                                            direction="row"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >

                                            <Typography style={{ fontWeight: 'bold', fontSize: '22px' }} >
                                                Dados do Equipamento:
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item xs={3}
                                            direction="column"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >

                                            <InputLabel id="tipoEquipamento">Tipo do Equipamento:</InputLabel>
                                            <Select
                                                labelId="tipoEquipamento"
                                                id="tipoEquipamento"
                                                value={tipoEquipamento}
                                                name="tipoEquipamento"
                                                label="Tipo do Equipamento"
                                                style={{ paddingLeft: 5 }}
                                                onChange={handleTipoEquipamento}
                                            >
                                                <MenuItem value={0}>Indefinido</MenuItem>
                                                <MenuItem value={1}>Câmera</MenuItem>
                                                <MenuItem value={2}>Sensor de Presença</MenuItem>
                                                <MenuItem value={3}>Sensor de Barreira</MenuItem>
                                                <MenuItem value={4}>Botoeira</MenuItem>
                                                <MenuItem value={5}>Controle de Acesso</MenuItem>

                                            </Select>
                                        </Grid>


                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="descricaoEquipamento"
                                                label="Descrição do Equipamento"
                                                required
                                                id="descricaoEquipamento"
                                                value={descricaoEquipamento}
                                                onChange={e => setDescricaoEquipamento(e.target.value)}
                                                fullWidth

                                            />
                                        </Grid>


                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="latitude"
                                                label="Latitude"
                                                required
                                                id="latitude"
                                                value={latitude}
                                                onChange={e => setLatitude(e.target.value)}
                                                fullWidth

                                            />
                                        </Grid>


                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="longitude"
                                                label="Longitude"
                                                required
                                                id="longitude"
                                                value={longitude}
                                                onChange={e => setLongitude(e.target.value)}
                                                fullWidth

                                            />
                                        </Grid>


                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="raioPerimetro"
                                                label="Raio Perímetro"
                                                required
                                                id="raioPerimetro"
                                                value={raioPerimetro}
                                                onChange={(e) => setRaioPerimetro(e.target.value)}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={12}  >

                                            <TextField
                                                variant="standard"
                                                name="rotacao"
                                                label="Rotação"
                                                required
                                                id="rotacao"
                                                value={rotacao}
                                                onChange={(e) => setRotacao(e.target.value)}
                                                fullWidth

                                            />
                                        </Grid>

                                    </Grid>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        item xs={10}
                                    >
                                        <Button style={{ marginTop: 50 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={atualizar}

                                        > Atualizar  </Button>
                                    </Grid>

                                </Grid>


                            </React.Fragment>
                        }
                    </Container>
                </main >
            </div >

        </div >
    );
}


