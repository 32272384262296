import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function CadastroFornecedor() {
    const history = useHistory();
    const { tipoMenuRender } = useParams();

    const [contatos, setContatos] = useState();

    const [parametrosEndereco, setParametrosEndereco] = useState(
        {
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            cidade: "",
            estado: "",
            cep: "",
            latitude: "",
            longitude: "",
        }
    );


    const [contato, setContato] = useState('');

    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */

    const [tipoLoja, setTipoLoja] = useState(0);


    const handleTipoLoja = (event) => {
        setTipoLoja(event.target.value);
    };

    const [recebeOrdens, setRecebeOrdens] = useState(0);


    const handleRecebeOrdens = (event) => {
        setRecebeOrdens(event.target.value);
    };


    const [site, setSite] = useState('');


    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');

    /* variaveis de estado de cadastro de pessoa juridica*/


    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);

    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');

    /*variaveis gerais*/


    /*variaveis de controle pessoa fisica */
    const [erroCpf, setErroCpf] = useState(false);
    const [textoErroCpf, setTextoErroCpf] = useState('');

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');




    /*variaveis de controle pessoa fisica */


    /*variaveis de controle pessoa juridica */
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [textoErroRazaoSocial, setTextoErroRazaoSocial] = useState('');

    const [erroNomeFantasia, setErroNomeFantasia] = useState(false);
    const [textoErroNomeFantasia, setTextoErroNomeFantasia] = useState('');

    const [erroCnpj, setErroCnpj] = useState(false);
    const [textoErroCnpj, setTextoErroCnpj] = useState('');
    /*variaveis de controle pessoa juridica */



    /* variaveis  de controle gerais */

    const [erroEmail, setErroEmail] = useState(false);
    const [textoErroEmail, setTextoErroEmail] = useState('');

    const [erroPassword, setErroPassword] = useState(false);
    const [textoErroPassword, setTextoErroPassword] = useState('');
    /* variaveis  de controle gerais */


    async function handleCnpj(cnpj) {

        // Remove caracteres não numéricos do CNPJ
        let cnpjSemFormato = cnpj.replace(/\D/g, '');

        cnpjSemFormato = cnpjSemFormato.slice(0, 14);


        setCnpj(cnpjSemFormato);

        if (cnpjSemFormato.length === 14) {
            console.log("cnpj" + cnpjSemFormato);
            const response = await api.get('https://brasilapi.com.br/api/cnpj/v1/' + cnpjSemFormato);
            setRazaoSocial(response.data.razao_social);
            setNomeFantasia(response.data.razao_social);
            setPorte(response.data.porte);
            setAtividade(response.data.cnae_fiscal_descricao);

            //endereco
            setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': response.data.logradouro }))
            setParametrosEndereco(prevState => ({ ...prevState, 'bairro': response.data.bairro }))

            setParametrosEndereco(prevState => ({ ...prevState, 'estado': response.data.uf }))
            setParametrosEndereco(prevState => ({ ...prevState, 'numero': response.data.numero }))
            setParametrosEndereco(prevState => ({ ...prevState, 'cep': response.data.cep }))
            setParametrosEndereco(prevState => ({ ...prevState, 'cidade': response.data.municipio }))
            setParametrosEndereco(prevState => ({ ...prevState, 'complemento': response.data.complemento }))

        }

        // Atualiza o estado com o CNPJ formatado
    }


    const verificaEspaco = (string) => /\s/g.test(string);

    useEffect(() => {



    }, []);


    function validarRazaoSocial() {
        if (razaoSocial?.length > 0) {
            setErroRazaoSocial(false);
            setTextoErroRazaoSocial('');
            return true;
        } else {
            setErroRazaoSocial(true);
            setTextoErroRazaoSocial("Razão Social Inválida");
            return false;
        }
    }

    function validarNomeFantasia() {
        if (nomeFantasia?.length > 0) {
            setErroNomeFantasia(false);
            setTextoErroNomeFantasia('');
            return true;
        } else {
            setErroNomeFantasia(true);
            setTextoErroNomeFantasia("Nome Fantasia Inválido");
            return false;
        }
    }

    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }




    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

  

    function validateCnpj() {

        if (cnpj.length === 14) {
            setErroCnpj(false);
            setTextoErroCnpj('');
            return true;
        } else {
            setErroCnpj(true);
            setTextoErroCnpj("CNPJ Inválido!");
            return false;
        }
    }

    function validateCpf() {

        if (cpf.length === 11) {
            setErroCpf(false);
            setTextoErroCpf('');
            return true;
        } else {
            setErroCpf(true);
            setTextoErroCpf("CPF Inválido!");
            return false;
        }
    }

   


    function validateEmail() {
        //var re1 = /\S+@\S+\.\S+/;
        var re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        var re2 = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/;
        if (re.test(email) || re2.test(email)) {

            setErroEmail(false);
            setTextoErroEmail('');
            return true;
        } else {

            setErroEmail(true);
            setTextoErroEmail("Email Inválido!");

            return false;
        }
    }



    async function salvar() {

        setBotaoSalvarClickado(true);

        if (tipoFisica) {
            //cadastrar pessoa fisica

            validateCpf();
            validarNome();
            validarSobreNome();
            validateEmail();

            if (validateCpf() && validateEmail()) {
                //verificar por cpf ja cadastrado
                try {
                    console.log("procurar cpf chamado: " + cpf);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.get('/v1/protected/signuppffornecedorverify/' + cpf,
                        { headers: headers });
                    const resposa_cpf = response.data.resposta;
                    if (resposa_cpf === "Error: CPF Já Cadastrado") {
                        setErroCpf(true);
                        setTextoErroCpf("CPF Já Cadastrado!");
                    } else {
                        setErroCpf(false);
                        setTextoErroCpf("CPF Válido");
                        //verificar por email ja cadastrado
                        try {
                            console.log("procurar email chamado: " + email);
                            const headers = {
                                'Authorization': 'Bearer ' + Cookies.get("token")
                            }
                            const response = await api.get('/v1/protected/signupemailverify/' + email,
                                { headers: headers });
                            const resposta_email = response.data.resposta;
                            if (resposta_email === "Error: Email Já Cadastrado") {
                                setErroEmail(true);
                                setTextoErroEmail("Email Já Cadastrado!");
                                setBotaoSalvarClickado(false);
                            } else {
                                setErroEmail(false);
                                setTextoErroEmail("Email aceito");

                                //prosseguir cadastro
                                if (validarNome() &&
                                    validarSobreNome()) {
                                    console.log("cadastro aceito!")


                                    try {

                                        console.log("cadastrar chamado");
                                        const cadastro_fornecedor_pf = {
                                            tipo_fornecedor: 0,
                                            nome: nome,
                                            sobrenome: sobreNome,
                                            cpf: cpf,
                                            nascimento: dataNascimento,
                                            rg: rg,
                                            email: email,
                                            descricao: descricao,
                                            logradouro: parametrosEndereco.logradouro,
                                            numero: parametrosEndereco.numero,
                                            bairro: parametrosEndereco.bairro,
                                            cidade: parametrosEndereco.cidade,
                                            estado: parametrosEndereco.estado,
                                            cep: parametrosEndereco.cep,
                                            complemento: parametrosEndereco.complemento,
                                            latitude: parametrosEndereco.latitude,
                                            longitude: parametrosEndereco.longitude,
                                            site: site,
                                            contato: contato,
                                            tipo_loja: tipoLoja,
                                            recebe_ordens: recebeOrdens,

                                        }

                                        const headers = {
                                            'Authorization': 'Bearer ' + Cookies.get("token")
                                        }

                                        const response = await api.post('/v1/protected/fornecedores/cadastrar', cadastro_fornecedor_pf,
                                            { headers: headers });

                                        const cadastro_salvo = response.data;
                                        if (cadastro_salvo) {
                                            alert("Cadastrado com sucesso!");

                                            if (parseInt(tipoMenuRender, 10) === 1) {
                                                history.push({
                                                    pathname: "/gestao/operacional/almoxarifado/fornecedores/listar/" + tipoMenuRender,

                                                })
                                            }


                                        } else {
                                            alert("Erro de Conexão, tente novamente mais tarde");
                                            setBotaoSalvarClickado(false);
                                        }
                                    } catch (_err) {
                                        console.log("erro ao cadastrar: " + _err);
                                        alert("Erro de Conexão, tente novamente mais tarde");
                                        setBotaoSalvarClickado(false);

                                    }



                                } else {
                                    console.log("cadastro rejeitado!");
                                    setBotaoSalvarClickado(false);

                                }

                            }
                        } catch (_err) {

                            alert("Erro de Conexão, tente novamente mais tarde");
                            setBotaoSalvarClickado(false);

                            return false;
                        }

                    }

                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");
                    setBotaoSalvarClickado(false);

                }


            }else{
                setBotaoSalvarClickado(false);
            }


        } else {
            //cadastrar pessoa juridica

            validateCnpj();
            validarRazaoSocial();
            validarNomeFantasia();
            validateEmail();

            if (validateCnpj() && validateEmail()) {
                //verificar por cpf ja cadastrado
                try {
                    console.log("procurar cnpj chamado: " + cnpj);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.get('/v1/protected/signuppjfornecedorverify/' + cnpj,
                        { headers: headers });
                    const resposta_cnpj = response.data.resposta;
                    if (resposta_cnpj === "Error: CNPJ Já Cadastrado") {
                        setErroCnpj(true);
                        setTextoErroCnpj("CNPJ Já Cadastrado!");
                        setBotaoSalvarClickado(false);
                    } else {
                        setErroCnpj(false);
                        setTextoErroCnpj("CNPJ Válido");
                        //verificar por email ja cadastrado
                        try {
                            console.log("procurar email chamado: " + email);
                            const headers = {
                                'Authorization': 'Bearer ' + Cookies.get("token")
                            }
                            const response = await api.get('/v1/protected/signupemailverify/' + email,
                                { headers: headers });
                            const resposta_email = response.data.resposta;
                            if (resposta_email === "Error: Email Já Cadastrado") {
                                setErroEmail(true);
                                setTextoErroEmail("Email Já Cadastrado!");
                                setBotaoSalvarClickado(false);
                            } else {
                                setErroEmail(false);
                                setTextoErroEmail("Email aceito");

                                //prosseguir cadastro
                                if (validarRazaoSocial() && validarNomeFantasia) {
                                    console.log("cadastro pj aceito!")


                                    try {

                                        console.log("cadastrar chamado");
                                        const cadastro_fornecedor_pj = {
                                            tipo_fornecedor: 1,
                                            cnpj: cnpj,
                                            razao_social: razaoSocial,
                                            nome_fantasia: nomeFantasia,
                                            porte: porte,
                                            ie: ie,
                                            atividade: atividade,
                                            email: email,
                                            descricao: descricao,
                                            logradouro: parametrosEndereco.logradouro,
                                            numero: parametrosEndereco.numero,
                                            bairro: parametrosEndereco.bairro,
                                            cidade: parametrosEndereco.cidade,
                                            estado: parametrosEndereco.estado,
                                            cep: parametrosEndereco.cep,
                                            complemento: parametrosEndereco.complemento,
                                            latitude: parametrosEndereco.latitude,
                                            longitude: parametrosEndereco.longitude,
                                            site: site,
                                            contato: contato,
                                            tipo_loja: tipoLoja,
                                            recebe_ordens: recebeOrdens,
                                        }

                                        const headers = {
                                            'Authorization': 'Bearer ' + Cookies.get("token")
                                        }

                                        const response = await api.post('/v1/protected/fornecedores/cadastrar', cadastro_fornecedor_pj,
                                            { headers: headers });

                                        const cadastro_salvo = response.data;
                                        if (cadastro_salvo) {
                                            alert("Cadastrado com sucesso!");

                                            if (parseInt(tipoMenuRender, 10) === 1) {
                                                history.push({
                                                    pathname: "/gestao/operacional/almoxarifado/fornecedores/listar/" + tipoMenuRender,

                                                })
                                            }

                                        } else {
                                            alert("Erro de Conexão, tente novamente mais tarde");
                                            setBotaoSalvarClickado(false);
                                        }
                                    } catch (_err) {
                                        console.log("erro ao cadastrar: " + _err);
                                        setBotaoSalvarClickado(false);
                                        alert("Erro de Conexão, tente novamente mais tarde");

                                    }



                                } else {
                                    console.log("cadastro pj rejeitado!")
                                    setBotaoSalvarClickado(false);
                                }

                            }
                        } catch (_err) {

                            alert("Erro de Conexão, tente novamente mais tarde");
                            setBotaoSalvarClickado(false);
                            return false;
                        }

                    }

                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");
                    setBotaoSalvarClickado(false);

                }


            }else{
                setBotaoSalvarClickado(false);
            }
        }


    }



    const handleChange1 = (event) => {
        setTipoFisica(true);
        setTipoJuridica(false);
    };

    const handleChange2 = (event) => {
        setTipoFisica(false);
        setTipoJuridica(true);
    };

    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Fornecedores={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >
                <React.Fragment >
                    <Grid container spacing={2}   >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            item xs={12}
                        >
                            <Button style={{ margin: 5 }}
                                variant="contained"
                                color="primary"
                                onClick={salvar}
                                disabled={botaoSalvarClickado}
                            > Salvar  </Button>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item xs={12}
                            spacing={-20}
                        >
                            <Typography  >
                                Tipo Pessoa:
                            </Typography>
                            <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                onChange={handleChange1} />} label="Pessoa Física" />
                            <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                onChange={handleChange2} />} label="Pessoa Jurídica" />

                        </Grid>


                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item xs={12}
                        >
                            <Typography style={{ paddingRight: 10 }} >
                                Tipo Loja:
                            </Typography>
                            <Select
                                value={tipoLoja}
                                onChange={handleTipoLoja}
                                label="Local"
                            >
                                <MenuItem value={0}>Local</MenuItem>
                                <MenuItem value={1}>Online</MenuItem>
                            </Select>

                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item xs={12}
                            style={{ paddingBottom: 50, paddingTop: 20 }}
                            spacing={5}>

                            <Typography style={{ paddingRight: 10 }}>
                                Recebe Ordens de Compra:
                            </Typography>
                            <RadioGroup
                                value={recebeOrdens}
                                onChange={handleRecebeOrdens}
                                row
                            >
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não"
                                />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Sim"
                                />
                            </RadioGroup>
                        </Grid>

                        <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={4}>
                                    <TextField
                                        error={erroCnpj}
                                        helperText={textoErroCnpj}
                                        variant="standard"
                                        name="cnpj"
                                        label="CNPJ"
                                        required
                                        id="cnpj"
                                        value={cnpj}
                                        onChange={e => handleCnpj(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={4} >
                                    <TextField
                                        error={erroRazaoSocial}
                                        helperText={textoErroRazaoSocial}
                                        variant="standard"
                                        name="razaoSocial"
                                        label="Razão Social"
                                        required
                                        id="razaoSocial"
                                        autoComplete="razaoSocial"
                                        value={razaoSocial}
                                        onChange={e => setRazaoSocial(e.target.value)}
                                        inputProps={{ maxLength: 50, minLength: 1 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4} >
                                    <TextField
                                        error={erroNomeFantasia}
                                        helperText={textoErroNomeFantasia}
                                        variant="standard"
                                        name="nomeFantasia"
                                        label="Nome Fantasia"
                                        required
                                        id="nomeFantasia"
                                        value={nomeFantasia}
                                        onChange={e => setNomeFantasia(e.target.value)}
                                        inputProps={{ maxLength: 50, minLength: 1 }}
                                        fullWidth

                                    />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>

                                    <Grid item xs={4} >

                                        <TextField

                                            variant="standard"
                                            name="porte"
                                            label="Porte"
                                            id="porte"
                                            value={porte}
                                            onChange={e => setPorte(e.target.value)}
                                            inputProps={{ maxLength: 100, minLength: 0 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            variant="standard"
                                            name="atividade"
                                            label="Atividade"
                                            id="atividade"
                                            autoComplete="atividade"
                                            value={atividade}
                                            onChange={e => setAtividade(e.target.value)}
                                            inputProps={{ maxLength: 100, minLength: 0 }}
                                            fullWidth

                                        />
                                    </Grid>

                                    <Grid item xs={4}  >
                                        <TextField

                                            variant="standard"
                                            name="ie"
                                            label="Inscrição Estadual"
                                            id="ei"
                                            value={ie}
                                            onChange={e => setIe(e.target.value)}
                                            inputProps={{ maxLength: 20, minLength: 0 }}
                                            fullWidth

                                        />
                                    </Grid>


                                </Grid>
                            </Grid>
                        </Collapse>

                        <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>
                                <Grid item xs={4}  >

                                    <TextField
                                        error={erroCpf}
                                        helperText={textoErroCpf}
                                        variant="standard"
                                        name="cpf"
                                        label="CPF"
                                        required
                                        id="cpf"
                                        value={cpf}
                                        onChange={e => (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) ? setCpf(e.target.value) : setTextoErroCnpj('')}
                                        inputProps={{ maxLength: 11, minLength: 11 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        variant="standard"
                                        name="rg"
                                        label="RG"
                                        required
                                        id="rg"
                                        value={rg}
                                        onChange={e => setRg(e.target.value)}
                                        inputProps={{ maxLength: 20, minLength: 3 }}
                                        fullWidth

                                    />
                                </Grid>


                                <Grid item xs={4}  >
                                    <TextField
                                        variant="standard"
                                        name="dataNascimento"
                                        label="Data Nascimento"
                                        required
                                        id="dataNascimento"
                                        value={dataNascimento}
                                        onChange={e => setDataNascimento(e.target.value)}
                                        inputProps={{ maxLength: 20, minLength: 3 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroNome}
                                        helperText={textoErroNome}
                                        variant="standard"
                                        name="nome"
                                        label="Nome"
                                        required
                                        id="nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        inputProps={{ maxLength: 100, minLength: 2 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroSobreNome}
                                        helperText={textoErroSobreNome}
                                        variant="standard"
                                        name="sobrenome"
                                        label="Sobrenome"
                                        required
                                        id="sobrenome"
                                        value={sobreNome}
                                        onChange={e => setSobrenome(e.target.value)}
                                        inputProps={{ maxLength: 100, minLength: 2 }}
                                        fullWidth

                                    />
                                </Grid>

                            </Grid>
                        </Collapse>



                        <Grid
                            container
                            direction="row"
                            item xs={12}
                        >
                            <Grid
                                container
                                direction="row" spacing={2}
                                item xs={12}>

                                <Typography style={{ margin: 15 }} >
                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                </Typography>


                                <Grid item xs={12}>
                                    <TextField

                                        id="logradouro"
                                        variant="standard"
                                        name="logradouro"
                                        fullWidth
                                        label="Logradouro"
                                        value={parametrosEndereco.logradouro}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField

                                        id="numero"
                                        variant="standard"
                                        name="numero"
                                        fullWidth
                                        label="Número"
                                        value={parametrosEndereco.numero}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>

                                <Grid item xs={5}>
                                    <TextField

                                        id="bairro"
                                        variant="standard"
                                        name="bairro"
                                        fullWidth
                                        label="Bairro"
                                        value={parametrosEndereco.bairro}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bairro': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>



                                <Grid item xs={5}>
                                    <TextField

                                        id="complemento"
                                        variant="standard"
                                        name="complemento"
                                        fullWidth
                                        label="Complemento"
                                        value={parametrosEndereco.complemento}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="cidade"
                                        variant="standard"
                                        name="cidade"
                                        fullWidth
                                        label="Cidade"
                                        value={parametrosEndereco.cidade}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cidade': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="estado"
                                        variant="standard"
                                        name="estado"
                                        fullWidth
                                        label="Estado"
                                        value={parametrosEndereco.estado}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'estado': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="cep"
                                        variant="standard"
                                        name="cep"
                                        fullWidth
                                        label="CEP"
                                        value={parametrosEndereco.cep}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cep': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField

                                        id="latitude"
                                        variant="standard"
                                        name="latitude"
                                        fullWidth
                                        label="Latitude"
                                        value={parametrosEndereco.latitude}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'latitude': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField

                                        id="longitude"
                                        variant="standard"
                                        name="longitude"
                                        fullWidth
                                        label="Longitude"
                                        value={parametrosEndereco.longitude}
                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'longitude': e.target.value }))}
                                        inputProps={{ maxLength: 40, minLength: 6 }}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            spacing={2}>

                            <Grid item xs={8} >
                                <TextField
                                    variant="standard"
                                    name="site"
                                    label="Site"
                                    id="site"
                                    value={site}
                                    onChange={e => setSite(e.target.value)}
                                    fullWidth
                                    multiline
                                    minRows={2}

                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            spacing={2}>

                            <Grid item xs={8} >
                                <TextField
                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    id="descricao"
                                    value={descricao}
                                    onChange={e => setDescricao(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}

                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            spacing={2}>

                            <Grid item xs={8} >
                                <TextField
                                    variant="standard"
                                    name="contato"
                                    label="Contato"
                                    id="contato"
                                    value={contato}
                                    onChange={e => setContato(e.target.value)}
                                    fullWidth
                                    multiline
                                    minRows={1}

                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justify="center"
                        >



                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>



                                    <Grid item xs={5}  >

                                        <Typography style={{ margin: 15 }} >
                                            <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                        </Typography>

                                        <TextField
                                            error={erroEmail}
                                            id="email"
                                            helperText={textoErroEmail}
                                            variant="standard"
                                            name="email"
                                            fullWidth
                                            label="E-Mail"
                                            autoComplete="email"
                                            value={email}
                                            onChange={e => verificaEspaco(e.target.value) ? setTextoErroEmail("") : setEmail(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 15 }}

                                        />
                                    </Grid>


                                </Grid>
                            </Grid>





                        </Grid>
                    </Grid>

                </React.Fragment>


            </Container>

        </div >
    );
}


