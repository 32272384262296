import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


import { Button } from "@material-ui/core";




export default function InstituicaoBancaria() {

    const [loading, setLoading] = useState(true);

    const [objetos, setObjetos] = useState(null);






    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/financeiro/instituicaobancaria/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {




        listar();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    const columnsDataGrid = [
       
        {
            headerName: 'ID',
            field: 'id_instituicao_bancaria',
            id: 1,
            headerClassName: 'cabecalho_azul',
            width: 50,

        },
        {
            headerName: 'Unidade Administrativa',
            field: 'unidade',
            id: 2,
            headerClassName: 'cabecalho_azul',
            width: 600,
            renderCell: (params) => {
                return <span>{returnDescricaoUnidade(params.value)}</span>;
            }
        },
        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 3,
            width: 400,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Banco',
            field: 'banco',
            id: 4,
            width: 200,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Agência',
            field: 'agencia',
            id: 5,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Conta',
            field: 'conta',
            id: 6,
            headerClassName: 'cabecalho_azul',
        },

    ];



    function novo() {
        window.location.href = "/gestao/financas/instituicaobancaria/cadastrar"

    }



    return (
        <div>

            <NavegadorGestao
                Financas={true}
                TabelasAuxiliaresFinancas={true}
                InstituicaoBancaria={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novo}
                    >
                        Nova Instituição Bancária
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white' }}>
                            {objetos !== null && (
                                <>
                                    <DataGrid
                                        pagination
                                        checkboxSelection
                                        rows={objetos}
                                        getRowId={(row) => row.id_instituicao_bancaria}
                                        columns={columnsDataGrid}
                                        disableColumnResize={false}
                                        onCellClick={handleCellClick}
                                        onRowClick={handleRowClick}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />



                                </>
                            )}
                        </div>
                    </div>
                }


            </div>



        </div>
    );
}



