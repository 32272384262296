import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';



import { styled } from '@material-ui/styles';


import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";


export default function GerenciarItemEstoque() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const { idItemEstoque } = useParams();


    const [mac, setMac] = useState(false);
    const [identificador, setIdentificador] = useState(false);
    const [codigo, setCodigo] = useState(false);
    const [numeroSerie, setNumeroSerie] = useState(false);


    const [botaoClicado, setBotaoClicado] = useState(false);
    const [loading, setLoading] = useState(true);
    const [itemEstoque, setItemEstoque] = useState([]);

    const [armazenamentos, setArmazenamentos] = useState(null);
    const [armazenamentoSelecionado, setArmazenamentoSelecionado] = useState(null);




    const history = useHistory();





    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/almoxarifado/estoque/listarporid/" + idItemEstoque, {
                headers: headers
            }).then(function (response) {
                setItemEstoque(response.data);

                setMac(response.data.mac);
                setIdentificador(response.data.identificador);
                setNumeroSerie(response.data.numero_serie);
                setCodigo(response.data.codigo);

                setArmazenamentoSelecionado(response.data.armazenamento);

                api.get("v1/protected/almoxarifado/armazenamento/listar", {
                    headers: headers
                }).then(function (response) {
                    setArmazenamentos(response.data);
                    setLoading(false);

                });
            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar as informações: " + _err)

        }

    }

    useEffect(() => {
        listarInformacoes();
    }, []);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };




    async function funcaoSalvar() {

        try {


            const cadastro = {

                id_item_estoque: idItemEstoque,
                armazenamento: { id_armazenamento: armazenamentoSelecionado.id_armazenamento },
                identificador: identificador,
                mac: mac,
                codigo: codigo,
                numero_serie: numeroSerie,
            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/almoxarifado/estoque/atualizaritemestoque/' + idItemEstoque, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Item Atualizado!");

                window.location.reload();

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }





    function returnDescricaoCotacao(cotacao) {

        var texto =
            "Insumo: " +
            cotacao.insumo.nome +
            "\nMarca/Modelo: " + cotacao.insumo.marca + "/" + cotacao.insumo.modelo +
            "\nCódigo Fornecedor: " + cotacao.insumo.codigo_fornecedor +
            "\nDescrição: " + cotacao.insumo.descricao +
            "\nUnidade: " +
            cotacao.unidade +
            "\nData Cotação: " +
            cotacao.data;
        return texto;
    }


    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }

    function returnDescricaoFornecedor(fornecedor) {
        var texto = "Fornecedor_ID: " + fornecedor.id_fornecedor + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\nNome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }

    function returnDescricaoOrdemCompra(ordem) {
        var texto = "Ordem_ID: " + ordem.id_ordem_compra + "\nFornecedor: " + returnDescricaoFornecedor(ordem.fornecedor) + "\nDescrição da Ordem: " + ordem.descricao + "\nData prevista de entrega: " + ordem.data_prevista_entrega;
        return texto;
    }


    function returnDescricaoEntradaEstoque(entrada_estoque) {
        var texto = "Entrada_Estoque_ID: " + entrada_estoque.id_entrada_estoque;
        return texto;
    }





    function handleSetArmazenamento(armazenamento) {
        setArmazenamentoSelecionado(armazenamento);
    }

    function returnDescricaoArmazenamento(armazenamento) {
        var texto = "ID_Armazenamento: " + armazenamento.id_armazenamento + "\nSala " + armazenamento.sala
            + "\nCorredor " + armazenamento.corredor
            + "\nBloco " + armazenamento.bloco
            + "\nGondôla " + armazenamento.gondola
            + "\nPrateleira " + armazenamento.prateleira;
        return texto;
    }




    return (
        <div>
            <NavegadorGestao
                Almoxarifado={true}
                Estoque={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Identificadores" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Armazenamento" style={{ backgroundColor: '#1C1C1C' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={10}
                            >
                                <Button style={{ marginTop: 50 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Atualizar  </Button>
                            </Grid>


                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={7}>
                                            <TextField
                                                variant="standard"
                                                name="insumo"
                                                label="insumo"
                                                required
                                                id="insumo"
                                                value={returnDescricaoCotacao(itemEstoque.item.cotacao)}
                                                fullWidth
                                                multiline
                                                rows={7}
                                            />
                                        </Grid>


                                        <Grid item xs={7}>
                                            <TextField
                                                variant="standard"
                                                name="ordem_compra"
                                                label="Ordem Compra"
                                                required
                                                id="ordem_compra"
                                                value={returnDescricaoOrdemCompra(itemEstoque.ordem_compra)}
                                                fullWidth
                                                multiline
                                                rows={7}
                                            />
                                        </Grid>



                                        <Grid item xs={7}>
                                            <TextField
                                                variant="standard"
                                                name="entrada_estoque"
                                                label="Entrada Estoque"
                                                required
                                                id="entrada_estoque"
                                                value={returnDescricaoEntradaEstoque(itemEstoque.entrada_estoque)}
                                                fullWidth
                                                multiline
                                                rows={7}
                                            />
                                        </Grid>



                                    </Grid>

                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid item xs={6}
                                            style={{ padding: 10 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="codigo"
                                                label="Código"
                                                required
                                                id="codigo"
                                                value={codigo}
                                                onChange={(e) => setCodigo(e.target.value)}
                                                fullWidth
                                                rows={1}
                                            />
                                        </Grid>

                                        <Grid item xs={6}
                                            style={{ padding: 10 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="numeroSerie"
                                                label="Número de Série"
                                                required
                                                id="numeroSerie"
                                                value={numeroSerie}
                                                onChange={(e) => setNumeroSerie(e.target.value)}
                                                fullWidth
                                                rows={1}
                                            />
                                        </Grid>

                                        <Grid item xs={6}
                                            style={{ padding: 10 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="mac"
                                                label="MAC"
                                                required
                                                id="mac"
                                                value={mac}
                                                onChange={(e) => setMac(e.target.value)}
                                                fullWidth
                                                rows={1}
                                            />
                                        </Grid>

                                        <Grid item xs={6}
                                            style={{ padding: 10 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="identificador"
                                                label="Identificador"
                                                required
                                                id="identificador"
                                                value={identificador}
                                                onChange={(e) => setIdentificador(e.target.value)}
                                                fullWidth
                                                rows={1}
                                            />
                                        </Grid>


                                    </Grid>

                                    <Grid
                                        value={value} index={2}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-ordem"
                                                    options={armazenamentos}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(ordem) => returnDescricaoArmazenamento(ordem).replace(/<br \/>/g, '\n')}
                                                    value={armazenamentoSelecionado}
                                                    onChange={(e, v) => handleSetArmazenamento(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoArmazenamento(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Armazenamento " multiline rows={9} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoArmazenamento(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                    </Grid>

                                </SwipeableViews>

                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


