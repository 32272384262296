import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';

import { useHistory } from "react-router-dom";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";

import { format, isValid, parse } from 'date-fns';



export default function CadastrarCotacao() {



    const history = useHistory();



    const { idInsumo } = useParams();
    const [dadosInsumo, setDadosInsumo] = useState();;
    const [loading, setLoading] = useState(true);

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);


    const [data, setData] = useState('');
    const [valorUnitario, setValorUnitario] = useState(0.0);
    const [descricao, setDescricao] = useState('');


    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [link, setLink] = useState('');

    const [observacoes, setObservacoes] = useState('');


    const [fornecedores, setFornecedores] = useState([]);


    function handleSetFornecedor(fornecedor) {
        setFornecedorSelecionado(fornecedor);
    }

    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/almoxarifado/insumos/listarporid/" + idInsumo, {
                headers: headers
            }).then(function (response) {

                setDadosInsumo(response.data)



                api.get("v1/protected/fornecedores/listarTodos", {
                    headers: headers
                }).then(function (response) {

                    setFornecedores(response.data);
                    setLoading(false);

                });



            });


        } catch (_err) {

            console.log("Erro ao listar informações: " + _err)
            setLoading(false);

        }

        setBotaoSalvarClickado(false);
    }

    useEffect(() => {




        listarInformacoes();


    }, []);



    async function salvar() {

        setBotaoSalvarClickado(true);

        try {

            const parsedDate = parse(data, 'dd/MM/yyyy', new Date());
            if (isValid(parsedDate)) {

            } else {
                alert("A data é invalida!");
                return;
            }

            const dataFormatada = format(parse(data, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');


            var id_user = Cookies.get('id_usuario');
            console.log("cadastrar chamado");
            const cadastro = {
                data: dataFormatada,



                valor_unitario: valorUnitario,
                descricao: descricao,
                usuario: { id_usuario: id_user, },
                fornecedor: fornecedorSelecionado,
                link: link,
                observacoes: observacoes,

            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/almoxarifado/cotacoes/cadastrar/' + idInsumo, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Cotação Salva");

                history.push({
                    pathname: "/gestao/operacional/almoxarifado/cotacoes/listar/" + idInsumo,
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }








    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }




    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Cotacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={(e) => salvar()}

                    > Salvar  </Button>
                </Grid>
                {
                    loading ?
                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                        </Skeleton>
                        :

                        <Container >

                            <React.Fragment  >
                                <Grid container spacing={2}   >



                                    <Grid
                                        style={{ paddingTop: 5 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5} >
                                                <Autocomplete
                                                    id="select-fornecedor"
                                                    options={fornecedores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor)}
                                                    value={fornecedorSelecionado}
                                                    onChange={(e, v) => handleSetFornecedor(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Fornecedor "

                                                            multiline
                                                            minRows={6} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="data"
                                                label="Data"
                                                required
                                                id="data"
                                                value={data}
                                                onChange={(e) => setData(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>




                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="valorUnitario"
                                                label="Valor Unitário"
                                                required
                                                id="valorUnitario"
                                                value={valorUnitario}
                                                onChange={(e) => setValorUnitario(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>





                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="descricao"
                                                label="Descrição"
                                                id="descricao"
                                                value={descricao}
                                                onChange={(e) => setDescricao(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="link"
                                                label="Link"
                                                required
                                                id="link"
                                                value={link}
                                                onChange={(e) => setLink(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="observacoes"
                                                label="Observações"
                                                required
                                                id="observacoes"
                                                value={observacoes}
                                                onChange={(e) => setObservacoes(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>



                                    </Grid>


                                </Grid>


                            </React.Fragment>


                        </Container>
                }
            </div >
        </div >
    );
}


