import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown'
import './styles.css';
import logo from '../../assets/imgs/logo_kapplan_energy.png';
import Grid from '@material-ui/core/Grid';


import {

  Link

} from "react-router-dom";


const Navegador = (props) => {


  const [width, setWidth] = useState(0);
  const [navBar, setNavbar] = useState(false);

  function checkDimenssoes() {
    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    setWidth(largura);

  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });

  window.addEventListener('scroll', function (event) {
    changeBackground();
  });


  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 200) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {

    checkDimenssoes();

  }, []);

  /*
    const [navbarLogo, setNavbarLogo] = useState(logo)
  const changeLogo = () => {
    if (window.scrollY >= 200) {
      setNavbarLogo(logo_menor)
    } else {
      setNavbarLogo(logo)
    }
  }
  useEffect(() => {
    changeLogo()
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo)
  })
  */


  return (
    <div style={{ zIndex: 5, backgroundColor: navBar === true ? 'white' : props.corFundo,position: props.travado ? 'sticky' : null, top: props.travado ? 0 : null , borderBottom: '1px solid black'}}>
      <Grid
        container
        direction="row"
        item xs={12} sm={12} md={12} lg={12} xl={12}

      >
        <Grid
          container
          direction="row"
          item xs={width > 900 ? 5 : 12} sm={width > 900 ? 4 : 12} md={width > 900 ? 4 : 12} lg={width > 900 ? 4 : 12} xl={width > 900 ? 3 : 12}
          alignItems="center"
          justifyContent="center"
          style={{padding: 20}}
        >
          <div style={{paddingLeft: width > 900 ? 30 : 0}}>
            <Link className="a"

              to={{
                pathname: "/",

              }}
            >
              <img alt="logomarca" width={200} 
                src={logo}
              />
            </Link>
          </div>
        </Grid>

        <Grid
          container
          direction="row"
          item xs={width > 900 ? 8 : 12} sm={width > 900 ? 8 : 12} md={width > 900 ? 8 : 12} lg={width > 900 ? 8 : 12} xl={width > 900 ? 9 : 12}
          alignItems="center"
          justifyContent={width > 1200 ? "center": "flex-end"}
         
        >
          <Navbar expand="xl" className={"color-nav"}>
            <Container  >
              <Navbar.Toggle  aria-controls="basic-navbar-nav" style={{marginBottom: 20, backgroundColor: 'rgba(255,255,255,0.8)' }} />
              <Navbar.Collapse
                style={{
                  backgroundColor: width >= 1200 ? "rgba(0,0,0,0.0)" : "white",
                  padding: width >= 1200 ? '' : '5%'
                }} >


                <Nav className="ml-auto">
                  <Nav.Item >
                    <Nav.Link id="nav-link" style={{ textDecoration: props.inicio }} href="/" >Início</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link id="nav-link" style={{ textDecoration: props.sobre }} href="/sobre">  Sobre Nós </Nav.Link>
                  </Nav.Item>

                  
                  <Nav.Item>
                    <Nav.Link id="nav-link" style={{ textDecoration: props.produtos }} href="/produtos">  Produtos </Nav.Link>
                  </Nav.Item>


                  <Nav.Item>
                    <Nav.Link id="nav-link" style={{ textDecoration: props.solucoes }} href="/solucoes">  Soluções </Nav.Link>
                  </Nav.Item>


                  <Nav.Item>
                    <Nav.Link id="nav-link" style={{ textDecoration: props.noticias }} href="/noticias">  Blog </Nav.Link>
                  </Nav.Item>


                  <NavDropdown
                    title="Meu Grupo Rosinetos"
                    id="nav-dropdown"
                    style={{ textDecoration: props.servicos, backgroundColor:'white', backgroundImage:'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #FF0000)' }}

                  >
                    <NavDropdown.Item eventKey="1.0" id="nav-link" href="/minhaconta">Minha Conta
                    </NavDropdown.Item>


                  </NavDropdown>




                  <Nav.Item>
                    <Nav.Link id="nav-link" style={{ textDecoration: props.contato }} href="/contato">Fale Conosco</Nav.Link>
                  </Nav.Item>
                </Nav>

              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Grid >
      </Grid >
    </div >
  )
}
export default Navegador;