import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@mui/material/AppBar';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';


import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function EditarPeD() {

    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const history = useHistory();

    
    const { idProjeto } = useParams();
    

    const [nomeProjeto, setNomeProjeto] = useState('');

    const [area, setArea] = useState(0);
    const [descricaoProjeto, setDescricaoProjeto] = useState('');

    const [arquivos, setArquivos] = useState(null);

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ped/listarporid/" + idProjeto, {
                headers: headers
            }).then(function (response) {

                setArea(response.data.projeto.area);
                setDescricaoProjeto(response.data.projeto.descricao_projeto);
                setNomeProjeto(response.data.projeto.nome_projeto);

                setArquivos(response.data);


                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    

    useEffect(() => {
        listarInformacoes();


    }, []);



    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/ped/baixarPdf", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }






    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };






    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                PeD={true}
                corFundo={'white'} travado={true} corTexto={'black'}

            />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :

                <div>
                    <Grid container 
                    
                        alignItems="center"
                        justifyContent="center"
                    >

                     

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                            style={{margin: 0}}
                        >


                            <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center' }}>
                                <AppBar position="static" >

                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"


                                    >
                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                        <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                    </Tabs>
                                </AppBar>

                            </Box>
                        </Grid>


                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >

                            <Grid
                                value={value} index={0}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                spacing={2}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    spacing={2}
                                    style={{ padding: 50 }}
                                >

                                    <Grid
                                        item xs={12}
                                        container
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="area-selector-label">Área</InputLabel>
                                                <Select
                                                    labelId="area-selector-label"
                                                    id="area-selector"
                                                    value={area}
                                                    label="Área"
                                                >
                                                    <MenuItem value={0}>Segurança</MenuItem>
                                                    <MenuItem value={1}>Refrigeração</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>




                                    <Grid
                                        justifyContent="flex-start"
                                        align="flex-start"
                                        container item xs={4}>
                                        <TextField
                                            variant="standard"
                                            name="nomeProjeto"
                                            label="Nome Projeto"
                                            required
                                            id="nomeProjeto"
                                            value={nomeProjeto}
                                            fullWidth
                                        />
                                    </Grid>



                                    <Grid container
                                        justifyContent="flex-start"
                                        align="flex-start"
                                        item xs={12} >
                                        <TextField
                                            variant="standard"
                                            name="descricaoProjeto"
                                            label="Descrição Projeto"
                                            required
                                            id="descricaoProjeto"
                                            value={descricaoProjeto}
                                            fullWidth
                                            multiline
                                            minRows={2}
                                        />
                                    </Grid>




                                </Grid>
                            </Grid>

                        


                        </SwipeableViews>







                    </Grid>


                </div>
            }

        </div >
    );
}


