import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import { useParams } from "react-router-dom";


import Skeleton from '@material-ui/lab/Skeleton';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { MenuItem, Select, InputLabel } from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const drawerWidth = 240;





const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function GerenciarModuloSonoro() {

    const classes = useStyles();
    const { idModuloSonoro } = useParams();
    const [moduloSonoro, setModuloSonoro] = useState(null);
    const [loading, setLoading] = useState(true);


    const [audios, setAudios] = useState([]);

    const [audioBloqueioSelecionado, setAudioBloqueioSelecionado] = useState(null);
    const [audioRecepcaoBomDiaSelecionado, setAudioRecepcaoBomDiaSelecionado] = useState(null);
    const [audioRecepcaoBoaTardeSelecionado, setAudioRecepcaoBoaTardeSelecionado] = useState(null);
    const [audioRecepcaoBoaNoiteSelecionado, setAudioRecepcaoBoaNoiteSelecionado] = useState(null);
    const [audioDisparoSelecionado, setAudioDisparoSelecionado] = useState(null);


    const [canal1, setCanal1] = useState(0);
    const [canal2, setCanal2] = useState(0);
    const [canal3, setCanal3] = useState(0);

    const [tipoCanal1, setTipoCanal1] = useState(-1);
    const [tipoCanal2, setTipoCanal2] = useState(-1);
    const [tipoCanal3, setTipoCanal3] = useState(-1);


    useEffect(() => {

        async function listarModuloSonoro() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/modulosonoro/listar/" + idModuloSonoro, {
                    headers: headers
                }).then(function (response1) {

                    var modulo_s = response1.data;
                    setModuloSonoro(modulo_s);

                    setCanal1(modulo_s.canal1_ativo);
                    setCanal2(modulo_s.canal2_ativo);
                    setCanal3(modulo_s.canal3_ativo);

                    setTipoCanal1(modulo_s.tipo_canal1);
                    setTipoCanal2(modulo_s.tipo_canal2);
                    setTipoCanal3(modulo_s.tipo_canal3);

                    api.get("v1/protected/modulosonoro/audios/listar/" + idModuloSonoro, {
                        headers: headers
                    }).then(function (response) {


                        setAudios(response.data);

                        if (modulo_s.audio_bloqueio_id > 0) {
                            setAudioBloqueioSelecionado(
                                response.data.find(audio => audio.id_audio === modulo_s.audio_bloqueio_id)
                            );
                        }


                        if (modulo_s.audio_recepcao_bom_dia_id > 0) {
                            setAudioRecepcaoBomDiaSelecionado(
                                response.data.find(audio => audio.id_audio === modulo_s.audio_recepcao_bom_dia_id)
                            );
                        }


                        if (modulo_s.audio_recepcao_boa_tarde_id > 0) {
                            setAudioRecepcaoBoaTardeSelecionado(
                                response.data.find(audio => audio.id_audio === modulo_s.audio_recepcao_boa_tarde_id)
                            );
                        }


                        if (modulo_s.audio_recepcao_boa_noite_id > 0) {
                            setAudioRecepcaoBoaNoiteSelecionado(
                                response.data.find(audio => audio.id_audio === modulo_s.audio_recepcao_boa_noite_id)
                            );
                        }


                        if (modulo_s.audio_disparo_id > 0) {
                            setAudioDisparoSelecionado(
                                response.data.find(audio => audio.id_audio === modulo_s.audio_disparo_id)
                            );
                        }


                        setLoading(false);


                    });

                });

            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

                console.log("Erro ao listar a Módulo Sonoro: " + _err)
            }

        }
        listarModuloSonoro();


    }, [idModuloSonoro]);




    async function listarAudios() {


        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/modulosonoro/audios/listar/" + idModuloSonoro, {
                headers: headers
            }).then(function (response) {

                setAudios(response.data);
            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );

            console.log("Erro ao listar audios" + _err)
        }

    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };





    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} >
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Status</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Nome do Áudio</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {audios.map((audio) => (
                            <Row key={audio.id_audio} row={audio} />
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        );
    }



    function Row(props) {
        const { row } = props;


        function returnCorStatus(status) {
            if (status === 0) {
                return "orange";
            } else if (status === 1) {
                return "green";
            } else if (status === 5) {
                return "red";
            }
        }

        function returnStatus(status) {
            if (status === 0) {
                //livre
                return "No Servidor";
            } else if (status === 1) {
                return "No Módulo Sonoro";
            } else if (status === 5) {
                return "Excluído";
            }
        }






        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>



                    <TableCell colSpan={1} align="right">{row.id_audio}</TableCell>
                    <TableCell colSpan={1} align="right" style={{ backgroundColor: returnCorStatus(row.status) }} > {returnStatus(row.status)} </TableCell>
                    <TableCell colSpan={1} align="right">{row.nome_arquivo}</TableCell>

                    <TableCell colSpan={1} align="center">
                        <Grid item xs={12} spacing={2}>
                            {
                                row.status === 1 ?
                                    <ButtonGroup >

                                        <Button color="secondary" onClick={() => excluir(row.id_audio)} >Excluir</Button>
                                        <Button color="primary" onClick={() => reproduzir(row.id_audio)}>Reproduzir</Button>

                                    </ButtonGroup>

                                    :
                                    <span> ... </span>
                            }

                        </Grid>


                    </TableCell>

                </TableRow>


            </React.Fragment>
        );
    }


    async function excluir(id) {

        console.log("Funcao deletar chamada, id: " + id);
        if (window.confirm("Excluir o Áudio Selecionado?")) {
            var result = await api.delete("/v1/protected/modulosonoro/audio/excluir/" + id);
            console.log("resultado da exclusao: " + result.status);
            if (result.status === 200) {

                listarAudios();

            } else {
                alert("Erro ao Excluir! Tente novamente");
            }
        }
    }


    async function reproduzir(id) {

        console.log("Funcao reprodzir chamada, id: " + id);
        var result = await api.get("/v1/protected/modulosonoro/reproduzir/" + id);
        console.log("resultado da reproducao: " + result.status);
        if (result.status === 200) {



        } else {
            alert("Erro ao Reproduzir! Tente novamente");
        }

    }



    const handleNovoAudio = async () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".mp3";
        fileInput.addEventListener("change", handleFileChange);
        fileInput.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await api.post(
                `/v1/protected/modulosonoro/upload/${moduloSonoro.id_modulo_sonoro}`,
                formData
            );

            console.log("resultado do upload: ", response.data);

            listarAudios();

        } catch (error) {
            console.error("Erro ao enviar o arquivo: ", error);
            alert("Erro ao enviar o arquivo! Tente novamente.");
        }
    };

    async function handleAtualizar() {

        try {

            console.log("cadastrar chamado");
            const cadastro_modulo_sonoro = {
                audio_bloqueio_id: audioBloqueioSelecionado !== null ? audioBloqueioSelecionado.id_audio : -1,
                audio_recepcao_bom_dia_id: audioRecepcaoBomDiaSelecionado !== null ? audioRecepcaoBomDiaSelecionado.id_audio : -1,
                audio_recepcao_boa_tarde_id: audioRecepcaoBoaTardeSelecionado !== null ? audioRecepcaoBoaTardeSelecionado.id_audio : -1,
                audio_recepcao_boa_noite_id: audioRecepcaoBoaNoiteSelecionado !== null ? audioRecepcaoBoaNoiteSelecionado.id_audio : -1,
                audio_disparo_id: audioDisparoSelecionado !== null ? audioDisparoSelecionado.id_audio : -1,
                canal1_ativo: canal1,
                canal2_ativo: canal2,
                canal3_ativo: canal3,
                tipo_canal1: tipoCanal1,
                tipo_canal2: tipoCanal2,
                tipo_canal3: tipoCanal3,


            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/modulosonoro/configuracoes/atualizar/' + idModuloSonoro, cadastro_modulo_sonoro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Configurações Atualizadas!")
                window.location.reload();
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


    }



    function returnDescricaoAudio(audio) {
        var texto = "  ID: " + audio.id_audio + "\n Nome: " + audio.nome_arquivo;
        return texto;
    }

    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Gerenciar Módulo Sonoro"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <React.Fragment >

                                <Box style={{ backgroundColor: 'white', width: '100%' }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"

                                        >
                                            <Tab label="Áudios" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Configurações" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>
                                </Box>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <div value={value} index={0} >

                                        <CollapsibleTable></CollapsibleTable>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button style={{ marginTop: 20 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNovoAudio}
                                            >
                                                Novo Áudio
                                            </Button>
                                        </Grid>

                                    </div>

                                    <div value={value} index={1} style={{ width: '100%' }}>

                                        <Grid
                                            item xs={12}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"

                                        >

                                            <Grid style={{ paddingTop: 20 }}
                                                item
                                                xs={12}>
                                                <Autocomplete
                                                    id="select-audio-bloqueio"
                                                    options={audios}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(audio_c) => returnDescricaoAudio(audio_c)}
                                                    value={audioBloqueioSelecionado}
                                                    onChange={(e, v) => setAudioBloqueioSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Áudio -> Bloqueio "
                                                        />
                                                    )}
                                                />
                                            </Grid>


                                            <Grid style={{ paddingTop: 20 }}
                                                item
                                                xs={12}>
                                                <Autocomplete
                                                    id="select-audio-recepcao-bom-dia"
                                                    options={audios}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(audio_c) => returnDescricaoAudio(audio_c)}
                                                    value={audioRecepcaoBomDiaSelecionado}
                                                    onChange={(e, v) => setAudioRecepcaoBomDiaSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Áudio -> Recepção, Bom Dia "
                                                        />
                                                    )}
                                                />
                                            </Grid>



                                            <Grid style={{ paddingTop: 20 }}
                                                item
                                                xs={12}>
                                                <Autocomplete
                                                    id="select-audio-recepcao-boa-tarde"
                                                    options={audios}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(audio_c) => returnDescricaoAudio(audio_c)}
                                                    value={audioRecepcaoBoaTardeSelecionado}
                                                    onChange={(e, v) => setAudioRecepcaoBoaTardeSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Áudio -> Recepção, Boa Tarde "
                                                        />
                                                    )}
                                                />
                                            </Grid>


                                            <Grid style={{ paddingTop: 20 }}
                                                item
                                                xs={12}>
                                                <Autocomplete
                                                    id="select-audio-recepcao-boa-noite"
                                                    options={audios}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(audio_c) => returnDescricaoAudio(audio_c)}
                                                    value={audioRecepcaoBoaNoiteSelecionado}
                                                    onChange={(e, v) => setAudioRecepcaoBoaNoiteSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Áudio -> Recepção, Boa Noite "
                                                        />
                                                    )}
                                                />
                                            </Grid>


                                            <Grid style={{ paddingTop: 20 }}
                                                item
                                                xs={12}>
                                                <Autocomplete
                                                    id="select-audio-disparo"
                                                    options={audios}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(audio_c) => returnDescricaoAudio(audio_c)}
                                                    value={audioDisparoSelecionado}
                                                    onChange={(e, v) => setAudioDisparoSelecionado(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Áudio -> Disparo"
                                                        />
                                                    )}
                                                />
                                            </Grid>


                                        </Grid>

                                        <Grid
                                            item xs={12}
                                            direction="row"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">Canal 1:</InputLabel>
                                                <Select
                                                    labelId="canal1"
                                                    id="canal1"
                                                    value={canal1}
                                                    name="canal1"
                                                    label="Canal1"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={(e) => setCanal1(e.target.value)}
                                                >
                                                    <MenuItem value={0}>INATIVO</MenuItem>
                                                    <MenuItem value={1}>ATIVO</MenuItem>

                                                </Select>
                                            </Grid>

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">Tipo 1:</InputLabel>
                                                <Select
                                                    labelId="tipoCanal1"
                                                    id="tipoCanal1"
                                                    value={tipoCanal1}
                                                    name="tipoCanal1"
                                                    label="tipoCanal1"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={(e) => setTipoCanal1(e.target.value)}
                                                >
                                                    <MenuItem value={-1}>Indefinido</MenuItem>
                                                    <MenuItem value={0}>Câmera</MenuItem>
                                                    <MenuItem value={1}>Controle de Acesso</MenuItem>

                                                </Select>
                                            </Grid>

                                        </Grid>


                                        <Grid
                                            item xs={12}
                                            direction="row"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >


                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">Canal 2:</InputLabel>
                                                <Select
                                                    labelId="canal2"
                                                    id="canal2"
                                                    value={canal2}
                                                    name="canal2"
                                                    label="Canal2"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={(e) => setCanal2(e.target.value)}
                                                >
                                                    <MenuItem value={0}>INATIVO</MenuItem>
                                                    <MenuItem value={1}>ATIVO</MenuItem>

                                                </Select>
                                            </Grid>

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">Tipo 2:</InputLabel>
                                                <Select
                                                    labelId="tipoCanal2"
                                                    id="tipoCanal2"
                                                    value={tipoCanal2}
                                                    name="tipoCanal2"
                                                    label="tipoCanal2"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={(e) => setTipoCanal2(e.target.value)}
                                                >
                                                    <MenuItem value={-1}>Indefinido</MenuItem>
                                                    <MenuItem value={0}>Câmera</MenuItem>
                                                    <MenuItem value={1}>Controle de Acesso</MenuItem>

                                                </Select>
                                            </Grid>

                                        </Grid>


                                        <Grid
                                            item xs={12}
                                            direction="row"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            style={{ padding: 20 }}
                                        >
                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">Canal 3:</InputLabel>
                                                <Select
                                                    labelId="canal3"
                                                    id="canal3"
                                                    value={canal3}
                                                    name="canal3"
                                                    label="Canal3"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={(e) => setCanal3(e.target.value)}
                                                >
                                                    <MenuItem value={0}>INATIVO</MenuItem>
                                                    <MenuItem value={1}>ATIVO</MenuItem>

                                                </Select>
                                            </Grid>

                                            <Grid
                                                item xs={3}
                                                direction="column"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ padding: 20 }}
                                            >

                                                <InputLabel id="status">Tipo 3:</InputLabel>
                                                <Select
                                                    labelId="tipoCanal3"
                                                    id="tipoCanal3"
                                                    value={tipoCanal3}
                                                    name="tipoCanal3"
                                                    label="tipoCanal3"
                                                    style={{ paddingLeft: 5 }}
                                                    onChange={(e) => setTipoCanal3(e.target.value)}
                                                >
                                                    <MenuItem value={-1}>Indefinido</MenuItem>
                                                    <MenuItem value={0}>Câmera</MenuItem>
                                                    <MenuItem value={1}>Controle de Acesso</MenuItem>

                                                </Select>
                                            </Grid>


                                        </Grid>



                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button style={{ marginTop: 20 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleAtualizar}
                                            >
                                                Atualizar
                                            </Button>
                                        </Grid>

                                    </div>



                                </SwipeableViews>


                            </React.Fragment>
                        }
                    </Container>
                </main >


            </div >



        </div >
    );
}


