import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";
import { isCellEditCommitKeys } from '@mui/x-data-grid/utils/keyboardUtils';





export default function CadastroFornecedorEditarContato() {
    const history = useHistory();


    const { idFornecedor } = useParams();

    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [banco, setBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [conta, setConta] = useState('');
    const [descricao, setDescricao] = useState('');
    const [titular, setTitular] = useState('');
    const [identificacao, setIdentificacao] = useState('');

    const [bancos, setBancos] = useState([]);

    const [loading, setLoading] = useState(true);

    async function salvar() {



        try {
            const cadastroConta = {

                identificacao: identificacao,
                titular: titular,
                banco: banco,
                agencia: agencia,
                conta: conta,
                descricao: descricao,

                

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/fornecedores/atualizar/contasbancarias/cadastrarcontabancaria/' + idFornecedor, cadastroConta,

                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Conta Bancária Cadastrada!")

                history.push({
                    pathname: "/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/" + idFornecedor,
                })

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    }


    async function listarInformacoes() {



        try {
            const response = await api.get('https://brasilapi.com.br/api/banks/v1');
            setBancos(response.data);

            const token = Cookies.get('token');

         
                setLoading(false);

        } catch (error) {
            console.log("Erro ao buscar bancos.");
        }
    }


    const handleChangeBanco = (event, _banco) => {

        setBanco(_banco);
    };

    useEffect(() => {

        listarInformacoes();


    }, []);


    function returnDescricaoBanco(banco) {
        if (banco) {
            var texto = banco.fullName + " | Código: " + banco.code;
            return texto;
        }
        else
            return '';
    }




    return (
        <div>
            <NavegadorGestao
                Almoxarifado={true}
                Fornecedores={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar  </Button>
                </Grid>


                {

!loading &&

<Grid
container
direction="row"
item xs={12}
  justifyContent="center"
                        alignItems="center"
spacing={2}>

<Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item xs={10}
                    >
                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={salvar}
                            disabled={estadoBotaoSalvar}
                        > Salvar  </Button>
                    </Grid>
                        <Grid container spacing={2}   >



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={3}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                    >

                                        <Autocomplete
                                            id="select-ordem-servico"
                                            options={bancos}
                                            disableCloseOnSelect
                                            getOptionLabel={(banco) => returnDescricaoBanco(banco).replace(/<br \/>/g, '\n')}
                                            value={banco}
                                            onChange={handleChangeBanco}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((grupo) => {
                                                    const cotacaoText = returnDescricaoBanco(grupo).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Banco " multiline minRows={1} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoBanco(option) }} />
                                            )}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="identificacao"
                                        label="CPF/CNPJ"
                                        required
                                        id="identificacao"
                                        value={identificacao}
                                        onChange={(e) => setIdentificacao(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="titular"
                                        label="Titular"
                                        required
                                        id="titular"
                                        value={titular}
                                        onChange={(e) => setTitular(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="agencia"
                                        label="Agência"
                                        required
                                        id="agencia"
                                        value={agencia}
                                        onChange={(e) => setAgencia(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        name="conta"
                                        label="Conta"
                                        required
                                        id="conta"
                                        value={conta}
                                        onChange={(e) => setConta(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    required
                                    id="descricao"
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    fullWidth
                                    multiline
                                    minRows={2}
                                />
                            </Grid>
                            </Grid>
                            </Grid>

                }

        </div >
    );
}


