import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';



import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function Cadastrarcolaborador() {
    const classes = useStyles();
    const history = useHistory();


    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */

    const [cargos, setCargos] = useState();
    const [funcoes, setFuncoes] = useState();

    const [departamentos, setDepartamentos] = useState([]);



    const [parametrosEndereco, setParametrosEndereco] = useState(
        {
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            cidade: "",
            estado: "",
            cep: "",

        }
    );




    const [erroCpf, setErroCpf] = useState(false);
    const [textoErroCpf, setTextoErroCpf] = useState('');
    const [loading, setLoading] = useState(true);

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');


    const [erroDataNascimento, setErroDataNascimento] = useState(false);
    const [textoErroDataNascimento, setTextoErroDataNascimento] = useState('');








    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }





    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }



    function validateCpf() {

        if (cpf.length === 11) {
            setErroCpf(false);
            setTextoErroCpf('');
            return true;
        } else {
            setErroCpf(true);
            setTextoErroCpf("CPF Inválido!");
            return false;
        }
    }

    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataNascimento(false);
            setTextoErroDataNascimento("")
        } else {
            setErroDataNascimento(true);
            setTextoErroDataNascimento("Data de Nascimento Inválida!")
        }
        return teste;
    }







    async function salvar() {

        //cadastrar pessoa fisica

        validateCpf();
        validarRg();
        validarData(dataNascimento);
        validarNome();
        validarSobreNome();

        if (validateCpf()) {
            //verificar por cpf ja cadastrado
            try {
                console.log("procurar cpf chamado: " + cpf);
                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }
                const response = await api.get('/v1/protected/signuppfverify/' + cpf,
                    { headers: headers });
                const resposa_cpf = response.data.resposta;
                if (resposa_cpf === "Error: CPF Já Cadastrado") {
                    setErroCpf(true);
                    setTextoErroCpf("CPF Já Cadastrado!");
                } else {
                    setErroCpf(false);
                    setTextoErroCpf("CPF Válido");
                    //verificar por email ja cadastrado


                    console.log(validarData(dataNascimento));
                    //prosseguir cadastro
                    if (validarRg() && validarData(dataNascimento) && validarNome() &&
                        validarSobreNome()) {
                        console.log("cadastro aceito!")


                        try {

                            const cadastro_cliente_pf = {
                                nome: nome,
                                sobrenome: sobreNome,
                                cpf: cpf,
                                nascimento: dataNascimento,
                                rg: rg,
                                rua: parametrosEndereco.logradouro,
                                numero: parametrosEndereco.numero,
                                bairro: parametrosEndereco.bairro,
                                complemento: parametrosEndereco.complemento,
                                estado: parametrosEndereco.estado,
                                departamentos: departamentosSelecionados,
                                funcoes: funcoesSelecionadas,
                                cargos: cargosSelecionados,

                            }

                            const headers = {
                                'Authorization': 'Bearer ' + Cookies.get("token")
                            }

                            const response = await api.post('/v1/protected/colaboradores/cadastrar', cadastro_cliente_pf,
                                { headers: headers });

                            const cadastro_salvo = response.data;
                            if (cadastro_salvo) {
                                alert("Colaborador cadastrado com sucesso!")

                                history.push({
                                    pathname: '/gestao/rh/colaboradores/listar',
                                })
                            } else {
                                alert("Erro de Conexão, tente novamente mais tarde");
                            }
                        } catch (_err) {
                            console.log("erro ao cadastrar: " + _err);
                            alert("Erro de Conexão, tente novamente mais tarde");

                        }



                    } else {
                        console.log("cadastro rejeitado!")
                    }



                }

            } catch (_err) {

                alert("Erro de Conexão, tente novamente mais tarde");

            }


        }




    }




    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("v1/protected/departamentos/listar", {
                headers: headers
            }).then(function (response) {
                setDepartamentos(response.data)


                api.get("v1/protected/cargos/listar", {
                    headers: headers
                }).then(function (response) {
                    setCargos(response.data)


                    api.get("v1/protected/funcoes/listar", {
                        headers: headers
                    }).then(function (response) {
                        setFuncoes(response.data)


                        setLoading(false);

                    });
                });
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Departamentos: " + _err)

        }

    }



    useEffect(() => {



        listarInformacoes();


    }, []);

    const [departamentosSelecionados, setDepartamentosSelecionados] = useState([]);

    const handleAdicionarDepartamento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosDepartamentos = valoresSelecionados.filter(
            (opcao) => !departamentosSelecionados.some((selecionado) => selecionado.id_departamento === opcao.id_departamento)
        );

        setDepartamentosSelecionados([...departamentosSelecionados, ...novosDepartamentos]);
    };

    const [cargosSelecionados, setCargoSelecionados] = useState([]);
    const handleAdicionarCargo = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosCargos = valoresSelecionados.filter(
            (opcao) => !cargosSelecionados.some((selecionado) => selecionado.id_cargo === opcao.id_cargo)
        );

        setCargoSelecionados([...cargosSelecionados, ...novosCargos]);
    };


    const [funcoesSelecionadas, setFuncoesSelecionadas] = useState([]);

    const handleAdicionarFuncao = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novasFuncoes = valoresSelecionados.filter(
            (opcao) => !funcoesSelecionadas.some((selecionado) => selecionado.id_funcao === opcao.id_funcao)
        );

        setFuncoesSelecionadas([...funcoesSelecionadas, ...novasFuncoes]);
    };

    return (
        <div>
            <NavegadorGestao
                RH={true}
                Colaboradores={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item xs={12}
            >
                <Button style={{ margin: 5 }}
                    variant="contained"
                    color="primary"
                    onClick={salvar}
                > Salvar  </Button>
            </Grid>
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container maxWidth="lg" className={classes.container} >

                    <React.Fragment >
                        <Grid container spacing={2}   >


                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={12}  >
                                    <Autocomplete
                                        multiple
                                        id="departamentos"
                                        options={departamentos}
                                        getOptionLabel={(departamento) => departamento.nome}
                                        onChange={handleAdicionarDepartamento}
                                        value={departamentosSelecionados}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecione Departamentos"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((departamento, index) => (
                                                <Chip
                                                    key={index}
                                                    label={departamento.nome}
                                                    style={{
                                                        borderRadius: 15, // Borda arredondada
                                                        backgroundColor: '#e3f2fd', // Cor de fundo azul claro
                                                        margin: '4px', // Espaçamento entre as tags
                                                    }}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                    />


                                </Grid>
                            </Grid>



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={12}  >
                                    <Autocomplete
                                        multiple
                                        id="funcoes"
                                        options={funcoes}
                                        getOptionLabel={(funcao) => funcao.nome}
                                        onChange={handleAdicionarFuncao}
                                        value={funcoesSelecionadas}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecione Funcões"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((funcao, index) => (
                                                <Chip
                                                    key={index}
                                                    label={funcao.nome}
                                                    style={{
                                                        borderRadius: 15, // Borda arredondada
                                                        backgroundColor: '#e3f2fd', // Cor de fundo azul claro
                                                        margin: '4px', // Espaçamento entre as tags
                                                    }}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                    />


                                </Grid>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={12}  >
                                    <Autocomplete
                                        multiple
                                        id="cargos"
                                        options={cargos}
                                        getOptionLabel={(cargo) => cargo.nome}
                                        onChange={handleAdicionarCargo}
                                        value={cargosSelecionados}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecione Cargos"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((cargo, index) => (
                                                <Chip
                                                    key={index}
                                                    label={cargo.nome}
                                                    style={{
                                                        borderRadius: 15, // Borda arredondada
                                                        backgroundColor: '#e3f2fd', // Cor de fundo azul claro
                                                        margin: '4px', // Espaçamento entre as tags
                                                    }}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                    />


                                </Grid>
                            </Grid>



                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>

                                <Grid item xs={4}  >

                                    <TextField
                                        error={erroCpf}
                                        helperText={textoErroCpf}
                                        variant="standard"
                                        name="cpf"
                                        label="CPF"
                                        required
                                        id="cpf"
                                        value={cpf}
                                        onChange={e => (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) ? setCpf(e.target.value) : setTextoErroCpf('')}
                                        inputProps={{ maxLength: 11, minLength: 11 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroRg}
                                        helperText={textoErroRg}
                                        variant="standard"
                                        name="rg"
                                        label="RG"
                                        required
                                        id="rg"
                                        value={rg}
                                        onChange={e => setRg(e.target.value)}
                                        inputProps={{ maxLength: 20, minLength: 3 }}
                                        fullWidth

                                    />
                                </Grid>


                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroDataNascimento}
                                        helperText={textoErroDataNascimento}
                                        variant="standard"
                                        name="dataNascimento"
                                        label="Data Nascimento"
                                        required
                                        id="dataNascimento"
                                        value={dataNascimento}
                                        onChange={e => setDataNascimento(e.target.value)}
                                        inputProps={{ maxLength: 20, minLength: 3 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroNome}
                                        helperText={textoErroNome}
                                        variant="standard"
                                        name="nome"
                                        label="Nome"
                                        required
                                        id="nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        inputProps={{ maxLength: 100, minLength: 2 }}
                                        fullWidth

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <TextField
                                        error={erroSobreNome}
                                        helperText={textoErroSobreNome}
                                        variant="standard"
                                        name="sobrenome"
                                        label="Sobrenome"
                                        required
                                        id="sobrenome"
                                        value={sobreNome}
                                        onChange={e => setSobrenome(e.target.value)}
                                        inputProps={{ maxLength: 100, minLength: 2 }}
                                        fullWidth

                                    />
                                </Grid>

                            </Grid>





                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid style={{ backgroundColor: 'WhiteSmoke' }} component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={9}
                                        spacing={2}>
                                        <Grid item xs={9}>

                                            <Typography style={{ margin: 15 }} >
                                                <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                            </Typography>


                                            <Grid item xs={9}>
                                                <TextField

                                                    id="logradouro"
                                                    variant="standard"
                                                    name="logradouro"
                                                    fullWidth
                                                    label="Logradouro"
                                                    value={parametrosEndereco.logradouro}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>

                                            <Grid item xs={9}>
                                                <TextField

                                                    id="numero"
                                                    variant="standard"
                                                    name="numero"
                                                    fullWidth
                                                    label="Número"
                                                    value={parametrosEndereco.numero}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>

                                            <Grid item xs={9}>
                                                <TextField

                                                    id="bairro"
                                                    variant="standard"
                                                    name="bairro"
                                                    fullWidth
                                                    label="Bairro"
                                                    value={parametrosEndereco.bairro}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bairro': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>

                                            <Grid item xs={9}>
                                                <TextField

                                                    id="complemento"
                                                    variant="standard"
                                                    name="complemento"
                                                    fullWidth
                                                    label="Complemento"
                                                    value={parametrosEndereco.complemento}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField

                                                    id="cidade"
                                                    variant="standard"
                                                    name="cidade"
                                                    fullWidth
                                                    label="Cidade"
                                                    value={parametrosEndereco.cidade}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cidade': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField

                                                    id="estado"
                                                    variant="standard"
                                                    name="estado"
                                                    fullWidth
                                                    label="Estado"
                                                    value={parametrosEndereco.estado}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'estado': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField

                                                    id="cep"
                                                    variant="standard"
                                                    name="cep"
                                                    fullWidth
                                                    label="CEP"
                                                    value={parametrosEndereco.cep}
                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cep': e.target.value }))}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>






                        </Grid>


                    </React.Fragment>



                </Container>
            }

        </div >
    );
}


