import React, { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import VariationsIcon from '@mui/icons-material/ViewCarousel';

import Skeleton from '@material-ui/lab/Skeleton';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment'; // Importe a biblioteca Moment.js

import {
    Box,
    Tooltip
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import {
    MaterialReactTable,

} from 'material-react-table';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";




function novo() {
    window.location.href = "/gestao/operacional/almoxarifado/estoque/cadastrarentrada"

}

export default function Estoque() {

    const [loading, setLoading] = useState(true);

    const [objetos, setObjetos] = useState(null);



    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                console.log(" Objetos: " + response);
                setLoading(false);


            });


        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {


        listar();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };



    function returnDescricaoCotacao(cotacao) {

        var texto =
            "Insumo: " +
            cotacao.insumo.nome +
            "\nMarca/Modelo: " + cotacao.insumo.marca + "/" + cotacao.insumo.modelo +
            "\nCódigo Fornecedor: " + cotacao.insumo.codigo_fornecedor +
            "\nDescrição: " + cotacao.insumo.descricao +
            "\nUnidade: " +
            cotacao.unidade +
            "\nData Cotação: " +
            cotacao.data;
        return texto;
    }

    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }

    function returnDescricaoFornecedor(fornecedor) {
        var texto = "Fornecedor_ID: " + fornecedor.id_fornecedor + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\nNome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }

    function returnDescricaoOrdemCompra(ordem) {
        // var texto = "Ordem_ID: " + ordem.id_ordem_compra + "\nFornecedor: " + returnDescricaoFornecedor(ordem.fornecedor) + "\nDescrição da Ordem: " + ordem.descricao + "\nData prevista de entrega: " + ordem.data_prevista_entrega;
        //  return texto;
        return '';
    }


    function returnDescricaoEntradaEstoque(entrada_estoque) {
        var texto = "Entrada_Estoque_ID: " + entrada_estoque.id_entrada_estoque;
        return texto;
    }


    const handleClickEdicao = (event, cellValues) => {
        window.open("/gestao/operacional/almoxarifado/estoque/gerenciaritemestoque/" + cellValues.row.id_item_estoque, '_blank');
    };


    function returnDescricaoArmazenamento(armazenamento) {
        if (armazenamento) {
            var texto = "ID_Armazenamento: " + armazenamento.id_armazenamento + "\nSala " + armazenamento.sala
                + "\nCorredor " + armazenamento.corredor
                + "\nBloco " + armazenamento.bloco
                + "\nGondôla " + armazenamento.gondola
                + "\nPrateleira " + armazenamento.prateleira;
            return texto;
        } else {
            return "";
        }
    }


   



    function returnDescricaoArmazenamento(armazenamento) {
        var texto = "Descrição: " + armazenamento.descricao + "\n" +
            "Sala: " + armazenamento.sala + "\n" +
            "Corredor: " + armazenamento.corredor + "\n" +
            "Bloco: " + armazenamento.bloco + "\n" +
            "Gondola: " + armazenamento.gondola + "\n" +
            "Prateleira: " + armazenamento.prateleira;

        return texto;
    }


    const columnsItensEstoque = useMemo(
        () => [

            {
                accessorKey: 'id_item_estoque', //normal accessorKey
                header: 'ID',
                size: 30,
                maxWidth: 30,
                id: 'id_item_estoque',
            },

            {
                header: 'Status',
                size: 30,
                accessorKey: 'status_item',
                Cell: ({ renderedCellValue }) => {

                    return (
                        <span>{returnDescricaoStatus(renderedCellValue)}</span>
                    );
                }
            },
            {
                header: 'Fornecedor',
                size: 30,
                accessorKey: 'item.cotacao.fornecedor',
                Cell: ({ renderedCellValue }) => {

                    var tipo_fornecedor = renderedCellValue.tipo_fornecedor;
                    return (
                        <Typography component="h1" style={{ fontSize: 12 }} variant="h6" color="inherit" noWrap>
                            {tipo_fornecedor === 0 ? (renderedCellValue.nome + " " + renderedCellValue.sobrenome) : renderedCellValue.nome_fantasia}
                        </Typography>
                    )
                }
            },
            {
                header: 'Ordem de Compra',
                size: 30,
                accessorKey: 'ordem_compra',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <a href={"/gestao/administracao/ordensdecompras/editar/" + renderedCellValue.id_ordem_compra} target="_blank">{"OC" + renderedCellValue.id_ordem_compra}</a>
                    )
                }
            },
            {
                header: 'Valor Unitário',
                size: 30,
                Cell: ({ row }) => {
                    return (
                        <span>
                            {currencyFormatter.format(row.original.item.cotacao.valor_unitario + row.original.item.cotacao.valor_ipi + row.original.item.cotacao.valor_icms)}
                        </span>
                    )
                }
            },
            {
                header: 'Entrada',
                size: 30,
                accessorKey: 'entrada_estoque',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <a href={"/gestao/operacional/almoxarifado/entradaestoque/gerenciarentradaestoque/" + renderedCellValue.id_entrada_estoque} target="_blank">{"OEE" + renderedCellValue.id_entrada_estoque + " em " + moment(renderedCellValue.data_criacao).format('DD/MM/YYYY')}</a>
                    )
                }
            },
            {
                header: 'Armazenamento',
                size: 300,
                accessorKey: 'armazenamento',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span style={{ minWidth: '300px' }}>{returnDescricaoArmazenamento(renderedCellValue)}</span>
                    )
                }
            },

        ],
        [],
    );



    const columns = useMemo(
        () => [

            {
                accessorKey: 'insumo.id_insumo', //normal accessorKey
                header: 'ID Insumo',
                size: 30,
                id: 1,
            },

            {

                accessorKey: 'insumo.descricao',
                header: 'Descrição',
                size: 300,
                Cell: ({ renderedCellValue }) => (
                    <span style={{ maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block' }}>
                        {renderedCellValue}
                    </span>
                ),
                id: 2,
            },

            {
                id: 3,
                accessorKey: 'itens_estoque',
                header: 'Valor Total',
                size: 50,
                Cell: ({ cell }) => {
                    const itensEstoque = cell.getValue();
                    const valorTotal = itensEstoque
                        .map(item => item.item.cotacao?.valor_unitario || 0)
                        .reduce((total, valor) => total + valor, 0);

                    return (
                        <span style={{ maxWidth: '50px' }}>
                            {currencyFormatter.format(valorTotal)}
                        </span>
                    );
                },
            },
            {
                id: 4,
                accessorKey: 'itens_estoque',
                header: 'Estoque Atual',
                size: 50,
                Cell: ({ cell }) => (
                    <span style={{ maxWidth: '50px', wordWrap: 'break-word', display: 'inline-block' }}>
                        {Array.isArray(cell.getValue()) ? cell.getValue().length : 0}
                    </span>
                ),
            },
            {
                id: 5,
                accessorKey: 'itens_estoque',
                header: 'Entradas',
                size: 50,
                Cell: ({ cell }) => (
                    <span style={{ maxWidth: '50px', wordWrap: 'break-word', display: 'inline-block' }}>
                        {Array.isArray(cell.getValue()) ? cell.getValue().length : 0}
                    </span>
                ),
            },
            {
                id: 6,
                accessorKey: 'itens_estoque',
                header: 'Saídas',
                size: 50,
                Cell: ({ cell }) => (
                    <span style={{ maxWidth: '50px', wordWrap: 'break-word', display: 'inline-block' }}>
                        {0}
                    </span>
                ),
            },


        ],
        [],
    );


    return (
        <div>

            <NavegadorGestao
                Almoxarifado={true}
                Estoque={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ padding: 10, width: '100%', height: '100%' }}>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white', margin: 5 }}>
                            {objetos && (
                                <MaterialReactTable
                                    columns={columns}
                                    data={objetos}
                                    enableBottomToolbar={true}
                                    enableGlobalFilterModes
                                    enablePagination={true}
                                    enableRowActions={false}
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_insumo_estoque}
                                    enableGrouping
                                    enableExpandAll={false} //hide expand all double arrow in column header
                                    enableExpanding={true}
                                    filterFromLeafRows={true} //apply filtering to all rows instead of just parent rows
                                    paginateExpandedRows={false}
                                    initialState={{
                                        pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                        showGlobalFilter: true,
                                    }}
                                    muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                    muiPaginationProps={{
                                        rowsPerPageOptions: [50, 100, 200],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                left: '1px',
                                                maxWidth: '3000px',
                                                position: 'sticky',
                                                width: '100%',
                                            }}
                                        >

                                            <MaterialReactTable
                                                columns={columnsItensEstoque}
                                                data={row.original.itens_estoque}
                                                enableBottomToolbar={false}
                                                enableTopToolbar={false}
                                                enableGlobalFilterModes
                                                enablePagination={true}
                                                enableRowActions={true}
                                                paginationDisplayMode={'pages'}
                                                getRowId={(row) => row.id_item_estoque}
                                                initialState={{
                                                    pagination: { pageSize: 1000, pageIndex: 0 }, // Correção da sintaxe aqui
                                                    showGlobalFilter: true,
                                                }}
                                                muiTableContainerProps={{ sx: { maxHeight: "3000px" } }}
                                                muiTableBodyRowProps={{
                                                    sx: {
                                                        height: 20,
                                                        color: 'black' // Define a cor do texto para preto
                                                    },
                                                }}
                                                muiPaginationProps={{
                                                    rowsPerPageOptions: [1000, 2000, 3000],
                                                    variant: 'outlined',
                                                }}
                                                enableFullScreenToggle={false}

                                            />

                                        </Box>
                                    )}

                                />

                            )}

                        </div>
                    </div>
                }



            </div>



        </div>
    );
}



