import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';


function novaUnidade() {
    window.location.href = "/gestao/administracao/unidades/cadastrarunidade"

}

export default function Unidades() {



    const [loading, setLoading] = useState(true);
    const [unidades, setUnidades] = useState([]);
    const [click, setClick] = useState(1);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [parametros, setParametros] = useState(
        {
            identificacao: "",
            page: 0,
            size: 25,
        }
    );

    const [height, setHeight] = useState(0);


    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });




    const handleChangePage = (event, value) => {

        setPage(value);
        let num_pagina = parseInt(value) - 1;
        console.log("numero da pagina: " + num_pagina)
        setParametros(prevState => ({ ...prevState, 'page': num_pagina }))
        setClick(click + 1)
        listarUnidades();

    };


    const handleRowElementsPorPage = (e) => {

        setParametros(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
        setClick(click + 1)
        listarUnidades();

    }

    const [idUnidadeAAlterar, setIdUnidadeAlterar] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [buttonOkDisabled, setButtonOkDisabled] = useState(false);




    async function handleCloseAlert() {
        setButtonOkDisabled(true);

        try {


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = Cookies.get('id_usuario');

            const response = await api.put('/v1/protected/unidades/mudarStatus/' + id_usuario_logado + "/" + idUnidadeAAlterar + "/" + mensagem,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Unidade Atualizada com sucesso!")



            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


        setIdUnidadeAlterar(0);
        setMensagem('');
        setAlertOpen(false);
        setButtonOkDisabled(false);


        listarUnidades();

    }



    async function listarUnidades() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            var parametros1 = parametros
            await api.get("v1/protected/unidades/listar", {
                params: {
                    identificacao: parametros1.identificacao,
                    page: parametros1.page,
                    size: parametros1.size,


                },
                headers: headers
            }).then(function (response) {
                setUnidades(response.data.content)

                console.log(" Unidades: " + response);
                setLoading(false);
                setTotalElements(response.data.totalElements);
                setTotalPages(response.data.totalPages);

                console.log(" total de elementos: " + response.data.totalElements);


                console.log(" total de paginas: " + response.data.totalPages);

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar unidades: " + _err)

        }

    }


    useEffect(() => {


        checkDimenssoes();

        async function listarUnidades() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                const id_usuario = parseInt(Cookies.get('id_usuario'), 10);
                console.log("id na tela unidades: " + id_usuario)
                await api.get("v1/protected/unidades/listar", {
                    params: {
                        identificacao: "",
                        page: 0,
                        size: 25,


                    },
                    headers: headers
                }).then(function (response) {
                    setUnidades(response.data.content)

                    console.log(" Unidades: " + response);
                    setLoading(false);
                    setTotalElements(response.data.totalElements);
                    setTotalPages(response.data.totalPages);

                    console.log(" total de elementos: " + response.data.totalElements);


                    console.log(" total de paginas: " + response.data.totalPages);

                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar Unidades: " + _err)

            }

        }
        listarUnidades();


    }, []);


    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} style={{ height: height }}>
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >
                            <TableCell style={{ backgroundColor: 'white', color: 'white', position: "sticky", top: 0, textAlign: "center" }} colSpan={1}></TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Status</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Data Cadastro</TableCell>

                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>CNPJ</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Nome Fantasia</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>E-mail</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Endereço</TableCell>

                            <TableCell style={{ backgroundColor: 'white', color: 'white', position: "sticky", top: 0 }} colSpan={1}></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unidades.map((unidade) => (
                            <Row key={unidade.id_unidade} row={unidade} />
                        ))}
                    </TableBody>





                </Table>
            </TableContainer>
        );
    }




    const Row = (props) => {
        const { row } = props;
        const [open, setOpen] = React.useState(false);


        function setOpenInfoExtra(open) {
            setOpen(open);
        }


        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenInfoExtra(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>


                    <TableCell colSpan={1} align="right">{row.id_cliente}</TableCell>                    <TableCell colSpan={1} align="right" style={{ backgroundColor: row.status === 0 ? 'red' : 'green' }} >{row.status === 0 ? "DESATIVADO" : "ATIVO"}</TableCell>

                    <TableCell colSpan={1} align="right">{row.data_cadastro}</TableCell>
                    <TableCell colSpan={1} align="right">{row.cnpj}</TableCell>
                    <TableCell colSpan={1} align="right">{row.nome_fantasia}</TableCell>
                    <TableCell colSpan={1} align="right">{row.email}</TableCell>
                    <TableCell colSpan={1} align="right">{returnEnderecoComplementar(row)}</TableCell>

                    <TableCell colSpan={1} align="center">
                        <Grid item xs={4}>
                            <ButtonGroup aria-label="outlined primary button group">
                                <Button color="primary" href={"/alterarunidade/" + row.id_unidade}>Atualizar</Button>

                            </ButtonGroup>
                        </Grid>
                    </TableCell>

                </TableRow>


            </React.Fragment>
        );
    }


    function returnEnderecoComplementar(row) {
        return (
            (row.endereco_logradouro ? ("Logradouro: " + row.endereco_logradouro) : "") + " " +
            (row.endereco_numero ? ("Número: " + row.endereco_numero) : "") + " " +
            (row.endereco_bairro ? ("Bairro: " + row.endereco_bairro) : "") + " " +
            (row.endereco_cidade ? ("Cidade: " + row.endereco_cidade) : "") + " " +
            (row.endereco_estado ? ("UF: " + row.endereco_estado) : "") + " " +
            (row.endereco_cep ? ("CEP: " + row.endereco_cep) : "") + " " +
            (row.endereco_ponto_referencia ? ("Ponto de Refêrencia: " + row.endereco_ponto_referencia) : "") + " " +
            (row.endereco_complemento ? ("Complemento: " + row.endereco_complemento) : "")
        );
    }

    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Unidades={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}

                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novaUnidade}
                    >
                        Nova Unidade
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <CollapsibleTable></CollapsibleTable>
                        <div style={{ backgroundColor: 'white' }}>
                            <Grid
                                direction="row"
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                                justifyContent="center"
                                alignItems="center"
                                style={{ padding: 10 }}
                                container
                            >

                                <Grid
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                    direction="column"
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ padding: 20 }}
                                >

                                    <span style={{ fontWeight: 'bold', fontSize: 14 }}> {totalElements} Unidades encontradas   </span>

                                    <InputLabel id="size">Elementos por página:</InputLabel>
                                    <Select
                                        labelId="size"
                                        id="size"
                                        value={parametros.size}
                                        name="size"
                                        onChange={e => handleRowElementsPorPage(e)}
                                        label="size"
                                        style={{ paddingLeft: 5 }}
                                    >
                                        <MenuItem value={10} >10</MenuItem>
                                        <MenuItem value={25}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={100}>2000</MenuItem>

                                    </Select>
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                    direction="column"
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handleChangePage}
                                        variant="outlined"
                                        size="large"
                                        color="primary"
                                    />

                                </Grid>

                            </Grid>


                        </div>
                    </div>
                }


            </div>



            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle
                    style={{ backgroundColor: 'black', color: 'white' }}
                    id="confirm-dialog"> Mudar Status de Colaborador</DialogTitle>
                <DialogContent
                    style={{ backgroundColor: 'black' }}
                >
                    <div>
                        <AppBar
                            style={{ backgroundColor: 'black', color: 'white' }}

                            sx={{ position: 'relative' }}>

                            <Grid container spacing={1}

                                style={{ backgroundColor: 'black', color: 'white' }}

                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disable={buttonOkDisabled}
                                    id="mensagem"
                                    variant="standard"
                                    name="mensagem"
                                    fullWidth
                                    label="Mensagem"
                                    required
                                    autoComplete="mensagem"
                                    value={mensagem}
                                    onChange={e => setMensagem(e.target.value)}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}

                                />

                                <Grid
                                    disable={buttonOkDisabled}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    onClick={(e) => handleCloseAlert()}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        ok
                                    </Button>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >
        </div>
    );
}



