import React from 'react';



import NavBarAdmin from "../../../../components/NavBarAdmin";
import Rodape from '../../../../components/Rodape';

import MenuAreaAdministracaoSistema from '../components/menu';


export default function AreaGestao() {
   

    


    return (
        <div>

            <NavBarAdmin />
            <MenuAreaAdministracaoSistema 
            link1={["Home","/"]}
           link2={["Minha Conta", "/minhaconta"]}
         
           titulo={"Área Central de Administração do Sistema"}
/>
            
        <div>
                <Rodape />
            </div>
        </div>
    );
}



