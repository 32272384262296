import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


import { Button } from "@material-ui/core";





function novaFuncao() {
    window.location.href = "/gestao/rh/funcoes/cadastrar"

}

export default function ListarFuncoess() {



    const [loading, setLoading] = useState(true);
    const [funcoes, setFuncoess] = useState([]);










    useEffect(() => {



        async function listarFuncoess() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }



                await api.get("v1/protected/funcoes/listar", {
                    headers: headers
                }).then(function (response) {
                    setFuncoess(response.data)


                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar Funcoess: " + _err)

            }

        }
        listarFuncoess();


    }, []);


    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} style={{ height: '100%' }}>
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >

                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>

                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Nome</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} colSpan={1}>Descrição</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {funcoes.map((funcao) => (
                            <Row key={funcao.id_funcao} row={funcao} />
                        ))}
                    </TableBody>





                </Table>
            </TableContainer>
        );
    }




    const Row = (props) => {
        const { row } = props;


        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

                    <TableCell colSpan={1} align="right">{row.id_funcao}</TableCell>
                    <TableCell colSpan={1} align="right">{row.nome}</TableCell>
                    <TableCell colSpan={1} align="right">{row.descricao}</TableCell>

                </TableRow>


            </React.Fragment>
        );
    }



    return (
        <div>

            <NavegadorGestao
                RH={true}
                TabelasAuxiliaresRh={true}
                Funcoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novaFuncao}
                    >
                        Nova Função
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <CollapsibleTable></CollapsibleTable>
                    </div>
                }


            </div>

        </div>
    );
}



