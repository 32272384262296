import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../../services/api';
import NavegadorGestao from "../../../../../../../components/NavBarGestao";
import { isCellEditCommitKeys } from '@mui/x-data-grid/utils/keyboardUtils';


export default function CadastroInstalacaoEditarContato() {
    const history = useHistory();


    const { idInstalacao } = useParams();
    const { idCliente } = useParams();
    const { idContato } = useParams();

    const [loading, setLoading] = useState(true);


    const [nome, setNome] = useState('');
    const [cargo, setCargo] = useState('');
    const [celular, setCelular] = useState('');
    const [fixo, setFixo] = useState('');
    const [email, setEmail] = useState('');
    const [descricao, setDescricao] = useState('');



    const [recebeOcorrenciasChecked, setRecebeOcorrenciasChecked] = useState(false);
   

    const handleRecebeOcorrenciasChecked = (event) => {
       
        setRecebeOcorrenciasChecked(event.target.checked);

    };

 



    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/clientes/instalacoes/contatos/retornardadoscontato/" + idContato, {
                headers: headers
            }).then(function (response) {

                setNome(response.data.nome_contato);
                setCargo(response.data.cargo_contato);
                setCelular(response.data.celular_contato);
                setFixo(response.data.fixo_contato);
                setEmail(response.data.e_mail_contato);
                setDescricao(response.data.descricao_contato);

  
                setRecebeOcorrenciasChecked(response.data.recebe_ocorrencias);


                setLoading(false);

            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }

    useEffect(() => {
        listarInformacoes();



    }, []);


    async function salvar() {



        try {
            const cadastro_contato = {

                nome_contato: nome,
                cargo_contato: cargo,
                celular_contato: celular,
                fixo_contato: fixo,
                e_mail_contato: email,
                descricao_contato: descricao,
                recebe_ocorrencias: recebeOcorrenciasChecked ? 1 : 0,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/clientes/instalacoes/atualizar/contatos/editarContato/' + idContato, cadastro_contato,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Contato Atualizado!")

                history.push({
                    pathname: "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente,
                })

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    }


    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <Container style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={10}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                    > Salvar  </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}   >
                            <Grid
                                container
                                direction="row" spacing={2}
                                item xs={12}>


                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems={"center"}
                                    justifyContent='center'
                                >
                                    <Grid
                                        container
                                        direction="row" spacing={2}
                                        item xs={6}>
                                        <TextField
                                            id="nome"
                                            variant="standard"
                                            name="nome"
                                            fullWidth
                                            label="Nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems={"center"}
                                    justifyContent='center'
                                >
                                    <Grid
                                        container
                                        direction="row" spacing={2}
                                        item xs={6}>
                                        <TextField
                                            id="cargo"
                                            variant="standard"
                                            name="cargo"
                                            fullWidth
                                            label="Cargo"
                                            value={cargo}
                                            onChange={e => setCargo(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems={"center"}
                                    justifyContent='center'
                                >
                                    <Grid
                                        container
                                        direction="row" spacing={2}
                                        item xs={6}>
                                        <TextField
                                            id="celular"
                                            variant="standard"
                                            name="celular"
                                            fullWidth
                                            label="Celular"
                                            value={celular}
                                            onChange={e => setCelular(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>



                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems={"center"}
                                    justifyContent='center'
                                >
                                    <Grid
                                        container
                                        direction="row" spacing={2}
                                        item xs={6}>
                                        <TextField
                                            id="fixo"
                                            variant="standard"
                                            name="fixo"
                                            fullWidth
                                            label="Fixo"
                                            value={fixo}
                                            onChange={e => setFixo(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems={"center"}
                                    justifyContent='center'
                                >
                                    <Grid
                                        container
                                        direction="row" spacing={2}
                                        item xs={6}>
                                        <TextField
                                            id="email"
                                            variant="standard"
                                            name="email"
                                            fullWidth
                                            label="E-mail"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>



                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems={"center"}
                                    justifyContent='center'
                                >
                                    <Grid
                                        container
                                        direction="row" spacing={2}
                                        item xs={6}>
                                        <TextField
                                            id="descricao"
                                            variant="standard"
                                            name="descricao"
                                            fullWidth
                                            label="Descrição"
                                            value={descricao}
                                            multiline
                                            minRows={3}
                                            onChange={e => setDescricao(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid container
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                                style={{ paddingTop: 20 }}
                            >
                                


                                <Grid item xs={8}
                                    container
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recebeOcorrenciasChecked} onChange={handleRecebeOcorrenciasChecked} />}
                                        label="Recebe Notificações"
                                    />
                                </Grid>
                            </Grid>







                        </Grid>


                    </React.Fragment>
                }
            </Container>

        </div >
    );
}


