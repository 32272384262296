import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    Box,
    Tooltip
} from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Email, EmailOutlined, Visibility, VisibilityOff, Message, MessageOutlined } from '@mui/icons-material';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import { Button } from "@material-ui/core";

import {
    MaterialReactTable,

} from 'material-react-table';




function novaNotificacao() {
    window.location.href = "/gestao/operacional/notificacoes/cadastrar"

}

export default function Notificacoes() {



    const [loading, setLoading] = useState(true);
    const [notificacoes, setNotificacoes] = useState([]);






    async function listarObjetos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/notificacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setNotificacoes(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Notificações: " + _err)

        }

    }


    useEffect(() => {




        listarObjetos();


    }, []);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    async function visualizar(caminho) {

        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            console.log("Caminho: " + caminho);
            const cadastro = {

                dado1: caminho

            };
            const response = await api.post("/v1/protected/notificacoes/baixarPdf", cadastro, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }




    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }




    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_notificacao', //normal accessorKey
                header: 'ID',
                size: 60,
            },

            {
                header: 'Informações',
                size: 150,
                accessorFn: row => {
                    return {
                        dados: row.registros_envio,
                        id: row.id_notificacao,
                    };
                },
                Cell: ({ renderedCellValue }) => {
                    const envios = renderedCellValue.dados;

                    if (envios) {
                        console.log("id: " + renderedCellValue.id);

                        console.log("envios; " + JSON.stringify(envios));
                    } else {
                        console.log("id: " + renderedCellValue.id);
                    }

                    return (
                        <Tooltip
                            title={
                                <div style={{ backgroundColor: 'white', padding: 20, color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>


                                    {envios.map((envio, index) => (
                                        <Card key={index} style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                            <CardContent>
                                                <Typography variant="body2">
                                                    <strong>Destinatário:</strong> {envio.vendedor.nome_contato}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Plataforma de Envio:</strong> {envio.plataforma_envio === 0 ? 'WhatsApp' : 'Email'}
                                                </Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <strong>Enviado:</strong> {envio.enviado === 1 ? <Email /> : <EmailOutlined />}
                                                    </Typography>
                                                    {envio.enviado === 1 && (
                                                        <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                                            {moment(envio.data_hora_envio).format('DD/MM/YYYY HH:mm')}
                                                        </Typography>
                                                    )}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <strong>Visualizado:</strong> {envio.vizualizado === 1 ? <Visibility /> : <VisibilityOff />}
                                                    </Typography>
                                                    {envio.vizualizado === 1 && (
                                                        <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                                            {moment(envio.data_hora_vizualizado).format('DD/MM/YYYY HH:mm')}
                                                        </Typography>
                                                    )}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>
                            }
                            placement="top"
                            arrow
                            style={{ backgroundColor: 'white' }}
                            sx={{ backgroundColor: 'white' }}
                        >
                            <span>Informações</span>
                        </Tooltip>

                    );
                },
            },


            {
                header: 'Tipo',
                size: 150,
                accessorKey: 'tipo_ocorrencia.nome',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span >
                            {renderedCellValue}
                        </span>
                    )
                },
            },



        ],
        [],
    );




    const handleClickVizualizar = (event, row) => {

        visualizar(row.caminho_arquivo_original);
    };




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Notificacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novaNotificacao}
                    >
                        Nova Notificação
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        {notificacoes && (
                            <MaterialReactTable
                                columns={columns}
                                data={notificacoes}
                                enableGlobalFilterModes
                                enablePagination={true}
                                enableRowActions
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_notificacao}
                                enableTopToolbar={true}
                                enableBottomToolbar={false}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={true}
                                enableSorting={true}
                                enableRowSelection={false}


                                enableColumnFilterModes={true}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}




                                initialState={{
                                    pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                    showGlobalFilter: true,
                                    density: 'compact',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                muiPaginationProps={{
                                    rowsPerPageOptions: [50, 100, 200],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            left: '30px',
                                            maxWidth: '1000px',
                                            position: 'sticky',
                                            width: '100%',
                                        }}
                                    >
                                    </Box>
                                )}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                                        <Tooltip title="Gerenciar">

                                            <IconButton
                                                color="primary"
                                                onClick={(event) => handleClickVizualizar(event, row.original)}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                )}
                            />
                        )}


                    </div>



                }


            </div>


        </div>
    );
}



