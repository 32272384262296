import React, {  useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';





import './styles.scss';

const Produtos = (props) => {

  


  useEffect(() => {






  }, []);







  return (
    <div >


      <Navegador produtos={'underline'} corFundo={'white'} travado={true} corTexto={'black'} />

    


      <div >
        <Rodape />
      </div>
    </div >
  );
}

export default Produtos;