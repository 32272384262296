
import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import ConfiguracoesGerais from './configuracoesGerais';
import ConfiguracoesReproducao from './configuracoesReproducao';

import {
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../../assets/imgs/miniatura_pdf.png';
import { Paper, makeStyles } from '@material-ui/core';


const ModuloSonoro = (props) => {
    const equipamento = props.equipamento;

    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);


    }


    const handleChangeIndex = (index) => {
        setValue(index);


    };



    return (
        <div>
            <Grid
                style={{ margin: 0, padding: 0, backgroundColor: 'blue' }}
            >

                <Grid
                    item xs={12}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent={"center"}
                    style={{ margin: 0, padding: 0, backgroundColor: 'pink' }}
                >


                    <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                        <AppBar position="static" >



                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >
                                <Tab label="Configurações Gerais" style={{ backgroundColor: '#1C1C1C' }} />
                                <Tab label="Configurar Reprodução" style={{ backgroundColor: '#1C1C1C' }} />
                            </Tabs>

                        </AppBar>

                    </Box>
                </Grid>

                <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                >
                    <Grid
                        value={value}
                        index={0}
                        style={{ paddingTop: 0 }}
                        container
                        direction="row"
                        item xs={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <ConfiguracoesGerais
                            equipamento={equipamento}
                        />
                    </Grid>

                    <Grid
                        value={value}
                        index={1}
                        style={{ paddingTop: 0 }}
                        container
                        direction="row"
                        item xs={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <ConfiguracoesReproducao
                        equipamento={equipamento}
                        />
                    </Grid>


                </SwipeableViews>


            </Grid>

        </div>
    )
}

export default ModuloSonoro;