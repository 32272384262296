import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuCliente from '../components/menu';
import Cookies from 'js-cookie';
import api from '../../../services/api';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NavBarAdmin from "../../../components/NavBarAdmin";
import Rodape from '../../../components/Rodape';
import Button from '@material-ui/core/Button';
import './styles.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';


//import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));



export default function ConfiguracoesFornecedor() {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [minhasConfiguracoes, setMinhasConfiguracoes] = useState(null);


    const [erroPrazoVencimento, setErroPrazoVencimento] = useState(false);
    const [textoErroPrazoVencimento, setTextoErroPrazoVencimento] = useState("");

    const [erroPrazoEspera, setErroPrazoEspera] = useState(false);
    const [textoErroPrazoEspera, setTextoErroPrazoEspera] = useState("");



    const [parametrosConfiguracao, setParametrosConfiguracao] = useState(
        {
            tipo_criacao: "manual",
            tipo_fatura: "nao",
            desativar_cliente: "nao",
            prazo_vencimento: 30,
            prazo_espera: 60,
            fatura_vence: "nao",
            email_destinatario_relatorio: "",
            cobrar_tarifa_mensal_uso_0: "nao",
        }
    );

    const [open, setOpen] = useState(false);

    const title = "Deseja atualizar suas configurações?";

    function validarPrazoVencimento() {

        if (parametrosConfiguracao.prazo_vencimento >= 0 && parametrosConfiguracao.prazo_vencimento <= 9999) {
            setErroPrazoVencimento(false);
            setTextoErroPrazoVencimento("")
            return true;
        } else {
            setErroPrazoVencimento(true);
            setTextoErroPrazoVencimento("Valor Prazo Vencimento Inválido!")
            return false;
        }
    }

    function validarPrazoEspera() {

        if (parametrosConfiguracao.prazo_espera >= 0 && parametrosConfiguracao.prazo_espera <= 9999) {
            setErroPrazoEspera(false);
            setTextoErroPrazoEspera("")
            return true;
        } else {
            setErroPrazoEspera(true);
            setTextoErroPrazoEspera("Valor Prazo Espera Inválido!")
            return false;
        }
    }


    function atualizar() {
        setOpen(true);

    }

    useEffect(() => {

        async function listarConfiguracoes() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                const id_usuario = parseInt(Cookies.get('id_usuario'), 10);
                console.log("id na tela Clientes: " + id_usuario)
                await api.get("v1/protected/fornecedores/configuracoes/listar/" + id_usuario, {
                    headers: headers
                }).then(function (response) {
                    var dados = response.data;
                    setMinhasConfiguracoes(dados)

                    setParametrosConfiguracao(prevState => ({ ...prevState, 'tipo_criacao': dados.tipo_criacao === 0 ? "manual" : "automatico" }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'tipo_fatura': dados.tipo_fatura === 0 ? "nao" : "sim" }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'desativar_cliente': dados.desativar_cliente === 0 ? "nao" : "sim" }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'prazo_vencimento': dados.prazo_vencimento }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'prazo_espera': dados.prazo_espera }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'email_destinatario_relatorio': dados.email_destinatario_relatorio }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'cobrar_tarifa_mensal_uso_0': dados.cobrar_tarifa_mensal_uso_0 === 0 ? "nao" : "sim" }))
                    setParametrosConfiguracao(prevState => ({ ...prevState, 'fatura_vence': dados.fatura_vence === 0 ? "nao" : "sim" }))


                    setLoading(false);

                });

            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar configuracoes: " + _err)

            }

        }
        listarConfiguracoes();

    }, []);

    async function onConfirm() {
        try {


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const teste_prazo_espera = validarPrazoEspera();
            const teste_prazo_vencimento = validarPrazoVencimento();



            const parametrosConfiguracao1 = (
                {
                    tipo_criacao: parametrosConfiguracao.tipo_criacao === "manual" ? 0 : 1,
                    tipo_fatura: parametrosConfiguracao.tipo_fatura === "nao" ? 0 : 1,
                    desativar_cliente: parametrosConfiguracao.desativar_cliente === "nao" ? 0 : 1,
                    prazo_vencimento: parametrosConfiguracao.prazo_vencimento,
                    prazo_espera: parametrosConfiguracao.prazo_espera,
                    fatura_vence: parametrosConfiguracao.fatura_vence === "nao" ? 0 : 1,
                    email_destinatario_relatorio: parametrosConfiguracao.email_destinatario_relatorio,
                    cobrar_tarifa_mensal_uso_0: parametrosConfiguracao.cobrar_tarifa_mensal_uso_0 === "nao" ? 0 : 1,

                }
            );


            if (teste_prazo_espera && teste_prazo_vencimento) {
                const id_configuracao = minhasConfiguracoes.id_configuracao;

                const response = await api.put('/v1/protected/fornecedores/configuracoes/atualizar/' + id_configuracao, parametrosConfiguracao1,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Configurações Atualizadas!");

                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }
    }





    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#DCDCDC' }}>
                <MenuCliente titulo={"Configurações"} icone={true}   />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    <Container maxWidth="lg" className={classes.container}>
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <div>

                                <Paper elevation={5} style={{ paddingBottom: 20 }} >
                                    <Typography variant="h6" component="h2" style={{ margin: 20, fontWeight: 'bold', paddingTop: 20 }}>
                                        Parâmetros de Faturamento
                                    </Typography>

                                    <Grid container spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid container spacing={1}
                                            direction="row"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                <Typography >
                                                    Geração de Faturas:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8}
                                                container spacing={1}
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <RadioGroup
                                                    value={parametrosConfiguracao.tipo_criacao}
                                                    onChange={(e) => setParametrosConfiguracao(prevState => ({ ...prevState, 'tipo_criacao': e.target.value }))}
                                                >
                                                    <FormControlLabel color="secondary" value={"manual"} control={<Radio />} label="Manual" />
                                                    <FormControlLabel color="success" value={"automatico"} control={<Radio />} label="Automático" />
                                                </RadioGroup>
                                            </Grid>

                                            <Grid container spacing={1}
                                                direction="row"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Grid item xs={4}>
                                                    <Typography style={{ textAlign: 'right' }}>
                                                        Gerar Links para Pagamento:
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={8} >
                                                    <FormControl>
                                                        <RadioGroup
                                                            name="controlled-radio-buttons-group"
                                                            value={parametrosConfiguracao.tipo_fatura}
                                                            onChange={(e) => setParametrosConfiguracao(prevState => ({ ...prevState, 'tipo_fatura': e.target.value }))}
                                                        >
                                                            <FormControlLabel value={"nao"} control={<Radio />} label="Não" />
                                                            <FormControlLabel value={"sim"} control={<Radio />} label="Sim" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}
                                                direction="row"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Grid item xs={4}>
                                                    <Typography style={{ textAlign: 'right' }}>
                                                        Cobrar Tarifa Mensal, mesmo se não houver consumo?
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={8} >
                                                    <FormControl>
                                                        <RadioGroup
                                                            name="controlled-radio-buttons-group"
                                                            value={parametrosConfiguracao.cobrar_tarifa_mensal_uso_0}
                                                            onChange={(e) => setParametrosConfiguracao(prevState => ({ ...prevState, 'cobrar_tarifa_mensal_uso_0': e.target.value }))}
                                                        >
                                                            <FormControlLabel value={"nao"} control={<Radio />} label="Não" />
                                                            <FormControlLabel value={"sim"} control={<Radio />} label="Sim" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            
                                            <Grid container spacing={1}
                                                direction="row"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Grid item xs={4}>
                                                    <Typography style={{ textAlign: 'right' }}>
                                                        A Fatura vence?
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={8} >
                                                    <FormControl>
                                                        <RadioGroup
                                                            name="controlled-radio-buttons-group"
                                                            value={parametrosConfiguracao.fatura_vence}
                                                            onChange={(e) => setParametrosConfiguracao(prevState => ({ ...prevState, 'fatura_vence': e.target.value }))}
                                                        >
                                                            <FormControlLabel value={"nao"} control={<Radio />} label="Não" />
                                                            <FormControlLabel value={"sim"} control={<Radio />} label="Sim" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                <Typography >
                                                    E-mail Relatorial:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8}
                                                container spacing={1}
                                                direction="row"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="standard"
                                                        name="email_destinatario_relatorio"
                                                        label=""
                                                        id="email_destinatario_relatorio"
                                                        value={parametrosConfiguracao.email_destinatario_relatorio}
                                                        onChange={(e) => setParametrosConfiguracao(prevState => ({ ...prevState, 'email_destinatario_relatorio': e.target.value }))}

                                                    />
                                                </Grid>

                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Paper elevation={5} style={{ paddingBottom: 20 }} >
                                    <Typography variant="h6" component="h2" style={{ margin: 20, fontWeight: 'bold', paddingTop: 20 }}>
                                        Parâmetros de Controle
                                    </Typography>

                                    <Grid container spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid container spacing={1}
                                            direction="row"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                <Typography >
                                                    Prazo de Vencimento:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8}
                                                container spacing={1}
                                                direction="row"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={2}>
                                                    <TextField
                                                        variant="standard"
                                                        name="prazo_vencimento"
                                                        label=""
                                                        id="prazo_vencimento"
                                                        value={parametrosConfiguracao.prazo_vencimento}
                                                        onChange={e => (e.target.value === '' || /^[.0-9\b]+$/.test(e.target.value)) ? setParametrosConfiguracao(prevState => ({ ...prevState, 'prazo_vencimento': e.target.value })) : null}
                                                        error={erroPrazoVencimento}
                                                        helperText={textoErroPrazoVencimento}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{ fontSize: 22 }}>
                                                        dias
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container
                                                direction="row"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Grid item xs={4}>
                                                    <Typography style={{ textAlign: 'right' }}>
                                                        Bloquear Cliente Inadiplente?
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={2} >
                                                    <FormControl>
                                                        <RadioGroup
                                                            name="controlled-radio-buttons-group"
                                                            value={parametrosConfiguracao.desativar_cliente}
                                                            onChange={(e) => setParametrosConfiguracao(prevState => ({ ...prevState, 'desativar_cliente': e.target.value }))}
                                                        >
                                                            <FormControlLabel value={"nao"} control={<Radio />} label="Não" />
                                                            <FormControlLabel value={"sim"} control={<Radio />} label="Sim" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                {parametrosConfiguracao.desativar_cliente === "sim" ?
                                                    <Grid item xs={6}
                                                        container spacing={1}
                                                        direction="row"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                                            <Typography >
                                                                Prazo de Espera:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} >
                                                            <TextField
                                                                variant="standard"
                                                                name="prazo_espera"
                                                                label=""
                                                                id="prazo_espera"
                                                                value={parametrosConfiguracao.prazo_espera}
                                                                onChange={e => (e.target.value === '' || /^[.0-9\b]+$/.test(e.target.value)) ? setParametrosConfiguracao(prevState => ({ ...prevState, 'prazo_espera': e.target.value })) : null}
                                                                error={erroPrazoEspera}
                                                                helperText={textoErroPrazoEspera}
                                                            />
                                                        </Grid>
                                                        <Typography style={{ fontSize: 22 }}>
                                                            dias
                                                        </Typography>

                                                    </Grid>
                                                    : null}
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Paper>


                                <Grid item xs={12}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    style={{ padding: 30 }}>


                                    <Grid item xs={1} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={atualizar}
                                        > Atualizar  </Button>
                                    </Grid>
                                </Grid>

                            </div>
                        }
                    </Container>

                </main>
            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">{title}</DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setOpen(false)}
                        color="secondary"
                    >
                        Não
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(false);
                            onConfirm();
                        }}
                        color="default"
                    >
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
            <div >
                <Rodape />
            </div>
        </div >
    );
}