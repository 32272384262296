import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import MenuRecursosFrota from '../components/menu';
import NavegadorGestao from "../../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastroInsumo() {



    const classes = useStyles();
    const history = useHistory();

    const [tipoVeiculo, setTipoVeiculo] = useState(0);
    const [tipoChassi, setTipoChassi] = useState(0);
    const [tipoMotor, setTipoMotor] = useState(0);
    const [tipoEixos, setTipoEixos] = useState(0);
    const [tipoTransmissao, setTipoTransmissao] = useState(0);
    const [placa, setPlaca] = useState('');
    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');
    const [anoModelo, setAnoModelo] = useState(0);
    const [anoVeiculo, setAnoVeiculo] = useState(0);
    const [cor, setCor] = useState('');
    const [numOcupantes, setNumOcupantes] = useState(0);
    const [capacidadePortaMalas, setCapacidadePortaMalas] = useState(0);
    const [descricao, setDescricao] = useState('');
    const [renavam, setRenavam] = useState('');
    const [chassi, setChassi] = useState('');
    const [statusVeiculo, setStatusVeiculo] = useState('');
    const [statusDocumentacao, setStatusDocumentacao] = useState('');
    const [autonomia, setAutonomia] = useState(10.0);


    async function salvar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                tipo_veiculo: tipoVeiculo,
                tipo_chassi: tipoChassi,
                tipo_motor: tipoMotor,
                tipo_eixos: tipoEixos,
                tipo_transmissao: tipoTransmissao,
                placa: placa,
                marca: marca,
                modelo: modelo,
                ano_modelo: anoModelo,
                ano_veiculo: anoVeiculo,
                cor: cor,
                num_ocupantes: numOcupantes,
                capacidade_porta_malas: capacidadePortaMalas,
                descricao: descricao,
                renavam: renavam,
                chassi: chassi,
                status_veiculo: statusVeiculo,
                status_documentacao: statusDocumentacao,
                autonomia: autonomia,
            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/veiculos/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Veículo Cadastrado!");
                history.push({
                    pathname: "/gestao/operacional/frota/veiculos/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Veiculos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }} >

                <React.Fragment >
                    <Grid container spacing={2}   >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            item xs={10}
                        >
                            <Button style={{ margin: 5 }}
                                variant="contained"
                                color="primary"
                                onClick={salvar}
                            > Salvar  </Button>
                        </Grid>

                        <Grid item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="status_-veiculo-label">Status Veículo</InputLabel>
                                    <Select
                                        labelId="status_-veiculo-label"
                                        id="status_-veiculo"
                                        value={statusVeiculo}
                                        onChange={(e) => setStatusVeiculo(e.target.value)}
                                        label="Status Veículo"
                                    >
                                        <MenuItem value={0}>APTO</MenuItem>
                                        <MenuItem value={1}>INABILITADO</MenuItem>
                                        <MenuItem value={2}>EM MANUTENÇÃO</MenuItem>
                                        <MenuItem value={3}>IRREGULAR</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="status-documentacao-label">Status Documentação</InputLabel>
                                    <Select
                                        labelId="status-documentacao-label"
                                        id="status-documentacao"
                                        value={statusDocumentacao}
                                        onChange={(e) => setStatusDocumentacao(e.target.value)}
                                        label="Status Documentação"
                                    >
                                        <MenuItem value={0}>IRREGUALAR</MenuItem>
                                        <MenuItem value={1}>REGULAR</MenuItem>
                                        <MenuItem value={2}>VENCIDO</MenuItem>
                                        <MenuItem value={3}>BAIXADO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid
                            style={{ paddingTop: 5 }}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >


                            <Grid container spacing={2}>
                                {/* TextFields */}
                                <Grid item xs={2}>
                                    <TextField
                                        variant="standard"
                                        name="marca"
                                        label="Marca"
                                        required
                                        id="marca"
                                        value={marca}
                                        onChange={(e) => setMarca(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="standard"
                                        name="modelo"
                                        label="Modelo"
                                        required
                                        id="modelo"
                                        value={modelo}
                                        onChange={(e) => setModelo(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="standard"
                                        name="cor"
                                        label="Cor"
                                        required
                                        id="cor"
                                        value={cor}
                                        onChange={(e) => setCor(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        variant="standard"
                                        name="placa"
                                        label="Placa"
                                        required
                                        id="placa"
                                        value={placa}
                                        onChange={(e) => setPlaca(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={2}>
                                    <TextField
                                        variant="standard"
                                        name="num_ocupantes"
                                        label="Número de Ocupantes"
                                        type="number"
                                        required
                                        id="num_ocupantes"
                                        value={numOcupantes}
                                        onChange={(e) => setNumOcupantes(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="standard"
                                        name="capacidade_porta_malas"
                                        label="Capacidade Porta-Malas"
                                        type="number"
                                        required
                                        id="capacidade_porta_malas"
                                        value={capacidadePortaMalas}
                                        onChange={(e) => setCapacidadePortaMalas(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >



                                    <Grid item xs={6}>
                                        <TextField
                                            variant="standard"
                                            name="renavam"
                                            label="Renavam"
                                            required
                                            id="renavam"
                                            value={renavam}
                                            onChange={(e) => setRenavam(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="standard"
                                            name="chassi"
                                            label="Chassi"
                                            required
                                            id="chassi"
                                            value={chassi}
                                            onChange={(e) => setChassi(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="ano_modelo"
                                                label="Ano do Modelo"
                                                type="number"
                                                required
                                                id="ano_modelo"
                                                value={anoModelo}
                                                onChange={(e) => setAnoModelo(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="ano_veiculo"
                                                label="Ano do Veículo"
                                                type="number"
                                                required
                                                id="ano_veiculo"
                                                value={anoVeiculo}
                                                onChange={(e) => setAnoVeiculo(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="autonomia"
                                                label="Autonomia"
                                                type="number"
                                                required
                                                id="autonomia"
                                                value={autonomia}
                                                onChange={(e) => setAutonomia(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Selects */}
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo-veiculo-label">Tipo Veículo</InputLabel>
                                        <Select
                                            labelId="tipo-veiculo-label"
                                            id="tipo-veiculo"
                                            value={tipoVeiculo}
                                            onChange={(e) => setTipoVeiculo(e.target.value)}
                                            label="Tipo Veículo"
                                        >
                                            <MenuItem value={0}>Carro</MenuItem>
                                            <MenuItem value={1}>Moto</MenuItem>
                                            <MenuItem value={2}>Caminhão</MenuItem>
                                            <MenuItem value={3}>Van</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo-chassi-label">Tipo Chassi</InputLabel>
                                        <Select
                                            labelId="tipo-chassi-label"
                                            id="tipo-chassi"
                                            value={tipoChassi}
                                            onChange={(e) => setTipoChassi(e.target.value)}
                                            label="Tipo Chassi"
                                        >
                                            <MenuItem value={0}>Passeio</MenuItem>
                                            <MenuItem value={1}>Utilitário</MenuItem>
                                            <MenuItem value={2}>Sedan</MenuItem>
                                            <MenuItem value={3}>Sport</MenuItem>
                                            <MenuItem value={4}>Caminhonete</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo-motor-label">Tipo Motor</InputLabel>
                                        <Select
                                            labelId="tipo-motor-label"
                                            id="tipo-motor"
                                            value={tipoMotor}
                                            onChange={(e) => setTipoMotor(e.target.value)}
                                            label="Tipo Motor"
                                        >
                                            <MenuItem value={0}>Gasolina</MenuItem>
                                            <MenuItem value={1}>Álcool</MenuItem>
                                            <MenuItem value={2}>Diesel</MenuItem>
                                            <MenuItem value={3}>Flex</MenuItem>
                                            <MenuItem value={4}>Elétrico</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo-eixos-label">Tipo Eixos</InputLabel>
                                        <Select
                                            labelId="tipo-eixos-label"
                                            id="tipo-eixos"
                                            value={tipoEixos}
                                            onChange={(e) => setTipoEixos(e.target.value)}
                                            label="Tipo Eixos"
                                        >
                                            <MenuItem value={0}>Tração Dianteira</MenuItem>
                                            <MenuItem value={1}>Tração Traseira</MenuItem>
                                            <MenuItem value={2}>4x4</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo-transmissao-label">Tipo Transmissão</InputLabel>
                                        <Select
                                            labelId="tipo-transmissao-label"
                                            id="tipo-transmissao"
                                            value={tipoTransmissao}
                                            onChange={(e) => setTipoTransmissao(e.target.value)}
                                            label="Tipo Transmissão"
                                        >
                                            <MenuItem value={0}>Manual</MenuItem>
                                            <MenuItem value={1}>Automática</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        name="descricao"
                                        label="Descrição"
                                        id="descricao"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                            </Grid>
                        </Grid>




                    </Grid>


                </React.Fragment>


            </Container>

        </div >
    );
}


