import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';

import Rodape from '../../../../components/Rodape';
import './styles.scss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from '@material-ui/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

import api from '../../../../services/api';
import MenuCliente from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

const drawerWidth = 240;


const ColorButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
        backgroundColor: 'DarkGreen',
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));



export default function Cartoes() {
    const classes = useStyles();
    const [cartoes, setCartoes] = useState([]);
    const theme = useTheme();

    const [loading, setLoading] = useState(true);

    const title = "Deseja mudar o status do cartão?";
    const [open, setOpen] = useState(false);
    const [idCartaoAtivamento, setIdCartaoAtivamento] = useState(-1);

    const titleBloqueio = "Deseja mudar o bloqueio do cartão?";
    const [openBloqueio, setOpenBloqueio] = useState(false);
    const [idCartaoBloqueio, setIdCartaoBloqueio] = useState(-1);


    async function listarMeusDados() {

        setLoading(true);
        try {


            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("v1/protected/cartoes/listarTodos", {
                headers: headers
            }).then(function (response) {
                setCartoes(response.data)
                console.log(" Cartoes: " + response);

                setLoading(false);
            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar cartoes: " + _err)

        }

    }


    useEffect(() => {


        listarMeusDados();


    }, []);

    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia) +
            "\n Endereço: " + (fornecedor.tipo_endereco === 0 ? "Normal " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
        return texto;
    }

    function returnDescricaoCliente(cliente) {
        const descricao = (cliente.tipo_cliente === 0 ? cliente.cpf : cliente.cnpj) + " " +
            (cliente.tipo_cliente === 0 ? cliente.nome + " " + cliente.sobrenome : cliente.nome_fantasia);

        return descricao;
    }


    async function onConfirm() {

        try {


            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.put("v1/protected/cartoes/ativamentocartao/" + idCartaoAtivamento, {
                headers: headers
            }).then(function (response) {

            });

            setIdCartaoAtivamento(-1);
            listarMeusDados();

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao desativar o cartao: " + _err)

        }

    }

    async function onConfirmBloqueio() {

        try {


            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.put("v1/protected/cliente/bloqueiocartao/" + idCartaoBloqueio, {
                headers: headers
            }).then(function (response) {

            });

            setIdCartaoBloqueio(-1);
            listarMeusDados();
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao bloquear o cartao: " + _err)

        }

    }

    function verificar(idCartao) {
        setIdCartaoAtivamento(idCartao);
        setOpen(true);
    }

    function verificarBloqueio(idCartao) {
        setIdCartaoBloqueio(idCartao);
        setOpenBloqueio(true);
    }



    const [parametrosAssociacao, setParametrosAssociacao] = useState(
        {
            id_cliente: 0,
            id_cartao: 0,
        }
    );
    const [clientes, setClientes] = useState([]);
    const [loadingClientes, setLoadingClientes] = useState([]);


    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = (cartao) => {

        console.log("cartao: " + cartao.id_cartao);

        listarClientes(cartao.fornecedor.id_fornecedor);
        setParametrosAssociacao(prevState => ({ ...prevState, 'id_cartao': cartao.id_cartao }))
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };


    const handleClienteNovaAssociacao = (e) => {

        setParametrosAssociacao(prevState => ({ ...prevState, 'id_cliente': e.target.value }))

    };


    async function associar() {

        if (parametrosAssociacao.id_cliente > 0) {
            try {

                const token = Cookies.get('token');
                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                const response = await api.post('/v1/protected/clientes/cadastrarassociacao', parametrosAssociacao,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    setOpenDialog(false);
                    //   setExecution(false);
                    listarMeusDados();

                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }

            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar meus cartoes: " + _err)
                alert("Erro ao associar, tente novamente!");

            }
        } else {
            alert("Selecione o cliente");
        }
    }


    async function listarClientes(id_fornecedor) {

        setLoadingClientes(true);
        try {


            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }


            const id_usuario = parseInt(Cookies.get('id_usuario'), 10);
            console.log("id na tela cartoes: " + id_usuario)

            //listarCLiente
            let url = "v1/protected/clientes/listarporfornecedor/" + id_fornecedor;
            console.log("url: " + url);
            await  api.get(url, {
                headers: headers
            }).then(function (response) {

                setClientes(response.data);
                setLoadingClientes(false);
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar cartoes: " + _err)

        }

    }






    function Row(props) {
        const { row } = props;

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>


                    <TableCell colSpan={1} align="right">{row.id_cartao}</TableCell>
                    <TableCell colSpan={1} align="center">
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color={row.status === 0 ? "primary" : "secondary"}
                                onClick={() => verificar(row.id_cartao)}
                            > {row.status === 0 ? "ATIVAR" : "DESATIVAR"} </Button>
                        </Grid>
                    </TableCell>                    <TableCell colSpan={1} align="right">{row.uid}</TableCell>
                    <TableCell colSpan={1} align="right">{row.nome}</TableCell>
                    <TableCell>

                        <Grid item xs={12}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <IconButton style={{ marginTop: 20 }}
                                        onClick={() => verificarBloqueio(row.id_cartao)}
                                    >
                                        {row.bloqueio === 1 ? "Desbloquear"
                                            : "Bloquear"
                                        }
                                        {row.bloqueio === 0 ?
                                            <Lock
                                                fontSize={'large'} style={{ color: "red" }}
                                            > </Lock> :
                                            <LockOpen
                                                fontSize={'large'} style={{ color: "green" }}

                                            />}
                                    </IconButton>
                                </Grid>

                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell colSpan={1} align="right">{returnDescricaoFornecedor(row.fornecedor)}</TableCell>
                    <TableCell colSpan={1} align="right">{row.cliente != null ? returnDescricaoCliente(row.cliente) :
                        <Grid item xs={12}>
                            <ColorButton
                                variant="contained"
                                onClick={() => handleClickOpen(row)}
                            > Associar </ColorButton>
                        </Grid>
                    }</TableCell>



                </TableRow>
            </React.Fragment>
        );
    }


    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} >
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Status</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>UID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Nome</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Bloqueio</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Fornecedor</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Cliente</TableCell>
                            <TableCell style={{ backgroundColor: 'white', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cartoes.map((cartao) => (
                            <Row key={cartao.id_cartao} row={cartao} />
                        ))}
                    </TableBody>





                </Table>
            </TableContainer>
        );
    }


    function novoCartao() {
        window.location.href = "/cadastrarcartao"

    }



    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#DCDCDC' }}>
                <MenuCliente titulo={"Cartões"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    <Container maxWidth="lg" className={classes.container}>
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :
                            <div>
                                <CollapsibleTable />
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <Button style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={novoCartao}
                                    >
                                        Novo Cartão
                                    </Button>
                                </Grid>
                            </div>
                        }
                    </Container>

                </main>
            </div>
            <Dialog
                open={openBloqueio}
                onClose={() => setOpenBloqueio(false)}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">{titleBloqueio}</DialogTitle>
                <DialogContent>{""}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setOpenBloqueio(false)}
                        color="secondary"
                    >
                        Não
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenBloqueio(false);
                            onConfirmBloqueio();
                        }}
                        color="default"
                    >
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>

          



            <Dialog open={openDialog} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle>Nova Associação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Selecione o Colaborador para associar ao Cartão
                    </DialogContentText>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        item xs={12}

                    >
                        <Grid
                            container
                            direction="row"
                            item xs={12}
                        >
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                justifyContent="flex-start"
                                alignItens="flex-start"
                            >
                                <InputLabel style={{ fontSize: 18, color: 'black' }} id="uid_cartao">Colaborador:</InputLabel>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                justifyContent="flex-start"
                                alignItens="flex-start"
                            >
                                {loadingClientes ? <div></div> :
                                    <Select
                                        labelId="id_cliente"
                                        id="id_cliente"
                                        value={parametrosAssociacao.id_cliente}
                                        name="id_cliente"
                                        onChange={e => handleClienteNovaAssociacao(e)}
                                        label="id_cliente"
                                        style={{ paddingLeft: 5, width: '100%', fontSize: 22, color: 'black' }}
                                    >
                                        <MenuItem value={0}>Selecione o Colaborador</MenuItem>
                                        {
                                            clientes.map((cliente) => {
                                                return (
                                                    <MenuItem value={cliente.id_cliente}>{returnDescricaoCliente(cliente)}</MenuItem>
                                                )
                                            })
                                        }

                                    </Select>

                                }

                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={associar}>Associar</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">{title}</DialogTitle>
                <DialogContent>{""}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setOpen(false)}
                        color="secondary"
                    >
                        Não
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(false);
                            onConfirm();
                        }}
                        color="default"
                    >
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>


            <div >
                <Rodape />
            </div>
        </div>
    );
}