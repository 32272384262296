import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import globalConfig from '../../../../../../configs/globalConfigs';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';





import Cookies from 'js-cookie';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListSubheader from '@material-ui/core/ListSubheader';

import './styles.css'; // Importe o arquivo de estilo CSS


const drawerWidth = 300;


const useStyles = makeStyles((theme) => ({
    root: {

        
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: 'white',
        backgroundColor: 'black',
        zIndex: 0,
    },
    toolbarIcon: {
      
        
    },
    icone: {
     
        

    },
    appBar: {
      
    },
    appBarShift: {


    },
    appBarDesktop: {
      
    },
    appBarShiftDesktop: {
       


    },
    menuButton: {
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
       
        
    },

    drawerPaperClose: {

        
    },
    drawerPaperDesktop: {
    
        
    },
    drawerPaperCloseDesktop: {
      
        
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
       
    },
    container: {
      
        
    },
    paper: {
      
        

    },
    fixedHeight: {
  
        
    },
}));


export default function MenuGerenciarRotas(props) {


    const classes = useStyles();

    const [width, setWidth] = useState(0);

    const regra = Cookies.get('regra');






    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

       

        setWidth(largura)

    }


    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });

  


    useEffect(() => {

        checkDimenssoes();

    }, [width]);



    



    const MenuMobile = () => {
        return (
            <>

                <CssBaseline />
                <AppBar position="absolute" style={{ top: 60 }} className={clsx( classes.appBarShift)}>
                    <Toolbar id="target" className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            className={clsx( classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"


                        >
                            <Grid
                                container
                                direction="row"
                                item xs={6} sm={6} md={6} lg={6} xl={6}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                    {props.titulo}
                                </Typography>
                                {props.subtitulo !== undefined ?
                                    <Typography style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {"------ " + props.subtitulo}</Typography> : null
                                }

                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
               
            </>
        )
    }

    const MenuDesktop = () => {
        return (
            <>
                <CssBaseline />
                <AppBar position="absolute" style={{ top: globalConfig.appBarTop }} className={clsx( classes.appBarShiftDesktop)}>
                    <Toolbar id="target" className={classes.toolbar} >
                       
                       
                        <Grid
                            container
                            direction="row"
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            justifyContent="flex-start"
                            alignItems="center"


                        >
                            <Grid
                                container
                                direction="row"
                                item xs={6} sm={6} md={6} lg={6} xl={6}
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {props.link1 !== undefined ?
                                        <Grid  >
                                            <Link to={props.link1[1]}>
                                                <Typography component="h1" style={{ fontSize: 14 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link1[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }
                                    {props.link1 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }

                                    {props.link2 !== undefined ?
                                        <Grid>
                                            <Link to={props.link2[1]}>
                                                <Typography component="h1" style={{ fontSize: 14 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link2[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }
                                    {props.link2 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link3 !== undefined ?
                                        <Grid>
                                            <Link to={props.link3[1]}>
                                                <Typography component="h1" style={{ fontSize: 14 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link3[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link3 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link4 !== undefined ?
                                        <Grid>
                                            <Link to={props.link4[1]}>
                                                <Typography component="h1" style={{ fontSize: 14 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link4[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link4 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link5 !== undefined ?
                                        <Grid>
                                            <Link to={props.link5[1]}>
                                                <Typography component="h1" style={{ fontSize: 14 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link5[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link5 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    {props.link6 !== undefined ?
                                        <Grid>
                                            <Link to={props.link6[1]}>
                                                <Typography component="h1" style={{ fontSize: 14 }} variant="h6" color="inherit" noWrap className={classes.title}>
                                                    {props.link6[0]}
                                                </Typography>
                                            </Link>
                                        </Grid>

                                        : null
                                    }

                                    {props.link6 !== undefined ?
                                        <Grid >
                                            <span style={{ whiteSpace: 'pre' }}> {" / "} </span>
                                        </Grid> : null
                                    }


                                    <Typography component="h1" variant="h6" style={{ fontSize: 16 }} color="inherit" noWrap className={classes.title}>
                                        {props.titulo}
                                    </Typography>

                                </Grid>

                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>

                <Drawer

                    variant="permanent"
                    classes={{
                        paper: clsx( classes.drawerPaperCloseDesktop),
                    }}
                >
                    


                </Drawer>
            </>
        )
    }

    return (


        <div>
            {
                width < 900 ?
                    <MenuMobile /> : <MenuDesktop />
            }


        </div>


    )

};