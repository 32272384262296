import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper } from '@mui/material';

import { styled } from '@material-ui/styles';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@material-ui/icons';
import { parse, format } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import api from '../../../../../services/api';
import { Card, CardContent } from '@mui/material';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import office from '../../../../../assets/imgs/office.png';
import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';



const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'green',
        borderColor: 'green',
    },
}));



export default function EditarCompra() {


    const history = useHistory();

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    const { idOrdemCompra } = useParams();
    const { idPreCotacao } = useParams();
    const [preCotacao, setPreCotacao] = useState(null);

    const [loading, setLoading] = useState(true);


    const [linkRastreio, setLinkRastreio] = useState('');
    const [codigoRastreio, setCodigoRastreio] = useState('');
    const [linkCompra, setLinkCompra] = useState('');
    const [codigoCompra, setCodigoCompra] = useState('');
    const [observacoes, setObservacoes] = useState('');

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/ordensdecompras/ordemdecompra/precotacao/listarporid/" + idPreCotacao, {
                headers: headers
            }).then(function (response) {

                setPreCotacao(response.data);

                setCodigoCompra(response.data.codigo_compra);
                setLinkCompra(response.data.link_compra);

                setCodigoRastreio(response.data.codigo_rastreio);
                setLinkRastreio(response.data.link_rastreio);

                setObservacoes(response.data.observacao_compra);

                setLoading(false);



            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }

    useEffect(() => {


        listarInformacoes();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };



    async function atualizar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {

                codigo_compra: codigoCompra,
                link_compra: linkCompra,
                codigo_rastreio: codigoRastreio,
                link_rastreio: linkRastreio,
                observacao_compra: observacoes,

            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));




            const response = await api.put('/v1/protected/ordensdecompras/ordemdecompra/atualizarcompra/' + idPreCotacao + "/" + id_usuario_logado, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Compra Atualizada!");

               
                history.push({
                    pathname: "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra,
                })

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    }




    return (
        <div>
            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5, padding: 10 }} >
                {loading
                    ?
                    <div></div>

                    :

                    <React.Fragment >
                        <Grid
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 5 }}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    align="flex-start"
                                    item xs={12}
                                    style={{ padding: 5 }}
                                >

                                    <a href="/gestao/administracao/ordensdecompras/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"Ordens de Compras"}</a>
                                    <span style={{ fontSize: 12 }}>-</span>
                                    <a href={"/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra} style={{ fontSize: 14, color: 'black', marginLeft: '5px' }}>{"Ordem de Compra nº " + idOrdemCompra}</a>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ padding: 5 }}
                                >


                                    <Grid item xs={12}
                                        style={{ padding: 2 }}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"

                                    >

                                        <Grid item xs={3}
                                            style={{ padding: 2 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="codigoCompra"
                                                label="Código da Compra"
                                                required
                                                id="codigoCompra"
                                                value={codigoCompra}
                                                onChange={(e) => setCodigoCompra(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={9}
                                            style={{ padding: 2 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="linkCompra"
                                                label="Link da Compra"
                                                required
                                                id="linkCompra"
                                                value={linkCompra}
                                                onChange={(e) => setLinkCompra(e.target.value)}
                                                fullWidth
                                                rows={1}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={12}
                                        style={{ padding: 2 }}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"

                                    >

                                        <Grid item xs={3}
                                            style={{ padding: 2 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="codigoRastreio"
                                                label="Código de Rastreio"
                                                required
                                                id="codigoRastreio"
                                                value={codigoRastreio}
                                                onChange={(e) => setCodigoRastreio(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={9}
                                            style={{ padding: 2 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="linkRastreio"
                                                label="Link de Rastreio"
                                                required
                                                id="linkRastreio"
                                                value={linkRastreio}
                                                onChange={(e) => setLinkRastreio(e.target.value)}
                                                fullWidth
                                                rows={1}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={12}
                                            style={{ padding: 2 }}

                                        >
                                            <TextField
                                                variant="standard"
                                                name="observacoes"
                                                label="Observações"
                                                required
                                                id="observacoes"
                                                value={observacoes}
                                                onChange={(e) => setObservacoes(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={2}
                                            />
                                        </Grid>


                                </Grid>



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ marginTop: 2 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={atualizar}
                                    > Atualizar </Button>
                                </Grid>



                            </Grid>

                        </Grid>
                    </React.Fragment>
                }

            </div>




        </div >
    );
}


