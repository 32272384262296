import React, { useState, useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../services/api';
import Grid from '@material-ui/core/Grid';
import './styles.css';
import Carousel from 'react-bootstrap/Carousel'
import { useParams } from "react-router-dom";
import RodapeBlog from '../../components/RodapeBlog';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper'

const Blog = () => {

  const { categoria } = useParams();
  const [loading, setLoading] = useState(true);
  const [noticias, setNoticias] = useState([]);
  const [ultimasNoticias, setUltimasNoticias] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loadingCategorias, setLoadingCategorias] = useState(true);
  const [categorias, setCategorias] = useState([]);

  const [parametros, setParametros] = useState(
    {
      categoria: "%%",
      page: 0,
      size: 9,
    }
  );

  const [width, setWidth] = useState(0);


  function checkDimenssoes() {
    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    setWidth(largura);

  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });


  /*
 <BlogPage1
          titulo={props.titulo}
          subtitulo={props.sub_titulo}
          data={props.data_noticia}
          hora={props.hora_noticia + "h"}
          redator={props.usuario.nome + " " + props.usuario.sobrenome}

          img1={props.url_img1}
          video1={props.url_video1}
          texto1={props.texto1}

          citacao={props.citacao}

          img2={props.url_img2}
          video2={props.url_video2}
          texto2={props.texto2}


          img3={props.url_img3}
          video3={props.url_video3}
          texto3={props.texto3}


        />
  */

  const handleChangePage = (event, value) => {

    setPage(value);
    let num_pagina = parseInt(value) - 1;
    console.log("numero da pagina: " + num_pagina)
    setParametros(prevState => ({ ...prevState, 'page': num_pagina }))

  };


  useEffect(() => {

    async function listarNoticias() {
      try {




        const response = await api.get('/v1/protected/noticias/listarNoticias', {
          params: {
            page: parametros.page,
            size: parametros.size,
            categoria: categoria,
          }
        });
        setNoticias(response.data.content);
        setTotalPages(response.data.totalPages);

        setUltimasNoticias(response.data.content.slice(0, 3));
        console.log("noticia 1: " + response.data.content[0].id_noticia)

        setLoading(false);

      } catch (_err) {
        // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
        console.log("Erro ao listar noticias: " + _err)

      }

    }

    checkDimenssoes();
    listarNoticias();


  }, [parametros, categoria]);


  useEffect(() => {
    async function listarCategorias() {

      setLoadingCategorias(true);

      try {
        const response = await api.get('/v1/protected/noticias/listarCategorias');
        setCategorias(response.data);

        setLoadingCategorias(false);

      } catch (_err) {
        // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
        console.log("Erro ao listar categorias: " + _err)
      }

    }

    listarCategorias();

  }, []);

  return (
    <div >

      <div >

        <Navegador noticias={'underline'} corFundo={'white'} travado={true} corTexto={'black'} />

      </div>



      <Grid
        container
        direction="row"
        item xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent="center"
        alignItems="center"
      >

        {loading ?
          <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
          </Skeleton> :
          <Carousel interval={5000} fade style={{ width: '100%' }}>
            {
              ultimasNoticias.map((noticia) => (
                <Carousel.Item key={noticia.id_noticia}
                >
                  <div style={{
                    backgroundImage: `url(${noticia.url_capa})`,
                    backgroundSize: "cover",
                    width: '100%'
                  }}  >

                    <div style={{ backgroundColor: 'rgba(3,0,15,0.6)', color: 'white' }}>
                      <Grid
                        container
                        direction="row"
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                        justifyContent="center"
                        alignItems="center"
                        style={{ paddingTop: 100, paddingBottom: 100, paddingInline: width < 1300 ? 30 : null }}
                      >

                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}
                        >
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          item xs={12} sm={12} md={12} lg={8} xl={8}
                          justifyContent="center"
                          alignItems="center"
                          style={{ paddingBottom: 10 }}
                        >

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                            style={{ paddingBottom: 10, height: 250, minHeight: 250, maxHeight: 250 }}
                          >

                            <a style={{ fontWeight: 'bold', fontSize: 15, color: 'green' }}
                              href={"/noticias/" + noticia.categoria.nome_categoria}
                            >
                              {noticia.categoria.nome_categoria}
                            </a>
                            <br></br>
                            <a
                              href={"/noticias/" + noticia.data_noticia + "/" + encodeURIComponent(noticia.titulo) + "/" + noticia.id_noticia}>
                              <span style={{ fontWeight: 'bold', fontSize: 48, color: 'white' }}>
                                {noticia.titulo}
                              </span>

                            </a>

                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                            style={{ height: 150, minHeight: 150, maxHeight: 150 }}
                          >
                            <span style={{ fontWeight: 'bold', fontSize: 22, color: 'white' }}>
                              {noticia.sub_titulo}-...
                            </span>
                          </Grid>



                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}
                        >
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Carousel.Item>
              ))
            }
          </Carousel>
        }

        <span style={{ paddingTop: 20, paddingBottom: 20, width: '100%', fontWeight: 'bold', color: 'black', textAlign: 'center', fontSize: 36, textDecoration: 'underline' }} >Blog do Grupo Rosinetos</span>

        {!loadingCategorias ?
          <Grid
            container
            direction="row"
            item xs={12} sm={12} md={12} lg={12} xl={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 20, marginRight: 20, paddingTop: 5, paddingBottom: 10, textDecoration: 'underline', color: 'black' }}
          >

            {
              categorias.slice(0, 10).map((categoria) => (
                <a
                  href={"/noticias/" + encodeURIComponent(categoria.nome_categoria)}>
                  <span style={{ paddingLeft: 10, paddingRight: 10, width: '100%', textAlign: 'center', fontSize: 16, color: 'black' }} >{categoria.nome_categoria}</span>
                </a>

              ))
            }
          </Grid>
          : null
        }


      </Grid>

      <div>
        {loading ?
          <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
          </Skeleton>
          :
          <div>


            <Grid
              container
              direction="row"
              item xs={12} sm={12} md={12} lg={12} xl={12}
              justifyContent="center"
              alignItems="center"
              style={{ paddingTop: 30, backgroundColor: 'Snow', margin: 10 }}

            >


              {
                noticias.map((noticia) => (

                  <Grid
                    key={noticia.id_noticia}
                    container
                    item xs={12} sm={6} md={3} lg={3} xl={3}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{
                      paddingBottom: 60, margin: 10, display: 'flex',
                      flexDirection: 'column',
                    }}
                    component={Paper} elevation={5}
                  >

                    <img alt="img1" style={{ width: '100%', height: 250 }}

                      src={noticia.url_capa}
                    />
                    <a style={{ textAlign: 'left', height: '100%', padding: 10, fontSize: 14, paddingTop: 30, lineHeight: '20px', color: 'green', fontWeight: 'bold' }} href={"/noticias/" + noticia.categoria.nome_categoria}>{noticia.categoria.nome_categoria}</a>
                    <p style={{ margin: 5 }} />
                    <a style={{
                       textAlign: 'left', 
                       padding: 10, 
                       fontSize: 16, 
                       paddingTop: 10, 
                       lineHeight: '20px', 
                       height: 80, /* altura do grid */ 
                       color: 'black', 
                       fontWeight: 'bold',
                       display: '-webkit-box',
                       WebkitLineClamp: 3,
                       WebkitBoxOrient: 'vertical',
                       overflow: 'hidden'
                    }} href={"/noticias/" + noticia.data_noticia + "/" + encodeURIComponent(noticia.titulo) + "/" + noticia.id_noticia} >{noticia.titulo}</a>
                    <p style={{ margin: 5 }} />
                    <span style={{ fontSize: 12, height: '100%', padding: 10 }} > {noticia.texto1.slice(0, 110) + "[...]"} </span>
                    <span style={{ fontSize: 16, height: '100%', padding: 10 }} > Em {noticia.data_noticia} {noticia.hora_noticia} </span>
                    <p style={{ margin: 5 }} />
                  </Grid>
                ))
              }


            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                size="large"
                color="primary"
              />
            </Grid>


          </div>

        }


      </div>
      {!loadingCategorias ?
        <div>
          <RodapeBlog categorias={categorias} />
        </div>
        : null
      }
      <div >
        <Rodape />
      </div>
    </div >
  );
}

export default Blog;