import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";
import Skeleton from '@material-ui/lab/Skeleton';
import {
    GoogleMap,
    
    LoadScript,
   
    
   OverlayView
} from "@react-google-maps/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from '@fortawesome/free-solid-svg-icons';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));




export default function VisaoGeralTransmissores() {

    const classes = useStyles();
    const [transmissores, setTransmissores] = useState([]);
    const [loadingTransmissores, setLoadingTransmissores] = useState(true);
 
    const options = {
        mapTypeId: 'satellite'
    };




    const containerStyle = {
        width: '1200px',
        height: '800px'
    };






    async function listarTransmissores() {

        setLoadingTransmissores(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const url = "v1/protected/transmissores/listartodas";
            console.log("Url: " + url)

            await api.get(url, {
                headers: headers
            }).then(function (response) {

                setTransmissores(response.data)



                setLoadingTransmissores(false)





            });



        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar transmissores: " + _err)

        }

    }


    useEffect(() => {


        listarTransmissores();





    }, []);




    const center = {
        lat: -30.574343676732784,
        lng: -50.47847309084829,
    };


    const AnyReactComponent = ({ text, data, status, lat, lng }) => (
        <OverlayView
            position={{ lat, lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height / 2),
            })}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: "translate(-50%, -50%)",
                    height: "100px",
                    width: "400px",
                }}
            >

                <FontAwesomeIcon icon={faWifi} size="4x" style={{ color: status === 1 ? "blue" : "red" }} />

                <div>
                    <span style={{ backgroundColor: "white", color: 'blue', fontSize: '22px', fontWeight: 'bold' }}> {"Monitor nº série: " + text}</span>
                    <br></br>
                    <span style={{ backgroundColor: "white", color: 'blue', fontSize: '22px', fontWeight: 'bold' }} > {"Última Resposta: " + data}</span>
                </div>
                <span style={{ backgroundColor: "white", color: 'blue', fontSize: '22px', fontWeight: 'bold' }} > {"Status: " + (status === 1 ? "Online 🟢" : "Offline 🔴")}</span>

            </div>
        </OverlayView>
    );




    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
                <MenuAdmin titulo={"Visão Geral dos Monitores em Tempo Real"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >

                        <React.Fragment >
                            <Grid container

                                item xs={12}
                                direction="row"
                                justifyContent="center"
                                align="center"


                            >

                                {
                                    loadingTransmissores ?
                                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                                        </Skeleton>
                                        :
                                        <div style={{ height: '100%', width: '100%' }}>
                                            <LoadScript googleMapsApiKey={"AIzaSyDYUFTfxXyi3TBHgkne5iKbsgooVXchxUU"}>
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    options={options}
                                                    center={center}
                                                    zoom={21}
                                                >
                                                    {transmissores.map((transmissor) => {
                                                        return (
                                                            <AnyReactComponent
                                                                key={transmissor.id_transmissor}

                                                                lat={parseFloat(transmissor.latitude)}
                                                                lng={parseFloat(transmissor.longitude)}
                                                                text={transmissor.numero_serie}
                                                                data={transmissor.data_hora_ultima_resposta}
                                                                status={transmissor.status_online}
                                                            />
                                                        )
                                                    })}
                                                </GoogleMap>

                                            </LoadScript>

                                        </div>
                                }
                            </Grid>
                        </React.Fragment>

                    </Container>
                </main >
            </div >
        </div >
    );
}


