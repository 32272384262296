import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';

import { useHistory } from "react-router-dom";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";




export default function EditarCotacao() {



    const history = useHistory();

    const { idCotacao } = useParams();

    const [dadosInsumo, setDadosInsumo] = useState();;
    const [loading, setLoading] = useState(true);

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);


    const [data, setData] = useState('');
    const [metodosPagamento, setMetodosPagamento] = useState([]);
    const [condicoesPagamento, setCondicoesPagamento] = useState([]);
    const [valorUnitario, setValorUnitario] = useState(0.0);
    const [descricao, setDescricao] = useState('');

    const [metodosPagamentosSelecionados, setMetodosPagamentosSelecionados] = useState([]);
    const [condicoesPagamentosSelecionadas, setCondicoesPagamentosSelecionadas] = useState([]);

    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
    const [link, setLink] = useState('');

    const [observacoes, setObservacoes] = useState('');


    const [fornecedores, setFornecedores] = useState([]);


    function handleSetFornecedor(fornecedor) {
        setFornecedorSelecionado(fornecedor);
    }

    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/almoxarifado/cotacoes/listarporidcotacao/" + idCotacao, {
                headers: headers
            }).then(function (response) {
                const id_insumo = response.data.id_insumo;

                setFornecedorSelecionado(response.data.fornecedor);
                setValorUnitario(response.data.valor_unitario);
                setDescricao(response.data.descricao);
                setMetodosPagamentosSelecionados(response.data.metodos_pagamento);
                setCondicoesPagamentosSelecionadas(response.data.condicoes_pagamento);
                setLink(response.data.link);
                setObservacoes(response.data.observacoes);


                api.get("v1/protected/almoxarifado/insumos/listarporid/" + id_insumo, {
                    headers: headers
                }).then(function (response) {

                    setDadosInsumo(response.data)



                    api.get("v1/protected/financeiro/metodospagamento/listartodos", {
                        headers: headers
                    }).then(function (response) {

                        setMetodosPagamento(response.data)


                        api.get("v1/protected/financeiro/condicoespagamento/listartodos", {
                            headers: headers
                        }).then(function (response) {

                            setCondicoesPagamento(response.data)


                            api.get("v1/protected/fornecedores/listarTodos", {
                                headers: headers
                            }).then(function (response) {

                                setFornecedores(response.data);
                                setLoading(false);

                            });

                        });
                    });
                });


            });



        } catch (_err) {

            console.log("Erro ao listar informações: " + _err)
            setLoading(false);

        }

        setBotaoSalvarClickado(false);
    }

    useEffect(() => {




        listarInformacoes();


    }, []);



    async function salvar() {

        setBotaoSalvarClickado(true);

        try {

            var id_user = Cookies.get('id_usuario');
            console.log("cadastrar chamado");
            const cadastro = {
                data: data,


                valor_unitario: valorUnitario,
                descricao: descricao,
                metodos_pagamento: metodosPagamentosSelecionados,
                condicoes_pagamento: condicoesPagamentosSelecionadas,
                link: link,
                observacoes: observacoes,

            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/almoxarifado/cotacoes/atualizar/' + idCotacao, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Cotação Atualizada");
                window.close();
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }

    const handleAdicionarMetodoPagamento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosSolicitantes = valoresSelecionados.filter(
            (opcao) => !metodosPagamentosSelecionados.some((selecionado) => selecionado.id_metodo_pagamento === opcao.id_metodo_pagamento)
        );

        setMetodosPagamentosSelecionados([...metodosPagamentosSelecionados, ...novosSolicitantes]);
    };




    const handleRemoverMetodoPagamento = (event, metodoRemovido) => {
        const novosMetodos = metodosPagamentosSelecionados.filter(
            (metodo) => metodo.id_metodo_pagamento !== metodoRemovido.id_metodo_pagamento
        );
        setMetodosPagamentosSelecionados(novosMetodos);
    };

    function returnDescricaoMetodoPagamento(metodo) {
        var texto = "ID_METODO: " + metodo.id_metodo_pagamento + "\nNome: " + metodo.nome +
            "\nCodigo: " + metodo.codigo + "\nSigla: " + metodo.sigla + "\nDescrição: " + metodo.descricao;
        return texto;
    }




    const handleAdicionarCondicaoPagamento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novasCondicoesPagamento = valoresSelecionados.filter(
            (opcao) => !condicoesPagamentosSelecionadas.some((selecionado) => selecionado.id_condicao_pagamento === opcao.id_condicao_pagamento)
        );

        setCondicoesPagamentosSelecionadas([...condicoesPagamentosSelecionadas, ...novasCondicoesPagamento]);
    };


    function returnDescricaoFornecedor(fornecedor) {
        var texto = "  Fornecedor_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }


    const handleRemoverCodicaoPagamento = (event, metodoRemovido) => {
        const novasCondicoes = condicoesPagamentosSelecionadas.filter(
            (metodo) => metodo.id_condicao_pagamento !== metodoRemovido.id_condicao_pagamento
        );
        setCondicoesPagamentosSelecionadas(novasCondicoes);
    };

    function returnDescricaoCodicaoPagamento(condicao) {
        var texto = "ID_CONDICAO: " + condicao.id_condicao_pagamento + "\nNome: " + condicao.nome +
            "\nCodigo: " + condicao.codigo + "\nSigla: " + condicao.sigla + "\nDescrição: " + condicao.descricao;
        return texto;
    }

    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Cotacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={(e) => salvar()}

                    > Atualizar  </Button>
                </Grid>
                {
                    loading ?
                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                        </Skeleton>
                        :

                        <Container >

                            <React.Fragment  >
                                <Grid container spacing={2}   >



                                    <Grid
                                        style={{ paddingTop: 5 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5} >
                                                <Autocomplete
                                                    id="select-fornecedor"
                                                    options={[]}
                                                    disableCloseOnSelect
                                                    disabled
                                                    getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor)}
                                                    value={fornecedorSelecionado}
                                                    onChange={(e, v) => handleSetFornecedor(v)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Fornecedor "

                                                            multiline
                                                            minRows={3} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="data"
                                                label="Data"
                                                required
                                                id="data"
                                                value={data}
                                                onChange={(e) => setData(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>




                                        <Grid item xs={2}>
                                            <TextField
                                                variant="standard"
                                                name="valorUnitario"
                                                label="Valor Unitário"
                                                required
                                                id="valorUnitario"
                                                value={valorUnitario}
                                                onChange={(e) => setValorUnitario(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                            >

                                                <Autocomplete
                                                    id="select-metodos-pagamento"
                                                    options={metodosPagamento}
                                                    disableCloseOnSelect
                                                    multiple
                                                    fullWidth
                                                    getOptionLabel={(metodo) => returnDescricaoMetodoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                                    value={metodosPagamentosSelecionados}
                                                    onChange={handleAdicionarMetodoPagamento}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((solicitante) => {
                                                            const cotacaoText = returnDescricaoMetodoPagamento(solicitante).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Metodos de Pagamento " multiline rows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoMetodoPagamento(option).replace(/\n/g, '<br>') }} />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((metodo, index) => (
                                                            <Chip
                                                                label={returnDescricaoMetodoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                                                {...getTagProps({ index })}
                                                                onDelete={(event) => handleRemoverMetodoPagamento(event, metodo)}
                                                            />
                                                        ))
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                            >

                                                <Autocomplete
                                                    id="select-condicao_pagamento"
                                                    options={condicoesPagamento}
                                                    disableCloseOnSelect
                                                    multiple
                                                    fullWidth
                                                    getOptionLabel={(condicao) => returnDescricaoCodicaoPagamento(condicao).replace(/<br \/>/g, '\n')}
                                                    value={condicoesPagamentosSelecionadas}
                                                    onChange={handleAdicionarCondicaoPagamento}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((solicitante) => {
                                                            const cotacaoText = returnDescricaoCodicaoPagamento(solicitante).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Condições de Pagamento " multiline rows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCodicaoPagamento(option).replace(/\n/g, '<br>') }} />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((metodo, index) => (
                                                            <Chip
                                                                label={returnDescricaoCodicaoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                                                {...getTagProps({ index })}
                                                                onDelete={(event) => handleRemoverCodicaoPagamento(event, metodo)}
                                                            />
                                                        ))
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="descricao"
                                                label="Descrição"
                                                id="descricao"
                                                value={descricao}
                                                onChange={(e) => setDescricao(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="link"
                                                label="Link"
                                                required
                                                id="link"
                                                value={link}
                                                onChange={(e) => setLink(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="observacoes"
                                                label="Observações"
                                                required
                                                id="observacoes"
                                                value={observacoes}
                                                onChange={(e) => setObservacoes(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>



                                    </Grid>


                                </Grid>


                            </React.Fragment>


                        </Container>
                }
            </div >
        </div >
    );
}


