import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Chip from '@mui/material/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import NavegadorGestao from "../../../../components/NavBarGestao";


import api from '../../../../services/api';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



export default function Configuracoes() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const [horaInicial, setHoraInicial] = useState("");
    const [dataInicial, setDataInicial] = useState("");
    const [horaFinal, setHoraFinal] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");



    const [botaoClicado, setBotaoClicado] = useState(false);
    const [loading, setLoading] = useState(true);
    const [unidades, setUnidades] = useState([]);
    const [unidadeSelecionada, setUnidadeSelecionada] = useState();

    const [configuracoes, setConfiguracoes] = useState(null);

    const [prazoMaximoOrdemCompra, setPrazoMaximoOrdemCompra] = useState(0);

    const [centroCustoSelecionado, setCentroCustoSelecionado] = useState(null);
    const [centrosCustos, setCentrosCustos] = useState([]);



    const [valorMaximoOrdemCompra1Diretor, setValorMaximoOrdemCompra1Diretor] = useState(0);
    const [valorMaximoOrdemCompra2Diretores, setValorMaximoOrdemCompra2Diretores] = useState(0);
    const [valorMaximoOrdemCompra3Diretores, setValorMaximoOrdemCompra3Diretores] = useState(0);


    const [supervisor1Selecionado, setSupervisor1Selecionado] = useState(null);


    const [diretor1Selecionado, setDiretor1Selecionado] = useState(null);
    const [diretor2Selecionado, setDiretor2Selecionado] = useState(null);
    const [diretor3Selecionado, setDiretor3Selecionado] = useState(null);

    const [diretores, setDiretores] = useState([]);

    const [gerente1Selecionado, setGerente1Selecionado] = useState(null);
    const [gerente2Selecionado, setGerente2Selecionado] = useState(null);
    const [auxiliar1Selecionado, setAuxiliar1Selecionado] = useState(null);
    const [auxiliar2Selecionado, setAuxiliar2Selecionado] = useState(null);


    
    const [usuariosRecebedoresNotificacoesCCSelecionados, setUsuariosRecebedoresNotificacoesCCSelecionados] = useState([]);

    
    

    const handleAdicionarUsuariosRecebedoresNotificacoesCC = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosSolicitantes = valoresSelecionados.filter(
            (opcao) => !usuariosRecebedoresNotificacoesCCSelecionados.some((selecionado) => selecionado.id_usuario === opcao.id_usuario)
        );

        setUsuariosRecebedoresNotificacoesCCSelecionados([...usuariosRecebedoresNotificacoesCCSelecionados, ...novosSolicitantes]);
    };


    const handleRemoverUsuariosRecebedoresNotificacoesCC = (event, solicitanteRemovido) => {
        const novosSolicitantes = usuariosRecebedoresNotificacoesCCSelecionados.filter(
            (solicitante) => solicitante.id_usuario !== solicitanteRemovido.id_usuario
        );
        setUsuariosRecebedoresNotificacoesCCSelecionados(novosSolicitantes);
    };

    function returnDescricaoSolicitante(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel + "\nEmail: " + usuario.email;
        return texto;
    }



    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();





    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/configuracoes", {
                headers: headers
            }).then(function (response) {
                setConfiguracoes(response.data)

                setUnidadeSelecionada(response.data.unidade_base);

                setCentroCustoSelecionado(response.data.centro_custo);

                setPrazoMaximoOrdemCompra(response.data.prazo_maximo_cumprimento_ordem_compra);
                setValorMaximoOrdemCompra1Diretor(response.data.valor_maximo_ordem_compra_1_diretor);
                setValorMaximoOrdemCompra2Diretores(response.data.valor_maximo_ordem_compra_2_diretores);
                setValorMaximoOrdemCompra3Diretores(response.data.valor_maximo_ordem_compra_3_diretores);

                setSupervisor1Selecionado(response.data.supervisor1);

                setDiretor1Selecionado(response.data.diretor1);
                setDiretor2Selecionado(response.data.diretor2);
                setDiretor3Selecionado(response.data.diretor3);

                setGerente1Selecionado(response.data.gerenteFinanceiro1);
                setGerente2Selecionado(response.data.gerenteFinanceiro2);
                setAuxiliar1Selecionado(response.data.auxiliarFinanceiro1);
                setAuxiliar2Selecionado(response.data.auxiliarFinanceiro2);

                if(response.data.usuarios_recebedores_notificacoes_cc)
                setUsuariosRecebedoresNotificacoesCCSelecionados(response.data.usuarios_recebedores_notificacoes_cc);


                api.get("v1/protected/unidades/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setUnidades(response.data)


                    api.get("v1/protected/user/listartodos", {
                        headers: headers
                    }).then(function (response) {
                        setDiretores(response.data);


                        api.get("v1/protected/financeiro/centrocusto/listartodos", {
                            headers: headers
                        }).then(function (response) {
                            setCentrosCustos(response.data);
                            setLoading(false);

                        });

                    });



                });

            });



        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Solicitantes: " + _err)

        }

    }

    useEffect(() => {




        listarInformacoes();


    }, []);

    function isHoraValida(hora) {
        return moment(hora, 'HH:mm', true).isValid();
    }

    // Função para validar data no formato dd/mm/yyyy
    function isDataValida(data) {
        return moment(data, 'DD/MM/YYYY', true).isValid();
    }

    // Função para formatar a data para o formato yyyy-mm-dd
    function formatarData(data) {
        return moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    async function inserirNovoEvento() {
        try {

            // Validação de hora e data
            if (!isHoraValida(horaInicial) || !isHoraValida(horaFinal) || !isDataValida(dataInicial) || !isDataValida(dataFinal)) {
                alert("Hora ou data inválida. Por favor, verifique os valores e tente novamente.");
                return;
            }


            const cadastro = {
                usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                nome: nome,
                descricao: descricao,
                hora_inicial: horaInicial,
                data_inicial: formatarData(dataInicial),
                hora_final: horaFinal,
                data_final: formatarData(dataFinal),

            };


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/configuracoes/eventoglobal/inserirEvento', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Novo Evento Inserido")
                window.location.reload();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");

            }

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    async function funcaoSalvar() {



        try {

            if (!unidadeSelecionada) {
                alert("Informe a Unidade Administrativa Base!");
                return;
            }

            
            if (!centroCustoSelecionado) {
                alert("Informe o Centro de Custo!");
                return;
            }

            const cadastro = {
                id_configuracao: configuracoes.id_configuracao,
                unidade_base: { id_unidade: unidadeSelecionada.id_unidade },
                centro_custo: { id_centro_custo: centroCustoSelecionado.id_centro_custo },
                prazo_maximo_cumprimento_ordem_compra: prazoMaximoOrdemCompra,
                valor_maximo_ordem_compra_1_diretor: valorMaximoOrdemCompra1Diretor,
                valor_maximo_ordem_compra_2_diretores: valorMaximoOrdemCompra2Diretores,
                valor_maximo_ordem_compra_3_diretores: valorMaximoOrdemCompra3Diretores,


            };

            // Verifica se supervisor1 foi selecionado
            if (supervisor1Selecionado) {
                cadastro.supervisor1 = { id_usuario: supervisor1Selecionado.id_usuario };
            }

            // Verifica se diretor1 foi selecionado
            if (diretor1Selecionado) {
                cadastro.diretor1 = { id_usuario: diretor1Selecionado.id_usuario };
            }

            // Verifica se diretor2 foi selecionado
            if (diretor2Selecionado) {
                cadastro.diretor2 = { id_usuario: diretor2Selecionado.id_usuario };
            }

            // Verifica se diretor3 foi selecionado
            if (diretor3Selecionado) {
                cadastro.diretor3 = { id_usuario: diretor3Selecionado.id_usuario };
            }


            if (gerente1Selecionado) {
                cadastro.gerenteFinanceiro1 = { id_usuario: gerente1Selecionado.id_usuario };
            }

            if (gerente2Selecionado) {
                cadastro.gerenteFinanceiro2 = { id_usuario: gerente2Selecionado.id_usuario };
            }

            if (auxiliar1Selecionado) {
                cadastro.auxiliarFinanceiro1 = { id_usuario: auxiliar1Selecionado.id_usuario };
            }

            if (auxiliar2Selecionado) {
                cadastro.auxiliarFinanceiro2 = { id_usuario: auxiliar2Selecionado.id_usuario };
            }



            

            let solicitantes_cad = [];

            if (!usuariosRecebedoresNotificacoesCCSelecionados || usuariosRecebedoresNotificacoesCCSelecionados.length > 0) {
                solicitantes_cad = usuariosRecebedoresNotificacoesCCSelecionados.map(solicitante => {


                    return {
                        solicitante: {
                            id_usuario: solicitante.id_usuario
                        }
                    };
                });
            }

            if(!solicitantes_cad || solicitantes_cad.length > 0){
                cadastro.usuarios_recebedores_notificacoes_cc = solicitantes_cad;
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/configuracoes/atualizar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Atualizado")
                window.location.reload();

                setBotaoClicado(false);


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoClicado(false);

            }

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const handleUnidade = (event, unidade) => {

        setUnidadeSelecionada(unidade);
    };



    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }



    function returnDescricaoDiretor(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel;
        return texto;
    }

    function handleSupervisor1(executor) {
        setSupervisor1Selecionado(executor);
    }

    function handleDiretor1(executor) {
        setDiretor1Selecionado(executor);
    }

    function handleDiretor2(executor) {
        setDiretor2Selecionado(executor);
    }


    function handleDiretor3(executor) {
        setDiretor3Selecionado(executor);
    }




    async function handleClickRemocao(event, cellValues) {




        const id_remover = cellValues.row.id_evento_global;

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.delete("v1/protected/configuracoes/eventoglobal/remover/" + id_remover, {
                headers: headers
            }).then(function (response) {
                const cadastro_salvo = response.data;

                if (cadastro_salvo) {
                    alert("Evento Removido");
                    window.location.reload();

                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            });


        } catch (_err) {

            console.log("Erro ao remover Evento " + _err)
            setLoading(false);

        }

    };


    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 250,
            renderCell: (cellValues) => {

                const status = cellValues.row.status;


                return (

                    <Grid item xs={4}>
                        <ButtonGroup aria-label="outlined primary button group">


                            {status === 0 ?
                                <IconButton
                                    color="primary"
                                    onClick={(event) => handleClickRemocao(event, cellValues)}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                                : null
                            }



                        </ButtonGroup>
                    </Grid>

                );

            },
        },
        {
            headerName: 'ID',
            field: 'id_evento_global',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Nome',
            field: 'nome',
            id: 2,
            width: 150,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 3,
            width: 200,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Hora Inicial',
            field: 'hora_inicial',
            id: 4,
            width: 100,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Data Inicial',
            field: 'data_inicial',
            id: 5,
            width: 100,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Hora Final',
            field: 'hora_final',
            id: 6,
            width: 100,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Data Final',
            field: 'data_final',
            id: 7,
            width: 100,
            headerClassName: 'cabecalho_azul',
        },


    ];




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    function returnDescricaoCentroCusto(cc) {
        if (cc) {
            var texto = cc.nome;
            return texto;
        }
        else
            return '';
    }

    const handleChangeCentroCusto = (event, cc) => {

        setCentroCustoSelecionado(cc);
    };


    return (
        <div>
            <NavegadorGestao
                Administracao={true}
                Configuracoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container  >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Configurações de Ordens de Compras" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Configurações de Ordens de Serviços" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Mesa Diretora" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Mesa Financeira" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Eventos" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Configurações de Operações" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>




                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ marginTop: 50 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoClicado}
                                    > Atualizar Configurações  </Button>
                                </Grid>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-ordem-servico"
                                                    options={unidades}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(unidade) => returnDescricaoUnidade(unidade).replace(/<br \/>/g, '\n')}
                                                    value={unidadeSelecionada}
                                                    onChange={handleUnidade}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((ordem) => {
                                                            const cotacaoText = returnDescricaoUnidade(ordem).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Unidade Administrativa Base" multiline rows={7} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoUnidade(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-cc"
                                                    options={centrosCustos}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(cc) => returnDescricaoCentroCusto(cc).replace(/<br \/>/g, '\n')}
                                                    value={centroCustoSelecionado}
                                                    onChange={handleChangeCentroCusto}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((grupo) => {
                                                            const cotacaoText = returnDescricaoCentroCusto(grupo).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Centro de Custo" multiline minRows={1} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCentroCusto(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={9}
                                            alignItems="center"
                                            justifyContent="center"

                                        >

                                            <TextField
                                                id="login"
                                                variant="standard"
                                                name="prazoMaximoOrdemCompra"
                                                fullWidth
                                                label="Prazo Máximo para cumprimento de Ordem de Compra"
                                                required
                                                autoComplete="prazoMaximoOrdemCompra"
                                                value={prazoMaximoOrdemCompra}
                                                onChange={e => setPrazoMaximoOrdemCompra(e.target.value)}

                                            />
                                        </Grid>





                                        <Grid
                                            container
                                            direction="row"
                                            item xs={9}
                                            alignItems="center"
                                            justifyContent="center"

                                        >

                                            <TextField
                                                id="valorMaximoOrdemCompra1Diretor"
                                                variant="standard"
                                                name="valorMaximoOrdemCompra1Diretor"
                                                fullWidth
                                                label="Valor Máximo Permitido para Compras com Autorização de no Mínimo 1 Diretor"
                                                required
                                                autoComplete="valorMaximoOrdemCompra1Diretor"
                                                value={valorMaximoOrdemCompra1Diretor}
                                                onChange={e => setValorMaximoOrdemCompra1Diretor(e.target.value)}

                                            />
                                        </Grid>


                                        <Grid
                                            container
                                            direction="row"
                                            item xs={9}
                                            alignItems="center"
                                            justifyContent="center"

                                        >

                                            <TextField
                                                id="valorMaximoOrdemCompra2Diretores"
                                                variant="standard"
                                                name="valorMaximoOrdemCompra2Diretores"
                                                fullWidth
                                                label="Valor Máximo Permitido para Compras com Autorização de no Mínimo 2 Diretores"
                                                required
                                                autoComplete="valorMaximoOrdemCompra2Diretores"
                                                value={valorMaximoOrdemCompra2Diretores}
                                                onChange={e => setValorMaximoOrdemCompra2Diretores(e.target.value)}

                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={9}
                                            alignItems="center"
                                            justifyContent="center"

                                        >

                                            <TextField
                                                id="valorMaximoOrdemCompra3Diretores"
                                                variant="standard"
                                                name="valorMaximoOrdemCompra3Diretores"
                                                fullWidth
                                                label="Valor Máximo Permitido para Compras com Autorização de no Mínimo 3 Diretores"
                                                required
                                                autoComplete="valorMaximoOrdemCompra3Diretores"
                                                value={valorMaximoOrdemCompra3Diretores}
                                                onChange={e => setValorMaximoOrdemCompra3Diretores(e.target.value)}

                                            />
                                        </Grid>


                                    </Grid>


                                    <Grid
                                        value={value} index={2}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    ></Grid>


                                    <Grid
                                        value={value} index={3}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

<Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-supervisor1"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={supervisor1Selecionado}
                                                    onChange={(e, v) => handleSupervisor1(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Supervisor" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-diretor1"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={diretor1Selecionado}
                                                    onChange={(e, v) => handleDiretor1(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Diretor 1" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-diretor1"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={diretor2Selecionado}
                                                    onChange={(e, v) => handleDiretor2(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Diretor 2" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-diretor1"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={diretor3Selecionado}
                                                    onChange={(e, v) => handleDiretor3(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Diretor 3" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                    </Grid>

                                    <Grid
                                        value={value} index={4}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-gerente1"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={gerente1Selecionado}
                                                    onChange={(e, v) => setGerente1Selecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="1º Gerente Financeiro" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-gerente2"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={gerente2Selecionado}
                                                    onChange={(e, v) => setGerente2Selecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="2º Gerente Financeiro" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-auxiliar1"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={auxiliar1Selecionado}
                                                    onChange={(e, v) => setAuxiliar1Selecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="1º Auxiliar Financeiro" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-auxiliar2"
                                                    options={diretores}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(diretor) => returnDescricaoDiretor(diretor).replace(/<br \/>/g, '\n')}
                                                    value={auxiliar2Selecionado}
                                                    onChange={(e, v) => setAuxiliar2Selecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cotacao) => {
                                                            const cotacaoText = returnDescricaoDiretor(cotacao).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="2º Auxiliar Financeiro" multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoDiretor(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid
                                        value={value} index={5}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={3}
                                                alignItems="center"
                                                justifyContent="center"

                                            >

                                                <TextField
                                                    id="nome"
                                                    variant="standard"
                                                    name="nome"
                                                    fullWidth
                                                    label="Nome Evento"
                                                    required
                                                    value={nome}
                                                    onChange={e => setNome(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 15 }}

                                                />
                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={11}
                                                alignItems="center"
                                                justifyContent="center"

                                            >

                                                <TextField
                                                    id="descricao"
                                                    variant="standard"
                                                    name="descricao"
                                                    fullWidth
                                                    label="Descrição Evento"
                                                    required
                                                    value={descricao}
                                                    onChange={e => setDescricao(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 15 }}
                                                    multiline
                                                    rows={3}

                                                />
                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={3}
                                                alignItems="center"
                                                justifyContent="center"

                                            >

                                                <TextField
                                                    id="horaInicial"
                                                    variant="standard"
                                                    name="horaInicial"
                                                    fullWidth
                                                    label="Hora Inicial"
                                                    required
                                                    value={horaInicial}
                                                    onChange={e => setHoraInicial(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 15 }}

                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={3}
                                                alignItems="center"
                                                justifyContent="center"

                                            >

                                                <TextField
                                                    id="dataInicial"
                                                    variant="standard"
                                                    name="dataInicial"
                                                    fullWidth
                                                    label="Data Inicial"
                                                    required
                                                    value={dataInicial}
                                                    onChange={e => setDataInicial(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 15 }}

                                                />
                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={3}
                                                alignItems="center"
                                                justifyContent="center"

                                            >

                                                <TextField
                                                    id="horaFinal"
                                                    variant="standard"
                                                    name="horaFinal"
                                                    fullWidth
                                                    label="Hora Final"
                                                    required
                                                    value={horaFinal}
                                                    onChange={e => setHoraFinal(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 15 }}

                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={3}
                                                alignItems="center"
                                                justifyContent="center"

                                            >

                                                <TextField
                                                    id="dataFinal"
                                                    variant="standard"
                                                    name="dataFinal"
                                                    fullWidth
                                                    label="Data Final"
                                                    required
                                                    value={dataFinal}
                                                    onChange={e => setDataFinal(e.target.value)}
                                                    inputProps={{ maxLength: 50, minLength: 15 }}

                                                />
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                item xs={10}
                                            >
                                                <Button style={{ marginTop: 50 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={inserirNovoEvento}

                                                > Inserir Novo Evento  </Button>
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >

                                            <>
                                                <DataGrid
                                                    localeText={ptBR.localeText}
                                                    pagination
                                                    checkboxSelection
                                                    rows={configuracoes.eventos}
                                                    getRowId={(row) => row.id_evento_global}
                                                    columns={columnsDataGrid}
                                                    disableColumnResize={false}
                                                    onCellClick={handleCellClick}
                                                    onRowClick={handleRowClick}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                />

                                            </>
                                        </Grid>



                                    </Grid>

                                    <Grid
                                        value={value} index={6}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                         
                                         <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 20 }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={5}
                                    >

                                        <Autocomplete
                                            id="select-usuarios-recebedores-notificacaocc"
                                            options={diretores}
                                            disableCloseOnSelect
                                            multiple
                                            getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                            value={usuariosRecebedoresNotificacoesCCSelecionados}
                                            onChange={handleAdicionarUsuariosRecebedoresNotificacoesCC}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((solicitante) => {
                                                    const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Usuários Recebedores de Notificações em Cópia " multiline rows={7} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((solicitante, index) => (
                                                    <Chip
                                                        label={returnDescricaoSolicitante(solicitante)}
                                                        {...getTagProps({ index })}
                                                        onDelete={(event) => handleRemoverUsuariosRecebedoresNotificacoesCC(event, solicitante)}
                                                    />
                                                ))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                         
                                          </Grid>
                                              </Grid>

                                </SwipeableViews>

                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


