import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import api from '../../../services/api';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import MenuFornecedor from '../components/menu';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import NavBarAdmin from "../../../components/NavBarAdmin";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastroClienteFornecedor() {
    const classes = useStyles();
    const history = useHistory();

    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */



    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    /* variaveis de estado de cadastro de pessoa juridica*/


    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    /*variaveis gerais*/


    const [infoAdicional, setInfoAdicional] = useState('');


    /*variaveis de controle pessoa fisica */
    const [erroCpf, setErroCpf] = useState(false);
    const [textoErroCpf, setTextoErroCpf] = useState('');

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');


    const [erroDataNascimento, setErroDataNascimento] = useState(false);
    const [textoErroDataNascimento, setTextoErroDataNascimento] = useState('');

    /*variaveis de controle pessoa fisica */


    /*variaveis de controle pessoa juridica */
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [textoErroRazaoSocial, setTextoErroRazaoSocial] = useState('');

    const [erroNomeFantasia, setErroNomeFantasia] = useState(false);
    const [textoErroNomeFantasia, setTextoErroNomeFantasia] = useState('');

    const [erroCnpj, setErroCnpj] = useState(false);
    const [textoErroCnpj, setTextoErroCnpj] = useState('');
    /*variaveis de controle pessoa juridica */



    /* variaveis  de controle gerais */

    const [erroEmail, setErroEmail] = useState(false);
    const [textoErroEmail, setTextoErroEmail] = useState('');

    const [erroPassword, setErroPassword] = useState(false);
    const [textoErroPassword, setTextoErroPassword] = useState('');
    /* variaveis  de controle gerais */





    const verificaEspaco = (string) => /\s/g.test(string);



    function validarRazaoSocial() {
        if (razaoSocial?.length > 0) {
            setErroRazaoSocial(false);
            setTextoErroRazaoSocial('');
            return true;
        } else {
            setErroRazaoSocial(true);
            setTextoErroRazaoSocial("Razão Social Inválida");
            return false;
        }
    }

    function validarNomeFantasia() {
        if (nomeFantasia?.length > 0) {
            setErroNomeFantasia(false);
            setTextoErroNomeFantasia('');
            return true;
        } else {
            setErroNomeFantasia(true);
            setTextoErroNomeFantasia("Nome Fantasia Inválido");
            return false;
        }
    }

    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }





    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }


    function validateCnpj() {

        if (cnpj.length === 14) {
            setErroCnpj(false);
            setTextoErroCnpj('');
            return true;
        } else {
            setErroCnpj(true);
            setTextoErroCnpj("CNPJ Inválido!");
            return false;
        }
    }

    function validateCpf() {

        if (cpf.length === 11) {
            setErroCpf(false);
            setTextoErroCpf('');
            return true;
        } else {
            setErroCpf(true);
            setTextoErroCpf("CPF Inválido!");
            return false;
        }
    }

    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataNascimento(false);
            setTextoErroDataNascimento("")
        } else {
            setErroDataNascimento(true);
            setTextoErroDataNascimento("Data de Nascimento Inválida!")
        }
        return teste;
    }


    function validateSenha() {
        if (password.length >= 8) {
            if (password === password2) {

                setErroPassword(false);
                setTextoErroPassword('');
                return true;
            } else {

                setErroPassword(true);
                setTextoErroPassword("Senhas não conferem");
                console.log("senhas nao conferem");
                return false;
            }
        } else {
            setErroPassword(true);
            setTextoErroPassword("Senha muito curta");
            console.log("Senha muito curta");
            return false;
        }
    }





    function validateEmail() {
        //var re1 = /\S+@\S+\.\S+/;
        var re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        var re2 = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/;
        if (re.test(email) || re2.test(email)) {

            setErroEmail(false);
            setTextoErroEmail('');
            return true;
        } else {

            setErroEmail(true);
            setTextoErroEmail("Email Inválido!");

            return false;
        }
    }


    async function salvar() {

        const id_usuario = Cookies.get('id_usuario');
        console.log("id na tela de painel fornecedor: " + id_usuario)


        if (tipoFisica) {
            //cadastrar pessoa fisica

            validateCpf();
            validarRg();
            validarData(dataNascimento);
            validarNome();
            validarSobreNome();
            validateEmail();
            validateSenha();

            if (validateCpf() && validateEmail()) {
                //verificar por cpf ja cadastrado
                try {
                    console.log("procurar cpf chamado: " + cpf);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.get('/v1/protected/signuppfverify/' + cpf,
                        { headers: headers });
                    const resposa_cpf = response.data.resposta;
                    if (resposa_cpf === "Error: CPF Já Cadastrado") {
                        setErroCpf(true);
                        setTextoErroCpf("CPF Já Cadastrado!");
                    } else {
                        setErroCpf(false);
                        setTextoErroCpf("CPF Válido");
                        //verificar por email ja cadastrado
                        try {
                            console.log("procurar email chamado: " + email);
                            const headers = {
                                'Authorization': 'Bearer ' + Cookies.get("token")
                            }
                            const response = await api.get('/v1/protected/signupemailverify/' + email,
                                { headers: headers });
                            const resposta_email = response.data.resposta;
                            if (resposta_email === "Error: Email Já Cadastrado") {
                                setErroEmail(true);
                                setTextoErroEmail("Email Já Cadastrado!");
                            } else {
                                setErroEmail(false);
                                setTextoErroEmail("Email aceito");

                                console.log(validarData(dataNascimento));
                                //prosseguir cadastro
                                if (validarRg() && validarData(dataNascimento) && validarNome() &&
                                    validarSobreNome() && validateSenha()) {
                                    console.log("cadastro aceito!")


                                    try {
                                        const cadastro_cliente_pf = {
                                            tipo_cliente: 0,
                                            nome: nome,
                                            sobrenome: sobreNome,
                                            cpf: cpf,
                                            nascimento: dataNascimento,
                                            rg: rg,
                                            email: email,
                                            descricao: descricao,
                                            senhaweb: password,
                                            info_adicional: infoAdicional,
                                            fornecedor: { id_fornecedor: id_usuario },

                                        }

                                        const headers = {
                                            'Authorization': 'Bearer ' + Cookies.get("token")
                                        }

                                        const response = await api.post('/v1/protected/clientes/cadastrar', cadastro_cliente_pf,
                                            { headers: headers });

                                        const cadastro_salvo = response.data;
                                        if (cadastro_salvo) {
                                            history.push({
                                                pathname: '/meusclientes',
                                            })
                                        } else {
                                            alert("Erro de Conexão, tente novamente mais tarde");
                                        }
                                    } catch (_err) {
                                        console.log("erro ao cadastrar: " + _err);
                                        alert("Erro de Conexão, tente novamente mais tarde");

                                    }



                                } else {
                                    console.log("cadastro rejeitado!")
                                }

                            }
                        } catch (_err) {

                            alert("Erro de Conexão, tente novamente mais tarde");
                            return false;
                        }

                    }

                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");

                }


            }


        } else {
            //cadastrar pessoa juridica

            validateCnpj();
            validarRazaoSocial();
            validarNomeFantasia();
            validateEmail();
            validateSenha();

            if (validateCnpj() && validateEmail()) {
                //verificar por cpf ja cadastrado
                try {
                    console.log("procurar cnpj chamado: " + cnpj);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.get('/v1/protected/signuppjverify/' + cnpj,
                        { headers: headers });
                    const resposta_cnpj = response.data.resposta;
                    if (resposta_cnpj === "Error: CNPJ Já Cadastrado") {
                        setErroCnpj(true);
                        setTextoErroCnpj("CNPJ Já Cadastrado!");
                    } else {
                        setErroCnpj(false);
                        setTextoErroCnpj("CNPJ Válido");
                        //verificar por email ja cadastrado
                        try {
                            console.log("procurar email chamado: " + email);
                            const headers = {
                                'Authorization': 'Bearer ' + Cookies.get("token")
                            }
                            const response = await api.get('/v1/protected/signupemailverify/' + email,
                                { headers: headers });
                            const resposta_email = response.data.resposta;
                            if (resposta_email === "Error: Email Já Cadastrado") {
                                setErroEmail(true);
                                setTextoErroEmail("Email Já Cadastrado!");
                            } else {
                                setErroEmail(false);
                                setTextoErroEmail("Email aceito");

                                //prosseguir cadastro
                                if (validarRazaoSocial() && validarNomeFantasia && validateSenha()) {
                                    console.log("cadastro pj aceito!")


                                    try {

                                        console.log("cadastrar chamado");
                                        const cadastro_cliente_pj = {
                                            tipo_cliente: 1,
                                            cnpj: cnpj,
                                            razao_social: razaoSocial,
                                            nome_fantasia: nomeFantasia,
                                            porte: porte,
                                            atividade: atividade,
                                            email: email,
                                            descricao: descricao,
                                            senhaweb: password,
                                            info_adicional: infoAdicional,
                                            fornecedor: { id_fornecedor: id_usuario },

                                        }

                                        const headers = {
                                            'Authorization': 'Bearer ' + Cookies.get("token")
                                        }

                                        const response = await api.post('/v1/protected/clientes/cadastrar', cadastro_cliente_pj,
                                            { headers: headers });

                                        const cadastro_salvo = response.data;
                                        if (cadastro_salvo) {
                                            history.push({
                                                pathname: '/meusclientes',
                                            })
                                        } else {
                                            alert("Erro de Conexão, tente novamente mais tarde");
                                        }
                                    } catch (_err) {
                                        console.log("erro ao cadastrar: " + _err);
                                        alert("Erro de Conexão, tente novamente mais tarde");

                                    }



                                } else {
                                    console.log("cadastro pj rejeitado!")
                                }

                            }
                        } catch (_err) {

                            alert("Erro de Conexão, tente novamente mais tarde");
                            return false;
                        }

                    }

                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");

                }


            }
        }


    }




    const handleChange1 = (event) => {
        setTipoFisica(true);
        setTipoJuridica(false);
    };

    const handleChange2 = (event) => {
        setTipoFisica(false);
        setTipoJuridica(true);
    };

    return (
        <div>
            <NavBarAdmin />
            <div className={classes.root} >
                <MenuFornecedor titulo={"Cadastrar Novo Cliente"} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >

                        <React.Fragment >
                            <Grid container spacing={2}   >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <Typography  >
                                        Tipo Pessoa:
                                    </Typography>
                                    <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                        onChange={handleChange1} />} label="Pessoa Física" />
                                    <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                        onChange={handleChange2} />} label="Pessoa Jurídica" />

                                </Grid>



                                <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}>

                                        <Grid item xs={4}>
                                            <TextField
                                                error={erroCnpj}
                                                helperText={textoErroCnpj}
                                                variant="standard"
                                                name="cnpj"
                                                label="CNPJ"
                                                required
                                                id="cnpj"
                                                value={cnpj}
                                                onChange={e => (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) ? setCnpj(e.target.value) : setTextoErroCnpj('')}
                                                inputProps={{ maxLength: 14, minLength: 14 }}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={4} >
                                            <TextField
                                                error={erroRazaoSocial}
                                                helperText={textoErroRazaoSocial}
                                                variant="standard"
                                                name="razaoSocial"
                                                label="Razão Social"
                                                required
                                                id="razaoSocial"
                                                autoComplete="razaoSocial"
                                                value={razaoSocial}
                                                onChange={e => setRazaoSocial(e.target.value)}
                                                inputProps={{ maxLength: 50, minLength: 1 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4} >
                                            <TextField
                                                error={erroNomeFantasia}
                                                helperText={textoErroNomeFantasia}
                                                variant="standard"
                                                name="nomeFantasia"
                                                label="Nome Fantasia"
                                                required
                                                id="nomeFantasia"
                                                value={nomeFantasia}
                                                onChange={e => setNomeFantasia(e.target.value)}
                                                inputProps={{ maxLength: 50, minLength: 1 }}
                                                fullWidth

                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            spacing={2}>

                                            <Grid item xs={4} >

                                                <TextField

                                                    variant="standard"
                                                    name="porte"
                                                    label="Porte"
                                                    id="porte"
                                                    value={porte}
                                                    onChange={e => setPorte(e.target.value)}
                                                    inputProps={{ maxLength: 100, minLength: 0 }}
                                                    fullWidth

                                                />
                                            </Grid>

                                            <Grid item xs={4} >
                                                <TextField
                                                    variant="standard"
                                                    name="atividade"
                                                    label="Atividade"
                                                    id="atividade"
                                                    autoComplete="atividade"
                                                    value={atividade}
                                                    onChange={e => setAtividade(e.target.value)}
                                                    inputProps={{ maxLength: 100, minLength: 0 }}
                                                    fullWidth

                                                />
                                            </Grid>

                                            <Grid item xs={4}  >
                                                <TextField

                                                    variant="standard"
                                                    name="ie"
                                                    label="Inscrição Estadual"
                                                    id="ei"
                                                    value={ie}
                                                    onChange={e => setIe(e.target.value)}
                                                    inputProps={{ maxLength: 20, minLength: 0 }}
                                                    fullWidth

                                                />
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Collapse>

                                <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}>
                                        <Grid item xs={4}  >

                                            <TextField
                                                error={erroCpf}
                                                helperText={textoErroCpf}
                                                variant="standard"
                                                name="cpf"
                                                label="CPF"
                                                required
                                                id="cpf"
                                                value={cpf}
                                                onChange={e => (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) ? setCpf(e.target.value) : setTextoErroCnpj('')}
                                                inputProps={{ maxLength: 11, minLength: 11 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4}  >
                                            <TextField
                                                error={erroRg}
                                                helperText={textoErroRg}
                                                variant="standard"
                                                name="rg"
                                                label="RG"
                                                required
                                                id="rg"
                                                value={rg}
                                                onChange={e => setRg(e.target.value)}
                                                inputProps={{ maxLength: 20, minLength: 3 }}
                                                fullWidth

                                            />
                                        </Grid>


                                        <Grid item xs={4}  >
                                            <TextField
                                                error={erroDataNascimento}
                                                helperText={textoErroDataNascimento}
                                                variant="standard"
                                                name="dataNascimento"
                                                label="Data Nascimento"
                                                required
                                                id="dataNascimento"
                                                value={dataNascimento}
                                                onChange={e => setDataNascimento(e.target.value)}
                                                inputProps={{ maxLength: 20, minLength: 3 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4}  >
                                            <TextField
                                                error={erroNome}
                                                helperText={textoErroNome}
                                                variant="standard"
                                                name="nome"
                                                label="Nome"
                                                required
                                                id="nome"
                                                value={nome}
                                                onChange={e => setNome(e.target.value)}
                                                inputProps={{ maxLength: 100, minLength: 2 }}
                                                fullWidth

                                            />
                                        </Grid>

                                        <Grid item xs={4}  >
                                            <TextField
                                                error={erroSobreNome}
                                                helperText={textoErroSobreNome}
                                                variant="standard"
                                                name="sobrenome"
                                                label="Sobrenome"
                                                required
                                                id="sobrenome"
                                                value={sobreNome}
                                                onChange={e => setSobrenome(e.target.value)}
                                                inputProps={{ maxLength: 100, minLength: 2 }}
                                                fullWidth

                                            />
                                        </Grid>

                                    </Grid>
                                </Collapse>



                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    spacing={2}>

                                    <Grid item xs={8} >
                                        <TextField
                                            variant="standard"
                                            name="descricao"
                                            label="Descrição"
                                            id="descricao"
                                            value={descricao}
                                            onChange={e => setDescricao(e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={4}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={8} >
                                        <TextField
                                            variant="standard"
                                            name="infoAdicional"
                                            label="Informação Adicional"
                                            id="infoAdicional"
                                            value={infoAdicional}
                                            onChange={e => setInfoAdicional(e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={4}

                                        />
                                    </Grid>
                                </Grid>


                                <Grid
                                    style={{ paddingTop: 30 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid style={{ backgroundColor: 'LightCyan' }} component={Paper} elevation={12}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"

                                        spacing={2}>



                                        <Grid item xs={5}  >

                                            <Typography style={{ margin: 15 }} >
                                                <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                            </Typography>

                                            <TextField
                                                error={erroEmail}
                                                id="email"
                                                helperText={textoErroEmail}
                                                variant="standard"
                                                name="email"
                                                fullWidth
                                                label="E-Mail"
                                                required
                                                autoComplete="email"
                                                value={email}
                                                onChange={e => verificaEspaco(e.target.value) ? setTextoErroEmail("") : setEmail(e.target.value)}
                                                inputProps={{ maxLength: 50, minLength: 15 }}

                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            spacing={2}>
                                            <Grid item xs={3}>
                                                <TextField
                                                    error={erroPassword}
                                                    id="password"
                                                    helperText={textoErroPassword}
                                                    variant="standard"
                                                    name="password"
                                                    fullWidth
                                                    label="Senha"
                                                    required
                                                    type="password"
                                                    autoComplete="current-password"
                                                    value={password}
                                                    onChange={e => (verificaEspaco(e.target.value) || /[`~!%^*()_|+\-=?;:¨'",.<>{}[\]/\b]+$/.test(e.target.value)) ? setTextoErroPassword('') : setPassword(e.target.value)}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    error={erroPassword}
                                                    id="password2"
                                                    helperText={textoErroPassword}
                                                    variant="standard"
                                                    name="password2"
                                                    fullWidth
                                                    label="Conferir Senha"
                                                    required
                                                    type="password"
                                                    autoComplete="current-password"
                                                    value={password2}
                                                    onChange={e => (verificaEspaco(e.target.value) || /[`~!%^*()_|+\-=?;:¨'",.<>{}[\]/\b]+$/.test(e.target.value)) ? setTextoErroPassword('') : setPassword2(e.target.value)}
                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>



                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ marginTop: 50 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                    > Salvar  </Button>
                                </Grid>

                            </Grid>


                        </React.Fragment>

                    </Container>
                </main >
            </div >
        </div >
    );
}


