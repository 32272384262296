import React from 'react';



import NavBarAdmin from "../../../../components/NavBarAdmin";
import Rodape from '../../../../components/Rodape';

import MenuAreaGestao from '../components/menu';


export default function AreaGestao() {
   

    


    return (
        <div>

            <NavBarAdmin />
            <MenuAreaGestao 
            link1={["Home","/"]}
           link2={["Minha Conta", "/minhaconta"]}
         
           titulo={"Área Central de Gestão"}
/>
            


        </div>
    );
}



