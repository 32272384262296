import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';

import { useHistory } from "react-router-dom";





import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastrarFuncao() {
    const classes = useStyles();
    const history = useHistory();


    const [estadoBotaoSalvar, setEstadoBotaoSalvar] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState(''); // Add this line






    async function salvar() {
        setEstadoBotaoSalvar(true);
        console.log("Salvar chamado");
        try {

            const cadastro_funcao = {

                nome: nome,

                descricao: descricao,

            };

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/colaboradores/funcao/cadastrar', cadastro_funcao,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Função cadastrado com sucesso!")

                history.push({
                    pathname: '/gestao/rh/funcoes/listar',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente!");
            setEstadoBotaoSalvar(false);
        }




    }






    return (
        <div>
            <NavegadorGestao
                RH={true}
                TabelasAuxiliaresRh={true}
                Funcoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <Container style={{ paddingTop: 5 }}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={salvar}
                        disabled={estadoBotaoSalvar}
                    > Salvar  </Button>
                </Grid>
                <React.Fragment >
                    <Grid container spacing={2}   >



                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            spacing={2}>




                            <Grid item xs={4} >
                                <TextField
                                    variant="standard"
                                    name="nome"
                                    label="Nome"
                                    required
                                    id="nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                    inputProps={{ maxLength: 50, minLength: 1 }}
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs={12}  >
                                <TextField

                                    variant="standard"
                                    name="descricao"
                                    label="Descrição"
                                    id="descricao"
                                    value={descricao}
                                    onChange={e => setDescricao(e.target.value)}
                                    inputProps={{ maxLength: 600, minLength: 0 }}
                                    fullWidth

                                />
                            </Grid>


                        </Grid>




                    </Grid>


                </React.Fragment>



            </Container>

        </div >
    );
}


